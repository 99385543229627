export default [
    {
        path: '/offer_analitics_grids',
        name: 'offer_analitics_grids',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Mapa de Oferta',
            icon: 'mdi-google-analytics',
            breadcrumbs: [
                { text: 'Mapa de Oferta', disabled: true }
            ]
        }
    }
]