export default [
    {
        path: '/',
        name: 'home',
        component: () => import ('./pages/Home'),
        meta: {
            title: 'Home',
            icon: 'mdi-home',
            breadcrumbs: [
                { text: 'Home', disabled: true, href: '/' }
            ],
        }
    }
]