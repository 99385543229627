<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-comment-text-multiple-outline" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:tools>
            <v-tooltip v-if="tab === 1 && !!model.id" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success darken-1" icon @click.prevent.stop="showAddQuestion = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span> Cadastrar Nova Pergunta </span>
            </v-tooltip>
        </template>
        
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab"  @change="forceRerender()" >
                <v-tab key="main">Categoria</v-tab>
                <v-tab key="questions">Perguntas</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container>
                            <v-row>
                                <v-col cols="12" sm="10">
                                    <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código*"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <span>Descrição*</span>
                                    <vue-editor v-model="model.description" :editorToolbar="customToolbar" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <s-text-field-icon :error-messages="errors.icon" v-model="model.icon" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-text-field :error="!!errors.key_words" :error-messages="errors.key_words" v-model="model.key_words" label="Palavras Chave"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <v-text-field :error="!!errors.order" :error-messages="errors.order" v-model="model.order" label="Ordem*"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                                </v-col>
                            </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="questions">
                    <sys-faq-questions v-if="renderComponent" :showAdd.sync="showAddQuestion" :item="model" />
                </v-tab-item>
            </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysFaqCategoryRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        renderComponent: true,
        errors: {},
        message: '',
        loading: false,
        showAddQuestion: false,
        tab: 0,
        customToolbar: [

                [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                [{ size: ["small", false, "large", "huge"] }],

                ["bold", "italic", "underline"],

                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],

                ["blockquote"],

                [{ list: "ordered" }, { list: "bullet" }],

                [{ indent: "-1" }, { indent: "+1" }],

                [{ color: [] }],

                ["link"],
                
        ]
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
                this.tab = 0;

                if(!this.model.id){
                    this.model.order = 0;
                    this.model.active = 1;
                }

                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-comment-text-multiple-outline"
        },
        title: {
            type: String,
            default: "Categoria de Faq"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('faqCategory', ['ActionUpdateFaqCategory', 'ActionCreateFaqCategory']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateFaqCategory(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateFaqCategory(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },

    }
}
</script>