export default [
    {
        path: '/payment_export_extracts',
        name: 'payment_export_extracts',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Extrato Financeiro',
            icon: 'mdi-card-account-details-outline',
            breadcrumbs: [
                { text: 'Extrato Financeiro', disabled: true }
            ]
        }
    }
]