export default {
    chartFind:                  { method: 'get', url: 'charts' },
    chartEfficiencyOfWorkload:  { method: 'get', url: 'charts/efficiencyOfWorkload' },
    chartEvolutionOfWorkload:   { method: 'get', url: 'charts/evolutionOfWorkload' },
    chartGroupNumbers:          { method: 'get', url: 'charts/groupNumbers' },
    chartTeacherNumbers:        { method: 'get', url: 'charts/teacherNumbers' },
    chartTeacherWorkLoadAvg:    { method: 'get', url: 'charts/teacherWorkLoadAvg' },
    chartAmbienceNumbers:       { method: 'get', url: 'charts/ambienceNumbers' },
    chartGridInHiringNumbers:   { method: 'get', url: 'charts/gridInHiringNumbers'},
    chartTeacherTitlings:       { method: 'get', url: 'charts/teacherTitlings'},
    budgetClass:                { method: 'get', url: 'charts/budget_class'},
    budgetActivity:             { method: 'get', url: 'charts/budget_activity'},
    budgetGeneral:              { method: 'get', url: 'charts/budget_general'},
    teacherBalance:             { method: 'get', url: 'charts/teacher_balance'},
    indexChartsOfferItems:      { method: 'get', url: 'charts/offer_items'},
}