import { default as event_public } from './event_public/routes'
import { default as area } from './area/routes'

export default [
    ...event_public,
    ...area,
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Calendário',
            icon: 'mdi-calendar',
            breadcrumbs: [
                { text: 'Calendário', disabled: true, href: '/' }
            ],
        }
    },
    {
        path: '/calendar-register',
        name: 'calendar-register',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Calendário',
            icon: 'mdi-calendar-edit',
            breadcrumbs: [
                { text: 'Calendário', disabled: true, href: '/' }
            ],
        }
    },
]