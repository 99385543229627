import { service } from '../../../helpers/http'

export const ActionFindTeacherContracts = ({ dispatch }, payload) => {

    return service({
        module: 'teacherContract',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateTeacherContract = ({ dispatch }, payload) => {
    
    return service({
        module: 'teacherContract',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateTeacherContract = ({ dispatch }, payload) => {
    
    return service({
        module: 'teacherContract',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteTeacherContract = ({ dispatch }, payload) => {
    
    return service({
        module: 'teacherContract',
        action: 'delete',
        payload: payload
    })
    
}

export const ActionIndexTeacherContractTypes = ({ dispatch }, payload) => {

    return service({
        module: 'teacherContract',
        action: 'indexTeacherContractTypes',
        payload: payload
    })
    
}

