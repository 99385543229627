<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          dark
          large
          color="grey"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Calendário</v-list-item-title>
              <v-list-item-subtitle>Configurações de Impressão</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <!-- <v-list-item-action>
              
            </v-list-item-action> -->
            <!-- <v-list-item-title></v-list-item-title> -->
            <v-container>
              <v-row v-if="model && model.id">
                <v-col>
                  <emc-avatar-calendar-setting width="80" height="80" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <s-text-field dense placeholder='Calendário Acadêmico {Ano}' :error-messages="errors.title" v-model="model.title" label="Título*" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <s-switch text-helper="Exibir Logo no cabeçalho" inset v-model="model.show_logo" label="Exibir Logo" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <s-switch text-helper="Exibir Resumo de contador de dias letivos" inset v-model="model.show_resume" label="Exibir Resumo" />
                </v-col>
              </v-row>
            </v-container>
            
          </v-list-item>

        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loading" text @click="save()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysCalendarPopOver",
    data: () => ({
      fav: true,
      menu: false,
      error: false,
      errors: [],
      message: '',
      model: {
        title: null,
        show_logo: true,
        show_resume: true,
      },
      loading: false,
    }),

    created() {
        this.getData();
    },
    
    computed: {
      ...mapState('auth', ['user'])
    },
    methods:{
        ...mapActions('calendar', ['ActionIndexEventCalendarSettings', 'ActionCreateEventCalendarSetting', 'ActionUpdateEventCalendarSetting', 'ActionDestroyEventCalendarSetting']),

        getData(){
          
          this.ActionIndexEventCalendarSettings()
                .then((res) => {
                    if(res.data.length > 0){
                      this.model = res.data[0];
                    }
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    
                });
        },
        
        save(){

          
          let method = this.model && this.model.id ? 'ActionUpdateEventCalendarSetting' : 'ActionCreateEventCalendarSetting';
          this.loading = true;

          this[method](this.model)
                .then((res) => {
                  this.model = res.data;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loading = false;
                });

        },
    }
}
</script>