<template>
    <v-simple-table>
        <template v-slot:top>
            <EmcBaseProgressBar v-if="loading" />
        </template>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left" width="30px">Horas</th>
                    <th class="text-center">Manhã</th>
                    <th class="text-center">Tarde</th>
                    <th class="text-center">Noite</th>
                    <th class="text-center">Digital</th>
                    <th class="text-center">Integral</th>
                    <th class="text-center">Total</th>
                </tr>
            </thead>
            <tbody v-if="availability">
                <tr>
                    <td>Disponibilizadas</td>
                    <td class="text-center"><strong v-if="!isNaN(parseFloat(availability.morning_availability))">{{ parseFloat(availability.morning_availability) }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(parseFloat(availability.evening_availability))">{{ parseFloat(availability.evening_availability) }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(parseFloat(availability.night_availability))">{{ parseFloat(availability.night_availability) }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(parseFloat(availability.digital_availability))">{{ parseFloat(availability.digital_availability) }}</strong></td>
                    <td></td>
                    <td class="text-center"><strong v-if="!isNaN(parseFloat(availability.max_workload))">{{ availability.max_workload === 44 ? parseFloat(availability.max_workload) + ' (máximo)' :  parseFloat(availability.max_workload)}}</strong></td>
                </tr>
                <tr>
                    <td>Atribuídas</td>
                    <td>
                        <v-progress-linear :value="availability.morning_workload / availability.morning_availability * 100" height="25" rounded :color="availability.morning_availability == '0' ? 'grey' : (availability.morning_workload == '0') ? 'orange' : 'green'">
                            <strong>{{ availability.morning_workload }}</strong>
                        </v-progress-linear>
                    </td>
                    <td>
                        <v-progress-linear :value="availability.evening_workload / availability.evening_availability * 100" height="25" rounded :color="availability.evening_availability == '0' ? 'grey' : (availability.evening_workload == '0') ? 'orange' : 'green'">
                            <strong>{{ availability.evening_workload }}</strong>
                        </v-progress-linear>
                    </td>
                    <td>
                        <v-progress-linear :value="availability.night_workload / availability.night_availability * 100" height="25" rounded :color="availability.night_availability == '0' ? 'grey' : (availability.night_workload == '0') ? 'orange' : 'green'">
                            <strong>{{ availability.night_workload }}</strong>
                        </v-progress-linear>
                    </td>
                    <td>
                        <v-progress-linear :value="availability.digital_workload / availability.digital_availability * 100" height="25" rounded :color="availability.digital_availability == '0' ? 'grey' : (availability.digital_workload == '0') ? 'orange' : 'green'">
                            <strong>{{ availability.digital_workload }}</strong>
                        </v-progress-linear>
                    </td>
                    <td class="text-center"><strong v-if="!isNaN(availability.full_workload)">{{ availability.full_workload }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(getAvailableTotal(availability))">{{ getAvailableTotal(availability) }}</strong></td>
                </tr>
                <tr>
                    <td>Saldo</td>
                    <td class="text-center"><strong v-if="!isNaN(availability.available_balance_morning)">{{ availability.available_balance_morning }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(availability.available_balance_evening)">{{ availability.available_balance_evening }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(availability.available_balance_night)">{{ availability.available_balance_night }}</strong></td>
                    <td class="text-center"><strong v-if="!isNaN(availability.available_balance_digital)">{{ availability.available_balance_digital }}</strong></td>
                    <td></td>
                    <td class="text-center"><strong v-if="!isNaN(availability.max_workload)">{{ availability.max_workload - (availability.morning_workload + availability.evening_workload + availability.night_workload + availability.digital_workload + availability.full_workload) }}</strong></td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'SysTeacherAvailabilitySummary',
    data: function() {
        return {
            loading: false,
            availabilities: [],
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        }
    },
    computed: {
        
        availability: function() {
            if(this.availabilities && this.availabilities.length > 0) {
                return this.availabilities[0];
            } else {
                return []
            }
        }
        
    },
    created(){
        this.getData();
    },
     watch: {
        teacher(){
            this.getData();
        }
     },
     methods: {
        ...mapActions('teacher', ['ActionFindTeachers']),
        
        getData(){

            if(this.loading){
                return;
            }
            
            this.loading = true;
            this.availabilities = [];
            
            var payload = {
                find_columns: {
                    id: this.teacher.id
                },
                with: 'availability',
                showAllTeachers: true
            }
            
            this.ActionFindTeachers(payload)
                .then((res) => {
                   this.availabilities = res.data[0].availabilities;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                     this.loading = false;
                });

        },

        getAvailableTotal(availability){
            return availability.morning_workload + availability.evening_workload + availability.night_workload + availability.digital_workload + availability.full_workload;
        }

     }
    
}
</script>