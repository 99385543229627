<template>
    <v-snackbar
          v-model="snackbar"
          :color="error ? 'error' : 'success'"
          >
            {{ texto }}
            <v-btn
              :color="error ? 'error' : 'success'"
              @click="snackbar = false"
              outlined
            >
              Fechar
            </v-btn>
      </v-snackbar>
</template>
<script>
export default {
    data: () => ({
        
    }),
    props: {
        snackbar: {
            type: Boolean,
            required: true,
        },
        error: {
            type: Boolean,
            required: true,
        },
        texto: {
            type: String,
            required: true,
        }
    }
}
</script>