<template>
    <v-dialog transition="dialog-top-transition" v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'">
        <v-card max-height="800px">
            <v-card-title  class="ma-0 pa-0">
                <v-app-bar  dense height="35" class="primary lighten-2 mb-4 elevation-1">
                    <v-toolbar-title v-if="model">
                        <span class="white--text subtitle-1">{{ model.name }}</span>
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon small @click.native="dialog = false">
                        <v-icon small color="white lighten-2">mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-img
                    class="ma-1"
                    v-if="source"
                    :src="source"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: "EmcAvatarShow",
    data() {
        return {
           dialog: false,
        };
    },
    props: {
        model: {
            required: true
        },
        show: {
            type: Boolean,
            default: false,
        },
        source: {
            
        },
    },

    watch: {
      show(val){
          this.dialog = val;
      },

      dialog(val){
          this.$emit('update:show', val);
      },
    },
    methods: {
        
    }
};
</script>