import { service } from '../../../helpers/http'

export const ActionFindCourseInfos = ({ dispatch }, payload) => {

    return service({
        module: 'courseInfo',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateCourseInfo = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseInfo',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateCourseInfo = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseInfo',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteCourseInfo = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseInfo',
        action: 'delete',
        payload: payload
    })
    
}

