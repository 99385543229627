<template>

  <v-card>
    <v-card-title>
      <span class="body-2">Relação de configurações para integração</span>
      <v-spacer></v-spacer>
      <v-btn icon @click.prevent.stop="show = true"><v-icon>mdi-plus</v-icon></v-btn>
    </v-card-title>

    <emcTableItems
        :headers="headers"
        v-bind:collections.sync="collections"
        v-bind:meta.sync="meta"
        v-bind:itemDelete.sync="itemDelete"
        v-bind:itemEdit.sync="model"
        :find="false"
    />

    <SysRegisterIntegrationParameter @onCompleted="getData()" :model="model" :show.sync="show"/>

    <!-- Modal usado para mensagem e ação de exclusão -->
    <emc-alert-modal :text="textDelete" :disabled="deleting" v-bind:show.sync="showDelete">
      <template v-slot:actions>
        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
      </template>
    </emc-alert-modal>

  </v-card>

</template>

<script>

import { mapActions } from 'vuex'
import {ActionDestroyIntegrationParameter} from "../../../modules/institution/store/actions";

export default {
  name: "SysInstitutionIntegration",
  props: {
    institution: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: 'Tipo', value: 'integration.name'},
      { text: 'Ambiente', value: 'environment'},
      { text: 'Usuário', value: 'integration_username'},
      { text: 'Domínio', value: 'integration_url'},
      { text: 'Porta', value: 'integration_port'},
      { text: 'Ações', value: 'actions'},
    ],
    collections: [],
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showDelete: false,
    textDelete: '',
    deleting: false
  }),
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('institution', ['ActionShowInstitution', 'ActionDestroyIntegrationParameter']),
    getData() {

      const params = {
        id: this.institution.id,
        with: 'integrationParameters.integration'
      }
      this.ActionShowInstitution(params)
          .then((r) => {
              this.collections = r.data.integration_parameters
              this.meta = {
                page: 1,
                itemsPerPage: 10,
                total: this.collections ? this.collections.length : 0,
                last_page: 1
              }
          })
          .finally(() => {

          })
    },
    deleteItem() {
      this.ActionDestroyIntegrationParameter({ id: this.itemDelete.id })
          .then((r) => {
            this.getData()
          })
          .finally(() => {
            this.showDelete = false
            this.textDelete = ''
          })
    }
  },
  watch: {
    model(newItem, oldItem) {
      if(newItem != oldItem && this.model.id) {
        this.show = true
      } else if(Object.keys(this.model).length === 0){
        this.show = false;
      }
    },
    show(val) {
      if(!val) {
        this.model = {}
      }
    },
    itemDelete(item_new, item_old){

      if(item_new !== item_old && Object.keys(item_new).length > 0)
      {
        this.showDelete = true;
        this.textDelete = "Deseja excluir o item " + item_new.environment + "?";
      } else {
        this.showDelete = false
        this.textDelete = '';
      }
    },
  }
}
</script>