import { service } from '../../../helpers/http'

export const ActionFindEventItems = ({ dispatch }, payload) => {
    return service({
        module: 'eventItem',
        action: 'eventItems',
        payload: payload
    })
}

export const ActionCreateEventItem = ({ dispatch }, payload) => {
    return service({
        module: 'eventItem',
        action: 'createEventItem',
        payload: payload
    })
}

export const ActionUpdateEventItem = ({ dispatch }, payload) => {
    return service({
        module: 'eventItem',
        action: 'updateEventItem',
        payload: payload
    })
}

export const ActionDeleteEventItem = ({ dispatch }, payload) => {
    return service({
        module: 'eventItem',
        action: 'deleteEventItem',
        payload: payload
    })
}