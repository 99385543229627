<template>
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" class="grey lighten-5 mb-4 elevation-1'">
                        <v-icon left>mdi-file-eye-outline</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                Detalhes da Atividade
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
                    <v-card-text v-if="model">

                        <v-tabs class="ma-0" v-model="tab">
                            <v-tab key="main">Detalhes</v-tab>
                            <v-tab key="logs">Histórico de Aprovações</v-tab>
                        </v-tabs>
                        <!-- Detalhes -->
                        <v-tabs-items  v-model="tab">
                            <v-tab-item class="mt-1" key="main">
                                <span v-for="(teacher, i) in model.teachers" :key="i">
                                    <strong>Professor: </strong>{{ teacher.teacher.name }}
                                    <br/>
                                    <strong>Valor: </strong>{{ teacher.value }}
                                    <br/>
                                    <strong>Período: </strong>{{ teacher.start | date }} - {{ teacher.end | date }}
                                </span>
                                <br/>
                                <strong>Tipo: </strong>{{ model.periodicityType.name + ' | ' + model.valueType.name }}
                                <br/>
                                <span v-if="model.campus">
                                    <br/>
                                    <strong>Campus: </strong>{{ model.campus.name }}
                                </span>
                                <span v-if="model.departament">
                                    <br/>
                                    <strong>Departamento: </strong>{{ model.departament.name }}
                                </span>
                                
                                <span v-if="model.courses.length">
                                    <br/>
                                    <strong>Curso: </strong>
                                    <span v-for="(course, j) in model.courses" :key="j">
                                        <span v-if="j > 0">{{ ', ' }}</span>
                                        {{ course.name }}
                                    </span>
                                </span>
                                <span v-if="model.business_unit">
                                    <br/>
                                    <strong>Uneg: </strong>{{ model.business_unit }}
                                </span>
                                <span v-if="model.costCenter">
                                    <strong> CC: </strong>{{ model.costCenter.name }}
                                </span>
                                <span v-if="model.activity.paymentEvent">
                                    <br v-if="!model.business_unit" />
                                    <strong> Evento: </strong>{{ model.activity.paymentEvent.initials }}
                                </span>
                                <br/>
                                <strong>Atividade: </strong>{{ model.activity.name }}
                                <span v-if="model.activity.category">
                                    <br/>
                                    <strong>Categoria: </strong>{{ model.activity.category.name }}
                                </span>
                                <span v-if="model.activity.category && model.activity.category.departament">
                                    <br/>
                                    <strong>Departamento: </strong>{{ model.activity.category.departament.name }}
                                </span>
                                <br/><br/>
                                <strong>Observação: </strong>{{ model.note }}
                                <br/><br/>
                                <strong>Usuário: </strong>{{ model.userCreator.name }}
                                <br/>
                                <strong>Data Cadastro: </strong>{{ model.created_at | dateTime }}
                                <span v-if="model.userApproval">
                                    <br/><br/>
                                    <strong>Aprovador: </strong>{{ model.userApproval.name }}
                                    <br/>
                                    <strong>Data Validação: </strong>{{ model.date_approval | dateTime }}
                                    <br/>
                                    <strong>Status: </strong>{{ statuses[model.activity_item_status_id - 1].name }}
                                    <br/>
                                    <strong>Observação: </strong>{{ model.note_approval }}
                                </span>
                            </v-tab-item>
                            <!-- Histórico -->
                            <v-tab-item key="logs">
                                <v-simple-table fixed-header height="300">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Status</th>
                                                <th>Observação</th>
                                                <th>Usuário</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(log, i) in model.logs.filter(i => i.is_change_status)" :key="i">
                                                <td>{{  log.data.activity_item_status_id ? statuses[log.data.activity_item_status_id - 1].name : null }}</td>
                                                <td>{{ log.data.note_approval }}</td>
                                                <td>
                                                    {{ log.user.name }}
                                                    <br/>
                                                    <small>
                                                        {{ log.created_at | dateTime }}
                                                        <br/>
                                                        {{ log.created_at_human }}
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot mt-2>
                                            <tr>
                                                <td colspan="4">
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </v-tab-item>
                        </v-tabs-items>
                       
                        
                    </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ligth green" @click.prevent.stop="dialog = false" >FECHAR</v-btn>
                </v-card-actions>
            </v-card>

    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "SysActivityItemDetails",
    data: (vm) => ({
        dialog: false,
        tab: 0,
        statuses: [
            { id: 0, name: 'Pendente',      color: 'grey',      icon: 'mdi-thumb-up-outline' },
            { id: 1, name: 'Autorizado N1', color: 'primary',   icon: 'mdi-thumb-up-outline' },
            { id: 2, name: 'Autorizado N2', color: 'primary',   icon: 'mdi-thumb-up-outline' },
            { id: 3, name: 'Reprovado',     color: 'error',     icon: 'mdi-thumb-down-outline'   },
            { id: 4, name: 'Realizado',     color: 'purple',    icon: 'mdi-thumb-up-outline'  },
            { id: 5, name: 'Removido',      color: 'error',     icon: 'mdi-account-remove'   },
            { id: 6, name: 'Finalizado',    color: 'error',     icon: 'mdi-calendar-remove'   },
            
        ],
    }),
    props: {
        show: {
            type: Boolean,
            default: false
        },
        model: {

        }
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            this.dialog = val;

            if(val){
                this.tab = 0;
            }
        },

    },
    computed: {
        ...mapState('auth', ['user'])
    },
    methods: {




    }
}
</script>