import { service } from '../../../../helpers/http'

export const ActionIndexPlanType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'indexPlanTypes',
        payload: payload
    })

}

export const ActionDestroyPlanType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'destroyPlanTypes',
        payload: payload
    })
}
export const ActionStorePlanType = ({ dispatch }, payload ) => {
    return service({
        module: 'plan',
        action: 'storePlanTypes',
        payload: payload
    })

}
export const ActionUpdatePlanType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'updatePlanTypes',
        payload: payload
    })
}