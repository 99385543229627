<template>
    <span>
        <v-avatar 
            :width="36"
            :height="36"
            @click.prevent.stop="$emit('click')"
        >
            <v-progress-circular
                v-if="loading && model.url_avatar"
                indeterminate
                color="cyan lighten-4"
            ></v-progress-circular>
            <img
                v-if="model.url_avatar"
                :src="model.url_avatar"
                style="cursor: pointer;"
                @load="loading = false"
            />
            <v-icon v-else color="grey darken-1" class="mdi-36px">{{ icon }}</v-icon>
        </v-avatar>
         <!-- <emc-avatar-show :model="null" :show.sync="show" :source="null" /> -->
    </span>
</template>
<script>

export default {
    name: "EmcAvatarShow",
    data() {
        return {
           show: false,
           loading: true,
        };
    },
    props: {
        model: {
            required: true
        },
        icon: {
            type: String,
            default: 'mdi-account-circle'
        }
    },

    watch: {
      
    },
    methods: {
        photoLoaded(){
           
        }
    }
};
</script>