import { service } from '../../../helpers/http'

export const ActionFindAttributions = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'attributions',
        payload: payload
    });
}

export const ActionCreateAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'createAttribution',
        payload: payload
    });
}

export const ActionUpdateAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'updateAttribution',
        payload: payload
    });
}

export const ActionDeleteAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'deleteAttribution',
        payload: payload
    });
}

export const ActionFindAttributionsToken = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'attributionsToken',
        payload: payload
    });
}

export const ActionFinalizeAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'finalizeAttribution',
        payload: payload
    });
}

export const ActionRemoveAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'attribution',
        action: 'removeAttribution',
        payload: payload
    });
}