import { service } from '../../../../helpers/http'

export const ActionIndexIntegrationItemSchedules = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationItemSchedules',
        payload: payload
    })

}
export const ActionDestroyIntegrationItemSchedule = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyIntegrationItemSchedule',
        payload: payload
    })
}
export const ActionStoreIntegrationItemSchedule = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeIntegrationItemSchedule',
        payload: payload
    })

}
export const ActionUpdateIntegrationItemSchedule = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateIntegrationItemSchedule',
        payload: payload
    })
}

export const ActionIndexIntegrationScheduleTypes = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationScheduleTypes',
        payload: payload
    })
}