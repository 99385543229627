<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Termo</th>
                        <th class="text-center">Data</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>{{ item.term.name }}</td>
                        <td  class="text-center">
                            <span v-if="item.term_signature_status_id == 2">{{  item.updated_at|dateTime }}</span>
                        </td>
                        <td  class="text-center">
                            {{ item.status.name }}
                            <small v-if="item.term_signature_status_id != 1">
                                <br/>
                                <span v-if="item.term.is_required">
                                    <span v-if="item.agree">{{ item.term.label }}</span>
                                </span>
                                <span v-else>
                                    <span v-if="item.option">{{ item.option.label }}</span>
                                </span>
                            </small>
                        </td>
                        <td  class="text-center">
                            <emc-table-icon-download-pdf
                                v-if="item.term_signature_status_id != 1"
                                toolTip="Download do Termo"
                                :route="token ? 'teachers/token/' + token + '/' + item.id + '/pdf-download/teacher-term-signature' : null"
                                type="teacher-term-signature"
                                :token="token" 
                                :item="item"
                                :disabled="item.term_signature_status == 1 || !item.url_document"
                            />
                            <emc-table-icon-edit :tool-tip="item.term_signature_status_id == 1 ? 'Assinar Termo' : 'Visualizar Termo'" :color="item.term_signature_status_id == 1 ? 'success' : 'purple'" @click="itemSelected = item; showForm = true" :icon="item.term_signature_status_id == 1 ? 'mdi-file-document-edit' : 'mdi-text-box-search-outline'" />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <sys-register-term-signature-form :view="itemSelected.term_signature_status_id != 1" :show.sync="showForm" :token="token" :signature-id="itemSelected.id" @onCompleted="getData()" />
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherTermSignature',
    data: function() {
        return {
            items: [],
            loading: true,
            showForm: false,
            itemSelected: { id: 0 },
            renderComponent: true,
        }
    },
    props: {
        teacher: {
            required: true
        },
        token: {
            type:   String,
            default: null
        },
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeacher', 'ActionFindTeacherByToken']),

        getData() {

            if(this.teacher.id === undefined) {
                return []
            }

            this.showForm = false;
            this.items = [];
            this.loading = true;
            var method = this.token ? 'ActionFindTeacherByToken' : 'ActionFindTeacher';

            var payload = {
                with: 'termSignatures.term,termSignatures.status,termSignatures.option',
            }

            if(this.token){
                payload.token = this.token;
            }else{
                payload.id = this.teacher.id;
            }

            this[method](payload)
                .then((r) => {
                    this.items = r.data.termSignatures;
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>