<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        icon="search" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
        :showButton="false"
    >
        <template v-slot:tools>
            <v-tooltip v-if="subHeaderAmbience || subHeaderDateTime || subHeaderResources" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo darken-1" icon @click.prevent.stop="clearFind()" v-bind="attrs" v-on="on"><v-icon>mdi-eraser</v-icon></v-btn>
                </template>
                <span> Limpar Pesquisa </span>
            </v-tooltip>
        </template>    
    
        <template v-slot:form>
            <v-expansion-panels v-model="panel" focusable>
                <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                        <span>
                            <strong class="mb-2">Local e Tipo de Ambiente *</strong>
                            <small v-if="subHeaderAmbience">
                                <br/>
                                {{ subHeaderAmbience }}
                                <br/>
                                {{ search.empty == '1' ? 'Disponível' : 'Ocupado' }}
                            </small>
                        </span>
                        <template v-slot:actions>
                            <v-icon color="primary">
                                mdi-google-classroom
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row class="mt-1">
                                <v-col cols="12" sm="6">
                                    <sys-select-campus
                                        :model.sync="search.campus_id"
                                        :error="errors.campus_id > 0"  
                                        :errors="errors.campus_id"
                                        label="Campus*"
                                        dense
                                        auto-complete
                                        @change="campus = $event"
                                        filter-relation="ambiences"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <sys-select-ambience-type
                                        :model.sync="search.types"
                                        label="Tipo de Ambiente*"
                                        multiple
                                        dense
                                        selectAll
                                        :campus-id="search.campus_id"
                                        :disabled="search.campus_id == null"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <s-text-field
                                        v-model="search.capacity"
                                        :error-messages="errors.capacity"
                                        label="Capacidade"
                                        clearable
                                        inset
                                        text-helper="Capacidade mínima do ambiente. Essa informação é opcional"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-radio-group
                                        v-model="search.empty"
                                        row
                                    >
                                        <v-radio
                                            label="Disponível"
                                            value="1"
                                        />
                                        <v-radio
                                            label="Ocupado"
                                            value="0"
                                            color="purple"
                                        />
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                        <span>
                            <strong>Data e Hora *</strong>
                            <small class="mt-1" v-if="subHeaderDateTime">
                                <br/>
                                {{ subHeaderDateTime }}
                            </small>
                        </span>
                        <template v-slot:actions>
                            <v-icon color="primary">
                                mdi-calendar-clock
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row class="mt-1">
                                <v-col cols="12" sm="3">
                                    <emc-calendar-date-picker2
                                            v-if="renderComponent"
                                            :model.sync="startDate" 
                                            label="Data Início*" 
                                            :errorMessages="errors.start_date"
                                            :show="dialog"
                                            :min="acesso.periodo.start"
                                            :max="acesso.periodo.end"
                                            dense
                                        />     
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-date-picker2
                                            v-if="renderComponent"
                                            :model.sync="search.end_date" 
                                            label="Data Fim*" 
                                            :errorMessages="errors.end_date"
                                            :show="dialog"
                                            :min="acesso.periodo.start"
                                            :max="acesso.periodo.end"
                                            dense
                                        />     
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker 
                                        dense 
                                        :error="!!errors.hour_start" 
                                        :errors="errors.hour_start" 
                                        :time.sync="search.hour_start" 
                                        label="Hora Início*"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker 
                                        dense 
                                        :error="!!errors.hour_end" 
                                        :errors="errors.hour_end" 
                                        :time.sync="search.hour_end" 
                                        label="Hora Fim*"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                        <span>
                            <strong>Recursos {{ selectedIndexResources.length ? '(' + selectedIndexResources.length + ')' : '' }}</strong>
                            <small class="mt-1" v-if="subHeaderResources">
                                <br/>
                                {{ subHeaderResources }}
                            </small>
                        </span>
                        <template v-slot:actions>
                            <v-icon color="primary">
                                mdi-projector
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list>
                        <v-list-item-group
                            v-model="resourcesSelecteds"
                            color="primary"
                        >
                                <emc-util-scroll height="45vh">
                                    <v-list-item
                                        v-for="(item, index) in resources"
                                        :key="index"
                                        @mousedown.prevent.stop
                                        >
                                        <v-list-item-icon @click.prevent.stop="selectResource(index)">
                                            <v-icon :color="isCheckedResource(index) ? 'indigo darken-4' : ''" v-text="isCheckedResource(index) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'" />
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row>
                                                    <v-col cols="12" sm="8">
                                                        {{ item.name_complete }}
                                                        <small v-if="item.type"><br/>{{ item.type.name }}</small>
                                                    </v-col>
                                                    <v-col v-if="!item.shared" cols="12" sm="4">
                                                        <v-text-field
                                                            v-model="item.total"
                                                            label="Quantidade"
                                                            :error="errors.length > 0"  
                                                            :error-messages="errors['resources.' + index + '.total']"
                                                            :disabled="!isCheckedResource(index)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-title>
                                            
                                        </v-list-item-content>
                                    </v-list-item>
                                </emc-util-scroll>
                        </v-list-item-group>
                    </v-list>
                    </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel :disabled="disabledResult">
                    <v-expansion-panel-header disable-icon-rotate>
                        <span>
                            <strong>Resultado da Pesquisa <span v-if="results.length"> ({{ results.length }})</span></strong>
                            <small class="mt-1">
                                
                            </small>
                        </span>
                        <template v-slot:actions>
                            <v-icon color="primary">
                                mdi-format-list-checkbox
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Ambiente</th>
                                        <th>Cap.</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(r, i) in results" :key="i">
                                        <td>{{ r.id }}</td>
                                        <td>{{ r.slug_full }}</td>
                                        <td>{{ r.capacity }}</td>
                                        <td>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="indigo darken-1" icon @click.prevent.stop="selectAmbience(r)" v-bind="attrs" v-on="on"><v-icon>mdi-hand-pointing-left</v-icon></v-btn>
                                                </template>
                                                <span> Selecionar </span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
              <v-expansion-panel v-if="false">
                <v-expansion-panel-header>
                    <strong>Horários</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        <v-simple-checkbox
                                        :indeterminate="indeterminate"
                                        :value="valueCheckAll"
                                            @click.prevent.stop="selectAll()"
                                        color="success"
                                        />
                                    </th>
                                    <th v-for="(day, i) in filteredDays" :key="i" class="text-center">
                                        {{ day.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(hour, i) in filteredHours"
                                    :key="i"
                                >
                                    <td>
                                        <small>{{ hour.start }}</small>
                                        <br/>
                                        <small>{{ hour.end }}</small>
                                    </td>
                                    <td v-for="(day, i) in filteredDays" :key="i" class="text-center">
                                    <v-simple-checkbox
                                        :value="isSelected(day.id, hour)"
                                        @click.prevent.stop="select(day.id, hour)"
                                        color="success"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tfoot>
                                <tr>
                                    <td colspan="3">
                                        
                                    </td>
                                    <td colspan="4"></td>
                                </tr>
                            </tfoot> -->
                        </template>
                    </v-simple-table>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
        <template v-slot:actions>
            <v-spacer/>
            <v-btn :disabled="disabledSearch || loading" :loading="loading" color="primary" @click.prevent.stop="searchAmbiences()">Pesquisar<v-icon right dark>search</v-icon></v-btn>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysAmbienceAllocationSearch",
    data: (vm) => ({
        dialog: false,
        error: false,
        panel: 0,
        valueCheckAll: false,
        resources: [],
        startDate: null,
        resourcesSelecteds: [],
        selectedIndexResources: [],
        renderComponent: true,
        disabledResult: true,
        results: [],
        campus: null,
        search: {
            campus_id: null,
            dates: [],
            types: [],
            empty: '1',
            capacity: null,
            resources: [],
            start_date: null,
            end_date: null,
            hour_start: null,
            hour_end: null,
        },
        days: [
            {id: 1, name: 'Domingo',    visible: false},
            {id: 2, name: 'Segunda',    visible: true},
            {id: 3, name: 'Terça',      visible: true},
            {id: 4, name: 'Quarta',     visible: true},
            {id: 5, name: 'Quinta',     visible: true},
            {id: 6, name: 'Sexta',      visible: true},
            {id: 7, name: 'Sábado',     visible: true},
        ],
        hours: [
            { id: 1, start: '07:00', end: '08:00',  visible: true},
            { id: 2, start: '08:00', end: '09:00',  visible: true},
            { id: 3, start: '09:00', end: '10:00',  visible: true},
            { id: 4, start: '10:00', end: '11:00',  visible: true},
            { id: 5, start: '11:00', end: '12:00',  visible: true},
        ],
        hoursIds: [],
        errors: {},
        message: '',
        loading: false,
        date: {
            start: null,
            end: null
        }
        
    }),
    created(){
       this.loadResources();
    },

    computed: {
      ...mapState('auth', ['acesso']),
      
      filteredDays(){
        return this.days.filter(d => d.visible);
      },

      filteredHours(){
        return this.hours.filter(h => h.visible);
      },
    
      disabledSearch(){
        return !this.search.campus_id 
                || !this.search.types 
                || this.search.types.length == 0 
                || !this.search.start_date
                || !this.search.end_date
                || !this.search.hour_start    
                || !this.search.hour_end    
      },

      indeterminate(){
            
            var total = this.filteredHours.length * this.filteredDays.length;

            if(this.search.dates.length > 0 && this.search.dates.length < total){
                return true;
            }else{
                return false;
            }
      },

      subHeaderAmbience(){

        let header = null;    
        
        if(this.campus && this.search.types && this.search.types.length){
            header =  this.campus.name + ' | ' + this.search.types.map(t => t.name).join(', ');
            if(this.search.capacity){
                header += ' | Capacidade: ' + this.search.capacity;
            }
        }

        return header;

      },

      subHeaderDateTime(){

            let header = null;
        
            if(this.search.start_date && this.search.end_date && this.search.hour_start && this.search.hour_end){
                
                header = this.$options.filters.date(this.search.start_date);

                if(this.search.start_date != this.search.end_date){
                    header += ' - ' + this.$options.filters.date(this.search.end_date);
                }

                header += ' | ' + this.search.hour_start + ' - ' + this.search.hour_end;


            }
            
            return header;
        
        },

        subHeaderResources(){
            
            var text = null;
            
            this.selectedIndexResources.forEach(i => {
                
                if(text === null){
                    text = '';
                }
                
                if(text != ''){
                    text += ', ';
                }
                
                text += this.resources[i].name;
            });

            return text;
        },

    },

    watch: {
        indeterminate(newValue) {
            if(newValue) {
                this.valueCheckAll = false;
            }
        },
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.startDate){
                    
                    let time = this.getDateTime();

                    if(time.date < this.acesso.periodo.start){
                        this.startDate = this.acesso.periodo.start;
                    }else{
                        this.startDate = time.date;
                    }
                }

            }
        },

        startDate(val){

            this.search.start_date = val;

            if(!this.search.end_date || this.search.end_date < val){
                this.search.end_date = val;
            }

            this.forceRerender();

        },

    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Pesquisa de Ambientes"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('roomAllocation', ['ActionIndexAmbiencesEmpty', 'ActionIndexAmbiencesUsed']),
        ...mapActions('resource', ['ActionFindResources']),

        loadResources(){

            var payload = {
                find_columns: {
                    active: 1
                },
                with: 'type'
            }
            
            this.ActionFindResources(payload)
                .then((res) => {
                    this.resources = res.data;
                    this.resources.forEach(r => {
                        if(r.shared){
                            r.total = 1;
                        }else if(this.search.capacity_min){
                            r.total = this.search.capacity_min;
                        }else{
                             r.total = 1;
                        }
                    });
                })
                .finally(() => {
                    
                })
        },

        selectAmbience(ambience){

            this.$emit('select', { start: this.search.start_date, ambience: ambience });
            this.dialog = false;

        },

        selectAll(){

            if(this.indeterminate || this.valueCheckAll){
                
                this.search.dates = [];
                this.valueCheckAll = false;
                
            }else{
                this.filteredHours.forEach(hour => {
                    this.filteredDays.forEach(day => {
                        this.select(day.id, hour, false);
                    });
                });

                this.valueCheckAll = true;
            }
        },

        select(day, hour, updateStatus = true){
            
            var key = day + '|' + hour.id;
            
            var find = this.search.dates.find(d => d.key == key);

            if(find){
                var index = this.search.dates.indexOf(find);
                this.search.dates.splice(index, 1);
            }else{
                this.search.dates.push({
                    key:            key,
                    day_of_week_id: day,
                    hour_start:     hour.start,
                    hour_end:       hour.end,
                    date_start:     this.date.start,
                    date_end:       this.date.end,
                });          
            }

            if(updateStatus){
                if(this.search.dates.length == (this.filteredHours.length * this.filteredDays.length)){
                    this.valueCheckAll = true;
                }else if(this.search.dates.length == 0){
                    this.valueCheckAll = false;
                }
            }
        },

        isSelected(day, hour){
            var key = day + '|' + hour.id;
            return this.search.dates.find(d => d.key == key) != null;
        },

        clearFind(){
            
            this.results = [];
            this.campus = null;
            this.selectedIndexResources = [];
            this.search = {
                campus_id: null,
                dates: [],
                types: [],
                empty: '1',
                capacity: null,
                resources: [],
                start_date: null,
                end_date: null,
                hour_start: null,
                hour_end: null,
            };
            this.disabledResult = true;
            this.startDate = null;
            this.panel = 0;

            this.forceRerender();
        },
        
        searchAmbiences()
        {
            this.error = false;
            this.errors = [];
            this.message = '';
            this.loading = true;

            let method = this.search.empty == '1' ? 'ActionIndexAmbiencesEmpty' : 'ActionIndexAmbiencesUsed';

            this.search.resources = [];

            for (let i = 0; i < this.resources.length; i++) {
                
                if(!this.isCheckedResource(i)){
                    continue;
                }
                
                var resource = this.resources[i];
                this.search.resources.push({
                    id:     resource.id,
                    total:  resource.total,
                });
                
            }

           let payload = {
                campus_id:      this.search.campus_id,
                start_date:     this.search.start_date,
                end_date:       this.search.end_date,
                hour_start:     this.search.hour_start,
                hour_end:       this.search.hour_end,
                types:          this.search.types.map(o => o.id),
                dates:          this.search.dates,
                capacity:       this.search.capacity,
                resources:      this.search.resources,
                onlyExternal:   0,
            };

            this[method](payload)
            .then((res) => {
                this.results = res.data;
                this.disabledResult = false;
                this.panel = 3;
            })
            .catch((error) =>{
                this.error      = true;
                this.errors     = error.errors;
                this.message    = error.message;
            })
            .finally(() => {
                this.loading = false;
            });
            
        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
        },

        selectResource(index){
            
            if(this.selectedIndexResources.includes(index)){
                this.selectedIndexResources.splice(this.selectedIndexResources.indexOf(index), 1);
            }else{
                this.selectedIndexResources.push(index);
            }
            
        },

        isCheckedResource(index){
            return this.selectedIndexResources.includes(index);
        },

        
    }
}
</script>