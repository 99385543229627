<template>
    <emc-table-icon 
        :color="color"
        :icon="icon"
        :disabled="disabled"
        :loading="loading"
        :tool-tip="toolTip"
        @click="$emit('click')"
    />
</template>
<script>
export default {
    name: "EmcTableIconEdit",
    data () {
        return {
            
        }
    },
    props: {
        color: {
            type: String,
            default: "blue accent-4"
        },
        icon: {
            type: String,
            default: "mdi-pencil-outline"
        },
        toolTip: {
            type: String,
            default: "Editar"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
}
</script>
