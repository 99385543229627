import Vue from "vue"

Vue.filter("date", value => {
  
    if (!value) return null

    const [year, month, day] = value.split('-')
    return `${day}/${month}/${year}`
    
  
})

Vue.filter("dateTime", value => {
    
  if(value){
        var data = new Date(value);
        return data.toLocaleDateString() + ' ' + data.toLocaleTimeString();
    }else{
        return '';
    }
    
})

Vue.filter("boolean", value => {
    
    return value ? 'Sim' : 'Não';
      
})

Vue.filter("time", value => {
    
    return value;
      
})

Vue.filter("money", value => {
    
    if(value){
        return parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }else{
        return null;
    }
      
})

Vue.filter("teacherName", value => {
    
    if(value.teacher && !value.in_hiring){
        return value.teacher.name;
    }else if(value.in_hiring){
        return "A Contratar";
    }else{
        return "";
    }
      
})

Vue.filter("capitalize", value => {
    
    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
      
})

Vue.filter("percent", value => {
    
    if (!value) return '';
    return Number(value).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
      
})