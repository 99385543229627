export default [
    {
        path: '/branches',
        name: 'branches',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Filiais',
            icon: 'mdi-source-branch',
            breadcrumbs: [
                { text: 'Lista de Filiais', disabled: true }
            ]
        }
    }
]