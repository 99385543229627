import { service } from '../../../../helpers/http'

export const ActionIndexUserSettings = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'indexUserSettings', 
		payload: payload 
	})
}

export const ActionCreateUserSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'createUserSetting', 
		payload: payload 
	})
}

export const ActionUpdateUserSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'updateUserSetting', 
		payload: payload 
	})
}

export const ActionDestroyUserSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'destroyUserSetting', 
		payload: payload 
	})
}
