<template>
    <div>
        <emc-message-base
            ref="message"
            :items="items" 
            :title="titleFormated" 
            :show.sync="dialog" 
            :loading="loading"
            :loadingUpdate="loadingUpdate"
            @onSend="sendMessage"
            @onDelete="deleteItem"
            @onUpdate="updateMessage"
            @getData="getData(false)"
            :sent="sent"
        >
            <template v-slot:tools>

                <s-switch class="mt-5" text-helper='Registro no nível da oferta' v-model="offerLevel" label="Oferta"/>

            </template>
        </emc-message-base>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "EmcMessageOfferItem",
    data: () => ({
        dialog: false,
        loading: false,
        loadingUpdate: false,
        showValidate: false,
        sent: false,
        offerLevel: false,
        items:[],
    }),
     props: {
        title: {
            type: String,
            default: "Formulário"
        },
        show: {
            type: Boolean,
            default: false
        },
        model: {

        }
    },

    mounted(){
       
    },

    computed: {
        
        titleFormated () {
            
            if(this.model){
                 
                let title = this.title + ' [ ' + this.model.base_group_name + ' | ' + this.model.base_group_code + ' ]';

                 if(this.offerLevel){
                    title += ' - ID: ' + this.model.id;
                 }
                
                return title;

            }else{
                return this.title;
            }
            
        },
    },

    watch: {
        
        offerLevel(val){
            this.getData();
        },
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.items = [];
                this.offerLevel = false;
                this.getData();
            }

        },
    },

    methods: {
        ...mapActions('grid', ['ActionIndexOfferItemMessages', 'ActionOfferItemMessageCreate', 'ActionOfferItemMessageUpdate', 'ActionOfferItemMessageDelete']),
        
        getData(load = true){

            if(!this.model || !this.model.id){
                return;
            }

            this.loading = load;
            
            var payload = {
                column_order: 'created_at',
                direction: 'asc',
                find_columns: {
                    base_group_id: this.model.base_group_id
                },
                with: 'user'
            }

            if(this.offerLevel){
                payload.find_columns.offer_item_id = this.model.id;
            }

            this.ActionIndexOfferItemMessages(payload)
                .then((res) => {
                    this.items = res.data;
                })
                .finally(()=>{
                    
                    if(this.loading){
                        this.$refs.message.setScroll();
                    }
                    
                    this.loading = false;
                    
                });

        },

        sendMessage(message, auto = false){

            this.loading = true;
            this.sent = false;
            
            var payload = {
               base_group_id:  this.model.base_group_id,
               message: message,
               auto: auto
            }

            if(this.offerLevel){
                payload.offer_item_id = this.model.id;
            }

            this.ActionOfferItemMessageCreate(payload)
                .then((res) => {
                    
                   this.getData();
                   this.modelMessage = null;
                   this.sent = true;
                   

                })
                .finally(()=>{
                    this.showValidate = false;
                });

        },

        updateMessage(item){

            this.loadingUpdate = true;
            this.sent = false;

            this.ActionOfferItemMessageUpdate(item)
                .then((res) => {
                    
                   this.sent = true;

                })
                .finally(()=>{
                     this.loadingUpdate = false;
                });

        },

        deleteItem(item){

            this.loading = true;
            this.sent = false;
            
            this.ActionOfferItemMessageDelete({ id: item.id })
                .then((res) => {
                    
                   this.getData();
                   this.sent = true;
                })
                .finally(()=>{
                    
                });

        }
    },
   
}
</script>