import { service } from '../../../helpers/http'

export const ActionFindResourceTypes = ({ dispatch }, payload) => {

    return service({
        module: 'resourceType',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateResourceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'resourceType',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateResourceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'resourceType',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteResourceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'resourceType',
        action: 'delete',
        payload: payload
    })
    
}

