<template>
    <v-col :cols="cols" :sm="sm" :md="md">
        <v-textarea
          :outlined="outlined"
          :auto-grow="autoGrow"
          :label="label"
          v-model="model"
          :hide-details="hideDetails"
          :error="error"
          :error-messages="errorMessages"
          :placeholder="placeholder"
          :disabled="disabled"
          :background-color="disabled ? `grey lighten-2` : ``"
        ></v-textarea>
    </v-col>
</template>

<script>
export default {
    name: 'EmcFormTextArea',
    props: {
        value: [String, Number],
        sm: { type: String, default: "6" },
        md: { type: String, default: "4" },
        cols: { type: String, default: "12" },
        label: { type: String, required: false },
        placeholder: { type: String, required: false },
        outlined: { type: Boolean, required: false },
        hideDetails: { type: [String, Boolean], default: "auto"},
        errorMessages: { type: [Object, Array], required: false },
        error: { type: Boolean, required: false },
        disabled: { type: Boolean, default: false },
        autoGrow: { type: Boolean, default: false }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    }
}
</script>