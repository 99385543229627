<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'" persistent >
        <v-card 
                outlined
                height="75vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="40vh" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Filtros
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-offer-item-status
                                :model.sync="statuses"
                                label="Status Oferta"
                                multiple
                                dense
                                include-null
                            />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-base-group-status
                                    :model.sync="statusBaseGroups"
                                    label="Status Composição"
                                    multiple
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-region
                                :model.sync="regions"
                                :showAdd=false
                                label="Regional"
                                multiple
                                dense
                            />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-brand
                                :model.sync="brands"
                                :showAdd=false
                                label="Marca"
                                multiple
                                dense
                            />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-campus
                                    :model.sync="campuses"
                                    label="Campus"
                                    dense
                                    multiple
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-big-occupation-area
                                    :model.sync="bigAreas"
                                    label="Grande Área"
                                    multiple
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <sys-select-occupation-area
                                    :model.sync="areas"
                                    label="Área"
                                    multiple
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <sys-select-join-category
                                    :model.sync="joinTypes"
                                    label="Tipo de Junção"
                                    multiple
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <s-switch 
                                    text-helper="Exibir composições com registros no formulário"
                                    inset 
                                    v-model="hasMessage"
                                    label="Registros de Formulário"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <s-switch 
                                    text-helper="Exibir apenas meus registros" 
                                    inset 
                                    v-model="myRecords"
                                    label="Meus registros"
                                />
                            </v-col>
                        </v-row>
                </v-container>
            </v-card-text>

                <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                    :disabled="disableButton"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysGridOfferFilter",
    data: (vm) => ({
        dialog: false,
        loading: false,
        items: [],
        regions: [],
        brands: [],
        bigAreas: [],
        areas: [],
        campuses: [],
        myRecords: false,
        joinTypes: [],
        statuses: [],
        statusBaseGroups: [],
        hasMessage: false,
    }),
    mounted() {
       
    },
    computed: {
        ...mapState('auth', ['user']),
        disableButton (){
            
            return !this.myRecords &&
                    !this.hasMessage &&
                    !this.bigAreas.length && 
                    !this.areas.length && 
                    !this.regions.length && 
                    !this.brands.length && 
                    !this.campuses.length && 
                    !this.joinTypes.length && 
                    !this.joinTypes.length &&
                    !this.statuses.length &&
                    !this.statusBaseGroups.length;

        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.updating = false;
            }
        },
        

    },
    methods: {

       filter(){

            var payload = {};

            if(this.myRecords){
                payload.find_columns = {
                    user_id: this.user.id,
                }
            }

            if(this.hasMessage){
                
                if(!payload.find_columns){
                    payload.find_columns = {};
                }
                
                payload.find_columns.has_message = 1;
                    
            }
        
            if(this.joinTypes && this.joinTypes.length > 0){
                
                let ids = this.joinTypes.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }
                
                payload.find_columns.join_category_id = ids;
            }

            if(this.brands && this.brands.length > 0){
                
                let ids = this.brands.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }
                
                payload.find_columns.brand_id = ids;
            }

            if(this.campuses && this.campuses.length > 0){
                
                let ids = this.campuses.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.campus_id = ids;
                
                
            }

            if(this.regions && this.regions.length > 0){
                
                let ids = this.regions.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.region_id = ids;
                
                
            }

            if(this.bigAreas && this.bigAreas.length > 0){
                
                let ids = this.bigAreas.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.big_occupation_area_id = ids;
                
                
            }

            if(this.areas && this.areas.length > 0){
                
                let ids = this.areas.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.occupation_area_id = ids;
                
                
            }

            if(this.statuses && this.statuses.length > 0){
                
                let hasNull = this.statuses.filter(i => i.id === 0);
                let status = this.statuses.filter(i => i.id !== 0);
                
                let ids = status.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                let relation = {
                    name:   'offerItemStatus',
                    key:    'id', 
                    value:  ids,
                    clause: 'whereIn'
                }

                if(hasNull && hasNull.length > 0){
                    relation.includeNull = true;

                    if(status.length == 0){
                        relation.value = 0;
                    }

                }
                
                payload.filter_relations.push(relation);
            }

            if(this.statusBaseGroups && this.statusBaseGroups.length > 0){
                
                let ids = this.statusBaseGroups.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }
                
                payload.find_columns.base_group_status_id = ids;
            }

            this.$emit('filter', payload);

       },

       clearFilter(){

            this.bigAreas           = [];
            this.areas              = [];
            this.campuses           = [];
            this.regions            = [];
            this.brands             = [];
            this.joinTypes          = [];
            this.statuses           = [];
            this.statusBaseGroups   = [];
            this.myRecords          = false;
            this.hasMessage         = false;

            this.$emit('filter', null);

       },

    }
}
</script>