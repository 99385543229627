<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="modelText"
            :label="label"
            append-icon="access_time"
            readonly
            v-bind="attrs"
            v-on="on"
            :error="error"
            :error-messages="errors"
            :dense="dense"
            :clearable="clearable"
            :disabled="disabled"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="modal"
          v-model="modelHour"
          full-width
          format="24hr"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
          <v-btn text color="primary" @click.prevent.stop="setTime(modelHour)">OK</v-btn>
        </v-time-picker>
      </v-dialog>
</template>

<script>
export default {
    name: "EmcCalendarTimePicker",
    data () {
      return {
        modelText: null,
        modelHour: null,
        modal: false,
      }
    },
    created() {
      this.modelText = this.time;
    },
    watch: {
      
      time(val){
        this.modelText = val;
      },

      model(val){

      },

      modelText(val){
        this.setTime(val);
      },

      modal(val){
        
        if(val){
          this.modelHour = this.modelText;
        }else{
          this.modelHour = '';
        }

      }

    },
    props: {
        time: {
            default: ''
        },
        label: {
            type: String,
            required: true
        },
        error: {
          type: Boolean,
          default: false
        },
        errors: {
          type: Array,
          default: null
        },
        dense: {
          type: Boolean,
          default: false
        },
        clearable: {
          type: Boolean,
          default: false
        },
        disabled: {
          type: Boolean,
          default: false
        }
    },
    methods: {
      
      setTime(val){
          
          let text = null;

          if(val){
             text = val.length > 5 ? val.substring(0,5) : val;
          }
        
          this.$refs.dialog.save(text);
          this.modelText = text;
          this.$emit('update:time', text);
          
          if(text && text != ''){
            this.$emit('onSelect', text);
          }
          
      }
    }
  }
</script>