export default [
    {
        path: '/teachers',
        name: 'teachers',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Professores',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Professores', disabled: true, href: '/' },
            ],
        }
    },
    {
        path: '/teacher-mail-confirm',
        name: 'teacher-mail-confirm',
        component: () => import ('./pages/TeacherMailConfirm')
    },
    {
        path: '/work-load-confirmations',
        name: 'work-load-confirmations',
        component: () => import ('./pages/WorkLoadConfirmationRegister')
    }
]