import { services as auth } from '../modules/auth'
import { services as indication } from '../modules/indication'
import { services as role } from '../modules/role'
import { services as availability } from '../modules/availability'
import { services as teacher } from '../modules/teacher'
import { services as user } from '../modules/user'
import { services as institution } from '../modules/institution'
import { services as schedule } from '../modules/schedule'
import { services as worktime } from '../modules/worktime'
import { services as account } from '../modules/account'
import { services as course } from '../modules/course'
import { services as courseLevel } from '../modules/course_level'
import { services as occupationArea } from '../modules/occupation_area'
import { services as category } from '../modules/category'
import { services as discipline } from '../modules/discipline'
import { services as campus } from '../modules/campus'
import { services as group } from '../modules/group'
import { services as ambience } from '../modules/ambience'
import { services as hour } from '../modules/hour'
import { services as grid } from '../modules/grid'
import { services as join } from '../modules/join'
import { services as workLoadType } from '../modules/work_load_type'
import { services as ambienceType } from '../modules/ambience_type'
import { services as block } from '../modules/block'
import { services as floor } from '../modules/floor'
import { services as categoryAmbience } from '../modules/category_ambience'
import { services as menu } from '../modules/menu'
import { services as resource } from '../modules/resource'
import { services as mail } from '../modules/mail'
import { services as educationalGroup } from '../modules/educational_group'
import { services as brand } from '../modules/brand'
import { services as controlPanel } from '../modules/control_panel'
import { services as categoryGroup } from '../modules/category_group'
import { services as calendar } from '../modules/calendar'
import { services as eventItem } from '../modules/event_item'
import { services as eventType } from '../modules/event_type'
import { services as eventCategory } from '../modules/event_category'
import { services as city } from '../modules/city'
import { services as roomAllocation } from '../modules/room_allocation'
import { services as curriculumCategory } from '../modules/curriculum_category'
import { services as curriculum } from '../modules/curriculum'
import { services as equivalence } from '../modules/equivalence'
import { services as baseGroup } from '../modules/base_group'
import { services as disciplineType } from '../modules/discipline_type'
import { services as activity } from '../modules/activity'
import { services as activityItem } from '../modules/activity_item'
import { services as license } from '../modules/license'
import { services as teacherLicense } from '../modules/teacher_license'
import { services as attribution } from '../modules/attribution'
import { services as resourceType } from '../modules/resource_type'
import { services as dynamicGeneration } from '../modules/dynamic_generation'
import { services as hourGroup } from '../modules/hour_group'
import { services as chart } from '../modules/chart'
import { services as budgetType } from '../modules/budget_type'
import { services as budget } from '../modules/budget'
import { services as paymentExport } from '../modules/payment_export'
import { services as hourGroupDate } from '../modules/hour_group_date'
import { services as bank } from '../modules/bank'
import { services as bankData } from '../modules/bank_data'
import { services as timeSheet } from '../modules/time_sheet'
import { services as faq } from '../modules/faq'
import { services as faqCategory } from '../modules/faq_category'
import { services as courseInfo } from '../modules/course_info'
import { services as country } from '../modules/country'
import { services as state } from '../modules/state'
import { services as ambienceOccupation } from '../modules/ambience_occupation'
import { services as teacherPosition } from '../modules/teacher_position'
import { services as teacherContract } from '../modules/teacher_contract'
import { services as contact } from '../modules/contact'
import { services as logAccessApi } from '../modules/log_access_api'
import { services as importLog } from '../modules/import_log'
import { services as plan } from '../modules/plan'
import { services as integration } from '../modules/integration'
import { services as eventCalendarBase } from '../modules/event_calendar_base'
import { services as term } from '../modules/term'
import { services as finance } from '../modules/management/finance'
import { services as message } from '../modules/message'
import { services as ticket } from '../modules/management/ticket'
import { services as address } from '../modules/address'

export default {
    auth,
    indication,
    role,
    teacher,
    availability,
    user,
    institution,
    schedule,
    worktime,
    account,
    course,
    courseLevel,
    occupationArea,
    category,
    discipline,
    campus,
    group,
    ambience,
    hour,
    grid,
    join,
    workLoadType,
    ambienceType,
    block,
    floor,
    categoryAmbience,
    menu,
    resource,
    mail,
    educationalGroup,
    brand,
    controlPanel,
    categoryGroup,
    calendar,
    eventItem,
    eventType,
    eventCategory,
    city,
    roomAllocation,
    curriculumCategory,
    curriculum,
    equivalence,
    baseGroup,
    disciplineType,
    activity,
    activityItem,
    license,
    teacherLicense,
    attribution,
    resourceType,
    dynamicGeneration,
    hourGroup,
    chart,
    budgetType,
    budget,
    paymentExport,
    hourGroupDate,
    bank,
    bankData,
    timeSheet,
    faq,
    faqCategory,
    courseInfo,
    country,
    state,
    ambienceOccupation,
    teacherPosition,
    teacherContract,
    contact,
    logAccessApi,
    importLog,
    plan,
    integration,
    eventCalendarBase,
    term,
    finance,
    message,
    ticket,
    address
}