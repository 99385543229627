export default [
    {
        path: '/departaments',
        name: 'departaments',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Departamentos',
            icon: 'mdi-office-building',
            breadcrumbs: [
                { text: 'Instituição', disabled: true, href: '/' },
                { text: 'Departamentos', disabled: true, href: '/' }
            ],
        }
    },
]