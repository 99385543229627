var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sys-register-base',{attrs:{"loading":_vm.loading,"model":_vm.modelBase,"title":_vm.title,"icon":_vm.icon,"show":_vm.dialog,"maxWidth":"98%","actions":false},on:{"update:model":function($event){_vm.modelBase=$event},"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('emc-excel-import-icon',{attrs:{"param":"payment_export_items","item-id":_vm.model.id},on:{"onCompleted":function($event){return _vm.getData()}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.modelItem = {}; _vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Novo registro ")])])]},proxy:true},{key:"form",fn:function(){return [_c('main-container',{attrs:{"showToolBar":false,"icon":"mdi-account-cash","title":"Folha de Pagamento"}},[_c('emc-table-items',{attrs:{"headers":_vm.headers.filter(function (h) { return (!h.types.length || h.types.includes(_vm.model.payment_type_id)); }),"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.modelItem,"loading":_vm.loading,"showActions":false},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.modelItem=$event},"update:item-edit":function($event){_vm.modelItem=$event}},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.course)?_c('span',[_vm._v(_vm._s(item.course.name))]):_vm._e(),(item.group)?_c('small',[_c('br'),_vm._v(_vm._s(item.group.name))]):_vm._e(),(item.discipline)?_c('small',[_c('br'),_vm._v(_vm._s(item.discipline.name))]):_vm._e(),(item.join_id)?_c('small',[_c('br'),_vm._v("Junção: "+_vm._s(item.join_id)+" | Principal: "+_vm._s(item.main ? 'Sim' : 'Não'))]):_vm._e()]}},{key:"item.teacher",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.teacher ? item.teacher.name : null)+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("date")(item.start))+" - "+_vm._s(_vm._f("date")(item.end)))]),(item.company)?_c('small',[_c('br'),_vm._v(_vm._s(item.company.initials))]):_vm._e(),(item.registration)?_c('small',[_c('br'),_vm._v(_vm._s(item.registration))]):_vm._e()]}},{key:"item.value_formated",fn:function(ref){
var item = ref.item;
return [(item.paymentExport.payment_export_prorated_type_id == 1)?_c('span',[(item.value_formated == parseFloat(item.value_prorated_grids))?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.value_prorated_grids))+" ")]):_c('span',[_vm._v(" CH: "+_vm._s(item.value_formated)+" | RT: "+_vm._s(parseFloat(item.value_prorated_grids))+" ")])]):(item.paymentExport.payment_export_prorated_type_id == 2)?_c('span',[(item.value_formated == parseFloat(item.value_prorated_students))?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.value_prorated_students))+" ")]):_c('span',[_vm._v(" CH: "+_vm._s(item.value_formated)+" | RT: "+_vm._s(parseFloat(item.value_prorated_students))+" ")])]):(item.paymentExport.payment_export_prorated_type_id == 3)?_c('span',[_vm._v(" "+_vm._s(parseFloat(item.value))+" ")]):_vm._e(),_c('small',[_c('br'),_vm._v(" "+_vm._s(item.valueType.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.periodicity.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.name)+" "),(item.payment_export_item_sum_id)?_c('small',[_c('br'),_vm._v("Núm: "+_vm._s(item.payment_export_item_sum_id))]):_vm._e()]}},{key:"item.register",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("dateTime")(item.updated_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('emc-table-icon',{attrs:{"tool-tip":item.payment_export_item_status_id == 4 ? 'Ver Detalhes' : 'Editar',"color":item.payment_export_item_status_id == 4 ? 'success' : 'blue accent-4',"icon":item.payment_export_item_status_id == 4 ? 'mdi-file-eye-outline' : 'mdi-pencil-outline'},on:{"click":function($event){_vm.modelItem = item}}}),(item.payment_export_item_status_id != 4)?_c('emc-table-icon-delete',{on:{"click":function($event){_vm.itemDelete = item}}}):_vm._e()]}}])}),_c('sys-payment-export-register-item',{attrs:{"payment-export":_vm.model,"item":_vm.modelItem,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('emc-alert-modal',{attrs:{"text":"Deseja excluir o item?","disabled":_vm.deleting,"show":_vm.showDelete},on:{"update:show":function($event){_vm.showDelete=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])})],1),_c('emc-alert-snackbar',{attrs:{"show":!!_vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }