export default [
    {
        path: '/worktime',
        name: 'worktime',
        component: () => import ('./pages/SelecionarPeriodo')
    },
    {
        path: '/worktime-register',
        name: 'worktimeRegister',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Período letivo',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Período letivo', disabled: true, href: '/' },
            ],
        }
    }
]