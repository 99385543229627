<template>
  <div>
    <sys-select-base
        :model.sync="selected"
        :items="items"
        :loading="loading"
        :label="label"
        :error="error"
        :errors="errors"
        :showClear="showClear"
        :showAdd="showAdd"
        :hint="hint"
        persistent-hint
    />
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'SysSelectIntegration',
  data: () => ({
    items: [],
    loading: false,
    selected: null
  }),
  created() {
    this.getItems();
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
    },
    label: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: false
    },
    model: {}
  },
  watch: {

    selected(val){
      this.$emit('update:model', val);
    },

    model(val){
      this.selected = val;
    },
  },
  methods: {
    ...mapActions('integration', ['ActionIndexIntegration']),
    async getItems() {
      this.loading = true
      let params = {
        column_order: 'name'
      }
      await this.ActionIndexIntegration(params)
          .then((res) => {
            this.items = res.data;
            this.selected = this.model;
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>