var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sys-register-base',{attrs:{"loading":_vm.loading,"model":_vm.model,"title":_vm.title,"icon":"mdi-book-open-page-variant","show":_vm.dialog,"maxWidth":"100%"},on:{"onSave":_vm.saveAll,"update:model":function($event){_vm.model=$event},"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.renderComponent)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.model.items,"headers":_vm.headers.filter(function (h) { return h.visible; }),"sort-by":"calories","item-key":"index","hide-default-footer":true,"items-per-page":-1,"fixed-header":"","height":"400"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,177284215)},[_c('span',[_vm._v("Adicionar Carga Horária")])])],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker2',{attrs:{"model":item.date,"label":"","error":_vm.errors.length > 0,"error-messages":_vm.errors['items.' + index + '.date'],"show":_vm.dialog,"min":_vm.acesso.periodo.classes_start,"max":_vm.acesso.periodo.classes_end,"dense":""},on:{"update:model":function($event){return _vm.$set(item, "date", $event)}}})]}},_vm._l((_vm.periods),function(period,i){return {key:("item." + period),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-time-picker',{key:i,attrs:{"dense":"","error":!!_vm.errors[period],"errors":_vm.errors['items.' + index + '.' + period],"time":item[period],"label":"","clearable":""},on:{"update:time":function($event){return _vm.$set(item, period, $event)}}})]}}}),{key:"item.description",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['items.' + index + '.description'],"dense":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},_vm._l((_vm.markings),function(mark,i){return {key:("item." + mark),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-time-picker',{key:i,attrs:{"dense":"","error":!!_vm.errors[mark],"errors":_vm.errors['items.' + index + '.' + mark],"time":item[mark],"label":"","clearable":""},on:{"update:time":function($event){return _vm.$set(item, mark, $event)}}})]}}}),{key:"item.occurrence_status_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('sys-select-occurrence-status',{attrs:{"model":item.occurrence_status_id,"error":_vm.errors.length > 0,"errors":_vm.errors['items.' + index + '.occurrence_status_id'],"label":""},on:{"update:model":function($event){return _vm.$set(item, "occurrence_status_id", $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"loading":index == _vm.indexItem && _vm.loadingUpdate,"small":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":"","color":"success","dense":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.updateItem(item, index)}}},[_vm._v(" mdi-content-save-outline ")])],1),_c('v-btn',{attrs:{"loading":index == _vm.indexItem && _vm.loadingDelete,"small":"","icon":"","disabled":""}},[_c('v-icon',{attrs:{"small":"","color":"error","dense":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item, index)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}):_vm._e()],1)],1)],1),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }