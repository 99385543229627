import { default as region } from './region/routes'
import { default as departament } from './departament/routes'
import { default as establishment } from './establishment/routes'

export default [
    ...region,
    ...departament,
    ...establishment,
    {
        path: '/institutions',
        name: 'InstitutionIndex',
        component: () => import ('./pages/Index'),
    }
]