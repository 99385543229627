<template>
    <sys-register-base 
        :loading="loadingSave" 
        @onSave="save" 
        :model.sync="model" 
        :title="event.id ? event.name : 'Novo Agendamento'" 
        maxWidth="50%"
        icon="mdi-calendar-plus" 
        v-bind:show.sync="dialog"
        text-button-save="Salvar"
    >
            <template v-slot:form>
                <v-tabs class="ma-0" v-model="tab">
                    <v-tab key="main">Agendamento</v-tab>
                    <v-tab key="resources">Recursos</v-tab>
                </v-tabs>
                <v-tabs-items  v-model="tab">
                    <v-tab-item key="main">
                        <v-container>    
                            <v-row v-if="error && message && message.includes('Choque de Horário em') ">
                                <v-col cols="12" sm="12">
                                    <v-alert type="error">
                                        {{ message }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="7">
                                    <s-text-field
                                        v-model="event.title"
                                        :error-messages="errors.title"
                                        label="Título*"
                                        autofocus
                                    />
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-radio-group row v-model="type">
                                        <v-radio v-if="type == 1" color="success" label="Aula" :value="1" />
                                        <v-radio color="success" label="Interno" :value="2" />
                                        <v-radio color="primary" label="Externo" :value="3" />
                                        <v-radio v-if="type == 4" color="primary" label="Externo - OnLine" :value="4" />
                                        <emc-info-help title="Informações">
                                        <template v-slot:text>
                                            <strong>Agendamento Interno</strong>
                                            <p>Agendamento realizado para um usuário da organização e o organizador possui cadastro no SysClass</p>
                                            <strong>Agendamento Externo</strong>
                                            <p>Agendamento realizado para um usuário fora da organização ou o organizador não é usuário do SysClass</p>
                                        </template>
                                    </emc-info-help>
                                    </v-radio-group>
                                    
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-user 
                                        v-if="type == 2"
                                        :model.sync="event.organizer_user_id"
                                        :data.sync="userOrganizer"
                                        :error="errors.length > 0"
                                        :errors="errors.organizer_user_id"
                                        label="Organizador*"
                                        autoComplete
                                        dense
                                    />
                                    <s-text-field
                                        v-else-if="type == 3 || type == 4"
                                        v-model="event.organizer"
                                        :error-messages="errors.organizer"
                                        label="Organizador*"
                                        autofocus
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="event.details"
                                        outlined
                                        name="input-7-4"
                                        label="Detalhes"
                                        :auto-grow="false"
                                        :value="event.details"
                                        rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                                    
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker2 
                                            :model.sync="startDate" 
                                            label="Data Início*" 
                                            :errorMessages="errors.start_date"
                                            :show="dialog"
                                            :min="acesso.periodo.start"
                                            :max="acesso.periodo.end"
                                        />     
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker2 
                                            :model.sync="endDate" 
                                            label="Data Fim*" 
                                            :errorMessages="errors.end_date"
                                            :show="dialog"
                                            :min="acesso.periodo.start"
                                            :max="acesso.periodo.end"
                                        />     
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker 
                                        dense 
                                        :error="!!errors.hour_start" 
                                        :errors="errors.hour_start" 
                                        :time.sync="event.hour_start" 
                                        label="Hora Início*"
                                    />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <emc-calendar-time-picker 
                                        dense 
                                        :error="!!errors.hour_end" 
                                        :errors="errors.hour_end" 
                                        :time.sync="event.hour_end" 
                                        label="Hora Fim*"
                                    />
                                </v-col>
                                <v-col v-if="showRecurrence" cols="12" sm="6">
                                    <v-select
                                        v-model="event.daysOfWeek"
                                        item-value="id"
                                        item-text="name"
                                        :items="days.filter(d => d.visible)"
                                        label="Recorrência"
                                        dense
                                        multiple
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="resources">
                        <sys-ambience-allocation-resources :ambience-id="ambienceId" :model="event" :errors="errors" />
                    </v-tab-item>
                </v-tabs-items>
            <emc-alert-modal 
              title="Exclusão" text="Deseja excluir o evento?" 
              :show.sync="showDelete"
            >
              <template v-slot:actions>
                  <v-btn :disabled="loadingSave || loadingDelete" :loading="loadingDelete" color="error" @click.prevent.stop="deleteItem()">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
              </template>
            </emc-alert-modal>

        </template>

</sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterEventCalendar",
    data: (vm) => ({
        event: {},
        dialog: false,
        showDelete: false,
        userOrganizer: null,
        tab: 0,
        startDate: null,
        endDate: null,
        showRecurrence: false,
        error: false,
        errors: {},
        message: '',
        type: 2,
        loadingSave: false,
        loadingDelete: false,
        days: [
          {id: 1, name: 'Domingo',  visible: false},
          {id: 2, name: 'Segunda',  visible: true},
          {id: 3, name: 'Terça',    visible: true},
          {id: 4, name: 'Quarta',   visible: true},
          {id: 5, name: 'Quinta',   visible: true},
          {id: 6, name: 'Sexta',    visible: true},
          {id: 7, name: 'Sábado',   visible: true}
        ]
    }),
    computed: {
      ...mapState('auth', ['acesso', 'user']),

      
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        startDate(val){

            this.event.start_date = val;

            if(!this.endDate || this.endDate < val){
                this.endDate = val;
            }

            this.setShowRecurrence();

        },

        endDate(val){
            this.event.end_date = val;
            this.setShowRecurrence();
        },

        

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.disabled = false;
                this.tab = 0;
                this.event = {
                    days: []
                };
                this.userOrganizer = null;
                Object.assign(this.event, this.model);

                if(this.model && this.model.allocation_type_id){
                    this.type = this.model.allocation_type_id;
                }else{
                    this.type = 2;
                }

                if(this.model && this.model.userOrganizer){
                    this.userOrganizer = this.model.userOrganizer;
                }else{
                    this.userOrganizer = this.user;
                    this.event.organizer_user_id = this.user.id;
                }

                this.event.daysOfWeek = this.event.days.map(i => i.id);
                this.startDate = this.event.start_date;
                this.endDate = this.event.end_date;

            }
        },
        
    },

    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "300"
        },
        maxWidth: {
            type: String,
            default: "60%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Calendário de Eventos"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        ambienceId: {

        }
    },
    methods:{
        ...mapActions('roomAllocation', ['ActionCreateAllocation', 'ActionUpdateAllocation']),

        save()
        {
            this.error = false;
            this.message = '';
            this.loadingSave = true;
            var edit = this.event.id;
            
            this.event.ambience_id = this.ambienceId;
            this.event.institution_id = this.acesso.instituicao.id;
            this.event.work_time_id = this.acesso.periodo.id;
            this.event.allocation_type_id = this.type;
                      
            if(edit) {
                this.ActionUpdateAllocation(this.event)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                        this.loadingSave = false;
                })
            } else {
                                
                this.event.id = 0;
                                
                this.ActionCreateAllocation(this.event)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loadingSave = false;
                })

            }
        },

        setShowRecurrence(){

            if(this.event && this.event.start_date && this.event.end_date){
                this.showRecurrence =  this.event.start_date != this.event.end_date;
            }else{
                this.showRecurrence = false;
            }
        },

        deleteItem()
        {
            
            this.loadingDelete = true;

            this.ActionDeleteCalendar({id: this.event.id })
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                        this.loadingDelete = false;
                });
        
        },
    }
}
</script>