<template>
    <emc-form-base 
        :show.sync="dialog"
        @onOk="submit"
        maxWidth='20%'
        icon="mdi-filter-menu-outline"
        textButton="Download"
        iconButton="mdi-microsoft-excel"
        colorButton="success"
    >
        <template v-slot:form>
            <v-form ref="form">
                <v-text-field
                    v-show="showYear"
                    ref="year"
                    v-model="year"
                    label="Ano"
                    outlined
                    :rules="[() => !!year || 'Informe o ano']"
                    required
                />
                <v-combobox
                     ref="select"
                    v-model="select"
                    :items="items"
                    item-key="id"
                    item-text="name"
                    :rules="[() => !!select || 'Informe o mês']"
                    outlined
                    label="Mês"
                    required
                />
            </v-form>
        </template>
    </emc-form-base>
</template>

<script>
import { myMixin } from '../../../../src/mixins.js'
import state from '../../../modules/auth/store/state'
export default {
    name: "SysPrintAttribution",
    mixins: [myMixin],
    data: () => ({
        dialog: false,
        year: null,
        select: null,
        formHasErrors: false,
        showYear: false,
        items: [ 
                { id:1,     name: 'Janeiro' },
                { id:2,     name: 'Fevereiro' },
                { id:3,     name: 'Março' },
                { id:4,     name: 'Abril' },
                { id:5,     name: 'Maio' },
                { id:6,     name: 'Junho' },
                { id:7,     name: 'Julho' },
                { id:8,     name: 'Agosto' },
                { id:9,     name: 'Setembro' },
                { id:10,    name: 'Outubro' },
                { id:11,    name: 'Novembro' },
                { id:12,    name: 'Dezembro' },
            ],
        
    }),

    computed: {
      
      form () {
        return {
          year: this.year,
          select: this.select,
        }
      },
    },
    watch: {

        
        
        show(val){
            this.dialog = val;
            
            if(val){
                var month = new Date().getMonth() + 1;
                this.year = state.acesso.periodo.year;
                this.select = this.items.find(i => i.id == month);
            }
        },

        dialog(val){
            this.$emit('update:show', val);
        },
        
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    methods: {
        submit () {
            
            this.formHasErrors = false;

            Object.keys(this.form).forEach(f => {
            
            if (!this.form[f]){
                this.formHasErrors = true;
            }
                this.$refs[f].validate(true);
            });

            if(!this.formHasErrors){
                this.download(31, 'options[year]='+ this.year + '&options[month]=' + this.select.id );
            }
      },
    }
}
</script>