<template>
    <div class="mt-3">
         
         <emc-table-items
            :headers="headers" 
            v-bind:params.sync="params"
            v-bind:collections.sync="collections"
            v-bind:meta.sync="meta"
            v-bind:itemDelete.sync="itemDelete" 
            v-bind:itemEdit.sync="model"
            :find="false"
            :loadingText="loadingText"
        />

        <sys-faq-register @onCompleted="getData()" disabledCategory :model="model" v-bind:show.sync="showForm" />

    <!-- Modal usado para mensagem e ação de exclusão -->
        <emc-alert-modal :text="textDelete" :disabled="deleting" v-bind:show.sync="show">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>
        
        <!-- Alert para mensagens -->
        <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
    
    </div>

</template>

<script>

import { mapActions } from 'vuex'

export default {
   name: 'SysFaqQuestions',
   data: () => ({
    collections: [],
    loadingText: '',
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    itemUpdate: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    textDelete: '',
    deleting: false,
    headers: [
        { text: 'Título', value: 'title'},
        { text: 'Ordem', value: 'order', align: 'center'},
        { text: 'Status', value: 'active', type: 'boolean'},
        { text: 'Ações', value: 'actions'},
    ]
    }),

    props: {
        item: {
            type: Object,
            required: true
        },
        showAdd: {
            type: Boolean,
            default: false
        }
    },

    watch: {
      
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData();
            }
        },
      
      //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      },
      
      showAdd(val){
          
        if(val){
            this.model = {};
            this.showForm = true;

            if(this.item && this.item.id){
                this.model.faq_category_id = this.item.id;
            }
          }
          
      },

      showForm(val){
          if(!val){
                this.$emit('update:showAdd', val);
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.item.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
       if(this.item && this.item.id){
        this.getData();
       }
       
    },
    methods: {
        ...mapActions('faq', ['ActionFindFaqs', 'ActionDeleteFaq']),
        
    getData(){
        
        this.error = false;
        this.message = '';
        this.showForm = false;
        this.loadingText = 'Carregando dados...';
        this.collections = [];
        
        this.params.find_columns = {
            faq_category_id: this.item.id
        };

        this.ActionFindFaqs(this.params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loadingText = '';
            });
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteFaq({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData(res.data);
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }

  },

}
</script>
