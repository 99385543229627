import { service } from '../../../../helpers/http'

export const ActionIndexDateMaskTypes = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexDateMaskTypes',
        payload: payload
    })

}
export const ActionDestroyDateMaskType = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyDateMaskType',
        payload: payload
    })
}
export const ActionStoreDateMaskType = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeDateMaskType',
        payload: payload
    })

}
export const ActionUpdateDateMaskType = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateDateMaskType',
        payload: payload
    })
}