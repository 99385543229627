<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="60%"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="8">
                        <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :data.sync="model.teacher"
                                :error="errors.length > 0" 
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                :autofocus="model.id == null"
                                dense
                            />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field dense :error="!!errors.year" :error-messages="errors.year" v-model="model.year" label="Ano*"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field dense :error="!!errors.period" :error-messages="errors.period" v-model="model.period" label="Período*"></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" sm="4">
                        <sys-select-campus
                            :model.sync="model.campus_id"
                            :error="errors.length > 0"  
                            :errors="errors.campus_id"
                            label="Campus"
                            showClear
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <s-text-field dense text-helper="Carga horária semanal em sala de aula." :error-messages="errors.work_load" v-model="model.work_load" label="Carga Horária*" />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <s-text-field dense text-helper="Carga horária semanal extra classe ou demais atividades (fora de sala de aula)." :error-messages="errors.activity" v-model="model.activity" label="Atividade*" />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <s-text-field dense text-helper="Carga horária semanal extra classe ou demais atividades (fora de sala de aula) que deve computar como carga horária de salário." :error-messages="errors.activity_work_load" v-model="model.activity_work_load" label="Atividade (CH Salário)*" />
                    </v-col>
                     <v-col cols="12" sm="2">
                        <s-text-field dense text-helper="Carga horária semanal disponibilizada pelo professor no período." :error-messages="errors.availability" v-model="model.availability" label="Disponibilidade*" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-textarea
                            v-model="model.note"
                            outlined
                            name="input-7-4"
                            label="Anotações"
                            :value="model.note"
                            :error="errors.length > 0"  
                            :error-messages="errors.note"
                            rows="2"
                            row-height="20"
                            :counter="255"
                            :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysControlPanelWorkLoadHistoryRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.work_load = 0;
                    this.model.activity = 0;
                    this.model.activity_work_load = 0;
                    this.model.availability = 0;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-chart-timeline"
        },
        title: {
            type: String,
            default: "Histórico de Carga Horária"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('controlPanel', ['ActionUpdateWorkLoadHistory', 'ActionStoreWorkLoadHistory']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateWorkLoadHistory(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionStoreWorkLoadHistory(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>