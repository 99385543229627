export default [
    {
        path: '/activity-categories',
        name: 'activity-categories',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Categoria das atividades',
            icon: '',
            breadcrumbs: [
                { text: 'Atividades', disabled: true, href: '/' },
                { text: 'Categoria das atividades', disabled: true, href: '/' }
            ],
        }
    },
]