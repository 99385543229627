import { service } from '../../../helpers/http'

export const ActionFindEventCategories = ({ dispatch }, payload) => {
    return service({
        module: 'eventCategory',
        action: 'eventCategories',
        payload: payload
    })
}

export const ActionCreateEventCategory = ({ dispatch }, payload) => {
    return service({
        module: 'eventCategory',
        action: 'createEventCategory',
        payload: payload
    })
}

export const ActionUpdateEventCategory = ({ dispatch }, payload) => {
    return service({
        module: 'eventCategory',
        action: 'updateEventCategory',
        payload: payload
    })
}

export const ActionDeleteEventCategory = ({ dispatch }, payload) => {
    return service({
        module: 'eventCategory',
        action: 'deleteEventCategory',
        payload: payload
    })
}