export default [
    {
        path: '/curriculum',
        name: 'curriculum',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Matriz currícular',
            icon: '',
            breadcrumbs: [
                { text: 'Matriz currícular', disabled: true, href: '/' },
            ],
        }
    },
]