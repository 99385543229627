var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-linear',{staticClass:"ma-1",attrs:{"indeterminate":"","color":"yellow darken-2"}}):_vm._e(),(_vm.showSearch)?_c('v-text-field',{staticClass:"pa-1",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.searchInner),callback:function ($$v) {_vm.searchInner=$$v},expression:"searchInner"}}):_vm._e(),_c('v-switch',{staticClass:"ml-3",attrs:{"inset":"","loading":_vm.loadingPendings,"label":"Pendentes"},on:{"change":function($event){return _vm.changeOnlyPendinds()}},model:{value:(_vm.onlyPendings),callback:function ($$v) {_vm.onlyPendings=$$v},expression:"onlyPendings"}}),_c('v-treeview',{ref:"tree",attrs:{"active":_vm.active,"items":_vm.items,"load-children":_vm.fetchItems,"open":_vm.open,"activatable":"","item-children":"children","color":"warning","open-on-click":false,"transition":"","item-key":"key","return-object":"","selectable":_vm.selectable,"dense":"","search":_vm.searchInner},on:{"update:active":[function($event){_vm.active=$event},function($event){return _vm.selectItem($event[0])}],"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(_vm.allowDrag && item.level >= 2)?_c('draggable',{on:{"start":function($event){return _vm.start($event, item)},"end":function($event){return _vm.end($event, item)}}},[(item.level == 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(_vm._s(item.name))]),_c('span',[(_vm.showPendencies && item.checked)?_c('v-icon',{attrs:{"small":"","color":"green","btn":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.course.name))]),(item.time_course)?_c('span',[_c('br'),_vm._v("Período: "+_vm._s(item.time_course))]):_vm._e()]):_c('span',{staticStyle:{"cursor":"pointer"}},[(item.level == 3)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(_vm._s(item.work_load_type.initials + ' - ' + item.name))]),_c('span',[(_vm.showPendencies && item.checked)?_c('v-icon',{attrs:{"small":"","color":"green","btn":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('span',[_vm._v(_vm._s(item.work_load_type.initials + ' - ' + item.name + ' (' + item.work_load + ')'))]),(item.students_join)?_c('span',[_c('br'),_vm._v("Alunos: "+_vm._s(item.students_join)+" ")]):_vm._e(),(item.students_join_forecast)?_c('span',[_c('br'),_vm._v("Previsão Alunos: "+_vm._s(item.students_join_forecast)+" ")]):_vm._e(),(item.teacher)?_c('span',[_c('br'),_vm._v(" "+_vm._s(item.teacher.name)+" ")]):_vm._e()])])],1):_c('span',[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[(_vm.showPendencies && item.checked)?_c('v-icon',{attrs:{"small":"","color":"green","btn":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)])])],1):_c('span',[(item.level == 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"default"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',[(_vm.showPendencies && item.checked)?_c('v-icon',{attrs:{"small":"","color":"green","btn":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.course.name))]),(item.time_course)?_c('span',[_c('br'),_vm._v("Período: "+_vm._s(item.time_course))]):_vm._e()]):_c('span',[_c('span',{staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(item.name))]),_c('span',[(_vm.showPendencies && item.checked)?_c('v-icon',{attrs:{"small":"","color":"green","btn":""}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]),(_vm.showCategory)?_c('emc-util-icon-category',{attrs:{"item":item}}):_vm._e()],1)]}}]),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }