<template>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
    >
        <v-card
            class="mx-auto elevation-0"
            height="100%"
        >
            <v-app-bar
                prominent
                height="40px"
            >
            
            <v-tooltip v-if="showActions" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small icon @click.prevent.stop="$emit('open-dialog-new')">
                        <v-icon btn color="green darken-1">mdi-plus</v-icon>
                    </v-btn>
                </template>
                    <span>
                    Novo
                </span>
            </v-tooltip>

            <v-tooltip v-if="showActions" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="disabledEdit" v-bind="attrs" v-on="on" small icon @click.prevent.stop="openDialog(selecteItem)">
                        <v-icon btn color="purple darken-1">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                    <span>
                    Editar
                </span>
            </v-tooltip>

            <v-text-field
                v-model="search"
                prepend-inner-icon="search"
                x-small
                placeholder="Pesquisar" 
                rounded 
                single-line
                clearable
                autofocus
                
            ></v-text-field>

            </v-app-bar>

            <sys-group-tree-view :showPendencies="true" :pendencies="pendencies" :search="search" @select="selectItem($event)" />
            
        </v-card>
    </v-navigation-drawer>
   
</template>

<style scoped>
.v-text-field{
    width: 200px;
}
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleDrawer",
    data: () => ({
      componentKey: "componentKey",
      render: true,
      drawer: false,
      selected: [],
      disabledEdit: true,
      selecteItem: null,
      search: '',
      active: [],
      options: {
          selectable: false,
          dense: true,
          activatable: true,
          hoverable: false,
          openOnClick: false,
          expandOnHover: true,
          miniVariant: false,
          shaped: false,
          rounded: true,
          color: 'primary',
          selectedColor: 'accent',
          selectedColors: [
            'accent',
            'teal',
            'red',
            'success',
            'warning lighten-2',
          ],
      },
      
    }),
    computed: {
       
    },
    
    watch: {
        
        model(model_new, model_old) {
            
            if(model_new !== model_old) {
                this.drawer = model_new;
            }

            if(model_new){
                this.componentKey = "componentKey";
            }else{
                this.componentKey = "";
            }
        },

        drawer(drawer_new, drawer_old) {
            
            if(drawer_new !== drawer_old) {
                this.$emit('update:model', drawer_new);
            }
        },

    },
    props: {
        model: {
            type: Boolean,
        },
        items: {
          type: Array,
          required: true
        },
        showActions: {
          type: Boolean,
          default: false
        },
        pendencies: {
            type: Number,   
            default: -1
        }
    },
    methods: {
      selectItem(item){
          
          this.disabledEdit = true;
          this.selecteItem = item;
          
          if(item && item.level == 2){
            this.disabledEdit = false;
            this.$emit('on-select', item);
          }
      },
      
      openDialog(item){
          
          if(item && item.level == 2){
            this.$emit('on-dblclick', item);
          }
      },

     getTextItem(item){
        
        if(item.level == 2){
            
            var countGrids = 0.0;
            var countClasses = 0.0;
            var text = "";

            item.grids.forEach(grid => {
                countGrids += parseFloat(grid.work_load * grid.periodicity_type['factor']);
                countClasses += parseFloat(grid.classes.length);
            });

            var total = parseFloat(countGrids) - parseFloat(countClasses);

            if(total > 0){
                
                total = parseFloat(total).toFixed(1);
                const [integer, decimal] = total.toString().split('.');

                if(decimal == 0){
                    total = integer;
                }
                
                item.pending = true;
                text = item.name + ' (' + total.toString() + ')';
                item.style = "cursor: pointer; color: red";
            }else{
                item.pending = false;
                text =  item.name;
                item.style = "cursor: pointer; color: blue";
            }

            return text;

        }else{
            return item.name;
        }

        
        

     },

    }
}
</script>