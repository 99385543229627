<template>
    <v-menu
        open-on-hover
        top
        offset-y
        >
       <template v-slot:activator="{ on, attrs }">
        <v-btn :style="styleCustom" small icon v-bind="attrs" v-on="on">
            <v-icon small :color="iconColor">{{ icon }}</v-icon>
        </v-btn>
    </template>
    <v-card outlined tile class="pb-2 pa-0 ma-0">
        <v-app-bar class="elevation-0" dense>
            <v-toolbar-title class="text-body-2">
                    <strong> {{ title }} </strong>
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="showCopy" class="ml-2" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" style="cursor: copy;" x-small @click.prevent.stop="copyToClipBoard()" >mdi-content-copy</v-icon>
                        </template>
                        <span> Copiar </span>
                    </v-tooltip>
            </v-toolbar-title>
        </v-app-bar>
        <v-divider class="mb-2"></v-divider>
        <div v-if="text" class="text-caption pb-2 pl-3 pr-3 grey--text text--darken-1">{{ text }}</div>
        <div  class="text-caption pb-2 pl-3 pr-3 grey--text text--darken-1"><slot name="text"></slot></div>
    </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'EmcInfoHelp',
    props: {
        title: {
            type: String,
            default: 'Informações'
        },
        text: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'mdi-help-circle-outline'
        },
        iconColor: {
            type: String,
            default: 'indigo'
        },
        styleCustom: {
            type: String,
            default: 'cursor: help'
        },
        showCopy: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        copyToClipBoard(){
            navigator.clipboard.writeText(this.text);
        }
    }
}
</script>