import { service } from '../../../../helpers/http'

export const ActionIndexSubGroups = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'indexSubGroups',
        payload: payload
    })
    
}

export const ActionStoreSubGroup = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'storeSubGroup',
        payload: payload
    })
    
}

export const ActionUpdateSubGroup = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'updateSubGroup',
        payload: payload
    })
    
}

export const ActionDestroySubGroup = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'destroySubGroup',
        payload: payload
    })
}

export const ActionUpdateSubGroupItems = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'updateSubGroupItems',
        payload: payload
    })
    
}