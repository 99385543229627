<template>
    <div>
        <emc-modal-form :title="title" :icon="icon" v-bind:show.sync="dialog">
            <template v-slot:form>
                <v-expansion-panels>
                    <v-expansion-panel v-for="(log, i) in logs" :key="i">
                        <v-expansion-panel-header disable-icon-rotate>
                            <span>
                                <v-icon v-if="log.origin == 'excel'" color="success">mdi-microsoft-excel</v-icon> 
                                <v-icon v-else color="primary">mdi-api</v-icon> 
                                {{ log.created_at }}</span>
                            <template v-slot:actions>
                                <v-icon v-if="log.items_amount !== (log.errors_amount + log.success_amount)" color="orange">mdi-sync</v-icon>
                                <v-icon v-else color="success">mdi-check</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-progress-linear :value="(log.success_amount / log.items_amount) * 100" height="10" color="success" background-color="red lighten-3"></v-progress-linear>
                            <br />
                            <v-list two-line dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Total de itens
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-html="log.items_amount"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Total de itens com erro
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-html="log.errors_amount"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Total de itens cadastrados
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-html="log.success_amount"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-card class="mx-auto" v-if="log.errors">
                                <v-toolbar height="40" color="grey lighten-1" dark>
                                    <v-toolbar-title>Erros</v-toolbar-title>
                                </v-toolbar>
                                <v-list two-line dense>
                                    <template v-for="(error, i) in log.errors">
                                        <v-list-item :key="i">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ error.code || 'Sem código'}} - {{ error.name || 'sem nome'}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-textarea
                                                    name="error"
                                                    label="Mensagem"
                                                    :value="error.message"
                                                    disabled
                                                    ></v-textarea>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="text-center">
                    <v-container>
                    <v-row justify="center">
                        <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-2"
                            :length="length"
                            circle
                            :disabled="loading"
                            ></v-pagination>
                        </v-container>
                        </v-col>
                    </v-row>
                    </v-container>
                    <EmcLoaderProgress v-bind:show.sync="loading" />
                </div>
            </template>
        </emc-modal-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysImportLog',
    data: () => ({
        logs: [],
        dialog: false,
        loading: false,
        page: 1,
        length: 0
    }),
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: 'mdi-account'
        },
        show: {
            type: Boolean,
            required: true
        },
        module: {
            type: String,
            required: true
        }
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            if(show_new !== show_old) {
                this.dialog = show_new
                this.getData()
            }

        },
        page() {
            this.getData()
        }
    },
    methods: {
        ...mapActions('importLog', ['ActionIndexImportLog']),
        getData() {
            var params = {
                search: this.module,
                column_order: 'updated_at',
                direction: 'desc',
                page: this.page,
                per_page: 5
            }
            this.ActionIndexImportLog(params)
                .then((r) => {
                    this.logs = r.data
                    this.length = r.meta.last_page
                })
                .catch((e) => {
                   
                })
                .finally(()=> {
                    this.loading = false
                })
            this.loading = true
        }
    }
}
</script>