export default {
    teachers:                           { method: 'get',    url: 'teachers' },
    teacher:                            { method: 'get',    url: 'teachers/{id}' },
    classes:                            { method: 'get',    url: 'teachers/{id}/classes' },
    classesOptimized:                   { method: 'get',    url: 'teachers/{id}/classes_optimized' },
    teacherCreate:                      { method: 'post',   url: 'teachers' },
    teacherUpdate:                      { method: 'put',    url: 'teachers/{id}' },
    teacherDelete:                      { method: 'delete', url: 'teachers/{id}' },
    createEmail:                        { method: 'post',   url: 'teachers/emails' },
    removeEmail:                        { method: 'delete', url: 'teachers/emails/{id}'},
    confirmEmail:                       { method: 'put',    url: 'teachers/confirm/email'},
    titling:                            { method: 'get',    url: 'teacher_titling'},
    classSchedules:                     { method: 'get',    url: 'teachers/class_schedules'},
    classSchedulesToken:                { method: 'get',    url: 'teachers/class_schedules/{token}/token'},
    updateDisciplines:                  { method: 'put',    url: 'teachers/{id}/disciplines'},
    updateDisciplinesToken:             { method: 'put',    url: 'teachers/disciplines/token/{token}'},
    findRelationships:                  { method: 'get',    url: 'teachers/relationships'},
    colorRaces:                         { method: 'get',    url: 'teachers/color_races'},
    workRegimes:                        { method: 'get',    url: 'teachers/work_regimes'},
    nationalities:                      { method: 'get',    url: 'teachers/nationalities'},
    genres:                             { method: 'get',    url: 'teachers/genres'},
    statusDeficiencies:                 { method: 'get',    url: 'teachers/status_deficiency'},
    releaseAvailability:                { method: 'put',    url: 'teachers/{id}/release/availability'},
    uploadAvatarToken:                  { method: 'post',   url: 'teachers/token/upload/avatar' },
    uploadAvatarId:                     { method: 'post',   url: 'teachers/id/upload/avatar' },
    workLoadConfirmations:              { method: 'get',    url: 'work_load_confirmations' },
    createWorkLoadConfirmation:         { method: 'post',   url: 'work_load_confirmations' },
    updateWorkLoadConfirmation:         { method: 'put',    url: 'work_load_confirmations/{id}' },
    deleteWorkLoadConfirmation:         { method: 'delete', url: 'work_load_confirmations/{id}' },
    workLoadConfirmationStatus:         { method: 'get',    url: 'work_load_confirmations/status' },
    workLoadConfirmationToken:          { method: 'put',    url: 'work_load_confirmations/{token}/confirm' },
    updateTeacherExtractSetting:        { method: 'put',    url: 'teacher_extract_settings/{id}' },
    indexTeacherExtractSetting:         { method: 'get',    url: 'teacher_extract_settings' },
    findTeacherExtractSettingDefault:   { method: 'get',    url: 'teacher_extract_settings/default' },
    findTeacherByToken:                 { method: 'get',    url: 'teachers/{token}/token' },
    indexTeachersByCampuses:            { method: 'get',    url: 'teachers/by-campuses' },
}