import { service } from '../../../helpers/http'

export const ActionFindCurriculums = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'curriculums',
        payload: payload
    })
}

export const ActionCreateCurriculum = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'createCurriculum',
        payload: payload
    })
}

export const ActionUpdateCurriculum = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'updateCurriculum',
        payload: payload
    })
}

export const ActionDeleteCurriculum = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'deleteCurriculum',
        payload: payload
    })
}

export const ActionFindCurriculumStatus = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'curriculumStatus',
        payload: payload
    })
}

export const ActionFindCurriculumSequencies = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'curriculumSequencies',
        payload: payload
    })
}

export const ActionFindCurriculumRegimes = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'curriculumRegimes',
        payload: payload
    })
}

export const ActionUpdateCurriculumDisciplines = ({ dispatch }, payload) => {
    return service({
        module: 'curriculum',
        action: 'updateCurriculumDisciplines',
        payload: payload
    })
}