<template>
    <sys-register-base 
            :loading="loading" 
            :model.sync="model" 
            :title="title" 
            :icon="icon"
            v-bind:show.sync="dialog"
            maxWidth="80%"
            :actions="false"
        >
    
        <template v-slot:form>

            <v-progress-linear
                indeterminate
                color="blue"
                class="mb-0"
                v-if="loading"
            ></v-progress-linear>
            
            <emc-chart-general-campus-balance :brand-id="brandId" :show.sync="showMore" /> 

              <v-container>
                <v-row>
                  <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
                      <emc-chart-teacher-balance 
                        @showMore="showChartCampuses" 
                        :item="item"
                        no-load
                        :name="'TeacherBalance' + item.id"
                    />
                  </v-col>
              </v-row>
              </v-container>
        </template>

    </sys-register-base> 
</template>

<script>


import { mapActions, mapState } from 'vuex'
export default {
    name: "EmcChartGeneralInstitutionBalance",
    data: (vm) => ({
        dialog: false,
        loading: false,
        showMore: false,
        brandId: 0,
        items: [],
        itemsTemp: [],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.getData();
            }
            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-monitor-eye"
        },
        title: {
            type: String,
            default: "Balanço de CH por IES"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
      ...mapActions('campus', ['ActionFindCampuses']),
      ...mapActions('chart', ['ActionChartTeacherBalance']),

        getData(){

          if(this.loading){
            return;
          }
          
          this.loading = true;
          this.items = [];
          this.itemsTemp = [];

          let payload = {
            with: 'brand',
            withContract: true,
            find_columns: {
                id: this.acesso.campus.map(c => c.id).join(','),
            }
          };

          this.ActionFindCampuses(payload)
              .then((res) => {
                   
                let ids = [];

                res.data.forEach(cam => {

                    let brand = { id: cam.brand.id, name: cam.brand.name };

                    if(!ids.includes(brand.id)){
                        this.itemsTemp.push(brand);
                        ids.push(brand.id);
                    }
                });

                this.setIndicator();  

              })
              .catch((error) =>{
                  
              })
              .finally(() => {
                    
              });

        },

        setIndicator(){
      
                var payload = {
                    type: 'allBrands',
                };

                this.ActionChartTeacherBalance(payload)
                    .then((res) => {
                        
                        let items = res.data;

                        this.itemsTemp.forEach(ies => {
                            ies.data = items.find(i => i.brand_id == ies.id);
                        });

                        this.items = this.itemsTemp;
                      
                    })
                    .finally(() => {
                        this.loading = false;
                        
                    });

        },

        showChartCampuses(item){
            this.brandId = item.id;
            this.showMore = true;

        },
        
    }
}
</script>