import { default as bigOccupationArea } from './big_occupation_area/routes'

export default [
    ...bigOccupationArea,
    {
        path: '/occupationAreas',
        name: 'occupationAreas',
        component: () => import ('./pages/OccupationAreaRegister'),
        meta: {
            title: 'Área de atuação',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Área de atuação', disabled: true, href: '/' },
            ],
        }
    },
]