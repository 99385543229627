export default [
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Blocos',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }, 
                { text: 'Blocos', disabled: true, href: '/' }, 
            ],
        }
    },
]