import { service } from '../../../../helpers/http'

export const ActionFindJoins = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'joins',
        payload: payload
    })
}

export const ActionFindJoinByGroupId = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'joinByGroupId',
        payload: payload,
        idAndPayload: true,
    })
}

export const ActionFindJoinByAmbienceId = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'joinByAmbienceId',
        payload: payload
    })
}

export const ActionGetStatus = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'status',
        payload: payload
    })
}

export const ActionDeleteJoin = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'joinDelete',
        payload: payload
    })
}

export const ActionUpdateJoin = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'update',
        payload: payload
    })
}

export const ActionCreateJoin = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'store',
        payload: payload
    })
}

export const ActionFindJoinByGroupIdToken = ({ dispatch }, payload) => {

    return service({
        module: 'join',
        action: 'joinByGroupIdToken',
        params: { id: payload.id, token: payload.token },
        payload: payload,
    })
    
}

export const ActionIndexJoinTypes = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'indexJoinTypes',
        payload: payload
    })
}

export const ActionJoinDestroyTotvs = ({ dispatch }, payload) => {
    return service({
        module: 'join',
        action: 'joinDestroyTotvs',
        payload: payload
    })
}