import { service } from '../../../helpers/http'
export * from '../place/store/actions'

export const ActionFindAmbiences = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambiences',
        payload: payload
    });
}

export const ActionFindAmbiencesFilter = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambiencesFilter',
        payload: payload
    });
}

export const ActionCreateAmbience = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambienceCreate',
        payload: payload
    });
}

export const ActionUpdateAmbience = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambienceUpdate',
        payload: payload
    });
}

export const ActionDeleteAmbience = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambienceDelete',
        payload: payload
    });
}

export const ActionSearchAmbiences = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambiencesSearch',
        payload: payload
    })
    
}

export const ActionSearchAmbiencesByItem = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'ambiencesSearchByItem',
        payload: payload
    })
    
}

export const ActionUploadAmbienceImage = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'uploadImage',
        payload: payload
    })
    
}

export const ActionUpdateAmbienceImage = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'updateImage',
        payload: payload
    })
    
}

export const ActionDeleteAmbienceImage = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'deleteImage',
        payload: payload
    })
    
}

export const ActionUpdateAmbienceImageMain = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'updateImageMain',
        payload: payload
    })
}

export const ActionFindAmbienceClassesOptimized = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'classesOptimized',
        payload: payload
    })
}

export const ActionShowAmbiencesTree = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambience',
        action: 'showAmbiencesTree',
        payload: payload
    })
}