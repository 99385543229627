export default [
    {
        path: '/ambienceTypes',
        name: 'ambienceTypes',
        component: () => import ('./pages/AmbienceTypeRegister'),
        meta: {
            title: 'Tipo de ambiente',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }, 
                { text: 'Tipo de ambiente', disabled: true, href: '/' }
            ],
        }
    },
]