import { service } from '../../../../helpers/http'

export const ActionIndexWorkTimeCategories = ({ dispatch }, payload) => {

    return service({
        module: 'worktime',
        action: 'indexWorkTimeCategories',
        payload: payload
    })
    
}

export const ActionCreateWorkTimeCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'worktime',
        action: 'createWorkTimeCategory',
        payload: payload
    })
    
}

export const ActionUpdateWorkTimeCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'worktime',
        action: 'updateWorkTimeCategory',
        payload: payload
    })
}

export const ActionDeleteWorkTimeCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'worktime',
        action: 'deleteWorkTimeCategory',
        payload: payload
    })
    
}