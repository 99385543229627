import { service } from '../../../helpers/http'

export const ActionFindCourses = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'findCourses',
        payload: payload
    })

}

export const ActionFindCoursesSearch = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'courseSearch',
        payload: payload
    })

}

export const ActionDeleteCourse = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'deleteCourse',
        payload: payload
    })

}

export const ActionUpdateCourse = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'updateCourse',
        payload: payload
    })

}

export const ActionCreateCourse = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'createCourse',
        payload: payload
    })

}


export const ActionFindCourseModalities = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'findModalities',
        payload: payload
    })
    
}

export const ActionFindCourseDegrees = ({ dispatch }, payload) => {

    return service({
        module: 'course',
        action: 'findDegrees',
        payload: payload
    })
    
}

export const ActionIndexCourses = (({ dispatch }, payload) => {
    return service({
        module: 'course',
        action: 'index',
        payload: payload
    })
})

export const ActionFindQualifiers = (({ dispatch }, payload) => {
    return service({
        module: 'course',
        action: 'findQualifiers',
        payload: payload
    })
})

export const ActionCreateQualifier = (({ dispatch }, payload) => {
    return service({
        module: 'course',
        action: 'createQualifier',
        payload: payload
    })
})

export const ActionUpdateQualifier = (({ dispatch }, payload) => {
    return service({
        module: 'course',
        action: 'updateQualifier',
        payload: payload
    })
})

export const ActionDeleteQualifier = (({ dispatch }, payload) => {
    return service({
        module: 'course',
        action: 'deleteQualifier',
        payload: payload
    })
})

