<template>    
        <v-app id="sandbox" >
          <v-navigation-drawer 
              v-if="acesso.periodo" 
              v-model="primaryDrawer.model" 
              :clipped="primaryDrawer.clipped" 
              :floating="primaryDrawer.floating" 
              :mini-variant="primaryDrawer.mini" 
              :permanent="primaryDrawer.type === 'permanent'" 
              :temporary="primaryDrawer.type === 'temporary'" 
              :app="primaryDrawer.app"
              overflow
            >
            <v-list dense>
              <template v-for="item in menu">
                <v-row v-if="item.heading" :key="item.id" align="center">
                  <v-col cols="6">
                    <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
                  </v-col>
                  <v-col cols="6" class="text-center">
                    <a href="#!" class="body-2 black--text">EDIT</a>
                  </v-col>
                </v-row>

              
              <v-list-group 
                v-else-if="Object.keys(item.children).length > 0" 
                :key="item.id" 
                v-model="item.model" 
                :prepend-icon="item.model ? item.icon : 'keyboard_arrow_down'"
                append-icon=""
              >

                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.to">

                  <v-list-item-action v-if="child.icon" >
                    <v-tooltip v-if="primaryDrawer.mini" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" >{{ child.icon }}</v-icon>
                      </template>
                      <span> {{ child.description }} </span>
                    </v-tooltip>
                    <v-icon v-else >{{ child.icon }}</v-icon>
                  </v-list-item-action>
                
                  <v-list-item-content>
                    <v-list-item-title >{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

            </v-list-group>

            <v-list-item v-else :key="item.id" link :to="item.to">
              
              <v-list-item-action>
                <v-tooltip v-if="primaryDrawer.mini" bottom>
                  <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" >{{ item.icon }}</v-icon>
                  </template>
                  <span> {{ item.description }}</span>
                </v-tooltip>
                <v-icon v-else >{{ item.icon }}</v-icon>
              </v-list-item-action>
              
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      
      </v-navigation-drawer>
  
      <v-app-bar :clipped-left="primaryDrawer.clipped" app>
        <v-app-bar-nav-icon v-if="primaryDrawer.type !== 'permanent' && acesso.periodo" @click.stop="primaryDrawer.model = !primaryDrawer.model"></v-app-bar-nav-icon>
        
        <v-btn v-if="primaryDrawer.model && acesso.periodo" icon @click.stop="primaryDrawer.mini = !primaryDrawer.mini">
          <v-icon v-html="primaryDrawer.mini ? 'keyboard_arrow_right' : 'keyboard_arrow_left'"></v-icon>
        </v-btn>
        
        <v-toolbar-title><v-img src="/img/sysclass_logo.png" max-width="150" /></v-toolbar-title>
        
        <v-spacer></v-spacer>

        <v-btn v-if="false && user.id"  to="faq-questions" icon>
          <v-icon color="indigo" large>mdi-help-circle-outline</v-icon>
        </v-btn>

        <v-menu v-if="user.id" v-model="showMenu" :close-on-content-click="false" :nudge-width="100" offset-x>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <EmcBaseAvatar 
                :size="36"
                :src="user.url_avatar"
                :text="user.initials"
                v-if="user.id"
              />
              <v-icon color="grey darken-1">keyboard_arrow_down</v-icon>

            </v-btn>
          </template>

            <v-card :max-width="400">
              <v-list>
                 <v-list-item>
                  <v-list-item-avatar>
                    <EmcBaseAvatar 
                        :size="36"
                        :src="user.url_avatar"
                        :text="user.initials"
                        v-if="user.id"
                      />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                  <v-list-item v-if="acesso.instituicao && acesso.instituicao.id" to="profile" @click="showMenu = !showMenu">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Meus Dados</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click.stop.prevent="sair()">
                    <v-list-item-icon>
                      <v-icon>{{ exit ? 'mdi-spin mdi-loading' : 'exit_to_app'}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Sair</v-list-item-title>
                  </v-list-item>

                </v-list>

            </v-card>
    </v-menu>

          <!-- <v-btn v-if="!user.id" text to="/account">Assine agora!</v-btn> -->
          <!-- <v-btn v-if="!user.id" text to="/login">Já sou cliente</v-btn> -->
        
      </v-app-bar>

      <sys-core-content />
      <sys-core-footer />

  </v-app>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    name: 'SysCoreToobar',
    data: () => ({
      fav: true,
      showMenu: false,
      message: false,
      hints: true,
      errors: {},
      app_env: process.APP_ENV,
        primaryDrawer: {
            model: null,
            type: 'default (no property)',
            clipped: true,
            floating: false,
            mini: true,
            app: true
        },
        title: "SysClass",
        loading: false,
        exit: false
    }),
    created() {
        
        var screen = this.$vuetify.breakpoint.name;
        
        if(screen == 'xs' || screen == 'sm'){
            this.primaryDrawer.mini = false;
        }
        
    },
    computed: {
      ...mapState('auth', ['acesso', 'user', 'menu'])
    },
    methods: {
      ...mapActions('auth', ['ActionSignOut']),
      sair(){
        this.exit = true;
        this.ActionSignOut()
            .then(() => { this.exit = false; })
      }
    },
}
</script>