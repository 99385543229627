import { render, staticRenderFns } from "./HoursLink.vue?vue&type=template&id=18a6d886&scoped=true&"
import script from "./HoursLink.vue?vue&type=script&lang=js&"
export * from "./HoursLink.vue?vue&type=script&lang=js&"
import style0 from "./HoursLink.vue?vue&type=style&index=0&id=18a6d886&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a6d886",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardActions,VCardText,VIcon,VProgressLinear,VSimpleTable,VTooltip})
