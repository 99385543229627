<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'" persistent >
        <v-card 
                outlined
                height="75vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Filtrar por turmas
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                        <!-- <v-text-field
                        v-model="searchGroup"
                        prepend-inner-icon="search"
                        x-small
                        placeholder="Pesquisar" 
                        rounded 
                        clearable
                        autofocus
                    /> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <v-treeview 
                                v-model="groups" 
                                ref="treeGroups"
                                :items="items"
                                item-key="key"
                                item-name="name" 
                                return-object 
                                item-children="children" 
                                selectable 
                                :open-all="false"
                                :search="searchGroup"
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-grid-category
                                        :model.sync="categories"
                                        label="Categoria"
                                        multiple
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-course-modality
                                        :model.sync="modalities"
                                        label="Modalidade de ensino"
                                        multiple
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="isAnima()">
                                <v-col cols="12" sm="12">
                                    <v-select
                                        v-model="attributionTypes"
                                        item-value="id"
                                        item-text="name"
                                        :items="attibutionTypeItems"
                                        label="Tipo de Atribuição"
                                        multiple
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="isAnima()">
                                <v-col cols="12" sm="12">
                                    <sys-select-join-category
                                        :model.sync="joinTypes"
                                        label="Tipo de Junção"
                                        multiple
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-work-load-type
                                        :model.sync="workLoadTypes"
                                        label="Tipo de CH"
                                        multiple
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

                <v-card-actions>
                <v-switch inset v-model="by_courses" color="purple" label="Meus cursos"></v-switch>
                <v-switch class="ml-2" inset v-model="config.no_teacher" color="purple" label="Sem professor"></v-switch>
                <!-- <v-switch v-if="config.no_teacher" class="ml-2" inset v-model="config.in_hiring" color="purple" label="Somente À Contratar"></v-switch> -->
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                    :disabled="disableButton"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysGridFilter",
    data: (vm) => ({
        dialog: false,
        loading: false,
        items: [],
        groups: [],
        categories: [],
        modalities: [],
        workLoadTypes: [],
        attributionTypes: [],
        joinTypes: [],
        config: {
            no_teacher: false,
            in_hiring: false,
        },
        by_courses: false,
        searchGroup: null,
        attibutionTypeItems: [
            { id: 1, name: 'Principal' },
            { id: 2, name: 'Secundário' },
        ],
    }),
    mounted() {
        this.loadTree();
    },
    computed: {
        ...mapState('auth', ['acesso']),
        disableButton (){
            
            return !this.groups.length && !this.categories.length && !this.config.no_teacher && !this.modalities.length && !this.attributionTypes.length && !this.joinTypes.length && !this.workLoadTypes.length;

        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.updating = false;
            }
        },
        
        by_courses(val_new){
            this.loadTree();
        },

        searchGroup(val){
            
            this.groups = [];
            
            if(val && val != ''){
                this.$refs.treeGroups.updateAll(true);
            }else{
                this.$refs.treeGroups.updateAll(false);
            }
        },

    },
    methods: {
        ...mapActions('group', ['ActionGetGroupsTreePrint']),

        loadTree(){
           
           this.loading = true;
           this.groups = [];
           
           this.ActionGetGroupsTreePrint({ loadGrids: false, by_courses: this.by_courses })
           .then((res) => {
               
               this.items = res.data;

               this.items.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

               this.items.forEach(campus => {
                   campus.children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                   
                   campus.children.forEach(turno => {
                       turno.children.forEach(categoria => {
                           categoria.children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                       });
                   });

               });
           })
           .catch((error) =>{
               
           })
           .finally(() => {
               this.loading = false;
           });
       },

       filter(){

            var payload = {};
        
            if(this.groups && this.groups.length > 0){
                
                let ids = this.groups.map(o => o['id']).join(',');
                
                payload.filter_relations = [
                    {
                        name:   'group',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    },
                ];
            }

            if(this.categories && this.categories.length > 0){
                
                let ids = this.categories.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'category',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.modalities && this.modalities.length > 0){
                
                let ids = this.modalities.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'group.course',
                        key:    'course_modality_id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.joinTypes && this.joinTypes.length > 0){
                
                let ids = this.joinTypes.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'join.joinBase',
                        key:    'join_category_id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.config.no_teacher){
                if(this.config.in_hiring){
                    payload.find_columns = { in_hiring: this.config.in_hiring ? 1 : 0 };
                }else{
                    payload.find_columns = { teacher_id: 'isNull' };
                }
            }else{
                this.config.in_hiring = false;
            }

            if(this.attributionTypes && this.attributionTypes.length > 0){
                
                let ids = [];

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                if(this.attributionTypes.includes(1)){
                    ids.push(1);
                    ids.push(0);
                }

                if(this.attributionTypes.includes(2)){
                    for (let index = 2; index <= 20; index++){
                        ids.push(index);
                    }
                }

                payload.find_columns.teacher_number = ids.join(',');
                
            }

            if(this.workLoadTypes && this.workLoadTypes.length > 0){
                
                let ids = this.workLoadTypes.map(o => o['id']).join(',');

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.work_load_type_id = ids;

                
            }
            
            this.$emit('filter', payload);

       },

       clearFilter(){

            this.groups = [];
            this.categories = [];
            this.modalities = [];
            this.attributionTypes = [];
            this.joinTypes = [];
            this.workLoadTypes = [];
            this.by_courses = false;
            this.config.no_teacher = false;
            this.config.in_hiring = false;
            this.$refs.treeGroups.updateAll(false);
            this.$emit('filter', null);

       },

    }
}
</script>