import { service } from '../../../helpers/http'

export const ActionUpdateEmailTemplate = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'mail', 
		action: 'templateUpdate', 
		payload: payload 
	})
}

export const ActionPreviewMailAvailability = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'previewMailAvailability', 
		payload: payload,
        unformatResponse: true
	})
}

export const ActionPreviewGeneralCommunication = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'previewGeneralCommunication', 
		payload: payload,
        unformatResponse: true
	})
}

export const ActionCreateEmailTemplate = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'createEmailTemplate', 
		payload: payload
	})
}

export const ActionFindTemplate = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'findTemplate', 
		payload: payload
	})
}

export const ActionSendEmailAvailability = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'sendEmailAvailability', 
		payload: payload
	})
}

export const ActionSendGeneralCommunication = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'sendGeneralCommunication', 
		payload: payload
	})
}

export const ActionSendEmailHours = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'sendEmailHours', 
		payload: payload
	})
}

export const ActionPreviewMailHours = ({ dispatch }, payload ) => { 
    return service({ 
		module: 'mail', 
		action: 'previewMailHours', 
		payload: payload,
        unformatResponse: true
	})
}