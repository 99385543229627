<template>
    <v-dialog v-model="dialog" scrollable max-height="80%" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '30%'" persistent>
        <v-card>
            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="30" class="primary lighten-2 mb-4 elevation-1'">
                    <v-toolbar-title >
                           <span class="subtitle-1"></span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="dialog = false" >
                                    <v-icon small color="white">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>

            <v-card-text>
                <emc-avatar-webcam
                    v-show="!showCapture"
                    ref="webcam"
                    :device-id="deviceId"
                    :facingModeFrontal="facingModeFrontal"
                    width="100%"
                    height="100%"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                />

                <figure v-show="showCapture" style="border: 3px solid green; border-radius: 5px;">
                    <img  width="100%" :src="img" class="img-responsive" />
                </figure>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="false && !showCapture"
                    class="d-flex d-sm-none mr-4"
                    color="white lighten-2"
                    fab
                    x-small
                    @click="facingModeFrontal = !facingModeFrontal"
                >
                    <v-icon >mdi-camera-retake-outline</v-icon>
                </v-btn>
                <v-btn
                    v-if="!showCapture"
                    color="primary lighten-2"
                    fab
                    small
                    @click="onCapture()"
                >
                    <v-icon medium>mdi-camera</v-icon>
                </v-btn>
                <v-btn
                    v-if="showCapture"
                    color="error"
                    icon
                    small
                    @click="showCapture = false"
                    class="mr-5"
                    
                >
                    <v-icon large>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                    v-if="showCapture"
                    color="success"
                    icon
                    small
                    @click="onSelect()"
                >
                    <v-icon large>mdi-check-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
         </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: "EmcUserCapture",
    data() {
        return {
            dialog: false,
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
            showCapture: false,
            facingModeFrontal: true,
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
    },

    watch: {
        
        show(val){
            this.dialog = val;
        },

        dialog(val){
            
            this.$emit('update:show', val);

            if(val){
                if(this.$refs.webcam){
                    this.onStart();
                }
            }else{
                this.onStop();
            }
        },
        
        camera: function(id) {
            this.deviceId = id;
        },

        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;  
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    methods: {

        onCapture() {
            this.img = this.$refs.webcam.capture();
            this.showCapture = true;
        },
        onStarted(stream) {
            
        },
        onStopped(stream) {
            
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
            this.showCapture = false;
        },
        onError(error) {
            
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },

        onSelect(){

            this.dialog = false;
            this.$emit('onSelect', this.img);
            this.completeCapture( this.img);

        },

        completeCapture(img){
            
            var instance = this;
            
            this.urltoFile(img, null)
                .then(function(file){
                    instance.$emit('completeCapture', file);
                });
        },

        urltoFile(url, filename, mimeType){
            mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
            return (fetch(url)
                .then(function(res){return res.arrayBuffer();})
                .then(function(buf){return new File([buf], filename, {type:mimeType});})
            );
        },
    }
};
</script>