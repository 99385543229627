import { service } from '../../../helpers/http'

export const ActionFindEventTypes = ({ dispatch }, payload) => {
    return service({
        module: 'eventType',
        action: 'eventTypes',
        payload: payload
    })
}

export const ActionCreateEventType = ({ dispatch }, payload) => {
    return service({
        module: 'eventType',
        action: 'createEventType',
        payload: payload
    })
}

export const ActionUpdateEventType = ({ dispatch }, payload) => {
    return service({
        module: 'eventType',
        action: 'updateEventType',
        payload: payload
    })
}

export const ActionDeleteEventType = ({ dispatch }, payload) => {
    return service({
        module: 'eventType',
        action: 'deleteEventType',
        payload: payload
    })
}