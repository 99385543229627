import { service } from '../../../helpers/http'

export const ActionFindCurriculumCategories = ({ dispatch }, payload) => {
    return service({
        module: 'curriculumCategory',
        action: 'curriculumCategories',
        payload: payload
    })
}

export const ActionCreateCurriculumCategory = ({ dispatch }, payload) => {
    return service({
        module: 'curriculumCategory',
        action: 'createCurriculumCategory',
        payload: payload
    })
}

export const ActionUpdateCurriculumCategory = ({ dispatch }, payload) => {
    return service({
        module: 'curriculumCategory',
        action: 'updateCurriculumCategory',
        payload: payload
    })
}

export const ActionDeleteCurriculumCategory = ({ dispatch }, payload) => {
    return service({
        module: 'curriculumCategory',
        action: 'deleteCurriculumCategory',
        payload: payload
    })
}


