export default [
    {
        path: '/category_groups',
        name: 'category_groups',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Categoria da turma',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Categoria da turma', disabled: true, href: '/' }
            ],
        }
    },
]