<template>
    <v-dialog v-model="dialog" scrollable :max-height="maxHeight" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>mdi-email-newsletter</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                Pesquisa de CEP
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small class="mr-1" @click.native="dialog = false" :disabled="loading" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>

                <v-card-text class="pt-0">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="4"> 
                                <sys-select-states
                                    :modelObject.sync="state"
                                    label="Estado*"
                                    autoComplete
                                    filled
                                    autofocus
                                />
                            </v-col>
                            <v-col cols="12" sm="8"> 
                                <v-autocomplete
                                    v-model="city"
                                    :disabled="!state || !state.id || loading"
                                    :loading="loading"
                                    :items="cities"
                                    item-text="name"
                                    item-value="name"
                                    filled
                                    color="blue-grey lighten-2"
                                    label="Município*"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field 
                                    v-model="address" 
                                    filled
                                    label="Endereço*"
                                    append-icon="mdi-magnify"
                                    @click:append="searchZipCode()"
                                    v-on:keyup.enter="searchZipCode()"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :loading="loadingTable"
                                    :items="results"
                                    :single-select="singleSelect"
                                    item-key="cep"
                                    show-select
                                    class="elevation-1"
                                    fixed-header
                                    height="250"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.prevent.stop="$emit('onSelect', selected[0])" :loading="loading" :disabled="selected.length == 0" >Selecionar CEP<v-icon right dark>mdi-check-bold</v-icon></v-btn>
                </v-card-actions>

            </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
    name: "SysAddressFind",
    data: () => ({
        dialog: false,
        loading: false,
        loadingTable: false,
        cities: [],
        results: [],
        state: {},
        city: null,
        address: null,
        singleSelect: true,
        selected: [],
        headers: [
          { text: 'Logradouro', value: 'logradouro' },
          { text: 'Bairro', align: 'start',value: 'bairro'},
          { text: 'Complemento', value: 'complemento' },
          { text: 'Cep', value: 'cep' },
        ],
    }),

    props: {
        show: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: String,
            default: "60%"
        },
        maxHeight:{
            type: String,
            default: "auto"
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
    },

    created(){
      
    },

    watch: {
        
        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.state = {};
                this.city = null;
                this.address = null;
                this.results = [];
                this.selected = [];
            }
        },

        state(){
            this.loadCities();
        }
    },

    methods:{
        ...mapActions('city', ['ActionSearchCities']),

        loadCities(){
            
            if(!this.state || !this.state.id){
                return;
            }
            
            this.loading = true;
            
            var payload = {
                find_columns: {
                    state_id: this.state.id
                },
                showAllCities: true
            }
        
            this.ActionSearchCities(payload)
                .then((res) => {
                    this.cities = res.data;
                })
                .finally(() => {
                   this.loading = false;
                });
        },
        
        searchZipCode(){
            
            if(!this.state || !this.state.initials || !this.city || this.city.length < 3 || !this.address || this.address.length < 3){
                return;
            }
            
            this.loadingTable = true;
            var url = 'https://viacep.com.br/ws/' + this.state.initials + '/' + this.city + '/' + this.address + '/json/';
            this.results = [];
            this.selected = [];
                
            axios.get(url)
                    .then(res => {
                        if(res.status && res.status == 200){
                           this.results = res.data;
                        }
                    })
                    .catch((error) =>{
                       
                    })
                    .finally(() => {
                        this.loadingTable = false;
                    });
        }
      
    }
    
}
</script>