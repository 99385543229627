export default [
    {
        path: '/courses',
        name: 'CourseIndex',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Cursos',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Cursos', disabled: true, href: '/' },
            ],
        }
    },
    {
        path: '/qualifiers',
        name: 'Qualifier',
        component: () => import ('./pages/Qualifier'),
        meta: {
            title: 'Qualificadores',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Qualificadores', disabled: true, href: '/' },
            ],
        }
    },
]