<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            :showClear="showClear"
            :showAdd="showAdd"
            :dense="dense"
            @change="$emit('change', $event)"
        />
        
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectOccurrenceSheetStatus',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        showClear: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('timeSheet', ['ActionIndexOccurrenceSheetStatus']),
        
        async getItems() {

            this.loading = true

            await this.ActionIndexOccurrenceSheetStatus()
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

    
    },

    
    
}
</script>