var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.grid)?_c('v-card',{class:_vm.getClassCard(),attrs:{"transition":"fab-transition","color":_vm.grid.color,"dark":"","draggable":(!_vm.user.is_master && _vm.locked) ? false : true,"width":"160px","height":"70px"},on:{"mouseover":function($event){_vm.actions = true; _vm.$emit('mouseover', $event)},"mouseleave":function($event){_vm.actions = false; _vm.$emit('mouseleave', $event)},"dragstart":function($event){return _vm.dragstartCard($event)},"dragend":function($event){return _vm.dragendCard($event)},"dblclick":function($event){return _vm.deleteCard(_vm.classe)},"click":function($event){return _vm.clickCard()}}},[_c('v-card-actions',[(_vm.grid.category && !_vm.grid.category.default)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mb-1",staticStyle:{"position":"absolute","bottom":"0px","left":"45px","cursor":"help"},attrs:{"color":"white","label":"","x-small":"","outlined":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.grid.category.initials)+" ")])]}}],null,false,3816940005)},[_c('span',[_vm._v(_vm._s(_vm.grid.category.name))])]):_vm._e(),(_vm.grid.join)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","bottom":"4px","left":"5px","cursor":"help"},attrs:{"small":"","left":""},on:{"click":function($event){return _vm.$emit('click', _vm.grid)}}},on),[_vm._v(" mdi-call-split ")])]}}],null,false,312722257)},[(_vm.grid.join && _vm.grid.join.grids)?_c('span',[[_c('ul',{attrs:{"type":"none"}},_vm._l((_vm.grid.join.grids),function(grid){return _c('li',{key:grid.id},[(grid.group)?_c('span',[_vm._v(_vm._s(grid.group.campus.name + ' | ' + grid.group.name + ' | ' + grid.discipline_name))]):_vm._e()])}),0)]],2):_c('span',[_vm._v("Carregando informações...")])]):_vm._e(),(_vm.classe.start_date)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","top":"2px","right":"1.5px","cursor":"help"},attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")])]}}],null,false,3584918220)},[_c('span',[(_vm.classe.start_date == _vm.classe.end_date)?_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.classe.start_date)))]):_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.classe.start_date))+" - "+_vm._s(_vm._f("date")(_vm.classe.end_date)))])])]):_vm._e(),(_vm.grid.ambience)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:(_vm.grid.join ? 'position: absolute; bottom: 4px; left: 20px; cursor: help' : 'position: absolute; bottom: 4px; left: 5px; cursor: help'),attrs:{"small":"","left":""}},on),[_vm._v(" "+_vm._s(( _vm.classe && _vm.classe.ambience && _vm.classe.ambience.type && _vm.classe.ambience.type.is_virtual) ? 'mdi-monitor-dashboard' : 'mdi-home')+" ")])]}}],null,false,2119849361)},[_c('span',[_vm._v(_vm._s(_vm.getAmbienceClasse()))])]):_vm._e(),(_vm.grid.work_time_date)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:((_vm.grid.join && _vm.grid.ambience) ? 'position: absolute; bottom: 4px; left: 35px; cursor: help' : (_vm.grid.join || _vm.grid.ambience) ? 'position: absolute; bottom: 4px; left: 20px; cursor: help' : 'position: absolute; bottom: 4px; left: 5px; cursor: help'),attrs:{"small":"","left":""}},on),[_vm._v(" event ")])]}}],null,false,3079503081)},[_c('span',[_vm._v(_vm._s(_vm.grid.work_time_date.name))])]):_vm._e(),_c('v-spacer'),(_vm.locked || (!_vm.hideActions && _vm.actions))?_c('div',[(_vm.locked == false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","bottom":"4px","right":"-5px"},attrs:{"small":"","left":"","color":_vm.locked ? 'transparent' : 'with'},on:{"click":function($event){return _vm.deleteCard(_vm.classe)}}},on),[_vm._v(" mdi-delete ")])]}}],null,false,12989859)},[_c('span',[_vm._v("Remover")])]):_vm._e(),(_vm.showLock)?_c('div',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","bottom":"4px","right":"15px"},attrs:{"small":"","left":""},on:{"click":function($event){$event.stopPropagation();return _vm.lockCard()}}},on),[_vm._v(" "+_vm._s(_vm.locked ? 'mdi-lock' : 'mdi-lock-open-variant')+" ")])]}}],null,false,228090481)},[_c('span',[_vm._v("Bloqueio")])])],1):_vm._e()],1):_vm._e()],1),(_vm.showData || _vm.actions)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-card-title',{staticClass:"pa-0",staticStyle:{"position":"absolute","top":"2px","left":"5px","font-size":"1em"}},[(_vm.grid.grid_note)?_c('emc-info-help',{attrs:{"icon":"mdi-information-outline","title":"Observação","text":_vm.grid.grid_note}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getNameDiscipline(_vm.grid))+" ")],1),(_vm.grid && (_vm.grid.teacher || _vm.grid.in_hiring))?_c('v-card-subtitle',{staticClass:"pa-0",staticStyle:{"position":"absolute","top":"44px","left":"6px","font-size":"1em"}},[(_vm.grid.teacher)?_c('span',[_vm._v(_vm._s(_vm.getFirstName(_vm.grid.teacher.name, _vm.widthTeacher)))]):_c('span',[_vm._v("A Contratar")])]):_vm._e()],1)]}}],null,false,298028083)},[_c('span',[_vm._v(" "+_vm._s(_vm.getNameDisciplineToolTip(_vm.grid))+" "),_c('br'),(_vm.grid && _vm.grid.teacher)?_c('span',[_vm._v(" "+_vm._s(_vm.grid.teacher.name)+" ")]):_vm._e(),(_vm.grid.dates && _vm.grid.dates.length > 0)?_c('span',[_c('ul',{attrs:{"type":"none"}},_vm._l((_vm.grid.dates),function(date,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(date.initials)+" : "+_vm._s(_vm._f("date")(date.date)))])])}),0)]):_vm._e()])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }