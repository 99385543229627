import { default as specificity } from './specificity/services'
import { default as dateMask } from './date_mask/services'
import { default as dateMaskType } from './date_mask_type/services'
import { default as modality } from './modality/services'
import { default as offer } from './offer/services'
import { default as offer_analitics } from './offer_analitics/services'

export default {
    ...offer,
    ...offer_analitics,
    ...specificity,
    ...dateMask,
    ...dateMaskType,
    ...modality,
    find:                   { method: 'get',     url: 'grids' },
    findById:               { method: 'get',     url: 'grids/{id}' },
    periodicityTypes:       { method: 'get',     url: 'periodicity_types'},
    gridCreate:             { method: 'post',    url: 'grids' },
    gridUpdate:             { method: 'put',     url: 'grids/{id}' },
    gridDelete:             { method: 'delete',  url: 'grids/{id}' },
    gridCreateDivision:     { method: 'post',    url: 'grids/division'},
    gridDeleteDivision:     { method: 'delete',  url: 'grids/division/{id}' },
    gridsGroupsHourDate:    { method: 'get',     url: 'grids/groups/date'},
    gridsShiftsHourDate:    { method: 'get',     url: 'grids/shifts/date'},
    gridAddTeacher:         { method: 'post',    url: 'grids/add_teacher'},
    gridAddTeacherAll:      { method: 'post',    url: 'grids/add_teacher/all'},
    gridDeleteAddTeacher:   { method: 'delete',  url: 'grids/add_teacher/{id}' },
    indexGridCategories:    { method: 'get',     url: 'grid_categories' },
    createGridCategory:     { method: 'post',    url: 'grid_categories' },
    updateGridCategory:     { method: 'put',     url: 'grid_categories/{id}' },
    deleteGridCategory:     { method: 'delete',  url: 'grid_categories/{id}' },
    indexOptimized:         { method: 'get',     url: 'grids/optimized' },
    findGridDates:          { method: 'get',     url: 'grid_dates' },
    deleteGridDate:         { method: 'delete',  url: 'grid_dates/{id}' },
    updateHiring:           { method: 'put',     url: 'grids/{id}/hiring' },
    gridLockAttribution:    { method: 'put',     url: 'grids/{id}/lock_attribution' },

}