import Vue from 'vue'

//Rotas
import router from './router/router'

//Components
import './components'

//Plugins
import './plugins'
import vuetify from '@/plugins/vuetify'

//Filters
import './filters'

//Helpers
import './helpers'

//Home App
import App from './App.vue'

//Vuex
import store from './store'

Vue.config.productionTip = false

window._Vue = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

