<template>
  <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="model"
      :title="title"
      :icon="icon"
      v-bind:show.sync="dialog"
      maxWidth="50%"
  >
    <template v-slot:form>
      <v-row class="mt-2">
        <v-col cols="12" sm="12">
          <v-text-field dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea label="Descrição" v-model="model.description"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-switch v-model="model.active" color="primary" label="Ativo" inset dense></v-switch>
          <v-switch v-model="model.has_charge" color="primary" label="Possui cobrança" inset dense></v-switch>
        </v-col>
      </v-row>
    </template>
  </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: "SysRegisterPlanType",
  data: (vm) => ({
    dialog: false,
    error: false,
    errors: {},
    message: '',
    loading: false,
    title: 'Tipo de plano',
    icon: 'mdi-format-list-bulleted-type'

  }),
  watch: {
    dialog(dialog_new, dialog_old) {
      if(dialog_new !== dialog_old) {
        this.$emit('update:show', dialog_new)
      }
    },
    show(show_new, show_old) {
      this.dialog = show_new;
      if(show_new){
        this.error = false;
        this.errors = {};
      }
    },
  },
  props: {
    model: {
      default: null
    },
    color: {
      type: String,
      default: 'grey lighten-5'
    },
    width: {
      type: String,
      default: "500"
    },
    maxWidth: {
      type: String,
      default: "80%"
    },
    text: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    ...mapActions('plan', ['ActionUpdatePlanType', 'ActionStorePlanType']),

    save(edit)
    {
      this.error = false;
      this.message = '';
      this.loading = true;

      if(edit) {
        this.ActionUpdatePlanType(this.model)
            .then((res) => {
              this.message = res.message;
              this.$emit('onCompleted');
            })
            .catch((error) =>{
              this.error      = true;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false;
            })
      } else {
        this.ActionStorePlanType(this.model)
            .then((res) => {
              this.message = res.message;
              this.$emit('onCompleted', res.data);
            })
            .catch((error) =>{
              this.error      = error;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false;
            })

      }
    },

  }
}
</script>