import { default as modules } from './module/routes'
import { default as plan_types } from './plan_type/routes'
import { default as plan } from './plan/routes'
import { default as charge_type } from './charge_type/routes'

export default [
    ...modules,
    ...plan_types,
    ...plan,
    ...charge_type
]