import { service } from '../../../helpers/http'
export * from '../big_occupation_area/store/actions'

export const ActionFindOccupationAreas = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'occupationAreas', 
		payload: payload 
	})
}

export const ActionCreateOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'occupationAreaCreate', 
		payload: payload 
	})
}

export const ActionUpdateOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'occupationAreaUpdate', 
		payload: payload 
	})
}

export const ActionDeleteOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'occupationAreaDelete', 
		payload: payload 
	})
}