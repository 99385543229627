import { service } from '../../../../helpers/http'

export const ActionIndexPaymentTypeSettings = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'indexPaymentTypeSettings', 
		payload: payload 
	})
}

export const ActionCreatePaymentTypeSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'createPaymentTypeSetting', 
		payload: payload 
	})
}

export const ActionUpdatePaymentTypeSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'updatePaymentTypeSetting', 
		payload: payload 
	})
}

export const ActionDestroyPaymentTypeSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'destroyPaymentTypeSetting', 
		payload: payload 
	})
}