export default [
    {
        path: '/availability',
        name: 'availability',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Disponibilidade',
            icon: '',
            breadcrumbs: [
                { text: 'Horário', disabled: true, href: '/' }, 
                { text: 'Disponibilidade', disabled: true, href: '/' }, 
            ],
        }    
    },
    {
        path: '/availability-teacher',
        name: 'availability-teacher',
        component: () => import ('./pages/Index')
    }
]