export default [
    {
        path: '/disciplineTypes',
        name: 'disciplineTypes',
        component: () => import ('./pages/DisciplineTypeRegister'),
        meta: {
            title: 'Tipo de disciplina',
            icon: '',
            breadcrumbs: [
                { text: 'Matriz currícular', disabled: true, href: '/' },
                { text: 'Tipo de disciplina', disabled: true, href: '/' },
            ],
        }
    },
]