import { default as panel } from './panel/services'
import { default as work_load_history } from  './work_load_history/services'
import { default as work_load_balance } from  './work_load_balance/services'
import { default as consistency } from  './consistency/services'
import { default as importItem } from  './import_item/services'

export default {
    ...panel,
    ...work_load_history,
    ...work_load_balance,
    ...consistency,
    ...importItem,
}