import { service } from '../../../../helpers/http'

export const ActionIndexOccurrenceSheetItems = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'indexOccurrenceSheetItems',
        payload: payload
    })

}
export const ActionDestroyOccurrenceSheetItem = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'destroyOccurrenceSheetItem',
        payload: payload
    })
}
export const ActionStoreOccurrenceSheetItem = ({ dispatch }, payload ) => {
    return service({
        module: 'timeSheet',
        action: 'storeOccurrenceSheetItem',
        payload: payload
    })

}
export const ActionUpdateOccurrenceSheetItem = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'updateOccurrenceSheetItem',
        payload: payload
    })
}

export const ActionStoreRegisterSheetItem = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'storeRegisterSheetItem',
        payload: payload,
    })
}