<template>
    <v-dialog v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '80%'" persistent>
        <v-card height="600px" max-height="600px">
            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="35" class="primary lighten-2 mb-4 elevation-1">
                    <v-toolbar-title >
                        <span class="subtitle-1"></span>
                    </v-toolbar-title>
                    <v-tooltip v-if="items && items.length > 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="openImage" >
                                <v-icon small color="white">mdi-folder-image</v-icon>
                            </v-btn>
                        </template>
                        <span>Carregar Foto</span>
                    </v-tooltip>
                    <v-tooltip v-if="items && items.length > 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="showCamera = true" >
                                <v-icon small color="white">mdi-camera</v-icon>
                            </v-btn>
                        </template>
                        <span>Tirar Foto</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                        <strong v-if="model" class="text-subtitle-1 white--text">{{ model.name }}</strong>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="dialog = false" >
                                    <v-icon small color="white">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-row
                    v-if="!overlay && (items && items.length == 0)"
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        
                        Nenhuma imagem disponível!
                        <br/>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon x-large @click.native="openImage" >
                                        <v-icon x-larg color="cyan">mdi-folder-image</v-icon>
                                    </v-btn>
                                </template>
                                <span>Carregar Foto</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon x-large @click.native="showCamera = true" >
                                        <v-icon x-large color="cyan">mdi-camera</v-icon>
                                    </v-btn>
                                </template>
                                <span>Tirar Foto</span>
                            </v-tooltip>
                      
                    </v-col>
                </v-row>
                <v-row
                    v-if="overlay"
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                    >
                     Carregando imagens...
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col
                        v-for="(item, i) in items"
                        :key="i"
                        class="d-flex child-flex"
                        cols="4"
                    >
                        <v-card>
                            <v-img
                                :src="item.url_path"
                                aspect-ratio="1"
                                class="grey lighten-2"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-card-title>
                            
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :loading="loading && i == index" x-small v-bind="attrs" v-on="on" icon @click.native="openDelete(item, i)" >
                                        <v-icon small color="error">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                            
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>
            </v-card-text>
        </v-card>
        
        <form v-show="false" ref="form">
            <v-file-input
                id="inputPhoto"
                v-model="files"
                @change="onFiledPicked"
                :rules="rules"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pick an photo"
                prepend-icon="mdi-camera"
                label="Photo"
                multiple
            />
        </form>

        <emc-user-capture :show.sync="showCamera" @completeCapture="upload([$event])" />

        <emc-alert-modal text="Confirma a exclusão da imagem?" v-bind:show.sync="showDelete">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" >Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysUserPhotos",
    data: (vm) => ({
        dialog: false,
        files: null,
        edit: false,
        loading: false,
        loadingMain: false,
        items: [],
        itemDelete: null,
        overlay: false,
        showDelete: false,
        index: -1,
        error: false,
        errors: [],
        showCamera: false,
        rules: [
            value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!',
        ],
    }),
    created(){

    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        show(val){
            
            this.dialog = val;

            if(val){
                this.overlay = false;
                this.edit = false;
                this.index = -1;
                this.getData();
            }
        },

        dialog(val){
            this.$emit('update:show', val);
        },
        
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        model: {
            required: true
        },
    },
    methods:{
        ...mapActions('user', ['ActionIndexUserPhotos', 'ActionDestroyUserPhoto', 'ActionCreateUserPhoto']),

        getData(){
            
            this.items = [];

            if(!this.model || !this.model.number_cpf){
                return;
            }

            let cpf = this.model.number_cpf.replace(/\.|\-/g, '');

            let payload = {
                find_columns: { number_cpf: (cpf ? cpf : 0) },
            };

            this.ActionIndexUserPhotos(payload)
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.overlay = false;
                    this.files = null;
                })
        },

        onFiledPicked(files){
            
            if(files){
                this.upload(files);
            }
        },
        
        upload(files)
        {
            let formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                formData.append('photos[' + i + ']', files[i]);
            }

            formData.append('number_cpf', this.model.number_cpf);
            this.overlay = true;
            
            this.ActionCreateUserPhoto(formData)
                .then((res) => {
                    this.getData();
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.overlay = false;
                    this.files = null;
                })
        },

        openDelete(item, i){
            
            this.itemDelete = item;
            this.index = i;
            this.showDelete = true;
        },

        deleteItem()
        {
            this.showDelete = false;
            this.loading = true;
            
            this.ActionDestroyUserPhoto({ id: this.itemDelete.id })
                .then((res) => {
                    this.getData();
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.loading = false;
                    this.index = -1;
                    this.itemDelete = null;
                })
        },

        openImage(){
            var element = document.getElementById('inputPhoto');
            element.click();
        },

    }
}
</script>