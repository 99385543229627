export default [
    {
        path: '/campuses',
        name: 'campuses',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Campus',
            icon: 'mdi-warehouse',
            breadcrumbs: [
                { text: 'Instituição', disabled: true, href: '/' },
                { text: 'Campus', disabled: true, href: '/' }
            ],
        }
    },
]