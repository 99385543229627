import { service } from '../../../../helpers/http'

export const ActionIndexDateMasks = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexDateMasks',
        payload: payload
    })

}
export const ActionDestroyDateMask = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyDateMask',
        payload: payload
    })
}
export const ActionStoreDateMask = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeDateMask',
        payload: payload
    })

}
export const ActionUpdateDateMask = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateDateMask',
        payload: payload
    })
}