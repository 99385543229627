<template>
    <v-dialog v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <slot name="body"></slot>
    </v-dialog>
</template>

<script>
export default {
    name: 'EmcBaseDialog',
    props: {
        dialog: {
            type: Boolean,
            default: false,
            required: true
        },
        maxWidth: {
            type: String,
            default: "80%"
        }
    }
}
</script>