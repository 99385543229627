<template>
    <div>
        <emc-avatar-base 
            :src="model.url_company_logo" 
            @onComplete="upload"
            :width="width"
            :height="height"
            :model="model"
            tile
            :showCamera="false"
            iconDefault="mdi-image-outline"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "EmcAvatarInstitution",
    data() {
        return {
           
        };
    },
    props: {
        model: {
            required: true
        },
        width: {
            default: 100
        },
        height: {
            default: 100
        },
    },

    watch: {
      
    },
    computed: {
      
    },
    methods: {
        ...mapActions('institution', ['ActionInstitutionUploadCompanyLogo']),
       
        upload(form){

            form.append('id', this.model.id);
            
            this.ActionInstitutionUploadCompanyLogo(form)
                .then((res) => {
                   this.model.url_company_logo = res.data.url_company_logo;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                });

        },
    }
};
</script>