import { service } from '../../../helpers/http'

export const ActionFindMenus = ({ dispatch }, payload) => {

    return service({
        module: 'menu',
        action: 'findMenus',
        payload: payload
    })
}

export const ActionStoreMenu = ({ dispatch }, payload) => {

    return service({
        module: 'menu',
        action: 'store',
        payload: payload
    })

}

export const ActionUpdateMenu = ({ dispatch }, payload) => {

    return service({
        module: 'menu',
        action: 'update',
        payload: payload
    })

}

export const ActionDestroyMenu = ({ dispatch }, payload) => {

    return service({
        module: 'menu',
        action: 'destroy',
        payload: payload
    })

}
