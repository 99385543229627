var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.dates,"hide-default-footer":"","items-per-page":-1,"item-key":"index"},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.model || !_vm.model.id,"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.frequency_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('sys-select-frequency',{attrs:{"model":item.frequency_id,"error":_vm.errors.length > 0,"errors":_vm.errors['dates.' + index + '.frequency_id'],"label":"","dense":"","filter":[2,3,5]},on:{"update:model":function($event){return _vm.$set(item, "frequency_id", $event)},"change":function($event){item.weeks = $event.weeks}}})]}},{key:"item.code",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('s-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['dates.' + index + '.code'],"dense":"","code":""},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['dates.' + index + '.name'],"dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.initials",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"dense":"","error":_vm.errors.length > 0,"error-messages":_vm.errors['dates.' + index + '.initials']},model:{value:(item.initials),callback:function ($$v) {_vm.$set(item, "initials", $$v)},expression:"item.initials"}})]}},{key:"item.period",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker-range',{attrs:{"errorMessages1":_vm.errors['dates.' + index + '.start'],"errorMessages2":_vm.errors['dates.' + index + '.end'],"show":_vm.dialog,"model1":item.start,"model2":item.end,"min":_vm.model.start,"max":_vm.model.end},on:{"update:model1":function($event){return _vm.$set(item, "start", $event)},"update:model2":function($event){return _vm.$set(item, "end", $event)}}})]}},{key:"item.classes",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker-range',{attrs:{"errorMessages1":_vm.errors['dates.' + index + '.classes_start'],"errorMessages2":_vm.errors['dates.' + index + '.classes_end'],"show":_vm.dialog,"model1":item.classes_start,"model2":item.classes_end,"min":item.start,"max":item.end},on:{"update:model1":function($event){return _vm.$set(item, "classes_start", $event)},"update:model2":function($event){return _vm.$set(item, "classes_end", $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"loading":_vm.loading && _vm.indexDelete == index,"disabled":_vm.loading,"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item, index)}}},[_c('v-icon',{attrs:{"small":"","color":"error","dense":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }