<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        :maxWidth="maxWidth"
    >
        <template v-slot:form>
            <v-container>
                    <v-row v-if="selected && selected.filter(i => i.exported).length">
                        <v-col cols="12" sm="12">
                            <v-alert
                                dense
                                outlined
                                type="error"
                                >
                                    <span v-if="statusId == 7">Atividade exportada na folha de pagamento. Ao editar a data fim a atividade será finalizada.</span>
                                    <span v-else>Atividade exportada na folha de pagamento. Ao remover será gerado um desconto retroativo.</span> 
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-activity-item-status
                                :model.sync="model.activity_item_status_id"
                                :error="errors.activity_item_status_id > 0"  
                                :errors="errors.activity_item_status_id"
                                label="Status*"
                            /> 
                        </v-col>
                    </v-row>
                    <v-row v-if="statusId == 7">
                        <v-col cols="12" sm="6">
                                <emc-calendar-date-picker2 
                                    :model.sync="model.date_start" 
                                    label="Início*" 
                                    :errorMessages="errors.date_start"
                                    :show="dialog"
                                    disabled
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <emc-calendar-date-picker2 
                                    :model.sync="model.date_end" 
                                    label="Fim*"
                                    :errorMessages="errors.date_end" 
                                    :show="dialog"
                                />
                            </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note_approval" 
                                :error-messages="errors.note_approval" 
                                v-model="model.note_approval" 
                                label="Observação"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                clearable
                                autofocus
                            />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterActivityItemApproval",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        model: {},
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.error = false;
                this.errors = {};
                
                let itemSelected = this.selected.length == 1 ? this.selected[0] : null;

                this.model = {
                    activity_item_status_id:    this.statusId,
                    note_approval:              itemSelected ? itemSelected.note_approval : null,
                    date_start:                 itemSelected ? itemSelected.date_start : null,
                    date_end:                   itemSelected ? itemSelected.date_end : null,
                };
            }
        },
        
    },
    props: {
        selected: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "30%"
        },
        icon: {
            type: String,
            default: "mdi-thumb-up"
        },
        title: {
            type: String,
            default: "Aprovação de Atividades"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        statusId: {
            required: true
        }
    },
    methods:{
        ...mapActions('activityItem', ['ActionUpdateActivityItemsApprovals']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            this.model.items = this.selected.map(i => i.id);

            this.ActionUpdateActivityItemsApprovals(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                });
           
        },

    }
}
</script>