<template>
    <v-card outlined max-height="350" class="pb-2 pa-0 mt-2">
          <v-app-bar class="elevation-0" dense>
              <v-toolbar-title class="text-body-2">
                    <strong>Legenda</strong>
              </v-toolbar-title>
          </v-app-bar>
          <v-divider class="mb-2"></v-divider>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon color="success">mdi-checkbox-marked-circle</v-icon>Horário da Turma</span>
                </template>
                <span>Horário da Turma</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon color="success">mdi-checkbox-marked-circle</v-icon>Horário do Ambiente</span>
                </template>
                <span> Horário do Ambiente </span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon color="success">mdi-checkbox-marked-circle</v-icon>Choque de Ambiente</span>
                </template>
                <span> Horário disponível pelo ambiente </span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon color="success">mdi-checkbox-marked-circle</v-icon>Horário Disponível</span>
                </template>
                <span> Horário ocupado pelo ambiente </span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span class="pb-2 pl-1 pr-0 grey--text text--darken-1" style="cursor: help;" v-bind="attrs" v-on="on"><v-icon color="success">mdi-checkbox-marked-circle</v-icon>Já alocado</span>
                </template>
                <span> Disciplina já alocada no ambiente </span>
              </v-tooltip>
            </div>
      </v-card>
</template>

<script>

export default {
    name: "SysAmbienceLegendLink",
    data: () => ({
      
    }),
    mounted(){
      
    },
    computed: {
       
    },
    watch: {

    },
    props: {
        
    },
    methods: {

    }
}
</script>