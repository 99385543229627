import { service } from '../../../helpers/http'

export const ActionFindCategoryAmbiences = ({ dispatch }, payload) => {

    return service({
        module: 'categoryAmbience',
        action: 'categoryAmbiences',
        payload: payload
    })
    
}

export const ActionCreateCategoryAmbience = ({ dispatch }, payload) => {

    return service({
        module: 'categoryAmbience',
        action: 'createCategoryAmbience',
        payload: payload
    })
}

export const ActionUpdateCategoryAmbience = ({ dispatch }, payload) => {

    return service({
        module: 'categoryAmbience',
        action: 'updateCategoryAmbience',
        payload: payload
    })
}

export const ActionDeleteCategoryAmbience = ({ dispatch }, payload) => {

    return service({
        module: 'categoryAmbience',
        action: 'deleteCategoryAmbience',
        payload: payload
    })
}