<template>
    <div>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :loading="loading" :disabled="loading" :color="color" icon @click.prevent.stop="exportEmail()" v-bind="attrs" v-on="on"><v-icon>mdi-email-send-outline</v-icon></v-btn>
                </template>
                <span>{{ toolTip }}</span>
            </v-tooltip>
            <!-- <v-snackbar
                v-model="snackbar"
                :color="error ? 'error' : 'success'"
                style="z-index: 99 !important;"
            >
                {{ message }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Fechar
                    </v-btn>
                </template>
            </v-snackbar> -->
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "SIconReportEmail",
    data () {
        return {
            loading: false,
            snackbar: false,
            error: false,
            message: null,
        }
    },
    props: {
       report: {
            Type: Number,
            required: true,
        },
        color: {
            type: String,
            default: 'purple darken-1',
        },
        toolTip: {
            type: String,
            default: 'Relatório por e-mail',
        },

    },

    methods: {
        ...mapActions('controlPanel', ['ActionExportEmail']),

        exportEmail(){

            this.loading = true;
            this.error = false;
            this.message = null;
            
            this.ActionExportEmail({ id: this.report  })
                .then((res) => {
                    this.message = 'Enviado Email!';
                    this.$emit('sendMail', false, res.message);
                }).catch((error) => {
                    this.message = 'Erro ao enviar Email!';
                    this.error = true;
                    this.$emit('sendMail', true, error.message);
                }).finally(() => {
                    this.loading = false;
                    //this.snackbar = true;
                });
        },
        
    }
}
</script>
