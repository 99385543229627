var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.renderComponent)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.model.resources,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.resource",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('sys-select-resource',{attrs:{"model":item.resource_id,"error":_vm.errors.length > 0,"errors":_vm.errors['resources.' + index + '.resource_id'],"label":"","dense":"","auto-complete":"","item-text":"name_complete","ambience-id":_vm.ambienceId},on:{"update:model":function($event){return _vm.$set(item, "resource_id", $event)}}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['resources.' + index + '.amount'],"dense":""},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})]}},{key:"item.note",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['resources.' + index + '.note'],"dense":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"error","dense":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,4211572806)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }