<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="innerLoading || loading"
            :label="label"
            :error="error"
            :errors="errors"
            :showClear="showClear"
            :itemText="itemText"
            :disabled="disabled || innerLoading || loading"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
            :returnObject="returnObject"
            :outlined="outlined"
            :dense="dense"
        />
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'SysSelectWorkTime',
    data: () => ({
        items: [],
        innerLoading: false,
        selected: null
    }),
    created() {
        this.getItems();
    },
    props: {
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        itemText: {
            type: String,
            default: 'name'
        },
        showClear: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        filterActive: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('worktime', ['ActionGetWorkTimes']),        
        
        async getItems() {

            this.innerLoading = true

            let payload = {
                column_order: 'name',
                direction: 'desc'
            }

            if(this.filterActive){
                payload.find_columns = {
                    active: 1
                }
            }

            await this.ActionGetWorkTimes(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.innerLoading = false;
                    })
        },

        newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = this.returnObject ? item : item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>