<template>
    <v-row>
          <v-col cols="4" v-for="(item, key ) in collections" :key="key">
              <v-card>
          <v-alert
            outlined
            :color="item.active ? `green` : `red`"
          > 
          <v-list>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  <v-icon :color="item.active ? `green` : `red`">{{ item.active ? `mdi-account-check` : `mdi-account-lock`}} </v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  <span
                    class="d-inline-block text-truncate"
                    style="max-width: 260px;"
                    >{{ item.name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
        
              <v-list-item-avatar
                tile
                size="80"
              >
              <v-icon color="grey" class="mdi-48px">
                        mdi-account
                    </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

            <v-card-actions>
                <span class="text-caption grey--text font-weight-light">{{ item.updated_at}}</span>
                <v-spacer></v-spacer>
              <v-btn
                icon
                color="primary"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="red"
                small
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-alert>
          </v-card>
          </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'EmcBaseCardList',
    data: () => ({}),
    props: {
        collections: {
            type: Array
        },
    }
}
</script>