<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :disabledSave="disabled"
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="100%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-container fluid>
                <v-progress-linear
                            v-if="loading"
                            indeterminate
                            color="yellow darken-2"
                        />
                <v-data-iterator
                :items="filteredItems"
                sort-by="course_name"
                :sort-desc="sortDesc"
                :items-per-page.sync="itemsPerPage"
                hide-default-footer
                no-data-text=""
                >
                <template v-slot:header>
                    <v-toolbar
                    dark
                    color="blue darken-3"
                    class="mb-1"
                    >
                    <!-- <v-toolbar-title>This is a header</v-toolbar-title> -->
                    
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-select
                            v-model="selectedCampus"
                            :items="model.campuses"
                            @change="changeCampus"
                            flat
                            solo-inverted
                            return-object
                            item-text="name"
                            hide-details
                            prepend-inner-icon="mdi-filter-menu-outline"
                            label="Campus"
                        />
                        <v-spacer></v-spacer>
                        <v-select
                            v-model="selectedShift"
                            :items="dynamic.shifts"
                            :loading="loading"
                            @change="selectedCourse = null; loadData(null)"
                            return-object
                            flat
                            solo-inverted
                            item-text="name"
                            hide-details
                            prepend-inner-icon="mdi-filter-menu-outline"
                            label="Turno"
                            clearable
                            :disabled="selectedCampus == null"
                        />
                        <v-spacer></v-spacer>
                        <v-select
                            v-model="selectedCourse"
                            :items="filteredCourses"
                            :loading="loading"
                            @change="loadData"
                            return-object
                            flat
                            solo-inverted
                            item-text="name"
                            hide-details
                            prepend-inner-icon="mdi-filter-menu-outline"
                            label="Curso"
                            clearable
                            :disabled="selectedCampus == null || selectedShift == null"
                        />
                        <v-spacer></v-spacer>

                    </template>
                   
                    <!-- <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Pesquisar"
                        :disabled="!dynamic || !dynamic.items || dynamic.items.length == 0"
                    />
                    
                    <v-spacer></v-spacer> -->

                     <v-icon
                        @click.prevent.stop="showList = !showList; expandeds = []"
                        color="white"
                        :disabled="filteredItems.length == 0"
                    >
                        {{ showList ? iconOpen.closed.icon  : iconOpen.open.icon }}
                    </v-icon>

                </v-toolbar>
                </template>

               <template v-slot:default="props">
                
                <emc-util-scroll height="60vh">

                    <v-row class="mt-2">
                        <v-col
                            v-for="(item, index) in props.items"
                            :key="index"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                        >
                        <v-card>
                        <v-card-title class="subheading font-weight-bold">
                            {{ item.group.name }}
                            <v-spacer></v-spacer>

                            <span class="text-subtitle-2 mr-1">{{ getTextTotal(item) }}</span>

                            <v-btn :loading="loadingAll && selectedItem && selectedItem.key == item.key" icon small @click.prevent.stop="acceptedAll(item, index)">
                                <v-icon :color="icons[getIndexIcon(item)].color">{{ icons[getIndexIcon(item)].icon }}</v-icon>
                            </v-btn>

                            <v-btn :disabled="showList" icon small @click.prevent.stop="expand(item.key)">
                                <v-icon :color="expandeds.includes(item.key) ? iconOpen.closed.color : iconOpen.open.color">{{ expandeds.includes(item.key) ? iconOpen.closed.icon : iconOpen.open.icon }}</v-icon>
                            </v-btn>
                            
                        </v-card-title>
                        <!-- <v-card-subtitle class="subheading">
                            {{ item.group.name }}
                        </v-card-subtitle> -->
                        <v-divider></v-divider>

                    <v-list v-if="renderComponent && (showList || expandeds.includes(item.key))" two-line>
                        <v-list-item-group
                            v-model="selectedIndex[index]"
                            multiple
                            active-class="primary--text"
                        >
                        <emc-util-scroll height="35vh">
                            <v-list-item
                                v-for="(grid, index2) in item.grids"
                                :key="grid.id"
                                @click="save(grid, index, index2)"
                                :class="grid.dynamic_attribution_result_status_id == 2 ? 'primary--text' : null"
                                >
                                <template v-slot:default>
                                    <v-list-item-content>
                                        
                                        <v-list-item-title>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">{{ grid.discipline.name }}</span>
                                                </template>
                                                <span>{{ grid.workLoadType.initials + '=' + grid.work_load + ' - ' + grid.discipline.name }}</span>
                                            </v-tooltip>
                                            
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="text--primary"
                                        >
                                            <small v-if="grid.teacher">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">{{ grid.teacher.name }}</span>
                                                    </template>
                                                    <span>{{ grid.teacher.name }}</span>
                                                </v-tooltip>
                                            </small>
                                        
                                        </v-list-item-subtitle>

                                                                           
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        
                                        <!-- <v-list-item-action-text v-if="isAnima()" class="text-right">
                                            <small>ID: {{ campus.id }}</small>
                                            <small v-if="campus.code"><br/>Código: {{ campus.code }}</small>
                                        </v-list-item-action-text> -->

                                        <v-btn :loading="loadingItem && selectedItemResult && selectedItemResult.id == grid.id" icon>
                                            <v-icon
                                                :color="icons[grid.dynamic_attribution_result_status_id - 1].color"
                                            >
                                                {{ icons[grid.dynamic_attribution_result_status_id - 1].icon }}
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                    
                            </template>
                            </v-list-item>
                            <v-divider
                                v-if="index < item.grids.length - 1"
                                :key="item.id"
                            />
                        </emc-util-scroll>
                    </v-list-item-group>
                    </v-list>
                        </v-card>
                    </v-col>
                    </v-row>

                </emc-util-scroll>

                </template>

                
                </v-data-iterator>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDynamicAttributionViewResult",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        showList: true,
        dynamic: {},
        loading: false,
        loadingItem: false,
        loadingAll: false,
        disabled: false,
        selectedIndex: [],
        selectedItem: null,
        selectedItemResult: null,
        itemsPerPageArray: [4, 8, 12],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 1000,
        renderComponent: false,
        sortBy: 'name',
        keys: [ ],
        items: [],
        selectedCampus: null,
        selectedShift: null,
        selectedCourse: null,
        expandeds: [],
        filteredItems: [],
        iconOpen: {
            open: { icon: 'mdi-eye-outline', color: 'primary' },
            closed: { icon: 'mdi-eye-off-outline', color: 'grey' },
        }, 
        icons: [
            { color: 'amber',   icon: 'mdi-help-circle-outline' },  // Pendente 
            { color: 'success', icon: 'mdi-check-circle-outline' }, // Aprovado
            { color: 'error',   icon: 'mdi-close-circle-outline' }, // Reprovado
            { color: 'cyan',   icon: 'mdi-minus-circle-outline' },  // Indefinido
        ]
        
    }),
    created() {
       
    },

    computed: {
      ...mapState('auth', ['acesso']),

      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },

      filteredCourses(){

        if(this.selectedCampus && this.selectedShift && this.dynamic){
            return this.dynamic.courses.filter(c => (c.campuses.includes(this.selectedCampus.id) && c.shifts.includes(this.selectedShift.id) ));
        }else{
            return [];
        }

      }, 

     
      
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error              = false;
                this.errors             = {};
                this.disabled           = false;
                this.selectedCampus     = null;
                this.selectedShift      = null;
                this.selectedCourse     = null;
                this.dynamic            = {};
                this.search             = null;
                this.filteredItems      = [];
                this.selectedIndex      = [];
                this.selectedItem       = null;
                this.selectedItemResult = null;
                this.expandeds          = [];
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-puzzle-outline"
        },
        title: {
            type: String,
            default: "Resultados Atribuição Dinâmica"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('dynamicGeneration', ['ActionUpdateDynamicAttributionResult', 'ActionShowDynamicAttributionViewResults', 'ActionUpdateAllDynamicAttribution']),

        acceptedAll(item, index){
            
            let indexIcon   = this.getIndexIcon(item) + 1;
            let status      = (indexIcon == 1 || indexIcon == 3) ? 2 : 3;
            this.loadingAll = true;
            this.selectedItem = item;

            let payload = {
                id:                                     this.dynamic.id,
                group_id:                               item.group.id,
                dynamic_attribution_result_status_id:   status
            };



            this.ActionUpdateAllDynamicAttribution(payload)
                .then((res) => {
                    
                    this.selectedIndex[index] = [];

                    for (let i = 0; i < item.grids.length; i++) {
                        
                        if(status == 2){
                            this.selectedIndex[index].push(i);
                        }

                        item.grids[i].dynamic_attribution_result_status_id = status;
                        
                    }

                })
                .finally(() => {
                    this.loadingAll = true;
                    this.selectedItem = null;
                });


        },

        getTextTotal(item){

            let total = item.grids.length;
            let accepteds = item.grids.filter(g => g.dynamic_attribution_result_status_id == 2).length;

            return accepteds + '/' + total;

        },

        getIndexIcon(item){

            let total       = item.grids.length;
            let pendentes   = item.grids.filter(g => g.dynamic_attribution_result_status_id == 1).length;
            let aprovados   = item.grids.filter(g => g.dynamic_attribution_result_status_id == 2).length;
            let reprovados  = item.grids.filter(g => g.dynamic_attribution_result_status_id == 3).length;

            if(pendentes == total){
                return 0;
            }else if(aprovados == total){
                return 1;
            }else if(reprovados == total){
                return 2;
            }else{
                return 3;
            }

        },

        changeCampus(item){
            this.getData(item);
        },

        loadData(item = null){
            
            
            this.filteredItems = [];
            this.selectedIndex = [];

            this.$nextTick(() => {
                
                if(this.dynamic && this.dynamic.items && this.selectedShift && item){
                    this.filteredItems = this.dynamic.items.filter(i => (i.course.id == item.id && i.shift.id == this.selectedShift.id));
                }

                this.forceRerender();

            });

        },

        getData(campus){  
      
            this.error = false;
            this.message = '';
            this.dynamic = {};
            this.loading = true;
            this.filteredItems = [];
            this.selectedIndex = [];
            this.selectedShift = null;
            this.selectedCourse = null;

            let payload = {
                id: this.model.id,
                filter: {
                    campus_id: campus.id
                }
            };
            
            this.ActionShowDynamicAttributionViewResults(payload)
                .then((res) => {
                    this.dynamic = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                    this.loadData();
                })
        },

        save(item, index, index2)
        {
            this.error = false;
            this.message = '';
            this.selectedItemResult = item;
            this.loadingItem = true;

            let acceptedOrigin = item.dynamic_attribution_result_status_id;

            let payload = {
                id: item.id,
                dynamic_attribution_result_status_id: (acceptedOrigin == 1 || acceptedOrigin == 3) ? 2 : 3,
            };

            this.ActionUpdateDynamicAttributionResult(payload)
                .then((res) => {
                    item.dynamic_attribution_result_status_id = res.data.dynamic_attribution_result_status_id;
                })
                .catch((error) =>{
                    
                    item.dynamic_attribution_result_status_id = acceptedOrigin;

                    this.selectedIndex[index].splice(index2, 1);

                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;

                })
                .finally(() => {
                    this.loadingItem = false;
                    this.selectedItemResult = null;
                });
        },

        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },

        getFirstName(name){
            return name.split(' ')[0];
        },

        expand(key){

            if(this.expandeds.includes(key)){
                let index = this.expandeds.indexOf(key);
                this.expandeds.splice(index, 1);
            }else{
                this.expandeds.push(key);
            }

        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },

    }
}
</script>