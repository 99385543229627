<template>
    <v-dialog transition="dialog-top-transition" v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '60%'">
        <v-card>
            <v-card-title  class="ma-0 pa-0">
                <v-app-bar  dense height="35" class="primary lighten-2 mb-4 elevation-1">
                    <v-toolbar-title v-if="model">
                        <span class="white--text subtitle-1">{{ model.name_complete }}</span>
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-tooltip v-if="model && model.images.length > 1" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <v-switch v-model="cycle" hide-details/>
                            </span>
                        </template>
                        <span> Apresentação automática </span>
                    </v-tooltip>
                    <v-btn icon small @click.native="dialog = false">
                        
                        <v-icon small color="white lighten-2">mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-carousel 
                    v-if="model"
                    :cycle="cycle"
                    :show-arrows="model && model.images.length > 1"
                    :show-arrows-on-hover="model && model.images.length > 1"
                    :hide-delimiters="!model || model.images.length <= 1"
                >
                    <v-carousel-item
                        v-for="(item,i) in model.images"
                        :key="i"
                        :src="item.url"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                        <template v-slot:default>
                            <span class="ma-2 white--text title">{{ item.title }}</span>
                            <br/>
                            <span class="ma-2 white--text subtitle-1">{{ item.description }}</span>              
                        </template>
                    
                    </v-carousel-item>
            </v-carousel>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: "SysAmbienceShowImages",
    data() {
        return {
           dialog: false,
           cycle: true
        };
    },
    props: {
        model: {
            required: true
        },
        show: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
      
      show(val){
          this.dialog = val;

          if(val){
              this.cycle = true;
          }
      },

      dialog(val){
          this.$emit('update:show', val);
      },
    },
    methods: {
        
    }
};
</script>