<template>
    <span>
            
            <emc-table-icon :cancel-auto-small="!autoSmall" :loading="loading" :disabled="loading" :color="color" @click="clickAction()" :small="small" :icon="icon" :tool-tip="toolTip" />

            <emc-excel-user-download-filter 
                :report-id="report"
                @onCompleted="exportReport($event)" 
                :show.sync="showFilter"
                :showFilterPaymentExport="showFilterPaymentExport"
            />

            <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'" persistent>
                <v-card>
                        <v-card-title class="ma-0 pa-0">
                            <v-app-bar dense height="45" class="grey lighten-5 mb-4 elevation-1'">
                                <v-icon left>mdi-file-download-outline</v-icon>
                                    <v-toolbar-title class="subtitle-1">
                                        Downloads
                                    </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                                <v-icon color="red lighten-2">mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Fechar</span>
                                </v-tooltip>
                            </v-app-bar>
                        </v-card-title>
                            <v-card-text>
                                <p class="text-center text-h6 text--primary">
                                    {{ message }}
                                    <br/>
                                    <br/>
                                    Deseja gerar um novo relatório?    
                                </p>
                            </v-card-text>
                        
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ligth green" @click.prevent.stop="downloadAllowLast()">Sim - Gerar Novo</v-btn>
                            <v-btn color="error" @click.prevent.stop="dialog = false">Não - Aproveitar Disponível</v-btn>
                        </v-card-actions>
                    </v-card>

            </v-dialog>

            <v-dialog v-model="dialogMessage" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'" persistent>
                <v-card>
                        <v-card-title class="ma-0 pa-0">
                            <v-app-bar dense height="45" class="grey lighten-5 mb-4 elevation-1'">
                                <v-icon left>mdi-file-download-outline</v-icon>
                                    <v-toolbar-title class="subtitle-1">
                                        Downloads
                                    </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.native="dialogMessage = false" v-bind="attrs" v-on="on">
                                                <v-icon color="red lighten-2">mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Fechar</span>
                                </v-tooltip>
                            </v-app-bar>
                        </v-card-title>
                            <v-card-text>
                                <p class="text-center text-h6 text--primary">
                                    Relatório sendo gerado e em breve estará disponível para download!
                                </p>
                            </v-card-text>
                        
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary green" to="user-downloads" target="_blank" >Ir para Downloads<v-icon right dark>mdi-page-next-outline</v-icon></v-btn>
                            <v-btn color="error" @click.prevent.stop="dialogMessage = false">Fechar</v-btn>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </span>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "SIconReportDownloadOptimized",
    data () {
        return {
            loading: false,
            dialog: false,
            dialogMessage: false,
            showFilter: false,
            error: false,
            message: null,
            allowLast: false,
            reportsFilter: [53, 66, 32, 79, 100],
        }
    },
    props: {
       report: {
            Type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'success darken-1',
        },
        toolTip: {
            type: String,
            default: 'Relatório',
        },
        icon: {
            type: String,
            default: 'mdi-microsoft-excel'
        },
        small: {
            type: Boolean,
            default: false
        },
        params: {
            default: null
        },
        showMessage: {
            type: Boolean,
            default: false
        },
        showFilterPaymentExport:{
            type: Boolean,
            default: false
        },
        autoSmall: {
            type: Boolean,
            default: false
        },
        delay: {

        },
        menu: {
            
        }

    },

    watch: {
        showFilter(val){
            if(!val){
                this.$emit('update:delay', 200);
                this.$emit('update:menu', false);
            }
        }
    },

    methods: {
        ...mapActions('controlPanel', ['ActionExportDownloadOptimized']),

        clickAction(){

            if(this.reportsFilter.includes(parseInt(this.report))){
                this.$emit('update:delay', 200000);
                this.showFilter = true;
            }else{
                this.$emit('update:delay', 200);
                this.exportReport();
            }

        },

        exportReport(params = null){

            this.loading = true;
            this.error = false;
            this.message = null;
            this.dialog = false;

            if(!params){
                params = {};
            }
            
            if(this.allowLast){
                params.allowLast = true;

            }else if(params){
                delete params.allowLast;
            }

            if(this.params){
                
                let description = params.description;

                if(!params){
                    params = this.params;
                }else{
                    Object.assign(params, this.params);
                }

                if(description){
                    params.description += ' | ' + description;
                }
                
            }

            this.ActionExportDownloadOptimized({ report: this.report, params: params  })
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', false, res.message);
                    if(this.showMessage){
                        this.dialogMessage = true;
                    }
                }).catch((error) => {
                    this.message = error.message;
                    this.error = true;

                    if(error.message && error.message.includes('Existe um relatório gerado há menos de')){
                        this.dialog = true;
                    }else{
                        this.$emit('onCompleted', true, error.message);
                    }
                    
                }).finally(() => {
                    this.loading = false;
                    this.allowLast = false;
                });
        },


        downloadAllowLast(){
            this.dialog = false;
            this.allowLast = true;
            this.clickAction();
        }

        
        
    }
}
</script>
