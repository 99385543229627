export default [
    {
        path: '/work-load-balance',
        name: 'work-load-balance',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Balanço de Carga Horária',
            icon: 'mdi-scale-balance',
            breadcrumbs: [
                { text: 'Balanço de Carga Horária', disabled: true }
            ]
        }
    }
]