<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            :search.sync="search"
            @onShowForm="showForm = true"
            :showClear="showClear"
            :autoComplete="autoComplete"
            :multiple="multiple"
            :showAdd="showAdd"
            :disabled="disabled"
            :autofocus="autofocus"
            :dense="dense"
        />
        <sys-register-teacher @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectTeacher',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false,
        search: ''
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        if(this.data instanceof Object){
            this.items.push(this.data)
            this.selected = this.model
        } else {
            
            if(!this.model) return

            this.getItems();
        }
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        showClear: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        dense: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        data: {
            required: false
        },
        selectedObject: {
            
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
        
    },
    watch: {
        
        data(val) {
            
            this.items = [];
            
            if(val){
                this.items.push(val);
                this.selected = this.model;
            }
            
        },

        selected(val){
            
            this.$emit('update:model', val);

            if(val && this.selectedObject){
                
                let teacher = this.items.find(i => i.id == val);
                if(teacher){
                    this.$emit('update:selectedObject', teacher);
                }
            }

        },
        
        model(val){
            this.selected = val;
        },
        search(newSearch, oldSearch) {

            this.getItems()
            
        },
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeachers']),        
        
        async getItems() {

            this.loading = true
            let payload = {
                column_order: 'name',
                direction: 'asc',
                search: this.search,
                find_columns: {
                    active: 1,
                }
            }

            if(this.selectedObject){
                payload.with = 'campuses';
            }

            await this.ActionFindTeachers(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>