import { service } from '../../../../helpers/http'

export const ActionIndexDynamicAttributions = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicAttributions',
        payload: payload
    })
    
}

export const ActionCreateDynamicAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'createDynamicAttribution',
        payload: payload
    })
    
}

export const ActionUpdateDynamicAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateDynamicAttribution',
        payload: payload
    })
}

export const ActionDeleteDynamicAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'deleteDynamicAttribution',
        payload: payload
    })
    
}

export const ActionIndexDynamicAttributionTypes = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicAttributionTypes',
        payload: payload
    })
    
}

export const ActionDynamicAttributionGenerate = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'generateDynamicAttribution',
        payload: payload
    })
    
}

export const ActionDynamicAttributionSynce = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'synceDynamicAttribution',
        payload: payload
    })
    
}

export const ActionShowDynamicAttributions = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'showDynamicAttributions',
        payload: payload
    })
    
}

export const ActionShowDynamicAttributionViewResults = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'showDynamicAttributionViewResults',
        payload: payload,
        idAndPayload: true
    })
    
}

export const ActionUpdateAllDynamicAttribution = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateAllDynamicAttribution',
        payload: payload
    })
}

