export default {
    
    login:                      { method: 'post',   url: 'login'},
    token:                      { method: 'post',   url: 'login/token'},
    accessTeacher:              { method: 'post',   url: 'access/teacher'},
    accessTeacherAuth:          { method: 'post',   url: 'access/teacher/auth'},
    tokenTeacher:               { method: 'post',   url: 'access/teacher/token/generate'},
    logout:                     { method: 'post',   url: 'logout'},
    loadSession:                { method: 'post',   url: 'auth/refresh'},
    loadAcesso:                 { method: 'get',    url: 'educational_groups/all' },
    menus:                      { method: 'get',    url: 'auth/menu' },
    sendMailResetPassword:      { method: 'post',   url: 'password/email' },
    sendConfirmResetPassword:   { method: 'post',   url: 'password/reset' },
    setSelected:                { method: 'post',   url: 'auth/selected' },
    authLoginFace:              { method: 'post',   url: 'aws_rekognitions/auth/face' },

}