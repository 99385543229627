<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:tools>
            <v-menu 
                v-if="true"
                v-model="viewMenu"
                offset-x
                offset-y
                bottom
            >
            <template v-slot:activator="{ on, attrs }">
                
                <v-btn
                    fab
                    color="primary"
                    x-small
                    v-on="on"
                    v-bind="attrs"
                    :disabled="!range"
                    >
                    <v-icon small>mdi-code-tags</v-icon>
                </v-btn>

            </template>
                <v-list>
                    <emc-util-scroll maxHeight="300px">
                        <v-list-item
                            v-for="(tag, i) in tags"
                            :key="i"
                            link 
                        >
                            <v-list-item-title @click="setField(tag)">{{ tag.tag }}</v-list-item-title>
                        </v-list-item>
                    </emc-util-scroll>
                </v-list>
            </v-menu>
        </template>
    
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="model.description" 
                                label="Descrição*"
                                :counter="255"
                                rows="2"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field 
                                v-model="model.label" 
                                persistent-placeholder 
                                :error="!!errors.label" 
                                :error-messages="errors.label" 
                                placeholder="Exemplo: Li e concordo" 
                                label="Rótulo"
                                :counter="50"
                                :rules="[v => (!v || v.length <= 50) || 'Máximo de 50 caracteres atingido']"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <span>Conteúdo*</span>
                                
                                <vue-editor 
                                    v-model="model.content"
                                    @selection-change="range = $event"
                                    ref="editor"
                                    :error="!!errors.content" 
                                    :error-messages="errors.content" 
                                    :editorToolbar="customToolbar" 
                                />
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <s-switch inset text-helper="Obrigatório concordar para avançar no cadastro" v-model="model.is_required" label="Obrigatório"/>
                        </v-col>
                        <!-- <v-col cols="12" sm="4">
                            <s-switch inset text-helper="Exibir concordância com o termo no Extrato do Professor" v-model="model.include_extract" label="Incluir Extrato"/>
                        </v-col> -->
                        <v-col cols="12" sm="4">
                            <s-switch inset text-helper="Enviar e-mail para o usuário com cópia do termo" v-model="model.send_email" label="Enviar E-mail"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch inset text-helper="Salvar cópia do termo em PDF no Servidor" v-model="model.save_pdf" label="Salvar PDF"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-switch color="purple" inset v-model="model.active" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterActivity",
    data: (vm) => ({
        viewMenu: false,
        dialog: false,
        error: false,
        errors: {},
        range: null,
        message: '',
        loading: false,
        tags: [],
        customToolbar: [

                [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                [{ size: ["small", false, "large", "huge"] }],

                ["bold", "italic", "underline"],

                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],

                ["blockquote"],

                [{ list: "ordered" }, { list: "bullet" }],

                [{ indent: "-1" }, { indent: "+1" }],

                [{ color: [] }],

                ["link"],
                
        ]
        
    }),
    created(){
       this.loadTags();
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.range = null;

                if(!this.model.id){
                    this.model.is_required = true;
                    this.model.send_email = true;
                    this.model.active = true;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Cadastro de Termo"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('term', ['ActionIndexTermTags','ActionUpdateTerm', 'ActionCreateTerm']),

        loadTags(){
            
            this.tags = [];
            let payload = {
                column_order: 'tag',
                find_columns: {
                    model: 'teacher',
                    active: 1,
                }
            }
            
            this.ActionIndexTermTags(payload)
                .then((res) => {
                    this.tags = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                })
        },
        
        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateTerm(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateTerm(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        setField(tag){
            
            const range = this.$refs.editor.quill.selection.savedRange;

            if(!range){
                return;
            }

            let text = ' [[' + tag.tag + ']]';
            this.$refs.editor.quill.editor.insertText(range.index, text);
            this.model.content = this.$refs.editor.quill.root.innerHTML;
            this.$refs.editor.quill.setSelection(range.index + text.length);
            this.$refs.editor.quill.focus();
        }

    }
}
</script>