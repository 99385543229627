import { service } from '../../../../helpers/http'

export const ActionIndexTermTags = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'indexTermTags', 
		payload: payload 
	})
}

export const ActionCreateTermTag = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'createTermTag', 
		payload: payload 
	})
}

export const ActionUpdateTermTag = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'updateTermTag', 
		payload: payload 
	})
}

export const ActionDestroyTermTag = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'destroyTermTag', 
		payload: payload 
	})
}