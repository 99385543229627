<template>
        <sys-register-base 
            :loading="loading" 
            @onSave="save" 
            :model.sync="model" 
            :title="title" 
            icon="mdi-merge" 
            v-bind:show.sync="dialog"
            maxWidth="95%"
            maxHeight="95%"
            :showButton="false"
            :showRequiredFields="false"
        >
            
            <template v-slot:tools>
                <sys-pop-over-join :model="config" />
            </template>
            
            <template v-slot:form>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step :editable="editable" :complete="step > 1" step="1">Seleção de Cursos</v-stepper-step>
                            <v-divider></v-divider>
                        <v-stepper-step :editable="editable" :complete="step > 2" step="2">Seleção de Disciplinas</v-stepper-step>
                            <v-divider></v-divider>
                        <v-stepper-step :editable="editable" step="3">Confirmação</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                    <v-stepper-content step="1" class="pa-2">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <div class="subtitle-1">Selecione o Campus</div>
                                <v-chip-group v-model="campusesSelected" column :multiple="true">
                                    <emc-util-scroll  height="320px">
                                        <v-chip filter outlined v-for="campus in campuses" :key="campus.id" :value="campus.id">
                                            {{ campus.name }}
                                        </v-chip>
                                    </emc-util-scroll>
                                </v-chip-group>
                            </v-col>

                            <v-divider vertical v-if="shifts.length"></v-divider>

                            <v-col cols="12" sm="2" v-if="shifts.length">
                                <div class="subtitle-1">Selecione o Turno</div>
                                <v-chip-group v-model="shiftsSelected" column :multiple="true">
                                    <v-chip filter outlined v-for="shift in shifts" :key="shift.id" :value="shift.id">
                                        {{ shift.name }}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>

                            <v-divider vertical v-if="courses.length"></v-divider>

                            <v-col cols="12" sm="6" v-if="courses.length">
                                <span class="subtitle-1">Selecione os Cursos</span>
                                <v-checkbox
                                    style="position: absolute; top: 2px; right: 35px;"
                                    label="Todos"
                                    class="subtitle-1"
                                    color="blue lighten-1"
                                    @click="selectAllCourses"
                                    v-model="bSelectAllCourses"
                                    hide-details
                                    dense
                                />
                                <v-chip-group v-model="coursesSelected" column multiple>
                                    <emc-util-scroll  height="320px">
                                        <v-chip filter outlined v-for="course in courses" :key="course.id" :value="course.id">
                                            {{ course.name_complete }}
                                        </v-chip>
                                    </emc-util-scroll>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="pa-2">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="subtitle-1">Selecione as turmas</div>
                                    <v-checkbox
                                        style="position: absolute; top: 2px; left: 200px;"
                                        label="Todos"
                                        color="blue lighten-1"
                                        v-model="bSelectAllGroups"
                                        hide-details
                                        dense
                                    />
                                    <v-chip-group v-model="groupsSelected" column multiple>
                                        <emc-util-scroll height="320px">
                                            <v-chip filter outlined v-for="(group, i) in groups" :key="i">
                                                {{ group.name }}
                                            </v-chip>
                                        </emc-util-scroll>
                                    </v-chip-group>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <span class="subtitle-1">Selecione as disciplinas</span>
                                    <v-row style="position: absolute; top: 8px; right: 35px;" >
                                        <v-checkbox
                                            label="Exibir todas"
                                            color="blue lighten-1"
                                            v-model="bShowAllJoins"
                                            hide-details
                                            dense
                                            class="mr-2"
                                        />
                                        <v-checkbox
                                            label="Selecionar Todas"
                                            color="blue lighten-1"
                                            v-model="bSelectAllJoins"
                                            hide-details
                                            dense
                                        />
                                    </v-row>
                                        <emc-util-scroll v-if="joinsFilters.length" height="320px">
                                            <v-alert v-for="(join, index) in joinsFilters" :key="index" class="pa-1 ma-1" :color="join.color" border="left" elevation="1" tile colored-border>
                                                <v-app-bar color="white" class="elevation-0" max-height="40px">
                                                    <v-toolbar-title class="subtitle-1">
                                                        <v-menu
                                                            open-on-hover
                                                            top
                                                            offset-y
                                                            >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn text v-bind="attrs" v-on="on" class="pl-0">
                                                                    {{ join.preview }}
                                                                    <v-icon v-if="isMultCampi(join) > 1" color="orange">mdi-map-marker-plus-outline</v-icon>
                                                                    <v-icon v-if="join.byEquivalence" color="primary">mdi-code-equal</v-icon>
                                                                </v-btn>
                                                            </template>

                                                            <v-card outlined tile max-width="400px" class="pb-2">
                                                                <v-app-bar class="elevation-0" dense>
                                                                    <v-toolbar-title class="text-body-2">
                                                                            Informações
                                                                    </v-toolbar-title>
                                                                </v-app-bar>
                                                                <v-divider class="mb-2"></v-divider>

                                                                <div class="text-caption pl-3 grey--text text--darken-1"><strong>Disciplina:</strong> {{join.name}}</div>
                                                                <div class="text-caption pl-3 grey--text text--darken-1"><strong>Carga Horária:</strong> {{join.work_load}}</div>
                                                                <div class="text-caption pl-3 grey--text text--darken-1"><strong>Tipo de Carga Horária:</strong> {{join.grids[0].work_load_type.name}}</div>
                                                                <div v-if="isMultCampi(join) > 1" class="text-caption pl-3 grey--text text--darken-1"><strong>Campus:</strong> {{ getCampi(join) }}</div>
                                                            </v-card>
                                                        </v-menu>
                                                    </v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                    <v-checkbox
                                                        v-model="indexJoins"
                                                        :value="index"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-app-bar>
                                                <div class="pl-4 pb-2">
                                                    <span v-if="isMultCampi(join) > 1" class="caption grey--text font-weight-light">Campus: <span class="font-weight-black">{{ isMultCampi(join) }}</span> - </span>
                                                    <span class="caption grey--text font-weight-light">Turmas: <span class="font-weight-black">{{ join.grids.length }}</span> - Alunos: <span class="font-weight-black">{{join.students}}</span></span>
                                                </div>
                                            </v-alert>
                                        </emc-util-scroll>
                                    <div v-else class="subtitle-2 orange--text"><v-icon color="orange">mdi-alert</v-icon> Não há sugestão de novas junções para as turmas selecionadas!</div>
                                </v-col>

                            </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="pa-0">
                         <emc-util-scroll height="400px">
                            <v-row class="mt-1" no-gutters>
                                    <v-col
                                        v-for="(item, index) in selectedJoins"
                                        :key="index"
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        lg="4"
                                        class="pa-2"
                                    >
                                        <v-card outlined tile :color="item.is_error ? 'red' : ''">
                                            <v-app-bar
                                                class="elevation-0"
                                                dense>
                                                <v-checkbox
                                                    v-model="selected"
                                                    :value="item"
                                                    color="success"
                                                    hide-details
                                                ></v-checkbox>
                                                <v-toolbar-title class="subtitle-1">
                                                    <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >{{ item.name }}</span>
                                                            </template>
                                                            {{ item.name }}
                                                        </v-tooltip>
                                                </v-toolbar-title>
                                                <v-spacer></v-spacer>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="success" @click="selecionarGrid(item)" v-bind="attrs" v-on="on">mdi-plus</v-icon>
                                                    </template>
                                                    <span> Adicionar Turma </span>
                                                </v-tooltip>
                                                </v-app-bar>
                                            <v-divider></v-divider>

                                            <v-list dense>
                                                <v-list-item v-for="grid in item.grids" :key="grid.id" >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >{{ getTextJoin(grid)}}</span>
                                                            </template>
                                                            {{ getTextJoin(grid) }}
                                                        </v-tooltip>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-if="grid.subGroup" v-text="grid.subGroup.name"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="grid.group.campus.abbreviation"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="grid.group.shift.name"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="'Código: ' + (grid.code ? grid.code : '')"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="grid.work_load_type.name + ' ('+ grid.work_load +')'"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="'Alunos: ' + (grid.students ? grid.students : '')"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-text="'Professor: ' + (grid.teacher ? grid.teacher.name : '')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="align-end">
                                                        <v-checkbox 
                                                            v-model="item.selectedGrids" 
                                                            :value="grid"
                                                            :disabled="!!grid.join"
                                                            hide-details
                                                            v-on:click.stop="gridChecked(item, grid)"
                                                        />                                                           
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                            <v-row  class="ma-0 white">
                                                <v-col cols="12">
                                                <v-divider class="my-2"></v-divider>
                                                <v-icon class="mr-2" small>mdi-account</v-icon>
                                                <span class="caption grey--text font-weight-light">{{ item.students }} alunos</span><br />
                                                <span class="caption grey--text font-weight-light">Carga horária total {{ item.work_load_amount }}</span>
                                                <v-progress-linear
                                                    v-model="item.work_load_percent"
                                                    background-color="red lighten-3"
                                                    color="success"
                                                    height="25"
                                                    >
                                                    <span class="caption">{{ item.work_load_amount - item.work_load }} carga(s) horária(s) otimizada(s)</span>
                                                    </v-progress-linear>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-col>
                            </v-row>
                        </emc-util-scroll>                 
                    </v-stepper-content>
                        
                    </v-stepper-items>
                </v-stepper>

                <v-alert v-if="showListErrors" type="error">
                    <ul v-if="error.errors.grids">
                      <li :key="i" v-for="(error, i) in error.errors.grids">
                        {{ error }}
                      </li>
                    </ul>
                    <ul v-else>
                      <li :key="i" v-for="(error, i) in error.errors">
                        {{ error }}
                      </li>
                    </ul>
                </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show.sync="showMessage"  :message.sync="message" :color="error ? 'error' : 'success'"/>
            <!-- Progresso -->
            <emc-loader-progress :show="progress"/>
            <!-- Selecionar nova grade -->
            <sys-find-grid 
                :show.sync="showSelectGrid"
                @onSelect="selectGrid($event)"
                :singleSelect="false"
                validateExistJoin
            />
            </template>

            <template v-slot:actions>
                
               
                <!-- Página 1 -->
                <span v-if="step == 1">
                    <v-btn small :loading="loadingStep" :disabled="coursesSelected.length < 1" color="primary" @click="loadGroups()" >
                        Próximo
                        <v-icon>mdi-step-forward</v-icon>
                    </v-btn>
                </span>
                <!-- Página 2 -->
                <span v-else-if="step == 2">
                    <v-btn class="mr-1" small text @click="step = 1"><v-icon>mdi-step-backward</v-icon>Voltar</v-btn>
                    <v-btn  small  :loading="loadingStep" :disabled="groupsSelected.length < 1 || indexJoins.length < 1" color="primary" @click="toConfirme()" >
                        Próximo
                        <v-icon>mdi-step-forward</v-icon>
                    </v-btn>
                </span>
                <span v-else>
                    <v-btn  class="mr-1" small :disabled="loading" text @click="step = 2"><v-icon>mdi-step-backward</v-icon>Voltar</v-btn>
                    <v-btn small :disabled="selected.length < 1 || loading" color="success" @click="showSaving = true" >
                        Salvar 
                        <v-icon small>mdi-content-save-all-outline</v-icon>
                    </v-btn>
                </span>

                <emc-alert-modal text="Deseja salvar as junções selecionadas?" :disabled="loading" v-bind:show.sync="showSaving">
                    <template v-slot:actions>
                        <v-btn color="success" @click.prevent.stop="save()" :loading="loading" :disabled="loading">Salvar <v-icon right dark>mdi-content-save-all-outline</v-icon></v-btn>
                    </template>
                </emc-alert-modal>
               

            </template>

        </sys-register-base> 
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysRegisterBachJoin",
    data: () => ({
        campuses: [],
        config: {
            distinctCampus: false,
            onlyDigitals: false,
        },
        bSelectAllCourses: false,
        showSaving: false,
        loadingStep: false,
        bShowAllJoins: false,
        bSelectAllJoins: false,
        bSelectAllGroups: true,
        campusesSelected: [],
        shifts: [],
        shiftsSelected: [],
        showListErrors: false,
        courses: [],
        coursesSelected: [],
        groups: [],
        groupsSelected: [],
        joins: [],
        indexJoins: [],
        dialog: false,
        errors: {},
        error: false,
        message: '',
        loading: false,
        progress: false,        
        showSelectGrid: false,
        gridSelecionado: null,
        step: 1,
        editable: false,
        colors: ['error', 'success', 'primary', 'purple', 'pink', 'cyan', 'indigo', 'blue', 'teal', 'amber', 'blue-grey'],
        singleSelect: false,
        selected: [],
        selectedJoins: [],
        showMessage: false,
        headers: [
          { text: 'Junção', align: 'center', sortable: false, value: 'name'},
          { text: 'Turmas da Junção', align: 'left', value: 'groups' },
          { text: 'Ações', align: 'left', value: 'actions' },
        ],
    }),
    computed: {
        ...mapState('auth', ['acesso']),
        joinsFilters: function() {
            return this.joins.filter((f) => {

                if(!this.bShowAllJoins) {
                    return f.grids.length >= 2
                } else {
                    return f.grids.length >= 1
                }
            })
        }
    },
    created() {
        this.loadCampuses();
        this.loadShifts();
    },
    props: {
        
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Junções"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        bShowAllJoins(value) {
            this.indexJoins = []
        },

        bSelectAllJoins(value) {
            
            if(value){
                for (let index = 0; index < this.joinsFilters.length; index++) {
                    if(!this.indexJoins.includes(index)){
                        this.indexJoins.push(index);
                    }
                }
            }else{
                this.indexJoins = [];
            }

        },

        bSelectAllGroups(value) {
            
            if(value){
                for (let index = 0; index < this.groups.length; index++) {
                    if(!this.groupsSelected.includes(index)){
                        this.groupsSelected.push(index);
                    }
                }
            }else{
                this.groupsSelected = [];
            }

        },

        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        campusesSelected() {
            this.loadCourses();
        },
        shiftsSelected(val) {
            this.loadCourses();
        },
        groupsSelected(){
            if(!this.loadingStep){
                this.loadUnions();
            }
        },
        show(show_new, show_old) {  
            
            this.dialog = show_new;
            
            if(show_new){
                this.errors = {};
                this.campusesSelected = [],
                this.shiftsSelected = [],
                this.coursesSelected = [],
                this.groupsSelected = [],
                this.indexJoins = [],
                this.selected = [];
                this.joins = [],
                this.selectedJoins = [],
                this.step = 1;
                this.config.distinctCampus = false;
                this.config.onlyDigitals = false;
                this.bSelectAllCourses = false;
                this.bSelectAllJoins = true;
                this.bSelectAllGroups = true;
                this.bShowAllJoins = false;
                this.showListErrors = false;
            }

        },
        selected(newSelected, oldSelected) {
            var arrayDeleted = oldSelected.filter((x) => !newSelected.includes(x))

            if(arrayDeleted.length > 0) {
                //Remove a marcação dos grids quando a junção é desmarcada
                this.joinsFilters.forEach((j) => {
                    if(arrayDeleted.includes(j)){
                        j.selectedGrids = []
                        j.students = 0
                        j.work_load = 0
                        j.work_load_amount = 0
                        j.work_load_percent = 0
                        return false;
                    }
                })
            } else {

                if(oldSelected.length > 0) {
                    var arrayInclude = newSelected.filter((x) => !oldSelected.includes(x))

                    if(arrayInclude.length > 0) {

                        //Adiciona os grids quando a junção é marcada novamente
                        this.joinsFilters.forEach((j) => {
                            if(arrayInclude.includes(j)){
                                j.selectedGrids = arrayInclude[0].grids
                                j.work_load_amount = 0
                                arrayInclude[0].grids.forEach((g) => {
                                    j.students += g.students;
                                    j.work_load = parseFloat(g.work_load);
                                    j.work_load_amount = parseFloat(j.work_load_amount) + parseFloat(g.work_load)
                                    j.work_load_percent = (parseFloat(j.work_load) / parseFloat(j.work_load_amount)) * 100
                                })
                                return false;
                            }
                        })
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions('join', ['ActionFindJoins', 'ActionCreateJoin']),
        ...mapActions('hour', ['ActionGetShifts']),
        ...mapActions('campus', ['ActionFindCampuses']),
        ...mapActions('course', ['ActionFindCoursesSearch']),
        ...mapActions('group', ['ActionFindGroups']),
        ...mapActions('grid', ['ActionIndexOptimized']),
        selectAllCourses() {

            if(this.bSelectAllCourses) {
                this.coursesSelected = []
                this.courses.forEach((c) => {
                    this.coursesSelected.push(c.id)
                })
            } else {
                this.coursesSelected = []
            }
            
        },
        gridChecked(item, grid){
            this.selectedJoins.forEach((j) => {
                if(j == item) {
                    j.students = 0
                    j.work_load_amount = 0
                    j.work_load_percent = 0

                    if(j.selectedGrids.length > 1) {

                        var arrayVerify = this.selected.filter((x) => x == item)

                        if(arrayVerify.length == 0) {
                            this.selected.push(item)
                        }

                        j.selectedGrids.forEach((g) => {
                            j.students += g.students
                            j.work_load = parseFloat(g.work_load)
                            j.work_load_amount = parseFloat(j.work_load_amount) + parseFloat(g.work_load)
                            j.work_load_percent = (parseFloat(j.work_load) / parseFloat(j.work_load_amount)) * 100
                        })
                    } else {

                        var arrayDeleted = this.selected.filter((x) => x == item)
                        if(arrayDeleted.length > 0){
                            j.selectedGrids = []
                            j.work_load = 0
                            var index = this.selected.indexOf(item)
                            this.selected.splice(index, 1)
                        }

                    }
                    return false;
                }
            })
        },
        selectAllDiscipline() {
            this.joinsFilters.forEach((j, v) => {
                this.indexJoins.push(v)
            });
            this.bSelectAllJoins = true;
        },
        selecionarGrid(item){
            this.showSelectGrid = true;
            this.gridSelecionado = item;
        },
        getTextJoin(grid){  
            var text = grid.group.name + ' | ' + grid.discipline_name;
            if(grid.join){
                text += ' [Possui Junção]';
            }
            return text;
        },
        selectGrid(val){  
            var count = 0;
            val.forEach((grid) => {
                count = 0;
                this.selectedJoins.forEach(join => {
                    var find = join.grids.find(g => g.id == grid.id);
                    if(find){
                        count++;
                    }
                });

                if(count == 0){
                    this.gridSelecionado.grids.push(grid);

                    this.gridSelecionado.students = this.gridSelecionado.students + grid.students;
                    this.gridSelecionado.work_load_amount = parseFloat(this.gridSelecionado.work_load_amount) + parseFloat(grid.work_load)
                    this.gridSelecionado.work_load_percent = (parseFloat(this.gridSelecionado.work_load) / parseFloat(this.gridSelecionado.work_load_amount)) * 100

                    if(!grid.join){
                        this.gridSelecionado.selectedGrids.push(grid);
                    }
                }
            });
        },
        toConfirme(){  
            
            this.loadingStep = true;
            this.selectedJoins = [];
            this.selected = [];
            
            for (let index = 0; index < this.joinsFilters.length; index++) {
                if(this.indexJoins.includes(index)){
                    this.selectedJoins.push(this.joinsFilters[index]);
                    this.selected.push(this.joinsFilters[index]);
                }
            }
            this.loadingStep = false;
            this.step = 3;
        },

        save(){
            
            if(!this.validate()){
                this.loading = false;
                return;
            }

            this.loading = true;
            this.errors = [];
            this.error = false;
            this.showListErrors = false;
            var payload = {
                joins: []
            };

            this.selected.forEach(join => {

                var newJoin = {
                    work_time_id: this.acesso.periodo.id,
                    status_join_id: 1,
                    join_type_id: 1,
                    replicate_teacher: 1,
                    code: null,
                    grids: []
                };
                
                join.selectedGrids.forEach(grid => {
                    newJoin.grids.push({
                        grid_id: grid.id,
                        main: 0
                    });
                    
                });

                payload.joins.push(newJoin);

            });

            this.ActionCreateJoin(payload)
                .then((res) => {
                    this.dialog = false;
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    this.showMessage = true;
                    this.showListErrors = true;
                })
                .finally(() => {
                   this.loading = false;
                })
        },

        validate(){
            this.message = '';
            this.error = false;
            this.showMessage = false;
            this.selected.forEach(join => {
                join.is_error = false;
                if(join.selectedGrids.length < 2){
                    this.message = "Selecione no mínimo 2 turmas para " + join.name;
                    this.error = true;
                    this.showMessage = true;
                    join.is_error = true;
                    return;
                }

                var teacher_id = null;

                //Verificando se existe junção com professores diferentes
                join.selectedGrids.forEach(grid => {
                    if(grid.teacher_id != null && grid.teacher_id != teacher_id) {
                        if(teacher_id == null) {
                            teacher_id = grid.teacher_id
                        } else {
                            this.message = "Não é possível fazer junção com professores diferentes indicados. Verifique a junção " + join.name + " sinalizada em vermelho.";
                            this.error = true;
                            this.showMessage = true;
                            join.is_error = true;
                            return;
                        }
                    }
                })
            });
            return this.message.length == 0;
        },
        loadCampuses(){
            this.ActionFindCampuses()
                .then((res) => {
                    this.campuses = res.data;
                });
        },
        loadShifts() {
            
            this.ActionGetShifts()
                .then((res) => {
                    this.shifts = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                });
             
        },
        loadCourses(){

            if(this.campusesSelected.length > 0 && this.shiftsSelected.length > 0){
                
                this.progress = true;
                this.courses = [];
                this.groups = [];

                var payload = {
                    campuses_id:    this.campusesSelected,
                    shifts_id:      this.shiftsSelected
                };

                this.ActionFindCoursesSearch(payload)
                    .then((res) => {
                        this.courses = res.data;
                    })
                    .catch((error) =>{
                        this.error      = error;
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.progress = false;
                    });
            } else {
                this.courses = []
                this.coursesSelected = []
            }
        },

        async loadGroups(){
            
            this.loadingStep = true;
            this.groups = [];
            this.groupsSelected = [];

            var courses = this.courses.filter((f) => {
                return this.coursesSelected.includes(f.id);
            });

            var payload = {
                filter_relations: [
                    {
                        name: 'course',
                        key: 'id', 
                        value: courses.map(o => o['id']).join(','),
                        clause: 'whereIn',
                    }
                ]
            };

            this.ActionFindGroups(payload)
                .then((res) => {
                    
                    res.data.forEach(group => {
                        this.groups.push(group);
                        this.groupsSelected.push(this.groups.length - 1);
                    });

                    this.loadGrids();
                })
                .catch((error) =>{
                   
                })
                .finally(() => {
                    
                });
        },

        loadGrids(){

            var payload = {
                campuses:   this.campusesSelected,
                shifts:     this.shiftsSelected,
                courses:    this.coursesSelected,
            };

            this.ActionIndexOptimized(payload)
                .then((res) => {
                    var grids = res.data;
                    this.groups.forEach(group => {
                        var grids2 = grids.filter(g => g.group_id == group.id);
                        group.grids = grids2;
                    });
                })
                .catch((error) =>{
                   
                })
                .finally(() => {
                    this.loadUnions();
                    this.step = 2;
                    this.loadingStep = false;
                    this.selectAllDiscipline();
                });

        },

        loadUnions(){
            
            this.joins = [];
            this.indexJoins = [];
            var index = 0;
            
            if(!this.groupsSelected || this.groupsSelected.length == 0){
                return;
            }

            this.groupsSelected.forEach(i => {
                var group = this.groups[i];
                if(group && group.grids){
                    group.grids.forEach(grid => {
                        if(!grid.join){
                            if(this.config.onlyDigitals){
                               if(grid.work_load_type.digital == 1){
                                   this.loadJoins(grid);
                               }
                            }else{
                               this.loadJoins(grid);
                            }
                        }
                    });
                }
            });

        },

        loadJoins(grid){

            var join;
            var base_code = grid.discipline.equivalence ? grid.discipline.equivalence.equivalence_id : grid.discipline.id;

            if(!this.config.distinctCampus){
                join = this.joins.find((join) => 
                    ((grid.work_load_type.digital && join.key == base_code + '|' + grid.work_load + '|' + grid.work_load_type_id) || (!grid.work_load_type.digital && join.key == base_code + '|' + grid.work_load + '|' + grid.work_load_type_id  + '|' + grid.group.campus.id + '|' +  grid.group.shift.id + '|' + grid.group_number + '|' + grid.teacher_number))
                );
            }else{
                join = this.joins.find((join) => 
                    ((grid.work_load_type.digital && join.key == base_code + '|' + grid.work_load + '|' + grid.work_load_type_id) || (!grid.work_load_type.digital && join.key == base_code + '|' + grid.work_load + '|' + grid.work_load_type_id  + '|' +  grid.group.shift.id + '|' + grid.group_number + '|' + grid.teacher_number))
                );
            }

            if(join){
                
                var hasGroup = join.grids.find(g => g.group_id == grid.group_id);

                if(!hasGroup){
                    join.grids.push(grid);
                    join.students = join.students + grid.students;
                    join.work_load_amount = parseFloat(join.work_load_amount) + parseFloat(grid.work_load)
                    join.work_load_percent = (parseFloat(join.work_load) / parseFloat(join.work_load_amount)) * 100
                    join.selectedGrids.push(grid);
                }
                
            }else{

                var key;
                
                if(!this.config.distinctCampus){
                    key = grid.work_load_type.digital ? base_code + '|' + grid.work_load + '|' + grid.work_load_type_id : base_code + '|' + grid.work_load + '|' + grid.work_load_type_id  + '|' + grid.group.campus.id + '|' +  grid.group.shift.id  + '|' + grid.group_number + '|' + grid.teacher_number;
                }else{
                    key = grid.work_load_type.digital ? base_code + '|' + grid.work_load + '|' + grid.work_load_type_id : base_code + '|' + grid.work_load + '|' + grid.work_load_type_id  + '|' +  grid.group.shift.id  + '|' + grid.group_number + '|' + grid.teacher_number;
                }

                var newJoin = {
                    key:                key,
                    byEquivalence:      grid.discipline.equivalence != null,
                    preview:            (grid.discipline_name.length > 50) ? grid.discipline_name.substring(0,50) + '...' :  grid.discipline_name,
                    name:               grid.discipline_name,
                    color:              (this.joins.length in this.colors) ? this.colors[this.joins.length] : this.globalColors(),
                    work_load:          parseFloat(grid.work_load),
                    work_load_amount:   parseFloat(grid.work_load),
                    work_load_percent:  0,
                    students:           0,
                    grids:              [],
                    selectedGrids:      []
                };

                newJoin.grids.push(grid);
                newJoin.students = grid.students;
                newJoin.selectedGrids.push(grid);
                this.joins.push(newJoin);
            }
        },

        isMultCampi(join){

            var campi = [];
            
            join.grids.forEach(grid => {
                if(!campi.includes(grid.group.campus.id)){
                    campi.push(grid.group.campus.id);
                }
            });

            return campi.length;

        },

        getCampi(join){

            var text = ''
            
            join.grids.forEach(grid => {
                
                var initials = grid.group.campus.initials;

                if(!text.includes(initials)){
                    if(text != ''){
                        text += ' | ';
                    }

                    text += initials;
                }

            });

            return text;

        },
    },
}
</script>