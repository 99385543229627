export default [
    {
        path: '/activity-items-approval',
        name: 'activity-items-approval',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Aprovação de Atividades',
            icon: '',
            breadcrumbs: [
                { text: 'Atividades', disabled: true, href: '/' },
                { text: 'Aprovação', disabled: true, href: '/' }
            ],
        }
    },
]