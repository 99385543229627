var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.renderComponent)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.teachers,"items-per-page":-1,"item-key":"index","hide-default-footer":"","fixed-header":"","height":"300"},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.teacher",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('sys-select-teacher',{attrs:{"model":item.teacher_id,"data":item.teacher,"error":_vm.errors.length > 0,"errors":_vm.errors['teachers.' + index + '.teacher_id'],"label":"","autoComplete":"","autofocus":"","showClear":"","dense":""},on:{"update:model":function($event){return _vm.$set(item, "teacher_id", $event)},"update:data":function($event){return _vm.$set(item, "teacher", $event)}}})]}},{key:"item.start",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker2',{attrs:{"errorMessages":_vm.errors['teachers.' + index + '.start'],"show":_vm.dialog,"model":item.start,"min":_vm.model.date_start,"max":_vm.model.date_end,"open-on-click":false,"readonly":false},on:{"update:model":function($event){return _vm.$set(item, "start", $event)}}})]}},{key:"item.end",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker2',{attrs:{"errorMessages":_vm.errors['teachers.' + index + '.end'],"show":_vm.dialog,"model":item.end,"min":_vm.model.date_start,"max":_vm.model.date_end,"open-on-click":false,"readonly":false},on:{"update:model":function($event){return _vm.$set(item, "end", $event)}}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['teachers.' + index + '.value'],"dense":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}},{key:"item.contract",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('sys-select-teacher-contract',{attrs:{"model":item.teacher_contract_id,"data":item.teacher_contract_id,"error":_vm.errors.length > 0,"errors":_vm.errors['teachers.' + index + '.teacher_contract_id'],"label":"","autoComplete":"","autofocus":"","showClear":"","dense":"","teacher-id":item.teacher_id,"campus-id":_vm.model.campus_id},on:{"update:model":function($event){return _vm.$set(item, "teacher_contract_id", $event)},"update:data":function($event){return _vm.$set(item, "teacher_contract_id", $event)}}})]}},{key:"item.note",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-textarea',{attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['teachers.' + index + '.note'],"dense":"","rows":"1","clearable":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"loading":_vm.loading && _vm.indexDelete == index,"disabled":_vm.loading,"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item, index)}}},[_c('v-icon',{attrs:{"small":"","color":"error","dense":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,false,3863022196)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }