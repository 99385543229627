export default [
    {
        path: '/baseGroups',
        name: 'baseGroups',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Turma base',
            icon: '',
            breadcrumbs: [
                { text: 'Oferta', disabled: true, href: '/' }, 
                { text: 'Turma base', disabled: true, href: '/' }, 
            ],
        }
    },
]