export default [
    {
        path: '/log-access-api',
        name: 'logAccessApi',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Log API',
            icon: '',
            breadcrumbs: [
                { text: 'Log API', disabled: true, href: '/' },
            ],
        }
    },
]
