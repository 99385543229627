<template>
    <v-dialog v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <div>
        <v-card>
            <v-app-bar color="grey lighten-4" dense>
                <v-toolbar-title><v-icon left>mdi-account-clock-outline</v-icon>&nbsp;&nbsp; Enviar email de Comunicação Geral</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :color="(modelTemplate.id) ? 'teal darken-1' : 'red'" icon @click.prevent.stop="showEmailTemplate = true" v-bind="attrs" v-on="on"><v-icon>mdi-card-account-mail-outline</v-icon></v-btn>
                    </template>
                    <span>{{ (modelTemplate.id) ? 'Template do email' : 'Template de email não cadastrado' }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" :disabled="saving" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>

            <v-card-text>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="teachers"
                    :single-select="false"
                    item-key="name"
                    show-select
                    :search="search"
                    class="elevation-1 mt-2"
                    dense
                    :itemsPerPage="10"
                    @current-items="getFiltered"
                    :item-selected="selected"
                    :loading="loading"
                    fixed-header
                    height="360"
                    
                >
                    <template v-slot:top>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                v-model="search"
                                label="Pesquisar..."
                                class="mx-4"
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-card-actions class="mx-2">
                                <v-spacer></v-spacer>
                                <v-btn dense color="primary" @click.prevent.stop="showSending()" :loading="saving" :disabled="saving || !modelTemplate.id || selected.length == 0" >Enviar para selecionados<v-icon right dark>mdi-send</v-icon></v-btn>
                            </v-card-actions>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:item.email="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span v-if="item.emails.length > 1">{{ item.email }} <strong>(+{{ item.emails.length - 1 }})</strong></span>
                                    <span v-else>{{ item.email }}</span>
                                </span>
                            </template>
                            <span v-html="item.emails.map(o => o['email']).join('<br/>')"></span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon :color="(modelTemplate.id) ? 'blue' : 'red'" small class="mr-2" @click="showEmail(item)" v-bind="attrs" v-on="on">mdi-newspaper-variant-outline</v-icon>
                            </template>
                            <span>{{ (modelTemplate.id) ? 'Visualizar' : 'Template de email não cadastrado' }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" icon v-bind="attrs" v-on="on" :disabled="saving || !modelTemplate.id || item.emails.length == 0" @click="showSending(item)"><v-icon small>mdi-send</v-icon></v-btn>
                            </template>
                            <span>Enviar email para {{ item.name }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

                <div class="caption mx-2"><strong>Quantidade selecionada: </strong>{{ selected.length }}</div>

            </v-card-text>

            

        </v-card>
        </div>

        <sys-register-template-email v-bind:onModel.sync="modelTemplate" :templateId="3" v-bind:show.sync="showEmailTemplate"/>

        <emc-loader-progress :show="loadingShowPreview" />
        <emc-modal-form v-bind:show.sync="showPreview">
            <template v-slot:form>
                <div v-html="previewMail"></div>
            </template>
        </emc-modal-form>

        <emc-alert-modal icon="mdi-send" title="Enviar email" :text="textSending" :disabled="saving" v-bind:show.sync="showSend">
            <template v-slot:actions>
                <v-btn color="primary" @click.prevent.stop="send()" :loading="saving" :disabled="saving">Enviar<v-icon right dark>mdi-send</v-icon></v-btn>
            </template>
        </emc-alert-modal>

        <emc-alert-snackbar :show="!! message.length"  :message="message" color="success"/>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'

export default {
    name: 'SysMailGeneralCommunication',
    data: () => ({
        dialog: false,
        saving: false,
        selected: [],
        filtered: [],
        teachers: [],
        search: '',
        showEmailTemplate: false,
        modelTemplate: [],
        showPreview: false,
        firstCharge: false,
        loadingShowPreview: false,
        showSend: false,
        itemsSending: [],
        textSending: '',
        itemSelected: null,
        message: '',
        previewMail: '',
        loading: false,
        workTime: null,
        headers: [
            { text: 'Nome', value: 'name'},
            { text: 'Email', value: 'email'},
            { text: 'Ações', value: 'actions', sortable: false, align: 'center'}
        ],
    }),
    created (){
        this.workTime = state.acesso.periodo;
    },
    props: {
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    watch: { 
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            
            this.dialog = show_new;

            if(show_new){
                this.firstCharge = true;
                this.search = '';
                this.itemSelected = null;
                this.getData();
            }

        },
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeachers']),
        ...mapActions('mail', ['ActionSendGeneralCommunication', 'ActionPreviewGeneralCommunication']),
        
        getData(){
            
            this.loading = true;
            this.selected = [];
            this.teachers = [];

            let payload = {
                column_order: 'name',
                direction: 'asc',
                with: 'emails',
                find_columns: {
                    active: 1,
                    teacher_status_id: 1
                },
                filter_relations: [
                    {
                        name:   'campuses',
                        key:    'id', 
                        value:  this.acesso.campus.map(o => o['id']).join(','),
                        clause: 'whereIn'
                    }
                ]
            };

            this.ActionFindTeachers(payload)
                .then((res) => {
                    
                    this.selected = res.data;
                    this.teachers = res.data;
                    
                })
                .finally(() => {
                    this.loading = false;
                    this.firstCharge = false;
                })
        },

        getFiltered(filtered){
            this.filtered = filtered
            
            if(this.search.length > 0) {
                this.selected = filtered
            }
            
        },
        showEmail(item) {
            this.loadingShowPreview = true
            this.ActionPreviewGeneralCommunication({ teacher_id: item.id })
                .then((res) => {
                    this.previewMail = res.data;
                })
                .finally(() => {
                    this.showPreview = true
                    this.loadingShowPreview = false
                })
        },

        showSending(item = null){
            
            if(item){
                this.itemsSending = [item];
                this.textSending = 'Confirma o envio do e-mail para ' + item.name + '?';
            }else{
                this.itemsSending = this.selected;
                if(this.selected.length == 1){
                    this.textSending = 'Confirma o envio do e-mail para ' + this.selected[0].name + '?';
                }else{
                    this.textSending = 'Confirma o envio do e-mail para os ' + this.selected.length + ' professores selecionados?';
                }
                
            }

            this.showSend = true;
            
        },

        send() {
            
            if(!this.itemsSending || this.itemsSending.length == 0){
                return;
            }

            this.saving = true;
            this.message = '';

            var items = this.itemsSending.map(o => ({ id: o['id'] }));
            var payload = {
                teachers: items
            };

            this.ActionSendGeneralCommunication(payload)
                .then((res) => {
                    this.message = res.message
                })
                .finally(() => {
                    this.saving = false;
                    this.itemsSending = [];
                    this.showSend = false;
                })
        },

    },
}
</script>