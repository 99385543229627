<template>
  <draggable>
      <v-progress-linear
          class="ma-1"
          v-if="loading"
          indeterminate
          color="yellow darken-2"
      />
    <div @drop="$emit('drop')">
      <v-sheet  v-if="showToolbar" height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setAll"
          >
            <span v-if='date.all'>Todo Semestre <v-icon color="success">mdi-check-all</v-icon></span>
            <span v-else>Por Data <v-icon color="indigo">mdi-calendar</v-icon></span>
          </v-btn>

          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Hoje
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>

            <v-spacer></v-spacer>
          
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet  height="280">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          first-time="18:00"
          interval-count="5"
          :start="start"
          show-week
          :weekdays=[1,2,3,4,5,6]
        >
          
          <template v-slot:day-label-header="{}">
            <span v-if="!showToolbar"></span>
          </template>
          
          <template v-slot:interval="{ time }">
            <!-- <v-icon color="success" small @click="teste(time)">mdi-plus</v-icon> -->
            <span @click="time"></span>
          </template>

        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
            
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <!-- <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon @click.stop.prevent="remove(selectedEvent)">mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                CANCELAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </div>
   </draggable>
</template>

<script>
import TemplateEmail from '../register/TemplateEmail.vue';

export default {
    name: "SysAmbienceCalendar",
      data: () => ({
      focus: '',
      type: 'week',
      showPlus: false,
      showToolbar: false,
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
    },
    props: {
      model: { },
      loading: {
        type: Boolean,
        default: false
      },
      date: {},
      minDate: {},
      maxDate: {},
      start: {},
     
    },

    watch: {

      model(val){

        this.events = [];

        if(val && val.allocations){

          val.allocations.forEach(el => {
              
              let event;
              
              el.dates.forEach(date => {
                
                event = {
                  id:       el.id,
                  name:     el.title,
                  start:    date.start,
                  end:      date.end,
                  details:  el.details,
                  color:    'cyan',
                  timed:    true,
                };

                this.events.push(event);

              });

          });

        }

      },

    },
    
    methods: {

      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },

      getEventColor (event) {
        return event.color
      },

      setToday () {
        this.focus = ''
      },

      setAll () {
        
        this.date.all = !this.date.all;

        if(this.date.all){
          this.setToday();
          this.type = 'week';
        }

        
      },

      prev () {
        this.$refs.calendar.prev()
      },

      next () {
        this.$refs.calendar.next()
      },
      
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        
        this.$emit('update:date', { start: start.date, end: end.date, all: this.date.all });
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      remove(event){
        this.$emit('remove', event);
        this.selectedOpen = false;
      },

      
    },
  }
</script>