<template>
        <v-card transition="fab-transition"
            v-if="grid"
            :color="grid.color"
            dark
            :draggable="(!user.is_master && locked) ? false : true"
            width='160px'
            height="70px"
            :class="getClassCard()"
            @mouseover="actions = true; $emit('mouseover', $event)"
            @mouseleave="actions = false; $emit('mouseleave', $event)"
            @dragstart="dragstartCard($event)"
            @dragend="dragendCard($event)"
            @dblclick="deleteCard(classe)"
            @click="clickCard()"
        >
            <v-card-actions>
                    
                    <v-tooltip v-if="grid.category && !grid.category.default" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip style="position: absolute; bottom: 0px; left: 45px; cursor: help" v-bind="attrs" v-on="on" class="mb-1" color="white" label x-small outlined>
                                {{ grid.category.initials }}
                            </v-chip>
                        </template>
                        <span>{{ grid.category.name }}</span>
                    </v-tooltip>
                    
                    <v-tooltip 
                        v-if="grid.join"
                        bottom 
                        >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    small
                                    style="position: absolute; bottom: 4px; left: 5px; cursor: help" 
                                    left
                                    v-on="on"
                                    @click="$emit('click', grid)"
                                >
                                        mdi-call-split
                            </v-icon>
                            </template>
                            <span v-if="grid.join && grid.join.grids">
                                <template>
                                    <ul type="none">
                                        <li :key="grid.id" v-for="grid in grid.join.grids">
                                            <span v-if="grid.group">{{ grid.group.campus.name + ' | ' + grid.group.name + ' | ' +  grid.discipline_name }}</span>
                                        </li>
                                    </ul>
                                </template>
                            </span>
                            <span v-else>Carregando informações...</span>
                     </v-tooltip>

                     <v-tooltip bottom v-if="classe.start_date">
                        <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                        small
                                        style="position: absolute; top: 2px; right: 1.5px; cursor: help"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-calendar
                                </v-icon>
                            </template>
                                <span>
                                    <span v-if="classe.start_date == classe.end_date">{{ classe.start_date | date }}</span>
                                    <span v-else>{{ classe.start_date | date }} - {{ classe.end_date | date }}</span>
                                </span>
                                
                        </v-tooltip>

                     <v-tooltip 
                        v-if="grid.ambience"
                        bottom 
                        >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    small
                                    :style="grid.join ? 'position: absolute; bottom: 4px; left: 20px; cursor: help' : 'position: absolute; bottom: 4px; left: 5px; cursor: help'" 
                                    left
                                    v-on="on"
                                >
                                    {{ ( classe && classe.ambience && classe.ambience.type && classe.ambience.type.is_virtual) ? 'mdi-monitor-dashboard' : 'mdi-home' }}
                            </v-icon>
                            </template>
                            <span>{{ getAmbienceClasse() }}</span>
                     </v-tooltip>

                     <v-tooltip 
                        v-if="grid.work_time_date"
                        bottom 
                        >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                    small
                                    :style="(grid.join && grid.ambience) ? 'position: absolute; bottom: 4px; left: 35px; cursor: help' : (grid.join || grid.ambience) ? 'position: absolute; bottom: 4px; left: 20px; cursor: help' : 'position: absolute; bottom: 4px; left: 5px; cursor: help'" 
                                    left
                                    v-on="on"
                                >
                                    event
                            </v-icon>
                            </template>
                            <span>{{ grid.work_time_date.name }}</span>
                     </v-tooltip>

                    <v-spacer/>
                    
                    <div v-if="locked || (!hideActions && actions)">

                        <v-tooltip v-if="locked == false" bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    style="position: absolute; bottom: 4px; right: -5px;" 
                                    left
                                    :color="locked ? 'transparent' : 'with'"
                                    @click="deleteCard(classe)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                                </template>
                            <span>Remover</span>
                        </v-tooltip>
                        <div v-if="showLock">
                            <v-spacer/>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        style="position: absolute; bottom: 4px; right: 15px" 
                                        small
                                        left
                                        @click.stop="lockCard()"
                                        v-on="on"
                                        
                                    >
                                        {{ locked ? 'mdi-lock' : 'mdi-lock-open-variant' }}
                                    </v-icon>
                                </template>
                                <span>Bloqueio</span>
                            </v-tooltip>
                        </div>
                    </div>
            
            </v-card-actions>

            <v-tooltip v-if="showData || actions" bottom>
                <template v-slot:activator="{ on }">
                    <span v-on="on">
                        <v-card-title class="pa-0" style="position: absolute; top: 2px; left: 5px;font-size:1em" >
                            <emc-info-help v-if="grid.grid_note" icon="mdi-information-outline" title="Observação" :text="grid.grid_note" />
                            {{ getNameDiscipline(grid) }}
                    </v-card-title>
                        <v-card-subtitle v-if="grid && (grid.teacher || grid.in_hiring)" style="position: absolute; top: 44px; left: 6px;font-size:1em" class="pa-0">
                            <span v-if="grid.teacher">{{ getFirstName(grid.teacher.name, widthTeacher) }}</span>
                            <span v-else>A Contratar</span>
                        </v-card-subtitle>
                        <!-- <v-card-subtitle v-else style="position: absolute; top: 48px; left: 10px;" class="pa-0">A Definir</v-card-subtitle> -->
                    </span>
                </template>
                    <span>
                        {{ getNameDisciplineToolTip(grid) }}
                        <br/>
                        <span v-if="grid && grid.teacher">
                            {{ grid.teacher.name }}
                        </span>

                        <span v-if="grid.dates && grid.dates.length > 0">
                            <ul type="none">
                                <li :key="i" v-for="(date, i) in grid.dates">
                                    <span>{{ date.initials }} : {{ date.date|date }}</span>
                                </li>
                            </ul>
                        </span>

                    </span>
            </v-tooltip>
        </v-card>
    
</template>

<style scoped>
  
  .grabbable {
        cursor: move;  /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .not-grabbable {
        cursor: no-drop;
        cursor: -moz-no-drop;
        cursor: -webkit-no-drop;
    }

    /* (Optional) Apply a "closed-hand" cursor during drag operation. */
    .grabbable:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }

    
</style>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysScheduleCard",
    data: () => ({
       dragging: false,
       locked: false,
       actions: false,
       showLock: false
       
    }),
    
    computed: {
        ...mapState('auth', ['user']),

      widthDiscipline () {
        
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':             
          case 'sm': return 3;
          default:  return 10;
        }

      },

      widthTeacher () {
        
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':             
          case 'sm': return 6;
          default:  return 15;
        }

      }

    },
    watch: {
        
        location(val){
            
            if(val && this.grid){
                var classes = this.grid.classes.find(c => (c.day_of_week_id == val.day && c.hour_id == val.hour.id));
                if(classes){
                    this.locked = classes.locked == 1;
                }
            }
            
        },
    },
    props: {
        grid: {
            type: Object,
        },
        classe: {
            type: Object,
        },
        hideActions: {
            type: Boolean,
            default: false
        },
        location: {
            type: Object,
        },
        showData: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        ...mapActions('indication', ['ActionGetGridJoin']),
        deleteCard(event) {
            if(this.user.is_master || !this.locked)
                this.$emit('onDelete', event);
        },

        lockCard() {
            this.locked = !this.locked;
            this.$emit('onLock', { grid: this.grid, locked: this.locked });
        },

        dragstartCard(event){
            
            if(!this.user.is_master && this.locked && this.locked == true){
                return;
            }

            this.dragging = true;
            event.locked = this.locked;
            event.grid = this.grid;
            this.$emit('onDragstartCard', event);
        },

        dragendCard(event){
            this.dragging = false;
            this.$emit('onDragendCard', event);
        },

        getNameDisciplineToolTip(grid){
            
            var name = grid.discipline.name;
            var type = grid.work_load_type;
            var period = grid.periodicity_type;

            if(type != 'TE'){
                name = type + '-' + name;
            }

            if(period != 'S'){
                name = name + ' (' + period + ')';
            }

            if(grid.group && grid.group_number != 0 && grid.teacher_number != 0){
                name = 'D' + grid.group_number + '/P' + grid.teacher_number + '-' + name;
            }else if(grid.group && grid.group_number != 0){
                name = 'D' + grid.group_number + '-' + name;
            }else if(grid.group && grid.teacher_number != 0){
                name = 'P' + grid.teacher_number + '-' + name;
            }

            return name;
        },

        getNameDiscipline(grid){
            
            var name = grid.discipline.name;
            var type = grid.work_load_type;
            var period = grid.periodicity_type;

            if(type != 'TE'){
                name = type + '-' + name;
            }

            name = this.getFirstName(name, this.widthDiscipline, true);

            if(grid.group && grid.group_number != 0 && grid.teacher_number != 0){
                name = 'D' + grid.group_number + '/P' + grid.teacher_number + '-' + name;
                name = this.getFirstName(name, this.widthDiscipline, true);
            }else if(grid.group && grid.group_number != 0){
                name = 'D' + grid.group_number + '-' + name;
                name = this.getFirstName(name, this.widthDiscipline, true);
            }else if(grid.group && grid.teacher_number != 0){
                name = 'P' + grid.teacher_number + '-' + name;
                name = this.getFirstName(name, this.widthDiscipline, true);
            }

            if(period != 'S'){
                name = name + ' (' + period + ')';
            }

            return name;
        },

        getFirstName(name, length, personal = false){
            
            var index = name.indexOf(' ');
            var firstName = name;

            if(index != -1){
                
                var parts = name.split(' ');

                if(parts.length > 1 && (parts[0].toUpperCase() == 'A' || parts[0].toUpperCase() == 'O')){
                    firstName = parts[1];
                }else{
                    firstName = parts[0];
                    
                    if(personal && firstName.length < length && parts.length > 1){
                        firstName = parts[0] + ' ' + parts[1];
                    }
                }
            }

            if(firstName.length > length)
                firstName = firstName.substring(0, length);

            return firstName;
        },

        clickCard(){
            
            if(!this.user.is_master && this.locked && this.locked == true){
                return;
            }

            this.$emit('onClickCard', this.grid);
        },

        getClassCard(){

            return  {
                grabbable:          !this.locked,
                'not-grabbable':    !this.user.is_master && this.locked
            };
        },

        getAmbienceClasse(){
            
            if(this.classe.ambience){
                return this.classe.ambience.name;
            }else{
                return '';
            }
        }
    }
}
</script>