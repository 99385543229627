import { service } from '../../../../helpers/http'

export const ActionIndexSpecificities = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexSpecificities',
        payload: payload
    })

}
export const ActionDestroySpecificity = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroySpecificity',
        payload: payload
    })
}
export const ActionStoreSpecificity = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeSpecificity',
        payload: payload
    })

}
export const ActionUpdateSpecificity = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateSpecificity',
        payload: payload
    })
}