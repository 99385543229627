<template>
    <div>
        <emc-avatar-base 
            :src="model.url_avatar" 
            @onComplete="upload"
            :width="width"
            :height="height"
            :model="model"
        />
    </div>
</template>
<script>

export default {
    name: "EmcAvatarAmbience",
    data() {
        return {
           
        };
    },
    props: {
        model: {
            required: true
        },
        width: {
            default: 100
        },
        height: {
            default: 100
        },
        showMenu: {
            type: Boolean,
            default: true
        }
    },

    watch: {
      
    },
    methods: {
        
        upload(form){
            this.$emit('upload', form);
        },
    }
};
</script>