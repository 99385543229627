<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'" persistent >
        <v-card 
                outlined
                height="auto"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Demais filtros
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-activity-item-status
                                :model.sync="itemsStatus"
                                label="Status"
                                multiple
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-campus
                                :model.sync="campuses"
                                label="Campus"
                                multiple
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-activity-category
                                :model.sync="categories"
                                label="Categoria da Atividade"
                                multiple
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-departament
                                :model.sync="departaments"
                                label="Departamento da Atividade"
                                multiple
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-switch inset v-model="myItems" color="purple" label="Exibir atividades cadastradas por mim"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-switch inset v-model="myApprovals" color="purple" label="Exibir atividades validadas por mim"></v-switch>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

                <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "SysActivityItemFilter",
    data: (vm) => ({
        dialog: false,
        loading: false,
        itemsStatus:  [],
        campuses: [],
        categories: [],
        departaments: [],
        myItems: false,
        myApprovals: false,
    }),
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            this.dialog = val;
        },

    },
    computed: {
        ...mapState('auth', ['user'])
    },
    methods: {

       filter(){

            var payload = null;

            if(this.myItems){
                payload = {};
                payload.find_columns = {
                    user_id_creator: this.user.id
                }
            }

            if(this.myApprovals){
                
                if(!payload){
                    payload = {};
                }

                if(!payload.find_columns){
                    payload.find_columns = {};
                }

                payload.find_columns.user_id_approver = this.user.id;
                   
            }

            if(this.itemsStatus && this.itemsStatus.length > 0){
                
                if(!payload){
                    payload = {};
                }
                
                let ids = this.itemsStatus.map(o => o['id']).join(',');
                
                payload.filter_relations = [
                    {
                        name:   'status',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    },
                ];
            }

            if(this.campuses && this.campuses.length > 0){
                
                let ids = this.campuses.map(o => o['id']).join(',');
                
                if(!payload){
                    payload = {};
                }
                
                if(payload.find_columns){
                    payload.find_columns.campus_id = ids;
                }else{
                    payload.find_columns = {
                        campus_id: ids
                    }
                }
                
            }

            if(this.categories && this.categories.length > 0){
                
                if(!payload){
                    payload = {};
                }

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                let ids = this.categories.map(o => o['id']).join(',');
                
                payload.filter_relations.push(
                    {
                        name:   'activity',
                        key:    'activity_category_id', 
                        value:  ids,
                    },
                );
                
            }

            if(this.departaments && this.departaments.length > 0){
                
                if(!payload){
                    payload = {};
                }

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                let ids = this.departaments.map(o => o['id']).join(',');
                
                payload.filter_relations.push(
                    {
                        name:   'activity.category',
                        key:    'departament_id', 
                        value:  ids,
                    },
                );
                
            }

            this.$emit('filter', payload);

       },

       clearFilter(){

            this.myItems        = false;
            this.myApprovals    = false;
            this.itemsStatus    = [];
            this.campuses       = [];
            this.categories     = [];
            this.departaments   = [];
            this.$emit('filter', null);

       },

    }
}
</script>