import { service } from '../../../helpers/http'

export const ActionIndexEventCalendarBases = ({ dispatch }, payload) => {
    return service({
        module: 'eventCalendarBase',
        action: 'indexEventCalendarBases',
        payload: payload
    })
}

export const ActionCreateEventCalendarBase = ({ dispatch }, payload) => {
    return service({
        module: 'eventCalendarBase',
        action: 'storeEventCalendarBase',
        payload: payload
    })
}

export const ActionUpdateEventCalendarBase = ({ dispatch }, payload) => {
    return service({
        module: 'eventCalendarBase',
        action: 'updateEventCalendarBase',
        payload: payload
    })
}

export const ActionDeleteEventCalendarBase = ({ dispatch }, payload) => {
    return service({
        module: 'eventCalendarBase',
        action: 'deleteEventCalendarBase',
        payload: payload
    })
}