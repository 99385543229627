<template>
    <div>
     <v-text-field 
          v-model="innerModel"
          v-bind:value="value"
          v-on:input="$emit('input', $event)"
          :error="errorMessages && errorMessages.length > 0" 
          :error-messages="errorMessages"  
          :append-outer-icon="innerModel"
          :clearable="!required"
          :label="label"
          :disabled="disabled"
          :autofocus="autofocus"
          >
          <template v-slot:prepend-inner>
               <emc-info-help :title="textHelper" >
                    <template v-slot:text>
                         Clique <a href="https://pictogrammers.github.io/@mdi/font/5.0.45/"  target="_blank">aqui</a> para escolher um ícone.
                    </template>
               </emc-info-help>
          </template>
     </v-text-field>
    </div>
</template>
<script>
import store from '../../store'
export default {
    name: "STextFieldIcon",
    data () {
        return {
            innerModel: null,
            user: null,
        }
    },
    created(){
        this.innerModel = this.value;
        this.user = store.state.auth.user;
    },
    computed: {
      
    },
    watch: {
        
        value(val_new){
            this.innerModel = val_new;
        },

    },
    props: {
        value: {},
        dense: {
            type: Boolean,
            default: false
       },
       autofocus: {
            type: Boolean,
            default: false
       },
       error: {
            type: Boolean,
            default: false
       },
       label: {
            type: String,
            default: 'Ícone',
       },
       textHelper: {
            type: String,
            default: 'Como escolher um ícone...',
       },
       disabled: {
            type: Boolean,
            default: false
       },
       required: {
          type: Boolean,
          default: false
       },
       errorMessages: {
            type: Array,
       },

    },

}
</script>
