<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="modelBase" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="98%"
        :actions="false"
    >

    <template v-slot:tools>

        <emc-excel-import-icon param="payment_export_items" :item-id="model.id" @onCompleted="getData()" />

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="indigo darken-1" icon @click.prevent.stop="modelItem = {}; showForm = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <span> Novo registro </span>
        </v-tooltip>
        
    </template>
    
        <template v-slot:form>

            <main-container :showToolBar="false" icon="mdi-account-cash" title="Folha de Pagamento">
                <emc-table-items 
                    :headers="headers.filter(h => (!h.types.length || h.types.includes(model.payment_type_id)))" 
                    v-bind:params.sync="params"
                    v-bind:collections.sync="collections"
                    v-bind:meta.sync="meta"
                    v-bind:itemDelete.sync="itemDelete" 
                    v-bind:itemEdit.sync="modelItem"
                    :loading="loading"
                    :showActions="false"
                >
                    
                    <template v-slot:item.group="{item}">
                        <span v-if="item.course">{{ item.course.name }}</span>
                        <small v-if="item.group"><br/>{{ item.group.name }}</small>
                        <small  v-if="item.discipline"><br/>{{ item.discipline.name }}</small>
                        <small  v-if="item.join_id"><br/>Junção: {{ item.join_id }} | Principal: {{ item.main ? 'Sim' : 'Não' }}</small>
                    </template>

                    <template v-slot:item.teacher="{item}">
                        {{ item.teacher ? item.teacher.name : null }}
                        <br/>
                        <small>{{ item.start|date }} - {{ item.end|date }}</small>
                        <small v-if="item.company"><br/>{{ item.company.initials }}</small>
                        <small v-if="item.registration"><br/>{{ item.registration }}</small>
                    </template>

                    <template v-slot:item.value_formated="{item}">
                        <span v-if="item.paymentExport.payment_export_prorated_type_id == 1">
                            <span v-if="item.value_formated == parseFloat(item.value_prorated_grids)">
                            {{ parseFloat(item.value_prorated_grids) }}
                            </span>
                            <span v-else>
                                CH: {{ item.value_formated }} | RT: {{ parseFloat(item.value_prorated_grids) }}
                            </span>
                        </span>
                        <span v-else-if="item.paymentExport.payment_export_prorated_type_id == 2">
                            <span v-if="item.value_formated == parseFloat(item.value_prorated_students)">
                            {{ parseFloat(item.value_prorated_students) }}
                            </span>
                            <span v-else>
                                CH: {{ item.value_formated }} | RT: {{ parseFloat(item.value_prorated_students) }}
                            </span>
                        </span>
                        <span v-else-if="item.paymentExport.payment_export_prorated_type_id == 3">
                            {{ parseFloat(item.value) }}
                        </span>
                        <small>
                            <br/>
                            {{ item.valueType.name }}
                            <br/>
                            {{ item.periodicity.name }}
                        </small>
                    </template>

                    <template v-slot:item.status="{item}">
                        {{ item.status.name }}
                        <small v-if="item.payment_export_item_sum_id"><br/>Núm: {{ item.payment_export_item_sum_id }}</small>
                    </template>
                
                    <template v-slot:item.register="{ item }">
                        {{ item.user.name }}
                        <br/>
                        <small>{{ item.updated_at | dateTime }}</small>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        
                        <emc-table-icon :tool-tip="item.payment_export_item_status_id == 4 ? 'Ver Detalhes' : 'Editar'" :color="item.payment_export_item_status_id == 4 ? 'success' : 'blue accent-4'" :icon="item.payment_export_item_status_id == 4 ? 'mdi-file-eye-outline' : 'mdi-pencil-outline'" @click="modelItem = item" />
                        <emc-table-icon-delete v-if="item.payment_export_item_status_id != 4" @click="itemDelete = item" />
                            

                    </template>
                </emc-table-items>

                <sys-payment-export-register-item @onCompleted="getData()" :payment-export="model" :item="modelItem" v-bind:show.sync="showForm" />

                <emc-alert-modal text="Deseja excluir o item?" :disabled="deleting" v-bind:show.sync="showDelete">
                    <template v-slot:actions>
                        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                    </template>
                </emc-alert-modal>

                

            </main-container>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!!message.length"  :message="message" :color="error ? 'error' : 'success'"/>

        </template>

    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysPaymentExportListItem",
    data: (vm) => ({
        dialog: false,
        collections: [],
        params: {},
        meta: {},
        error: false,
        errors: {},
        modelBase: {},
        modelItem: {},
        message: '',
        loading: false,
        showDelete: false,
        showForm: false,
        itemDelete: {},
        deleting: false,
        headers: [
            { text: 'Campus',       value: 'campus.name',       types: [], sortable: false },
            { text: 'Turma',        value: 'group',             types: [ 1, 3, 5, 6 ], sortable: false },
            { text: 'Atividade',    value: 'activity.name',     types: [ 2 ], sortable: false },
            { text: 'Professor',    value: 'teacher',           types: [], sortable: false },
            { text: 'Valor',        value: 'value_formated',    types: [], align: 'center', sortable: false },
            { text: 'Status',       value: 'status',            types: [], align: 'center', sortable: false },
            { text: 'Cadastro',     value: 'register',          types: [], align: 'center', sortable: false },
            { text: 'Ações',        value: 'actions',           types: [], sortable: false },
        ]
    }),
    watch: {
        
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.showDelete = true;
            } else {
                this.showDelete = false
            }
        },
        
        params(params_new, params_old){
            if(params_new != params_old) {
                if(!this.loading && this.params.page){
                    this.getData();
                }
            }
        },
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.params = { search: null, column_order: 'id', direction: 'asc', page: 1, per_page: 5 };
                this.collections = [];
            }
        },

        modelItem(item_new, item_old){
          
        if(item_new !== item_old && this.modelItem.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.modelItem = {};
          }
      },
        
    },
    props: {
        model: {
            default: null
        },
        icon: {
            type: String,
            default: "mdi-account-cash"
        },
        title: {
            type: String,
            default: "Registros de Pagamento"
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('paymentExport', ['ActionFindPaymentExportItems', 'ActionDeletePaymentExportItem']),

        getData(){
      
            this.loading = true;
            var payload = this.params;
            this.showForm = false;
            
            payload.find_columns = { payment_export_id: this.model.id };
            payload.with = 'paymentExport,periodicity,valueType,activity,campus,course,group,teacher,discipline,status,user,company';
            
            this.ActionFindPaymentExportItems(payload)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    deleteItem()
    {
        this.message = '';
        this.error = false;
        this.errors = {};
        this.deleting = true;

        this.ActionDeletePaymentExportItem({ id: this.itemDelete.id })
            .then((res) => {
                this.getData();
            })
            .catch((error) =>{
               
                this.error      = true;
                this.errors     = error.errors;

                if(error.errors && error.errors.length > 0){
                    this.message = error.errors[0];
                }else{
                    this.message = error.message;
                }
               
            })
            .finally(() => {
                this.deleting = false;
                this.showDelete = false;
                this.itemDelete = null;
            });
    },
    }
}
</script>