import { service } from '../../../helpers/http'
export * from '../download/store/actions'
export * from '../import/store/actions'
export * from '../setting/store/actions'
export * from '../photo/store/actions'

export const ActionFindUsers = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'findUsers',
        payload: payload
    })
}

export const ActionDeleteUser = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'deleteUser',
        payload: payload
    })
}

export const ActionUpdateUser = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'updateUser',
        payload: payload
    })
}

export const ActionCreateUser = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'createUser',
        payload: payload
    })
}

export const ActionUpdateProfile = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'updateProfile',
        payload: payload
    })
}

export const ActionUserUploadAvatar = ({ dispatch }, payload) => {

    return service({
        module: 'user',
        action: 'uploadAvatar',
        payload: payload
    })
}

export const ActionIndexUsers = (({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'index',
        payload: payload
    })
})

export const ActionMyTicketsIndex = ({ dispatch }, payload) => {
    return service({
        module: 'user',
        action: 'myTicketsIndex',
        payload: payload
    })
}