<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Campus</th>
                        <th>Atividade</th>
                        <th>Periodicidade</th>
                        <th>Tipo</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Compõe CH</th>
                        <th class="text-center">Período</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.campus ? item.campus.name : '' }}</td>
                        <td>{{ item.activity.name }}</td>
                        <td>{{ item.periodicityType.name }}</td>
                        <td>{{ item.valueType.name }}</td>
                        <td class="text-center">{{ item.value }}</td>
                        <td class="text-center">
                            <v-icon small v-if="item.activity.is_work_load" color="success">mdi-check-bold</v-icon>
                            <v-icon small v-else color="error">mdi-close-thick</v-icon>
                        </td>
                        <td class="text-center">{{ item.date_start|date }} - {{ item.date_end|date }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherOtherActivitiesAttribution',
    data: function() {
        return {
            items: [],
            loading: false,
            renderComponent: true,
        }
    },
    props: {
        attribution: {
            type: Object,
            required: true,
        },
    },
    methods: {
        ...mapActions('activityItem', ['ActionFindActivityItems']),

        getData() {

            this.items = [];

            if(!this.attribution || !this.attribution.id) {
                return;
            }

            this.loading = true;

            var params = {
                with: 'campus,activity,valueType,periodicityType',
                showAllCampuses: 'true',
                showAllTeachers: true,
                filter_relations: [
                    {
                        name:   'attributions',
                        key:    'attributions.id', 
                        value:  this.attribution.id,
                    }
                ]
            }

            this.ActionFindActivityItems(params)
                .then((res) => {
                    this.items = res.data;
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        attribution: function(){
            this.getData()
        },
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>