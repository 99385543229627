import { service } from '../../../helpers/http'
export * from '../specificity/store/actions'
export * from '../date_mask/store/actions'
export * from '../date_mask_type/store/actions'
export * from '../modality/store/actions'
export * from '../offer/store/actions'
export * from '../offer_analitics/store/actions'

export const ActionFindGrids = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'find',
        payload: payload
    })
}

export const ActionFindGridById = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'findById',
        idAndPayload: true,
        payload: payload
    })
}

export const ActionGetPeriodicityTypes = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'periodicityTypes',
        payload: payload
    })
}

export const ActionCreateGrid = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridCreate',
        payload: payload
    })
}

export const ActionCreateDivisionGrid = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridCreateDivision',
        payload: payload
    })
}

export const ActionDeleteDivisionGrid = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridDeleteDivision',
        payload: payload
    })
}

export const ActionUpdateGrid = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridUpdate',
        payload: payload
    })
}

export const ActionDeleteGrid = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridDelete',
        payload: payload
    })
}
export const ActionFindGroupsHourDate = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'gridsGroupsHourDate',
        payload: payload
    })

}

export const ActionFindShiftsHourDate = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'gridsShiftsHourDate',
        payload: payload
    })

}


export const ActionCreateAddTeacher = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridAddTeacher',
        payload: payload
    })
}

export const ActionCreateAddTeacherAll = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridAddTeacherAll',
        payload: payload
    })
}

export const ActionDeleteAddTeacher = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridDeleteAddTeacher',
        payload: payload
    })
}

export const ActionIndexGridCategories = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'indexGridCategories',
        payload: payload
    })
}

export const ActionCreateGridCategory = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'createGridCategory',
        payload: payload
    })
}

export const ActionUpdateGridCategory = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'updateGridCategory',
        payload: payload
    })
}

export const ActionDeleteGridCategory = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'deleteGridCategory',
        payload: payload
    })
}

export const ActionIndexOptimized = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'indexOptimized',
        payload: payload
    })
}

export const ActionFindGridDates = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'findGridDates',
        payload: payload
    })
}

export const ActionDeleteGridDate = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'deleteGridDate',
        payload: payload
    })
}

export const ActionUpdateHiring = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'updateHiring',
        payload: payload
    })
}

export const ActionGridLockAttribution = ({ dispatch }, payload) => {
    return service({
        module: 'grid',
        action: 'gridLockAttribution',
        payload: payload
    })
}

