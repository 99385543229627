<template>
    <div>
        <emc-message-base
            ref="message"
            :items="items" 
            :title="title" 
            :show.sync="dialog" 
            :loading="loading"
            :loadingUpdate="loadingUpdate"
            :loadingDelete="loadingDelete"
            :loadingSend="loadingSend"
            @onSend="sendMessage"
            @onDelete="deleteItem"
            @onUpdate="updateMessage"
            @getData="getData()"
            :sent="sent"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "EmcMessageChat",
    data: () => ({
        dialog: false,
        loading: false,
        loadingUpdate: false,
        loadingDelete: false,
        loadingSend: false,
        sent: false,
        items:[]
    }),
     props: {
        title: {
            type: String,
            default: "Registro de Anotações"
        },
        show: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            required: true
        },
        model: {

        }
    },

    mounted(){
       
    },

    watch: {
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.items = [];
                this.getData(true);
            }

        },
    },

    methods: {
        ...mapActions('message', ['ActionIndexMessages', 'ActionCreateMessage', 'ActionDestroyMessage', 'ActionUpdateMessage']),
        
        getData(load = false){

            if(!this.model || !this.model.id){
                return;
            }

            this.loading = load;

            let total = this.items.length;

            var payload = {
                column_order: 'created_at',
                direction: 'asc',
                find_columns: {
                    type:       this.type,
                    item_id:    this.model.id
                },
                with: 'user'
            }

            this.ActionIndexMessages(payload)
                .then((res) => {
                    
                    this.items = res.data;
                    
                })
                .finally(()=>{
                    
                    if(this.items.length > total){
                        this.$refs.message.setScroll();
                    }
                    
                    this.loading = false;
                    this.loadingSend = false;
                    this.loadingDelete = false;
                    this.sent = true;
                });

        },

        sendMessage(message){

            this.sent = false;
            this.loadingSend = true;
            
            var payload = {
               type:    this.type,
               item_id: this.model.id,
               message: message
            }

            this.ActionCreateMessage(payload)
                .then((res) => {
                    
                   this.getData();
                   this.modelMessage = null;
                   

                })
                .finally(()=>{
                    
                });

        },

        updateMessage(item){

            this.loadingUpdate = true;
            this.sent = false;
            
            this.ActionUpdateMessage(item)
                .then((res) => {
                    
                   this.sent = true;

                })
                .finally(()=>{
                     this.loadingUpdate = false;
                });

        },

        deleteItem(item){

            this.loadingDelete = true;
            this.sent = false;
            
            this.ActionDestroyMessage({ id: item.id })
                .then((res) => {
                   this.getData();
                })
                .finally(()=>{
                    
                });

        }
    },
   
}
</script>