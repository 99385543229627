<template>
    <EmcCardStats 
        color="blue darken-4"
        icon="mdi-school-outline"
        icon-large
        :value="this.grids"
        title="A contratar"
        sub-text="Número de disciplinas a contratar"
        />
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartGridInHiringNumber',
    data () {
        return {
            grids: '0',
            title: ''
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartGridInHiringNumber']),
        getData() {
            this.ActionChartGridInHiringNumber()
                .then((r) => {
                    this.grids = r.data.value.toString()
                    this.title = r.data.title
                })
        }
    },
    created(){
        this.getData()
    }

    
}
</script>