<template>
       <v-card
          max-width="450"
          class="mx-auto"
          oncontextmenu="return false;"
          @mousedown="$emit('mousedown', $event)"
        >
          
          <emc-message-chat :show.sync="dialogChat" :model="gridChat" type="grid" title="Registro de Anotações da Disciplina (Chat)" />
          
          <v-list dense>
            
            <v-subheader v-if="showActions" >
              
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" small icon @click.prevent.stop="openDialogNewGrid()">
                          <v-icon btn color="green darken-1">mdi-plus</v-icon>
                      </v-btn>
                  </template>
                      <span>
                      Novo
                  </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="selected == -1" v-bind="attrs" v-on="on" small icon @click.prevent.stop="openDialogEditGrid()">
                        <v-icon btn color="purple darken-1">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                    <span>
                    Editar
                </span>

            </v-tooltip>

              <span v-if="showHeader">

              <span class="blue--text"> {{ title }} </span>
                            
              <v-spacer></v-spacer>
              
                  <v-tooltip bottom>
                    
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              dark
                              x-small
                              color="red"
                              @click="drawer"
                            >
                          <v-icon>mdi-format-list-checkbox</v-icon>
                        </v-btn>
                    </template>
                        <span>
                        Selecionar turma...
                    </span>

                </v-tooltip>

            </span>

            </v-subheader>
            
            <v-list-item-group 
              v-model="selected" 
              color="blue"
            >
              <v-list-item v-for="(item, i) in items" :key="i" @click="$emit('click', item)">
                
                <v-list-item-content>
                    
                   <v-list-item-title :class="getPendencies(item) > 0 ? 'red--text' : 'blue--text'">
                      {{ getNameDiscipline(item) }}
                    </v-list-item-title>
                   <v-list-item-subtitle v-if="item.specificity" :class="getPendencies(item) > 0 ? 'red--text' : 'blue--text'">[{{ item.specificity.abbreviation }}]</v-list-item-subtitle>
                   <v-list-item-subtitle>
                        <v-chip
                          text-color="white"
                          :color="item.color"
                          :outlined="false"
                          pill
                          x-small
                        >
                          <span v-if="getPendencies(item) <= 0">
                            {{ item.work_load_time_table }}
                            <v-icon small>
                              mdi-check
                            </v-icon>
                          </span>
                          <span v-else>
                            {{ getPendencies(item) }} / {{ item.work_load_time_table  }}
                          </span>
                      </v-chip>

                      <v-tooltip v-if="item.work_time_date" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip v-bind="attrs" v-on="on" class="ml-1" color="orange" label x-small outlined>
                                  <v-icon left x-small>
                                    event
                                  </v-icon>
                                  {{ item.work_time_date.initials }}
                              </v-chip>
                            </template>
                            <span>{{ item.work_time_date.name }}</span>
                      </v-tooltip>
                             
                      <v-tooltip v-if="item.join" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip  v-bind="attrs" v-on="on" class="ml-1"  color="indigo" label x-small outlined>
                                <v-icon x-small>
                                  mdi-call-split
                                </v-icon>
                          </v-chip>
                         </template>
                         <span>{{ '[' + item.join.join_id + '] ' +  getTextJoin(item.join) }}</span>
                       </v-tooltip>

                      <v-tooltip v-if="item.category && !item.category.default" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip v-bind="attrs" v-on="on" class="ml-1" color="purple" label x-small outlined>
                                  {{ item.category.initials }}
                              </v-chip>
                            </template>
                            <span>{{ item.category.name }}</span>
                      </v-tooltip>

                      <v-chip class="ml-1" v-if="item.ambience" color="indigo" label x-small outlined>
                          <v-icon x-small>
                            mdi-home
                          </v-icon>
                      </v-chip>

                      <emc-info-help v-if="item.grid_note" icon="mdi-information-outline" title="Observação" :text="item.grid_note" />

                    </v-list-item-subtitle>
                     <v-list-item-subtitle class="ml-1" v-if="item.teacher || item.in_hiring">
                       <span v-if="item.teacher">{{ getFirstName(item.teacher.name) }}</span>
                       <span v-else-if="item.in_hiring">A Contratar</span>
                     </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  
                  <span style="cursor: help; position: relative; right: 10px" @click.stop.prevent="openChat(item)">
                        
                        <v-badge
                            v-if="item.has_message"
                            bordered
                            top
                            color="red"
                            overlap
                            dot
                            class="ml-1"
                          >
                            <v-icon
                                large
                                color="blue darken-2"
                                small
                              >
                                mdi-email
                              </v-icon>
                            
                          </v-badge>
                          <v-icon
                              v-else
                              large
                              color="grey"
                              small
                              class="ml-1"
                            >
                              mdi-email
                            </v-icon>
                  </span>

                  <emc-info-popover
                    :title="item.discipline.name"
                  >
                    <template v-slot:card-text>

                    <v-list dense>
                      
                      <v-list-item v-if="item.teacher">
                        <v-list-item-avatar>
                          <v-icon>mdi-school-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ item.teacher.name }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item v-if="item.ambience">
                        <v-list-item-avatar>
                          <v-icon v-if="item.ambience.type && item.ambience.type.is_virtual">mdi-monitor-dashboard</v-icon>
                          <v-icon v-else>mdi-home</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ item.ambience.name }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-pencil-ruler</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ item.work_load_type + '=' +  item.work_load_time_table }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item v-if="item.group_number != 0">
                        <v-list-item-avatar>
                          <v-icon>mdi-shield-half-full</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>D{{ item.group_number }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item v-if="item.teacher_number != 0">
                        <v-list-item-avatar>
                          <v-icon>mdi-school</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>P{{ item.teacher_number }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item v-if="item.join && item.join.grids">
                        <v-list-item-avatar>
                          <v-icon>mdi-call-split</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ getTextJoin(item.join) }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                      <v-list-item v-if="item.category">
                        <v-list-item-avatar>
                          <v-icon>mdi-grid</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ item.category.name }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>

                    </v-list>
                          
                  </template>
                  </emc-info-popover>
              
              </v-list-item-action>
              
                      
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <!-- Componente para Cadastrar Nova Grade -->
          <sys-register-grid
            :model="selectedGrid" 
            v-bind:show.sync="showRegisterGrid"
            @onCompleted="completedGrid"
          />

          <emc-loader-progress :show="progress" />

        </v-card>
</template>

<style>
 
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleList",
    data: () => ({
      selected: -1,
      showRegisterGrid: false,
      selectedGrid: {},
      gridChat: null,
      selectedGroup: null,
      progress: false,
      dialogChat: false,
    }),

    watch: {
      
      selected(selected_new, selected_old) {
        if(selected_new !== selected_old && !isNaN(selected_new)) {
            this.$emit('update:model', selected_new);
        }
      },

      model(model_new, model_old) {
        if(model_new !== model_old && !isNaN(model_new)) {
            this.selected = model_new;
        }
      },

      group(group_new, group_old){
        if((group_new && group_old) && (group_new.id != group_old.id)){
          this.selectedGroup = null;
        }
      },
      
    },
    props: {
        items: {
          type: Array,
          required: true
        },
        model: {
          type: Number,
          required: true
        },
        title: {
          type: String,
          default: "Disciplina"
        },
        colors: {
          type: Array,
          required: true
        },
        group: {
          type: Object,
          required: true
        },
        showActions: {
          type: Boolean,
          default: false
        },
        hours: {

        },
        showHeader: {
          type: Boolean,
          default: false
        }

    },
    
    methods: {
      
      ...mapActions('group', ['ActionFindGroupById']),
      
      openChat(item){
        this.gridChat = item;
        this.dialogChat = true;
      },
      
      getPendencies(grid){
          
          var count = 0;
          var total = 0;
          var pendencies = 0;

          grid.classes.forEach(s => {
            
            if(s.grid_id == grid.id && !s.start_date){
                
                let hour = this.hours.find(h => h.id == s.hour_id);
                if(hour){
                  count += hour.number_of_classes;
                }
               
            }
              
          });

          total = grid.work_load_time_table - count;

          if(total > 0){
              
              total = parseFloat(total).toFixed(1);
              const [integer, decimal] = parseFloat(total).toFixed(1).toString().split('.');

              if(decimal == 0){
                pendencies = integer;
              }else{
                pendencies = parseFloat(total).toFixed(1).toString();
              }
          }
         
          grid.pendencies = pendencies;
          return pendencies;

        },

        getFirstName(name) {
            
            var length = 10;
            var index = name.indexOf(' ');
            var text = name;

            if(index != -1){

                var parts = name.split(' ');

                if(parts.length > 1 && (parts[0].toUpperCase() == 'A' || parts[0].toUpperCase() == 'O')){
                    text = parts[1];
                }else{
                    text = parts[0];
                }
            }

            if(text.length > length)
                text = text.substring(0, length);

            return text;
        },

        getNameDiscipline(grid){
            
            var name = grid.discipline.name;

            if(!grid.work_load_type_default){
              name = grid.work_load_type + ':' + name;
            }

            var groupNumber = null;
            var teacherNumber = null;

            if(grid.group_number != 0){
                groupNumber = 'D' + grid.group_number;
            }
            
            if(grid.teacher_number != 0){
                teacherNumber = 'P' + grid.teacher_number;
            }

            if(groupNumber && teacherNumber){
              return groupNumber + '/' + teacherNumber + '-' + name;
            }else if(groupNumber){
              return groupNumber + '-' + name;
            }else if(teacherNumber){
              return teacherNumber + '-' + name;
            }else{
              return name;
            }
        },

        getTextJoin(join){

          if(join && join.grids){
              var text = '';
              join.grids.forEach(grid => {
                
                if(grid.group){
                  if(text.length > 0)
                      text += ' | ';

                  text += grid.group.name;
                }
              });

              return text;
          }else{
            return "";
          }

        },

        openDialogNewGrid(){

          if(!this.selectedGroup){
            this.loadGroup();
          }else{
            this.openDialogNewGrid2();
          }
        },

        openDialogNewGrid2(){
          
          this.selectedGrid = {
            group: this.selectedGroup,
            disableFields: true,
            group_id: this.selectedGroup.id
          };

          this.showRegisterGrid = true;
        },

        openDialogEditGrid(){

          if(this.selectedGrid){
            var newObject = {};
            Object.assign(newObject, this.items[this.selected]);
            Object.assign(newObject, { disableFields: true });
            this.selectedGrid = newObject;
            this.showRegisterGrid = true;
          }

        },

        loadGroup(){
          
          var payload = {
            id: this.group.id
          };

          this.progress = true;
          
          this.ActionFindGroupById(payload).then((res) => {
                
            if(res.success){
              this.selectedGroup = res.data;
              this.openDialogNewGrid2();
            }
          })
          .catch(error => {
          
          })
          .finally(() => {
            this.progress = false;
          });
        },

        completedGrid(val, newItem){
          
          this.showRegisterGrid = false;
          
          if(newItem){
            Object.assign(val, { color: this.colors[this.items.length]});
            this.items.push(val);
          }else{

            this.items[this.selected].teacher_id = val.teacher_id;
            this.items[this.selected].teacher = val.teacher;
            this.items[this.selected].work_load = val.work_load;

          }
        },

        drawer(){
         this.$emit('onDrawer');
      },
    }
        
}
</script>