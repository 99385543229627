import { service } from '../../../helpers/http'

export const ActionFindCountries = ({ dispatch }, payload) => {

    return service({
        module: 'country',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateCountry = ({ dispatch }, payload) => {
    
    return service({
        module: 'country',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateCountry = ({ dispatch }, payload) => {
    
    return service({
        module: 'country',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteCountry = ({ dispatch }, payload) => {
    
    return service({
        module: 'country',
        action: 'delete',
        payload: payload
    })
    
}

