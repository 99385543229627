import { service } from '../../../../helpers/http'

export const ActionIndexBigOccupationAreas = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'indexBigOccupationAreas', 
		payload: payload 
	})
}

export const ActionCreateBigOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'createBigOccupationArea', 
		payload: payload 
	})
}

export const ActionUpdateBigOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'updateBigOccupationArea', 
		payload: payload 
	})
}

export const ActionDestroyBigOccupationArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'occupationArea', 
		action: 'destroyBigOccupationArea', 
		payload: payload 
	})
}
