import { default as time } from './time/services'
import { default as allocation } from  './allocation/services'
import { default as offer } from  './offer/services'
import { default as attribution } from  './attribution/services'
import { default as attributionResult } from  './attribution_result/services'

export default {
    ...time,
    ...allocation,
    ...offer,
    ...attribution,
    ...attributionResult
}