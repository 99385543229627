<template>
    <div style="cursor: help" @click.stop.prevent="$emit('onOpen', model)">

        <v-badge
            v-if="model && model.has_message"
            class="ma-0"
            bordered
            top
            color="red"
            overlap
            dot
            >
            <v-icon
                large
                color="blue darken-2"
                small
                >
                mdi-email
                </v-icon>
            
        </v-badge>
        <v-icon
            v-else
            large
            color="grey"
            small
        >
            mdi-email
        </v-icon>

    </div>
</template>

<script>
export default {
    name: 'EmcMessageBox',
    data: () => ({
        
    }),
    props: {
        model: {
            
        },
    },
}
</script>