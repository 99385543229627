import { service } from '../../../helpers/http'

export const ActionFindContacts = ({ dispatch }, payload) => {

    return service({
        module: 'contact',
        action: 'contacts',
        payload: payload
    })

}

export const ActionDestroyContacts = ({ dispatch }, payload) => {
    return service({
        module: 'contact',
        action: 'destroy',
        payload: payload
    })
}

export const ActionUpdateContacts = ({ dispatch }, payload) => {
    return service({
        module: 'contact',
        action: 'update',
        payload: payload
    })
}

export const ActionFindStatusContacts = ({ dispatch }, payload) => {
    return service({
        module: 'contact',
        action: 'indexStatusContacts',
        payload: payload
    })
}