<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
                :color="color" 
                icon 
                v-bind="attrs"
                :disabled="disabled"
                v-on="on" 
                @click="$emit('click', $event)"
                :small="btnSmall"
                :loading="loading"
                :href="href"
                :target="target"
            ><v-icon :small="iconSmall">{{ icon }}</v-icon></v-btn>
        </template>
        <span> {{ text }} </span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'EmcButtonIcon',
    props: {
        color: {
            type: String,
            default: "indigo darken-1"
        },
        icon: {
            type: String,
            default: "mdi-plus"
        },
        btnSmall: {
            type: Boolean,
            default: false
        },
        iconSmall: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: "Novo Cadastro"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        href: {

        },
        target: {
            type: String,
            default: '_blank'
        }
    },
    emits: ['click']
}
</script>