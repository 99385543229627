<template>
    <v-card
        v-if="visible"
        class="mx-auto"
        max-width="300"
        @mousedown="movingCard = true"
        @mousemove="mouseMove"
        @mouseup="movingCard = false"
        @mouseleave="movingCard = false"
        tile
        :style="position"
        >
        <v-list shaped>
            <v-subheader>
                <span>{{ title }}</span>
                <v-btn
                    class="red--text"
                    icon
                >
                    <v-icon @click="visible = false">mdi-close</v-icon>
                </v-btn>
            </v-subheader>
            <v-list-item-group  v-model="selected" color="primary">
                <emc-util-scroll maxHeight="300px">
                    <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                        @click="$emit('on-select', item)"
                    >
                        <v-list-item-content v-if="item">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind="attrs" v-on="on" v-text="item.name"/>
                                </template>
                                <span>
                                    {{ item.campus.name }}
                                    <br/>
                                    {{ item.course.name }}
                                    <span v-if="item.time_course"><br/>Período: {{ item.time_course }}</span>
                                </span>
                        </v-tooltip>
                        </v-list-item-content>
                    </v-list-item>
                </emc-util-scroll>
            </v-list-item-group>
        </v-list>
        </v-card>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleItem",
    data: () => ({
       selected: null,
       visible: false,
       movingCard: false,
       posX: '950',
       posY: '100',
    }),
    computed: {
       position(){
           return 'position: fixed; top: ' + this.posY + 'px; left: ' + this.posX + 'px; z-index: 10;';
       }
    },
    watch: {
        selected(selected_new, selected_old) {
            if(selected_new !== selected_old) {
                this.$emit('onClick', selected_new);
            }
        },

        visible(visible_new, visible_old) {
            if(visible_new !== visible_old) {
                this.$emit('update:show', visible_new);
            }
        },

        show(show_new, show_old) {
           
            if(show_new !== show_old) {
                this.visible = show_new;
            }
        },
    },
    props: {
        title: {
            type: String,
            default: "Junções"
        },
        items: {
            type: Array,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        mouseMove(e){
            if(this.movingCard){
                //this.posX =  e.clientX;
                //this.posY = e.clientY;
            }
        },
    }
}
</script>