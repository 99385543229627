<template>
    <emc-card-stats
            icon="mdi-school-outline"
            color="blue lighten-1"
            sheet-width="50"
            sheet-heigth="50"
            icon-class="px-3 py-1"
            :subtitle="subtitle"
            sub-icon="mdi-clock-outline"
            :sub-text="limited"
            :tools="!hideFooter"
            :classSubTitle="classSubTitle"
          >

          <template slot="tools">

            <emc-button-icon v-if="!type" :loading="loading" icon="mdi-refresh-circle" text="Atualizar" color="success" @click.prevent.stop="refresh()" />
            <emc-button-icon v-if="showMoreButton" icon="mdi-monitor-eye" text="Ver Mais..." color="primary" @click.prevent.stop="$emit('showMoreAttributions', itemId)" />

              <span v-if="!hideTools">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo darken-1" icon small @click.prevent.stop="download(3)" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                  </template>
                  <span>Resumido</span>
              </v-tooltip>

              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-1" icon small @click.prevent.stop="download(19)" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                  </template>
                  <span>Detalhado</span>
              </v-tooltip>
              </span>
              

          </template>
          <template slot="body">
                <v-progress-circular
                          :rotate="360"
                          :size="140"
                          :width="15"
                          :value="indication && indication.percent ? indication.percent : 0"
                          color="blue lighten-1"
                        >
                        <template slot:default>
                          <span>
                            <span>{{ (indication && indication.percent) ? indication.percent : 0}}%</span><br/>
                            <span v-if="indication &&  indication.total">{{ indication.completed}} / {{ indication.total}}</span>
                          </span>
                        </template>
                      </v-progress-circular>
          </template>
          </emc-card-stats>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../mixins';

export default {
    name: 'EmcChartIndicationOfWorkTime',
    mixins: [myMixin],
    data () {
        return {
          loading: false,
          indication: {
            total: 0,
            percent: 0,
            completed: 0
          }
        }
    },
    methods: {
      ...mapActions('controlPanel', ['ActionFindIndicatorIndications', 'ActionIndicatorAttributionsRefresh']),
      
      getData(){
      
        
        this.indication = this.indicator;
        
        if(this.type == 'campus' || this.type == 'institution'){
          return;
        }
        
        var payload = {
            work_time_id: this.acesso.periodo.id,
        };

        if(this.type && this.itemId){
            payload.type        = this.type,
            payload.value       = this.itemId;
        }
        
        this.ActionFindIndicatorIndications(payload)
          .then((res) => {
              this.indication = res.data;
          })
          .finally(() => {
              this.loading = false;
            });
      
      },

      refresh(){
      
        this.loading = true;
        
        this.ActionIndicatorAttributionsRefresh()
            .then((res) => {
                
            })
            .finally(() => {
              setTimeout(() => {  
                this.getData();
              }, 6000);
            });
      },

    },

    created() {
      this.getData()
    },
    props: {
        hideTools: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        type: {
          type: String,
          default: null,
        },
        indicator: {
          default: null,
        },
        itemId: {
            type: Number,
            default: null
        },
        showMoreButton: {
            type: Boolean,
            default: false
        },
        subtitle: {
          type: String,
          default: 'Atribuição',
        },
        hideSubText: {
          type: Boolean,
          default: false
        },
        classSubTitle: {
          type: String,
          default: 'text-h5 font-weight-light grey--text'
        }
    },
    computed: {
      ...mapState('auth', ['acesso']),
      limited() {
        
        if(!this.hideSubText && this.acesso && this.acesso.periodo){
          const [year, month, day] = this.acesso.periodo.indication_end.split('-')
          return 'Liberado até ' + `${day}/${month}/${year}`;
        }else{
          return null;
        }
         
      }
    }
}
</script>