import { service } from '../../../helpers/http'

export const ActionSave = ({ dispatch }, payload) => {
    
    return service({
        module: 'account',
        action: 'register',
        payload: payload
    });
}

