export default [
    {
        path: '/brands',
        name: 'brands',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Marca',
            icon: '',
            breadcrumbs: [
                { text: 'Instituição', disabled: true, href: '/' }, 
                { text: 'Marca', disabled: true, href: '/' }, 
            ],
        }
    },
]