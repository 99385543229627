<template>
    <div>
        <EmcBaseProgressBar v-if="isLoading" />
            <v-treeview
                    ref="tree"
                    :active.sync="active"
                    :items="campuses"
                    :load-children="fetchItems"
                    :open.sync="open"
                    activatable
                    item-children="children"
                    color="warning"
                    :open-on-click="true"
                    transition
                    item-key="key"
                    return-object
                    @update:active="selectItem($event)"
                >
                    <template slot="label" slot-scope="{ item, active }">
                        
                        <span>{{ item.name }}</span>
                        
                        <!-- <v-progress-linear
                            v-if="item.level == 3 && item.loading && active"
                            color="deep-purple accent-4"
                            indeterminate
                            rounded
                            height="12"
                        >
                            <template v-slot:default>
                                Carregando horário...
                            </template>
                        </v-progress-linear> -->

                        

                    </template>
                </v-treeview>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'SysGridTreeView',
    data: function() {
        return {
            campuses: '',
            active: [],
            open: [],
            search: '',
            isLoading: false,
            itemActive: null,
        }
    },
    created() {
        this.getCampuses()
    },
    methods: {
        ...mapActions('hour', ['ActionGetShiftsFilter']),
        ...mapActions('group', ['ActionFindGroupById']),
        ...mapActions('grid', ['ActionFindGroupsHourDate', 'ActionFindShiftsHourDate' , 'ActionFindGrids']),
        ...mapActions('campus', ['ActionFindCampusesFilterGroup']),
        async fetchItems (item) {        
            switch (item.level) {
                case 0:
                    return await this.getShifts(item);
                case 1:
                    return await this.getGroups(item);
                case 2: 
                    return await this.getGrids(item)
            }
        },
        getCampuses(){
            this.campuses = [];
            this.isLoading = true

            this.ActionFindCampusesFilterGroup()
                .then((res) => {
                    res.data.forEach(campus => {
                        this.campuses.push({
                            id: campus.id,
                            key: campus.id,
                            level: 0,
                            name: campus.name,
                            children: []
                        })
                    });
                })
                .finally(() => {
                    this.isLoading = false
                });
        },
        async getGrids(group) {
            
            var payload = {
                id: group.id,
                with: 'grids.workLoadType'
            };

            return this.ActionFindGroupById(payload)
                .then((res) => {
                    
                    group.grids = res.data.grids

                    res.data.grids.forEach( grid => {
                        group.children.push({
                            id: grid.id, 
                            key: group.id + '|' + grid.id, 
                            level: 3, 
                            name: grid.discipline_name + ' (' + grid.work_load_type.initials + ')',
                            grid: grid,
                            group: group,
                            loading: true,
                        })
                    });

                    this.laodGrids(group);
                })
        },

        laodGrids(group){
            
            var payload = {
                find_columns: { group_id: group.id },
                with: 'group,periodicity_type,workLoadType,classes.hour,classes.dayOfWeek',
                withDays: true,
            };

            return this.ActionFindGrids(payload)
                .then((res) => {
                    
                    group.grids = res.data;
                    
                    group.children.forEach(chield => {
                        res.data.forEach(grid => {
                            if(chield.id == grid.id){
                                chield.grid = grid;
                                chield.group.grids = res.data;
                                chield.loading = false;
                            }
                        });
                    });
                   
                }).finally(() => {
                    if(!this.itemActive && this.itemActive[0].loading){
                        this.selectItem(this.itemActive);
                    }
                });
        },

        async getShifts(campus) {  
            var payload = {
                campus_id: campus.id,
            };
            return this.ActionFindShiftsHourDate(payload)
                .then((res) => {
                    res.data.forEach(shift => {
                        campus.children.push({
                            id: shift.id,
                            campus_id: campus.id,
                            key: campus.key + '|' + shift.id,
                            level: 1,
                            name: shift.name,
                            children: []
                        });
                    });
            })
        },
        async getGroups(shift){

            const payload = {
                campus_id: shift.campus_id,
                shift_id: shift.id
            }

            return this.ActionFindGroupsHourDate(payload)
                .then((res) => {
                    
                    res.data.forEach(group => {
                        
                        shift.children.push({
                            id: group.id,
                            key: shift.key + '|' + group.id,
                            level: 2,
                            name: group.name,
                            children: [],
                            
                        });
                        
                    })
                    
                });

        },

        selectItem(item){
            
            if(item && item.length > 0){
                this.$emit('selected', item[0]);
                this.itemActive = item;
            }
            
            
            
            // if(!item[0].loading){
            //     this.itemActive = null;
            //     this.$emit('selected', item[0]);
            // }else{
            //     this.itemActive = item;
            // }

            // item[0].loading = false;
            
        },
            
            
        

    }
}
</script>