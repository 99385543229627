import { service } from '../../../helpers/http'
export * from '../vacancy_control/store/actions'
export * from '../allocation/store/actions'

export const ActionFindAllocations = ({ dispatch }, payload) => {
    return service({
        module: 'roomAllocation',
        action: 'find',
        payload: payload
    })
}

export const ActionCreateAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteAllocation = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'delete',
        payload: payload
    })
    
}

export const ActionDeleteAllocationAll = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'deleteAll',
        payload: payload
    })
    
}

export const ActionUpdateAmbienceLink = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'updateLink',
        payload: payload
    })
    
}

export const ActionDeleteAmbienceLink = ({ dispatch }, payload) => {
    
    return service({
        module: 'roomAllocation',
        action: 'deleteLink',
        payload: payload
    })
    
}

export const ActionFindClassesOptimized = ({ dispatch }, payload) => {
    return service({
        module: 'roomAllocation',
        action: 'findClassesOptimized',
        payload: payload
    })
}

export const ActionIndexAmbiencesEmpty = ({ dispatch }, payload) => {
    return service({
        module: 'roomAllocation',
        action: 'ambiencesFindEmpty',
        payload: payload
    })
}

export const ActionIndexAmbiencesUsed = ({ dispatch }, payload) => {
    return service({
        module: 'roomAllocation',
        action: 'ambiencesFindUsed',
        payload: payload
    })
}


