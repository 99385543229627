import { default as download } from  './download/services'
import { default as userImport } from  './import/services'
import { default as setting } from  './setting/services'
import { default as photo } from  './photo/services'

export default {
    ...download,
    ...userImport,
    ...setting,
    ...photo,
    findUsers:      { method: 'get',    url: 'users/all' },
    deleteUser:     { method: 'delete', url: 'users/{id}'},
    updateUser:     { method: 'put',    url: 'users/{id}'},
    createUser:     { method: 'post',   url: 'users'},
    updateProfile:  { method: 'put',    url: 'users/profile/{id}' },
    uploadAvatar:   { method: 'post',   url: 'users/upload/avatar' },
    index:          { method: 'get',    url: 'users' },
    myTicketsIndex: { method: 'get',    url: 'emcadmin/my-tickets'}
}