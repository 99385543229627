export default [
    {
        path: '/managements/ticket/categories',
        name: 'ticket-categories',
        component: () => import ('./pages/category/CategoryIndex'),
        meta: {
            title: 'Categoria dos tickets',
            icon: 'mdi-buffer',
            breadcrumbs: [
                { text: 'Lista das categorias dos tickets', disabled: true }
            ]
        }
    },
    {
        path: '/managements/ticket/statuses',
        name: 'ticket-statuses',
        component: () => import ('./pages/status/StatusIndex'),
        meta: {
            title: 'Status dos tickets',
            icon: 'mdi-alert-circle',
            breadcrumbs: [
                { text: 'Lista dos status dos tickets', disabled: true }
            ]
        }
    },
    {
        path: '/managements/ticket/types',
        name: 'ticket-types',
        component: () => import ('./pages/type/TypeIndex'),
        meta: {
            title: 'Tipos dos tickets',
            icon: 'mdi-cube-outline',
            breadcrumbs: [
                { text: 'Lista dos tipos dos tickets', disabled: true }
            ]
        }
    },
    {
        path: '/managements/tickets',
        name: 'tickets',
        component: () => import ('./pages/ticket/TicketItems'),
        meta: {
            title: 'Lista dos chamados',
            icon: 'mdi-ticket-outline',
            breadcrumbs: [
                { text: 'Lista dos chamados', disabled: true }
            ]
        }
    }
]