export default [
    {
        path: '/grid-modalities',
        name: 'grid-modalities',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Modalidade de Ofertas',
            icon: 'mdi-grid',
            breadcrumbs: [
                { text: 'Lista de Modalidade de Ofertas', disabled: true }
            ]
        }
    }
]