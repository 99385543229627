import { service } from '../../../../helpers/http'

export const ActionFindOccurrenceTypes = ({ dispatch }, payload) => {

    return service({
        module: 'timeSheet',
        action: 'findOccurrenceTypes',
        payload: payload
    })
    
}

export const ActionCreateOccurrenceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'createOccurrenceType',
        payload: payload
    })
    
}

export const ActionUpdateOccurrenceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'updateOccurrenceType',
        payload: payload
    })
}

export const ActionDeleteOccurrenceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'deleteOccurrenceType',
        payload: payload
    })
    
}

export const ActionFindOccurrenceStatus = ({ dispatch }, payload) => {

    return service({
        module: 'timeSheet',
        action: 'findStatusOccurrenceType',
        payload: payload
    })
    
}

export const ActionFindOccurrenceNatures = ({ dispatch }, payload) => {

    return service({
        module: 'timeSheet',
        action: 'findNaturesOccurrenceType',
        payload: payload
    })
    
}

