import { default as occurrence } from './occurrence/services'
import { default as occurrence_sheet } from  './occurrence_sheet/services'
import { default as occurrence_sheet_item } from  './occurrence_sheet_item/services'
import { default as occurrence_type } from  './occurrence_type/services'
import { default as print } from  './print/services'
import { default as auth } from  './auth/services'
import { default as clocking } from  './clocking/services'

export default {
    ...occurrence,
    ...occurrence_sheet,
    ...occurrence_sheet_item,
    ...occurrence_type,
    ...print,
    ...auth,
    ...clocking,
}