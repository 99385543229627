<template>
  <div>
    <sys-select-base
        :model.sync="selected"
        :items="items"
        :loading="loading"
        :label="label"
        :error="error"
        :errors="errors"
    />
  </div>
</template>

<script>
export default {
  name: 'SysSelectEnvironment',
  data: () => ({
    items: [
      { id: 'production', name: 'Produção' },
      { id: 'staging', name: 'Teste' },
      { id: 'local', name: 'Desenvolvimento'}
    ],
    loading: false,
    selected: null,
    showForm: false
  }),
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
    },
    label: {
      type: String,
      required: true
    },
    model: {},
  },
  created() {
    this.selected = this.model
  },
  watch: {
    selected(val){
      this.$emit('update:model', val);
    },
    model(val){
      this.selected = val;
    },
  }

}
</script>