export default [
    {
        path: '/workLoadTypes',
        name: 'workLoadTypes',
        component: () => import ('./pages/WorkLoadTypeRegister'),
        meta: {
            title: 'Tipo de Carga Horária',
            icon: 'mdi-pencil-ruler',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Tipo de carga horária', disabled: true, href: '/' },
            ],
        }
    },
]