import { default as unsanitary_types } from './unsanitary_type/services'

export default {
    ...unsanitary_types,
    disciplines:            { method: 'get',    url: 'disciplines' },
    disciplinesToken:       { method: 'get',    url: 'disciplines/token/{token}' },
    disciplineCreate:       { method: 'post',   url: 'disciplines' },
    disciplineUpdate:       { method: 'put',    url: 'disciplines/{id}' },
    disciplineDelete:       { method: 'delete', url: 'disciplines/{id}' },
}

