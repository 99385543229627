import { service } from '../../../../helpers/http'

export const ActionIndexUnsanitaryTypes = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'indexUnsanitaryType',
        payload: payload
    })
}

export const ActionCreateUnsanitaryType = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'createUnsanitaryType',
        payload: payload
    })
}

export const ActionUpdateUnsanitaryType = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'updateUnsanitaryType',
        payload: payload
    })
}

export const ActionDeleteUnsanitaryType = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'deleteUnsanitaryType',
        payload: payload
    })
}