import { service } from '../../../helpers/http'

export const ActionFindDisciplineTypes = ({ dispatch }, payload) => {
    return service({
        module: 'disciplineType',
        action: 'disciplineTypes',
        payload: payload
    })
}

export const ActionCreateDisciplineType = ({ dispatch }, payload) => {
    return service({
        module: 'disciplineType',
        action: 'createDisciplineType',
        payload: payload
    })
}

export const ActionUpdateDisciplineType = ({ dispatch }, payload) => {
    return service({
        module: 'disciplineType',
        action: 'updateDisciplineType',
        payload: payload
    })
}

export const ActionDeleteDisciplineType = ({ dispatch }, payload) => {
    return service({
        module: 'disciplineType',
        action: 'deleteDisciplineType',
        payload: payload
    })
}
