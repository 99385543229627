export default [
    {
        path: '/unsanitary-types',
        name: 'unsanitary-types',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Tipos de Insalubridade',
            icon: '',
            breadcrumbs: [
                { text: 'Turmas', disabled: true, href: '/' },
                { text: 'Tipos Insalubridade', disabled: true, href: '/' },
            ],
        }
    },
]