import { service } from '../../../../helpers/http'

export const ActionDestroyIntegrationHour = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyIntegrationHour',
        payload: payload
    })
}
export const ActionStoreIntegrationHour = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeIntegrationHour',
        payload: payload
    })

}
export const ActionUpdateIntegrationHour = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateIntegrationHour',
        payload: payload
    })
}

export const ActionRunIntegration = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'runIntegrationProcess',
        payload: payload,
    })
}

export const ActionIntegrationProcess = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationProcess',
        idAndPayload: true,
        payload: payload
    })
}

export const ActionIntegrationProcessFind = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'findIntegrationProcess',
        payload: payload
    })
}

export const ActionIntegrationProcessGetResponse = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'getReponseProcess',
        payload: payload
    })
}