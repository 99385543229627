import { service } from '../../../helpers/http'

export const ActionFindTeacherLicenses = ({ dispatch }, payload) => {
    return service({
        module: 'teacherLicense',
        action: 'teacherLicenses',
        payload: payload
    })
}

export const ActionCreateTeacherLicense = ({ dispatch }, payload) => {
    return service({
        module: 'teacherLicense',
        action: 'createTeacherLicense',
        payload: payload
    })
}

export const ActionUpdateTeacherLicense = ({ dispatch }, payload) => {
    return service({
        module: 'teacherLicense',
        action: 'updateTeacherLicense',
        payload: payload
    })
}

export const ActionDeleteTeacherLicense = ({ dispatch }, payload) => {
    return service({
        module: 'teacherLicense',
        action: 'deleteTeacherLicense',
        payload: payload
    })
}