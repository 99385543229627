export default [
    {
        path: '/plans',
        name: 'plans',
        component: () => import ('./pages/PlanIndex'),
        meta: {
            title: 'Planos',
            icon: 'mdi-animation',
            breadcrumbs: [
                { text: 'Lista de planos', disabled: true }
            ]
        }
    }
]