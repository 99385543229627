export default [
    {
        path: '/countries',
        name: 'countries',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Países',
            icon: '',
            breadcrumbs: [
                { text: 'Municípios', disabled: true, href: '/' },
                { text: 'Países', disabled: true, href: '/' },
            ],
        }
    },
]