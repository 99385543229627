var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.renderComponent)?_c('v-data-table',{attrs:{"headers":_vm.headersFiltered,"items":_vm.model.timeTables,"items-per-page":-1,"item-key":"index","hide-default-footer":"","fixed-header":"","height":"300"},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.day_of_week_id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-select',{attrs:{"item-value":"id","item-text":"name","items":_vm.days.filter(function (d) { return d.visible; }),"error":!!_vm.errors.day_of_week_id,"error-messages":_vm.errors['timeTables.' + index + '.day_of_week_id'],"label":"","dense":""},model:{value:(item.day_of_week_id),callback:function ($$v) {_vm.$set(item, "day_of_week_id", $$v)},expression:"item.day_of_week_id"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-date-picker2',{attrs:{"model":item.date,"label":"","errorMessages":_vm.errors['timeTables.' + index + '.date'],"show":_vm.dialog,"min":_vm.acesso.periodo.start,"max":_vm.acesso.periodo.end,"dense":""},on:{"update:model":function($event){return _vm.$set(item, "date", $event)}}})]}},{key:"item.hour_start",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-time-picker',{attrs:{"dense":"","error":!!_vm.errors.hour_start,"errors":_vm.errors['timeTables.' + index + '.hour_start'],"time":item.hour_start,"label":""},on:{"update:time":function($event){return _vm.$set(item, "hour_start", $event)}}})]}},{key:"item.hour_end",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('emc-calendar-time-picker',{attrs:{"dense":"","error":!!_vm.errors.hour_end,"errors":_vm.errors['timeTables.' + index + '.hour_end'],"time":item.hour_end,"label":""},on:{"update:time":function($event){return _vm.$set(item, "hour_end", $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"loading":_vm.loading && _vm.indexDelete == index,"disabled":_vm.loading,"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item, index)}}},[_c('v-icon',{attrs:{"small":"","color":"error","dense":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"footer",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-switch',{staticClass:"ml-4",attrs:{"inset":"","label":"Horário por Data"},on:{"change":function($event){return _vm.forceRerender()}},model:{value:(_vm.model.by_date),callback:function ($$v) {_vm.$set(_vm.model, "by_date", $$v)},expression:"model.by_date"}})],1)])]},proxy:true}],null,false,1971690498)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }