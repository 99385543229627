<template>

    <v-card class="mt-6 mb-4 mx-auto card-title--chart" max-width="400">
        <v-sheet
            class="v-sheet--offset mx-auto pa-2"
            :color="color"
            elevation="6"
            max-width="calc(100% - 32px)"
            rounded
            dark
        >
            <div v-if="title" class="text-h6">
                {{ title }}
            </div>
            <slot name="card-title"></slot>
        </v-sheet>

        <v-card-text class="pt-0">
            <slot name="card-text"></slot>
        </v-card-text>
        </v-card>
    
</template>

<script>
export default {
    name: "EmcBaseCardChart",
    props: {
        title: {
            type: String,
            required: false
        },
        color: {
            type: String,
            default: "cyan"
        }
    }
}
</script>


<style>
  .card-title--chart .v-sheet--offset {
    top: -24px;
    position: relative;
  }
</style>