<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-grid" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        
        <template v-slot:tools>

            <v-tooltip v-if="tab == 1 && model.id" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :disabled="disableSaveDisciplines" :loading="loadingSaveDisciplines" color="success darken-1" icon @click.prevent.stop="updateDisciplines()" v-bind="attrs" v-on="on"><v-icon>mdi-content-save-edit-outline</v-icon></v-btn>
                </template>
                <span> Salvar alterações </span>
            </v-tooltip>

        </template>

        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados</v-tab>
                <v-tab key="disciplines">Disciplinas</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" >
                <v-tab-item key="main">
                    <v-container>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <sys-select-course
                                    :model.sync="model.course_id"
                                    :error="errors.course_id && errors.length > 0"  
                                    :errors="errors.course_id"
                                    label="Curso*"
                                    autoComplete
                                />
                        </v-col>
                         <v-col cols="12" sm="4">
                            <sys-select-curriculum-category
                                :model.sync="model.curriculum_category_id"
                                :error="errors.length > 0"  
                                :errors="errors.curriculum_category_id"
                                label="Categoria*"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <v-text-field dense autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome/Sigla*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-frequency
                                :model.sync="model.frequency_id"
                                :error="errors.length > 0"  
                                :errors="errors.frequency_id"
                                label="Periodicidade*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field 
                                dense 
                                :error="!!errors.number_modules" 
                                :error-messages="errors.number_modules" 
                                v-model="model.number_modules" 
                                label="Períodos*"
                            >
                                 <template v-slot:append>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :disabled="model.number_modules < 1 || model.number_modules > 24" v-bind="attrs" v-on="on" small icon @click.prevent.stop="loadSequencies()">
                                                <v-icon btn color="green darken-1">mdi-flash</v-icon>
                                            </v-btn>
                                        </template>
                                            <span>
                                                Carregar Períodos
                                            </span>
                                    </v-tooltip>
                                </template>
                            
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-campus
                                :model.sync="model.campuses"
                                :error="errors.length > 0"  
                                :errors="errors.campuses"
                                label="Campus"
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-curriculum-status
                                :model.sync="model.curriculum_status_id"
                                :error="errors.length > 0"  
                                :errors="errors.curriculum_status_id"
                                label="Status*"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-curriculum-regime
                                :model.sync="model.curriculum_regime_id"
                                :error="errors.length > 0"  
                                :errors="errors.curriculum_regime_id"
                                label="Regime*"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-text-field dense :error="!!errors.year" :error-messages="errors.year" v-model="model.year" label="Ano Ingresso"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense :error="!!errors.period" :error-messages="errors.period" v-model="model.period" label="Semestre Ingresso"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense :error="!!errors.workload" :error-messages="errors.workload" v-model="model.workload" label="Carga Horária"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.description" 
                                :error-messages="errors.description" 
                                v-model="model.description" 
                                label="Descrição"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                            />
                        </v-col>
                    </v-row>
                </v-container>
                </v-tab-item>
                <v-tab-item key="disciplines">
                    <v-container>
                     <v-row>
                         <v-col cols="12" sm="12">
                                <v-expansion-panels  
                                    v-if="renderComponent"
                                    v-model="panel" focusable
                                    prominent
                                    class="mx-auto elevation-0"
                                    >
                                    <emc-util-scroll maxHeight="400px">
                                    <v-expansion-panel
                                        v-for="sequency in model.sequencies"
                                        :key="sequency.curriculum_sequency_id"
                                    >
                                    
                                    <v-expansion-panel-header> 
                                        
                                        <div>
                                            <v-badge
                                                v-if="sequency.sequencyDisciplines.length"
                                                color="blue lighten-2"
                                                :content="sequency.sequencyDisciplines.length"
                                                small
                                            >
                                                <strong>{{ sequency.sequency.id == 99 ? sequency.sequency.name : sequency.sequency.id }}</strong>
                                            </v-badge>
                                            <strong v-else class="grey--text">{{ sequency.sequency.id == 99 ? sequency.sequency.name : sequency.sequency.id }}</strong>
                                        </div>
                                        
                                        <!-- <v-spacer></v-spacer> -->
                                        {{ (sequency.initials ? sequency.initials : '') + (sequency.description ? ' - ' + sequency.description : '') }}
                                        <v-spacer></v-spacer>
                                        <!-- {{ sequency.disciplines.length }} Disciplina(s) -->
                                    </v-expansion-panel-header>
                                    
                                    <v-expansion-panel-content>
                                        
                                         <v-row class="ma-0">
                                            <v-col cols="12" sm="12">
                                                <v-data-table
                                                    :headers="headers"
                                                    :items="sequency.sequencyDisciplines"
                                                    sort-by="name"
                                                    class="elevation-1"
                                                    hide-default-footer
                                                >
                                                    <template v-slot:top>
                                                        
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn 
                                                                    color="indigo darken-1" 
                                                                    @click.prevent.stop="addItem(sequency, false)" 
                                                                    icon
                                                                    v-bind="attrs"
                                                                    v-on="on" 
                                                                >
                                                                    <v-icon>mdi-plus</v-icon>
                                                                </v-btn>
                                                                 </template>
                                                            <span> Incluir disciplina </span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-slot:item.discipline="{ item }">
                                                        {{ item.discipline.name_complete }}
                                                        <small v-if="item.discipline.code"><br/>[{{ item.discipline.code }}]</small>
                                                    </template>

                                                    <template v-slot:item.grid_category_id="{ item }">
                                                        <sys-select-grid-category
                                                            :model.sync="item.grid_category_id"
                                                            :error="errors.length > 0"  
                                                            :errors="errors.grid_category_id"
                                                            itemText="initials"
                                                            showClear
                                                            label=""
                                                            @input="disableSaveDisciplines = false"
                                                        />
                                                    </template>

                                                    <template v-slot:item.actions="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    @click="showDeleteItem(sequency, item)"
                                                                    color="red"
                                                                    v-bind="attrs"
                                                                    v-on="on"  
                                                                >
                                                                    mdi-delete
                                                                </v-icon>
                                                            </template>
                                                            <span> Excluir Disciplina </span>
                                                        </v-tooltip>

                                                    </template>
                                                
                                                </v-data-table>
                                            </v-col>
                                    </v-row>
                                     <v-row v-if="sequency.sequency.id != 99">
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="sequency.initials"
                                                placeholder="Módulo"
                                                @input="disableSaveDisciplines = false"
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="8">
                                            <v-text-field
                                                v-model="sequency.description"
                                                placeholder="Descrição"
                                                @input="disableSaveDisciplines = false"
                                            />
                                            
                                        </v-col>
                                        </v-row>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </emc-util-scroll>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                </v-container>
                </v-tab-item>
            </v-tabs-items>
           
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

            <sys-find-discipline 
                showAdd 
                showSelect
                :singleSelect="false" 
                @onSelect="selectDiscipline($event)" 
                :show.sync="dialogAddDiscipline"
            />

            <emc-alert-modal text="Deseja excluir a disciplina?" v-bind:show.sync="showDelete">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="deleteItem()" >Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                </template>
            </emc-alert-modal>

        </template>
    </sys-register-base>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterCurriculum",
    data: (vm) => ({
        dialog: false,
        tab: 0,
        renderComponent: true,
        panel: null,
        dialogAddDiscipline: false,
        showDelete: false,
        selectedSequency: null,
        selectedItem: null,
        sequencies: [],
        disableSaveDisciplines: true,
        loadingSaveDisciplines: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        headers: [
            { text: 'Disciplina', value: 'discipline', sortable: false, width: '78%'},
            { text: 'Categoria', value: 'grid_category_id', sortable: false, width: '20%'},
            { text: 'Ações', value: 'actions', sortable: false, width: '2%'}
    ]
        
    }),
    created(){
       this.getSequencies();
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.panel = null;
                this.tab = 0;
                this.disableSaveDisciplines = true;

                if(!this.model.id){
                    this.model.curriculum_status_id = 3;
                    this.model.sequencies = [];
                }
               
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Matriz Curricular"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('curriculum', ['ActionUpdateCurriculum', 'ActionUpdateCurriculumDisciplines', 'ActionCreateCurriculum', 'ActionFindCurriculumSequencies']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {
                institution_id: this.acesso.instituicao.id,
                changeDisciplines: !this.disableSaveDisciplines
            });

            if(edit) {
                this.ActionUpdateCurriculum(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                     this.disableSaveDisciplines = true;
                })
            } else {
                this.ActionCreateCurriculum(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        updateDisciplines()
        {
            this.error = false;
            this.message = '';
            this.loadingSaveDisciplines = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            
                this.ActionUpdateCurriculumDisciplines(this.model)
                .then((res) => {
                    this.message = res.message;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loadingSaveDisciplines = false;
                     this.disableSaveDisciplines = true;
                })
        },

        getSequencies() {

            this.ActionFindCurriculumSequencies()
                .then((res) => {
                    this.sequencies = res.data;
                })
                .finally(() => {
                    
                });
        },

        addItem(item){
            
            this.selectedSequency = item;
            this.dialogAddDiscipline = true;
            

        },

        selectDiscipline(items){ 
            
            if(this.selectedSequency && this.selectedSequency.sequencyDisciplines){
                
                items.forEach(discipline => {
                    var find = this.selectedSequency.sequencyDisciplines.find(i => i.discipline_id == discipline.id);

                    if(!find){
                            this.selectedSequency.sequencyDisciplines.push(
                                { 
                                    discipline_id:      discipline.id,
                                    grid_category_id:   null,
                                    discipline:         { id: discipline.id, code: discipline.code, name: discipline.name, name_complete: discipline.name_complete, work_load: discipline.work_load },
                                    category:           null,
                                    
                                }
                            );
                    }
                });

                this.dialogAddDiscipline = false;
                this.disableSaveDisciplines = false;
            }

            this.forceRerender();
        },

        showDeleteItem(sequency, item){

            this.selectedSequency = sequency;
            this.selectedItem = item;
            this.showDelete = true;

        },

        deleteItem(){
            if(this.selectedSequency && this.selectedItem){
                var index = this.selectedSequency.sequencyDisciplines.indexOf(this.selectedItem);
                this.selectedSequency.sequencyDisciplines.splice(index, 1);
                this.showDelete = false;
                this.disableSaveDisciplines = false;
            }
        },

        loadSequencies(){

            var array = [];
            var atividades = null;

            this.model.sequencies.forEach(el => {
                
                if(el.curriculum_sequency_id != 99){
                    
                    if(el.curriculum_sequency_id <= this.model.number_modules){
                        array.push(el);
                    }
                }else{
                    atividades = el;
                }

            });

            this.sequencies.forEach(s => {

                var find = null;

                if(this.model.sequencies.length > 0){
                    find = this.model.sequencies.find(i => i.curriculum_sequency_id == s.id);
                }
                
                if(!find && s.id <= this.model.number_modules){
                    
                    var sequency = {
                        curriculum_id:          this.model.id ? this.model.id : 0,
                        curriculum_sequency_id: s.id,
                        initials: '',
                        description: '',
                        sequencyDisciplines: [],
                        sequency: {
                            id: s.id,
                            name: s.name
                        }
                    }

                    if(s.id != 99){
                        array.push(sequency);
                    }else{
                        if(!atividades){
                            atividades = sequency;
                        }
                    }
                }

            });

            if(atividades){
                array.push(atividades);
            }else{
                
                var atividade = this.sequencies.find(i => i.id == 99);

                var sequency = {
                    curriculum_id:          this.model.id ? this.model.id : 0,
                    curriculum_sequency_id: atividade.id,
                    initials: '',
                    description: '',
                    sequencyDisciplines: [],
                    sequency: {
                        id:     atividade.id,
                        name:   atividade.name
                    }
                }

                array.push(sequency);
            }

            array.sort((a,b) => (a.curriculum_sequency_id > b.curriculum_sequency_id) ? 1 : ((b.curriculum_sequency_id > a.curriculum_sequency_id) ? -1 : 0));

            if(array.length != this.model.sequencies.length){
                this.model.sequencies = array;
                this.forceRerender();
            }

        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
      },
      
    }
}
</script>