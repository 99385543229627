import { service } from '../../../helpers/http'

export const ActionFindBankData = ({ dispatch }, payload) => {

    return service({
        module: 'bankData',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateBankData = ({ dispatch }, payload) => {
    
    return service({
        module: 'bankData',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateBankData = ({ dispatch }, payload) => {
    
    return service({
        module: 'bankData',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteBankData = ({ dispatch }, payload) => {
    
    return service({
        module: 'bankData',
        action: 'delete',
        payload: payload
    })
    
}

export const ActionFindAccountTypes = ({ dispatch }, payload) => {

    return service({
        module: 'bankData',
        action: 'findAccountType',
        payload: payload
    })
    
}

export const ActionFindPixTypes = ({ dispatch }, payload) => {

    return service({
        module: 'bankData',
        action: 'findPixType',
        payload: payload
    })
    
}

