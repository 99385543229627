import { service } from '../../../helpers/http'
export * from '../settting/store/actions'
export * from '../event_public/store/actions'
export * from '../area/store/actions'

export const ActionSearchCalendars = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'calendars',
        payload: payload
    })
    
}

export const ActionCreateCalendar = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'calendarCreate',
        payload: payload
    })
    
}

export const ActionUpdateCalendar = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'calendarUpdate',
        payload: payload
    })
    
}

export const ActionDeleteCalendar = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'calendarDelete',
        payload: payload
    })
    
}

export const ActionSearchEventTypes = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'eventTypes',
        payload: payload
    })
    
}

export const ActionSearchScopes = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'scopes',
        payload: payload
    })
    
}

export const ActionIndexCalendarHolidays = ({ dispatch }, payload) => {

    return service({
        module: 'calendar',
        action: 'calendarHolidays',
        payload: payload
    })
    
}