export default [
    {
        path: '/register-roles',
        name: 'register-roles',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Cadastro de Perfis',
            icon: 'mdi-shield-account',
            breadcrumbs: [
                { text: 'Lista de Perfis', disabled: true }
            ]
        }
    }
]