var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sys-register-base',{attrs:{"loading":_vm.loadingSave,"model":_vm.model,"title":_vm.title,"icon":_vm.icon,"show":_vm.dialog,"maxWidth":"50%"},on:{"onSave":_vm.save,"update:model":function($event){_vm.model=$event},"update:show":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('emc-loader-progress',{attrs:{"show":_vm.loading}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.grids,"hide-default-footer":"","items-per-page":-1,"item-key":"index","fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.model || !_vm.model.id,"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.teacher_number",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"cursor":"help"},attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","color":"amber","btn":""}},[_vm._v("mdi-star-half-full")])],1)]}}],null,true)},[_c('span',[_vm._v(" Professor Principal ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"cursor":"help"},attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","color":"primary","btn":""}},[_vm._v("mdi-dots-horizontal-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Professor Secundário ")])])]}},{key:"item.work_load",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{staticClass:"centered-input text--darken-3 mt-3",attrs:{"error":_vm.errors.length > 0,"error-messages":_vm.errors['items.' + index + '.work_load'],"dense":""},model:{value:(item.work_load),callback:function ($$v) {_vm.$set(item, "work_load", $$v)},expression:"item.work_load"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","disabled":!index},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showDeleteItem(item, index)}}},[_c('v-icon',{attrs:{"small":"","color":"error","dense":""}},[_vm._v(" mdi-delete ")])],1)]}}])})],1),_c('emc-alert-modal',{attrs:{"text":"Confirma e exclusão?","disabled":_vm.loadingDelete,"show":_vm.showDelete},on:{"update:show":function($event){_vm.showDelete=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.loadingDelete,"disabled":_vm.loadingDelete},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }