<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          color="grey"
          small
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Configurações</v-list-item-title>
              <v-list-item-subtitle>Instituição</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                small
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>

          <emc-util-scroll maxHeight="350px">

            <v-list-item>
              
              <v-textarea
                  v-model="model.text_availability"
                  outlined
                  name="input-7-4"
                  label="Texto da Disponibilidade Semanal"
                  :value="model.text_availability"
                  :error="errors.length > 0"  
                  :errors="errors.text_availability"
                  clearable
                  auto-grow
                  rows="1"
                  
              />

            </v-list-item>
            <v-list-item>

              <v-textarea
                  v-model="model.text_availability_date"
                  outlined
                  name="input-7-4"
                  label="Texto da Disponibilidade Data"
                  :value="model.text_availability_date"
                  :error="errors.length > 0"  
                  :errors="errors.text_availability_date"
                  clearable
                  auto-grow
                  rows="1"
              />
            
            </v-list-item>

            <v-list-item>

              <v-textarea
                  v-model="model.text_interest"
                  outlined
                  name="input-7-4"
                  label="Texto Interesse Disciplinas"
                  :value="model.text_interest"
                  :error="errors.length > 0"  
                  :errors="errors.text_interest"
                  clearable
                  auto-grow
                  rows="1"
              />
            
            </v-list-item>
            <v-list-item>

              <v-textarea
                  v-model="model.text_confirmation"
                  outlined
                  name="input-7-4"
                  label="Texto Confirmação de CH"
                  :value="model.text_confirmation"
                  :error="errors.length > 0"  
                  :errors="errors.text_confirmation"
                  clearable
                  auto-grow
                  rows="1"
              />
            
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.is_confirm_email" label="Enviar email de confirmação para professor"></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.show_update_data" label="Exibir Atualização Cadastral"/> 
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.clear_classes_attribution" label="Derrubar horário ao trocar professor"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch inset text-helper="Validar curso do usário na Atribuição de Professores" v-model="model.settings.validate_courses" label="Validar Cursos"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch inset text-helper="Validar disponibilidade e choque de horário na Atribuição de Professores" v-model="model.settings.validate_time_table_attribution" label="Validar Choque de Horário"/> 
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.enable_activity_time" label="Habilitar Hora Atividade"/> 
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.calculate_variation" label="Calcular Resilição"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch inset text-helper="Exibir opção de atribuição temporária na tela de Professores das Turmas" v-model="model.settings.show_attribution_temp" label="Atribuição Eventual"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch inset text-helper="Exibir opção de carga horária paga na tela de Professores das Turmas" v-model="model.settings.show_attribution_pay" label="Atribuição Paga/Não Paga"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <v-switch inset v-model="model.settings.allow_replicate_teacher_join" label="Permitir configuração de replicar junção"/> 
            </v-list-item>
            <v-list-item v-if="model.settings && model.settings.allow_replicate_teacher_join">
              <v-switch inset v-model="model.settings.replicate_teacher_join" label="Replicar Junção"/> 
            </v-list-item>
            <v-list-item>
              <v-switch inset v-model="model.enable_activity_approval_flow" label="Fluxo de Aprovação de Atividade"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <v-switch inset v-model="model.settings.send_email_activity" label="Enviar e-mail de aprovação"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <v-switch inset v-model="model.settings.show_term_signatures" label="Exibir Termos"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <v-switch inset v-model="model.settings.allow_multi_teachers_activities" label="Multi Docente nas Atividades"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Atividades - Obrigatório Centro de Custos quando curso for vazio" inset v-model="model.settings.cost_center_activity_required" label="Centro de Custo obrigatório - Atividades"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Atividades - Filtrar Atividades por departamento do Usuário" inset v-model="model.settings.activities_by_departament" label="Atividades por Departamento"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Habilitar professor genérico A Contratar" inset v-model="model.settings.allow_teacher_in_hiring" label="Habilitar A Contratar"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Exibir Grupo de Horário na página da disponibilidade" inset v-model="model.settings.show_hour_group_availability" label="Exibir Grupo de Horário"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Exibir opção de horário por data na tela de horário" inset v-model="model.settings.allow_time_table_by_date" label="Horário por data"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Exibir opção de atualização de endreço na página do professor" inset v-model="model.settings.show_address_teacher" label="Atualização Endereço"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Pesquisar profesores próximos da localidade na atribuição de professor" inset v-model="model.settings.show_address_teacher_attribution" label="Pesquisar Localidade Professor"/> 
            </v-list-item>
            <v-list-item v-if="model.settings">
              <s-switch text-helper="Habilitar cadastro de fotos do professor" inset v-model="model.settings.enable_photo_teacher" label="Habilitar Foto Professor"/> 
            </v-list-item>
           <v-list-item>
              <v-row>
                <v-col>
                  <s-text-field text-helper="Máximo de horas de aulas + atividades no mesmo dia" :error-messages="errors.max_day_hours" v-model="model.max_day_hours" label="Máximo de horas por dia" />
                </v-col>
                <v-col>
                  <s-text-field text-helper="Máximo de aulas consecutivas no dia sem intervalo" :error-messages="errors.max_times_break_day" v-model="model.max_times_break_day" label="Máximo aulas consecutivas" />
                </v-col>
                <v-col v-if="model.settings">
                  <s-text-field text-helper="Dia limite para aprovação das atividades" :error-messages="errors.settings ? errors.settings.approval_activity_deadline : []" v-model="model.settings.approval_activity_deadline" label="Dia limite aprovação atividades" />
                </v-col>
                <v-col>
                  <emc-calendar-time-picker :error="!!errors.min_time_break" :errors="errors.min_time_break" :time.sync="model.min_time_break" label="Intervalo mínimo" />
                </v-col>
              </v-row>
              
            </v-list-item>

            <v-list-item v-if="model.settings">
              <sys-select-activity-item-status
                  :model.sync="model.settings.activity_item_status"
                  :error="errors.length > 0"  
                  :errors="errors.activity_item_status_id"
                  label="Status Atividade para pagamento"
                  multiple
              /> 
            </v-list-item>


          </emc-util-scroll>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "SysInstitutionPopOver",
    data: () => ({
      fav: true,
      menu: false,
      allowShockTeacher: false,
      allowDoubleClass: false,
      allowDesloc: false,
      allowJourney: false,
      hints: true,
    }),
    watch: {
        
        model(model_new, model_old) {
            
            if(!model_new.id){
              this.menu = false;
            }
           
        },
    },
    computed: {
      ...mapState('auth', ['user', 'acesso']),
      
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        },
    },
}
</script>