import Vue from 'vue'
import Vuetify from 'vuetify'
import pt from 'vuetify/src/locale/pt.ts'
// import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  iconfont: 'mdi',
  lang: {
      locales: { pt },
      current: 'pt'
  }
}

export default new Vuetify(opts)