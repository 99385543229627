import { service } from '../../../../helpers/http'

export const ActionIndexIntegrationShifts = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationShifts',
        payload: payload
    })

}
export const ActionDestroyIntegrationShift = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyIntegrationShift',
        payload: payload
    })
}
export const ActionStoreIntegrationShift = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeIntegrationShift',
        payload: payload
    })

}
export const ActionUpdateIntegrationShift = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateIntegrationShift',
        payload: payload
    })
}

export const ActionCreateIntegrationShift = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'storeIntegrationShift',
        payload: payload
    })
}