<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table
            v-else-if="renderComponent"
            dense
            fixed-header
            height="450px"
        >
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Professor</th>
                        <th class="text-center">CH</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Período</th>
                        <th class="text-left">Usuário</th>
                        <th class="text-left">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, i) in items"
                        :key="i"
                    >
                        <td>{{ item.teacher ? item.teacher.name : null }}</td>
                        <td>{{ item.work_load }}</td>
                        <td>{{ item.status.name }}</td>
                        <td>{{ item.date_start | date }} - {{ item.date_end | date }}</td>
                        <td>{{ item.user.name }}</td>
                        <td>{{ item.date_action | dateTime }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherAttributionLogTable',
    data: function() {
        return {
            loading: false,
            renderComponent: true,
            items: [],
        }
    },
    created() {

        this.getData();

    },
    props: {
        model: {
            type: Object,
            required: true
        },
    },
    methods: {
        ...mapActions('grid', ['ActionFindGridById']),

        getData() {

            if(this.loading || !this.model || !this.model.id) {
                return;
            }

            this.loading = true;

            var payload = {
                id: this.model.id,
                with: 'attributionsLog.status,attributionsLog.teacher,attributionsLog.user',
            }

            
            this.ActionFindGridById(payload)
                .then((res) => {
                    this.items = this.sortLogs(res.data.attributionsLog);
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        sortLogs(logs){

            logs.sort(function(a, b){
                return b.date_action.toString().localeCompare(a.date_action);
            });

            return logs;

        },

        
        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>