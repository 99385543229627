import { default as approval } from './approval/routes'

export default [
    ...approval,
    {
        path: '/activity-items',
        name: 'activity-items',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Cadastro de atividades',
            icon: '',
            breadcrumbs: [
                { text: 'Atividades', disabled: true, href: '/' },
                { text: 'Cadastro de atividades', disabled: true, href: '/' }
            ],
        }
    },
    {
        path: '/activity-items-restrict',
        name: 'activity-items-restrict',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Professores das Atividades',
            icon: '',
            breadcrumbs: [
                { text: 'Atividades', disabled: true, href: '/' },
                { text: 'Professores das Atividades', disabled: true, href: '/' }
            ],
        }
    },
]