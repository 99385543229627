export default {

    indicatorEvolution:                     { method: 'get',        url: 'offer_items/indicator-evolution' },
    indicatorHybridity:                     { method: 'get',        url: 'offer_items/indicator-hybridity' },
    tableTotalUCDay:                        { method: 'get',        url: 'offer_items/table-total-uc-day' },
    tableTotalUC:                           { method: 'get',        url: 'offer_items/table-total-uc' },
    tableGroups:                            { method: 'get',        url: 'offer_items/table-groups' },
    indexOfferItemDays:                     { method: 'get',        url: 'offer_items/offer-item-days' },
    indexOfferItemDisciplines:              { method: 'get',        url: 'offer_items/filter-disciplines' },
    indexOfferItemGroups:                   { method: 'get',        url: 'offer_items/filter-groups' },
    indexOfferItemCampuses:                 { method: 'get',        url: 'offer_items/filter-campuses' },
    indexOfferItemCourses:                  { method: 'get',        url: 'offer_items/filter-courses' },
    indexOfferItemQualifiers:               { method: 'get',        url: 'offer_items/filter-qualifiers' },
    indexOfferItemCategories:               { method: 'get',        url: 'offer_items/filter-categories' },
    indexOfferItemWorkTimes:                { method: 'get',        url: 'offer_items/filter-work-times' },
    indexOfferItemOfferItemDaysFilter:      { method: 'get',        url: 'offer_items/filter-offer-item-days' },
    indexOfferItemBaseGroupStatusFilter:    { method: 'get',        url: 'offer_items/filter-base-group-status' },
    indexOfferItemOfferItemStatusFilter:    { method: 'get',        url: 'offer_items/filter-offer-item-status' },
    updateOfferItemAll:                     { method: 'post',       url: 'offer_items/update-all' },
    showOfferItemInconsistencies:           { method: 'get',        url: 'offer_items/{id}/inconsistencies' },
    
}