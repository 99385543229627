export default [
    {
        path: '/equivalences',
        name: 'equivalences',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Equivalência',
            icon: '',
            breadcrumbs: [
                { text: 'Matriz currícular', disabled: true, href: '/' },
                { text: 'Equivalência', disabled: true, href: '/' },
            ],
        }
    }
]