<template>
     <v-card outlined max-height="350" class="pb-0 pa-0 mt-0">
        <v-app-bar class="elevation-0" dense>
            <v-toolbar-title class="text-body-1 mt-4">
                    <p><strong>{{ title }}</strong></p>
            </v-toolbar-title>
        </v-app-bar>
        <v-divider class="mb-2"></v-divider>
        <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="yellow darken-2"
                    />
        <v-card-text>
        <v-row>
            <v-col
            v-for="(item, i) in items"
            :key="i"
            cols="12"
            sm="2"
            >
            <v-text-field
                v-if="renderComponent"
                :id="'text' + i"
                :value="item"
                class="ml-0 centered-input text--darken-3 mt-3"
                style="text-align: center"
                regular
                dense
                :disabled="disabled"
                @keydown.prevent.stop="keydown($event, i)"
            />
        </v-col>
        </v-row>
        </v-card-text>
    </v-card>
</template>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>

<script>
export default {
    name: "EmcTextToken",
    data: () => ({
        dialog: false,
        items: [],
        renderComponent: true,
        index: 0,
        updateItems: true,
    }),

    created(){
        this.initItems();
    },
    props: {
        model: {},
        maxLength: {
            type:       Number,
            default:    6
        },
        disabled: {
            type:       Boolean,
            default:    false
        },
        loading: {
            type:       Boolean,
            default:    false
        },
        title: {
            type:       String,
            default:    'Informe o token enviado:'
        }
    },
    watch: {
        model(val){
            if(this.updateItems){
                this.initItems();
            }
        }
    },
    methods: {
        
        initItems(){
            
            this.items = [];
            this.index = 0;

            for (let i = 0; i < this.maxLength; i++) {
                
                if(this.model){
                    this.items.push(this.model[i]);
                }else{
                    this.items.push('');
                }

                
            }

            this.forceRerender();
        },
        
        keydown(ev, index){
            
            if(ev.keyCode >= 96 && ev.keyCode <= 105){
                this.items[index] = ev.key;
                this.index = index + 1;
                this.forceRerender();
            }else if(ev.keyCode == 8){
                
                if(this.items[index] == ''){
                    this.index = index - 1;
                    this.forceRerender();
                }else{
                    this.items[index] = '';
                    this.index = index;
                    this.forceRerender();
                }
            }

            this.updateModel();
           
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                
                this.renderComponent = true;

                this.$nextTick(() => {
                    let el = document.getElementById('text' + (this.index));
                    if(el){
                        el.focus();
                        this.updateItems = true;
                    }
                });
            });
        },

        updateModel(){

            
            var text = this.items.join('');
            this.updateItems = false;

            this.$emit('update:model', text);
            
            if(text.length == this.maxLength){
                this.$emit('complete', text);
                this.updateItems = true;
            }

        },

        reset(){
            this.$emit('update:model', null);
            this.initItems();
        }

    }
}
</script>