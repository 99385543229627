export default [
    {
        path: '/courseLevels',
        name: 'courseLevels',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Nível de curso',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Nível de curso', disabled: true, href: '/' },
            ],
        }
    },
]