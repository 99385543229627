import store from '../store'

export default req => {
    return ({ status, body}) => {
        
        var rota = window._Vue.$router.app._route.name;


        if(rota == 'access-time-sheet' || rota == 'clocking'){

            if(status === 401 && rota == 'clocking'){
                window._Vue.$router.push({ name: 'access-time-sheet' });
            }

        }else if(rota != 'availability-teacher'){
            
            if(status === 203 || status === 401){
                if(window._Vue.$router.app._route.name != 'login'){
                    store.dispatch('auth/ActionSignOut');
                    window._Vue.$router.push({ name: 'login' });
                }
            }

            if(status === 403) {
                window._Vue.$router.push({ name: '403'})
            }

            if(status === 500) {
                store.dispatch('auth/ActionSetErrorsHttp', { message: body.message, url: req.url})
                //Atualizar os dados para 
                //window._Vue.$router.push({ name: '500'})
            }

            if(status === 503) {
                window._Vue.$router.push({ name: '503'})
            }
        }
    }
}