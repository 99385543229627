import { service } from '../../../helpers/http'

export const ActionGetWorkLoadTypes = ({ dispatch }, payload) => {
    return service({
        module: 'workLoadType',
        action: 'workLoadTypes',
        payload: payload
    })
}

export const ActionCreateWorkLoadType = ({ dispatch }, payload) => {
    return service({
        module: 'workLoadType',
        action: 'workLoadTypeCreate',
        payload: payload
    })
}

export const ActionUpdateWorkLoadType = ({ dispatch }, payload) => {
    return service({
        module: 'workLoadType',
        action: 'workLoadTypeUpdate',
        payload: payload
    })
}

export const ActionDeleteWorkLoadType = ({ dispatch }, payload) => {
    return service({
        module: 'workLoadType',
        action: 'workLoadTypeDelete',
        payload: payload
    })
}