import { service } from '../../../../helpers/http'

export const ActionGetHours = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hours',
        payload: payload
    });
}

export const ActionCreateHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hourCreate',
        payload: payload
    });
}

export const ActionUpdateHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hourUpdate',
        payload: payload
    });
}

export const ActionDeleteHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hourDelete',
        payload: payload
    });
}

export const ActionFindDayOfWeeks = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'dayOfWeeks',
        payload: payload
    });
}

export const ActionGetHoursGroups = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hoursGroups',
        payload: payload
    });
}

export const ActionGetHoursByToken = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'hoursByToken',
        payload: payload
    });
}

export const ActionGetHoursIndexToken = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'indexHoursToken',
        payload: payload
    });
}

export const ActionGetShifts = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'shifts',
        payload: payload
    });
}

export const ActionGetShiftsFilter = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'shiftsFilter',
        payload: payload
    });
}