import { service } from '../../../helpers/http'

export const ActionFindCampuses = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campuses',
        payload: payload
    })
    
}

export const ActionFindCampusesFilterAmbience = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campusesFilterAmbience',
        payload: payload
    })
}

export const ActionFindCampusesFilterGroup = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campusesFilterGroup',
        payload: payload
    })
}

export const ActionCreateCampus = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campusCreate',
        payload: payload
    })
}

export const ActionUpdateCampus = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campusUpdate',
        payload: payload
    })
}

export const ActionDeleteCampus = ({ dispatch }, payload) => {

    return service({
        module: 'campus',
        action: 'campusDelete',
        payload: payload
    })
}