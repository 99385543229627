<template>
    <div>
        <sys-pop-over-config-base :model="model" :configs="configs">
            
            <template v-slot:custom>
                <sys-select-term
                  :model.sync="model.term_id"
                  :error="errors.length > 0"  
                  :errors="errors.term_id"
                  label="Termo"
                  showClear
              /> 
            </template>
        </sys-pop-over-config-base>
    </div>
</template>

<script>

export default {
    name: "SysWorkTimePopOver",
    data: () => ({
      configs: [
        { type: 'boolean',      field: 'availability_single',               label: 'Disponibilidade única',                 textHelper: 'O professor só poderá informar a disponibilidade uma única vez enquanto o prazo estiver liberado' },
        { type: 'boolean',      field: 'show_availability_week',            label: 'Disponibilidade semanal',               textHelper: 'Exibir quadro de disponibilidade semanal na Página do Professor' },
        { type: 'boolean',      field: 'show_availability_date',            label: 'Disponibilidade por data',              textHelper: 'Exibir quadro de disponibilidade por data na Página do Professor' },
        { type: 'boolean',      field: 'display_times',                     label: 'Exibir horário para o professor',       textHelper: 'Exibir horários na Página do Professor' },
        { type: 'boolean',      field: 'display_indications' ,              label: 'Exibir atribuição para o professor',    textHelper: 'Exibir atribuições na Página do Professor' },
        { type: 'boolean',      field: 'show_confirmation',                 label: 'Confirmação de carga horária',          textHelper: 'Exibir confirmação de carga horária na Página do Professor' },
        { type: 'boolean',      field: 'show_attributions',                 label: 'Atribuição no extrato',                 textHelper: 'Exibir atribuições no Extrado do Professor' },
        { type: 'boolean',      field: 'show_disciplines_interest',         label: 'Disciplinas de interesse',              textHelper: 'Habilitar disciplinas de interesse na Página do Professor' },
        { type: 'boolean',      field: 'allow_editing_attribution',         label: 'Edição de atribuição',                  textHelper: 'Permitir editar data e carga horária na tela de Professores das Turmas' },
        { type: 'boolean',      field: 'availability_date_by_hour_group',   label: 'Disponibilidade agrupada por horário',  textHelper: 'Agrupar horários no quadro de disponibilidade por data na Página do Professor' },
        { type: 'boolean',      field: 'auto_generate_grids' ,              label: 'Oferta automática',                     textHelper: 'Gerar oferta automaticamente ao editar ou criar uma turma' },
        { type: 'boolean',      field: 'show_digital',                      label: 'Exibir CH digital',                     textHelper: 'Habilitar cadastro de disponibilidade para disciplinas digitais na Página do Professor' },
        { type: 'boolean',      field: 'show_other_activities',             label: 'Exibir Atividades',                     textHelper: 'Exibir atividades na Página do Professor' },
        { type: 'boolean',      field: 'show_activity_time_table',          label: 'Exibir Horário das Atividades',         textHelper: 'Exibir horário das atividades na Página do Professor' },
        { type: 'boolean',      field: 'show_agree_term',                   label: 'Exibir aceitação do termo',             textHelper: 'Exibir aceitação do termo na Página do Professor' },
        { type: 'boolean',      field: 'show_occurrences',                  label: 'Exibir Ocorrências de Ponto',           textHelper: 'Exibir Ocorrências de Ponto na Página do Professor' },
        { type: 'boolean',      field: 'show_box_total',                    label: 'Exibir CH Máxima',                      textHelper: 'Exibir Opção do professor informar a carga horária máxima desejada na Página do Professor' },
        { type: 'boolean',      field: 'auto_calculate_work_load',          label: 'Cálculo Automático CH',                 textHelper: 'Calcular a CH Máxima desejada automaticamente na Página do Professor' },
        { type: 'boolean',      field: 'validate_min_work_load',            label: 'Validar CH Mínima',                     textHelper: 'Validar a CH Mínima permitida desejada na disponibilidade da Página do Professor' },
        { type: 'text-field',   field: 'max_work_load',                     label: 'CH Máxima Atribuição',                  textHelper: 'Carga Horária máxima permitida de atribuição (Semanal)' },
      ]
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {}
    },
}
</script>