<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="saveAll" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-book-open-page-variant" 
        v-bind:show.sync="dialog"
        maxWidth="100%"
    >
        <template v-slot:form>
            <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-data-table
                                v-if="renderComponent"
                                :items="model.items"
                                :headers="headers.filter(h => h.visible)"
                                sort-by="calories"
                                class="elevation-1"
                                item-key="index"
                                :hide-default-footer="true"
                                :items-per-page="-1"
                                fixed-header
                                height="400"
                            >
                                <template v-slot:top>
                                    <v-row>
                                        <v-col cols="12" sm="10">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn disabled color="indigo darken-1" @click.prevent.stop="addItem()" icon v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                                                </template>
                                                <span>Adicionar Carga Horária</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template dense v-slot:item.date="{ item, index }">
                                    <emc-calendar-date-picker2 
                                        :model.sync="item.date" 
                                        label="" 
                                        :error="errors.length > 0"
                                        :error-messages="errors['items.' + index + '.date']" 
                                        :show="dialog"
                                        :min="acesso.periodo.classes_start"
                                        :max="acesso.periodo.classes_end"
                                        dense
                                    />
                                </template>

                                <!-- Períodos -->
                                <template v-for="(period, i) in periods" v-slot:[`item.${period}`]="{ item, index }">
                                    <emc-calendar-time-picker 
                                        :key="i"
                                        dense 
                                        :error="!!errors[period]" 
                                        :errors="errors['items.' + index + '.' + period]" 
                                        :time.sync="item[period]" 
                                        label=""
                                        clearable
                                    />
                                </template>

                                <template dense v-slot:item.description="{ item, index }">
                                    <v-text-field
                                        v-model="item.description"
                                        :error="errors.length > 0"  
                                        :error-messages="errors['items.' + index + '.description']"
                                        dense
                                    ></v-text-field>
                                </template>
                                
                                <!-- Marcações -->
                                <template v-for="(mark, i) in markings" v-slot:[`item.${mark}`]="{ item, index }">
                                        <emc-calendar-time-picker 
                                            :key="i"
                                            dense 
                                            :error="!!errors[mark]" 
                                            :errors="errors['items.' + index + '.' + mark]" 
                                            :time.sync="item[mark]" 
                                            label=""
                                            clearable
                                        />
                                </template>

                                <template dense v-slot:item.occurrence_status_id="{ item, index }">
                                    <sys-select-occurrence-status
                                        :model.sync="item.occurrence_status_id"
                                        :error="errors.length > 0"  
                                        :errors="errors['items.' + index + '.occurrence_status_id']"
                                        label=""
                                    />
                                </template>

                                <template v-slot:item.actions="{ item, index }">
                                    
                                    <v-btn
                                        :loading="index == indexItem && loadingUpdate"
                                        small
                                        icon
                                        disabled
                                    >
                                        <v-icon
                                        small
                                        color="success"
                                        @click.prevent.stop="updateItem(item, index)"
                                        dense
                                        >
                                        mdi-content-save-outline
                                        </v-icon>
                                    </v-btn>

                                    <v-btn
                                        :loading="index == indexItem && loadingDelete"
                                        small
                                        icon
                                        disabled
                                    >
                                        <v-icon
                                        small
                                        color="error"
                                        @click.prevent.stop="deleteItem(item, index)"
                                        dense
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                    
                                </template>
                            
                            </v-data-table>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
       
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "SysOccurrenceSheetItemRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        indexItem: -1,
        loading: false,
        loadingUpdate: false,
        loadingDelete: false,
        renderComponent: true,
        periods: ['hour_start1', 'hour_end1', 'hour_start2', 'hour_end2', 'hour_start3', 'hour_end3',],
        markings: ['check_in_1', 'check_out_1', 'check_in_2', 'check_out_2', 'check_in_3', 'check_out_3',],
        headers: [
            { text: 'Data',         value: 'date',                  align: 'start', sortable: false, visible: true },
            { text: 'Início',       value: 'hour_start1',           align: 'start', sortable: false, visible: true },
            { text: 'Fim',          value: 'hour_end1',             align: 'start', sortable: false, visible: true },
            { text: 'Início',       value: 'hour_start2',           align: 'start', sortable: false, visible: false },
            { text: 'Fim',          value: 'hour_end2',             align: 'start', sortable: false, visible: false },
            { text: 'Início',       value: 'hour_start3',           align: 'start', sortable: false, visible: false },
            { text: 'Fim',          value: 'hour_end3',             align: 'start', sortable: false, visible: false },
            { text: 'Descrição',    value: 'description',           align: 'start', sortable: false, visible: true },
            { text: 'Entrada1',     value: 'check_in_1',            align: 'start', sortable: false, visible: true },
            { text: 'Saída1',       value: 'check_out_1',           align: 'start', sortable: false, visible: true },
            { text: 'Entrada2',     value: 'check_in_2',            align: 'start', sortable: false, visible: false },
            { text: 'Saída2',       value: 'check_out_2',           align: 'start', sortable: false, visible: false },
            { text: 'Entrada3',     value: 'check_in_3',            align: 'start', sortable: false, visible: false },
            { text: 'Saída3',       value: 'check_out_3',           align: 'start', sortable: false, visible: false },
            { text: 'Status',       value: 'occurrence_status_id',  align: 'center',sortable: false, visible: true },
            { text: '',             value: 'actions',               align: 'center',sortable: false, visible: true },
        ],
    }),
    created(){
        
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
                this.loadMarks();
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Folhas de Ponto"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('timeSheet', ['ActionUpdateOccurrenceSheet', 'ActionStoreOccurrenceSheetItem', 'ActionUpdateOccurrenceSheetItem', 'ActionDestroyOccurrenceSheetItem']),

        loadMarks(){

            this.model.items.forEach(item => {
                for (let i = 0; i < item.registers.length; i++) {
                    
                    if(item.registers[i].action != 'D'){
                        item[this.markings[i]] = item.registers[i].time;
                    }
                }
            });

        },
        
        saveAll(){
            
            this.loading = true;
            this.error = false;
            this.errors = {};

            this.model.items.forEach(item => {
                this.loadRegisters(item);
            });
            
            this.ActionUpdateOccurrenceSheet(this.model)
                .then((res) => {
                    
                })
                .catch((error) =>{
                    this.error = true;
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        removeItem(item){
          var index = this.model.items.indexOf(item);
          this.model.items.splice(index, 1);
          this.forceRerender();
        },

        updateItem(item, index){
          
          var method = item.id ? 'ActionUpdateOccurrenceSheetItem' : 'ActionStoreOccurrenceSheetItem';
          this.loadingUpdate = true;
          this.indexItem = index;
          this.error = false;
          this.errors = {};

          this.loadRegisters(item);
          
          this[method](item)
            .then((res) => {
                item.id = res.data.id;
            })
            .catch((error) =>{
                Object.keys(error.errors).forEach(key => {
                    this.errors['items.' + index + '.' + key] = error.errors[key];
                });
                this.error = true;
            })
            .finally(() => {
                  this.loadingUpdate = false;
                  this.indexItem = -1;
                  //this.forceRerender();
            });
            
      },

      loadRegisters(item){

            for (let i = 0; i < this.markings.length; i++) {
                
                let reg     = item.registers.length > i ? item.registers.length[i] : null;
                let date    = item.date;
                let time    = item[this.markings[i]];

                if(reg){
                    reg.time = time;
                }else if(time && !item.registers.find(r => r.time == time)){
                    item.registers.push({
                        id:                                 0,
                        occurrence_sheet_item_id:           item.id,
                        occurrence_intervention_type_id:    2, // Tratado
                        date:                               date,
                        time:                               time,                            
                    });
                }

                item.registers = item.registers.filter(r => r.time);

            }
      },

        deleteItem(item, index){
          
          if(!item.id){
            this.removeItem(item);
            return;
          }
          
          this.loadingDelete = true;
          this.indexItem = index;
          this.error = false;
          this.errors = {};
          
          this.ActionDestroyOccurrenceSheetItem({id: item.id})
            .then((res) => {
                this.removeItem(item);
            })
            .catch((error) =>{
                
                
            })
            .finally(() => {
                  this.loadingDelete = false;
                  this.indexItem = -1;
                  this.forceRerender();
            });
            
      },

      addItem(){
          
          if(!this.model){
            return;
          }

          var length = this.model.items.length;
          
          var item = {
              index:                        length,
              id:                           0,
              occurrence_sheet_id:          this.model.id,
              occurrence_record_type_id:    1,
              occurrence_type_id:           null,
              occurrence_status_id:         1,
              date:                         null,
              hour_start1:                  null,
              hour_end1:                    null,
              hour_start2:                  null,
              hour_end2:                    null,
              hour_start3:                  null,
              hour_end3:                    null,
              description:                  null,
              check_in_1:                   null,
              check_out_1:                  null,
              check_in_2:                   null,
              check_out_2:                  null,
              check_in_3:                   null,
              check_out_3:                  null,
              registers:                    [],
          };

          this.model.items.push(item);
          this.forceRerender();
          
      },

      forceRerender () {
      
        this.renderComponent = false;

        this.$nextTick(() => {
            this.renderComponent = true;
        });
    }

      

    }
}
</script>