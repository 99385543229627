export default [
    {
        path: '/date-masks',
        name: 'date-masks',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Máscara de Datas',
            icon: 'mdi-calendar-heart',
            breadcrumbs: [
                { text: 'Lista de Máscara de Datas', disabled: true }
            ]
        }
    }
]