<template>

  <v-card>
    <v-card-title>
      <span class="body-2">Relação de configurações para integração</span>
      <v-spacer></v-spacer>
      
      <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon :loading="loadingReplicate" @click.prevent.stop="replicate()" v-bind="attrs" v-on="on"><v-icon color="primary">mdi-content-copy</v-icon></v-btn>
            </template>
            <span> Replicar registros do período anterior </span>
      </v-tooltip>

      <v-btn icon @click.prevent.stop="show = true"><v-icon>mdi-plus</v-icon></v-btn>
      
    </v-card-title>

    <emcTableItems
        :headers="headers"
        v-bind:collections.sync="collections"
        v-bind:meta.sync="meta"
        v-bind:itemDelete.sync="itemDelete"
        v-bind:itemEdit.sync="model"
        :find="false"
    />

    <SysRegisterWorkTimeIntegration @onCompleted="getData()" :model="model" :show.sync="show" :workTime="workTime"/>

    <!-- Modal usado para mensagem e ação de exclusão -->
    <emc-alert-modal :text="textDelete" :disabled="deleting" v-bind:show.sync="showDelete">
      <template v-slot:actions>
        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
      </template>
    </emc-alert-modal>

  </v-card>

</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: "SysWorkTimeIntegration",
  props: {
    workTime: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: 'Código', value: 'code'},
      { text: 'Empresa', value: 'company.name'},
      { text: 'Filial', value: 'branch.name'},
      { text: 'Nível de curso', value: 'course_level.name'},
      { text: 'Sigla', value: 'note'},
      { text: 'Ações', value: 'actions'},
    ],
    collections: [],
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showDelete: false,
    textDelete: '',
    deleting: false,
    loadingReplicate: false,
  }),
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('worktime', ['ActionShowWorkTime', 'ActionDestroyIntegrationWorkTime', 'ActionIntegrationWorkTimeReplicate']),

    getData() {
      this.collections = []
      const params = {
        id: this.workTime.id,
        with: 'integration.branch,integration.company,integration.courseLevel'
      }
      this.ActionShowWorkTime(params)
          .then((r) => {
            this.collections = r.data.integration
            this.meta = {
              page: 1,
              itemsPerPage: 10,
              total: this.collections ? this.collections.length : 0,
              last_page: 1
            }
          })
          .finally(() => {

          })
    },

    replicate(){

      this.loadingReplicate = true;
      
      this.ActionIntegrationWorkTimeReplicate(this.workTime)
          .then((r) => {
            this.getData();
          })
          .finally(() => {
            this.loadingReplicate = false;
          })
    },

    deleteItem() {
      this.ActionDestroyIntegrationWorkTime({ id: this.itemDelete.id })
          .then((r) => {
            this.getData();
          })
          .finally(() => {
            this.showDelete = false
            this.textDelete = ''
          })
    }
  },
  watch: {
    model(newItem, oldItem) {
      if(newItem != oldItem && this.model.id) {
        this.show = true
      } else if(Object.keys(this.model).length === 0){
        this.show = false;
      }
    },
    show(val) {
      if(!val) {
        this.model = {}
      }
    },
    itemDelete(item_new, item_old){

      if(item_new !== item_old && Object.keys(item_new).length > 0)
      {
        this.showDelete = true;
        this.textDelete = "Deseja excluir o item " + item_new.note + "?";
      } else {
        this.showDelete = false
        this.textDelete = '';
      }
    },
    workTime() {
      this.getData()
    }
  }
}
</script>