<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'" persistent >
        <v-card 
                outlined
                height="70vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Demais Filtros
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-campus
                                :model.sync="campuses"
                                label="Campus"
                                multiple
                                selectAll
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-category
                                :model.sync="categories"
                                label="Categoria do Curso"
                                multiple
                                selectAll
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-work-load-type
                                :model.sync="workLoads"
                                label="Tipo de Carga Horária"
                                multiple
                                selectAll
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-category-group
                                :model.sync="categoryGroups"
                                label="Categoria da Turma"
                                multiple
                                selectAll
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                    :disabled="disableButton"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SysJoinFilter",
    data: (vm) => ({
        dialog: false,
        loading: false,
        campuses: [],
        categories: [],
        workLoads: [],
        categoryGroups: [],
    }),
    computed: {
        disableButton (){
            
            return !this.campuses.length && !this.categories.length && !this.workLoads.length && !this.categoryGroups.length;

        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;
        },

    },
    methods: {
        
       filter(){

            var payload = {};
        
            if(this.categories && this.categories.length > 0){
                
                let ids = this.categories.map(o => o['id']).join(';');
                payload.filter_relations = [
                    {
                        name:   'category',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                ];
            }

            if(this.workLoads && this.workLoads.length > 0){
                
                let ids = this.workLoads.map(o => o['id']).join(';');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                payload.filter_relations.push(
                    {
                        name:   'workLoadType',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.categoryGroups && this.categoryGroups.length > 0){
                
                let ids = this.categoryGroups.map(o => o['id']).join(';');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                payload.filter_relations.push(
                    {
                        name:   'categoryGroups',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.campuses && this.campuses.length > 0){
                
                let ids = this.campuses.map(o => o['id']).join(';');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                payload.filter_relations.push(
                    {
                        name:   'campuses',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }
        
            this.$emit('filter', payload);

       },

       clearFilter(){

            this.categories = [];
            this.workLoads = [];
            this.campuses = [];
            this.categoryGroups = [];
            this.$emit('filter', null);

       },

    }
}
</script>