import { service } from '../../../helpers/http'

export const ActionFindCategoryGroups = ({ dispatch }, payload) => {

    return service({
        module: 'categoryGroup',
        action: 'categoryGroups',
        payload: payload
    })
    
}

export const ActionCreateCategoryGroup = ({ dispatch }, payload) => {

    return service({
        module: 'categoryGroup',
        action: 'createCategoryGroup',
        payload: payload
    })
    
}

export const ActionUpdateCategoryGroup = ({ dispatch }, payload) => {

    return service({
        module: 'categoryGroup',
        action: 'updateCategoryGroup',
        payload: payload
    })
    
}

export const ActionDeleteCategoryGroup = ({ dispatch }, payload) => {

    return service({
        module: 'categoryGroup',
        action: 'deleteCategoryGroup',
        payload: payload
    })
    
}