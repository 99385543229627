import { service } from '../../../../helpers/http'

/** 
 * Tipos de lançamentos financeiros
*/
export const ActionFinancialPostingTypesIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'financialPostingTypesIndex',
        payload: payload
    })
}


export const ActionFinancialPostingTypesStore = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'financialPostingTypesStore',
        payload: payload
    })
}

export const ActionFinancialPostingTypesUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'financialPostingTypesUpdate',
        payload: payload
    })
}

export const ActionFinancialPostingTypesDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'financialPostingTypesDestroy',
        payload: payload
    })
}

/** 
 * Lançamentos financeiros
*/

export const ActionPostingFinancialItemsIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'postingFinancialItemsIndex',
        payload: payload
    })
}


export const ActionPostingFinancialItemsStore = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'postingFinancialItemsStore',
        payload: payload
    })
}

export const ActionPostingFinancialItemsUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'postingFinancialItemsUpdate',
        payload: payload
    })
}

export const ActionPostingFinancialItemsDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'postingFinancialItemsDestroy',
        payload: payload
    })
}


export const ActionForecastMonthIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'forecastMonthIndex',
        payload: payload
    })
}

export const ActionExpenseForecastMonthIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'expenseForecastMonthIndex',
        payload: payload
    })
}

export const ActionOverdueInIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'overdueInIndex',
        payload: payload
    })
}

export const ActionBalanceEntriesIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'balanceEntriesIndex',
        payload: payload
    })
}

export const ActionReleaseFromRecentMonthsIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'releaseFromRecentMonthsIndex',
        payload: payload
    })
}

export const ActionDelayedExpenseIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'delayedExpenseIndex',
        payload: payload
    })
}

export const ActionCashForecastIndex = ({ dispatch }, payload) => {
    return service({
        module: 'finance',
        action: 'cashForecastIndex',
        payload: payload
    })
}