<template>
    <sys-register-base 
        :loading="loading" 
        :disabled="disabled"
        @onSave="save" 
        :model.sync="model" 
        title="Alterar o dia da aula" 
        icon="mdi-clock-outline" 
        v-bind:show.sync="dialog"
        maxWidth="30%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-row align="center">
                <v-date-picker
                    v-model="model.date"
                    year-icon="mdi-calendar-blank"
                    :show-current="false"
                    full-width
                    readonly
                ></v-date-picker>
            </v-row>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
        <template v-slot:actions>
            <v-btn v-if="model.periodicity === 'D'" color="error" @click.prevent.stop="destroy()" :loading="loadingDestroy" :disabled="disabled" >Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterScheduleDate",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        disabled: false,
        loadingDestroy: false
        
    }),
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-format-list-text"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('schedule', ['ActionDeleteClasse']),
        destroy() {

            if(this.model.periodicity === 'D' && this.model.class_schedule_id) {

                this.error = false;
                this.message = '';
                this.disabled = true;
                this.loadingDestroy = true 

                this.ActionDeleteClasse({ id: this.model.class_schedule_id })
                    .then((res) => {
                        this.$emit('onDestroy', this.model);
                    })
                    .catch((error) => {
                        this.error      = true;
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loadingDestroy = false
                        this.disabled = false
                    })
            }
        },
        save()
        {
            //
        },

    }
}
</script>