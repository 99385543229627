<template>
    <div>
        <sys-core-toobar />
    </div>
</template>

<script>
export default {
    name: "SysCoreMain"
}
</script>