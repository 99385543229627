<template>
    <emc-card-stats
            icon="mdi-timeline-clock-outline"
            color="blue darken-2"
            sheet-width="50"
            sheet-heigth="50"
            icon-class="px-3 py-1"
            :subtitle="subtitle"
            :tools="!hideFooter"
            :classSubTitle="classSubTitle"
            >

          <template slot="tools">

            <emc-button-icon v-if="!type" :loading="loading" icon="mdi-refresh-circle" text="Atualizar" color="success" @click.prevent.stop="refresh()" />
              
              <v-tooltip v-if="showMoreButton" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMoreTimeTable', itemId)">
                        <v-icon btn>mdi-monitor-eye</v-icon>
                    </v-btn>
                </template>
                <span>
                    Ver Mais...
                </span>
            </v-tooltip>
              <v-tooltip bottom v-if="!hideTools">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo darken-1" icon small @click.prevent.stop="download(12)" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                  </template>
                  <span>Horários</span>
              </v-tooltip>
          </template>
          <template slot="body">
            <v-progress-circular
                          :rotate="360"
                          :size="140"
                          :width="15"
                          :value="schedule && schedule.percent ? schedule.percent : 0"
                          color="blue darken-2"
                        >
                        <template slot:default>
                          <span>
                            <span>{{ schedule && schedule.percent ? schedule.percent : 0}}%</span><br />
                            <span v-if="schedule && schedule.total">{{ schedule.completed}} / {{ schedule.total}}</span>
                          </span>
                        </template>
                      </v-progress-circular>
          </template>
          </emc-card-stats>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../mixins';

export default {
    name: 'EmcChartClassScheduleOfWorkTime',
    mixins: [myMixin],
    data () {
        return {
          loading: false,
          schedule: {
            total: 0,
            percent: 0,
            completed: 0
          }
        }
    },
    methods: {
      ...mapActions('controlPanel', ['ActionFindIndicatorTimeTable', 'ActionIndicatorTimeTableRefresh']),
      getData(){
      
        this.schedule = this.indicator;
        
        if(this.type == 'campus' || this.type == 'institution'){
          return;
        }
        
        var payload = {
            work_time_id: this.acesso.periodo.id
        };
        
        this.ActionFindIndicatorTimeTable(payload)
          .then((res) => {
              this.schedule = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      
      },

      refresh(){
      
        this.loading = true;
        
        this.ActionIndicatorTimeTableRefresh()
            .then((res) => {
                
            })
            .finally(() => {
              setTimeout(() => {  
                this.getData();
              }, 6000);
            });
      },
    },
    props: {
        hideTools: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        type: {
          type: String,
          default: null,
        },
        indicator: {
          default: null,
        },
        itemId: {
            type: Number,
            default: null
        },
        showMoreButton: {
            type: Boolean,
            default: false
        },
        subtitle: {
          type: String,
          default: 'Horário',
        },
        hideSubText: {
          type: Boolean,
          default: false
        },
        classSubTitle: {
          type: String,
          default: 'text-h5 font-weight-light grey--text'
        }
    },
    created() {
      this.getData()
    },
    computed: {
      ...mapState('auth', ['acesso']),
    }
}
</script>