<template>
    <sys-register-base 
            :loading="loading" 
            :model.sync="model" 
            :title="title" 
            :icon="icon"
            v-bind:show.sync="dialog"
            maxWidth="80%"
            :actions="false"
        >

        
    
        <template v-slot:form>

            <v-progress-linear
                indeterminate
                color="blue"
                class="mb-0"
                v-if="loading"
            ></v-progress-linear>
            
              <v-container>
                <v-row>
                  <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
                      <emc-chart-teacher-work-load-avg 
                        :item="item"
                        no-load
                        show-actions
                    />
                  </v-col>
              </v-row>
              </v-container>
        </template>

    </sys-register-base> 
</template>

<script>


import { mapActions, mapState } from 'vuex'
export default {
    name: "EmcChartGeneralCampusWorkLoadAvg",
    data: (vm) => ({
        dialog: false,
        loading: false,
        showMore: false,
        items: [],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.getData();
            }
            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-monitor-eye"
        },
        title: {
            type: String,
            default: "CH Média por Campus"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        brandId: {

        }
    },
    methods:{
      ...mapActions('chart', ['ActionChartTeacherWorkLoadAvg']),

        getData(){
      
            this.items = [];
            
            var payload = {
                type: 'campuses',
                value: this.brandId
            };

            this.loading = true;

            this.ActionChartTeacherWorkLoadAvg(payload)
                .then((res) => {
                    this.items = res.data;
                })
                .finally(() => {
                    this.loading = false;
                    
                });

            },
        
    }
}
</script>