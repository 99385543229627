<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus</th>
                        <th>Atividade</th>
                        <!-- <th>Periodicidade</th> -->
                        <th>Dia</th>
                        <th>Horário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in times" :key="item.id">
                        <td>{{ item.campus.name }}</td>
                        <td>{{ item.activity.name }}</td>
                        <!-- <td>
                            {{ item.by_date ?  'Por Data' : 'Horário Semanal' }}
                        </td> -->
                        <td>
                            <span v-if="item.by_date">{{ item.date|date }}</span> 
                            <span v-else>{{ item.day_of_week.name }}</span>
                        </td>
                        <td>{{ item.hour_start }} - {{ item.hour_end }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherOtherActivityTimeTable',
    data: function() {
        return {
            items: [],
            loading: true,
            renderComponent: true,
            times: [],
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        token: {
            type:   String,
            default: null
        }
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeacher']),
        ...mapActions('availability', ['ActionGetTeacherByTokenActivities']),

        getData() {

            if(this.teacher.id === undefined) {
                return []
            }

            this.items = [];
            this.times = [];
            this.loading = true;
            var method = this.token ? 'ActionGetTeacherByTokenActivities' : 'ActionFindTeacher';

            var params = {
                with: 'activities.activityItem.campus,activities.activityItem.activity,activities.timeTables,activities.timeTables.dayOfWeek',
                showAllCampuses: 'true',
                showAllTeachers: true
            }

            if(this.token){
                params.token = this.token;
            }else{
                params.id = this.teacher.id;
            }

            this[method](params)
                .then((r) => {
                    this.items = this.token ? r.data.activities : r.data.activities;
                    this.loadTimes(this.items);
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        loadTimes(items){

            items.forEach(item => {
                
                if(item.by_date){
                    item.timeTables.sort((a,b) => (a.date.toString().localeCompare(b.date) || a.hour_start.toString().localeCompare(b.hour_start)));
                }else{
                    item.timeTables.sort((a,b) => (a.day_of_week_id.toString().localeCompare(b.day_of_week_id) || a.hour_start.toString().localeCompare(b.hour_start)));
                }
                
                item.timeTables.forEach(time => {
                    
                    var itemTime = {
                        id:             time.id,
                        campus:         item.activityItem.campus,
                        activity:       item.activityItem.activity,
                        day_of_week_id: time.day_of_week_id,
                        day_of_week:    time.day_of_week,
                        date:           time.date,
                        hour_start:     time.hour_start,
                        hour_end:       time.hour_end,
                        by_date:        item.activityItem.by_date,
                    };

                    this.times.push(itemTime);
                    
                });
            });

        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>