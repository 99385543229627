import { service } from '../../../../helpers/http'

export const ActionIndexUserImports = ({ dispatch }, payload ) => { 
	
	return service({ 
		module: 'user', 
		action: 'indexUserImports', 
		payload: payload 
	})
}

export const ActionDestroyUserImport = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'destroyUserImport', 
		payload: payload 
	})
}

export const ActionUserImportItem = ({ dispatch }, payload ) => { 
	
	return service({ 
		module: 'user', 
		action: 'userImportItem',
		params: { param: payload.get('param') },
		payload: payload,
	})
}

export const ActionUserImportItemReprocess = ({ dispatch }, payload ) => { 
	
	return service({ 
		module: 'user', 
		action: 'reprocessUserImport',
		payload: payload,
	})
}

export const ActionIndexImports = ({ dispatch }, payload ) => { 
	
	return service({ 
		module: 'user', 
		action: 'indexImports', 
		payload: payload 
	})
}