<template>
    <div>
        <sys-pop-over-config-base :model="model" :configs="configs" />
    </div>
</template>

<script>

export default {
    name: "SysGridOfferPopOver",
    data: () => ({
      configs: [
        { type: 'boolean', field: 'validate_time_table',  label: 'Validar Choque de Horário', textHelper: 'Validar choque de horário para a mesma turma composição' },
        { type: 'boolean', field: 'validate_ucdp',  label: 'Validar UCDP', textHelper: 'Validar UCDP para turmas de não formandos' },
      ]
    }),
    props: {
        model: {
            type: Object,
        },
    },
}
</script>