import { service } from '../../../../helpers/http'

export const ActionIndexEventCalendarSettings = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'indexEventCalendarSettings', 
		payload: payload 
	})
}

export const ActionCreateEventCalendarSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'createEventCalendarSetting', 
		payload: payload 
	})
}

export const ActionUpdateEventCalendarSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'updateEventCalendarSetting', 
		payload: payload 
	})
}

export const ActionDestroyEventCalendarSetting = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'destroyEventCalendarSetting', 
		payload: payload 
	})
}

export const ActionCalendarSettingUploadLogo = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'updateCalendarSettingUploadLogo', 
		payload: payload 
	})
}
