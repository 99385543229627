<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :color="color" icon @click.prevent.stop="$emit('click')" v-bind="attrs" v-on="on"><v-icon>{{ icon }}</v-icon></v-btn>
            </template>
            <span> {{ toolTip }} </span>
        </v-tooltip>

    </div>
</template>

<script>

import { myMixin } from '../../../mixins'

export default {
    name: "EmcExcelNewItem",
    mixins: [myMixin],
    data () {
        return {
           
        }
    },
    props: {
        color: {
            type: String,
            default: "indigo darken-1"
        },
        toolTip: {
            type: String,
            default: "Novo Registro"
        },
        icon: {
            type: String,
            default: "mdi-plus"
        },
        attrs: {},
        on: {}
    },
    
    methods: {

       

    }
}
</script>
