import { service } from '../../../helpers/http'

export const ActionFindHourGroupDates = ({ dispatch }, payload) => {

    return service({
        module: 'hourGroupDate',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateHourGroupDate = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroupDate',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateHourGroupDate = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroupDate',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteHourGroupDate = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroupDate',
        action: 'delete',
        payload: payload
    })
    
}

export const ActionFindHourGroupDatesToken = ({ dispatch }, payload) => {

    return service({
        module: 'hourGroupDate',
        action: 'findToken',
        payload: payload
    })
    
}

