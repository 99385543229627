<template>
    <div>
        
        <v-text-field 
            v-model="innerModel"
            v-bind:value="value"
            v-on:input="$emit('input', $event)"
            :error="errorMessages && errorMessages.length > 0" 
            :autofocus="autofocus" 
            :error-messages="errorMessages" 
            :label="label" 
            :dense="dense"
            :required="required"
            :type="type"
            :readonly="code && (!user || !user.is_master) && (!user || !user.is_internal)"
            :persistent-placeholder="persistentPlaceholder"
            :placeholder="placeholder"
            :clearable="clearable"
            :disabled="disabled"
        >
            <template v-if="code" v-slot:append>
                <emc-info-help icon-color="error" title="Informações" :text="textHelper ? textHelper : 'Código externo utilizado para integração com outros sistemas. É permitido alteração apenas pelo administrador.'"" />
            </template>
            <template v-else-if="textHelper" v-slot:append>
                <emc-info-help title="Informações" :text="textHelper" />
            </template>

        </v-text-field>
    </div>
</template>
<script>
import store from '../../store'
export default {
    name: "STextField",
    data () {
        return {
            innerModel: null,
            user: null,
        }
    },
    created(){
        this.innerModel = this.value;
        this.user = store.state.auth.user;
    },
    computed: {
      
    },
    watch: {
        
        value(val_new){
            this.innerModel = val_new;
        },

    },
    computed: {

    },
    props: {
        value: {},
        dense: {
            type: Boolean,
            default: false
       },
       autofocus: {
            type: Boolean,
            default: false
       },
       error: {
            type: Boolean,
            default: false
       },
       errorMessages: {
            type: Array,
       },
       label: {
            type: String,
            default: null,
       },
       textHelper: {
            type: String,
            default: null,
       },
       code: {
            type: Boolean,
            default: false
       },
       required: {
            type: Boolean,
            default: false
       },
       type: {
            type: String,
            default: 'text',
       },
       persistentPlaceholder: {
            type: Boolean,
            default: false
       },
       placeholder: {
            type: String,
            default: null,
       },
       disabled: {
            type: Boolean,
            default: false
       },
       clearable: {
            type: Boolean,
            default: false
       },
       vMaskCnpj: {
            type: Boolean,
            default: false
       }

    },

    methods: {
        
    }
}
</script>
