<template>
    <v-card :outlined="outlined" :prominent="prominent" :width="width"  class="mx-auto elevation-0 ma-1" :style="style">
        <slot></slot>
    </v-card>
</template>
<script>
export default {
    name: "EmcUtilScroll",
    data () {
        return {
            
        }
    },
    computed: {

         style () {
             return 'overflow-y: auto; max-height: '+ this.maxHeight + '; height: ' + this.height;
         }

    },
    props: {
        width: {
            type: String,
            default: "100%"
        },
        maxHeight: {
            type: String,
            default: "auto"
        },
        height: {
            type: String,
            default: "100%"
        },
        outlined: {
            type: Boolean,
            default: false
        },
        prominent: {
            type: Boolean,
            default: false
        }
    },
}
</script>
