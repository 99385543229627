export default [
    {
        path: '/cities',
        name: 'cities',
        component: () => import ('./pages/CityRegister'),
        meta: {
            title: 'Cadastro de municípios',
            icon: '',
            breadcrumbs: [
                { text: 'Municípios', disabled: true, href: '/' },
                { text: 'Cadastro de municípios', disabled: true, href: '/' }
            ],
        }
    },
]