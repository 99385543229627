<template>
    <EmcCardStats 
        color="blue accent-1"
        icon="mdi-account-group-outline"
        icon-large
        :title="this.title"
        :value="this.groups"
        sub-text="Número de turmas cadastradas"
        />
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartGroupNumber',
    data () {
        return {
            groups: '0',
            title: ''
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartGroupNumber']),
        getData() {
            this.ActionChartGroupNumber()
                .then((r) => {
                    this.groups = r.data.value.toString()
                    this.title = r.data.title
                })
        }
    },
    created(){
        this.getData()
    }

    
}
</script>