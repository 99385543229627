<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-city-variant-outline" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            v-if="true || (!model || !model.id)"
                            v-mask="'99999-999'"
                            label="Pesquisar pelo CEP"
                            autofocus
                            v-model="search"
                            v-on:keyup.enter="searchCity()"
                            :loading="loadingFind"
                        >
                            <template v-slot:append>
                                    <v-btn
                                    :disabled="search.length < 9"
                                    @click="searchCity()"
                                    icon
                                    >
                                    <v-icon color="indigo">
                                        mdi-magnify
                                    </v-icon>
                                    </v-btn>
                                </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="8">
                        <v-text-field :disabled="disableFields" :autofocus="model.id == null" :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"> 
                        <sys-select-states
                            :model.sync="model.state_id"
                            :states.sync="states"
                            :error="errors.length > 0"  
                            :errors="errors.state_id"
                            label="Estado*"
                            :disabled="disableFields"
                        />
                    </v-col>
                </v-row>
                <v-row>
                     <v-col cols="12" sm="3">
                        <v-text-field :disabled="disableFields" :error="!!errors.ibge" :error-messages="errors.ibge" v-model="model.ibge" label="Ibge*"></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="3">
                        <v-text-field :disabled="disableFields" :error="!!errors.gia" :error-messages="errors.gia" v-model="model.gia" label="Gia*"></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="3">
                        <v-text-field :disabled="disableFields" :error="!!errors.ddd" :error-messages="errors.ddd" v-model="model.ddd" label="DDD*"></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="3">
                        <v-text-field :disabled="disableFields" :error="!!errors.siafi" :error-messages="errors.siafi" v-model="model.siafi" label="Siafi*"></v-text-field>
                     </v-col>
                </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { http } from "@/http"
import axios from 'axios'

export default {
    name: "SysRegisterCity",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        loadingFind: false,
        search: '',
        states: [],
        message: '',
        disableFields: true,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
                this.search = '';
            }
        },

        search(val){

        }
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Município"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('city', ['ActionUpdateCity', 'ActionCreateCity']),

        searchCity(){
                
            if(this.search.length < 9){
                return;
            }
            
            this.loadingFind = true;
            var cep = this.search.replace('-', '').replace('.', '');
            this.message = '';
            this.error = false;
            this.errors = {};
                
            axios.get('https://viacep.com.br/ws/' + cep + '/json/')
                    .then(res => {
                        
                        if(res.status && res.status == 200){
                            
                            var state = this.states.find(i => i.initials.toUpperCase() == res.data.uf.toUpperCase());
                            this.model.name     = res.data.localidade;
                            this.model.state_id = state ? state.id : null,
                            this.model.ibge     = res.data.ibge;
                            this.model.ddd      = res.data.ddd;
                            this.model.gia      = res.data.gia;
                            this.model.siafi    = res.data.siafi;
                        }else{
                            this.error = true;
                            this.message = 'Cep não Localizado';
                        }
                    })
                    .catch((error) =>{
                        this.error = true;
                        this.message = 'Cep não Localizado';
                    })
                    .finally(() => {
                        this.loadingFind = false;
                    });


               
        },

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateCity(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateCity(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>