import { service } from '../../../../helpers/http'

export const ActionIndexTerms = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'indexTerms', 
		payload: payload 
	})
}

export const ActionCreateTerm = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'createTerm', 
		payload: payload 
	})
}

export const ActionUpdateTerm = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'updateTerm', 
		payload: payload 
	})
}

export const ActionDestroyTerm = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'destroyTerm', 
		payload: payload 
	})
}

export const ActionIndexTermOptions = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'indexTermOptions', 
		payload: payload 
	})
}