var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"sandbox"}},[(_vm.acesso.periodo)?_c('v-navigation-drawer',{attrs:{"clipped":_vm.primaryDrawer.clipped,"floating":_vm.primaryDrawer.floating,"mini-variant":_vm.primaryDrawer.mini,"permanent":_vm.primaryDrawer.type === 'permanent',"temporary":_vm.primaryDrawer.type === 'temporary',"app":_vm.primaryDrawer.app,"overflow":""},model:{value:(_vm.primaryDrawer.model),callback:function ($$v) {_vm.$set(_vm.primaryDrawer, "model", $$v)},expression:"primaryDrawer.model"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.menu),function(item){return [(item.heading)?_c('v-row',{key:item.id,attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[(item.heading)?_c('v-subheader',[_vm._v(_vm._s(item.heading))]):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('a',{staticClass:"body-2 black--text",attrs:{"href":"#!"}},[_vm._v("EDIT")])])],1):(Object.keys(item.children).length > 0)?_c('v-list-group',{key:item.id,attrs:{"prepend-icon":item.model ? item.icon : 'keyboard_arrow_down',"append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]},proxy:true}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},_vm._l((item.children),function(child,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":child.to}},[(child.icon)?_c('v-list-item-action',[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(child.icon))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(child.description)+" ")])]):_c('v-icon',[_vm._v(_vm._s(child.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(child.text))])],1)],1)}),1):_c('v-list-item',{key:item.id,attrs:{"link":"","to":item.to}},[_c('v-list-item-action',[(_vm.primaryDrawer.mini)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(item.icon))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.description))])]):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]})],2)],1):_vm._e(),_c('v-app-bar',{attrs:{"clipped-left":_vm.primaryDrawer.clipped,"app":""}},[(_vm.primaryDrawer.type !== 'permanent' && _vm.acesso.periodo)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.primaryDrawer.model = !_vm.primaryDrawer.model}}}):_vm._e(),(_vm.primaryDrawer.model && _vm.acesso.periodo)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.primaryDrawer.mini = !_vm.primaryDrawer.mini}}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(_vm.primaryDrawer.mini ? 'keyboard_arrow_right' : 'keyboard_arrow_left')}})],1):_vm._e(),_c('v-toolbar-title',[_c('v-img',{attrs:{"src":"/img/sysclass_logo.png","max-width":"150"}})],1),_c('v-spacer'),(false && _vm.user.id)?_c('v-btn',{attrs:{"to":"faq-questions","icon":""}},[_c('v-icon',{attrs:{"color":"indigo","large":""}},[_vm._v("mdi-help-circle-outline")])],1):_vm._e(),(_vm.user.id)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":100,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[(_vm.user.id)?_c('EmcBaseAvatar',{attrs:{"size":36,"src":_vm.user.url_avatar,"text":_vm.user.initials}}):_vm._e(),_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("keyboard_arrow_down")])],1)]}}],null,false,1609194273),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',{attrs:{"max-width":400}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[(_vm.user.id)?_c('EmcBaseAvatar',{attrs:{"size":36,"src":_vm.user.url_avatar,"text":_vm.user.initials}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1)],1),_c('v-divider'),_c('v-list',[(_vm.acesso.instituicao && _vm.acesso.instituicao.id)?_c('v-list-item',{attrs:{"to":"profile"},on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("Meus Dados")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sair()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.exit ? 'mdi-spin mdi-loading' : 'exit_to_app'))])],1),_c('v-list-item-title',[_vm._v("Sair")])],1)],1)],1)],1):_vm._e()],1),_c('sys-core-content'),_c('sys-core-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }