<template>
    
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" small v-on="on" icon :href="urlDownload" target="_blank">
                                    <v-icon small color="success">mdi-download</v-icon>
                                </v-btn>
                            </template>
                            <span> Download Layout </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="dialog = false" :disabled="loading" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="param == 'class_schedules'">
                                <v-col cols="12" sm="6">
                                    <sys-select-hour-group
                                        :model.sync="hour_group_id"
                                        label="Grupo de Horário"
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="showClearItems">
                                <v-col cols="12" sm="6">
                                    <s-switch
                                        v-model="clearItems" 
                                        text-helper='Excluir itens anteriores e manter apenas itens do arquivo que será importado' 
                                        inset 
                                        label="Excluir itens anteriores"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <form ref="form">
                                        <v-file-input
                                            name="file"
                                            v-model="fileUpload"
                                            accept=".xlsx,.xls"
                                            placeholder="Selecione um arquivo excel"
                                            label="Arquivo"
                                            :disabled="param == 'class_schedules' && !hour_group_id"
                                        />
                                    </form>
                                    
                                </v-col>
                            </v-row>
                    </v-container>
                        
                    </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ligth green" @click.prevent.stop="importItem()" :loading="loading" :disabled="loading || fileUpload == null" >Importar<v-icon right dark>mdi-cloud-upload</v-icon></v-btn>
                </v-card-actions>
            </v-card>

        <!-- Alert para mensagens -->
        <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

    </v-dialog>

</template>

<script>

import { mapActions } from 'vuex'
import store from '../../../modules/auth/store'

export default {
    name: "EmcExcelUserImport",
    data: () => ({
        dialog: false,
        fileUpload: null,
        loading: false,
        message: '',
        hour_group_id: null,
        clearItems: false,
        errors: [],
        error: false,
        token: null,
    }),
    created(){
        
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.fileUpload = null;
                this.errors = [];
            }
        },

        fileUpload(val){

            if(!val){
                this.errors = [];
            }

        }
        
    },
    computed: {

        urlDownload(){
            
            let url = process.env.VUE_APP_ROOT_API + 'user_imports/' + this.param +  '/download-layout?token=' + store.state.token;

            if(this.layout){
                url += '&layout=' + this.layout;
            }
            
            return url;
        }

    },
    props: {
        
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "40%"
        },
        icon: {
            type: String,
            default: "mdi-cloud-upload"
        },
        title: {
            type: String,
            default: 'Importação'
        },
        show: {
            type: Boolean,
            default: false
        },
        showClearItems: {
            type: Boolean,
            default: false
        },
        param: {
            type: String,
            required: true
        },
        layout: {
            type: String,
            default: null
        },
        itemId: {

        }
    },
    methods:{
        
        ...mapActions('user', ['ActionUserImportItem']),

        importItem(){

            this.errors = [];
            this.error = false;
            this.message = '';
            this.loading = true;
            let formData = new FormData(this.$refs.form);
            formData.append('param', this.param);
            formData.append('params', JSON.stringify({ 
                hourGroupId:    this.hour_group_id,
                clearItems:     this.clearItems,
                layout:         this.layout,
                itemId:         this.itemId,
                description:    this.layout,
            }));

            this.ActionUserImportItem(formData)
                .then((res) => {
                    this.$emit('onCompleted', res);
                    this.dialog = false;
                }).catch(error => {

                    this.error = true;
                    this.message = error.message;

                }).finally(() => {
                    this.loading = false;
                });

        },
        
    }
}
</script>