export default {
    
    indexDynamicAttributions:           { method: 'get',    url: 'dynamic_attributions' },
    createDynamicAttribution:           { method: 'post',   url: 'dynamic_attributions'},
    updateDynamicAttribution:           { method: 'put',    url: 'dynamic_attributions/{id}'},
    deleteDynamicAttribution:           { method: 'delete', url: 'dynamic_attributions/{id}'},
    indexDynamicAttributionTypes:       { method: 'get',    url: 'dynamic_attributions/types' },
    generateDynamicAttribution:         { method: 'put',    url: 'dynamic_attributions/{id}/generate' },
    syncDynamicAttribution:             { method: 'put',    url: 'dynamic_attributions/{id}/sync' },
    showDynamicAttributions:            { method: 'get',    url: 'dynamic_attributions/{id}' },
    showDynamicAttributionViewResults:  { method: 'get',    url: 'dynamic_attributions/{id}/view-results' },
    updateAllDynamicAttribution:        { method: 'post',   url: 'dynamic_attributions/{id}/update-all' }
    
}