<template>
    <v-container>
          <v-row>
            <v-col cols="12" sm="4">
                <v-card tile class="elevation-1">
                    <v-toolbar class="elevation-1" color="grey lighten-3" height="40">
                        <v-toolbar-title>Telefones</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field 
                                    autofocus 
                                    append-icon="mdi-phone"
                                    dense :error="errors.length > 0" 
                                    v-mask-phone.br 
                                    :error-messages="errors.mobile_phone" 
                                    v-model="model.mobile_phone" 
                                    label="Celular 1"
                                >
                                </v-text-field>
                            </v-col>
                        <v-row>
                        </v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field append-icon="mdi-phone" dense :error="errors.length > 0" v-mask-phone.br :error-messages="errors.mobile_phone2" v-model="model.mobile_phone2" label="Celular 2"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card tile class="elevation-1">
                    <v-toolbar class="elevation-1" color="grey lighten-3" height="40">
                        <v-toolbar-title>Meus Links</v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field clearable append-icon="mdi-linkedin" dense :error="errors.length > 0" :error-messages="errors.url_linkedin" v-model="model.url_linkedin" label="Linkedin"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field clearable append-icon="mdi-school-outline" dense :error="errors.length > 0" :error-messages="errors.url_lattes" v-model="model.url_lattes" label="Currículo Lattes"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="mx-auto">
                        <v-toolbar color="grey lighten-2" height="40">
                            <v-toolbar-title>Emails</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field ref="addmail" v-model="mail" type="email" placeholder="Adicionar e-mail" :error="errors.length > 0" :error-messages="errors.emails">
                                <template v-slot:append-outer>
                                    <v-btn @click="addMail()" color="green white--text" :disabled="!mail" fab x-small>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-card-text>
                        <v-card-text>
                            <v-list>
                                <v-list-item v-for="(email, i) in emails" :key="i">
                                     <emc-info-help v-if="!email.allow_editing" title="E-mail Institucional" text="Não é permitido edição!" />
                                    <v-list-item-content>
                                        <v-text-field
                                            v-model="email.email"
                                            :error="errors.length > 0"  
                                            :error-messages="errors['emails.' + i + '.email']"
                                            dense
                                            :disabled="!email.allow_editing"
                                        />
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :disabled="!email.allow_editing" v-bind="attrs" v-on="on" @click="removeMail(email, i)" color="red" icon x-small>
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Excluir email?</span>
                                        </v-tooltip>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                    </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-spacer></v-spacer>
                <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                    <v-btn :loading="loading" v-on="on" color="primary" dark @click="save()">
                        <v-icon dark>save</v-icon>
                            Salvar
                    </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherUpdateData",
    data: (vm) => ({
        error: false,
        errors: [],
        loading: false,
        mail: null,
        emails: [],
        avatar: null,
        
    }),
    created(){
      this.setData();
    },

    watch: {
        model(){
            this.setData();
        }
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    props: {
        model: {},
        token:{
            type: String,
            default: null
        },
    },
    methods:{
        ...mapActions('teacher', ['ActionUpdateTeacher']),
        
        setData(){
            this.error =    false;
            this.errors =   [];
            this.mail =     null;
            this.emails =   this.model.emails;
        },
        
        save(){

            this.error = false;
            this.errors = [];
            
            if(this.loading){
                return;
            }
            
            if(!this.model || !this.model.id || this.model.id == 0){
                return;
            }

            this.loading = true;
            
            var payload = {
                emails:         this.emails,
                token:          this.token,
                updateData:     true,
            };

            Object.assign(payload, this.model);

            delete payload.availabilities;
            delete payload.institution;

            this.ActionUpdateTeacher(payload)
                .then((res) => {
                    this.$emit('onComplete', { text: 'Dados atualizados com sucesso!', error: false  });
                })
                .catch((error) =>{
                    this.error = true;
                    this.errors = error.errors;
                    this.$emit('onComplete', { text: 'Erro ao atualizar os dados!', error: true  });
                })
                .finally(() => {
                    this.loading = false;
                });
            
        },

        addMail(){
            this.emails.push({
                id:             0,
                institution_id: this.model.institution_id,
                teacher_id:     this.model.id,
                email:          this.mail,
                is_main:        true,
                allow_editing:  true,
            });
            this.mail = null;
            this.$refs.addmail.focus();
        },

        removeMail(mail, i){
            this.emails.splice(i, 1);
            this.errors = [];
        },
    }
}
</script>