import { service } from '../../../../helpers/http'

export const ActionIndexBranches = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'indexBranches', 
		payload: payload 
	})
}

export const ActionCreateBranch = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'createBranch', 
		payload: payload 
	})
}

export const ActionUpdateBranch = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'updateBranch', 
		payload: payload 
	})
}

export const ActionDestroyBranch = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'destroyBranch', 
		payload: payload 
	})
}