<template>
    <div>
        <EmcChartLoading v-if="loading" />

        <v-card v-show="!loading" class="pa-2">
            <canvas :id="name" :width="width" :height="height"></canvas>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <!-- <v-tooltip v-if="showMoreButton" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMore', itemId)">
                                <v-icon btn>mdi-monitor-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Ver Mais...
                        </span>
                    </v-tooltip> -->
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions } from 'vuex'

export default {
    name: 'EmcChartEvolutionOfWorkload',
    data () {
        return {
            loading: true,
            name: 'EvolutionOfWorkload',
            width: '400',
            height: '240',
            chartData: [],
            title: '',
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            boxWidth: 10,
                            usePointStyle: true
                        }
                    },
                    title: {
                        display: true,
                        text: ''
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            borderDash: [2, 2],
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            borderDash: [2, 2],
                        }
                    }
                }
            }
        }
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: Number,
        },
    },
    methods: {
        ...mapActions('chart', ['ActionChartEvolutionOfWorkload']),
        async getData(){
            await this.ActionChartEvolutionOfWorkload()
                .then((r) => {
                    this.chartData = r.data
                    this.chartOptions.plugins.title.text = r.data['title']
                })
            this.getChart()
        },
        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }
            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });
            
            var speedCanvas = document.getElementById(this.name)
            new Chart(speedCanvas, {
                        type: 'line',
                        data: this.chartData,
                        options: this.chartOptions
                    });
            this.loading = false
        }
    },
    created(){
        this.getData();
    }
}
</script>