export default [
    {
        path: '/regions',
        name: 'regions',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Regiões',
            icon: 'mdi-google-maps',
            breadcrumbs: [
                { text: 'Lista de Regiões', disabled: true }
            ]
        }
    }
]