<template>
    <v-card outlined>
        <v-toolbar v-if="title" :color="colorToolbar" :height="heightToolbar" :class="classToolbar">
            <v-toolbar-title :class="classTitle"><v-icon v-if="icon" :small="small" :medium="medium" :large="large">{{icon}}</v-icon> {{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="tools" />
            <v-tooltip bottom v-if="dialog">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click.native="dialog = false" :disabled="updating" v-bind="attrs" v-on="on">
                        <v-icon color="red lighten-2">mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Fechar</span>
            </v-tooltip>
        </v-toolbar>
        <v-card-text>
            <slot name="card-text"></slot>
        </v-card-text>
        <v-card-actions>
            <slot name="card-actions"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'EmcBaseCard',
    data: () => ({}),
    props: {
        title: {
            type: String,
            default: '',
            required: false
        },
        dialog: {
            type: Boolean,
            default: false,
            required: false
        },
        icon: {
            type: String,
            default: '',
            required: false
        },
        heightToolbar: {
            type: Number,
            default: 40,
            required: false
        },
        classToolbar: {
            type: String,
            default: '',
            required: false
        },
        colorToolbar: {
            type: String,
            default: 'grey lighten-4',
            required: false
        },
        classTitle: {
            type: String,
            required: false,
            default: 'subtitle-1'
        },
        small: {
            type: Boolean,
            default: false,
            required: false
        },
        medium: {
            type: Boolean,
            default: false,
            required: false
        },
        large: {
            type: Boolean,
            default: false,
            required: false
        }
    }
}
</script>