import { service } from '../../../../helpers/http'

export const ActionIndexGroupIdentifiers = ({ dispatch }, payload ) => {

    return service({
        module: 'group',
        action: 'indexGroupIdentifiers',
        payload: payload
    })

}
export const ActionDestroyGroupIdentifier = ({ dispatch }, payload ) => {

    return service({
        module: 'group',
        action: 'destroyGroupIdentifier',
        payload: payload
    })
}
export const ActionStoreGroupIdentifier = ({ dispatch }, payload ) => {
    return service({
        module: 'group',
        action: 'storeGroupIdentifier',
        payload: payload
    })

}
export const ActionUpdateGroupIdentifier = ({ dispatch }, payload ) => {

    return service({
        module: 'group',
        action: 'updateGroupIdentifier',
        payload: payload
    })
}