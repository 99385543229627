export default [
    {
        path: '/consistencies',
        name: 'consistencies',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Verificação de Consistências',
            icon: 'mdi-marker-check',
            breadcrumbs: [
                { text: 'Verificação de Consistências', disabled: true }
            ]
        }
    }
]