<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        :maxWidth="maxWidth"
        :showRequiredFields="false"
    >
        <template v-slot:form>
            <v-container>
                <v-row class="mt-0">
                    <v-col cols="12" sm="12">
                        <v-autocomplete
                            v-model="selectedGrid"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            append-icon="mdi-magnify"
                            @click:append="searchGrids(search)"
                            v-on:keyup.enter="searchGrids(search)"
                            hide-no-data
                            hide-selected
                            item-text="name"
                            label="Pesquisar pela turma ou disciplina..."
                            return-object
                            autofocus
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="3">
                        <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <s-text-field text-helper="Código utilizado para identificar junções que pertecem ao mesmo grupo" :error-messages="errors.code_link" v-model="model.code_link" label="Código Cluster" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <sys-select-join-category
                            :model.sync="model.join_category_id"
                            label="Categoria"
                            showClear
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <sys-select-join-type
                            :model.sync="model.join_type_id"
                            label="Tipo"
                        />
                    </v-col>
                    <v-col cols="12" sm="3" v-if="settings && settings.allow_replicate_teacher_join">
                        <v-switch inset v-model="model.replicate_teacher" label="Replicar Professor"></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12"> 
                        <v-card outlined height="50%">
                            <v-app-bar color="grey lighten-2 grey--text text--darken-1" class="elevation-0" dense>
                                <v-toolbar-title>
                                    Turmas da junção <span v-if="getTotalStudents() > 0">({{ getTotalStudents() }} Alunos)</span>
                                </v-toolbar-title>
                            </v-app-bar>
                            <v-data-table
                                v-model="selected"
                                :headers="headers"
                                :items="grids"
                                :single-select="false"
                                item-key="id"
                                show-select
                                :item-selected="selected"
                                class="elevation-0"
                                hide-default-footer
                                height="300"
                                :items-per-page="-1"
                                fixed-header
                            >
                                <template v-slot:item.group="{ item }">
                                    <span>
                                        <span v-if="model && model.id">
                                            <v-icon 
                                                v-if="item.join && item.join.main"
                                                style="cursor: default;"
                                                color="amber"
                                                small
                                            >
                                                mdi-star-half-full
                                            </v-icon>
                                            <v-icon 
                                                v-else
                                                color="grey"
                                                small
                                                @click="setMain(item)"
                                            >
                                                mdi-star-outline
                                            </v-icon>
                                        </span>
                                        {{ item.group.name }}
                                        <small v-if="item.subGroup"><br/>{{ item.subGroup.name }}</small>
                                    </span>
                                </template>

                                    <template v-slot:item.work_load="{ item }">
                                    <span>{{ item.work_load_type.initials + '=' + item.work_load }}</span>
                                </template>

                                <template v-slot:item.join="{ item }">
                                    <span class="red--text" v-if="item.join && item.join.join_id !== model.id && item.join.join_id > 0">{{ 'Essa disciplina já faz parte da junção: ' + item.join.join_id }}</span>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            
            <v-alert v-if="errors.length > 0" type="error">
                <ul>
                    <li :key="error" v-for="error in errors">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

        </template>

        <template v-if="model && model.id" v-slot:actions>
            <v-icon 
                color="amber"
                small
            >
                mdi-star-half-full
            </v-icon>
            <small>Turma Principal</small>
            <v-spacer></v-spacer>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterJoin",
    data: (vm) => ({
        selectedGrid: {},
        search: '',
        dialog: false,
        error: false,
        errors: {},
        message: '',
        isLoading: false,
        items: [],
        grids: [],
        loading: false,
        selected: [],
        headers: [
          { text: 'Campus', align: 'left', value: 'group.campus.abbreviation' },
          { text: 'Turma', align: 'left', value: 'group' },
          { text: 'Disciplina', align: 'left', value: 'discipline_name' },
          { text: 'Professor', align: 'left', value: 'teacher.name' },
          { text: 'CH', align: 'left', value: 'work_load' },
          { text: 'Alunos', align: 'left', value: 'students' },
          { text: 'Mensagem', align: 'left', value: 'join', sortable: false}
        ],
        
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {  
        
        selectedGrid(val){
            
            if(Object.keys(val).length > 0){
                
                var grids2 = this.grids.filter((f) => {
                    return f.id == val.id
                });

                if(Object.keys(grids2).length == 0){
                    
                    var grids3 = this.grids;
                    this.grids = [];
                    
                    this.grids.push(val);
                    
                    grids3.forEach(g => {
                        this.grids.push(g);
                    });

                    this.selected.push(val);
                    
                }

                this.selectedGrid = {}
            }
            
        },
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },
        
        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.errors = {};
                this.selected = [];
                this.search = null;
                this.items = [];
                this.grids = [];

                if(this.model.id){
                    
                    if(this.model.grids){
                        this.model.grids.forEach(g => {
                            let grid = JSON.parse(JSON.stringify(g));
                            this.grids.push(grid);
                        });

                        Object.assign(this.selected, this.grids);
                    }
                }else{
                    this.model.code = null;
                    this.model.status_join_id = 1;
                    this.model.join_type_id = 1;
                    this.model.join_category_id = null;
                    this.model.work_time_id = this.acesso.periodo.id;
                    this.model.replicate_teacher = 1;
                }
            }
        },
    },
    props: {
        model: {
            default: null
        },
        maxWidth: {
            type: String,
            default: "70%"
        },
        icon: {
            type: String,
            default: "mdi-merge"
        },
        title: {
            type: String,
            default: "Alterar junção"
        },
        show: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            default: null
        }

    },
    methods:{
        ...mapActions('join', ['ActionCreateJoin', 'ActionUpdateJoin']),
        ...mapActions('grid', ['ActionFindGrids']),
        
        setMain(grid){
            
            this.grids.forEach(g => {
                
                if(g.join){
                    g.join.main = g.id == grid.id ? 1 : 0;
                }else{
                    if(g.id == grid.id){
                        g.join = { 
                            grid_id:    g.id,
                            main:       g.id == grid.id ? 1 : 0
                        };
                    }
                }
            });
        },
        
        searchGrids(){
            var payload = {
                search: this.search,
                page: 1,
                per_page: 500,
                showAllCampuses: true,
                with: 'group.campus,group.shift,discipline,teacher,workLoadType,subGroup',
                withName: true
            };
            this.isLoading  = true;
            this.items = [];
            
            this.ActionFindGrids(payload)
                .then((res) => {
                    this.items = res.data;
                    this.search = null;
                })
                .finally(() => {
                    this.isLoading  = false;
                });
        },
        save()
        {  
            this.error = null;
            this.message = '';
            this.loading = true;
            var edit = this.model.id ? true : false;
            var method = edit ? 'ActionUpdateJoin' : 'ActionCreateJoin';

            var payload;
            var grids = [];

            this.selected.forEach(grid => {
                
                if(!grid.join){
                    
                    grid.join = {
                        grid_id: grid.id,
                        main: 0
                    }
                }

                grids.push(grid.join);
            });

            if(edit){
                payload = this.model;
                payload.grids = grids;
            }else{
                payload = { joins: [] };
                this.model.work_time_id = this.acesso.periodo.id;
                this.model.grids = grids;
                payload.joins.push(this.model);
            }

            payload.showAllCampuses = true;
            
            this[method](payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    
                    if(error.errors.grids){
                        this.errors = error.errors.grids;
                    }
                    
                })
                .finally(() => {
                        this.loading = false;
                });
            
        },

        getTotalStudents(){

            var count = 0;

            this.selected.forEach(g => {
                count += g.students;
            });

            return count;

        }

    }
}
</script>