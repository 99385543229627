<template>

    <v-container fluid>
        <v-row v-if="find">
            <v-col cols="12">
                <v-text-field
                    solo
                    :label="label"
                    append-icon="mdi-magnify"
                    autofocus
                    v-model="search"
                    @click:append="searchItems()"
                    v-on:keyup.enter="searchItems()"
                >
                    <template v-slot:prepend-inner>
                        <slot name="optionsFilter"/>
                    </template>
                
                    <template v-if="showAppend" v-slot:append>
                        <slot name='append'/>
                        <v-tooltip  v-if="showFilter" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" :color="payloadFilter ? 'primary' : 'grey'" v-on="on" icon @click.prevent.stop="$emit('click-filter')">
                                    <v-icon btn>{{ payloadFilter ? 'mdi-filter-menu-outline' : 'mdi-filter-outline'}}</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                Demais Filtros
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click.prevent.stop="searchItems()">
                                    <v-icon btn>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>
                            Pesquisar
                            </span>
                        </v-tooltip>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>

        <v-data-table
                v-model="selected"
                :headers="headerItems"
                :items="collections"
                :options.sync="options"
                :server-items-length="total"
                :page.sync="meta.page"
                :items-per-page="options.itemsPerPage"
                @page-count="count = $event"
                :footer-props="footerProps"
                :loading="loadingText.length > 0"
                :loading-text="loadingText"
                fixed-header
                height="400"
                :custom-filter="customFilter"
                :single-select="singleSelect"
                :show-select="showSelect"
            >

            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>

            <!-- Trata o formato para todo campo do tipo data  headersDate é um filtro definido no computed -->
            <template v-for="(h, index) in headersDate" v-slot:[`item.${h.value}`]="{ item }">
                <span :key="index" v-if="h.type =='date'">
                    <span v-if="h.range">
                        {{ item[h.value[0]] | date }}<span v-if="item[h.value[1]]"><br v-if="h.breakrow"/><span v-else> - </span> {{ item[h.value[1]] | date }}</span>
                    </span>
                    <span v-else>
                        {{ item[h.value] | date }}
                    </span>
                    
                </span>
                <span :key="index" v-else-if="h.type =='dateTime'">{{ item[h.value] | dateTime }}</span>
                <span :key="index" v-else-if="h.type =='icon'"><v-icon>{{ item[h.value] }}</v-icon></span>
                <span :key="index" v-else-if="h.type =='html'"><span v-html="item[h.value]"></span></span>
                <span :key="index" v-else-if="h.type =='boolean'">
                    <span v-if="showIconBoolean">
                        <v-icon small v-if="item[h.value]" color="success">mdi-check-bold</v-icon>
                        <v-icon small v-else color="error">mdi-close-thick</v-icon>
                    </span>
                    <span v-else>
                        {{ item[h.value] | boolean }}
                    </span>
                </span>
                <span :key="index" v-else-if="h.type =='time'">
                    <span v-if="h.range">
                        {{ item[h.value[0]] | time }} - {{ item[h.value[1]] | time }}
                    </span>
                    <span v-else>
                        {{ item[h.value] | time }}
                    </span>
                    
                </span>
                <span :key="index" v-else-if="h.type =='array'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <span v-bind="attrs" v-on="on" style="cursor: help;">{{ item[h.value].map(o => o['initials']).join(', ') }}</span>
                        </template>
                        <span> {{ item[h.value].map(o => o['name']).join(', ') }} </span>
                    </v-tooltip>              
                </span>
                <span :key="index" v-else-if="h.type =='money'">
                    {{ item[h.value] | money }}                    
                </span>
                <span :key="index" v-else-if="h.type =='initials'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <span v-bind="attrs" v-on="on" style="cursor: help;">{{ item[h.value]['initials'] }}</span>
                        </template>
                        <span> {{ item[h.value]['name'] }} </span>
                    </v-tooltip>                             
                </span>
            </template>

          <!--
          <template v-slot:header.active="{ header }" class="text-center">
            {{ header.text }}
          </template>
          -->

            <template v-slot:item.active="{ item }">

                <v-chip class="ma-2" :color="item.active ? `green` : `red`" label outlined small>
                  <v-icon small left>{{ item.active ? `mdi-check-circle` : `mdi-circle-off-outline` }}</v-icon>
                  {{ item.active ? `Ativo` : `Inativo`}}
                </v-chip>

            </template>

            <template v-if="showActions" v-slot:item.actions="{ item }">

                <slot name="actions" :item="item"></slot>
                
                <emc-table-icon-edit    v-if="!hideEdit"    :icon="iconEdit" :tool-tip="toolTipEdit" @click="updateItem(item)" />
                <emc-table-icon-delete  v-if="showDelete"   @click="deleteItem(item)" />

            </template>

        </v-data-table>

        <emc-loader-progress :show="loading"/>
        
    </v-container>
</template>

<script>
export default {
    name: "EmcTableItems",
    data: () => ({
        count: 0,
        page: 1,
        selected: [],
        search: '',
        footerProps: {
            'items-per-page-options': [ 5, 10, 15, 30, 50]
        },
        options: {
            itemsPerPage: 5
        },
        total: 0,
        itemRemove: {},
        itemUpdate: {},
    }),
    computed: {

        headerItems: function () {
          this.headers.forEach((h) => {
            if(h.value === 'active' || h.type === 'boolean') {
              h.align = 'center';
            }
            if(h.value === 'actions') {
              h.align = 'center';
              h.sortable = false;
            }
            if(h.value.includes('.')){
                h.sortable = false;
            }
          })

          return this.headers
        },
        headersDate: function() {
            return this.headers.filter(function(u) {
                
                if(u.type === 'date'){
                    return u.type === 'date';
                }

                if(u.type === 'dateTime'){
                    return u.type === 'dateTime';
                }

                if(u.type === 'percent'){
                    return u.type === 'percent';
                }

                if(u.type === 'boolean'){
                    return u.type === 'boolean';
                }

                if(u.type === 'time'){
                    return u.type === 'time';
                }

                if(u.type === 'icon'){
                    return u.type === 'icon';
                }

                if(u.type === 'html'){
                    return u.type === 'html';
                }

                if(u.type === 'array'){
                    return u.type === 'array';
                }

                if(u.type === 'money'){
                    return u.type === 'money';
                }

                if(u.type === 'initials'){
                    return u.type === 'initials';
                }
                   
            })
        },
    },
    watch: {
        
        meta(meta_new, meta_old) {
            this.total = meta_new.total
        },
        options(options_new, options_old) {
            if(options_new !== options_old) {
                this.setParams()
            }
        },
        
        itemRemove(item_remove_new, item_remove_old) {
            if(item_remove_new !== item_remove_old) {
                this.$emit('update:itemDelete', item_remove_new)
            }
        },
        itemDelete(item_delete_new, item_delete_old) {
            if(item_delete_new !== item_delete_old) {
                this.itemRemove = item_delete_new
            }
        },
        
        itemUpdate(item_update_new, item_update_old) {
            if(item_update_new != item_update_old) {
                this.$emit('update:itemEdit', item_update_new)
            }
        },

        itemEdit(item_edit_new, item_edit_old) {
            if(item_edit_new !== item_edit_old) {
                this.itemUpdate = item_edit_new
            }
        },
        
        selected(val){
            this.$emit('update:selectedItems', val);
        },

        selectedItems(val){
           this.selected = val;
        }
    },

    methods: {
        
        updateItem(item){
            
            var newObject = {};
            Object.assign(newObject, item);
            this.itemUpdate = newObject;

        },

        deleteItem(item){
            
            var newObject = {};
            Object.assign(newObject, item);
            this.itemRemove = newObject;

        },
        
        searchItems() {
            
           this.options.page = 1;
           
           if(this.search.length >= this.searchLength || this.search.length === 0) {
                this.setParams()
           }
            
        },

        
        setParams() {
            
            this.$emit('update:collections', [])

            const columnOrder  = this.options.sortBy.length ? this.options.sortBy[0] : null;
            const direction     = (this.options.sortDesc.length && this.options.sortDesc[0]) ? 'desc' : 'asc'

            const params = {
                search: this.search,
                column_order: columnOrder,
                direction: direction,
                page: this.options.page,
                per_page: this.options.itemsPerPage
            }

            this.$emit('update:params', params)
        },
    },
    props: {
        collections: {
            type: Array
        },
        headers: {
            type: Array
        },
        meta: {
            type: Object,
            required: false
        },
        params: {
            type: Object
        },
        find: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        itemDelete: {
            type: Object,
            required: false
        },
        itemEdit: {
            type: Object,
            required: false
        },
        selectedItems: {
            type: Array,
            required: false
        },
        label: {
            type: String,
            default: 'Pesquisar...'
        },
        showActions: {
            type: Boolean,
            default: true
        },
        showAppend: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: ''
        },
        searchLength:{
            type: Number,
            default: 4
        },
        showDelete: {
            type: Boolean,
            default: true
        },
        hideEdit: {
            type: Boolean,
            default: false
        },
        iconEdit: {
            type: String,
            default: "mdi-pencil-outline"
        },
        toolTipEdit: {
            type: String,
            default: 'Editar registro'
        },
        showIconBoolean: {
            type: Boolean,
            default: true
        },
        customFilter: {  },
        showFilter: {
            type: Boolean,
            default: false,
        },
        payloadFilter: {
            default: null
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        showSelect: {
            type: Boolean,
            default: false,
        }
    }
}
</script>