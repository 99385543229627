<template>
    <form ref="form">
        <v-file-input
            name="file"
            v-model="fileUpload"
            :accept="accept"
            :placeholder="placeholder"
            :label="label"
            :disabled="disabled || loading"
        >
            <template v-slot:append>
                <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small @click.prevent.stop="upload()" :loading="loading" :disabled="loading || fileUpload == null" >
                            <v-icon color="ligth green" right dark>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </template>
                    <span>Fazer upload do arquivo</span>
                </v-tooltip>
            </template>
        </v-file-input>
    </form>
</template>

<script>
import { mapActions } from 'vuex'
  export default {
    name: "EmcFileUpload",
    data: () => ({
        fileUpload: null,
        loading: false,
        errors: [],
        error: false,
        message: '',
    }),
    props: {
        accept: {
            Type: String,
            default: null,
        },
        placeholder: {
            Type: String,
            default: 'Selecione um arquivo',
        },
        label: {
            Type: String,
            default: 'Arquivo',
        },
        type: {
            Type: String,
            required: true,
        },
        itemId: {
            Type: Number,
            required: true,
        },
        token: {
            Type: String,
            default: null,
        },
        disabled: {
            Type: Boolean,
            default: false,
        }
    },
    methods: {
        ...mapActions('controlPanel', ['ActionUpload', 'ActionUploadToken']),

        upload(){

        this.errors = [];
        this.error = false;
        this.message = '';
        this.loading = true;
        var method = this.token ? 'ActionUploadToken' : 'ActionUpload';
        let formData = new FormData(this.$refs.form);
        formData.append('type', this.type);
        formData.append('id', this.itemId);
        
        if(this.token){
            formData.append('token', this.token);
        }

        this[method]( formData )
            .then((res) => {
                this.dialog = false;
                this.$emit('onCompleted', res.data);
            }).catch(error => {
                this.error = true;
                this.errors = error.errors;
                this.message = error.message;
            }).finally(() => {
                this.loading = false;
                this.fileUpload = null;
            });

        },


    },
  }
</script>
