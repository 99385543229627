import { default as teachers } from './teachers/services'
import { default as approval } from './approval/services'

export default {
    ...teachers,
    ...approval,
    activityItems:                  { method: 'get',    url: 'activity_items' },
    createActivityItem:             { method: 'post',   url: 'activity_items'},
    updateActivityItem:             { method: 'put',    url: 'activity_items/{id}'},
    deleteActivityItem:             { method: 'delete', url: 'activity_items/{id}'},
    activityItemStatus:             { method: 'get',    url: 'activity_items/status' },
    activityItemEntryTypes:         { method: 'get',    url: 'activity_items/entry_types' },
    deleteActivityItemTimeTable:    { method: 'delete', url: 'activity_item_time_tables/{id}'},
    updateActivityItemTeacher:      { method: 'put',    url: 'activity_items/{id}/teacher'},
}