<template>
  <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="model"
      :title="title"
      :icon="icon"
      v-bind:show.sync="dialog"
      maxWidth="50%"
  >
    <template v-slot:form>
      <v-row class="mt-2">
        <v-col cols="12" sm="8">
          <v-text-field :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <SysSelectPlanType
              :model.sync="model.plan_type_id"
              :error="errors.length > 0"
              :errors="errors.plan_type_id"
          />
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field dense :error="errors.length > 0" :error-messages="errors.price" v-model="model.price" label="Preço*" v-mask-decimal.br="2" maxlength="6">
            <template v-slot:prepend-inner>
              <strong>R$</strong>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea label="Descrição" v-model="model.description"></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
        <v-card outlined>
          <v-app-bar dense elevation="0">
            <v-toolbar-title class="text-subtitle-1"><v-icon class="mr-1" small>mdi-layers-triple</v-icon> Módulos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch v-model="selectAll" label="Todos" hide-details inset dense></v-switch>
          </v-app-bar>
          <v-card-text>
            <v-alert dense outlined color="red" class="text-center caption" v-if="errors.modules">
              <div v-for="(message, i) in errors.modules" :key="i">{{ message }}</div>
            </v-alert>
            <v-container fluid>
              <v-row justify="center">
                <v-col cols="auto" v-for="(module, i) in modules" :key="i">
                  <v-switch v-model="model.modules" :label="module.name" :value="module" hide-details inset dense></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
        <v-card outlined>
          <v-app-bar elevation="0">
            <v-toolbar-title class="text-subtitle-1"><v-icon class="mr-1" small>mdi-cash-multiple</v-icon> Tipos de cobrança</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="5">
              <SysSelectChargeType
                  :model.sync="chargeTypeId"
                  hint="Selecione o tipo de cobrança"
              />
            </v-col>

          </v-app-bar>
          <v-card-text>
            <div v-if="model.charge_types.length === 0" class="text-caption text-center">A cobrança padrão será por turma.</div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">Tipo</th>
                    <th class="text-left">Preço</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(charge, i) in model.charge_types"
                      :key="i"
                  >
                    <td>{{ charge.type.name }}</td>
                    <td>
                      <v-text-field dense :error="errors.length > 0" :error-messages="errors.price" v-model="charge.price" v-mask-decimal.br="2" maxlength="6"></v-text-field>
                    </td>
                    <td><v-btn icon @click.stop="deleteChargePlan(charge)"><v-icon color="red">mdi-delete-outline</v-icon></v-btn></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-switch
              v-model="model.active"
              color="primary"
              label="Ativo"
              inset
              dense
          ></v-switch>
        </v-col>
      </v-row>
    </template>
  </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: "SysRegisterPlan",
  data: (vm) => ({
    dialog: false,
    selectAll: false,
    error: false,
    errors: [],
    message: '',
    loading: false,
    title: 'Plano',
    icon: 'mdi-animation',
    modules: [],
    chargePlanTypes: [],
    chargeTypeId: null
  }),
  watch: {
    chargeTypeId(chargePlanType) {
      if(chargePlanType) {

        let hasCharge = this.model.charge_types.find((charge) => { return charge.charge_type_id == chargePlanType.id })

        if(!hasCharge) {
          this.model.charge_types.push({
            charge_type_id: chargePlanType.id,
            type: chargePlanType,
            price: null
          })
        }

      }
    },
    dialog(dialog_new, dialog_old) {
      if(dialog_new !== dialog_old) {
        this.$emit('update:show', dialog_new)
      }
    },
    show(show_new, show_old) {
      this.dialog = show_new;
      if(show_new){
        this.error = false;
        this.errors = {};
      }
    },
    selectAll(select) {
      if(select) {
        this.model.modules = this.modules
      } else {
        this.model.modules = []
      }
    }
  },
  props: {
    model: {
      default: null
    },
    color: {
      type: String,
      default: 'grey lighten-5'
    },
    width: {
      type: String,
      default: "500"
    },
    maxWidth: {
      type: String,
      default: "80%"
    },
    text: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.getModules()
  },
  methods:{
    ...mapActions('plan', ['ActionUpdatePlan', 'ActionStorePlan', 'ActionIndexModule']),
    getModules() {
        const params = {
          column_order: 'name'
        }
        this.ActionIndexModule(params)
          .then((r) => {
            this.modules = r.data
          })
    },
    deleteChargePlan(item) {

      let index = this.model.charge_types.indexOf(item)

      if (index > -1) {
        this.model.charge_types.splice(index, 1);
      }

    },
    save(edit)
    {
      this.error = false;
      this.message = '';
      this.loading = true;

      if(edit) {
        this.ActionUpdatePlan(this.model)
            .then((res) => {
              this.message = res.message;
              this.$emit('onCompleted');
            })
            .catch((error) =>{
              this.error      = true;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false;
            })
      } else {
        this.ActionStorePlan(this.model)
            .then((res) => {
              this.message = res.message;
              this.$emit('onCompleted', res.data);
            })
            .catch((error) =>{
              this.error      = error;
              this.errors     = error.errors;
              this.message    = error.message;
            })
            .finally(() => {
              this.loading = false;
            })

      }
    },

  }
}
</script>