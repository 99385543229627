import { service } from '../../../../helpers/http'

export const ActionIndexActivityCategories = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'indexActivityCategories',
        payload: payload
    });
}

export const ActionCreateActivityCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'createActivityCategory',
        payload: payload
    });
}

export const ActionUpdateActivityCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'updateActivityCategory',
        payload: payload
    });
}

export const ActionDeleteActivityCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'deleteActivityCategory',
        payload: payload
    });
}
