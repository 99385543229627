<template>
    <div>
        <sys-pop-over-config-base :model="model" :configs="configs" :errors="errors">
            
            <template v-if="model.settings" v-slot:custom>
                <sys-select-activity-item-status
                  :model.sync="model.settings.activity_item_status"
                  :error="errors.length > 0"  
                  :errors="errors.activity_item_status_id"
                  label="Status Atividade para pagamento"
                  multiple
              /> 
            </template>

        </sys-pop-over-config-base>
    </div>
</template>

<script>

export default {
    name: "SysInstitutionPopOver2",
    data: () => ({
      configs: [
        { type: 'text-area',    field: 'text_availability', label: 'Texto da Disponibilidade Semanal' },
        { type: 'text-area',    field: 'text_availability_date', label: 'Texto da Disponibilidade Data' },
        { type: 'text-area',    field: 'text_interest', label: 'Texto Interesse Disciplinas' },
        { type: 'text-area',    field: 'text_confirmation', label: 'Texto Confirmação de CH' },
        { type: 'boolean',      field: 'is_confirm_email',  label: 'Enviar email de confirmação para professor',    textHelper: null },
        { type: 'boolean',      field: 'show_update_data',  label: 'Exibir Atualização Cadastral',    textHelper: null },
        { type: 'boolean',      field: 'clear_classes_attribution',  label: 'Derrubar horário ao trocar professor',    textHelper: null },
        { type: 'boolean',      field: 'enable_activity_time',  label: 'Habilitar Hora Atividade',    textHelper: null },
        { type: 'boolean',      field: 'calculate_variation',  label: 'Calcular Resilição',    textHelper: null },
        { type: 'boolean',      field: 'settings.show_attribution_temp',  label: 'Atribuição Eventual',    textHelper: null },
        { type: 'boolean',      field: 'settings.allow_replicate_teacher_join',  label: 'Permitir configuração de replicar junção',    textHelper: null },
        { type: 'boolean',      field: 'settings.replicate_teacher_join',  label: 'Replicar Junção',    textHelper: null },
        { type: 'boolean',      field: 'enable_activity_approval_flow',  label: 'Fluxo de Aprovação de Atividade',    textHelper: null },
        { type: 'text-field',   field: 'settings.approval_activity_deadline',  label: 'Limite Aprovação Atividade',    textHelper: 'Dia limite de aprovação' },
        { type: 'text-field',   field: 'max_day_hours',     label: 'Máximo aulas por dia', textHelper: 'Máximo aulas total no mesmo dia' },
        { type: 'text-field',   field: 'max_times_break_day',     label: 'Máximo aulas consecutivas', textHelper: 'Máximo de aulas consecutivas no dia sem intervalo' },
        { type: 'time-picker',  field: 'min_time_break',     label: 'Intervalo mínimo' },
      ]
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {
            type: Object,
            required: true
        },
    },
}
</script>