export default [
    {
        path: '/bankData',
        name: 'bankData',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Dados bancários',
            icon: '',
            breadcrumbs: [
                { text: 'Financeiro', disabled: true, href: '/' }, 
                { text: 'Dados bancários', disabled: true, href: '/' }, 
            ],
        }
    },
]