import { service } from '../../../helpers/http'

export const ActionFindBudgetTypes = ({ dispatch }, payload) => {

    return service({
        module: 'budgetType',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateBudgetType = ({ dispatch }, payload) => {
    
    return service({
        module: 'budgetType',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateBudgetType = ({ dispatch }, payload) => {
    
    return service({
        module: 'budgetType',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteBudgetType = ({ dispatch }, payload) => {
    
    return service({
        module: 'budgetType',
        action: 'delete',
        payload: payload
    })
    
}

