import { default as vacancyControl } from  './vacancy_control/services'
import { default as allocation } from  './allocation/services'

export default {
    ...vacancyControl,
    ...allocation,
    find:                   { method: 'get',    url: 'allocations' },
    create:                 { method: 'post',   url: 'allocations'},
    update:                 { method: 'put',    url: 'allocations/{id}'},
    delete:                 { method: 'delete', url: 'allocations/{id}'},
    deleteAll:              { method: 'delete', url: 'allocations/all'},
    updateLink:             { method: 'put',    url: 'ambience_links/{id}'},
    deleteLink:             { method: 'delete', url: 'ambience_links/{id}'},
    findClassesOptimized:   { method: 'get',    url: 'ambience_links/classes/optimized' },
    ambiencesFindEmpty:     { method: 'get',    url: 'allocations/find/empty' },
    ambiencesFindUsed:      { method: 'get',    url: 'allocations/find/used' },

}