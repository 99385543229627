<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="model.description"
                                outlined
                                name="input-7-4"
                                label="Detalhes"
                                :auto-grow="false"
                                :value="model.description"
                                rows="2"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Permitir bloquear/desbloquear atribuições" v-model="model.allow_lock_attribution" label="Bloqueio de Atribuição"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Permitir edição dos horários das aulas" v-model="model.allow_change_time_table" label="Alterar Horário"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Permitir edição das atividades extra classe" v-model="model.allow_change_activities" label="Alterar Atividades"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Permitir editar indicação e atividades com o sistem fechado para folha de pagamento" v-model="model.allow_edit_work_load" label="Editar CH Professor"/>
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch color="purple" inset v-model="model.active" label="Ativo"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-switch text-helper="Permitir alterações restritas no Planejamento de Oferta" color="purple" inset v-model="model.allow_change_offer_item" label="Alterar Oferta"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch text-helper="Ocultar ícones de importações no Menu Importação" inset v-model="model.hide_imports" label="Ocultar Importações"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-text-field :error-messages="errors.level" v-model="model.level" label="Nível"/>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <s-switch text-helper="Permitir abertura de chamados" inset v-model="model.allow_open_tickets" label="Abrir Chamados"/>
                        </v-col> -->
                    </v-row>
                    <v-row>
                       
                        <v-col cols="12" sm="6">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>
<script>

import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterRole",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = 1;
                    this.model.level = 0;
                }
                
                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Perfil de Acesso"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('role', ['ActionUpdateRole', 'ActionCreateRole']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateRole(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateRole(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>