<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      title="Abrir chamado"
      icon="mdi-text-box-plus"
      v-bind:show.sync="dialog"
      maxWidth="50%"
    >
        <template v-slot:form>
            <v-form @submit.prevent="save">
                <v-row class="mt-1">
                    <v-col cols="12" v-if="message">
                        <v-alert
                            outlined
                            color="warning"
                            prominent
                            border="left"
                            >
                            {{ message }}
                            </v-alert>
                    </v-col>
                    <EmcFormTextField 
                        v-model="item.subject" 
                        label="Assunto*" 
                        md="12"
                        outlined
                        :error="errors.length > 0"
                        :error-messages="errors.subject"
                        autofocus
                    />
                    <FormTicketSelectCategory 
                        v-model="item.ticket_category_id" 
                        label="Categoria*" 
                        md="12"
                        outlined
                        hide-no-data
                        :error="errors.length > 0"
                        :error-messages="errors.ticket_category_id"
                    />
                    <FormTicketSelectType 
                        v-model="item.ticket_type_id" 
                        label="Tipo*" 
                        md="12"
                        outlined
                        hide-no-data
                        :error="errors.length > 0"
                        :error-messages="errors.ticket_type_id"
                    />    
                    <EmcFormTextArea
                        v-model="item.description" 
                        label="Descrição*" 
                        md="12"
                        outlined
                        :error="errors.length > 0"
                        :error-messages="errors.description"
                        placeholder="Informe todos os detalhes do seu chamado..."
                    />

                    <v-col cols="12" md="12">
                        <form ref="form">

                            <EmcFormFile 
                                v-model="files"
                                name="files"
                                multiple
                                outlined
                                :error="hasValidationErrors.length ? true : false"
                            />

                            <!-- Se houver mensagens de validação de array, exibe-as -->
                            <v-alert color="error" outlined v-if="hasValidationErrors.length">
                                <div class="text-subtitle2">
                                    Verifique os arquivos:
                                </div>
                                <div class="mt-2">
                                    <ul>
                                        <!-- Usa v-for para percorrer e exibir apenas as mensagens de validação de array -->
                                        <li v-for="(item, index) in hasValidationErrors" :key="index">
                                            {{ item[1][0] }}
                                        </li>
                                    </ul>
                                </div>
                            </v-alert>

                        </form>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'
import FormTicketSelectCategory from '../category/SelectCategory.vue'
import FormTicketSelectType from '../type/SelectType.vue'

export default {
    name: "OpenTicket",
    data () {
        return {
            loading: false,
            message: null,
            errors: {},
            item: {},
            files: []
        }
    },
    props: {
        show: { type: Boolean, default: false }
    },
    components: {
        FormTicketSelectCategory,
        FormTicketSelectType
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        hasValidationErrors() {

            if(Object.keys(this.errors).length) {

                const arrayDePara = Object.entries(this.errors);
                // Verifica se há mensagens de validação de array
                return arrayDePara.filter(message => message[0].startsWith('files.'));
            }

            return []
        }
    },
    watch: {
        show(val){

            if(val){
                this.item = {};
                this.message = null,
                this.errors = {},
                this.files = []
            }

        }
    },
    methods: {
        ...mapActions("ticket", ["ActionTicketsStore"]),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        save() {
            
            this.initVariables()

            let formData = new FormData();

            if(this.item.subject != undefined)
                formData.append('subject', this.item.subject)

            if(this.item.ticket_category_id != undefined)
                formData.append('ticket_category_id', this.item.ticket_category_id)

            if(this.item.ticket_type_id != undefined)
                formData.append('ticket_type_id', this.item.ticket_type_id)

            if(this.item.description != undefined)
                formData.append('description', this.item.description)

            // Adiciona os arquivos ao FormData
            for (const file of this.files) {
                formData.append('files[]', file);
            }

            this.ActionTicketsStore(formData)
                .then((res) => {
                    this.message = null;

                    this.$emit('onCompleted', res.data);

                    if(this.$route.name != 'my-tickets'){
                        const routeData = this.$router.resolve({name: 'my-tickets' });
                        window.open(routeData.href, '_blank');
                        this.dialog = false;

                    }

                    
                })
                .catch((error) => {
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loading = !this.loading
                })
        }
    }
}
</script>