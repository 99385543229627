<template>
  <div>

    <sys-register-teacher only-hiring @onCompleted="pesquisar('', false, is_hiring)" :model="{}" icon="event" v-bind:show.sync="showFormAddTeacher" />

  <sys-register-base 
        :showButton="false" 
        :disabled="updatingAttribution || updatingHiring"
        :model.sync="model"
        title="Atribuição de Professor" 
        icon="mdi-school-outline" 
        v-bind:show.sync="dialog"
        maxWidth="90%"
        :showRequiredFields="false"
    >
       
        <template v-slot:tools>
            <v-tooltip v-if="tab == 4"  bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" icon @click.prevent.stop="showTextActivityId = !showTextActivityId" v-bind="attrs" v-on="on"><v-icon>mdi-clipboard-list-outline</v-icon></v-btn>
                </template>
                <span> Vincular Atividade pelo ID </span>
            </v-tooltip>
            <v-tooltip v-if="tab == 4"  bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo darken-1" icon @click.prevent.stop="showFormAddActivity = true" v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span> Vincular Nova Atividade </span>
            </v-tooltip>
        </template>
        
        <template v-slot:title>
           <div v-if="!!model.id">
                
                <span>
                    <emc-info-help title="Mais informações...">
                        <template v-slot:text>
                            <span><strong>Campus: </strong>{{ model.group.campus.abbreviation }}</span>
                            <br/>
                            <span><strong>Curso: </strong>{{ model.group.course.name }}</span>
                            <br/>
                            <span><strong>Turma: </strong>{{ model.group.name }}</span>
                            <br/>
                            <span v-if="model.group.category"><strong>Categoria: </strong>{{ model.group.category.name }}<br/></span>
                            <span><strong>Turno da Turma: </strong>{{ model.group.shift.name }}</span>
                            <br/>
                            <span  v-if="!!model.shift_id"><strong>Turno da Disciplina: </strong>{{ model.shift.name }}<br/></span>
                            <span><strong>Disciplina: </strong>{{ model.discipline.name }}</span>
                            <br/>
                            <span><strong>Carga Horária: </strong>{{ model.work_load_type.name + ' (' + model.work_load  + ')' }}</span>
                            <span v-if="model.work_load_pay !== null ">
                                <br/>
                                <strong>Carga Horária Paga: </strong>{{ model.work_load_pay }}
                            </span>
                            <span v-if="model.workTimeDate">
                                <br/><strong>Período: </strong>{{ model.workTimeDate.name }}
                            </span>
                            <br/>
                            <span v-if="model.group.baseGroups && !!model.group.baseGroups.length > 0"><strong>Ingressantes: </strong>{{ getBaseGroups() }}<br/></span>
                            <span v-if="model.joins"><strong>Turmas da Junção: </strong>{{ getTextoJuncao(model.joins) }} <br/></span>
                            <span class="blue--text"><strong>Professor Atual: </strong>{{ actualTeacher }} </span>
                        </template>
                    </emc-info-help>
                </span>
                
                <span><strong>Curso: </strong>{{ model.group.course.name }}</span>&nbsp;
                
                <span v-if="!model.shift_id"><strong>Turno: </strong>{{ model.group.shift.name }}</span> &nbsp;
                <span  v-else><strong>Turno: </strong>{{ model.shift.name }}</span>&nbsp;

                <span v-if="model.group.category"><strong>Turma: </strong>{{ model.group.name + ' (' + model.group.category.name + ')' }}</span>
                <span v-else><strong>Turma: </strong>{{ model.group.name }}</span>&nbsp;/
                <span>{{ model.discipline.name + ' - ' + model.work_load_type.name + ' (' + model.work_load  + ')' }}</span>
                
            </div>
       </template>
       
       <template v-slot:form>

        
        
        <v-tabs @change="refreshHistory()" v-model="tab" :vertical="!($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm')">
                <v-tab style="justify-content: left;">
                    <v-icon left>
                    mdi-account
                    </v-icon>
                        Atribuição
                </v-tab>
                <v-tab style="justify-content: left;" v-if="showAttributionGuide">
                    <v-icon left>
                        mdi-check-decagram
                    </v-icon>
                        Status
                </v-tab>
                <v-tab style="justify-content: left;">
                    <v-icon left>
                        mdi-timetable
                    </v-icon>
                        Horário
                </v-tab>
                <v-tab style="justify-content: left;">
                    <v-icon left>
                        mdi-chart-timeline-variant
                    </v-icon>
                        Histórico
                </v-tab>
                <v-tab v-if="model.payment_by_activity" style="justify-content: left;">
                    <v-icon left>
                        mdi-clipboard-list-outline
                    </v-icon>
                        Atividades
                </v-tab>
            <v-tab-item>
                <v-container>
                    <v-row v-if="attribution.id && !attribution.exported">
                        <v-col>
                            <v-alert
                                border="top"
                                colored-border
                                type="info"
                                >
                                    Para substituição, remova o professor atual na tela principal.
                                </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="attribution.exported">
                        <v-col>
                            <v-alert
                                dense
                                outlined
                                type="error"
                                >
                                    Atribuição exportada na folha de pagamento. Para remover ou finalizar acesse a guia <strong>STATUS</strong>.
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                                <v-text-field 
                                    :error="!!errors.teacher_id" 
                                    :error-messages="errors.teacher_id" 
                                    :value="(model && model.teacher) ? model.teacher.name : ``"
                                    label="Professor Selecionado"
                                    disabled
                                />
                                <!-- <SysSelectTeacherStatus
                                    :model.sync="model.teacher_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.teacher_id"
                                    label="Professor"
                                    :status="2"
                                    showAdd
                                    @change="selecionarProfessor($event)"
                                    auto-complete
                                /> -->
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-text-field
                                v-if="renderComponent"
                                :error="!!errors.work_load" 
                                :error-messages="errors.work_load" 
                                v-model="attribution.work_load"
                                label="CH"
                                :disabled="disabledFields || !attribution.is_paid"
                                />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <emc-calendar-date-picker2 
                                :model.sync="attribution.date_start" 
                                label="Início"
                                :errorMessages="errors.date_start" 
                                :show="dialog"
                                :min="minDate"
                                :max="maxDate"
                                :disabled="disabledFields"
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <emc-calendar-date-picker2 
                                :model.sync="attribution.date_end" 
                                label="Fim"
                                :errorMessages="errors.date_end" 
                                :show="dialog"
                                :min="minDate"
                                :max="maxDate"
                                :disabled="disabledFields"
                            />
                        </v-col>
                        <v-col v-if="showAttributionTemp" cols="12" sm="2">
                            <s-switch text-helper="Aula eventual não será considerada na composição de carga horária" inset v-model="attribution.is_temp" label="Eventual"/>
                        </v-col>
                        <v-col v-if="showAttributionPay" cols="12" sm="2">
                            <s-switch text-helper="Carga Horária Paga" :disabled="disabledFields || (!(model.work_load_type && model.work_load_type.has_paid_workload) && !user.is_master)" @input="changeWorkLoadPay" inset v-model="attribution.is_paid" label="CH Paga"/>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <s-switch :disabled="disabledFields || (model != null && model.discipline != null && model.discipline.unsanitary_type_id != null && model.work_load_type.is_practice == 1)" text-helper="Considerar pagamento de adicional de insalubridade" inset v-model="attribution.is_unsanitary" label="Insalubridade" />
                        </v-col>
                        <v-col cols="12" sm="2">
                            
                        </v-col>
                    </v-row>
                    
                    <v-row v-if="!attribution.id">
                        <v-col cols="12" sm="4">
                            <v-row>
                            <s-switch
                                class="ml-4"
                                v-model="is_hiring"     
                                text-helper="Informar um professor a contratar"
                                @input="pesquisar('', false, is_hiring)" 
                                inset 
                                label="Em contratação"
                                dense
                            />
                            <v-tooltip v-if="is_hiring" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" small icon @click.prevent.stop="showFormAddTeacher = true">
                                        <v-icon btn color="green darken-1">mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Novo professor a contratar</span>
                            </v-tooltip>
                            </v-row>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch
                                class="ml-4"
                                v-model="showOnlyCampus"
                                :loading="loadingOnlyCampus"    
                                text-helper="Exibir apenas professores que possuem vínculo na unidade da oferta"
                                @input="pesquisar(search, 4, is_hiring)"
                                inset 
                                label="Professores com vinculo"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!attribution.id">
                        <v-col cols="12" sm="12">
                            <v-data-table
                                v-model="selected"
                                :headers="headersProfessores"
                                :items="itemsProfessores"
                                :search="search"
                                item-key="id"
                                single-select
                                fixed-header
                                hide-default-footer
                                height="250"
                                class="elevation-1"
                            >

                                 <template v-slot:top>
                                    <v-text-field
                                        id="txt-pesquisa"
                                        v-model="search"
                                        label="Pesquisar..."
                                        required
                                        append-icon="search"
                                        v-on:keyup.enter="pesquisar(search, 'enter')"
                                        @input="onChangeSearch($event)"
                                        :loading="loading"
                                        ref="pesquisa"
                                        autofocus
                                        class="mx-4"
                                    >
                                        <template v-slot:append>

                                            <emc-button-icon v-if="showAddressTeacher" icon="mdi-google-maps" text="Ver professores próximos da Unidade" color="purple" @click.prevent.stop="showDistances = true" />
                                            <emc-button-icon icon="mdi-priority-high" text="Ver professores com atribuição prioritária" color="primary" @click.prevent.stop="pesquisar('', 3)" />
                                            <emc-button-icon v-if="workTime.show_disciplines_interest" icon="mdi-star-half-full" text="Ver professores que tem interesse em lecionar a disciplina" color="amber" @click.prevent.stop="pesquisar('', 1)" />
                                            <emc-button-icon icon="mdi-check-bold" text="Ver professores que lecionaram essa disciplina" color="green" @click.prevent.stop="pesquisar('', 2)" />
                                            
                                            <v-divider vertical class="mr-2 ml-2" inset dense/>

                                            <emc-button-icon :disabled="!search || search.length < 3" icon="search" text="Pesquisar" color="blue" @click.prevent.stop="pesquisar(search)" />

                                        </template>
                                    </v-text-field>
                                 </template>

                                 <template v-slot:item="{ item }">
                                    
                                    <tr v-if="item" style="cursor: pointer;" @click="selecionarProfessor(item)">
                                        
                                        <td class="text-xs-left">
                                            
                                            <v-icon v-if="selected.length > 0 && selected[0].id === item.id"
                                                color="blue"
                                                small
                                            >
                                                mdi-checkbox-marked-circle
                                            </v-icon>
                                            <v-icon v-else
                                                color="blue"
                                                small
                                            >
                                                mdi-circle-outline
                                            </v-icon>

                                                <emc-avatar-show-image :model="item" @click="showAvatar(item)" />

                                                <strong> {{ item.name }}</strong>
                                                <br/>

                                                <span v-if="isAnima()">
                                                    <span v-if="item.brands && item.brands.length > 0">
                                                        <v-tooltip bottom>
                                                            <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on" class="text-caption grey--text text--darken-1"> [{{ getCampusBrands(item, 'brands') }}] </span>
                                                            </template>
                                                            <span> {{ getToolTipCampusBrands(item, 'brands') }} </span>
                                                        </v-tooltip>
                                                    </span>

                                                </span>
                                                <span v-else>
                                                    <span v-if="item.campuses && item.campuses.length > 0">
                                                        <v-tooltip bottom>
                                                            <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on" class="text-caption grey--text text--darken-1"> [{{ getCampusBrands(item, 'campuses') }}] </span>
                                                            </template>
                                                            <span> {{ getToolTipCampusBrands(item, 'campuses') }} </span>
                                                        </v-tooltip>
                                                    </span>
                                                </span>
                                                
                                                <v-tooltip bottom>
                                                    <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                    <v-btn icon small ><v-icon small  v-bind="attrs" v-on="on" @click="openExtract(item)" color="purple">mdi-account-clock-outline</v-icon></v-btn>
                                                    </template>
                                                    <span>Ver Horários</span>
                                                </v-tooltip>  


                                                <span v-if="item.is_priority">
                                                    <v-tooltip bottom>
                                                        <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small :href="item.url_linkedin" target="_blank"><v-icon small  v-bind="attrs" v-on="on" color="primary">mdi-priority-high</v-icon></v-btn>
                                                        </template>
                                                        <span>Prioridade de Indicação</span>
                                                    </v-tooltip>  
                                                </span>

                                                <span v-if="item.url_linkedin">
                                                    <v-tooltip bottom>
                                                        <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small :href="item.url_linkedin" target="_blank"><v-icon small  v-bind="attrs" v-on="on" color="primary">mdi-linkedin</v-icon></v-btn>
                                                        </template>
                                                        <span>Ver Linkedin</span>
                                                    </v-tooltip>  
                                                </span>

                                                <span v-if="item.url_lattes">
                                                    <v-tooltip bottom>
                                                        <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                        <v-btn icon small :href="item.url_lattes" target="_blank"><v-icon small  v-bind="attrs" v-on="on" color="primary">mdi-school-outline</v-icon></v-btn>
                                                        </template>
                                                        <span>Ver Lattes</span>
                                                    </v-tooltip>  
                                                </span>

                                                
                                            <span v-if="workTime.show_disciplines_interest && hasInterest(item)">
                                                &nbsp;  &nbsp; &nbsp; 
                                                <v-tooltip bottom>
                                                    <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                       <v-icon style="cursor: help" small  v-bind="attrs" v-on="on" color="amber">mdi-star-half-full</v-icon>
                                                    </template>
                                                    <span>Tem interesse em lecionar essa disciplina</span>
                                                </v-tooltip>  
                                            </span>

                                            <span v-if="hasAttribution(item)">
                                                &nbsp;  &nbsp; &nbsp; 
                                                <v-tooltip bottom>
                                                    <template v-bind="attrs" v-on="on" v-slot:activator="{ on, attrs }">
                                                       <v-icon small  v-bind="attrs" v-on="on" color="green">mdi-check-bold</v-icon>
                                                    </template>
                                                    <span>Leciona(ou) essa disciplina</span>
                                                </v-tooltip>  
                                            </span>
                                            
                                        </td>
                                        <td class="text-xs-center black--text">{{ item.titling ? item.titling.name : null }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, 1) }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, 2) }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, 3) }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, 5) }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, 4) }}</td>
                                        <td class="text-xs-center blue--text">{{ getCargaHoraria(item, -1) }}</td>
                                        <td v-if="config.showTotal" class="text-xs-center green--text">{{ getCargaHoraria(item, 0) }}</td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                   
                </v-container>
            </v-tab-item>
            <v-tab-item>
                <sys-teacher-attribution-status @onCompleted="$emit('onCompleted')"  :model="model" />
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                <v-card-text>
                    <SysTeacherConfirmedTimes :workTime="workTime" :grid="model" />
                   <!-- <v-data-table
                        v-model="selectedHours"
                        :headers="headersHours"
                        :items="model.classes"
                        :single-select="false"
                        item-key="id"
                        :show-select="teachers.length > 1"
                        hide-default-footer
                        fixed-header
                        :items-per-page="100"
                        class="elevation-1"
                        height="400px"
                    >
                         <template v-if="teachers.length > 1" v-slot:top>
                            <v-container
                                fluid
                            >
                                <v-radio-group row v-model="teacherSelectedHour">
                                    <v-radio
                                        v-for="t in teachers"
                                        :key="t.id"
                                        :label="t.name"
                                        :value="t.id"
                                    ></v-radio>
                                    <v-radio
                                        label="Sem Professor"
                                        :value="-1"
                                    ></v-radio>
                                </v-radio-group>
                    
                                <v-btn 
                                    :disabled="!teacherSelectedHour || selectedHours.length == 0"
                                    color="success darken-1" 
                                    @click.stop="saveTeacherHours()">
                                    Salvar
                                </v-btn>
                             </v-container>
                         </template>
                        
                    </v-data-table> -->
                </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <sys-teacher-attribution-log-table ref="history" :model="model" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-text-field
                                    v-if="showTextActivityId"
                                    v-model="activityId"
                                    label="ID Atividade"
                                    :error="errors.length > 0"  
                                    :error-messages="errors['activityItems.0.id']"
                                    autofocus
                                />
                            </v-col>
                        </v-row>
                        <SysTeacherOtherActivitiesAttribution :attribution="attribution" />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            </v-tabs>
        </template>

        <template v-slot:actions>
            <v-tooltip v-if="acesso && acesso.instituicao && acesso.instituicao.settings.allow_teacher_in_hiring" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      v-bind="attrs" v-on="on"
                      :disabled="(model && model.in_hiring == 1) || updatingAttribution || updatingHiring" 
                      :loading="updatingHiring" 
                      color="red darken-1"
                      small
                      text 
                      @click.stop="saveHiring()">
                        A Contratar
                    </v-btn>
                  </template>
                    <span> Definir que o professor está em processo de contratação </span>
            </v-tooltip>
            <v-btn
                v-if="!attribution.id"
                :disabled="disableButtom" 
                :loading="updatingAttribution" 
                color="success darken-1" 
                @click.stop="save()">
                Atribuir Professor
            </v-btn>
            <v-btn
                v-if="attribution.id && model && model.teacher_id"
                :disabled="disabledFields" 
                color="success darken-1" 
                @click.prevent.stop="editAttribution()"
            >
                Salvar edição <v-icon>mdi-content-save-edit-outline</v-icon>
            </v-btn>
                
            
            <emc-alert-modal 
              title="Deseja finalizar a atribuição?" text="Essa ação irá finalizar e remover a atribuição. Não será possível editar novamente!" 
              :show.sync="showFinishAttribution"
              :disabled="updatingAttribution"
            >
              <template v-slot:actions>
                  <v-btn :disabled="updatingAttribution" :loading="updatingAttribution" color="error" @click.prevent.stop="save()">Finalizar Atribuição<v-icon right dark>mdi-playlist-remove</v-icon></v-btn>
                  
              </template>
            </emc-alert-modal>

            <emc-alert-modal 
              title="Deseja editar a atribuição?" text="Essa ação irá editar a carga horária e/ou o período informado" 
              :show.sync="showEditAttribution"
              :disabled="updatingAttribution"
            >

                <template v-slot:actions>
                    <v-btn 
                        :disabled="updatingAttribution" 
                        :loading="updatingAttribution" 
                        color="success" 
                        @click.prevent.stop="save()"
                    >
                        Salvar edição<v-icon right dark>mdi-content-save-edit-outline</v-icon>
                    </v-btn>
                </template>
             
            </emc-alert-modal>
        </template>

        <template v-if="!attribution.id" v-slot:footer>
            <strong class="d-none d-sm-flex blue--text">*CH Atribuída/CH Disponível</strong>
            <v-spacer></v-spacer>
        </template>

        

        

    </sys-register-base>

        <v-dialog
                v-model="showExtract"
                scrollable
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Horários
                    <v-spacer></v-spacer>

                <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small class="mr-1" @click.native="showExtract = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                  </v-card-title>

                  <v-card-text>
                      <SysTeacherExtract :teacher="teacher" :grid="model" />
                  </v-card-text>
                </v-card>
        </v-dialog>

    <sys-teacher-distance :show.sync="showDistances" :grid="model" />

    <sys-register-activity-item @onCompleted="onCompletedActivityItem" :model="{}" :grid="model" v-bind:show.sync="showFormAddActivity" />

    <emc-avatar-show :model="selectedTeacher" :show.sync="showImage" :source="selectedTeacher ? selectedTeacher.url_avatar : null" />

</div>
    
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysTeacherAttribution',
    data: function() {
        return {
            loading: false,
            renderComponent: true,
            loadingOnlyCampus: false,
            dialog: false,
            updatingAttribution: false,
            updatingHiring: false,
            showAttributionGuide: true,
            showFinishAttribution: false,
            showEditAttribution: false,
            is_hiring: false,
            showFormAddTeacher: false,
            
            search: '',
            workTime: null,
            showExtract: false,
            teacher: {},
            showImage: false,
            showAttributionTemp: false,
            showAddressTeacher: false,
            showDistances: false,
            showAttributionPay: false,
            selectedTeacher: null,
            error: false,
            showFormAddActivity: false,
            errors: [],
            selected: [],
            showOnlyCampus: true,
            teacherSelectedHour: null,
            activity: null,
            activityId: null,
            showTextActivityId: false,
            selectedHours: [],
            attribution: {},
            teachers: [],
            actualTeacher: '',
            minDate: null,
            maxDate: null,
            tab: 0,
            config: {
                showTotal: true,
            },
            itemsProfessores: [],
            headersProfessores: [
                {
                    text: 'Professor',
                    align: 'left',
                    sortable: true,
                    value: 'name',
                    class: "black--text"
                },
                {
                    text: 'Titulação',
                    align: 'left',
                    sortable: true,
                    value: 'titling.name',
                    class: "black--text"
                },
                {
                    text: 'Manhã*',
                    align: 'left',
                    sortable: false,
                    value: 'morningAvailability',
                    class: "blue--text"
                },
                {
                    text: 'Tarde*',
                    align: 'left',
                    sortable: false,
                    value: 'eveningAvailability',
                    class: "blue--text"
                },
                {
                    text: 'Noite*',
                    align: 'left',
                    sortable: false,
                    value: 'nightAvailability',
                    class: "blue--text"
                },
                {
                    text: 'Digital*',
                    align: 'left',
                    sortable: false,
                    value: 'digitalAvailability',
                    class: "blue--text"
                },
                {
                    text: 'Integral',
                    align: 'left',
                    sortable: false,
                    value: 'full_workload',
                    class: "blue--text"
                },
                {
                    text: 'Atividades',
                    align: 'left',
                    sortable: false,
                    value: 'activity_workload',
                    class: "blue--text"
                },
                {
                    text: 'Total*',
                    align: 'left',
                    sortable: false,
                    value: 'totalAvailability',
                    class: "green--text",
                }
            ],
            headersHours: [
                {
                    text: 'Dia',
                    align: 'center',
                    sortable: false,
                    value: 'day_of_week.name',
                },
                {
                    text: 'Horário',
                    align: 'center',
                    sortable: false,
                    value: 'hour.start',
                },
                {
                    text: 'Professor',
                    align: 'center',
                    sortable: false,
                    value: 'teacher.name',
                }
            ]
        }
    },

    created(){
        
        this.workTime               = this.acesso.periodo;
        this.showAttributionTemp    = this.acesso.instituicao.settings.show_attribution_temp;
        this.showAttributionPay     = this.acesso.instituicao.settings.show_attribution_pay;
        this.showAddressTeacher     = this.acesso.instituicao.settings.show_address_teacher_attribution;
       
    },

    watch: {
        
        selected(val){
            if(val && val.length > 0){
                this.attribution.teacher_id = val[0].id;
                this.model.teacher = {id: val[0].id, name: val[0].name };
            }
        },

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                
                this.updatingAttribution = false;
                this.updatingHiring = false;
                this.loading = false;
                this.search = '';
                this.itemsProfessores = [];
                this.selected = [];
                this.tab = 0;
                this.error = false;
                this.errors = [];
                this.is_hiring = false;
                this.showOnlyCampus = true;
                this.activity = null;
                this.activityId = null;
                this.showTextActivityId = false;

                if(this.model){
                    this.loadData();
                }
            }

        }
    },
    props: {
        
        model: {
            
        },
        show: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
    },

    computed: {
        ...mapState('auth', ['acesso', 'user']),
        disableButtom () {
            return this.updatingAttribution || this.updatingHiring || !this.selected || this.selected.length==0 || this.selected[0].id == this.model.teacher_id;
        },

        disabledFields() {
            return  this.workTime.allow_editing_attribution == 0 || this.attribution.exported;
        }

    },

    methods: {
        ...mapActions('teacher', ['ActionFindTeachers']),
        ...mapActions('attribution', ['ActionUpdateAttribution', 'ActionCreateAttribution']),
        ...mapActions('grid', ['ActionUpdateHiring']),
        ...mapActions('schedule', ['ActionUpdateAll']),

        loadData(){
            
            const att = this.model.attributions.find(i => i.attribution_status_id == 1);

            this.minDate = this.workTime.start;
            this.maxDate = this.workTime.end;

                if(this.model.work_time_date_id){
                    this.minDate = this.model.workTimeDate.start;
                    this.maxDate = this.model.workTimeDate.end;
                }
            
            if(att){
                
                this.attribution = att;
                this.teachers = this.model.attributions.filter((i) => 
                        (i.attribution_status_id == 1 && i.teacher_id)
                    ).map(o => o['teacher']);

            }else{
                
                this.attribution.grid_id                = this.model.id;
                this.attribution.id                     = 0;
                this.attribution.teacher_id             = this.model.teacher_id;
                this.attribution.work_load              = this.getWorkLoad(this.model);
                this.attribution.attribution_status_id  = 1;
                this.attribution.main                   = 1;
                this.attribution.is_temp                = 0;
                this.attribution.is_paid                = this.model.work_load_type ? this.model.work_load_type.has_paid_workload : 1;
                this.attribution.date_start             = this.minDate;
                this.attribution.date_end               = this.maxDate;
                this.attribution.in_hiring              = this.model.in_hiring;
                this.attribution.exported               = false;

            }

            this.attribution.is_unsanitary = this.model && this.model.discipline.unsanitary_type_id != null;

            if(!!this.model.id){
                this.actualTeacher = this.model.teacher_name;
            }else{
                this.actualTeacher = '';
            }

            
        },

        changeWorkLoadPay(ev){

            this.attribution.work_load = ev ? this.model.work_load_pay : 0;

            this.forceRerender();
        },

        openExtract(item){
            this.teacher        = item;
            this.showExtract    = true;
        },

        getWorkLoad(model){
            
            if(model.work_load_type && model.work_load_type.has_paid_workload){
                return model.work_load_pay;
            }else{
                return 0;
            }
            
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },

        editAttribution(){

            
            var today = new Date();
            var dateEnd = this.attribution.date_end;
            var date = today.getFullYear() + '-' + this.addZero(today.getMonth() + 1) + '-' + this.addZero(today.getDate());

            if(dateEnd < date && dateEnd < this.maxDate){
                this.showFinishAttribution = true;
            }else{
                this.showEditAttribution = true;
            }

        },

        addZero(number){
            if (number <= 9) 
                return "0" + number;
            else
                return number; 
        },

        save()
        {
            this.error = false;
            this.message = '';
            this.snackbar = false;
            this.updatingAttribution = true;
            let edit = this.attribution.id != 0;
            var old_status      = this.attribution.attribution_status_id;
            var old_teacher_id  = this.attribution.teacher_id;
            this.attribution.showAllCampuses = true;

            if(this.activity){
                this.attribution.activityItems = [
                    { id: this.activity.id }
                ];
            }else if(this.showTextActivityId && this.activityId){
                this.attribution.activityItems = [
                    { id: this.activityId }
                ];
            }

            delete this.attribution.teacher_contract_id;

            if(edit) {
                this.ActionUpdateAttribution(this.attribution)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    this.$emit('onError', error);
                    this.attribution.attribution_status_id  = old_status;
                    this.attribution.teacher_id             = old_teacher_id;
                })
                .finally(() => {
                     this.updatingAttribution = false;
                     this.showFinishAttribution = false;
                     this.showEditAttribution = false;
                })
            } else {
                this.ActionCreateAttribution(this.attribution)
                .then((res) => {
                    this.message = res.message;
                    this.attribution.id = res.data.id;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    this.$emit('onError', error);
                    this.attribution.attribution_status_id  = old_status;
                    this.attribution.teacher_id             = old_teacher_id;
                })
                .finally(() => {
                   this.updatingAttribution = false;
                   this.showFinishAttribution = false;
                   this.showEditAttribution = false;
                })

            }
        },

        saveHiring()
        {
            this.updatingHiring = true;
            this.error = false;
            this.message = '';
            this.snackbar = false;

            this.ActionUpdateHiring({id: this.model.id, in_hiring: 1})
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    this.$emit('onError', error);
                })
                .finally(() => {
                    this.updatingHiring = false;
                });
        },
        
        saveTeacherHours(){

             let payload = {
                 items: []
             };

             this.selectedHours.forEach(c => {
                 payload.items.push({
                     id: c.id,
                     teacher_id : this.teacherSelectedHour !== -1 ? this.teacherSelectedHour : null
                 })
             });
             
             this.ActionUpdateAll(payload)
                .then((res) => {
                    
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                     
                });

        },
        
        
        selecionarProfessor(item){
            
           this.selected = [item];

        },

        onChangeSearch($event){
            this.selected = [];
        },

        confirmarIndicacao(inHiring){
            
            var teacher = inHiring ? { in_hiring: inHiring } : this.selected[0];
            this.$emit('onSave', teacher);

        },

        getCargaHoraria(professor, turno){

            var dispManha = 0;
            var dispTarde = 0;
            var dispNoite = 0;
            var dispDigital = 0;
            var dispActivity = 0;
            var dispMax = 0;

            var indManha = 0;
            var indTarde = 0;
            var indNoite = 0;
            var indDigital = 0;
            var indActivity = 0;
            var indFull = 0;
            var indTotal = 0;

            if(professor.availabilities && professor.availabilities.length > 0){
                var disponibilidade = professor.availabilities[0];
            }

            if(disponibilidade){
                
                if(disponibilidade.morning_availability){
                    dispManha = parseFloat(disponibilidade.morning_availability);
                }
                    
            
                if(disponibilidade.evening_availability){
                    dispTarde = parseFloat(disponibilidade.evening_availability);
                }
                

                if(disponibilidade.night_availability){
                    dispNoite = parseFloat(disponibilidade.night_availability);
                }
                
                if(disponibilidade.digital_availability){
                    dispDigital = parseFloat(disponibilidade.digital_availability);
                }

                if(disponibilidade.activity_availability){
                    dispActivity = parseFloat(disponibilidade.activity_availability);
                }
                    

                if(disponibilidade.morning_workload){
                    indManha = disponibilidade.morning_workload;
                }
                
            
                if(disponibilidade.evening_workload){
                    indTarde = disponibilidade.evening_workload;
                }
                

                if(disponibilidade.night_workload){
                    indNoite = disponibilidade.night_workload;
                }
                

                if(disponibilidade.digital_workload){
                    indDigital = disponibilidade.digital_workload;
                }

                if(disponibilidade.full_workload){
                    indFull = disponibilidade.full_workload;
                }

                if(disponibilidade.activity_workload){
                    indActivity = disponibilidade.activity_workload;
                }

                if(disponibilidade.total_workload){
                    indTotal = disponibilidade.total_workload;
                }
                
                dispMax = parseFloat(disponibilidade.max_workload);

            }

            switch (turno){
                case -1:    return parseFloat(indActivity);
                case 0:     return indTotal   + ' / ' + dispMax;
                case 1:     return indManha   + ' / ' + dispManha;
                case 2:     return indTarde   + ' / ' + dispTarde;
                case 3:     return indNoite   + ' / ' + dispNoite;
                case 4:     return indFull;
                case 5:     return indDigital + ' / ' + dispDigital;
                default:    return 0;
            }

        },

            getTextoJuncao(joins){

            if(joins){
                
                var texto = '';

                joins.forEach(j => {
                
                if(texto != ''){
                    texto += ', ';
                }
                
                texto += j.group.name;
    
                });

                return texto;
                
            }

            },

            getCampusBrands(teacher, type){

                if(teacher[type] && teacher[type].length  > 0){
                    
                    let total = teacher[type].length;
                    let limit = 5;
                    
                    if(total < limit){
                        return teacher[type].map(o => o['initials']).join(', ');
                    }else{
                        let items = [];

                        for (let index = 0; index < limit; index++) {
                            items.push(teacher[type][index]);
                            
                        }

                        return items.map(o => o['initials']).join(', ') + ' (+' + (total - limit) + ')';
                    }

                    
                }else{
                    return "";
                }

            },

        getToolTipCampusBrands(teacher, type){

          if(teacher[type] && teacher[type].length  > 0){
            return teacher[type].map(o => o['name']).join(', ');
          }else{
            return "";
          }

        },

        refreshHistory(){

            if(this.tab == 3 && this.$refs.history){
                this.$refs.history.getData();
            }

        },

        onCompletedActivityItem(item){
            
            if(item){
                this.showFormAddActivity = false;
                this.activity = item;
                this.save();
            }
        },

        getBaseGroups(){
            
             return this.model.group.baseGroups.map(o => o['year'] + '/' + o['period'] ).join(', ');

        },

        pesquisar(search, type = 0, hiring = false) {
        
            if(!hiring && !type && search == '')
                return;

            if(type == 4 && !search){
                return;
            }

            if(type == 'enter' && (!search || search.length < 3)){
                return;
            }
            
            this.loading = true;
            this.loadingOnlyCampus = type == 4;
            this.itemsProfessores = [];
            this.showFormAddTeacher = false;

            if(type && type != 4){
                this.search = '';
            }

            let withTeacher = this.isAnima() ? 'availability,brands,titling' : 'availability,campuses,titling';

            let payload = {
                page: 1,
                per_page: 100,
                column_order: 'name',
                direction: 'asc',
                search: search,
                with: withTeacher,
                find_columns: {
                    active: 1
                }
            };

            if(type === 1){
                
                payload.with += ',disciplines';
                
                payload.filter_relations = [
                        {
                            name: 'disciplines',
                            key: 'discipline_id', 
                            value: this.model.discipline_id
                        }
                    ]
            }else if(type === 2){
                
                payload.with += ',gridsAll';
                payload.per_page = 15;
                
                payload.filter_relations = [  
                    {
                        name: 'gridsAll',
                        key: 'discipline_id', 
                        value: this.model.discipline_id
                    }
                ]
            }else if(type === 3){
                 payload.filter_relations = [  
                    {
                        name: 'contracts.position',
                        key: 'is_priority', 
                        value: 1
                    }
                ]
            }

            payload.showAllCampuses = true;
            
            if(this.is_hiring){
                payload.find_columns.teacher_status_id = 2;
            }

            // Exibição do campus
            if(this.showOnlyCampus){
                
                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                if(this.isAnima()){
                    payload.filter_relations.push(
                    {
                        name: 'brands',
                        key: 'brands.id',
                        value: this.model.group.campus.brand_id,
                    }
                );
                }else{
                    payload.filter_relations.push(
                    {
                        name: 'campuses',
                        key: 'campuses.id',
                        value: this.model.group.campus_id,
                    }
                );
                }

                

            }

            let newPayload = payload;

            if(search && search.length > 0){
                Object.assign(newPayload, { search: 'name := ' + search, operator: 'LIKE' });
            }

            this.ActionFindTeachers(newPayload)
                .then((res) => {
                    this.itemsProfessores = res.data;
                    })
                .finally(() => {
                    this.loading = false;
                    this.loadingOnlyCampus = false;
                })

        },

        hasInterest(teacher){

            if(!teacher.disciplines){
                return 0;
            }
            
            var count = 0;
            
            teacher.disciplines.forEach(d => {
                if(this.model.discipline_id == d.id){
                    count++;
                }
            });

            return count > 0;

        },

        hasAttribution(teacher){

            if(!teacher.gridsAll){
                return 0;
            }
            
            var count = 0;
            
            teacher.gridsAll.forEach(g => {
                if(this.model.discipline_id == g.discipline_id){
                    count++;
                }
            });

            return count > 0;

        },

        showAvatar(item){
            
            if(item && item.url_avatar){
                this.selectedTeacher = item;
                this.showImage = true;
            }
        }
    }
    
}
</script>
