<template>
    <v-card id="create">
      <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
      >
        <template v-slot:activator>
          
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
            x-small
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-svg</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          dark
          x-small
          color="indigo"
          @click="clickEye"
        >
          <v-icon v-if="eyeOpen">mdi-eye-off-outline</v-icon>
          <v-icon v-else>mdi-eye-check-outline</v-icon>
        </v-btn>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                   v-bind="attrs" v-on="on"
                  fab
                  dark
                  x-small
                  color="success"
                  @click="showHours"
                >
                  <v-icon v-if="hoursVisible">mdi-close-thick</v-icon>
                  <v-icon v-else>mdi-timetable</v-icon>
                  
                </v-btn>
            </template>
            <span v-if="hoursVisible">Não exibir todos horários</span>
            <span v-else>Exibir todos horários</span>
      </v-tooltip>

      </v-speed-dial>
    </v-card>
</template>

<style>
  /* This is for documentation purposes and will not be needed in your application */
  #create .v-speed-dial {
    position: fixed;
    bottom: 100px;
  }

  #create .v-btn--floating {
    position: relative;
  }
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleSpeed",
    data: () => ({
      eyeOpen: false,
      hoursVisible: false,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'scale-transition'
      // transition: 'slide-y-reverse-transition',
    }),
    computed: {
      activeFab () {
        switch (this.tabs) {
          case 'one': return { class: 'purple', icon: 'account_circle' }
          case 'two': return { class: 'red', icon: 'edit' }
          case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
          default: return {}
        }
      },
    },
    watch: {
      top (val) {
        this.bottom = !val
      },
      right (val) {
        this.left = !val
      },
      bottom (val) {
        this.top = !val
      },
      left (val) {
        this.right = !val
      },
    },
    props: {
        
    },
    methods: {
      clickEye(){
        this.eyeOpen  = !this.eyeOpen;
        this.$emit('onClickEye', this.eyeOpen);
      },

      showHours(){
        this.hoursVisible  = !this.hoursVisible;
        this.$emit('onShowHours', this.hoursVisible);
      },

    }
        
}
</script>