<template>
    <span>
        <emc-table-icon-download-base 
            :type="type"
            :item="item"
            :color="color" 
            :icon="icon" 
            :tool-tip="toolTip"
            :url="url"
            :disabled="disabled"
        />
    </span>
</template>
<script>
export default {
    name: "EmcTableIconDownloadPdf",
    data () {
        return {
            
        }
    },
    computed: {
        url: function() {
            
            if(this.route){
                return this.route;
            }else if(this.token){

                var url = 'teachers/token/' + this.token + '/' + this.type;
                
                if(this.signed){
                    url += '?signed=true';
                }

                return url;

            }else{
                return null;
            }
        }
    },
    props: {
        color: {
            type: String,
            default: 'error darken-1'
        },
        icon: {
            type: String,
            default: 'mdi-file-pdf'
        },
        toolTip: {
            type: String,
            default: 'Download PDF'
        },
        type: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            required: true
        },
        token: {
            type: String,
            default: null
        },
        signed: {
            type: Boolean,
            default: false
        },
        route: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
