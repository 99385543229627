<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :nudge-width="200"
      open-on-hover
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-btn>
      </template>

      <v-card>

        <v-app-bar class="elevation-0" dense v-if="title">
            <v-toolbar-title class="text-subtitle-1">{{ title }}</v-toolbar-title>
        </v-app-bar>

        <v-card-text>
            <slot name="card-text" />
        </v-card-text>
        
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
    data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
    }),
    props: {
        icon: {
            type: String,
            default: 'mdi-information'
        },
        color: {
            type: String,
            default: 'blue-grey lighten-3'
        },
        title: {
            type: String,
            required: false
        }
    }
}
</script>