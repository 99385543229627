<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          color="grey"
          small
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-row dense>
          <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Configurações</v-list-item-title>
              <v-list-item-subtitle>Junção de Turmas</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        
        <v-divider></v-divider>

          </v-col>

        </v-row>


      <v-row dense>
        <v-col
          cols="12"
          sm="12"
        >
          <v-card elevation="0">
            <v-list dense>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Junção entre campus</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch inset dense v-model="model.distinctCampus"/>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">Somente disciplina digital</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch inset dense v-model="model.onlyDigitals" />
            </v-list-item-action>
          </v-list-item>

          </v-list>
          </v-card>
        </v-col>
      </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
    name: "SysPopOverJoin",
    data: () => ({
      fav: true,
      menu: false,
      hints: true,
    }),
    watch: {
        
        model(model_new, model_old) {
            
            if(!model_new.id){
              this.menu = false;
            }
           
        },
    },
    props: {
        model: {
            type: Object,
        },
    },
}
</script>