export default {
    hours:              { method: 'get',    url: 'hours' },
    hoursGroups:        { method: 'get',    url: 'hours/groups' },
    hoursByToken:       { method: 'get',    url: 'hours/token/{token}/?with=shift' },
    shifts:             { method: 'get',    url: 'shifts' },
    shiftsFilter:       { method: 'get',    url: 'shifts/filter' },
    hourCreate:         { method: 'post',   url: 'hours' },
    hourUpdate:         { method: 'put',    url: 'hours/{id}' },
    hourDelete:         { method: 'delete', url: 'hours/{id}' },
    dayOfWeeks:         { method: 'get',    url: 'day_of_weeks'},
    indexHoursToken:    { method: 'get',    url: 'hours/index/{token}/token'},
}