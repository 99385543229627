<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        maxWidth="70%"
        icon="mdi-calendar-plus" 
        v-bind:show.sync="dialog"
    >
            
            <template v-slot:tools>
                <sys-work-time-pop-over :model="model" :errors="errors" />
            </template>
            
            <template v-slot:form>
                <v-tabs class="ma-0" v-model="tab">
                        <v-tab key="main">Dados Principais</v-tab>
                        <v-tab key="dates">Sub-Períodos</v-tab>
                        <v-tab key="integration">Integração</v-tab>
                    </v-tabs>
                    <v-tabs-items  v-model="tab">
                        <v-tab-item key="main">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field :error="errors.length > 0" :autofocus="true" :error-messages="errors.name" v-model="model.name" label="Nome*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field :error="errors.length > 0" :error-messages="errors.year" v-model="model.year" label="Ano*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field :error="errors.length > 0" :error-messages="errors.period" v-model="model.period" label="Período*" required></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- Datas -->
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker-range textHelper="Data de início e fim do Período Letivo." :errorMessages1="errors.start" :errorMessages2="errors.end" :show="dialog" :model1.sync="model.start" :model2.sync="model.end" label="Início/Fim*" />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker-range textHelper="Data de início e fim das aulas." :errorMessages1="errors.classes_start" :errorMessages2="errors.classes_end" :show="dialog" :model1.sync="model.classes_start" :model2.sync="model.classes_end" label="Período de Aulas*" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker-range textHelper="Período de cadastro da disponibilidade pelo professor." :errorMessages1="errors.availability_start" :errorMessages2="errors.availability_end" :show="dialog" :model1.sync="model.availability_start" :model2.sync="model.availability_end" label="Período Disponibilidade*" />
                                </v-col>
                                <v-col  cols="12" sm="6">
                                    <emc-calendar-date-picker-range textHelper="Período de atribuição de aulas para os professores." :errorMessages1="errors.indication_start" :errorMessages2="errors.indication_end" :show="dialog" :model1.sync="model.indication_start" :model2.sync="model.indication_end" label="Período de Atribuição*" />
                                </v-col>    
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <emc-calendar-date-picker-range dense textHelper="Período de confirmação de carga horária dos professores."  clearable :errorMessages1="errors.confirmation_start" :errorMessages2="errors.confirmation_end" :show="dialog" :model1.sync="model.confirmation_start" :model2.sync="model.confirmation_end" label="Período de Confirmação" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <sys-select-frequency
                                            :model.sync="model.frequency_id"
                                            :error="errors.length > 0"  
                                            :errors="errors.frequency_id"
                                            label="Regime*"
                                            dense
                                            text-helper="Regime da oferta do Período"
                                        />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <sys-select-work-time-category
                                            :model.sync="model.work_time_category_id"
                                            :error="errors.length > 0"  
                                            :errors="errors.work_time_category_id"
                                            label="Categoria"
                                            dense
                                            showClear
                                        />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <s-text-field text-helper="Número de semanas total do período. O número de semanas é utilizado para conversão da carga horária total para semanal." :error-messages="errors.weeks" v-model="model.weeks" label="Semanas" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <s-switch text-helper="Período letivo fechado não permite alterações nos dados." color="purple" inset v-model="model.closed" label="Período Fechado" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <s-switch text-helper="Período inativo, apenas para registro." color="purple" inset v-model="model.active" label="Ativo" />
                                </v-col>
                                <!-- <v-col cols="12" sm="3">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.deadline_occurrence" v-model="model.deadline_occurrence" label="Limite Lançamento Ocorrência" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.deadline_occurrence_validation" v-model="model.deadline_occurrence_validation" label="Limite Validação Ocorrência" required></v-text-field>
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <s-switch text-helper="Período padrão exibido na tela de seleção" color="purple" inset v-model="model.is_default" label="Período Padrão" />
                                </v-col>
                            </v-row>
                            
                        </v-tab-item>
                        <v-tab-item key="dates">
                            <sys-work-time-register-dates :model="model" :errors="errors"/>
                        </v-tab-item>
                        <v-tab-item key="integration">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <emc-calendar-date-picker2
                                            :errorMessages="errors.start_integration" 
                                            :show="dialog" 
                                            :model.sync="model.start_integration" 
                                            :open-on-click="false"
                                            :readonly="false"
                                            show-clear
                                            label="Início"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="3">
                                        <emc-calendar-date-picker2
                                            :errorMessages="errors.end_integration" 
                                            :show="dialog" 
                                            :model.sync="model.end_integration" 
                                            :open-on-click="false"
                                            :readonly="false"
                                            show-clear
                                            label="Fim"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <SysWorkTimeIntegration :workTime="model" />
                                    </v-col>
                                </v-row>
                            </v-container>
                            
                        </v-tab-item>
                    </v-tabs-items>
                <!-- Alert para mensagens -->
                <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
            
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import store from '../../../store'
export default {
    name: "SysRegisterWorkTime",
    data: (vm) => ({
        dateWorkTimeStart: null,
        tab: null,
        dateWorkTimeEnd: null,
        dateClassesStart: null,
        dateClassesEnd: null,
        dateAvailabilityStart: null,
        dateAvailabilityEnd: null,
        dateIndicationStart: null,
        dateIndicationEnd: null,
        modalWorkTimeStart: null,
        modalWorkTimeEnd: null,
        modalAvailabilityStart: false,
        modalAvailabilityEnd: false,
        modalIndicationStart: false,
        modalIndicationEnd: false,
        frequencies: [],
        dialog: false,
        error: false,
        errors: [],
        message: '',
        loading: false,
        tab: null,
        headers: [
            {
            text: 'Regime',
            sortable: false,
            value: 'frequency_id',
            },
            { text: 'Início/Fim', value: 'period', sortable: false, },
            { text: '', value: 'actions', sortable: false, },
        ],
    }),
    created(){
        
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.error = false;
                this.errors = [];
                this.tab = null;

                 if(!this.model.id){
                    this.model.frequency_id                     = 5;
                    this.model.show_availability_week           = 1;
                    this.model.auto_calculate_work_load         = 1;
                    this.model.auto_generate_grids              = 0;
                    this.model.weeks                            = 20;
                    this.model.deadline_occurrence              = 5;
                    this.model.deadline_occurrence_validation   = 10;
                    this.model.active = 1;
                 }
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            requered: false
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('worktime', ['ActionCreateWorktime', 'ActionUpdateWorktime']),
        ...mapActions('auth', ['ActionSetAcesso']),
        
        save(edit)
        {
            this.error = null;
            this.errors = [];
            this.message = '';
            this.loading = true;

            if(edit) {
                
                var acesso = store.state.auth.acesso;

                this.ActionUpdateWorktime(this.model)
                .then((res) => {
                    
                    if(res.data.id == acesso.periodo.id){
                        acesso.periodo = res.data;
                        this.ActionSetAcesso(acesso);
                    }

                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {

                this.ActionCreateWorktime(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },
    }
}
</script>