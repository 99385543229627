<template>
  <div :class="{ 'mt-12' : breads.length <= 1}">
    <v-breadcrumbs :items="breads" v-if="breads.length > 1">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>

  export default {
        name: "SysCoreBreadcrumbs",
        data: () => ({
            items: 
                {
                    text: 'Home',
                    disabled: false,
                    href: '/',
                }
            ,
            breads: []
        }),
        watch:{
            $route (to, from){

                var newBreads = []

                if(to.meta.breadcrumbs) {
                    newBreads = to.meta.breadcrumbs
                }

                if(newBreads.length === 0 || newBreads[0].text !== 'Home') {
                    newBreads.unshift(this.items)
                }

                this.breads = newBreads
            }
        },
  }
</script>