import { service } from '../../../../helpers/http'

export const ActionIndexActivityItemTeachers = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'indexActivityItemTeachers',
        payload: payload,
    });
}

export const ActionCreateActivityItemTeacher = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'createActivityItemTeacher',
        payload: payload
    });
}

export const ActionUpdateActivityItemTeacher = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'updateActivityItemTeacher',
        payload: payload
    });
}

export const ActionDeleteActivityItemTeacher = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'deleteActivityItemTeacher',
        payload: payload
    });
}