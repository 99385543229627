import { service } from '../../../../helpers/http'

export const ActionIndexImports = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'indexImports',
        payload: payload
    })
    
}

export const ActionStoreImport = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'storeImport',
        payload: payload
    })
    
}

export const ActionUpdateImport = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'updateImport',
        payload: payload
    })
    
}

export const ActionDestroyImport = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'destroyImport',
        payload: payload
    })
}