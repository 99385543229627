<template>
  <sys-register-base 
        :loading="loadingSave" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <emc-loader-progress :show="loading"/>
                    <v-data-table
                            :headers="headers"
                            :items="grids"
                            hide-default-footer
                            :items-per-page="-1"
                            item-key="index"
                            fixed-header
                            height="300px"
                        >
                            <template v-slot:top="{ on, attrs }">
                                <v-btn 
                                  :disabled="!model || !model.id"
                                  color="indigo darken-1" 
                                  @click.prevent.stop="addItem()" 
                                  icon v-bind="attrs" 
                                  v-on="on">
                                  <v-icon>mdi-plus</v-icon></v-btn>
                            </template>

                            <template v-slot:item.teacher_number="{ index}">
                                <v-tooltip  v-if="index == 0" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn x-small style="cursor: help;" v-bind="attrs" v-on="on" icon>
                                          <v-icon x-small color="amber" btn>mdi-star-half-full</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>
                                      Professor Principal
                                  </span>
                              </v-tooltip>
                              <v-tooltip  v-else bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-btn x-small style="cursor: help;" v-bind="attrs" v-on="on" icon>
                                          <v-icon x-small color="primary" btn>mdi-dots-horizontal-circle-outline</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>
                                      Professor Secundário
                                  </span>
                              </v-tooltip>
                            </template>

                            <template dense v-slot:item.work_load="{item, index}">
                                <v-text-field 
                                  v-model="item.work_load" 
                                  :error="errors.length > 0" 
                                  :error-messages="errors['items.' + index + '.work_load']" 
                                  dense
                                  class="centered-input text--darken-3 mt-3"
                                />
                            </template>

                            
                            <template v-slot:item.actions="{ item, index }">
                                
                                <v-btn
                                  @click.prevent.stop="showDeleteItem(item, index)"
                                  icon
                                  :disabled="!index"
                                >
                                  <v-icon
                                      small
                                      color="error"
                                      dense
                                  >
                                      mdi-delete
                                  </v-icon>
                                </v-btn>
                                
                                
                            </template>
                        </v-data-table>
                  </v-col>

                  <emc-alert-modal text="Confirma e exclusão?" :disabled="loadingDelete" v-bind:show.sync="showDelete">
                      <template v-slot:actions>
                          <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="loadingDelete" :disabled="loadingDelete">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                      </template>
                  </emc-alert-modal>

                  <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

                </v-row>
              </v-container>
        </template>
    </sys-register-base> 
</template>

<script>


import { mapActions, mapState } from 'vuex'
export default {
    name: "SysGridDivisionTeacher",
    data: (vm) => ({
        dialog: false,
        error: false,
        grids: [],
        errors: {},
        message: '',
        loading: false,
        loadingDelete: false,
        loadingSave: false,
        showDelete: false,
        indexDelete: -1,
        itemDelete: null,
        totalDeleteds: 0,
        headers: [
            { text: '',       value: 'teacher_number', sortable: false,  width: "10%" },
            { text: 'Professor',  value: 'teacher.name',    sortable: false,  width: "70%"},
            { text: 'CH*',         value: 'work_load',    sortable: false,  width: "18%", align: 'center' },
            { text: '',           value: 'actions', sortable: false, width: "2%"},
        ],
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
                this.grids = [];
                this.totalDeleteds = 0;
                this.getData();
            }else{
              if(this.totalDeleteds){
                this.$emit('onCompleted');
              }
            }

            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-division"
        },
        title: {
            type: String,
            default: "Divisão de Professores"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('grid', ['ActionFindGrids', 'ActionCreateAddTeacherAll', 'ActionDeleteAddTeacher', 'ActionDeleteDivisionGrid']),

        getData(){

          this.grids = [];
          this.loading = true;

          let payload = {
            find_columns: {
              group_id:           this.model.group_id,
              discipline_id:      this.model.discipline_id,
              work_load_type_id:  this.model.work_load_type_id,
              group_number:       this.model.group_number,
            },
            column_order: 'teacher_number',
            with: 'teacher,discipline.workLoads',
          };
          
          this.ActionFindGrids(payload)
              .then((res) => {

                    res.data.forEach(grid => {
                      this.grids.push({
                        id:             grid.id,
                        teacher_number: grid.teacher_number,
                        teacher:        { id: grid.teacher_id, name: grid.teacher_name },
                        work_load:      grid.work_load,
                        work_load_pay:  grid.work_load_pay,
                        work_load_time: grid.work_load_time,
                      });
                    });
                
                  this.loadIndex(this.grids);
              })
              .catch((error) =>{
                  
              })
              .finally(() => {
                    this.loading = false;
              });

        },
        
        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loadingSave = true;

            this.grids.forEach(g => {
              g.work_load       = g.work_load.toString().replace(',', '.');
              g.work_load_pay   = g.work_load;
              g.work_load_time  = g.work_load;
            });

            let payload = {
              group_id:           this.model.group_id,
              discipline_id:      this.model.discipline_id,
              work_load_type_id:  this.model.work_load_type_id,
              items:              this.grids
            };

            this.ActionCreateAddTeacherAll(payload)
              .then((res) => {
                  this.message = res.message;
                  this.$emit('onCompleted');
              })
              .catch((error) =>{
                  this.error      = true;
                  this.errors     = error.errors;
                  this.message    = error.message;
              })
              .finally(() => {
                    this.loadingSave = false;
              });
          
        },

        deleteItem(){

          
          this.loadingDelete = true;
          
          var method = this.itemDelete.teacher_number ? 'ActionDeleteAddTeacher' : 'ActionDeleteDivisionGrid';
                
          this[method]({id: this.itemDelete.id})
          .then((res) => {
              this.message = res.message;
              this.totalDeleteds++;
              this.getData();
          })
          .catch((error) =>{
            this.message = error.message;
            this.error = true;
          })
          .finally(() => {
              this.loadingDelete = false;
              this.showDelete = false;
          });

        },

        showDeleteItem(item, index){

          if(item.id){
            this.indexDelete = index;
            this.itemDelete = item;
            this.showDelete = true;
          }else{
            this.removeItem(item);
            return;
          }

        },

        addItem(){
          
          if(!this.model || !this.model.id){
            return;
          }
          
          var item = {
              index:            this.grids.length,
              id:               0,
              teacher_number:   0,
              teacher:          null,
              work_load:        0,
              work_load_pay:    0,
              work_load_time:   0,
          };

          this.grids.push(item);
          
      },

      removeItem(item){
          var index = this.grids.indexOf(item);
          this.grids.splice(index, 1);
          this.loadIndex(this.grids);
      },

      loadIndex(items){
          
          var index = 0;

          items.forEach(item => {
            item.index = index++;
          });
      },

    }
}
</script>