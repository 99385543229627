export default [
    {
        path: '/event-publics',
        name: 'event-publics',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Público de Eventos',
            icon: 'mdi-account-group-outline',
            breadcrumbs: [
                { text: 'Público de Eventos', disabled: true }
            ]
        }
    }
]