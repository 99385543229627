export default [
    {
        path: '/views',
        name: 'views',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Consultas',
            icon: '',
            breadcrumbs: [
                { text: 'Consultas', disabled: true, href: '/' },
            ],
        }
    }
]