<template>
    <EmcCardStats 
        color="blue darken-2"
        icon="mdi-flask-outline"
        icon-large
        :title="this.title"
        :value="this.ambiences"
        sub-text="Número de ambientes"
        />
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'EmcChartAmbienceNumber',
    data () {
        return {
            ambiences: '0',
            title: ''
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartAmbienceNumber']),
        getData() {
            this.ActionChartAmbienceNumber()
                .then((r) => {
                    this.ambiences = r.data.value.toString()
                    this.title = r.data.title
                })
        }
    },
    created(){
        this.getData()
    }

    
}
</script>