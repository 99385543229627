export default [
    {
        path: '/allocations',
        name: 'allocations',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Agendamento',
            icon: 'mdi-calendar-edit',
            breadcrumbs: [
                { text: 'Agendamento do Ambiente', disabled: true, href: '/' }
            ],
        }
    },
]