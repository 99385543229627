<template>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <v-data-table
                  :headers="headers"
                  :items="model.subGroups"
                  hide-default-footer
                  :items-per-page="-1"
                  item-key="index"
                  fixed-header
                  height="300px"
                 
              >
                  <template v-slot:top="{ on, attrs }">
                      <v-btn 
                        :disabled="!model || !model.id"
                        color="indigo darken-1" 
                        @click.prevent.stop="addItem()" 
                        icon v-bind="attrs" 
                        v-on="on">
                        <v-icon>mdi-plus</v-icon></v-btn>
                  </template>

                  <template v-slot:item.code="{item, index}">
                      <s-text-field 
                        v-model="item.code"
                        code
                        :error-messages="errors['items.' + index + '.code']" 
                        dense
                      />
                  </template>

                  <template v-slot:item.code_curriculum="{item, index}">
                      <s-text-field 
                        v-model="item.code_curriculum" 
                        :error-messages="errors['items.' + index + '.code_curriculum']" 
                        dense
                      />
                  </template>
 
                  <template dense v-slot:item.name="{item, index}">
                      <v-text-field 
                        v-model="item.name" 
                        :error="errors.length > 0" 
                        :error-messages="errors['items.' + index + '.name']" 
                        dense
                      />
                  </template>

                  
                  <template v-slot:item.actions="{ item, index }">
                      
                      <v-btn
                        @click.prevent.stop="showDeleteItem(item, index)"
                        icon
                      >
                        <v-icon
                            small
                            color="error"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                      </v-btn>
                      
                      
                  </template>

                  <template v-slot:footer>
                    <v-row>
                      <v-col>
                        <v-btn
                            @click.prevent.stop="updateItems()"
                            :loading="loadingSave"
                            :disabled="loadingSave || !model.id || model.subGroups.length == 0"
                            color="success"
                            class='ma-1'
                          >
                            Salvar 
                            <v-icon
                              small
                              dense
                            >
                              mdi-content-save
                            </v-icon>
                          </v-btn>
                      </v-col>
                    </v-row>
                    
                  </template>
                            
                </v-data-table>
        </v-col>

        <emc-alert-modal text="Confirma e exclusão?" :disabled="loadingDelete" v-bind:show.sync="show">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="loadingDelete" :disabled="loadingDelete">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>

        <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

      </v-row>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysGroupSubGroup",
    data: () => ({
      fav: true,
      dialog: false,
      loadingSave: false,
      loadingDelete: false,
      errors: [],
      itemDelete: null,
      message: '',
      error: false,
      show: false,
      indexDelete: -1,
      headers: [
          { text: 'Código', value: 'code',    sortable: false,  width: "30%" },
          { text: 'Cód. Matriz', value: 'code_curriculum',    sortable: false,  width: "20%" },
          { text: 'Nome*',  value: 'name',    sortable: false,  width: "68%"},
          { text: '',       value: 'actions', sortable: false, width: "2%"},
      ],
    }),
    props: {
        model: {
            type: Object,
        },
    },
    created(){
      if(this.model.id && this.model.subGroups){
        this.loadIndex(this.model.subGroups);
      }
    },
     watch: {
       model(){
         if(this.model.id && this.model.subGroups){
            this.loadIndex(this.model.subGroups);
         }
       },
     },
    methods: {
      ...mapActions('group', ['ActionDestroySubGroup', 'ActionUpdateSubGroupItems', 'ActionStoreSubGroup']),

      loadIndex(items){
          
          var index = 0;

          items.forEach(item => {
            item.index = index++;
          });
      },
      
      updateItems(){

        this.errors = [];
        this.error = false;
        this.message = '';
        
        var payalod = {
          group_id: this.model.id,
          items: this.model.subGroups,
        };

        this.loadingSave = true;
          
          this.ActionUpdateSubGroupItems(payalod)
            .then((res) => {
                var items = res.data;
                this.loadIndex(items);
                this.model.subGroups = items;
                this.message = 'Item(s) atualizado(s) com sucesso!';
            })
            .catch((error) =>{
                this.errors = error.errors;
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                  this.loadingSave = false;
            });

      },

      showDeleteItem(item, index){

          if(item.id){
            this.indexDelete = index;
            this.itemDelete = item;
            this.show = true;
          }else{
            this.removeItem(item);
            return;
          }

      },
      
      deleteItem(){
          
          this.error = false;
          this.message = '';
          this.loadingDelete = true;
          
          this.ActionDestroySubGroup({id: this.itemDelete.id})
            .then((res) => {
                this.removeItem(this.itemDelete);
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                  this.loadingDelete = false;
                  this.indexDelete = -1;
                  this.itemDelete = null;
                  this.show = false;
            });
            
      },

      removeItem(item){
          var index = this.model.subGroups.indexOf(item);
          this.model.subGroups.splice(index, 1);
          this.loadIndex(this.model.subGroups);
      },

      addItem(){
          
          if(!this.model || !this.model.id){
            return;
          }
          
          var item = {
              index:            this.model.subGroups.length,
              group_id:         this.model.id,
              id:               0,
              code:             null,
              code_curriculum:  null,
              name:             null,
          };

          this.model.subGroups.push(item);
          
      },
    }
}
</script>