import { service } from '../../../../helpers/http'

export const ActionIndexPlan = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'indexPlan',
        payload: payload
    })

}

export const ActionDestroyPlan = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'destroyPlan',
        payload: payload
    })
}

export const ActionStorePlan = ({ dispatch }, payload ) => {
    return service({
        module: 'plan',
        action: 'storePlan',
        payload: payload
    })

}

export const ActionUpdatePlan = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'updatePlan',
        payload: payload
    })
}