import { service } from '../../../../helpers/http'

export const ActionIndexGridModalities = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexGridModalities',
        payload: payload
    })

}
export const ActionDestroyGridModality = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyGridModality',
        payload: payload
    })
}
export const ActionStoreGridModality = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeGridModality',
        payload: payload
    })

}
export const ActionUpdateGridModality = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateGridModality',
        payload: payload
    })
}