import { service } from '../../../../helpers/http'

export const ActionIndexEstablishments = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'indexEstablishments',
        payload: payload
    });
}

export const ActionCreateEstablishment = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'createEstablishment',
        payload: payload
    });
}

export const ActionUpdateEstablishment = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'updateEstablishment',
        payload: payload
    });
}

export const ActionDeleteEstablishment = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'deleteEstablishment',
        payload: payload
    });
}
