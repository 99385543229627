export default [
    {
        path: '/work-load-history',
        name: 'work-load-history',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Histórico de Carga Horária',
            icon: 'mdi-chart-timeline',
            breadcrumbs: [
                { text: 'Histórico de Carga Horária', disabled: true }
            ]
        }
    }
]