export default [
    {
        path: '/specificities',
        name: 'specificities',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Especificidades',
            icon: 'mdi-debug-step-into',
            breadcrumbs: [
                { text: 'Lista de especificidades', disabled: true }
            ]
        }
    }
]