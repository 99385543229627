<template>
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '80%' : maxWidth" persistent>
        <emc-loader-progress v-if="renderComponent" :show="progress" />
            <v-card
                class="mx-auto elevation-0"
                height="80hv"
                
            >
                    <v-card-title dark class="grey lighten-5">
                        <v-icon left></v-icon>&nbsp;&nbsp;
                        Impressão de Folha de Ponto
                    </v-card-title>
                        <v-container>
                            <v-row>
                                <v-col>
                                       <v-card
                                        outlined
                                        height="60vh"
                                        prominent
                                        class="mx-auto elevation-0 overflow-y-auto overflow-x-auto"
                                        
                                    >
                                        <v-list flat>
                                        <!-- <v-subheader>Campus</v-subheader> -->
                                        <v-list-item-group
                                            v-model="selectedCampus"
                                            color="primary"
                                        >
                                            <v-list-item
                                            v-for="item in campuses"
                                            :key="item.id"
                                            >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                            </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>

                                        </v-list>
                                    </v-card> 
                                </v-col>
                            <v-col>
                                       <v-card 
                                            outlined
                                            height="60vh"
                                            prominent
                                            class="mx-auto elevation-0 overflow-y-auto overflow-x-auto"
                                        >
                                            <v-app-bar
                                                prominent
                                                flat
                                                color="transparent"
                                                height="40px"
                                            >
                                       
                                            <v-text-field
                                                class="pa-0"
                                                v-model="searchTeacher"
                                                prepend-inner-icon="search"
                                                x-small
                                                placeholder="Pesquisar" 
                                                rounded 
                                                single-line
                                                clearable
                                            ></v-text-field>

                                             <v-checkbox
                                                v-model="checkboxAllTeacher"
                                                label="Todos"
                                            ></v-checkbox>
                                            

                                            </v-app-bar>

                                           
                                                <v-treeview 
                                                    class="pa-0"
                                                    ref="treeTeachers"
                                                    :items="itemsTeachers"
                                                    item-key="id"
                                                    item-name="name" 
                                                    return-object 
                                                    item-children="children" 
                                                    v-model="modelTeachers" 
                                                    selectable 
                                                    :open-all="false"
                                                    :search="searchTeacher"
                                                />
                                           
                                        </v-card>
                            </v-col>
                            <v-col>
                                <v-list>
                                    
                                    <v-list-item>
                                        <!-- <v-list-item-action> -->
                                            <v-select
                                                v-model="month"
                                                :items="months"
                                                item-value="id"
                                                item-text="name"
                                                label="Mês"
                                                outlined
                                            ></v-select>
                                        <!-- </v-list-item-action> -->
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                        <v-switch v-model="params.showEntry" color="purple"></v-switch>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Assinatura de Entrada/Saída</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                        <v-switch v-model="params.showHoliday" color="purple"></v-switch>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir datas não letivas</v-list-item-title>
                                    </v-list-item>

                                     <v-list-item>
                                        <v-list-item-action>
                                        <v-switch @change="changeState()" v-model="params.groupByShift" color="purple"></v-switch>
                                        </v-list-item-action>
                                        <v-list-item-title>Agrupar por Turno</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                        <v-switch :disabled="params.groupByShift" v-model="params.showDiscipline" color="purple"></v-switch>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Disciplina</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-action>
                                        <v-switch v-model="params.showClasses" color="purple"></v-switch>
                                        </v-list-item-action>
                                        <v-list-item-title>Exibir Número de Aulas</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-radio-group
                                            v-model="type"
                                            column
                                            >
                                             <v-radio
                                                label="PDF"
                                                color="red"
                                                value="pdf"
                                            ></v-radio>
                                            <v-radio
                                                label="Excel"
                                                color="green darken-3"
                                                value="excel"
                                            ></v-radio>
                                           
                                        </v-radio-group>
                                        <v-spacer/>
                                        <v-radio-group
                                            v-model="params.orientation"
                                            column
                                            >
                                             <v-radio
                                                label="Retrato"
                                                color="purple"
                                                value="portrait"
                                            ></v-radio>
                                             <v-radio
                                                label="Paisagem"
                                                color="blue"
                                                value="landscape"
                                            ></v-radio>
                                           
                                        </v-radio-group>
                                    </v-list-item>

                                </v-list>
                            </v-col>
                        </v-row>
                     </v-container>
                   
                    <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn :color="type=='excel' ? 'success' : 'error'" @click.prevent.stop="printTimeSheet()" :disabled="modelTeachers.length == 0" >Imprimir<v-icon right dark>{{ type == 'excel' ? 'mdi-microsoft-excel' : 'mdi-file-pdf-box-outline' }}</v-icon></v-btn>
                            <v-btn @click.native="dialog = false">Fechar</v-btn>
                    </v-card-actions>

                </v-card>
    </v-dialog>

</template>

<script>

import { myMixin } from '../../../mixins';
import { mapActions } from 'vuex'
import state from '../../../modules/auth/store/state'

export default {
    name: "SysTimeSheetTable",
    mixins: [myMixin],  
    data: () => ({
        campuses: [],
        type:'pdf',
        month: null,
        months: [ 
            { id: 1, name: 'Janeiro' }, 
            { id: 2, name: 'Fevereiro' }, 
            { id: 3, name: 'Março' }, 
            { id: 4, name: 'Abril' }, 
            { id: 5, name: 'Maio' }, 
            { id: 6, name: 'Junho' },
            { id: 7, name: 'Julho' },
            { id: 8, name: 'Agosto' },
            { id: 9, name: 'Setembro' },
            { id: 10, name: 'Outubro' },
            { id: 11, name: 'Novembro' },
            { id: 12, name: 'Dezembro' },
        ],
        selectedCampus: {},
        searchTeacher: '',
        checkboxAllTeacher: false,
        itemsTeachers: [],
        modelTeachers: [],
        params: {
            init_date: '',
            end_date: '',
            showDiscipline: false,
            showEntry: false,
            groupByShift: true,
            showHoliday: false,
            orientation:'portrait'
        },
        dialog: false,
        progress: false,
        renderComponent: false,
    }),
    created(){
        
        var date = new Date();
        this.month = date.getMonth() + 1;

        

        

    },
    computed: {
        
    },
    watch: {
        
        dialog(val_new, val_old) {
            
            if(val_new !== val_old) {
                this.$emit('update:show', val_new)
            }

        },

        show(val_new, val_old) {
            
            this.dialog = val_new;

            if(val_new && this.campuses.length == 0){
                this.loadCampuses();
            }
            
        },

        checkboxAllTeacher(val_new){
          
          if(val_new){
              this.modelTeachers = this.itemsTeachers;
          }else{
              this.modelTeachers = [];
          }
            
        },

        selectedCampus(val_new){

            this.itemsTeachers = [];
            var idsTeachers = [];

            this.campuses[val_new].teachers.forEach(teacher => {

                if(!idsTeachers.includes(teacher.id)){
                    
                    this.itemsTeachers.push(teacher);
                    idsTeachers.push(teacher.id);
                }

            });

            this.itemsTeachers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            this.modelTeachers = this.itemsTeachers;
            

        },
    },
    props: {
        
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            default: false
        },
        
    },
    methods:{
        ...mapActions('group', ['ActionGetGroupsTimeSheet']),

        loadCampuses(){
           
            this.progress = true;
            this.forceRerender();
            
            this.ActionGetGroupsTimeSheet()
            .then((res) => {
                this.campuses = res.data;
            })
            .catch((error) =>{
                
            })
            .finally(() => {
              this.progress = false;
            });
            
        },

        changeState(){
            
            if(this.params.groupByShift){
                this.params.showDiscipline = false;
            }
           
        },

        printTimeSheet(){
            
            var dateInit = new Date(state.acesso.periodo.year, this.month - 1, 1);
            var dateEnd = new Date(state.acesso.periodo.year, this.month, 0);
            var dayInit = dateInit.getDate().toString().padStart(2, '0');
            var dayEnd = dateEnd.getDate().toString().padStart(2, '0');
            var monthInit = (dateInit.getMonth() + 1).toString().padStart(2, '0');
            var monthEnd = (dateEnd.getMonth() + 1).toString().padStart(2, '0');
            this.params.init_date = dateInit.getFullYear() + '-' + monthInit + '-' + dayInit;
            this.params.end_date = dateEnd.getFullYear() + '-' + monthEnd + '-' + dayEnd;
            
            var campus_id = this.campuses[this.selectedCampus].id;
            var ids = this.modelTeachers.map(o => o['id']).join(',');
            var params = Object.entries(this.params).map(([key, val]) => `params[${key}]=${val}`).join('&');
            var url = 'type=' + this.type + '&campus_id=' + campus_id + '&teacher_ids=' + ids + '&' + params;
            this.download(this.type == 'excel' ? 13 : 14, url);
            
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
            });
        },
    }
}
</script>