import { service } from '../../../../helpers/http'

export const ActionIndexUserPhotos = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'indexUserPhotos', 
		payload: payload 
	})
}

export const ActionCreateUserPhoto = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'createUserPhoto', 
		payload: payload 
	})
}

export const ActionDestroyUserPhoto = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'destroyUserPhoto', 
		payload: payload 
	})
}