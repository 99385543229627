import { service } from '../../../../helpers/http'

export const ActionFindDynamicTimes = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'findDynamicTimes',
        payload: payload
    })
    
}

export const ActionCreateDynamicTime = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'createDynamicTime',
        payload: payload
    })
    
}

export const ActionUpdateDynamicTime = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateDynamicTime',
        payload: payload
    })
}

export const ActionDeleteDynamicTime = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'deleteDynamicTime',
        payload: payload
    })
    
}

export const ActionDynamicTimeGenerate = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'generateDynamicTime',
        payload: payload
    })
}

export const ActionDynamicTimeSync = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'syncDynamicTime',
        payload: payload
    })
}

export const ActionDynamicTimeTeacherSharing = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'teacherSharingDynamicTime',
        payload: payload
    })
}

export const ActionDynamicTimeIndexGroupingTypes = ({ dispatch }, payload) => {
    return service({
        module: 'dynamicGeneration',
        action: 'indexGroupingTypesDynamicTime',
        payload: payload
    })
}

