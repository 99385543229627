<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="70%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model.teacher"
                                :selectedObject.sync="teacherSelected" 
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                autofocus
                                :disabled="disabled || teacher != null"
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <sys-select-teacher-contract
                                :model.sync="model.teacher_contract_id"
                                :error="errors.teacher_contract_id && errors.length > 0" 
                                :errors="errors.teacher_contract_id"
                                label="Matrícula"
                                autoComplete
                                autofocus
                                filter-company
                                showClear
                                :disabled="disabled || !model.teacher_id"
                                :teacher-id="model.teacher_id"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <sys-select-campus
                                :model.sync="model.campus_id"
                                label="Campus"
                                dense
                                auto-complete
                                show-clear
                                :disabled="disabled"
                            />
                        </v-col>
                         <v-col cols="12" sm="4">
                            <sys-select-course
                                :model.sync="model.course_id"
                                label="Curso"
                                dense
                                auto-complete
                                :disabled="disabled || !model.campus_id"
                                :campus-id="model.campus_id"
                                show-clear
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-group
                                :model.sync="model.group_id"
                                label="Turma"
                                dense
                                auto-complete
                                show-clear
                                :disabled="disabled || !model.course_id"
                                :campus-id="model.campus_id"
                                :course-id="model.course_id"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="paymentExport.payment_type_id == 2">
                        <v-col cols="12" sm="12">
                            <sys-select-activity
                                :model.sync="model.activity_id"
                                :error="errors.length > 0"  
                                :errors="errors.activity_id"
                                label="Atividade"
                                itemText="name_complete"
                                autoComplete
                                :disabled="disabled"
                                dense
                            /> 
                        </v-col>
                    </v-row>
                    <v-row> 
                        <v-col cols="12" sm="4">
                            <sys-select-payment-periodicity-type
                                    :model.sync="model.payment_periodicity_type_id"
                                    :error="errors.length > 0"  
                                    :errors="errors.payment_periodicity_type_id"
                                    label="Periodicidade*"
                                    :disabled="disabled"
                                    dense
                                /> 
                        </v-col>
                        <v-col cols="12" sm="4">
                            <sys-select-value-type
                                :model.sync="model.value_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.value_type_id"
                                label="Tipo de Valor*"
                                dense
                                :disabled="disabled"
                            /> 
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field
                                v-if="paymentExport.payment_export_prorated_type_id == 1"
                                v-model="model.value_prorated_grids"     
                                :error-messages="errors.value_prorated_grids" 
                                label="Valor*"
                                dense
                                :disabled="disabled"
                            />
                            <s-text-field
                                v-else-if="paymentExport.payment_export_prorated_type_id == 2"
                                v-model="model.value_prorated_students"     
                                :error-messages="errors.value_prorated_students" 
                                label="Valor*"
                                dense
                                :disabled="disabled"
                            />
                            <s-text-field
                                v-else
                                v-model="model.value"     
                                :error-messages="errors.value" 
                                label="Valor*"
                                dense
                                :disabled="disabled"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.start" 
                                label="Início*" 
                                :errorMessages="errors.start"
                                :show="dialog"
                                :min="acesso.periodo.start"
                                :max="acesso.periodo.end"
                                :disabled="disabled"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.end" 
                                label="Fim*" 
                                :errorMessages="errors.end"
                                :show="dialog"
                                :min="acesso.periodo.start"
                                :max="acesso.periodo.end"
                                :disabled="disabled"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-cost-center
                                :model.sync="model.cost_center_id"
                                :error="errors.length > 0"  
                                :errors="errors.cost_center_id"
                                label="Centro de Custo"
                                showClear
                                auto-complete
                                dense
                                :disabled="disabled || !!model.course_id"
                            /> 
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-payment-event
                                :model.sync="model.payment_event_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_event_id"
                                label="Evento de Pgto"
                                item-text="initials"
                                showClear
                                auto-complete
                                dense
                                :disabled="disabled"
                            /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note" 
                                :error-messages="errors.note" 
                                v-model="model.note" 
                                label="Observação"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                :disabled="disabled"
                                rows="2"
                                 />
                        </v-col>
                    </v-row>
                    <v-row v-if="model.details">
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.details" 
                                :error-messages="errors.details" 
                                v-model="model.details" 
                                label="Detalhes"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                disabled
                                rows="2"
                                 />
                        </v-col>
                    </v-row>

            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'
export default {
    name: "SysPaymentExportRegisterItem",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        model: {},
        message: '',
        loading: false,
        workTime: state.acesso.periodo,
        teacherSelected: {},
    }),
    computed: {
      ...mapState('auth', ['acesso']),

      disabled(){
        return this.model && this.model.payment_export_item_status_id == 4;
      }
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            this.model = {};

            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.item.id){
                    this.model.start                        = this.workTime.start;
                    this.model.end                          = this.workTime.end;
                    this.model.value_type_id                = 1;
                    this.model.payment_periodicity_type_id  = 1;
                }else{
                    Object.assign(this.model, this.item);
                }
                
            }
        },
        
    },
    props: {
        item: {
            default: null
        },
        paymentExport: {
            required: true,
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-account-cash"
        },
        title: {
            type: String,
            default: "Registro de Pagamento"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        }
    },
    methods:{
        ...mapActions('paymentExport', ['ActionUpdatePaymentExportItem', 'ActionCreatePaymentExportItem']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

                if(this.paymentExport){
                this.model.payment_export_id = this.paymentExport.id;
            }

            if(this.model && this.model.id) {
                this.ActionUpdatePaymentExportItem(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;

                    if(error && error.errors.length > 0){
                        this.message = error.errors[0];
                    }else{
                        this.message = error.message;
                    }

                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                
                this.ActionCreatePaymentExportItem(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        loadGroups(items){
            
            var course = this.courses.find(c => c.id == this.model.group.course_id);
            this.course = course;
            this.group_id = this.model.group_id;
        },

    }
}
</script>