export default {
    
    controlPanelIndicatorAvailabilities:   { method: 'get', url: 'control_panel/indicator-availability/{work_time_id}'},
    controlPanelIndicatorIndications:      { method: 'get', url: 'control_panel/indicator-indication/{work_time_id}'},
    controlPanelIndicatorTimeTable:        { method: 'get', url: 'control_panel/indicator-time-table/{work_time_id}'},
    controlPanelIndicatorAmbiences:        { method: 'get', url: 'control_panel/indicator-ambiences/{work_time_id}'},
    excelImport:                           { method: 'post', url: 'excel/import/{class_name}'},
    exportEmail:                           { method: 'get', url: 'excel/export_email/{id}'},
    exportDownloadOptimized:               { method: 'get', url: 'excel/export_download_optimized/{report}'},
    exportPdf:                             { method: 'get', url: 'pdf/export/{id}/{type}'},
    upload:                                { method: 'post', url: 'control_panel/upload'},
    download:                              { method: 'post', url: 'control_panel/download/{token}'},
    uploadToken:                           { method: 'post', url: 'control_panel/upload/token'},
    indicatorAttributionsRefresh:          { method: 'post', url: 'control_panel/indicator-indication/refresh'},
    indicatorTimeTableRefresh:             { method: 'post', url: 'control_panel/time_table/refresh'},
    indicatorAmbiencesRefresh:             { method: 'post', url: 'control_panel/ambiences/refresh'},
    indicatorAvailabilityRefresh:          { method: 'post', url: 'control_panel/indicator-availability/refresh'},
    joinStudentsRefresh:                   { method: 'post', url: 'control_panel/join_students/refresh'},
}