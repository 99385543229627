<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-credit-card-plus" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                autofocus
                            />

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-bank
                                :model.sync="model.bank_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :errors="errors.bank_id"
                                label="Banco*"
                                autoComplete
                                autofocus
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <sys-select-bank-account-type
                                :model.sync="model.bank_account_type_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :errors="errors.bank_account_type_id"
                                label="Tipo de Conta*"
                                autofocus
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.agency" :error-messages="errors.agency" v-model="model.agency" label="Agência*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.agency_digit" :error-messages="errors.agency_digit" v-model="model.agency_digit" label="Dígito"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense :error="!!errors.account" :error-messages="errors.account" v-model="model.account" label="Conta*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field dense :error="!!errors.account_digit" :error-messages="errors.account_digit" v-model="model.account_digit" label="Dígito"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <sys-select-bank-pix-type
                                :model.sync="model.bank_pix_type_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :errors="errors.bank_pix_type_id"
                                label="Tipo de Chave Pix"
                                autofocus
                                showClear
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="8">
                            <v-text-field ref="keyPix" :disabled="!model.bank_pix_type_id" dense :error="!!errors.key_pix" :error-messages="errors.key_pix" v-model="model.key_pix" label="Chave Pix*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-switch
                                v-model="model.same_ownership"
                                inset
                                label="Mesma Titularidade"
                            />
                        </v-col>
                        <v-col v-if="!model.same_ownership" cols="12" sm="6">
                           <v-radio-group row v-model="model.legal_person">
                                <v-radio
                                    label="Pessoa Física"
                                    :value="1"
                                />
                                <v-radio
                                    label="Pessoa Jurídica"
                                    :value="0"
                                />
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="!model.same_ownership">
                        <v-col cols="12" sm="8">
                            <v-text-field :error="!!errors.ownership_name" :error-messages="errors.ownership_name" v-model="model.ownership_name" :label="model.legal_person ? 'Nome' : 'Razão Social'"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field :error="!!errors.ownership_document_number" :error-messages="errors.ownership_document_number" v-model="model.ownership_document_number" :label="model.legal_person ? 'CPF' : 'CNPJ'"></v-text-field>
                        </v-col>
                    </v-row>
            </v-container>
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysBankDataRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        maskPix: null,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.same_ownership = 1;
                    this.model.legal_person = 1;
                }
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Dados Bancários"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('bankData', ['ActionUpdateBankData', 'ActionCreateBankData']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateBankData(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateBankData(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>