<template>
    <v-dialog v-model="dialog" scrollable :max-width="$vuetify.breakpoint.name == 'xs' ? '80%' : maxWidth" persistent>
        <v-card>
                
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" :class="color + ' mb-4 elevation-1'">
                        <v-icon v-if="icon" left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="tools"></slot>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
                
                    <v-card-text>
                        <v-container>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    class="ma-0 pa-0"
                                    v-model="search"
                                    solo
                                    label="Pesquisar..."
                                    append-icon="mdi-magnify"
                                    autofocus
                                    v-on:keyup.enter="searchItems(search)"
                                    :loading="searching"
                                >
                                    <template v-slot:append>
                                        
                                        <v-tooltip v-if="showAdd" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                    color="green darken-1" 
                                                    @click.prevent.stop="$emit('onAdd')" 
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"  
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span> Nova Disciplina </span>
                                        </v-tooltip>
                                        
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                    color="indigo darken-1" 
                                                    @click.prevent.stop="searchItems(search)" 
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"  
                                                >
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                            <span> Pesquisar </span>
                                        </v-tooltip>
                                        
                                        

                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :items="items"
                                    :single-select="singleSelect"
                                    item-key="id"
                                    :show-select="showSelect"
                                    class="elevation-1 ma-0 pa-0"
                                    fixed-header
                                    height="250"
                                    hide-default-footer
                                    :items-per-page="-1"
                                >
                                    <template v-if="singleSelect" v-slot:item.name="{ item }">
                                        <tr v-if="item" style="cursor: pointer;" @click.prevent.stop="selected=[item]">
                                            <td class="text-xs-left">
                                                <v-icon v-if="selected.length > 0 && selected[0].id === item.id"
                                                color="blue"
                                                small
                                                >
                                                    mdi-checkbox-marked-circle
                                                </v-icon>
                                                
                                                <v-icon v-else
                                                    color="blue"
                                                    small
                                                >
                                                    mdi-circle-outline
                                                </v-icon>
                                                    {{ item.name }}
                                            </td>
                                        </tr>
                                    </template>
                                    
                                    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                                        <slot :name="slot" v-bind="scope"/>
                                    </template>

                                </v-data-table>

                            </v-col>
                        </v-row>

                    </v-container>
                    <emc-alert-snackbar v-bind:show.sync="bShow"  v-bind:message.sync="message" :color="'error'"/>
                </v-card-text>
            
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="selected.length == 0" @click.prevent.stop="select()">Selecionar<v-icon right dark>mdi-cursor-pointer</v-icon></v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "SysFindBase",
    data: (vm) => ({
        search: '',
        message: '',
        selected: [],
        dialog: false,
        searching: false,
        items: [],
        bShow: false
    }),
    created(){
        
    },

    computed: {
      
    },

    watch: {
        
        loading(val) {
            this.searching = val;
        },
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.searching = false;
                this.selected = [];
                this.search = "";
                this.items = [];
            }
        },

        collection(val){
            this.items = val;
        },
        message(val) {
            this.bShow = (val.length > 0) ? true : false 
        },

        selecteds(val){
            this.selected = val;
        },

        selected(val){
            this.$emit('update:selecteds', val);
        }
    
    },
    props: {
        collection: {
            type: Array,
        },
        headers: {
            type: Array,
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "400"
        },
        maxWidth: {
            type: String,
            default: "50%"
        },
        icon: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: true
        },
        showSelect: {
            type: Boolean,
            default: true
        },
        selecteds: {

        },
        showAdd: {
            type: Boolean,
            default: false
        },
        validateExistJoin: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        
        select(){
            
            this.message = '';
            
            if(this.validateExistJoin && this.selected[0].join instanceof Object) {
                this.message = 'Essa disciplina da turma já faz parte de uma junção!'
            } else {
                this.dialog = false;
                this.$emit('onSelect', this.selected);
            }
        },

        searchItems(search){
            this.$emit('onSearch', this.search);
        }
    }
}
</script>