import { service } from '../../../helpers/http'

export const ActionFindFloors = ({ dispatch }, payload) => {
    return service({
        module: 'floor',
        action: 'floors',
        payload: payload
    })
}

export const ActionFindFloorsFilter = ({ dispatch }, payload) => {
    return service({
        module: 'floor',
        action: 'floorsFilter',
        payload: payload
    })
}

export const ActionCreateFloor = ({ dispatch }, payload) => {
    return service({
        module: 'floor',
        action: 'createFloor',
        payload: payload
    })
}

export const ActionUpdateFloor = ({ dispatch }, payload) => {
    return service({
        module: 'floor',
        action: 'updateFloor',
        payload: payload
    })
}

export const ActionDeleteFloor = ({ dispatch }, payload) => {
    return service({
        module: 'floor',
        action: 'deleteFloor',
        payload: payload
    })
}