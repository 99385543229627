import { service } from '../../../helpers/http'
export * from '../teachers/store/actions'
export * from '../approval/store/actions'

export const ActionFindActivityItems = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'activityItems',
        payload: payload,
    });
}

export const ActionCreateActivityItem = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'createActivityItem',
        payload: payload
    });
}

export const ActionUpdateActivityItem = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'updateActivityItem',
        payload: payload
    });
}

export const ActionDeleteActivityItem = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'deleteActivityItem',
        payload: payload
    });
}

export const ActionFindActivityItemStatus = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'activityItemStatus',
        payload: payload
    });
}

export const ActionDeleteActivityItemTimeTable = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'deleteActivityItemTimeTable',
        payload: payload
    });
}

export const ActionUpdateActivityItemTeacher = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'updateActivityItemTeacher',
        payload: payload
    });
}

export const ActionIndexActivityItemEntryTypes = ({ dispatch }, payload) => {
    
    return service({
        module: 'activityItem',
        action: 'activityItemEntryTypes',
        payload: payload
    });
}