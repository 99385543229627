<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          dark
          large
          color="grey"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Validação Geral</v-list-item-title>
              <v-list-item-subtitle>Horário</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch :disabled="!user.is_master" inset v-model="config.allowShock" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Choque de Professor</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch  :disabled="!user.is_master" inset v-model="config.allowDoubleClass" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Sobreposição de Aulas</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch  :disabled="!user.is_master" inset v-model="config.allowDoubleClassJoin" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Sobreposição nas turmas da Junção</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="config.allowDesloc" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Deslocamento</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch  :disabled="!user.is_master" inset v-model="config.allowJourney" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Interjornada</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="config.allowShockAmbience" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Choque de Ambiente</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="config.allowHourGroup" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Alocação em outros horários</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch  inset v-model="config.allowIntraDay" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Intrajornada</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="config.allowMaxHoursDay" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Permitir Máximo de Horas por Dia</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="config.allowAvailability" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Não Validar Disponibilidade</v-list-item-title>
          </v-list-item>

        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="menu = false">Cancel</v-btn>
          <v-btn color="primary" text @click="menu = false">Save</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: "SysSchedulePopOver",
    data: () => ({
      fav: true,
      menu: false,
    }),
    props: {
        config: { },
        
    },
    computed: {
      ...mapState('auth', ['user'])
    }
}
</script>