import * as actions from './actions'
import state from  './state'
import mutations from './mutations'
import * as getters  from './getters'

export default {
    actions,
    mutations,
    state,
    getters,
    namespaced: true
}