export default [
    {
        path: '/integration-schedules',
        name: 'integration-schedules',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Agendamento de Integração',
            icon: 'mdi-av-timer',
        }
    }
]