<template>
    
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
                <v-card-title v-if="title" dark :class="color">
                    <v-icon left>{{icon}}</v-icon>&nbsp;&nbsp;
                     {{ title }}
                </v-card-title>
                    <!-- <v-switch v-model="model.active" label="Professor Ativo"></v-switch> -->
                    <v-card-text>
                        <!-- <slot name="form" /> -->
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="items"
                            :single-select="false"
                            :item-key="itemKey"
                            show-select
                            fixed-header
                            height="300"
                            :hide-default-footer="true"
                            :items-per-page="-1"
                            
                        >
                            <template v-slot:top>
                                
                                <v-row>
                                    <v-col cols="12" :sm="showUpdate ? 9 : 12">
                                        <v-file-input
                                            v-model="fileUpload"
                                            accept=".csv"
                                            placeholder="Selecione um arquivo .csv"
                                            label="Arquivo"
                                            @change="onFileChange"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col v-if="showUpdate" cols="12" sm="3">
                                        <v-switch v-model="update" label="Atualizar Dados"></v-switch>
                                    </v-col>
                                </v-row>
                               
                                    <v-alert
                                        v-if="failures.length > 0"
                                        dense
                                        outlined
                                        type="error"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="success darken-1" icon @click.prevent.stop="downloadErrors()" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                                                </template>
                                                <span> Lista de erros </span>
                                            </v-tooltip>
                                                Houve erros na importação em <strong>{{ failures.length }}</strong> linhas!
                                        </v-alert>
                                <v-expansion-panels>
                                    <v-expansion-panel
                                    >
                                    <v-expansion-panel-header>
                                        Mais informações sobre os campos...
                                        <template v-slot:actions>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="success darken-1" icon @click.prevent.stop="download()" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                                                </template>
                                                <span> Baixar layout </span>
                                            </v-tooltip>
                                            <v-icon color="primary">
                                                $expand
                                            </v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-alert
                                            dense
                                            type="info"
                                            >
                                            <ul>
                                                <li :key="header.value" v-for="header in headers">
                                                    <span v-if="header.description">{{ header.text + ': ' + header.description }}</span>
                                                    <span v-else>{{ header.text }}</span>
                                                </li>
                                            </ul>
                                        </v-alert>
                                       
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                                <v-divider
                                    v-if="errorsList"
                                    :dark="true"
                                    :light="false"
                                    inset
                                    vertical
                                >
                                
                                </v-divider>

                                <v-alert 
                                    v-if="errorsList"
                                    type="error"
                                >
                                <ul>
                                    <li :key="error.key" v-for="error in errorsList">
                                        {{ error }}
                                    </li>
                                </ul>
                                            
                                </v-alert>
                                
                                <v-divider
                                    :dark="true"
                                    :light="false"
                                    inset
                                    vertical
                                ></v-divider>


                            </template>
                           
                        </v-data-table>
                    </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <vue-csv-import v-model="parseCsv" url="/url/to/post" :map-fields="['array', 'of', 'field', 'names']"></vue-csv-import> -->
                    <v-btn @click.native="dialog = false" :disabled="loading">Cancelar</v-btn>
                    <v-btn :color="update ? 'indigo' : 'success'" @click.prevent.stop="save()" :loading="loading" :disabled="loading || selected.length == 0" >{{ update ? "Atualizar" : "Importar" }} <v-icon right dark>mdi-cloud-upload</v-icon></v-btn>
                    <slot name="actions" />
                </v-card-actions>

                

            </v-card>
    </v-dialog>

</template>

<script>

import * as mixins from '../../../mixins';

export default {
    name: "SysImportBase",
    data: () => ({
        update: false,
        dialog: false,
        errorsList: null,
        selected: [],
        fileUpload: null,
        failures: [],
        rules: [
            value => !value || value.size < 2000000 || 'Arquivo não pode ser maior que 2 MB!',
        ],
        items: [],
    }),
    created(){
        
    },
    computed: {
        
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.fileUpload = null;
                this.items = [];
                this.selected = [];
                this.errorsList = null;
                this.failures = [];
                this.update = false;
            }
        },

        errors(val) {
            this.errorsList = val;
        },

        failuresList(val){
            this.failures = val;
        }

        
    },
    props: {
        
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-cloud-upload"
        },
        title: {
            type: String,
            requered: false
        },
        itemKey: {
            type: String,
            default: "index"
        },
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        headers: {
            type: Array,
            default: null
        },
        errors: {
            type: Array,
            default: null
        },
        showUpdate: {
            type: Boolean,
            default: false
        },
        failuresList : {
            default: null
        }
        
    },
    methods:{
        
            save(){
               if(this.selected && this.selected.length > 0){
                    
                    if(this.update){
                        this.$emit('onUpdate', this.selected);
                    }else{
                        this.$emit('onSave', this.selected);
                    }
                    
               }
            },
            
            onFileChange(file) {
                
                this.errorsList = null;
                
                if(file){
                    this.createInput(file);
                }else{
                    this.fileUpload = null;
                    this.items = [];
                    this.selected = [];
                    this.failures = [];
                }
            },
            
            createInput(file) {
                
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.loadItems(reader.result);
                }

                reader.readAsBinaryString(file);

            },

            loadItems(result){

                var array = result.split(/\r?\n/);
                var columns = array[0];

                if(this.validateFields(columns)){
                    
                    this.items = [];
                    this.selected = [];
                    
                    for (let i = 1; i < array.length; i++) {
                        
                        if(array[i] == ""){
                            continue;
                        }
                        
                        var register = array[i].split(';');
                        
                        if(register.length > 0){
                        
                            var item = {};

                            for (let h = 0; h < this.headers.length; h++) {
                                
                                var index = this.getIndexHeader(columns, this.headers[h].text);
                                Object.assign(item, { index: i });
                                Object.assign(item, { [this.headers[h].value]: register[index] });
                            }

                            this.items.push(item);
                            this.selected.push(item);
                        }
                    }
                }
            },

            validateFields(fields){

                var array = fields.toLowerCase().split(';');
                var count = 0;
                
                this.headers.forEach(h => {
                    if(!array.includes(h.text.toLowerCase())){
                        count++;
                    }
                });

                if(count > 0){
                    this.errorsList = [];
                    this.errorsList.push('Campos inválidos. Consulte a lista de campos necessários');
                }

                return count == 0;

            },

            getIndexHeader(fields, value){
                
                var array = fields.toLowerCase().split(';');
                var indexReturn = -1;
                
                for (let index = 0; index < array.length; index++) {
                    
                    if(array[index] == value.toLowerCase()){
                        indexReturn = index;
                    }
                }

                return indexReturn;
            },

            download(){
                
                var text = '';

                this.headers.forEach(header => {
                    
                    if(text != ''){
                        text += ';';
                    }

                    text += header.text;

                });
                
                mixins.downloadCsv(text, 'Layout');
            },

            downloadErrors(){
                
                var text = '';

                this.headers.forEach(header => {
                    
                    if(text != ''){
                        text += ';';
                    }

                    text += header.text;

                });

                text += ';Erro'

                this.failures.forEach(item => {
                    
                    text += '\n';
                    
                    this.headers.forEach(header => {
                        text += item.item[header.value];
                        text += ';'
                    });

                    text += item.errors;
                    
                });
                
                mixins.downloadCsv(text, 'Erros');
            }
    }
}
</script>