import { default as setting } from  './settting/services'
import { default as event_public } from  './event_public/services'
import { default as area } from  './area/services'

export default {
    ...setting,
    ...event_public,
    ...area,
    calendars:             { method: 'get',     url: 'event_calendars' },
    calendarCreate:        { method: 'post',    url: 'event_calendars' },
    calendarUpdate:        { method: 'put',     url: 'event_calendars/{id}' },
    calendarDelete:        { method: 'delete',  url: 'event_calendars/{id}' },
    eventTypes:            { method: 'get',  url: 'event_types' },
    scopes:                { method: 'get',  url: 'event_scopes' },
    calendarHolidays:      { method: 'get',  url: 'event_calendars/holidays' },
    
}
