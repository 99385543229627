<template>
    <v-card tile class="elevation-1">
        
            <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" class="mb-4 elevation-1">
                        <v-icon left></v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">Endereço</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="tools"></slot>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="loadAddress(true)" v-bind="attrs" v-on="on">
                                        <v-icon color="orange lighten-2">mdi-eraser</v-icon>
                                    </v-btn>
                                </template>
                                <span>Limpar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
        
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="4">
                    <v-text-field 
                        ref="field_cep"
                        v-mask="'99999-999'" 
                        :error="errors.length > 0" 
                        :error-messages="errors.cep" 
                        v-model="address.cep" 
                        label="Cep"
                        v-on:keyup.enter="searchCity()"
                        :loading="loading"
                        @input="changeValue"
                    >
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="address.cep == null || address.cep.length < 9" v-bind="attrs" v-on="on" icon @click.prevent.stop="searchCity()">
                                        <v-icon btn>mdi-location-enter</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                Carregar dados...
                                </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click.prevent.stop="show = true">
                                        <v-icon btn>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                Pesquisar CEP
                                </span>
                            </v-tooltip>

                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="9">
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.city_id" disabled dense label="Município" v-model="address.localidade"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.state_id" disabled dense label="UF" v-model="address.uf"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="9">
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.address" dense v-model="address.logradouro" label="Endereço"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">           
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.address_number" dense v-model="address.numero" label="Número"></v-text-field>
                </v-col>
            </v-row>
            <v-row>  
                <v-col cols="12" sm="4">           
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.complement" dense v-model="address.complemento" label="Complemento"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">           
                    <v-text-field @input="changeValue" :error="errors.length > 0" :error-messages="errors.district"  dense v-model="address.bairro" label="Bairro"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <sys-address-find :show.sync="show" @onSelect="selectedCep" />
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
    name: "SysAddressBase",
    data: () => ({
        loading: false,
        show: false,
        states: [],
        address: {
            city_id: null,
            cep: '',
            localidade: '',
            logradouro: '',
            complemento: '',
            numero: '',
            bairro: '',
            uf: '',
            gia: '',
            ddd: '',
            siafi: '',
        }
    }),

    props: {
        
        model: {

        },

        errors: {
            required: true
        },
       
    },

    created(){
      
        this.loadAddress();
        this.getStates();
      
    },

    watch: {
        
        model(val){
            this.loadAddress();
        }

    },

    methods:{
        ...mapActions('city', ['ActionSearchCities', 'ActionCreateCity', 'ActionSearchStates']),

        changeValue(){
            
            this.model.city_id          = this.address.city_id;
            this.model.cep              = this.address.cep;
            this.model.address          = this.address.logradouro;
            this.model.complement       = this.address.complemento;
            this.model.district         = this.address.bairro;
            this.model.address_number   = this.address.numero;
            
            this.$emit("update:model", this.model);

        },
        
        async getStates(){

            var payload = {
                per_page: 50
            };

            await this.ActionSearchStates(payload)
                .then((res) => {
                    this.states = res.data;
                })
                .finally(() => {
                    
                });
        },
        
        getCity(){
            
            this.loading = true;
            
            var payload = {
                with: 'state',
                search: this.address.ibge,
                showAllCities: true
            }
        
            this.ActionSearchCities(payload)
                .then((res) => {
                    
                    if(res.data && res.data.length == 0){
                        this.createCity();
                    }else{
                        this.address.city_id = res.data[0].id;
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.changeValue();
                });
        },
        
        createCity(){

            var payload = {

                state_id:   this.states.find(i => i.initials.toUpperCase() == this.address.uf.toUpperCase()).id,
                name:       this.address.localidade,
                ibge:       this.address.ibge,
                gia:        this.address.gia,
                ddd:        this.address.ddd,
                siafi:      this.address.siafi,
            };

            this.ActionCreateCity(payload)
                .then((res) => {
                    this.address.city_id = res.data.id;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.changeValue();
                });

        },


        loadAddress(clear = false){

            if(!clear && this.model && this.model.id){
                
                this.address.city_id        = this.model.city ? this.model.city.id : null;
                this.address.cep            = this.model.cep;
                this.address.localidade     = this.model.city ? this.model.city.name : '';
                this.address.logradouro     = this.model.address;
                this.address.complemento    = this.model.complement;
                this.address.bairro         = this.model.district;
                this.address.uf             = this.model.city ? this.model.city.state.initials : '';
                this.address.gia            = this.model.city ? this.model.city.gia : '';
                this.address.ddd            = this.model.city ? this.model.city.ddd : '';
                this.address.siafi          = this.model.city ? this.model.city.siafi : '';
                this.address.numero         = this.model.address_number;
            }else{
                
                this.address.city_id        = null;
                this.address.cep            = null;
                this.address.localidade     = null;
                this.address.logradouro     = null;
                this.address.complemento    = null;
                this.address.bairro         = null;
                this.address.uf             = null;
                this.address.gia            = null;
                this.address.ddd            = null;
                this.address.siafi          = null;
                this.address.numero         = null;
            }

            if(clear){
                this.$refs.field_cep.focus();
            }

        },
        
        searchCity(){
            
            if(this.address.cep < 9){
                return;
            }
            
            this.loading = true;
            var cep = this.address.cep.replace('-', '').replace('.', '');
            this.message = '';
                
            axios.get('https://viacep.com.br/ws/' + cep + '/json/')
                    .then(res => {
                        
                        if(res.status && res.status == 200){
                            this.address = res.data;
                            this.address.complemento = '';
                            this.getCity();
                        }else{
                            this.message = 'Cep não Localizado';
                        }
                    })
                    .catch((error) =>{
                        this.message = 'Cep não Localizado';
                    })
                    .finally(() => {
                        this.loading = false;
                    });
        },

        selectedCep(ev){
            this.address.cep = ev.cep;
            this.searchCity();
            this.show = false;
        }
      
    }
    
}
</script>