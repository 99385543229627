import { service } from '../../../helpers/http'

export const ActionFindTeachers = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'teacher', 
		action: 'teachers', 
		payload: payload 
	})
}

export const ActionFindTeacher = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'teacher', 
		action: 'teacher', 
		payload: payload,
        idAndPayload: true
	})
}

export const ActionFindTeacherClasses = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'teacher', 
		action: 'classes', 
		payload: payload 
	})
}

export const ActionFindTeacherClassesOptimized = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'classesOptimized',
        payload: payload
    })
}

export const ActionFindTeacherClassSchedules = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'classSchedules',
        payload: payload
    })
}

export const ActionCreateTeacher = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'teacherCreate',
        payload: payload
    })
}

export const ActionUpdateTeacher = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'teacherUpdate',
        params: {id: payload.id, token: payload.token},
        payload: payload
    })
}

export const ActionUpdateDisciplines = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'updateDisciplines',
        payload: payload
    })
}

export const ActionUpdateDisciplinesToken = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'updateDisciplinesToken',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionDeleteTeacher = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'teacherDelete',
        payload: payload
    })
}

export const ActionGetProfessorById = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'teachers',
        payload: payload
    })
}

export const ActionFindTeacherByToken = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'findTeacherByToken',
        payload: payload
    })
}

export const ActionCreateEmail = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'createEmail',
        payload: payload
    })
}

export const ActionRemoveEmail = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'removeEmail',
        payload: payload
    })
}

export const ActionMailConfirm = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'confirmEmail',
        payload: payload
    })
}

export const ActionFindTitling = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'titling',
        payload: payload
    })
}

export const ActionFindRelationships = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'findRelationships',
        payload: payload
    })
}

export const ActionReleaseAvailability = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'releaseAvailability',
        payload: payload
    })
}

export const ActionFindTeacherClassSchedulesToken = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'classSchedulesToken',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionFindColorRaces = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'colorRaces',
        payload: payload
    })
}

export const ActionFindWorkRegimes = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'workRegimes',
        payload: payload
    })
}

export const ActionFindNationalities = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'nationalities',
        payload: payload
    })
}

export const ActionFindGenres = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'genres',
        payload: payload
    })
}

export const ActionFindStatusDeficiencies = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'statusDeficiencies',
        payload: payload
    })
}

export const ActionTeacherUploadAvatarToken = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'uploadAvatarToken',
        payload: payload
    })
}

export const ActionTeacherUploadAvatarId = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'uploadAvatarId',
        payload: payload
    })
}

export const ActionFindWorkLoadConfirmations = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'workLoadConfirmations',
        payload: payload
    })
}

export const ActionCreateWorkLoadConfirmation = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'createWorkLoadConfirmation',
        payload: payload
    })
}

export const ActionUpdateWorkLoadConfirmation = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'updateWorkLoadConfirmation',
        payload: payload
    })
}

export const ActionDeleteWorkLoadConfirmation = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'deleteWorkLoadConfirmation',
        payload: payload
    })
}

export const ActionFindWorkLoadConfirmationStatus = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'workLoadConfirmationStatus',
        payload: payload
    })
}

export const ActionUpdateWorkLoadConfirmationToken = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'workLoadConfirmationToken',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionIndexTeacherExtractSetting = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'indexTeacherExtractSetting',
        payload: payload
    })
}

export const ActionFindTeacherExtractSettingDefault = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'findTeacherExtractSettingDefault',
        payload: payload
    })
}

export const ActionUpdateTeacherExtractSetting = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'updateTeacherExtractSetting',
        payload: payload
    })
}

export const ActionIndexTeachersByCampuses = ({ dispatch }, payload) => {
    return service({
        module: 'teacher',
        action: 'indexTeachersByCampuses',
        payload: payload
    })
}