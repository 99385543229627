<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-warehouse" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-tabs class="ma-0" v-model="tab">
                <v-tab key="main">Dados Principais</v-tab>
                <v-tab key="address">Endereço</v-tab>
                <v-tab key="hourGroups">Grupos de Horário</v-tab>
                <v-tab key="others">Demais Dados</v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab">
                <v-tab-item key="main">
                    <v-container class="mt-2">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <s-text-field code dense :error-messages="errors.code" v-model="model.code" label="Código" />
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.name" v-model="model.name" label="Nome do Campus*" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.initials" v-model="model.initials" label="Sigla do Campus*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="9">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.abbreviation" v-model="model.abbreviation" label="Abreviação*" required></v-text-field>
                                </v-col>
                                
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <s-switch text-helper="Se essa oprção for marcada, o campus NÃO será considerado na validação de deslocamento na elaboração do horário" inset v-model="model.is_virtual" label="Campus Virtual"/>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <s-switch text-helper="Se essa estiver ativada, os items serão integrados com outros sistemas" inset v-model="model.allow_integration" label="Permitir Integração"/>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <s-switch inset v-model="model.active" label="Ativo"/>  
                                </v-col>
                            </v-row>
                    </v-container>
                    </v-tab-item>
                    <v-tab-item key="address">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-address-register :model.sync="model" type='campus' />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="hourGroups">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-data-table
                                        :headers="headers"
                                        :items="itemHourGroups"
                                        class="elevation-1"
                                        :hide-default-footer="true"
                                        :items-per-page="-1"
                                    >
                                        <template v-slot:top="{ on, attrs }">
                                            <v-btn color="indigo darken-1" @click.prevent.stop="addItem()" icon v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                                        </template>

                                        <template dense v-slot:item.hour_group_id="{ item, index }">
                                            <sys-select-hour-group
                                                :model.sync="item.hour_group_id"
                                                :error="errors.length > 0"  
                                                :errors="errors['hourGroups.' + index + '.hour_group_id']"
                                                label=""
                                                dense
                                            />
                                        </template>

                                        <template dense v-slot:item.category_group_id="{ item, index }">
                                            <sys-select-category-group
                                                :model.sync="item.category_group_id"
                                                :error="errors.length > 0"  
                                                :errors="errors['hourGroups.' + index + '.category_group_id']"
                                                label=""
                                                dense
                                                showClear
                                            />
                                        </template>
                                        
                                        
                                        <template v-slot:item.actions="{ item }">
                                            <v-icon
                                                small
                                                color="error"
                                                @click.prevent.stop="deleteItem(item)"
                                                dense
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    
                                    </v-data-table>
                                </v-col>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <sys-select-hour-group
                                            :model.sync="model.hour_group_id"
                                            :error="errors.length > 0"  
                                            :errors="errors.hour_group_id"
                                            label="Grupo de Horário*"
                                            required
                                        />
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="others">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-institution
                                        :model.sync="model.institution_id"
                                        :error="errors.length > 0"  
                                        :errors="errors.institution_id"
                                        label="Instituição"
                                        auto-complete
                                        dense
                                        @change="changeIes"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-company
                                        :model.sync="model.company_id"
                                        :error="errors.length > 0"  
                                        :errors="errors.company_id"
                                        label="Empresa"
                                        auto-complete
                                        showClear
                                    /> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <sys-select-branch
                                        :model.sync="model.branches"
                                        :error="errors.length > 0"  
                                        :errors="errors.branches"
                                        label="Filial"
                                        multiple
                                        dense
                                    /> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.company_name" v-model="model.company_name" label="Razão Social" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <sys-select-campus
                                        :model.sync="model.campus_id_parent"
                                        :error="errors.length > 0"  
                                        :errors="errors.campus_id_parent"
                                        label="Campus Gerencial"
                                        show-clear
                                        auto-complete
                                        dense
                                    /> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense :error="errors.length > 0" v-mask-cnpj :error-messages="errors.cnpj" v-model="model.cnpj" label="Cnpj" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense :error="errors.length > 0" :error-messages="errors.business_unit" v-model="model.business_unit" label="Unidade Negócio" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterCampus",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        tab: null,
        loading: false,
        categories: [],
        address: {},
        itemHourGroups: [],
        headers: [
            { text: 'Categoria', value: 'category_group_id', sortable: false, },
            { text: 'Grupo de Horário', align: 'start', sortable: false, value: 'hour_group_id' },
            { text: '', value: 'actions', sortable: false, },
        ],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            this.itemHourGroups = [];
           
            if(show_new){
                
                this.errors = {};
                this.tab = null;

                if(!this.model.id){
                    this.model.active = 1;
                }

                if(this.model.hourGroups){
                    Object.assign(this.itemHourGroups, this.model.hourGroups);
                }

                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Campus"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('campus', ['ActionCreateCampus', 'ActionUpdateCampus']),
        
        save(edit)
        {
            this.error = null;
            this.message = '';
            this.loading = true;

            Object.assign(this.model,{ 
                    hourGroups: this.itemHourGroups 
                });

            if(edit) {
                this.ActionUpdateCampus(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateCampus(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        changeIes(item){

            this.model.company_id = item.company_id;

        },

         addItem(){
            
            var item = {
                id:                 0,
                campus_id:          this.model.id ? this.model.id : null,
                hour_group_id:      null,
                category_group_id:  null,
            };

            this.itemHourGroups.push(item);
            
        },

        deleteItem(item){
            var index = this.itemHourGroups.indexOf(item);
            this.itemHourGroups.splice(index, 1);
            
        },

    }
}
</script>