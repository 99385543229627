import { service } from '../../../helpers/http'

export const ActionFindAmbienceTypes = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceType',
        action: 'ambienceTypes',
        payload: payload
    });
}

export const ActionFindAmbienceTypesFilter = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceType',
        action: 'ambienceTypesFilter',
        payload: payload
    });
}

export const ActionCreateAmbienceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceType',
        action: 'createAmbienceType',
        payload: payload
    });
}

export const ActionUpdateAmbienceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceType',
        action: 'updateAmbienceType',
        payload: payload
    });
}

export const ActionDeleteAmbienceType = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceType',
        action: 'deleteAmbienceType',
        payload: payload
    });
}
