export default [
    {
        path: '/posting-financial-items',
        name: 'posting-financial-items',
        component: () => import ('./pages/posting/PostingFinancialItems'),
        meta: {
            title: 'Lançamentos financeiros',
            icon: 'mdi-cash-register',
            breadcrumbs: [
                { text: 'Lista de lançamentos', disabled: true }
            ]
        }
    },
    {
        path: '/financial-posting-types',
        name: 'financial-posting-types',
        component: () => import ('./pages/type/FinancialPostingTypes'),
        meta: {
            title: 'Categoria dos lançamentos financeiros',
            icon: 'mdi-sitemap',
            breadcrumbs: [
                { text: 'Tipos de lançamentos financeiros', disabled: true }
            ]
        }
    }
]