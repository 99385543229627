var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","left":"","bottom":"","max-width":(_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm') ? '90%' : '90%'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey","small":_vm.small}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber"}},'v-icon',attrs,false),on),[_vm._v("mdi-flash-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.toolTip))])])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_c('v-app-bar',{staticClass:"grey lighten-5 mb-4 elevation-1'",attrs:{"dense":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","small":""},nativeOn:{"click":function($event){_vm.menu = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-2"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1)],1),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('emc-util-scroll',{attrs:{"maxHeight":"400px"}},[(_vm.user.is_master)?_c('emc-button-icon',{attrs:{"text":"Iniciar Merge","icon":"mdi-table-merge-cells"},on:{"click":function($event){return _vm.$emit('startMerge')}}}):_vm._e(),(_vm.user.is_master)?_c('emc-button-icon',{attrs:{"text":"Iniciar Espelhamento de todas as turmas origens","icon":"mdi-account-switch-outline"},on:{"click":function($event){return _vm.$emit('startUpdateRelocation')}}}):_vm._e(),(_vm.user.is_master)?_c('emc-button-icon',{attrs:{"text":"Iniciar Espelhamento da turma origem selecionada","disabled":_vm.disabled,"icon":"mdi-account-switch","color":"purple"},on:{"click":function($event){return _vm.$emit('startUpdateRelocationItem')}}}):_vm._e(),_c('emc-button-icon',{attrs:{"disabled":_vm.disabled,"text":"Atualizar Oferta da linha selecionada","color":"amber","icon":"mdi-flash-outline"},on:{"click":function($event){return _vm.$emit('generateGrid', false)}}}),_c('emc-button-icon',{attrs:{"disabled":_vm.disabled,"text":"Atualizar Oferta de todas as linha do mesmo chamado","color":"purple","icon":"mdi-flash-outline"},on:{"click":function($event){return _vm.$emit('generateGrid', true)}}}),(_vm.user.is_internal || _vm.user.id == 334)?_c('emc-button-icon',{attrs:{"text":"Gerar Todas as Ofertas","color":"error","icon":"mdi-flash-outline"},on:{"click":function($event){return _vm.$emit('generateGridAll', 'grids')}}}):_vm._e(),(_vm.user.is_internal)?_c('emc-button-icon',{attrs:{"text":"Gerar Todas as Junções","color":"primary","icon":"mdi-flash-outline"},on:{"click":function($event){return _vm.$emit('generateGridAll', 'joins')}}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }