export default [
    {
        path: '/categories',
        name: 'categories',
        component: () => import ('./pages/CategoryRegister'),
        meta: {
            title: 'Categoria dos cursos',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Categoria dos cursos', disabled: true, href: '/' }
            ],
        }
    },
]