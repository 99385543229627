<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo darken-1" icon v-bind="attrs" v-on="on" @click="$emit('update:show', true)"><v-icon>mdi-plus</v-icon></v-btn>
        </template>
        <span> Novo Cadastro </span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'EmcButtonIconCreate',
    emits: ['update:show']
}
</script>