<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="290px"
    >
        <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary">Fechar</v-btn>
            <v-btn text color="primary">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    name: "EmcCalendarDialogDatePicker",
    data: (vm) => ({
        modal: false,
        date: vm.model,
        dateFormatted: vm.formatDate(vm.model),
    }),
    props: {
        date: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true
        }
    },
}
</script>