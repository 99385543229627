export default {
    worktimes:          { method: 'get',    url: 'work_times' },
    worktimeCreate:     { method: 'post',   url: 'work_times' },
    worktimeUpdate:     { method: 'put',    url: 'work_times/{id}' },
    worktimeDelete:     { method: 'delete', url: 'work_times/{id}' },
    frequencies:        { method: 'get',    url: 'frequencies' },
    workTimeDateDelete: { method: 'delete', url: 'work_time_dates/{id}' },
    workTimeDateFind:   { method: 'get',    url: 'work_time_dates' },
    showWorkTime:       { method: 'get',    url: 'work_times/{id}'},
    destroyIntegrationWorkTime: { method: 'delete', url: 'integration-work-times/{id}'},
    updateIntegrationWorkTime: { method: 'put', url: 'integration-work-times/{id}'},
    storeIntegrationWorkTime: { method: 'post', url: 'integration-work-times'},
    replicateIntegrationWorkTime: { method: 'post', url: 'integration-work-times/replicate'},
}