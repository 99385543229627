export default [
    {
        path: '/user-imports',
        name: 'user-imports',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Importações',
            icon: 'mdi-cloud-upload-outline',
            breadcrumbs: [
                { text: 'Lista de Importações', disabled: true }
            ]
        }
    }
]