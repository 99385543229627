<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :disabledSave="disabled"
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field autofocus dense :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-dynamic-allocation-type
                                :model.sync="model.dynamic_allocation_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.dynamic_allocation_type_id"
                                label="Tipo Alocação"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno"
                                :maxShift="3"
                                :disabled="model.dynamic_allocation_status_id != 1"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-campus
                                :model.sync="model.campuses"
                                :error="errors.length > 0"  
                                :errors="errors.campuses"
                                label="Campus*"
                                multiple
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-select
                                v-model="model.limit_seconds"
                                item-value="id"
                                item-text="name"
                                :items="minutes.filter(m => m.active)"
                                :error="!!errors.limit_seconds"
                                :error-messages="errors.limit_seconds"
                                label="Tempo"
                                dense
                            />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDynamicAllocationRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        disabled: false,
        minutes: [
            { id: 60,   name: '1 min',   active: false },
            { id: 120,  name: '2 min',  active: true },
            { id: 180,  name: '3 min',  active: true },
            { id: 240,  name: '4 min',  active: true },
            { id: 300,  name: '5 min',  active: true },
            { id: 600,  name: '10 min', active: true },
            { id: 900,  name: '15 min', active: true },
            { id: 1200, name: '20 min', active: true },
            { id: 1500, name: '25 min', active: true },
            { id: 1800, name: '30 min', active: true },
            { id: 2700, name: '45 min', active: true },
            { id: 3600, name: '1 Hora', active: true },
        ]
        
    }),
    created() {
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.disabled = false;
                
                if(!this.model.id){
                    
                    this.model.limit_seconds = 120;
                    this.model.name = 'Nova Alocação';
                    this.model.dynamic_allocation_type_id = 1;
                    this.model.dynamic_allocation_status_id = 1;

                }else{
                    if(this.model.dynamic_allocation_status_id == 3){
                        this.disabled = true;
                    }
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-puzzle-outline"
        },
        title: {
            type: String,
            default: "Alocação Dinâmica"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('dynamicGeneration', ['ActionUpdateDynamicAllocation', 'ActionCreateDynamicAllocation']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateDynamicAllocation(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateDynamicAllocation(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>