<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            @onShowForm="showForm = true"
            :showClear="showClear"
            :showAdd="showAdd"
            :dense="dense"
            :multiple="multiple"
            :selectAll="selectAll"
            :auto-complete="autoComplete"
            :item-text="itemText"
            :disabled="disabled"
        />
        <sys-register-branch @onCompleted="newItem" :model="{}" v-bind:show.sync="showForm" />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectBranch',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
        showForm: false
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    mounted(){
        
    },
    created() {
        this.getItems();
    },
    props: {
        
        error: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        showClear: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        dense: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'name'
        },
        companyId: {

        },
        campusId: {

        }
    },
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },

        companyId(val){
            this.getItems();
        },

        campusId(val){
            this.getItems();
        },
        
    },
    methods: {
        ...mapActions('paymentExport', ['ActionIndexBranches']),        
        
        async getItems() {

            this.loading = true;

            var payload = {
                column_order: 'name',
            };

            if(this.companyId){
                payload.find_columns = {
                    company_id: this.companyId
                }
            }

            if(this.campusId){
                payload.filter_relations = [
                    {
                        name:   'campuses',
                        key:    'campus_id',
                        value:  this.campusId
                       
                    }
                ];
            }

            await this.ActionIndexBranches(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

    newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>