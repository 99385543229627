<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        icon="search" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
        :showButton="false"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-campus
                                :model.sync="search.campus_id"
                                :error="errors && errors.campus_id > 0"  
                                :errors="errors ? errors.campus_id : []"
                                label="Campus*"
                                dense
                                auto-complete
                            />
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <emc-calendar-date-picker-range dense :errorMessages1="errors.start_date" :errorMessages2="errors.end_date" :show="dialog" :model1.sync="date.start" :model2.sync="date.end" label="Data" />
                        </v-col> -->
                        <v-col cols="12" sm="6">
                            <sys-select-ambience-type
                                :model.sync="search.types"
                                label="Tipo de Ambiente*"
                                multiple
                                dense
                                selectAll
                                :campus-id="search.campus_id"
                                :disabled="search.campus_id == null"
                            />
                        </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="12" sm="3">
                            <s-text-field
                                v-model="search.capacity_min"
                                :error-messages="errors ? errors.capacity_min : []"
                                label="Cap. Mínima"
                                inset
                                text-helper="Capacidade mínima do ambiente. Essa informação é opcional"
                            />
                        </v-col>
                         <v-col cols="12" sm="3">
                             <s-text-field
                                v-model="search.capacity_max"
                                :error-messages="errors ? errors.capacity_max : []"
                                label="Cap. Máxima"
                                text-helper="Capacidade máxima do ambiente. Essa informação é opcional"
                            />
                        </v-col>
                         <v-col cols="12" sm="6">
                            <v-radio-group
                                v-model="search.empty"
                                row
                            >
                                <v-radio
                                    label="Vazias"
                                    value="1"
                                />
                                <v-radio
                                    label="Ocupadas"
                                    value="0"
                                    color="purple"
                                />
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-expansion-panels focusable>
                                <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <span>
                                        <strong>Recursos Disponíveis {{ selectedIndexResources.length ? '(' + selectedIndexResources.length + ')' : '' }}</strong>
                                        <small v-if="selectedIndexResources.length">
                                            <br/>
                                            {{ getFilterResources() }}
                                        </small>
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list>
                                    <!-- <v-subheader>PROFESSORES</v-subheader> -->
                                    <v-list-item-group
                                        v-model="resourcesSelecteds"
                                        color="primary"
                                    >
                                            <emc-util-scroll height="45vh">
                                                <v-list-item
                                                    v-for="(item, index) in resources"
                                                    :key="index"
                                                    @mousedown.prevent.stop
                                                    >
                                                    <v-list-item-icon @click.prevent.stop="selectResource(index)">
                                                        <v-icon :color="isCheckedResource(index) ? 'indigo darken-4' : ''" v-text="isCheckedResource(index) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'" />
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <v-row>
                                                                <v-col cols="12" sm="8">
                                                                    {{ item.name_complete }}
                                                                    <small v-if="item.type"><br/>{{ item.type.name }}</small>
                                                                </v-col>
                                                                <v-col v-if="!item.shared" cols="12" sm="4">
                                                                    <v-text-field
                                                                        v-model="item.total"
                                                                        label="Quantidade"
                                                                        :error="errors.length > 0"  
                                                                        :error-messages="errors['resources.' + index + '.total']"
                                                                        :disabled="!isCheckedResource(index)"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-title>
                                                        
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </emc-util-scroll>
                                    </v-list-item-group>
                                </v-list>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-simple-table fixed-header height="300px">
                                
                            <template v-slot:top>
            
                                <v-progress-linear
                                    v-if="loadingHours"
                                    indeterminate
                                    color="yellow darken-2"
                                />
                                
                            </template>
                                
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>
                                              <v-simple-checkbox
                                                :indeterminate="indeterminate"
                                                :value="valueCheckAll"
                                                 @click.prevent.stop="selectAll()"
                                                color="success"
                                                />
                                            </th>
                                            <th v-for="(day, i) in filteredDays" :key="i" class="text-center">
                                                {{ day.name }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(hour, i) in filteredHours"
                                            :key="i"
                                        >
                                            <td>
                                                <small>{{ hour.start }}</small>
                                                <br/>
                                                <small>{{ hour.end }}</small>
                                            </td>
                                            <td v-for="(day, i) in filteredDays" :key="i" class="text-center">
                                            <v-simple-checkbox
                                                :value="isSelected(day.id, hour)"
                                                @click.prevent.stop="select(day.id, hour)"
                                                color="success"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="3">
                                                <sys-select-hour-group
                                                    :model.sync="hour_group_id"
                                                    label="Grupo de Horário"
                                                    dense
                                                    @change="changeHourGroup"
                                                    class="mt-4"
                                                    auto-complete
                                                />
                                            </td>
                                            <td colspan="4"></td>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
        <template v-slot:actions>
            <v-spacer/>
            <v-btn :disabled="disabledSearch || loading" :loading="loading" color="primary" @click.prevent.stop="searchAmbiences()">Pesquisar<v-icon right dark>search</v-icon></v-btn>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysAmbienceSearch",
    data: (vm) => ({
        dialog: false,
        error: false,
        loadingHours: false,
        valueCheckAll: false,
        resources: [],
        hour_group_id: false,
        resourcesSelecteds: [],
        selectedIndexResources: [],
        search: {
            campus_id: null,
            dates: [],
            types: [],
            empty: '1',
            capacity_min: null,
            capacity_max: null,
            resources: [],
        },
        days: [
            {id: 1, name: 'Domingo',    visible: false},
            {id: 2, name: 'Segunda',    visible: true},
            {id: 3, name: 'Terça',      visible: true},
            {id: 4, name: 'Quarta',     visible: true},
            {id: 5, name: 'Quinta',     visible: true},
            {id: 6, name: 'Sexta',      visible: true},
            {id: 7, name: 'Sábado',     visible: true},
        ],
        hours: [],
        hoursIds: [],
        errors: {},
        message: '',
        loading: false,
        date: {
            start: null,
            end: null
        }
        
    }),
    created(){
       this.loadHours();
       this.loadResources();
    },

    computed: {
      ...mapState('auth', ['acesso']),
      
      filteredDays(){
        return this.days.filter(d => d.visible);
      },

      filteredHours(){
        
        if(this.hoursIds.length > 0){
            return this.hours.filter(h => this.hoursIds.includes(h.id));
        }else{
            return this.hours;
        }

      },
    
      disabledSearch(){
        return !this.search.campus_id || !this.search.types || this.search.types.length == 0 || !this.search.dates || this.search.dates .length == 0;
      },

      indeterminate(){
            
            var total = this.filteredHours.length * this.filteredDays.length;

            if(this.search.dates.length > 0 && this.search.dates.length < total){
                return true;
            }else{
                return false;
            }
      },

    },

    watch: {
        indeterminate(newValue) {
            if(newValue) {
                this.valueCheckAll = false;
            }
        },
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.hoursIds = [];
                this.hour_group_id = null;

                if(this.autoSearch && this.searchStart){
                    this.loadSearchStart();
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Pesquisa de Ambientes"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        searchStart: {
            type: Object,
            default: null
        },
        autoSearch: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('ambience', ['ActionSearchAmbiences']),
        ...mapActions('hour', ['ActionGetHours']),
        ...mapActions('resource', ['ActionFindResources']),

        loadSearchStart(){
            
            this.search.campus_id = this.searchStart.campus_id;
            this.search.types = [];
            this.search.dates = [];
            this.search.empty = '1';
            this.search.capacity_min = null;
            this.search.capacity_max = null;
            this.valueCheckAll = false;
            this.hoursIds = [];

            this.search.capacity_min = this.searchStart.students;

            if(this.searchStart.ambience_type_id){
                this.search.types.push({id: this.searchStart.ambience_type_id});
            }

            this.searchStart.classes.forEach(classe => {
                
                var hour = this.filteredHours.find(h => h.id == classe.hour_id);
                var type =  this.search.types.find(t => t.id == classe.ambience_type_id);

                if(!type && !this.searchStart.ambience_type_id && classe.ambience_type_id){
                    this.search.types.push({id: classe.ambience_type_id});
                }

                if(!this.searchStart.students && parseInt(classe.students_join) > this.search.capacity_min){
                    this.search.capacity_min = parseInt(classe.students_join);
                }

                if(hour){
                   
                   var key = classe.day_of_week_id + '|' + classe.hour_id;
                   var find = this.search.dates.find(d => d.key == key);

                   if(!find){
                        this.search.dates.push({
                            key:            key,
                            day_of_week_id: classe.day_of_week_id,
                            hour_start:     hour.start,
                            hour_end:       hour.end,
                            date_start:     null,
                            date_end:       null,
                        });
                   }

                   if(this.hoursIds.includes(hour.id)){
                        this.hoursIds.push(hour.id);
                   }

                }
            });
        },

        changeHourGroup(item){
            this.hour_group_id = item.id;
            this.loadHours();
        },
        
        loadHours(){

            
            this.loadingHours = true;
            
            var payload = {
                find_columns: {
                    active: 1
                },
            }

            if(this.hour_group_id){
                payload.filter_relations = [
                    {
                        name:   'groups',
                        key:    'id', 
                        value:  this.hour_group_id,
                    }
                ];
            }else{
                payload.filter_relations = [
                    {
                        name:   'groups',
                        key:    'is_default', 
                        value:  1,
                    }
                ];
            }
        
            this.ActionGetHours(payload)
                .then((res) => {
                    this.hours = res.data;
                })
                .finally(() => {
                    this.loadingHours = false;
                })

        },

        loadResources(){

            
            var payload = {
                find_columns: {
                    active: 1
                },
                with: 'type'
            }
            
            this.ActionFindResources(payload)
                .then((res) => {
                    this.resources = res.data;
                    this.resources.forEach(r => {
                        if(r.shared){
                            r.total = 1;
                        }else if(this.search.capacity_min){
                            r.total = this.search.capacity_min;
                        }else{
                             r.total = 1;
                        }
                    });
                })
                .finally(() => {
                    
                })
        },

        selectAll(){

            if(this.indeterminate || this.valueCheckAll){
                
                this.search.dates = [];
                this.valueCheckAll = false;
                
            }else{
                this.filteredHours.forEach(hour => {
                    this.filteredDays.forEach(day => {
                        this.select(day.id, hour, false);
                    });
                });

                this.valueCheckAll = true;
            }
        },

        select(day, hour, updateStatus = true){
            
            var key = day + '|' + hour.id;
            
            var find = this.search.dates.find(d => d.key == key);

            if(find){
                var index = this.search.dates.indexOf(find);
                this.search.dates.splice(index, 1);
            }else{
                this.search.dates.push({
                    key:            key,
                    day_of_week_id: day,
                    hour_start:     hour.start,
                    hour_end:       hour.end,
                    date_start:     this.date.start,
                    date_end:       this.date.end,
                });          
            }

            if(updateStatus){
                if(this.search.dates.length == (this.filteredHours.length * this.filteredDays.length)){
                    this.valueCheckAll = true;
                }else if(this.search.dates.length == 0){
                    this.valueCheckAll = false;
                }
            }
        },

        isSelected(day, hour){
            var key = day + '|' + hour.id;
            return this.search.dates.find(d => d.key == key) != null;
        },
        
        searchAmbiences()
        {
            this.error = false;
            this.errors = {};
            this.message = '';
            this.loading = true;

            var payload = {
                with: 'block,floor,type,campus',
            };

            this.search.resources = [];

            for (let i = 0; i < this.resources.length; i++) {
                
                if(!this.selectedIndexResources.includes(i)){
                    continue;
                }

                var resource = this.resources[i];
                
                this.search.resources.push({
                    id:     resource.id,
                    total:  resource.total,
                    shared: resource.shared,
                    enable: this.selectedIndexResources.includes(i),
                });

                
            }

            Object.assign(payload, { search : {
                campus_id:      this.search.campus_id,
                types:          this.search.types.map(o => o.id),
                dates:          this.search.dates,
                capacity_min:   this.search.capacity_min ? this.search.capacity_min : 0,
                capacity_max:   this.search.capacity_max ? this.search.capacity_max : 10000,
                empty:          this.search.empty,
                resources:      this.search.resources,
            }});

            this.ActionSearchAmbiences(payload)
            .then((res) => {
                this.$emit('onSearch', res.data);
            })
            .catch((error) =>{
                this.error      = true;
                this.errors     = (error && error.errors) ? error.errors : {};
                this.message    = error.message ? error.message : '';
            })
            .finally(() => {
                this.loading = false;
            });
            
        },

        selectResource(index){
            
            if(this.selectedIndexResources.includes(index)){
                this.selectedIndexResources.splice(this.selectedIndexResources.indexOf(index), 1);
            }else{
                this.selectedIndexResources.push(index);
            }
            
        },

        isCheckedResource(index){
            return this.selectedIndexResources.includes(index);
        },

        getFilterResources(){
            
            var text = '';
            
            this.selectedIndexResources.forEach(i => {
                
                if(text != ''){
                    text += ', ';
                }
                
                text += this.resources[i].name;
            });

            return text;
        }

    }
}
</script>