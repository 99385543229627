import { service } from '../../../../helpers/http'

export const ActionIndexPlaces = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'ambience', 
		action: 'indexPlaces', 
		payload: payload 
	})
}

export const ActionCreatePlace = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'ambience', 
		action: 'createPlace', 
		payload: payload 
	})
}

export const ActionUpdatePlace = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'ambience', 
		action: 'updatePlace', 
		payload: payload 
	})
}

export const ActionDestroyPlace = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'ambience', 
		action: 'destroyPlace', 
		payload: payload 
	})
}