export default [
    {
        path: '/budgetTypes',
        name: 'budgetTypes',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Tipo de orçamento',
            icon: '',
            breadcrumbs: [
                { text: 'Financeiro', disabled: true, href: '/' }, 
                { text: 'Tipo de orçamento', disabled: true, href: '/' }, 
            ],
        }
    },
]