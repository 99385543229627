import { setBearerToken } from "@/http"

export const setHeaderToken = (token) => setBearerToken(token);
export const setParamsHeader = (access) => localStorage.setItem('access', access);
export const getParamsHeader = () => localStorage.getItem('access');
export const getLocalToken = () => localStorage.getItem('token');
export const setLocalToken = (token) => localStorage.setItem('token', token);
export const deleteLocalToken = () => { localStorage.removeItem('token'); } 
export const setLocalAcesso = (acesso) => localStorage.setItem('acesso', acesso);
export const getLocalAcesso = () => localStorage.getItem('acesso');
export const deleteLocalAcesso = () => localStorage.removeItem('acesso');
export const setLocalUser = (user) => localStorage.setItem('user', user);
export const getLocalUser = () => localStorage.getItem('user');
export const deleteLocalUser = () => localStorage.removeItem('user');
export const setLocalMenu = (menu) => localStorage.setItem('menu', menu);
export const getLocalMenu = () => localStorage.getItem('menu');
export const deleteLocalMenu = () => localStorage.removeItem('menu');

