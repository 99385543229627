export default [
    {
        path: '/vacancy-control',
        name: 'vacancy-control',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Controle de Vagas',
            icon: 'mdi-car-cruise-control',
            breadcrumbs: [
                { text: 'Controle de Vagas', disabled: true }
            ]
        }
    }
]