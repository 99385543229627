import { service } from '../../../helpers/http'

export const ActionFindClasses = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'find', 
		payload: payload 
	})
}

export const ActionCreateClasse = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesCreate', 
		payload: payload 
	})
}

export const ActionUpdateClasse = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesUpdate', 
		payload: payload 
	})
}

export const ActionUpdateAmbience = ({ dispatch }, payload ) => {
	return service({ 
		module: 'schedule', 
		action: 'classesUpdateAmbience', 
		payload: payload 
	})
}

export const ActionUpdateAmbiencesAll = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesUpdateAmbiencesAll', 
		payload: payload 
	})
}

export const ActionDeleteClasse = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesDelete', 
		payload: payload 
	})
}

export const ActionUpdateAll = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesUpdateAll', 
		payload: payload 
	})
}

export const ActionIndexClassesOptimized = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'indexClassesOptimized', 
		payload: payload 
	})
}

export const ActionClearClasses = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'clearClasses', 
		payload: payload 
	})
}

export const ActionReplicateClasses = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'schedule', 
		action: 'classesReplicate', 
		payload: payload 
	})
}