import { service } from '../../../helpers/http'
export * from '../sub_group/store/actions'
export * from '../identifier/store/actions'

export const ActionFindGroups = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groups',
        payload: payload
    })
}

export const ActionFindGroupById = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupById',
        idAndPayload: true,
        payload: payload
    })
}

export const ActionFindGridsOptimized = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'gridsOptimized',
        payload: payload
    })
}

export const ActionGetGroupsTree = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupsTree',
        payload: payload
    })
}

export const ActionGetGroupsTreePrint = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupsTreePrint',
        payload: payload
    })
}

export const ActionGetGroupsTimeSheet = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupsTimeSheet',
        payload: payload
    })
}

export const ActionGetStatus = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'status',
        payload: payload
    })
}

export const ActionCreateGroup = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupCreate',
        payload: payload
    })
}

export const ActionUpdateGroup = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupUpdate',
        payload: payload
    })
}

export const ActionGenerateGrids = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'generateGrids',
        payload: payload
    })
}

export const ActionGenerateGridsAll = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'generateGridsAll',
        payload: payload
    })
}

export const ActionDeleteGroup = ({ dispatch }, payload) => {
    return service({
        module: 'group',
        action: 'groupDelete',
        payload: payload
    })
}

export const ActionUpdateValidation = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'updateValidation',
        payload: payload
    })

}

export const ActionRefreshPendencies = ({ dispatch }, payload) => {

    return service({
        module: 'group',
        action: 'refreshPendencies',
        payload: payload
    })

}