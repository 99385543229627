<template>
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
            <v-app-bar color="grey lighten-4" class="elevation-0" dense>
                <v-toolbar-title><v-icon left>mdi-calendar-clock</v-icon>&nbsp;&nbsp; Enviar Email com horários do professor</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :color="(modelTemplate.id) ? 'teal darken-1' : 'red'" icon @click.prevent.stop="showEmailTemplate = true" v-bind="attrs" v-on="on"><v-icon>mdi-card-account-mail-outline</v-icon></v-btn>
                    </template>
                    <span>{{ (modelTemplate.id) ? 'Template do email' : 'Template de email não cadastrado' }}</span>
                </v-tooltip>
            </v-app-bar>
            
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="teachers"
                :single-select="false"
                item-key="id"
                show-select
                :search="search"
                class="elevation-1"
                dense
                :itemsPerPage="5"
                @current-items="getFiltered"
                :item-selected="selected"
                :loading="loading" 
            >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Pesquisar..."
                class="mx-4"
                ></v-text-field>
            </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :color="(modelTemplate.id) ? 'blue' : 'red'" small class="mr-2" @click="showEmail(item)" v-bind="attrs" v-on="on">mdi-newspaper-variant-outline</v-icon>
                        </template>
                        <span>{{ (modelTemplate.id) ? 'Visualizar' : 'Template de email não cadastrado' }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <div class="caption mx-2"><strong>Quantidade selecionada: </strong>{{ selected.length }}</div>

            <v-card-actions>
                <v-switch inset v-model="onlyMain" label="Exibir apenas turmas principais"/>
                <v-spacer></v-spacer>
                <v-btn @click.native="dialog = false" :disabled="saving">Cancelar</v-btn>
                <v-btn color="primary" @click.prevent.stop="send()" :loading="saving" :disabled="saving || !modelTemplate.id || !selected.length" >Enviar<v-icon right dark>mdi-send</v-icon></v-btn>
            </v-card-actions>

        </v-card>

        <sys-register-template-email v-bind:onModel.sync="modelTemplate" v-bind:show.sync="showEmailTemplate" :templateId="2"/>
        <emc-loader-progress :show="loadingShowPreview" />
        <emc-modal-form v-bind:show.sync="showPreview">
            <template v-slot:form>
                <div v-html="previewMail"></div>
            </template>
        </emc-modal-form>

        <emc-alert-snackbar :show="!! message.length"  :message="message" color="success"/>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysMailAvailability',
    data: () => ({
        dialog: false,
        saving: false,
        selected: [],
        filtered: [],
        teachers: [],
        onlyMain: false,
        search: '',
        showEmailTemplate: false,
        modelTemplate: [],
        showPreview: false,
        loadingShowPreview: false,
        message: '',
        previewMail: '',
        loading: true,
        headers: [
            { text: 'Codigo', value: 'code'},
            { text: 'Nome', value: 'name'},
            { text: 'Email', value: 'email'},
            { text: 'Ações', value: 'actions', sortable: false}
        ],
    }),
    props: {
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    watch: { 
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            this.dialog = show_new;
            
            if(show_new){
                if(!this.teachers || this.teachers.length == 0){
                    this.getData();
                }
                
            }
        },
    },
    methods: {
        ...mapActions('teacher', ['ActionIndexTeachersByCampuses']),
        ...mapActions('mail', ['ActionSendEmailHours', 'ActionPreviewMailHours']),
        getData(){
            
            this.loading = true;

            this.ActionIndexTeachersByCampuses()
                .then((res) => {
                    this.selected = res.data;
                    this.teachers = res.data;
                })
                .finally(() => {
                    this.loading = false
                });
        },

        getFiltered(filtered){
            
            this.filtered = filtered
            
            if(this.search.length > 0) {
                this.selected = filtered
            }
            
        },
        showEmail(item) {
            this.loadingShowPreview = true
            this.ActionPreviewMailHours({ teacher_id: item.id, work_time_id: this.acesso.periodo.id, onlyMain: this.onlyMain})
                .then((res) => {
                    this.previewMail = res.data
                })
                .finally(() => {
                    this.showPreview = true
                    this.loadingShowPreview = false
                })
        },
        send() {
            this.saving = true
            this.message = ''
            
            var payload = {
                teachers: this.selected,
                onlyMain: this.onlyMain,
            };

            this.ActionSendEmailHours(payload)
                .then((res) => {
                    this.message = res.message
                })
                .finally(() => {
                    this.saving = false
                })
        }

    },
}
</script>