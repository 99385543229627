import { service } from '../../../helpers/http'

export const ActionFindEducationalGroups = ({ dispatch }, payload) => {
    return service({
        module: 'educationalGroup',
        action: 'educationalGroups',
        payload: payload
    })
}

export const ActionCreateEducationalGroup = ({ dispatch }, payload) => {
    return service({
        module: 'educationalGroup',
        action: 'educationalGroupCreate',
        payload: payload
    })
}

export const ActionUpdateEducationalGroup = ({ dispatch }, payload) => {
    return service({
        module: 'educationalGroup',
        action: 'educationalGroupUpdate',
        payload: payload
    })
}

export const ActionDeleteEducationalGroup = ({ dispatch }, payload) => {
    return service({
        module: 'educationalGroup',
        action: 'educationalGroupDelete',
        payload: payload
    })
}