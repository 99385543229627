import { service } from '../../../helpers/http'

export const ActionFindLicenses = ({ dispatch }, payload) => {
    return service({
        module: 'license',
        action: 'licenses',
        payload: payload
    })
}

export const ActionCreateLicense = ({ dispatch }, payload) => {
    return service({
        module: 'license',
        action: 'createLicense',
        payload: payload
    })
}

export const ActionUpdateLicense = ({ dispatch }, payload) => {
    return service({
        module: 'license',
        action: 'updateLicense',
        payload: payload
    })
}

export const ActionDeleteLicense = ({ dispatch }, payload) => {
    return service({
        module: 'license',
        action: 'deleteLicense',
        payload: payload
    })
}