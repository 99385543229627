import { service } from '../../../../helpers/http'

export const ActionIndexModule = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'indexModule',
        payload: payload
    })

}
export const ActionDestroyModule = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'destroyModule',
        payload: payload
    })
}
export const ActionStoreModule = ({ dispatch }, payload ) => {
    return service({
        module: 'plan',
        action: 'storeModule',
        payload: payload
    })

}
export const ActionUpdateModule = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'updateModule',
        payload: payload
    })
}