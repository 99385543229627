<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                      <v-col cols="12" sm="3">
                        <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código*" />
                      </v-col>
                        <v-col cols="12" sm="9">
                            <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                    </v-row>
                <v-row>
                      <v-col cols="12">
                        <SysSelectShift 
                            :model.sync="model.shift_id" 
                            label="Turno SysClass*" 
                            :error="errors.length > 0"  
                            :errors="errors.shift_id"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-company
                                :model.sync="model.company_id"
                                :error="errors.length > 0"  
                                :errors="errors.company_id"
                                label="Empresa"
                                showClear
                                dense
                                autoComplete
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-branch
                                :model.sync="model.branch_id"
                                :error="errors.length > 0"  
                                :errors="errors.branch_id"
                                label="Filial"
                                showClear
                                dense
                                :disabled="model.company_id === null"
                                :company-id="model.company_id"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <SysSelectCampus 
                            dense 
                            :model.sync="model.campus_id" 
                            label="Campus" 
                            :error="errors.length > 0"  
                            :errors="errors.campus_id"
                            showClear
                            dense
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <SysSelectCourseLevel 
                            :model.sync="model.course_level_id" 
                            label="Nível de curso" 
                            :error="errors.length > 0"  
                            :errors="errors.course_level_id"
                            showClear
                            dense
                            />
                      </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="model.note"
                                outlined
                                name="input-7-4"
                                label="Anotações"
                                :value="model.note"
                                :error="errors.length > 0"  
                                :error-messages="errors.note"
                                rows="2"
                                row-height="20"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                            />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysIntegrationShiftRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = true;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-timeline-clock-outline"
        },
        title: {
            type: String,
            default: "Integração Turno"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('integration', ['ActionUpdateIntegrationShift', 'ActionCreateIntegrationShift']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateIntegrationShift(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateIntegrationShift(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>