export default [
    {
        path: '/occupation',
        name: 'occupation',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Ocupação',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }, 
                { text: 'Ocupação', disabled: true, href: '/' }
            ],
        }
    },
    {
        path: '/balance',
        name: 'balance',
        component: () => import ('./pages/Balance'),
        meta: {
            title: 'Balanço',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }, 
                { text: 'Balanço', disabled: true, href: '/' }
            ],
        }
    },
    {
        path: '/occupationIdeal',
        name: 'occupationIdeal',
        component: () => import ('./pages/OccupationIdeal'),
        meta: {
            title: 'Simulação de ocupação',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }, 
                { text: 'Simulação de ocupação', disabled: true, href: '/' }
            ],
        }
    },
]