import { service } from '../../../helpers/http'

export const ActionGetGridJoin = ({ dispatch }, payload) => {
    return service({
        module: 'indication',
        action: 'join',
        payload: payload,
        idAndPayload: true
    })
}

export const ActionReplicateAttributions = ({ dispatch }, payload) => {
    return service({
        module: 'indication',
        action: 'attributionsReplicate',
        payload: payload,
    })
}
