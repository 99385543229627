export default [
    {
        path: '/control-panel',
        name: 'control-panel',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Painel de controle',
            icon: '',
            breadcrumbs: [
                { text: 'Painel de controle', disabled: true, href: '/' }
            ],
        }
    }
]