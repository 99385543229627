<template>
    
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        absolute
        expand-on-hover
        :temporary="!mini"
        v-if="show"
        app
    >

        <v-list dense>
        <v-list-item link class="px-2 py-0 my-0" href="profile">
            <v-list-item-avatar>
                <EmcBaseAvatar 
                :size="36"
                :src="user.url_avatar"
                :text="user.initials"
                v-if="user.id"
              />
                
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title class="text-title">{{ user.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list>

        <v-divider></v-divider>
        
        <v-list dense app>
              <template v-for="item in menu">

              <v-list-group 
                v-if="Object.keys(item.children).length > 0" 
                :key="item.id" 
                v-model="item.model" 
                :prepend-icon="item.icon"
              >

                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="(child, i) in item.children" :key="i" link :to="child.to">

                  <v-list-item-action v-if="child.icon" >
                    <v-tooltip v-if="primaryDrawer.mini" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" >{{ child.icon }}</v-icon>
                      </template>
                      <span> {{ child.description }} </span>
                    </v-tooltip>
                    <v-icon v-else >{{ child.icon }}</v-icon>
                  </v-list-item-action>
                
                  <v-list-item-content>
                    <v-list-item-title >{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>

            </v-list-group>

            <v-list-item v-else :key="item.id" link :to="item.to">
              
              <v-list-item-action>
                <v-tooltip v-if="primaryDrawer.mini" bottom>
                  <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" >{{ item.icon }}</v-icon>
                  </template>
                  <span> {{ item.description }}</span>
                </v-tooltip>
                <v-icon v-else >{{ item.icon }}</v-icon>
              </v-list-item-action>
              
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: "CoreNavigationNew",
    data () {
        return {
            drawer: true,
            mini: true,
            primaryDrawer: {
                model: null,
                type: 'default (no property)',
                clipped: true,
                floating: false,
                mini: true,
                app: true
            },
            
        }
    },
    methods: {
        ...mapActions('auth', ['ActionSignOut']),
        logout() {
            this.ActionSignOut()
                .then(() => {
                    this.$router.push({ name: 'login' });
                })
        }
    },
    computed: {
        ...mapState('auth', ['user', 'menu', 'acesso']),
        show() {
            return !this.acesso.instituicao ? false : true;
        }
    }
}
</script>