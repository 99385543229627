import { store as auth } from '../modules/auth'
import { store as worktime } from '../modules/worktime'
import { store as home } from '../modules/home'
import { store as role } from '../modules/role'
import { store as indication } from '../modules/indication'
import { store as availability } from '../modules/availability'
import { store as teacher } from '../modules/teacher'
import { store as user } from '../modules/user'
import { store as institution } from '../modules/institution'
import { store as schedule } from '../modules/schedule'
import { store as account } from '../modules/account'
import { store as course } from '../modules/course'
import { store as courseLevel } from '../modules/course_level'
import { store as occupationArea } from '../modules/occupation_area'
import { store as category } from '../modules/category'
import { store as discipline } from '../modules/discipline'
import { store as campus } from '../modules/campus'
import { store as group } from '../modules/group'
import { store as ambience } from '../modules/ambience'
import { store as hour } from '../modules/hour'
import { store as grid } from '../modules/grid'
import { store as join } from '../modules/join'
import { store as workLoadType } from '../modules/work_load_type'
import { store as ambienceType } from '../modules/ambience_type'
import { store as block } from '../modules/block'
import { store as floor } from '../modules/floor'
import { store as categoryAmbience } from '../modules/category_ambience'
import { store as menu } from '../modules/menu'
import { store as resource } from '../modules/resource'
import { store as mail } from '../modules/mail'
import { store as educationalGroup } from '../modules/educational_group'
import { store as brand } from '../modules/brand'
import { store as controlPanel } from '../modules/control_panel'
import { store as categoryGroup } from '../modules/category_group'
import { store as calendar } from '../modules/calendar'
import { store as eventItem } from '../modules/event_item'
import { store as eventType } from '../modules/event_type'
import { store as eventCategory } from '../modules/event_category'
import { store as city } from '../modules/city'
import { store as roomAllocation } from '../modules/room_allocation'
import { store as curriculumCategory } from '../modules/curriculum_category'
import { store as curriculum } from '../modules/curriculum'
import { store as equivalence } from '../modules/equivalence'
import { store as baseGroup } from '../modules/base_group'
import { store as disciplineType } from '../modules/discipline_type'
import { store as activity } from '../modules/activity'
import { store as activityItem } from '../modules/activity_item'
import { store as license } from '../modules/license'
import { store as teacherLicense } from '../modules/teacher_license'
import { store as attribution } from '../modules/attribution'
import { store as resourceType } from '../modules/resource_type'
import { store as dynamicGeneration } from '../modules/dynamic_generation'
import { store as view } from '../modules/view'
import { store as hourGroup } from '../modules/hour_group'
import { store as chart } from '../modules/chart'
import { store as budgetType } from '../modules/budget_type'
import { store as budget } from '../modules/budget'
import { store as paymentExport } from '../modules/payment_export'
import { store as hourGroupDate } from '../modules/hour_group_date'
import { store as bank } from '../modules/bank'
import { store as bankData } from '../modules/bank_data'
import { store as timeSheet } from '../modules/time_sheet'
import { store as faq } from '../modules/faq'
import { store as faqCategory } from '../modules/faq_category'
import { store as courseInfo } from '../modules/course_info'
import { store as country } from '../modules/country'
import { store as state } from '../modules/state'
import { store as ambienceOccupation } from '../modules/ambience_occupation'
import { store as teacherPosition } from '../modules/teacher_position'
import { store as teacherContract } from '../modules/teacher_contract'
import { store as contact } from '../modules/contact'
import { store as logAccessApi } from '../modules/log_access_api'
import { store as importLog } from '../modules/import_log'
import { store as plan } from '../modules/plan'
import { store as integration } from '../modules/integration'
import { store as eventCalendarBase } from '../modules/event_calendar_base'
import { store as term } from '../modules/term'
import { store as finance } from '../modules/management/finance'
import { store as message } from '../modules/message'
import { store as ticket } from '../modules/management/ticket'
import { store as address } from '../modules/address'

export default {
    auth,
    role,
    home,
    indication,
    availability,
    teacher,
    worktime,
    user,
    institution,
    schedule,
    account,
    course,
    courseLevel,
    occupationArea,
    category,
    discipline,
    campus,
    group,
    ambience,
    hour,
    grid,
    join,
    workLoadType,
    ambienceType,
    block,
    floor,
    categoryAmbience,
    menu,
    resource,
    mail,
    educationalGroup,
    brand,
    controlPanel,
    categoryGroup,
    calendar,
    eventItem,
    eventType,
    eventCategory,
    city,
    roomAllocation,
    curriculumCategory,
    curriculum,
    equivalence,
    baseGroup,
    disciplineType,
    activity,
    activityItem,
    license,
    teacherLicense,
    attribution,
    resourceType,
    dynamicGeneration,
    view,
    hourGroup,
    chart,
    budgetType,
    budget,
    paymentExport,
    hourGroupDate,
    bank,
    bankData,
    timeSheet,
    faq,
    faqCategory,
    courseInfo,
    country,
    state,
    ambienceOccupation,
    teacherPosition,
    teacherContract,
    contact,
    logAccessApi,
    importLog,
    plan,
    integration,
    eventCalendarBase,
    term,
    finance,
    message,
    ticket,
    address,
}