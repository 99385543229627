<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          dark
          small
          color="grey"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Validação Geral</v-list-item-title>
              <v-list-item-subtitle>Horário</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>

          <v-list-item>
            <v-list-item-action>
              <v-switch inset v-model="model.allow_work_load" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Não Validar CH Disponível</v-list-item-title>
          </v-list-item>

        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="menu = false">Cancel</v-btn>
          <v-btn color="primary" text @click="menu = false">Save</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysActivityItemPopOver",
    data: () => ({
      fav: true,
      menu: false,
      hints: true,
    }),
    computed: {
       
    },
    watch: {
        
        model(model_new, model_old) {
            
            if(model_new !== model_old) {
                this.menu = model_new;
            }
        },

        menu(menu_new, menu_old) {
            
            if(menu_new !== menu_old) {
                this.$emit('update:model', menu_new);
            }
        },
    },
    props: {
        model: {
            
        },
    },
    methods: {

    }
}
</script>