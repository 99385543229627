<template>
    <div>
        <v-combobox
            v-if="autoComplete && multiple"
            v-model="selected"
            :items="items"
            :label="label"
            item-value="id"
            :item-text="itemText"
            required
            :error="error"
            :error-messages="errors"
            :dense="dense"
            :loading="loading"
            :disabled="disabled || loading"
            :shaped="false"
            :multiple="multiple"
            :return-object="multiple || returnObject"
            @change="change"
            @input="$emit('input', $event)"
            :autofocus="autofocus"
            :hide-selected="hideSelected"
            :clearable="showClear"
            :hint="hint"
            :persistent-hint="persistentHint"
            :filled="filled"
            :outlined="outlined"
            :class="classe"
            :hide-details="hideDetails"
            :background-color="backgroundColor"
            :color="color"
        >
            <template v-slot:selection="{ item, index }">

                <span v-if="index === 0">{{ item.name }}</span>
            
                <span
                    v-if="index === 1"
                    class="grey--text caption ml-1"
                >
                    (+{{ selected.length - 1 }} outros)
                </span>
            </template>
            
        </v-combobox>

        <v-autocomplete
                v-else-if="autoComplete"
                v-model="selected"
                :items="items"
                :loading="loading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                item-value="id"
                :item-text="itemText"
                :label="label"
                :error="error"
                :error-messages="errors"
                v-on:keyup="getSearch"
                :dense="dense"
                :disabled="disabled || loading"
                :autofocus="autofocus"
                :clearable="showClear"
                :hint="hint"
                :persistent-hint="persistentHint"
                :filled="filled"
                @change="change"
                @input="$emit('input', $event)"
                :multiple="multiple"
                :return-object="multiple || returnObject"
                :outlined="outlined"
                :class="classe"
                :hide-details="hideDetails"
                :background-color="backgroundColor"
                :color="color"
            >

            <template v-if="textHelper" v-slot:prepend-inner>
                 <emc-info-help title="Informações" :text="textHelper" />
            </template>
            
            <template v-if="!loading && (showAdd && items.length == 0)" v-slot:append>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="disableAdd" v-bind="attrs" v-on="on" small icon @click.prevent.stop="showForm">
                            <v-icon btn color="green darken-1">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                        <span>
                        Novo
                    </span>
                </v-tooltip>

            </template>

            <template v-slot:selection="{ item, index }">
            
                <span v-if="index === 0">{{ item[itemText] }}</span>
            
                <span
                    v-if="index === 1"
                    class="grey--text caption ml-1"
                >
                    (+{{ selected.length - 1 }} outros)
                </span>
            </template>
            
            <template v-slot:prepend-item>
                <v-tooltip  v-if="showAdd && items.length > 0" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="disableAdd" v-bind="attrs" v-on="on" small icon @click.prevent.stop="showForm">
                            <v-icon btn color="green darken-1">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                        <span>
                        Novo
                    </span>
                </v-tooltip>
                
            </template>
        </v-autocomplete>

        <v-select
            v-else
            v-model="selected"
            :items="items"
            :label="label"
            item-value="id"
            :item-text="itemText"
            required
            :error="error"
            :error-messages="errors"
            :dense="dense"
            :loading="loading"
            :disabled="disabled || loading"
            :shaped="false"
            :multiple="multiple"
            :return-object="multiple || returnObject"
            @change="change"
            @input="$emit('input', $event)"
            :autofocus="autofocus"
            :hide-selected="hideSelected"
            :clearable="showClear"
            :hint="hint"
            :persistent-hint="persistentHint"
            :filled="filled"
            :outlined="outlined"
            :class="classe"
            :hide-details="hideDetails"
            :background-color="backgroundColor"
            :color="color"
        >
            <template v-if="appendIcon" v-slot:append>
                <v-icon>{{ appendIcon }}</v-icon>
            </template>

            <template v-if="textHelper" v-slot:prepend-inner>
                 <emc-info-help title="Informações" :text="textHelper" />
            </template>
            
            <template v-slot:prepend-item>
            
                <v-tooltip  v-if="showAdd" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="disableAdd" v-bind="attrs" v-on="on" small icon @click.prevent.stop="showForm">
                            <v-icon btn color="green darken-1">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                        <span>
                        Novo
                    </span>
                </v-tooltip>

                <v-tooltip  v-if="multiple && selectAll" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-checkbox
                            v-bind="attrs" v-on="on"
                            v-model="checkbox"
                            label="Todos"
                            @change="changeCheckbox"
                            class="ml-2"
                            />
                    </template>
                        <span>
                        Novo
                    </span>
                </v-tooltip>
                
            </template>
        
            <template v-slot:selection="{ item, index }">
            
                <span v-if="index === 0">{{ item[itemText] }}</span>
            
                <span
                    v-if="index === 1"
                    class="grey--text caption ml-1"
                >
                    (+{{ selected.length - 1 }} outros)
                </span>
            </template>

            <template v-if="$slots.dataItem || !!$scopedSlots.dataItem" v-slot:item="data">
                <slot :item="data.item" name="dataItem" />
            </template>

        </v-select>

        

        
    </div>
</template>

<script>

export default {
    name: "SysSelectBase",
    data: () => ({
        selected: null,
        search: '',
        interval: 0,
        checkbox: false
    }),
    created(){
        
        if(this.model) {
            this.selected = this.model;
        }
        
    },

    computed: {
      
    },

    watch: {
        
        selected(val) {
            
            this.$emit('update:model', val);
            if(!val){
                this.$emit('clearSelected');
            }
        },

        model(val){
            this.selected = val;
        },
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        model: {
        },
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: false
        },
        showClear: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disableAdd: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: 'name'
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hideSelected: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        appendIcon: {
            type: String,
            default: null
        },
        hint: {
          type: String,
          required: false
        },
        persistentHint: {
          type: Boolean,
          default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        textHelper: {
            type: String,
            default: null,
        },
        outlined: {
            type: Boolean,
            default: false
        },
        classe: {

        },
        hideDetails: {
            type: [Boolean,String],
            default: false
        },
        backgroundColor: {},
        color: {},
    },
    methods:{
        async getSearch() {
            
            if(!this.search) return

            clearTimeout(this.interval)

            this.interval = setTimeout(()=>{
                    this.$emit('update:search', this.search)
            }, 1000);
        },

        showForm(){
            this.$emit('onShowForm');
        },

        change(item){
            
            if(this.items){
                var type = this.items.find(i => i.id == item);
                if(type){
                    this.$emit('change', type);
                }
            }
            
        },

        changeCheckbox(ev){
            
            if(ev){
                this.selected = this.items;
            }else{
                this.selected = [];
            }

        }

        
    }
}
</script>