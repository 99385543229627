
import Vue from 'vue'
import VueResource from 'vue-resource'
import services from './services'
import interceptors from './interceptors'
import * as storage from '../modules/auth/storage'


Vue.use(VueResource)
const http = Vue.http

http.headers.common['Access-Control-Allow-Origin'] = '*'
http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;'
http.headers.common['Accept'] = 'application/json, text/plain, */*'
http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin'

http.options.root = process.env.VUE_APP_ROOT_API;
http.interceptors.push(interceptors);

Object.keys(services).map(service => {
    services[service] = Vue.resource('', {}, services[service])
})

const setBearerToken = (token) => {
    http.headers.common['Authorization'] = 'Bearer ' + token;
}

http.interceptors.push(function(request, next){
    //var access = JSON.parse(storage.getParamsHeader());
    next();
});


export default services
export { http, setBearerToken }




