import { service } from '../../../helpers/http'

export const ActionSearchCities = ({ dispatch }, payload) => {

    return service({
        module: 'city',
        action: 'cities',
        payload: payload
    })
    
}

export const ActionCreateCity = ({ dispatch }, payload) => {

    return service({
        module: 'city',
        action: 'cityCreate',
        payload: payload
    })
    
}

export const ActionUpdateCity = ({ dispatch }, payload) => {

    return service({
        module: 'city',
        action: 'cityUpdate',
        payload: payload
    })
    
}

export const ActionDeleteCity = ({ dispatch }, payload) => {

    return service({
        module: 'city',
        action: 'cityDelete',
        payload: payload
    })
    
}

export const ActionSearchStates = ({ dispatch }, payload) => {

    return service({
        module: 'city',
        action: 'states',
        payload: payload
    })
    
}