export default {

    indexOfferItems:                { method: 'get',        url: 'offer_items' },
    storeOfferItem:                 { method: 'post',       url: 'offer_items' },
    updateOfferItem:                { method: 'put',        url: 'offer_items/{id}'},
    destroyOfferItem:               { method: 'delete',     url: 'offer_items/{id}'},
    indexOfferItemStatus:           { method: 'get',        url: 'offer_items/status' },
    indexOfferItemFilters:          { method: 'get',        url: 'offer_items/filters' },
    indexOfferItemsScroll:          { method: 'get',        url: 'offer_items/scroll' },
    indexMessages:                  { method: 'get',        url: 'offer_item_messages' },
    createMessage:                  { method: 'post',       url: 'offer_item_messages' },
    updateMessage:                  { method: 'put',        url: 'offer_item_messages/{id}' },
    deleteMessage:                  { method: 'delete',     url: 'offer_item_messages/{id}' },
    indexOfferItemsNew:             { method: 'get',        url: 'offer_items/new_registers' },
    mergeOfferItems:                { method: 'post',       url: 'offer_items/merge' },
    updateRelocationOfferItems:     { method: 'post',       url: 'offer_items/update-relocations' },
    updateRelocationItemOfferItems: { method: 'post',       url: 'offer_items/update-relocation-item' },
    filterOfferItemLogs:            { method: 'get',        url: 'offer_items/{id}/filter-offer-item-logs' },
    generateGridOfferItems:         { method: 'post',       url: 'offer_items/generate-grid/{id}' },
    generateGridsOfferItems:        { method: 'post',       url: 'offer_items/generate-grids' },
    generateJoinOfferItems:         { method: 'post',       url: 'offer_items/generate-joins' },
    indexGrisOfferItems:            { method: 'get',        url: 'offer_items/{id}/grids' },

}