export default [
    {
        path: '/banks',
        name: 'banks',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Bancos',
            icon: '',
            breadcrumbs: [
                { text: 'Financeiro', disabled: true, href: '/' }, 
                { text: 'Bancos', disabled: true, href: '/' }, 
            ],
        }    
    },
]