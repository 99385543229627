import { service } from '../../../../helpers/http'

export const ActionIndexBaseHours = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'indexBaseHours',
        payload: payload
    });
}

export const ActionCreateBaseHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'createBaseHour',
        payload: payload
    });
}

export const ActionUpdateBaseHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'updateBaseHour',
        payload: payload
    });
}

export const ActionDeleteBaseHour = ({ dispatch }, payload) => {
    
    return service({
        module: 'hour',
        action: 'deleteBaseHour',
        payload: payload
    });
}