<template>
    <v-col :cols="cols" :sm="sm" :md="md">
        <v-text-field
            v-model="model"
            :label="label"
            :append-icon="appendIcon"
            :readonly="readonly"
            :disabled="disabled"
            :outlined="outlined"
            v-mask-decimal.br="decmal"
            :hint="hint"
            :hide-details="hideDetails"
            :background-color="outlined && disabled ? `grey lighten-2` : undefined"
            :error="error"
            :error-messages="errorMessages"
        >
        </v-text-field>
    </v-col>
</template>

<script>
export default {
    name: 'EmcFormTextField',
    props: {
        value: [String, Number],
        label: { type: String, required: false },
        appendIcon: { type: String, default: "mdi-cash" },
        readonly: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
        outlined: { type: Boolean, required: false },
        hideDetails: { type: [String, Boolean], default: "auto"},
        sm: { type: String, default: "6" },
        md: { type: String, default: "4" },
        cols: { type: String, default: "12" },
        decmal: { type: [String, Number], default: 2 },
        hint: { type: String, required: false },
        errorMessages: { type: Object, required: false },
        error: { type: Boolean, required: false }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(val) {
                let removeDot = val.replace('.','')
                const money = removeDot.replace(',','.')
                this.$emit('input', money)
            }
        }
    }
}
</script>