import { default as panel } from './panel/routes'
import { default as work_load_history } from './work_load_history/routes'
import { default as work_load_balance } from './work_load_balance/routes'
import { default as consistency } from './consistency/routes'
import { default as importItem } from './import_item/routes'

export default [
    ...panel,
    ...work_load_history,
    ...work_load_balance,
    ...consistency,
    ...importItem,
]