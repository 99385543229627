import { default as vacancyControl } from './vacancy_control/routes'
import { default as allocation } from './allocation/routes'

export default [
    ...vacancyControl,
    ...allocation,
    {
        path: '/roomAllocation',
        name: 'roomAllocation',
        component: () => import ('./pages/roomAllocationRegister')
    },
    {
        path: '/roomLink',
        name: 'roomLink',
        component: () => import ('./pages/roomLinkRegister')
    },
]