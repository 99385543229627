export default [
    {
        path: '/curriculumCategories',
        name: 'curriculumCategories',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Categoria da matriz currícular',
            icon: '',
            breadcrumbs: [
                { text: 'Matriz currícular', disabled: true, href: '/' },
                { text: 'Categoria da matriz currícular', disabled: true, href: '/' },
            ],
        }
    },
]