import { default as branch } from './branch/routes'
import { default as event } from './event/routes'
import { default as company } from './company/routes'
import { default as type_setting } from './type_setting/routes'
import { default as extract } from './extract/routes'

export default [
    ...branch,
    ...event,
    ...company,
    ...type_setting,
    ...extract,
    {
        path: '/payment-exports',
        name: 'payment-exports',
        component: () => import ('./pages/Index')
    },
]