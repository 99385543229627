export function returnReject(error) {

    if(error.status === 422) {

        const params = {
            success:    error.body.success,
            code:       error.body.code,
            errors:     error.body.errors,
            message:    error.body.message
        };

        return params;

    } else {

        const params = {
            success:    false,
            code:       error.status,
            message:    error.body.message,
            error:      error.statusText
        }

        return params;

    }

}

export function returnResolve(data) {

    const items = {
        success:    data.body.success,
        data:       data.body.data,
        message:    data.body.message,
        totalCount: data.body.totalCount ? data.body.totalCount : 0,
    }

    //Para caso exista paginação
    if(typeof data.body.meta !== undefined) {
        
        const body = data.body;
        
        items.meta = {
            page:           body.meta ? body.meta.current_page          : body.current_page ? body.current_page : 1, 
            itemsPerPage:   body.meta ? parseInt(body.meta.per_page)    : body.per_page     ? body.per_page     : 1,
            total:          body.meta ? body.meta.total                 : body.total        ? body.total        : 1,
            last_page:      body.meta ? body.meta.last_page             : body.last_page    ? body.last_page    : 0
        };
    }

    return items
}