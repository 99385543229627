import { service } from '../../../../helpers/http'

export const ActionIndexRegions = ({ dispatch }, payload ) => {

    return service({
        module: 'institution',
        action: 'indexRegions',
        payload: payload
    })

}
export const ActionDestroyRegion = ({ dispatch }, payload ) => {

    return service({
        module: 'institution',
        action: 'destroyRegion',
        payload: payload
    })
}
export const ActionStoreRegion = ({ dispatch }, payload ) => {
    return service({
        module: 'institution',
        action: 'storeRegion',
        payload: payload
    })

}
export const ActionUpdateRegion = ({ dispatch }, payload ) => {

    return service({
        module: 'institution',
        action: 'updateRegion',
        payload: payload
    })
}