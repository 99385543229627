import { service } from '../../../../helpers/http'

export const ActionIndexOfferItems = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItems',
        payload: payload
    })

}

export const ActionDestroyOfferItem = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'destroyOfferItem',
        payload: payload
    })
}

export const ActionStoreOfferItem = ({ dispatch }, payload ) => {
    return service({
        module: 'grid',
        action: 'storeOfferItem',
        payload: payload
    })

}

export const ActionUpdateOfferItem = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateOfferItem',
        payload: payload
    })
}

export const ActionIndexOfferItemStatus = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemStatus',
        payload: payload
    })

}

export const ActionIndexOfferItemFilters = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemFilters',
        payload: payload
    })

}

export const ActionIndexOfferItemsScroll = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemsScroll',
        payload: payload
    })

}

export const ActionIndexOfferItemMessages = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'indexMessages',
        payload: payload
    })
}

export const ActionOfferItemMessageCreate = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'createMessage',
        payload: payload
    })
}

export const ActionOfferItemMessageUpdate = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'updateMessage',
        payload: payload
    })
}

export const ActionOfferItemMessageDelete = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'deleteMessage',
        payload: payload
    })
}


export const ActionIndexOfferItemsNews = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemsNew',
        payload: payload
    })
}

export const ActionFilterOfferItemLogs = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'filterOfferItemLogs',
        payload: payload
    })

}

export const ActionMergeOfferItems = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'mergeOfferItems',
        payload: payload
    })
}

export const ActionUpdateRelocationOfferItems = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'updateRelocationOfferItems',
        payload: payload
    })
}

export const ActionUpdateRelocationItemOfferItems = ({ dispatch }, payload) => {

    return service({
        module: 'grid',
        action: 'updateRelocationItemOfferItems',
        payload: payload
    })
}


export const ActionGenerateGridOfferItems = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'generateGridOfferItems',
        payload: payload
    })
}

export const ActionGenerateGridsOfferItems = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'generateGridsOfferItems',
        payload: payload
    })
}

export const ActionGenerateJoinsOfferItems = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'generateJoinOfferItems',
        payload: payload
    })
}

export const ActionIndexGridsOfferItems = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexGrisOfferItems',
        payload: payload,
        idAndPayload: true
    })
}


