import { service } from '../../../helpers/http'

export const ActionFindEquivalences = ({ dispatch }, payload) => {
    return service({
        module: 'equivalence',
        action: 'equivalences',
        payload: payload
    })
}

export const ActionGetStatus = ({ dispatch }, payload) => {
    return service({
        module: 'equivalence',
        action: 'status',
        payload: payload
    })
}

export const ActionDeleteEquivalence = ({ dispatch }, payload) => {
    return service({
        module: 'equivalence',
        action: 'deleteEquivalence',
        payload: payload
    })
}

export const ActionUpdateEquivalence = ({ dispatch }, payload) => {
    return service({
        module: 'equivalence',
        action: 'updateEquivalence',
        payload: payload
    })
}

export const ActionCreateEquivalence = ({ dispatch }, payload) => {
    return service({
        module: 'equivalence',
        action: 'storeEquivalence',
        payload: payload
    })
}