<template>
    <v-simple-table
        height="260px"
        fixed-header
        loading
    >
       
        <template v-slot:top>
            
            <v-progress-linear
                indeterminate
                :color="loading ? 'yellow darken-2' : ''"
            />
            
        </template>

        <template v-slot:default>

            <!-- <caption v-if="item">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <strong v-on="on">{{ getTitleHour(item) }}</strong>
                    </template>
                    <span v-html="getToolTipoTitleHour(item)"/>
                </v-tooltip>
            </caption> -->
            
            <thead>
               
                <tr>
                    <th>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <small><strong v-on="on">{{ getTitleHour(item) }}</strong></small>
                        </template>
                        <span v-html="getToolTipoTitleHour(item)"/>
                    </v-tooltip>
                    </th>
                    <th 
                        v-for="day in days_index"
                        :key="day"
                        class="text-center"
                    >
                        {{ days_name[day] }}
                        <span v-if="date">
                            {{ date.all ? '*' : getDate(day) }}
                        </span>
                        
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="hour in hours" :key="hour.id" v-show="type == 'ambience' || (shifts.includes(hour.shift_id) && hasGroups(hour))">
                
                    <td>
                        <small>
                            {{ hour.start }}
                            <br/>
                            {{ hour.end }}
                        </small>

                    </td>
                            
                        <td
                            v-for="day in days_index"
                            :key="day"
                            class="text-left"
                            @drop="drop"
                            @dragover.prevent
                            
                        >
                            <draggable :options="{disabled: type == 'ambience'}" @start="start(getClasses(hour.id, (day + 1)))" gostClass="on-drag" animation="400">
                                
                                <v-card
                                    v-if="getClasses(hour.id, (day + 1))"
                                    transition="fab-transition"
                                    color='blue lighten-3'
                                    dark
                                    class="ma-1 black--text"
                                    height="40px"
                                    width="100px"
                                    @dblclick="dblclick(getClasses(hour.id, (day + 1)))"
                                    >

                                    <v-card-actions>
                                        
                                        <v-tooltip v-if="getClasses(hour.id, (day + 1))" bottom>
                                            <template v-slot:activator="{ on }">
                                                <span style="cursor: default;" v-on="on" v-html="getTextClasses(hour.id, (day + 1))"></span>
                                                </template>
                                            <span v-html="getToolTip(hour.id, (day + 1))"></span>
                                        </v-tooltip>
                                        
                                        <v-tooltip v-if="!!getClasses(hour.id, (day + 1)).ambience_id" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    style="position: absolute; bottom: 4px; right: -5px;" 
                                                    left
                                                    @click="$emit('remove', getClasses(hour.id, (day + 1)))"
                                                    v-on="on"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>Liberar Ambiente</span>
                                        </v-tooltip>

                                        <v-tooltip 
                                                v-if="getClasses(hour.id, (day + 1)) && getClasses(hour.id, (day + 1)).grid.join"
                                                bottom 
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                            small
                                                            style="position: absolute; bottom: 20px; right: -5px; cursor: help" 
                                                            left
                                                            v-on="on"
                                                        >
                                                                mdi-call-split
                                                    </v-icon>
                                                    </template>
                                                    <span v-if="renderComponent">
                                                        <template>
                                                            <ul type="none">
                                                                <li :key="grid.id" v-for="grid in getClasses(hour.id, (day + 1)).grid.join.grids">
                                                                    {{ grid.group.name + ' | ' + (grid.group_number != 0 ? ('D' + grid.group_number + '-') : '') + grid.discipline.name }}
                                                                </li>
                                                            </ul>
                                                        </template>
                                                    </span>
                                                    <span v-else>Carregando...</span>
                                            </v-tooltip>

                                    </v-card-actions>

                                </v-card>
                            </draggable>
                        </td>
                </tr>

               

            </tbody>
        </template>
        
    </v-simple-table>
</template>

<style scoped>
   
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysAmbienceHours",
    data: () => ({
      days_name: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      days_index: [1,2,3,4,5,6],
      hours: [],
      shifts: [],
      hourClasses: [],
      itemStart: {},
      
    }),
    
    mounted(){
       this.getData();
    },
    created() {
      
    },
    computed: {
       
    },
    watch: {
        
        item(val){
            if(val.classes && val.classes.length > 0){
                this.loadShifts(val.classes);
            }else{
                this.shifts = [];
                this.hourClasses = [];
            }
        }

    },
    props: {
        
        title: {
            type: String,
            default: 'Titulo'
        },
        item: {
            type: Object,
            default: null
        },

        type:{
            type: String,
            required: true
        },

        loading: {
            type: Boolean,
            default: true
        },
        renderComponent: {
            type: Boolean,
            default: true
        },
        date: { }
    },
    methods: {
        ...mapActions('hour', ['ActionGetHours', 'ActionDeleteHour']),

        getData(){
        
            var payload = {
                with: 'shift'
            };
            
            this.ActionGetHours(payload)
                .then((res) => {
                    this.hours = res.data
                })
                .finally(() => {
                    
                });
        },

        getDate(day_index){

            if(!this.date || !this.date.start){
                return '';
            }
            
            let start = new Date(this.date.start + ' 00:00:00');
            start.setDate(start.getDate() + day_index);
            let newDate = start.toISOString().substring(0, 10);
            const [year, month, day] = newDate.split('-');
            return ' (' + day + '/' + month + ')';

        },

        getTitleHour(item){

            if(!item){
                return 'Horário';
            }

            if(this.type == 'group'){
                return item.name;
            }else{
                return item.name + (item.block ? '-' + item.block.initials : '');
            }

        },

        getToolTipoTitleHour(item){
            
            if(!item){
                return '';
            }

            if(this.type == 'group'){
                return item.name;
            }else{
                var html = '<span>';
                html += 'Número: ' + item.name;
                html += '<br/>';
                html += 'Tipo: ' + item.type.name;
                html += '<br/>';

                if(item.description){
                    html += 'Descrição: ' + item.description;
                    html += '<br/>';
                }

                html += 'Campus: ' + item.campus.abbreviation;

                if(item.block){
                    html += '<br/>';
                    html += item.block.name;
                    
                }
                
                html += '<br/>';
                html += item.floor.name;
                html += '<br/>';
                html += 'Capacidade Padrão: ' + item.capacity;

                if(item.reduced_capacity > 0){
                    html += '<br/>';
                    html += 'Capacidade Reduzida: ' + item.reduced_capacity;
                }

                if(item.extended_capacity > 0){
                    html += '<br/>';
                    html += 'Capacidade Ampliada: ' + item.extended_capacity;
                }

                html += '</span>';

                return html;
            }


        },

        loadShifts(classes){

            this.shifts = [];
            this.hourClasses = [];
            
            classes.forEach(classe => {
                
                let hour = this.hours.find(h => h.id == classe.hour_id);
                
                if(hour){
                    
                    if(!this.shifts.includes(hour.shift_id)){
                        this.shifts.push(hour.shift_id);
                    }

                    if(!this.shifts.includes(classe.grid.group.shift_id)){
                        this.shifts.push(classe.grid.group.shift_id);
                    }

                    if(!this.hourClasses.includes(hour.id)){
                        this.hourClasses.push(hour.id);
                    }
                }
            });
            
        },

        hasGroups(hour){

            return this.hourClasses.includes(hour.id);

        },

        getTextClasses(hour, day){


            if(this.item && this.item.classes){
                
                var classe = this.getClasses(hour, day);

                if(classe){
                    
                    if(this.type == 'group'){
                        
                        var grid = classe.grid;
                        var ambience = classe.ambience;
                        var html = '';

                        // if(ambience){
                        //     html = '<strong>' + ambience.name + (ambience.block ? ' - ' + ambience.block.name : '') + '</strong><br/>';
                        // }

                        // html += '<small>' + this.getFirstName(grid.discipline.name) + '</small>';

                        // if(grid.teacher){
                        //     html += '<br/><small>' + this.getFirstName(grid.teacher.name) + '</small>';
                        // }

                        if(ambience){
                            html = '<small>' + ambience.name + (ambience.block ? ' - ' + ambience.block.name : '') + '</small><br/>';
                        }else{
                            html = '<small class="red--text">Sem ambiente</small>';
                        }

                        return html;

                    }else if(this.type == 'ambience' && classe.grid){
                        
                        var grid = classe.grid;
                        var group = grid.group;

                        // var html = '<strong>' +  group.name + '</strong><br/>';
                        // html += '<small>' + this.getFirstName(grid.discipline.name) + '</small>';
                        // if(grid.teacher){
                        //     html += '<br/><small>' + this.getFirstName(grid.teacher.name) + '</small>';
                        // }

                        var html = '<small>' +  classe.title + '</small>';
                        

                        return html;
                    }
                }
            }
            
            return '';

        },

        getToolTip(hour, day){
            
            var classe = this.getClasses(hour, day);
            var html = '';

            if(classe){
                var grid = classe.grid;
                var ambience = classe.ambience;
                var group = grid.group;
                var html = '';

                if(this.type == 'group'){
                    if(ambience){
                        html = '<span>' + ambience.name + (ambience.block ? ' - ' + ambience.block.name : '') + '</span><br/>';
                    }
                }else{
                    html += '<span>' +  group.name + '</span><br/>';
                }
                
                html += '<span>' + grid.discipline.name + '</span>';
                
                if(grid.teacher){
                    html += '<br/><span>' + grid.teacher.name + '</span>';
                }
            }

            return html;
            
        },

        getClasses(hour, day){
            
            if(this.type == 'group'){
                if(this.item && this.item.classes){
                    var classe = this.item.classes.find(i => (i.hour_id == hour && i.day_of_week_id == day));
                    return classe;
                }
            }else{
                if(this.item && this.item.allocations){
                    var allocation = this.item.allocations.find(i => (i.hour_id == hour && i.day_of_week_id == day));
                    return allocation;
                }
            }
            
            return null;
        },

        getFirstName(name, length = 10){
            
            var index = name.indexOf(' ');
            var firstName = name;

            if(index != -1){
                firstName = name.split(' ')[0];
            }

            if(firstName.length > length)
                firstName = firstName.substring(0, length);

            return firstName;
        },

        start(classe){

            this.$emit('start', [ classe.id ]);

        },

        drop(){
            this.$emit('drop');
        },

        dblclick(classe){
            
            if(this.type == 'group'){
                if(classe.ambience_id){
                    this.$emit('dblclick', { id: classe.ambience_id });
                }
            }else{
                this.$emit('dblclick', { id: classe.grid.group_id });
            }
           
        }
        
    }
}
</script>