import { service } from '../../../helpers/http'

export const ActionFindBrands = ({ dispatch }, payload) => {

    return service({
        module: 'brand',
        action: 'brands',
        payload: payload
    })
    
}

export const ActionCreateBrand = ({ dispatch }, payload) => {

    return service({
        module: 'brand',
        action: 'brandCreate',
        payload: payload
    })
    
}

export const ActionUpdateBrand = ({ dispatch }, payload) => {

    return service({
        module: 'brand',
        action: 'brandUpdate',
        payload: payload
    })
    
}

export const ActionDeleteBrand = ({ dispatch }, payload) => {

    return service({
        module: 'brand',
        action: 'brandDelete',
        payload: payload
    })
    
}
