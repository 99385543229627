<template>
    <div>
        <emc-avatar-base 
            :src="model.url_logo" 
            @onComplete="upload"
            :width="width"
            :height="height"
            :model="model"
            tile
            :showCamera="false"
            iconDefault="mdi-image-outline"
        />
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "EmcAvatarCalendarSetting",
    data() {
        return {
           model: {},
        };
    },
    props: {
        width: {
            default: 100
        },
        height: {
            default: 100
        },
        brandId: {

        }
    },

    created() {
        this.getData();
    },

    methods: {
        ...mapActions('calendar', ['ActionIndexEventCalendarSettings', 'ActionCalendarSettingUploadLogo']),

        getData(){
          
          let payload = {};

          if(this.brandId){
            payload.brand_id = this.brandId;
          }
          
          this.ActionIndexEventCalendarSettings(payload)
                .then((res) => {
                    if(res.data.length > 0){
                      this.model = res.data[0];
                    }
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    
                });
        },
       
        upload(form){

            form.append('id', this.model.id);

            if(this.brandId){
                form.append('brand_id', this.brandId);
            }
            
            this.ActionCalendarSettingUploadLogo(form)
                .then((res) => {
                   this.model.url_logo = res.data.url_logo;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                });

        },
    }
};
</script>