var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{attrs:{"height":"500px","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.ambience)?_c('strong',[_vm._v(_vm._s(_vm.ambience.name))]):_vm._e(),_c('v-progress-linear',{attrs:{"indeterminate":"","color":(_vm.loadingInternal || _vm.loading) ? 'yellow darken-2' : ''}})]},proxy:true},{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_c('small',[_c('strong',[_vm._v("Horário")])])]),_vm._l((_vm.days_index),function(day){return _c('th',{key:day,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.days_name[day])+" ")])})],2)]),(_vm.renderComponent)?_c('tbody',_vm._l((_vm.hours),function(hour,i){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHour(hour)),expression:"showHour(hour)"}],key:i},[_c('td',[_c('small',[_vm._v(" "+_vm._s(hour.start)+" "),_c('br'),_vm._v(" "+_vm._s(hour.end)+" ")])]),_vm._l((_vm.days_index),function(day){return _c('td',{key:day,staticClass:"text-left",class:_vm.getClassCell(hour.id, (day + 1)),on:{"drop":_vm.drop,"dragover":function($event){$event.preventDefault();}}},[(_vm.renderComponent && _vm.getClassesAmbience(hour.id, (day + 1)) && _vm.getClassesAmbience(hour.id, (day + 1)).ambience_id)?_c('v-card',{staticClass:"ma-1 black--text",attrs:{"transition":"fab-transition","color":"blue lighten-3","dark":"","height":"20px","width":"30px"},on:{"dblclick":function($event){_vm.dblclick(_vm.getClassesAmbience(hour.id, (day + 1)))}}},[_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","top":"3px"},attrs:{"small":"","left":"","color":"error"},on:{"click":function($event){_vm.$emit('remove', _vm.getClassesAmbience(hour.id, (day + 1)))}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(" Liberar Ambiente "),_c('br'),_vm._v(" "+_vm._s(_vm.getClassesAmbience(hour.id, (day + 1)).group_name + ' - ' + _vm.getClassesAmbience(hour.id, (day + 1)).discipline_name)+" ")])])],1)],1):_vm._e(),(_vm.renderComponent && _vm.getClassesGroup(hour.id, (day + 1)))?_c('v-card',{staticClass:"ma-1 black--text",attrs:{"transition":"fab-transition","color":"amber lighten-3","dark":"","height":"20px","width":"30px"},on:{"dblclick":function($event){_vm.dblclick(_vm.getClassesGroup(hour.id, (day + 1)))}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-text',_vm._g(_vm._b({},'v-card-text',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTextDiscipline(hour.id, (day + 1)))+" ")])]),_c('v-card-actions',[(_vm.getClassesGroup(hour.id, (day + 1)).ambience_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"position":"absolute","top":"3px"},attrs:{"small":"","left":"","color":"error"},on:{"click":function($event){_vm.$emit('remove', _vm.getClassesGroup(hour.id, (day + 1)))}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(" Liberar Ambiente "),_c('br'),_vm._v(" "+_vm._s(_vm.getTextAmbience(hour.id, (day + 1)))+" ")])]):_vm._e()],1)],1):_vm._e()],1)})],2)}),0):_vm._e(),(false)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"ma-1",staticStyle:{"background-color":"#FFE082"},attrs:{"colspan":"2"}},[_c('strong',[_vm._v("Turma")])]),_c('td',{staticClass:"ma-1",staticStyle:{"background-color":"#90CAF9"},attrs:{"colspan":"2"}},[_c('strong',[_vm._v("Ambiente")])]),_c('td'),_c('td'),_c('td')])]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }