import { service } from '../../../helpers/http'

export const ActionInstances = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'role', 
		action: 'instances', 
		payload: payload 
	})
}

export const ActionFindRoles = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'role', 
		action: 'findRoles', 
		payload: payload 
	})
}

export const ActionCreateRole = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'role', 
		action: 'createRole', 
		payload: payload 
	})
}

export const ActionUpdateRole = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'role', 
		action: 'updateRole', 
		payload: payload 
	})
}

export const ActionUpdateRoleMenu = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'role', 
		action: 'updateRoleMenu', 
		payload: payload 
	})
}

export const ActionFindRoleCharts = () => {

    return service({
        module: 'role',
        action: 'charts'
    })

}

export const ActionIndexRoles = (({ dispatch }, payload) => {
    return service({
        module: 'role',
        action: 'index',
        payload: payload
    });
})

export const ActionDestroyRole = (({ dispatch }, payload) => {
    return service({
        module: 'role',
        action: 'destroyRole',
        payload: payload
    });
})