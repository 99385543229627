import services  from '@/http'
import { service } from '../../../helpers/http'

export const ActionGetWorkTimeByToken = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'workTimeByToken',
        payload: payload
    })
}

export const ActionFindOptimized = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'optimized',
        payload: payload
    })
}

export const ActionGetByDates = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'availabilityDates',
        payload: payload
    })
}

export const ActionGetByDatesByToken = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'availabilityDatesToken',
        payload: payload
    })
}

export const ActionCreateAvailabilityDate = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'crateDate',
        payload: payload
    })
}

export const ActionUpdateAvailabilityDate = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'updateDate',
        payload: payload
    })
}

export const ActionDeleteAvailabilityDate = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'deleteDate',
        payload: payload
    })
}

export const ActionUpdateAvailabilityDateAll = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'updateDateAll',
        payload: payload
    })
}

export const ActionUpdateAvailabilityDateAllToken = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'updateDateAllToken',
        payload: payload
    })
}

export const ActionGetTeacherByTokenAttribution = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'teacherByTokenAttribution',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionGetTeacherByTokenActivities = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'teacherByTokenActivities',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionAvailabilityCreate = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'availabilityCreate',
        payload: payload
    })
}

export const ActionAvailabilityUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'availabilityUpdate',
        payload: payload
    })
}

export const ActionAvailabilityUpdateToken = ({ dispatch }, payload) => {
    return service({
        module: 'availability',
        action: 'availabilityToken',
        params: {id: payload.id, token: payload.token },
        payload: payload,
    })
}

export const ActionSetAvailability = ({ dispatch }, payload) => {
    
    return new Promise( async (resolve, reject) => {

        try {

            if(payload.isToken){
                var params = {
                    id: payload.id,
                    token: payload.token
                }

                const res = await  services.availability.availabilityToken(params, payload);
                resolve(res.body);
            }else{
                
                if(payload.id == 0){
                    const res = await  services.availability.availabilityStore(payload);
                    resolve(res.body);
                }else{
        
                    const res = await  services.availability.availabilityUpdate( {id: payload.id}, payload);
                    resolve(res.body);
                }
            }
            
        } catch (error) {
            var params;
            
            if(error.status === 422) {
                params = {
                    success: false,
                    code: error.status,
                    errors: error.body.errors,
                    message: error.body.message
                }
            }else{
                params = {
                    success: false,
                    code: error.status,
                    errors: error.body.errors,
                    error: error.statusText,
                    message: error.body.message
                };
            }
            
            resolve(params);
        }
    });
}