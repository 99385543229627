export default [
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Horário das aulas',
            icon: '',
            breadcrumbs: [
                { text: 'Horário', disabled: true, href: '/' },
                { text: 'Horário das aulas', disabled: true, href: '/' },
            ],
        }
    },
    {
        path: '/schedule-date',
        name: 'schedule-date',
        component: () => import ('./pages/ClassScheduleDate'),
        meta: {
            title: 'Horário das aulas por data',
            icon: '',
            breadcrumbs: [
                { text: 'Horário', disabled: true, href: '/' },
                { text: 'Horário das aulas por data', disabled: true, href: '/' },
            ],
        }
    }
]