import { service } from '../../../../helpers/http'

export const ActionIndexDynamicOffers = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicOffers',
        payload: payload
    })
    
}

export const ActionCreateDynamicOffer = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'createDynamicOffer',
        payload: payload
    })
    
}

export const ActionUpdateDynamicOffer = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateDynamicOffer',
        payload: payload
    })
}

export const ActionDeleteDynamicOffer = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'deleteDynamicOffer',
        payload: payload
    })
    
}

export const ActionIndexDynamicOfferTypes = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicOfferTypes',
        payload: payload
    })
    
}

export const ActionDynamicOfferGenerate = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'generateDynamicOffer',
        payload: payload
    })
    
}

