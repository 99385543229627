<template>
    <emc-card-stats
            :icon="data.icon"
            :color="data.color"
            :subtitle="null"
            classCard="mt-0"
            classSheet='mt-0'
            :sub-text="data.title"
            tools
            icon-large
            >

          <template v-if="!hideTools" slot="tools">
              <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :loading="loading" color="primary" icon v-bind="attrs" v-on="on" :disabled="!data.value" @click="$emit('filterChart', data.title)"><v-icon small>mdi-filter-outline</v-icon></v-btn>
                    </template>
                    <span>{{ data.toolTipFilter ? data.toolTipFilter : 'Filtrar' }}</span>
                </v-tooltip>
          </template>
          <template slot="body">
            <v-progress-circular
                v-if="data && data.data"
                :rotate="360"
                :size="140"
                :width="15"
                :value="data.data.percent"
                color="blue accent-1"
              >
              <template slot:default>
                <span>
                  <span>{{ data.data.percent}}%</span><br />
                  <span>{{ data.data.completed}} / {{ data.data.total}}</span>
                </span>
              </template>
            </v-progress-circular>
          </template>
          </emc-card-stats>
</template>

<script>

import  { myMixin } from '../../../mixins';

export default {
    name: 'EmcChartBaseCircular',
    mixins: [myMixin],
    data () {
        return {
          availability: {
            total: 0,
            percent: 0,
            completed: 0
          }
        }
    },
    props: {
        hideTools: {
            type: Boolean,
            default: false
        },
        data: {
            
        },
        index: {

        },
        subText: {
          type: String,
        },
        loading: {
          type: Boolean,
          default: false
        },
    },
    
}
</script>