export default [
    {
        path: '/budgets',
        name: 'budgets',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Orçamento',
            icon: '',
            breadcrumbs: [
                { text: 'Financeiro', disabled: true, href: '/' }, 
                { text: 'Orçamento', disabled: true, href: '/' }, 
            ],
        }
    },
    {
        path: '/costCenters',
        name: 'costCenters',
        component: () => import ('./pages/CostCenterRegister'),
        meta: {
            title: 'Centro de Custo',
            icon: '',
            breadcrumbs: [
                { text: 'Financeiro', disabled: true, href: '/' }, 
                { text: 'Centro de Custo', disabled: true, href: '/' }, 
            ],
        }
    },
]