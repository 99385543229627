<template>
    <div>
        <emc-button-icon v-if="data && data.series && data.series.length > 1 && !hideRefresh" :loading="loading" icon="mdi-refresh" text="Atualizar" color="light-green" @click.prevent.stop="$emit('refresh')" />
        <apexchart v-if="renderComponent" :type="data.type" :options="chartOptions" :series="data.series"></apexchart>
    </div>
</template>

<script>

export default {
    name: 'EmcChartApexBase',
    data (vm) {
        return {
            renderComponent: true,
            chartOptions: {
                    series: (vm.data && vm.data.series) ? vm.data.series : [],
                    chart: {
                    width: '100%',
                    type: (vm.data && vm.data.type) ? vm.data.type : 'pie',
                    },
                labels: (vm.data && vm.data.labels) ? vm.data.labels : [],
                theme: {
                    monochrome: {
                    enabled: true
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                title: {
                    text: (vm.data && vm.data.title) ? vm.data.title : ''
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex];
                        return [name, val.toFixed(1) + '%'];
                    }
                },
                legend: {
                    show: false
            }
    },
        }
    },

    mounted() {
        
    },

    watch: {
        data(val){
            this.forceRerender();
      },
    },

    props: {
       data: {
            type: Object,
            required: true,
       },
       loading: {
            type: Boolean,
            default: false,
       },
       hideRefresh: {
            type: Boolean,
            default: false,
       }
    },


    methods: {
        
        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },
    
        
    },
    
}
</script>