
import { mapActions } from 'vuex'

export default {
  data: () => ({
    show: false,
    showAccessTeacher: true,
    valid: true,
    snackbar: false,
    loading: false,
    showLogin: false,
    erro: '',
    errors: [],
    login: '',
    password: '',
    form: {
      email: '',
      password: ''
    },
    nameRules: [
      v => !!v || 'Email obrigatório'
    ],
    senhaRules: [
      v => !!v || 'Senha obrigatória'
    ],
  }),

  created(){

    let env = process.env.VUE_APP_ENV.toString();
    
    if(env.includes('anima')){
      
      if(this.$route.query.support && this.$route.query.support == 'true'){
        this.showLogin = true;
      }else{
        let path = process.env.VUE_APP_ROOT_API + 'login/anima/redirect';
        window.location.href = path;
      }

    }else{
      this.showLogin = true;
    }

  },

  methods: {
    ...mapActions('auth', ['ActionDoLogin']),
    resetPassword() {
      this.$router.push({ name: 'reset_password' });
    },
    submit(){
        
        this.snackbar = false;
        this.loading = true;
        this.erro = '';
        this.errors = [];
        
        this.ActionDoLogin(this.form).then(() => {
          this.$router.push({ name: 'worktime' });
        })
        .catch((error) =>{
          this.erro = error.message;
          this.errors = error.errors;
          this.snackbar = true;
          this.loading = false;        
        });
      
    },
    validate () {
      
      if (this.$refs.form.validate()) {
        this.submit();
      }

    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },

    toAccessTeacher(){
      this.$router.push({ name: 'access-teacher' });
    },

  },
}