<template>
  <div>
      <SysCoreNavigationBar />
      <v-main>
        <v-container fluid>
            <!-- <SysCoreBreadcrumbs v-if="menu.length > 0"/> -->
            <router-view></router-view>
        </v-container>
      </v-main>

      <div class="text-center">
        <v-bottom-sheet
          v-model="sheet"
          persistent
        >
          <v-sheet
          >
            <div class="text-center">
              <v-btn
              class="mt-6"
              text
              outlined
              color="error"
              @click="sheet = !sheet"
            >
              Fechar mensagem
              <v-icon right>mdi-close</v-icon>
            </v-btn>

            <p class="text-h5 mt-2 red--text">Ocorreu um erro inesperado!</p>
            </div>
            
            <v-row>
              <v-app-bar
                color="white"
                dense
                class="elevation-0 pr-10 mb-2"
              >

              <v-spacer></v-spacer>

              <v-btn
                color="info"
                outlined
                @click.prevent="capturaTela"
              >
                Capturar imagem
                <v-icon right>mdi-image-size-select-actual</v-icon>
              </v-btn>
              <v-btn
                color="info"
                outlined
                @click="showOpenTicket = true"
                class="ml-4"
                v-if="acesso.instituicao && acesso.instituicao.educational_group_id != 16"
              >
                Abrir um chamado
                <v-icon right>mdi-text-box-plus</v-icon>
              </v-btn>

              </v-app-bar>
            </v-row>
            <div class="py-3">
              <v-alert
                border="top"
                outlined
                prominent
                type="error"
                elevation="2"
                class="mx-10"
                v-for="(item, i) in errorsHttp" :key="i"
              >

              <v-row
                align="center"
                no-gutters
              >
                <v-col class="grow">
                  <p class="ma-0"><strong>Requisição:</strong> {{ item.url }}</p>
                  <p class="ma-0"><strong>Mensagem:</strong> {{ item.message }}</p>
                </v-col>
              </v-row>
              
              </v-alert>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </div>

      <OpenTicket 
    :show="showOpenTicket"
    @update:show="(value) => showOpenTicket = value"
/>

    <SysCoreFooter />
  </div>
</template>

<script>

  import { mapState } from 'vuex'
  import html2canvas from 'html2canvas';
  import OpenTicket from '../../../modules/management/ticket/pages/ticket/OpenTicket'

  export default {
    name: 'SysCoreMain',
    data: () => ({
      showOpenTicket: false
    }),
    computed: {
      ...mapState('auth', ['user', 'errorsHttp', 'acesso']),
      sheet: {
          get() {
            return this.errorsHttp.length ? true : false
          },
          set() {
            return this.$store.dispatch('auth/ActionSetErrorsHttp', {})
          }
      }
    },
    components: {
      OpenTicket
    },
    methods: {

      capturaTela() {
        //var element = document.getElementById('app');
        html2canvas(document.body).then(canvas => {

          // Crie um elemento de imagem para exibir o print
          var screenshotImage = new Image();
          screenshotImage.src = canvas.toDataURL('image/png');

          // Crie uma janela pop-up para exibir o print
          var popupWindow = window.open();
          popupWindow.document.write('<html><head><title>Screenshot</title></head><body></body></html>');
          popupWindow.document.body.appendChild(screenshotImage);

        })

      }
    }
  }
</script>
