<template>
    <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      left
      bottom
    >
      
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          color="grey"
          small
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Configurações</v-list-item-title>
              <v-list-item-subtitle>Ambientes</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon @click="menu = false">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>

          <v-list-item>
            <v-list-item-action>
              <v-switch @change="updateConfigs()" inset v-model="config.capacity.validate" color="purple"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Validar Capacidade</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
             <v-radio-group @change="updateConfigs()" :disabled="!config.capacity.validate" v-model="config.capacity.type">
                  <!-- <template v-slot:label>
                    <div><strong>Validação de Capacidade</strong></div>
                  </template> -->
                  <v-radio color="success" value="capacity">
                    <template v-slot:label>
                      <div>Capacidade <strong class="success--text">Real</strong></div>
                    </template>
                  </v-radio>
                  <v-radio color="primary"  value="extended_capacity">
                    <template v-slot:label>
                      <div>Capacidade <strong class="primary--text">Estendida</strong></div>
                    </template>
                  </v-radio>
                  <v-radio color="purple" value="reduced_capacity">
                    <template v-slot:label>
                      <div>Capacidade <strong class="purple--text">Reduzida</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
            </v-list-item-action>
          </v-list-item>

          <v-divider/>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Periodicidade</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
             <v-radio-group @change="updateConfigs()" v-model="config.periodicity">
                  <!-- <template v-slot:label>
                    <div><strong>Validação de Capacidade</strong></div>
                  </template> -->
                  <v-radio color="success" value="1">
                    <template v-slot:label>
                      Semanal
                    </template>
                  </v-radio>
                  <v-radio color="primary"  value="2">
                    <template v-slot:label>
                      Quinzenal
                    </template>
                  </v-radio>
                  <v-radio color="purple" value="3">
                    <template v-slot:label>
                      Mensal
                    </template>
                  </v-radio>
                  <!-- <v-radio color="indigo" value="4">
                    <template v-slot:label>
                      Data
                    </template>
                  </v-radio> -->
                </v-radio-group>
            </v-list-item-action>
          </v-list-item>

        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="menu = false">Cancel</v-btn>
          <v-btn color="primary" text @click="menu = false">Save</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
    name: "SysAmbiencePopOver",
    data: () => ({
      fav: true,
      menu: false,
      allowShockTeacher: false,
      allowDoubleClass: false,
      allowDesloc: false,
      allowJourney: false,
      hints: true,
      config: {
        capacity: {
            validate: true,
            type: 'capacity',
        },
        periodicity: '1'
      },
    }),
    mounted(){
      this.updateConfigs();
    },
    computed: {
       
    },
    watch: {
        
        model(model_new, model_old) {
            
            if(model_new !== model_old) {
                this.menu = model_new;
            }
        },

        menu(menu_new, menu_old) {
            
            if(menu_new !== menu_old) {
                this.$emit('update:model', menu_new);
            }
        },

    },
    props: {
        model: {
            type: Boolean,
            default: false,
        },
        configs: {
          type: Object
        },
        
    },
    methods: {

      updateConfigs(){
        this.$emit('update:configs', this.config)
      }

    }
}
</script>