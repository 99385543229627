<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '50%'" persistent >
        <v-card 
                outlined
                height="65vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-0 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            <span class="mb-0">
                                ({{ selected.length }}) Professor(es) selecionado(s) <strong v-if="selected.length > maxSelected" class="red--text">Máximo {{ maxSelected }} professores</strong>
                            </span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="selected.length === 0" icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                    <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </template>
                        <span>Limpar Seleção</span>
                    </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="disableButton" :loading="loadingSaving" icon @click.prevent.stop="generate()" v-bind="attrs" v-on="on">
                                    <v-icon color="amber darken-1">mdi-flash-outline</v-icon>
                                </v-btn>
                            </template>
                        <span>Gerar Termos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-list>
                                    <v-list-item-group
                                        v-model="selectedTeacher"
                                        color="primary"
                                    >
                                            <v-list-item
                                                ripple
                                                @mousedown.prevent
                                                @click="toggle"
                                            >
                                                <v-list-item-action>
                                                    <v-icon v-if="items.length" :color="selected.length > 0 ? 'indigo darken-4' : ''">
                                                        {{ icon }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        Selecionar todos
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                
                                                <v-progress-linear
                                                    v-if="loading"
                                                    indeterminate
                                                    color='yellow darken-2'
                                                />

                                                <emc-util-scroll height="45vh">
                                                    <v-list-item
                                                        v-for="(item, index) in items"
                                                        :key="index"
                                                        @mousedown.prevent
                                                        @click="selectTeacher(index)"
                                                        >
                                                        <v-list-item-icon>
                                                            <v-icon :color="isCheckedTeacher(index) ? 'indigo darken-4' : ''" v-text="isCheckedTeacher(index) ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'" />
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </emc-util-scroll>
                                    </v-list-item-group>
                                </v-list>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-term
                                :model.sync="term_id"
                                label="Termo"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
                <v-card-actions>
                    <v-container>
                        <v-row>
                            <v-col cols="11">
                                    <v-pagination
                                    v-model="page"
                                    class="my-4"
                                    @input="pagination()"
                                    :length="totalPages"
                                    ></v-pagination>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysTermGenerate",
    data: (vm) => ({
        dialog: false,
        loading: false,
        loadingSaving: false,
        page: 1,
        maxSelected: 200,
        totalPages: 0,
        term_id: null,
        items: [],
        selected: [],
        selectedCurrent: [],
        selectedTeacher: null,
    }),
    computed: {
        
        disableButton (){
            
            return !this.selected.length || this.selected.length > this.maxSelected || !this.term_id;

        },

        likesAllTeacher () {
            
            return this.selectedCurrent.length === this.items.length
        },

        likesSomeTeacher () {
            return this.selectedCurrent.length > 0 && !this.likesAllTeacher
        },

        icon () {
            if (this.likesAllTeacher) return 'mdi-close-box'
            if (this.likesSomeTeacher) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },

    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;


            if(val){
                
                if(this.items.length == 0){
                    this.getItems();
                }

            }
        },

    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeachers']),
        ...mapActions('term', ['ActionUpdateTermSignatureGenerate']),       
        
        async getItems() {

            this.loading = true;
            this.loadingSaving = false;
            this.selectedCurrent = [];

            let payload = {
                column_order: 'name',
                direction: 'asc',
                page: this.page,
                per_page: this.maxSelected,
                find_columns: {
                    active: 1,
                }
            }

            await this.ActionFindTeachers(payload)
                    .then((res) => {
                        this.totalPages = res.meta.last_page;
                        this.items = res.data;

                        this.items.forEach(item => {
                            if(this.selected.includes(item.id)){
                                this.selectedCurrent.push(item.id);
                            }
                        });

                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },

        pagination(){

            this.getItems();

        },


        generate(){

            if(!this.term_id){
                return;
            }
            
            let payload = {
                teacher_ids:    this.selected,
                term_id:        this.term_id,
            };

            this.loadingSaving = true;

            this.ActionUpdateTermSignatureGenerate(payload)
                .then((res) => {
                    this.$emit('onCompleted');
                })
                .finally(() => {
                    this.loadingSaving = false;
                });

            
       },

       toggle () {
            
            this.$nextTick(() => {
                
                if (this.likesAllTeacher) {
                    
                    this.selectedCurrent.forEach(id => {
                        
                        if(this.selected.includes(id)){
                            this.selected.splice(this.selected.indexOf(id), 1);
                        }
                    });
                   
                    this.selectedCurrent = [];

                }else{
                    for (let index = 0; index < this.items.length; index++) {
                        
                        let id = this.items[index]['id'];
                        
                        if(!this.selected.includes(id)){
                            this.selected.push(id);
                        }

                        if(!this.selectedCurrent.includes(id)){
                            this.selectedCurrent.push(id);
                        }
                   }
                }
                
            })
        },

        clearFilter(){
            this.selected = [];
            this.selectedCurrent = [];
        },

        isCheckedTeacher(index){
            
            let id = this.items[index]['id'];
            
            return this.selected.includes(id);
        },

        selectTeacher(index){
            
            let id = this.items[index]['id'];
            
            if(this.selected.includes(id)){
                this.selected.splice(this.selected.indexOf(id), 1);
            }else{
                this.selected.push(id);
            }

            if(this.selectedCurrent.includes(id)){
                this.selectedCurrent.splice(this.selectedCurrent.indexOf(id), 1);
            }else{
                this.selectedCurrent.push(id);
            }
            
        },

        

    }
}
</script>