<template>
    <div class="ma-0 pa-0">
        <v-tooltip bottom v-if="toolTip">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :href="href" :target="target"  class="ma-0 pa-0" @click.prevent.stop="$emit('click')" :disabled="disabled" :loading="loading"  icon :x-small="xSmall" :small="small" v-bind="attrs" v-on="on">
                         <v-icon class="ma-0 pa-0" :color="color" :x-small="xSmall" :small="small">{{ icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ toolTip }}</span>
        </v-tooltip>
        <v-btn v-else  :href="href" :target="target" class="ma-0 pa-0" @click.prevent.stop="$emit('click')" :disabled="disabled" :loading="loading" icon :x-small="xSmall" :small="small" v-bind="attrs" v-on="on">
               <v-icon class="ma-0 pa-0" :color="color" :x-small="xSmall" :small="small">{{ icon }}</v-icon>
     </v-btn>
    </div>
</template>
<script>
export default {
    name: "SIcon",
    data () {
        return {
           
        }
    },
    props: {
       toolTip: {
            type: String,
            default: null,
       },
       color: {
            type: String,
            default: 'gray',
       },
       small: {
            type: Boolean,
            default: false
       },
       xSmall: {
            type: Boolean,
            default: false
       },
       disabled: {
            type: Boolean,
            default: false
       },
       icon: {
            type: String,
            required: true
       },
       loading: {
            type: Boolean,
            default: false
       },
       href: {
          type: String,
       },
       target: {
          type: String,
          default: '_blank'
       }
    },
}
</script>
