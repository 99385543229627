<template>
    <span v-if="item && item.category && !item.category.default">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-chip style="cursor: help" v-bind="attrs" v-on="on" :color="color ? color : (item.category.color ? item.category.color : 'primary')" label x-small outlined>
                    <v-icon v-if="icon" @click="$emit('click')" class="mr-1" x-small>{{ icon }}</v-icon>
                    <v-icon v-else-if="item.category.icon" @click="$emit('click')" class="mr-1" x-small>{{ item.category.icon }}</v-icon>
                    {{ item.category.initials }}
                </v-chip>
            </template>
            <span>{{ item.category.name }}</span>
        </v-tooltip>
    </span>
</template>
<script>
export default {
    name: "EmcUtilIconCategory",
    data () {
        return {
            
        }
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        }
    },
}
</script>