<template>
    <v-container>
        <v-card
            elevation="2"
            shaped
        >
            <v-alert 
                v-if="!isAvailable"
                dense
                outlined
                type="warning"
            >
                <strong>O período para confirmar a carga horária não está liberado!</strong>
            </v-alert>
            
            <v-card-title>
                Confirmação dos Horários e Atribuições para o período {{ workTime ? workTime.name : '' }}
            </v-card-title>
            <v-card-subtitle>

                <v-alert 
                    colored-border
                    type="info"
                    elevation="1"
                    icon="mdi-information-variant"
                >
                    <p v-if="text && text != ''">{{ text }}</p>

                    <span v-if="hasDate">
                        <strong>Prazo Oficial: </strong> {{ workTime.confirmation_start | date }}  à {{ workTime.confirmation_end | date }}
                    </span>
                    <span v-else><strong>Prazo Oficial: </strong> Ainda não definido!</span>
                    
                    <span v-if="teacher.is_available_confirmation">
                        <br/><strong>Prazo Estendido: </strong> {{ teacher.release_confirmation_until | date }} 
                    </span>
                
                </v-alert>

            </v-card-subtitle>
            <v-card-text>

                <v-textarea
                    :error="!!errors.note_teacher" 
                    :error-messages="errors.note_teacher" 
                    v-model="note_teacher" 
                    label="Observação (Caso NÃO CONFIRME, é necessário informar o motivo)"
                    :counter="255"
                    :rows="2"
                    clearable
                    :disabled="!isAvailable || !allowActions"
                    :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                    outlined
                />
                
                <span v-if="confirmation">
                    
                    <v-alert
                        v-if="confirmation.work_load_confirmation_status_id == 2"
                        dense
                        text
                        
                        type="success"
                        >
                        Você <strong>CONFIRMOU</strong> sua carga horária em <strong>{{ confirmation.date_confirmation | dateTime }}</strong>.
                    </v-alert>

                    <v-alert
                        v-if="confirmation.work_load_confirmation_status_id == 3"
                        dense
                        outlined
                        type="error"
                        >
                        Você informou que <strong>NÃO CONFIRMA</strong> sua carga horária em <strong>{{ confirmation.date_confirmation | dateTime }}</strong>.
                    </v-alert>
                </span>
                <span v-else>
                    <v-alert
                        v-if="!confirmation"
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                    >
                        Você ainda não confirmou sua carga horária!
                    </v-alert>
                </span>

                

            </v-card-text>
            <v-card-actions v-if="allowActions" class="ml-2">
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn outlined class="mr-1" rounded :disabled="!isAvailable" v-on="on" color="success" @click="showMessageConfirm(2)">
                        <v-icon small class="mr-1" dark>mdi-thumb-up</v-icon>
                            Confirmar
                    </v-btn>
                    </template>
                    <span>Estou de acordo com minha carga horária atribuída</span>
                </v-tooltip>

                <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                    <v-btn outlined class="mr-1"  rounded :disabled="!isAvailable" v-on="on" color="error" @click="showMessageConfirm(3)">
                        <v-icon small class="mr-1" dark>mdi-thumb-down</v-icon>
                            Não Confirmar
                    </v-btn>
                    </template>
                    <span>Não estou de acordo com minha carga horária atribuída</span>
                </v-tooltip>

                <!-- <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                    <v-btn class="mr-1" rounded :disabled="!isAvailable || loading" :loading="loading && status == 4" v-on="on" color="warning" @click="save(4)">
                        <v-icon class="mr-1" dark>warning</v-icon>
                            Divergente
                    </v-btn>
                    </template>
                    <span>Minha carga horária está divergente</span>
                </v-tooltip> -->

                <v-spacer></v-spacer>

                <!-- <emc-file-upload
                    v-if="token && (confirmation && confirmation.work_load_confirmation_status_id == 2)"
                    accept=".pdf" 
                    placeholder="Selecioner um arquivo PDF" 
                    type="teacher-extract" 
                    :item-id="teacher.id"
                    :token="token"
                    @onCompleted="teacher.confirmation = $event"
                />

                <emc-table-icon-download-pdf 
                    v-if="token && (!confirmation || !confirmation.file_extract)"
                    toolTip="Ver extrato" 
                    type="extract" 
                    :token="token" 
                    :item="teacher" 
                />

                <emc-table-icon-download-pdf 
                    v-if="token && confirmation && confirmation.file_extract"
                    toolTip="Download do extrato não assinado" 
                    type="extract-download"
                    :token="token" 
                    :item="teacher"
                    icon="mdi-file-download-outline"
                    color="success"
                />

                <emc-table-icon-download-pdf 
                    v-if="token && confirmation && confirmation.file_extract_signed"
                    toolTip="Download do extrato Assinado" 
                    type="extract-download"
                    signed
                    :token="token" 
                    :item="teacher"
                    icon="mdi-file-download-outline"
                    color="primary"
                /> -->

            </v-card-actions>
        </v-card>

        <emc-alert-modal :text="confirmText" :disabled="loading" v-bind:show.sync="showConfirm">
            <template v-slot:actions>
                <v-btn :color="confirmColor" @click.prevent.stop="save()" :loading="loading" :disabled="loading">{{ confirmButtonText }}<v-icon right dark>{{ confirmIcon }}</v-icon></v-btn>
            </template>
        </emc-alert-modal>

    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherWorkLoadConfirm",
    data: (vm) => ({
        error: false,
        errors: [],
        loading: false,
        status: 0,
        note_teacher: null,
        showConfirm: false,
        confirmText: '',
        fileUpload: null,
        confirmIcon: '',
        confirmColor: '',
        confirmButtonText: ''
        
    }),
    created(){
      this.setData();
    },

    watch: {
        teacher(){
            this.setData();
        }
    },

    computed: {
      ...mapState('auth', ['acesso']),
      
      isAvailable() {
          
          if(this.teacher && this.teacher.is_available_confirmation){
                return true;
          }else{
                return this.workTime && this.workTime.is_available_confirmation;
          }
      },

      hasDate() {
          return this.workTime && (this.workTime.confirmation_start && this.workTime.confirmation_end);
      },

      confirmation() {
          
          if(this.teacher && this.teacher.confirmation){
              return this.teacher.confirmation;
          }else{
              return null;
          }
          
          
      },
    },

    props: {
        teacher: {},
        workTime: {},
        token:{
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        allowActions: {
            type: Boolean,
            required: true
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionUpdateWorkLoadConfirmationToken']),
        
        setData(){
            this.error =    false;
            this.errors =   [];
            
            if(this.confirmation){
                this.note_teacher = this.confirmation.note_teacher;
            }
        },

        showMessageConfirm(status){
            
            if(status == 2){
                this.confirmText = 'Confirma que ESTÁ DE ACORDO com a carga horária atribuída? Você poderá realizar alteração enquanto o período estiver liberado!';
                this.confirmIcon = 'mdi-thumb-up';
                this.confirmColor = 'success';
                this.confirmButtonText = 'CONFIRMO MINHA CARGA HORÁRIA';
            }else{
                this.confirmText = 'Confirma que NÃO ESTÁ DE ACORDO com a carga horária atribuída? Você poderá realizar alteração enquanto o período estiver liberado!';
                this.confirmIcon = 'mdi-thumb-down';
                this.confirmColor = 'error';
                this.confirmButtonText = 'NÃO CONFIRMO MINHA CARGA HORÁRIA';
            }

            this.status = status;
            this.showConfirm = true;
        },
        
        save(){

            this.error = false;
            this.errors = [];
            
            if(this.loading){
                return;
            }
            
            if(!this.teacher || !this.teacher.id || this.teacher.id == 0){
                return;
            }

            this.loading = true;
            
            var payload = {
                token:          this.token,
                note:           this.note_teacher,
                status_id:      this.status,
            };

            this.ActionUpdateWorkLoadConfirmationToken(payload)
                .then((res) => {
                    this.teacher.confirmation = res.data;
                    this.$emit('onComplete', {text: 'Confirmação de carga horária atualizada com sucesso!', error: false});
                })
                .catch((error) =>{
                    this.error = true;
                    this.errors = error.errors;
                    this.$emit('onComplete', {text: 'Erro ao atualizar a confirmação de carga horária!', error: true});
                })
                .finally(() => {
                    this.loading = false;
                    this.showConfirm = false;
                });
        },
    }
}
</script>