import { service } from '../../../../helpers/http'


//Início das chamadas para categoria de tickets
export const ActionTicketCategoriesIndex = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketCategoriesIndex',
        payload: payload
    })
}

export const ActionTicketCategoriesStore = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketCategoriesStore',
        payload: payload
    })
}

export const ActionTicketCategoriesUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketCategoriesUpdate',
        payload: payload
    })
}

export const ActionTicketCategoriesDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketCategoriesDestroy',
        payload: payload
    })
}

//Início das chamadas para status dos tickets
export const ActionTicketStatusesIndex = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketStatusesIndex',
        payload: payload
    })
}

export const ActionTicketStatusesStore = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketStatusesStore',
        payload: payload
    })
}

export const ActionTicketStatusesUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketStatusesUpdate',
        payload: payload
    })
}

export const ActionTicketStatusesDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketStatusesDestroy',
        payload: payload
    })
}

//Início das chamadas para os tipos de tickets
export const ActionTicketTypesIndex = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketTypesIndex',
        payload: payload
    })
}

export const ActionTicketTypesStore = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketTypesStore',
        payload: payload
    })
}

export const ActionTicketTypesUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketTypesUpdate',
        payload: payload
    })
}

export const ActionTicketTypesDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketTypesDestroy',
        payload: payload
    })
}

//Início das chamadas para os tickets
export const ActionTicketsIndex = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsIndex',
        payload: payload
    })
}

export const ActionTicketsStore = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsStore',
        payload: payload
    })
}

export const ActionTicketsUpdate = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsUpdate',
        payload: payload
    })
}

export const ActionTicketsDestroy = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsDestroy',
        payload: payload
    })
}

export const ActionTicketsCommentStore = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsCommentStore',
        payload: payload.form,
        params: payload.params
    })
}

export const ActionTicketsGraphTotalIndex = ({ dispatch }, payload) => {
    return service({
        module: 'ticket',
        action: 'ticketsGraphTotalIndex',
        payload: payload
    })
}