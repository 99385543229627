export default [
    {
        path: '/courseInfos',
        name: 'courseInfos',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Informações do curso',
            icon: '',
            breadcrumbs: [
                { text: 'Cadastros gerais', disabled: true, href: '/' },
                { text: 'Informações do curso', disabled: true, href: '/' },
            ],
        }
    },
]