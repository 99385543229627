import { service } from '../../../helpers/http'

export const ActionIndexMessages = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'message', 
		action: 'indexMessages', 
		payload: payload 
	})
}

export const ActionCreateMessage = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'message', 
		action: 'createMessage', 
		payload: payload 
	})
}

export const ActionUpdateMessage = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'message', 
		action: 'updateMessage', 
		payload: payload 
	})
}

export const ActionDestroyMessage = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'message', 
		action: 'destroyMessage', 
		payload: payload 
	})
}