export default [
    {
        path: '/work-time-categories',
        name: 'work-time-categories',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Categoria de Período Letivo',
            icon: 'mdi-puzzle-outline',
            breadcrumbs: [
                { text: 'Categoria de Período Letivo', disabled: true, href: '/' },
                { text: 'Categoria de Período Letivo', disabled: true, href: '/' },
            ],
        }
    },
]