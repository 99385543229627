<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :color="color" icon @click.prevent.stop="clickAction()" v-bind="attrs" v-on="on"><v-icon>{{ icon }}</v-icon></v-btn>
            </template>
            <span> {{ toolTip }} </span>
        </v-tooltip>

        <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '30%'" persistent>
                <v-card>
                        <v-card-title class="ma-0 pa-0">
                            <v-app-bar dense height="45" class="grey lighten-5 mb-4 elevation-1'">
                                <v-icon left>mdi-filter-outline</v-icon>
                                    <v-toolbar-title class="subtitle-1">
                                        Filtros
                                    </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                                <v-icon color="red lighten-2">mdi-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Fechar</span>
                                </v-tooltip>
                            </v-app-bar>
                        </v-card-title>
                        
                        <v-card-text>
                            <v-container v-if="filterCalendar">
                                <v-row v-if="isAnima()">
                                    <v-col cols="12" sm="12">
                                        <sys-select-brand
                                            :model.sync="brands"
                                            label="Marca"
                                            multiple
                                            dense
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <sys-select-campus
                                            :model.sync="campuses"
                                            label="Campus"
                                            multiple
                                            dense
                                            :brand-id="brands.map(i => i.id).join(',')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <sys-select-states
                                            :modelObject.sync="states"
                                            label="Estado"
                                            multiple
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <sys-select-event-area
                                            :model.sync="areas"
                                            label="Área"
                                            multiple
                                            dense
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <sys-select-event-category
                                            :model.sync="categories"
                                            label="Categoria"
                                            multiple
                                            dense
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <sys-select-event-public
                                            :model.sync="publics"
                                            label="Público"
                                            multiple
                                            dense
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="ligth green" @click.prevent.stop="downloadReport()">Download</v-btn>
                        </v-card-actions>
                    </v-card>

            </v-dialog>

    </div>
</template>

<script>

import { myMixin } from '../../../mixins'

export default {
    name: "EmcExcelExportIcon",
    mixins: [myMixin],
    data () {
        return {
            dialog: false,
            brands: [],
            campuses: [],
            states: [],
            publics: [],
            categories: [],
            areas: [],
        }
    },
    watch: {

        dialog(val){
            if(val){
                this.brands = [];
                this.campuses = [];
                this.publics = [];
                this.categories = [];
                this.areas = [];
                this.states = [];
            }
        }

    },
    props: {
        reportId:{
            required: true,
        },
        color: {
            type: String,
            default: "success darken-1"
        },
        toolTip: {
            type: String,
            default: "Relatório"
        },
        icon: {
            type: String,
            default: "mdi-microsoft-excel"
        },
        filterCalendar: {
            type: Boolean,
            default: false,
        },
        params: {
            type: String,
        },
        attrs: {},
        on: {}
    },
    
    methods: {

       clickAction(){

        if(this.filterCalendar){
            this.dialog = true;
        }else{
            this.downloadReport();
        }

       },

       downloadReport()
       {
            
            let paramsFilter = null;

            if(this.params){
                paramsFilter = this.params;
            }

            if(this.brands.length){
                
                let paramsBrands = 'brands=' + this.brands.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsBrands;
                }else{
                    paramsFilter = paramsBrands;
                }

            }

            if(this.campuses.length){
                
                let paramsCampus = 'campuses=' + this.campuses.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsCampus;
                }else{
                    paramsFilter = paramsCampus;
                }

            }

            if(this.publics.length){
                
                let paramsPublics = 'publics=' + this.publics.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsPublics;
                }else{
                    paramsFilter = paramsPublics;
                }

            }

            if(this.areas.length){
                
                let paramsAreas = 'areas=' + this.areas.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsAreas;
                }else{
                    paramsFilter = paramsAreas;
                }

            }

            if(this.categories.length){
                
                let paramsCategories = 'categories=' + this.categories.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsCategories;
                }else{
                    paramsFilter = paramsCategories;
                }

            }

            if(this.states.length){
                
                let paramsStates = 'states=' + this.states.map(i => i.id).join(',');

                if(paramsFilter){
                    paramsFilter += '&' + paramsStates;
                }else{
                    paramsFilter = paramsStates;
                }

            }
        
            this.download(this.reportId, paramsFilter);
       }

    }
}
</script>
