import { service } from '../../../helpers/http'

export const ActionFindAmbienceOccupation = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceOccupation',
        action: 'occupation',
        payload: payload
    })
    
}

export const ActionFindAmbienceOccupationIdeal = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceOccupation',
        action: 'occupationIdeal',
        payload: payload
    })
    
}

export const ActionFindAmbienceBalance = ({ dispatch }, payload) => {
    
    return service({
        module: 'ambienceOccupation',
        action: 'balance',
        payload: payload
    })
    
}

