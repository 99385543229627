<template>

    <div>
        <v-file-input
            v-model="model"
            :counter="counter"
            :label="label"
            :multiple="multiple"
            placeholder="Selecione os arquivos"
            :outlined="outlined"
            :show-size="1000"
            @change="handleFileChange"
            :name="name"
            :error="error"
        >
            <template v-slot:selection="{ index, text }">
                <v-chip
                    v-if="index < 2"
                    outlined
                    label
                    small
                    color="grey grey--text text--darken-3"
                >
                    {{ text }}
                </v-chip>
                
                <span
                v-else-if="index === 2"
                class="text-overline grey--text text--darken-3 mx-2"
                >
                +{{ model.length - 2 }} Arquivo(s)
                </span>
      </template>
    </v-file-input>

        <!-- <div v-if="selectedFiles" v-for="(item, i) in selectedFiles" :key="i">
            <p>Nome do arquivo: {{ item.name }}</p>
            <p>Tipo do arquivo: {{ item.type }}</p>
            <p>Tamanho do arquivo: {{ item.size }} bytes</p>
        </div> -->
    </div> 
  </template>

<script>
export default {
    name: "EmcFormFile",
    data: () => ({
        files: [],
        selectedFiles: null
    }),
    props: {
        label: { type: String, default: "Anexo" },
        outlined: { type: Boolean, required: false },
        multiple: { type: Boolean, required: false },
        counter: { type: Boolean, default: true },
        value: { type: [String, Number, Object, Array] },
        name: { type: String, default: "file" },
        error: { type: Boolean, required: false }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        handleFileChange(files) {
        // Verifica se há arquivos selecionados
        if (files.length > 0) {
            // Access the selected file
            const file = files;

            // Update the data property
            this.selectedFiles = file;
        } else {
            // Caso não haja arquivos selecionados, redefine selectedFile para null
            this.selectedFiles = null;
        }
    }
  }
}
</script>