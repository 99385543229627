import { service } from '../../../helpers/http'

export const Action = ({ dispatch }, payload) => {
    
    return service({
        module: 'mudule',
        action: 'action',
        payload: payload
    })
    
}

