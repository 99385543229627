<template>
    <v-dialog v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <div>
        <v-card>
            <v-app-bar color="grey lighten-4" dense>
                <v-toolbar-title><v-icon left>mdi-account-clock-outline</v-icon>&nbsp;&nbsp; Enviar email de disponibilidade</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :color="(modelTemplate.id) ? 'teal darken-1' : 'red'" icon @click.prevent.stop="showEmailTemplate = true" v-bind="attrs" v-on="on"><v-icon>mdi-card-account-mail-outline</v-icon></v-btn>
                    </template>
                    <span>{{ (modelTemplate.id) ? 'Template do email' : 'Template de email não cadastrado' }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" :disabled="saving" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>

            <v-card-text>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="teachers"
                    :single-select="false"
                    item-key="name"
                    show-select
                    :search="search"
                    class="elevation-1 mt-2"
                    dense
                    :itemsPerPage="10"
                    @current-items="getFiltered"
                    :item-selected="selected"
                    :loading="loading"
                    fixed-header
                    height="360"
                    
                >
                    <template v-slot:top>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="5">
                                <v-text-field
                                    v-model="search"
                                    label="Pesquisar..."
                                    class="mx-4"
                                    />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <sys-select-campus
                                    :model.sync="campuses"
                                    label="Campus"
                                    multiple
                                    selectAll
                                    @loaded="itemsCampuses = $event"
                                />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-switch 
                                    v-model="onlyPending"     
                                    :loading="!firstCharge && loadingPending" 
                                    @change="getData()" 
                                    class="pl-4" 
                                    inset 
                                    label="Somente pendentes"
                                    :disabled="campuses.length == 0"
                                />
                            </v-col>
                            <v-col cols="12" sm="1">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            v-bind="attrs" v-on="on"    
                                            color="primary" 
                                            @click.prevent.stop="getData(true)" 
                                            :loading="loadingSearch" 
                                            icon
                                            dense
                                            :disabled="campuses.length == 0"
                                        >
                                                <v-icon dark>search</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Buscar professores</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-container>
                    </template>

                    <template v-slot:item.email="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span v-if="item.emails.length > 1">{{ item.email }} <strong>(+{{ item.emails.length - 1 }})</strong></span>
                                    <span v-else>{{ item.email }}</span>
                                </span>
                            </template>
                            <span v-html="item.emails.map(o => o['email']).join('<br/>')"></span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.campuses="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.campuses.map(o => o['initials']).join(', ') }}</span>
                            </template>
                            <span> {{ item.campuses.map(o => o['name']).join(', ') }}  </span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.status="{ item }">
                        {{ item.availabilities.length > 0 ? item.availabilities[0].status.name : 'Pendente'  }}
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon :color="(modelTemplate.id) ? 'blue' : 'red'" small class="mr-2" @click="showEmail(item)" v-bind="attrs" v-on="on">mdi-newspaper-variant-outline</v-icon>
                            </template>
                            <span>{{ (modelTemplate.id) ? 'Visualizar' : 'Template de email não cadastrado' }}</span>
                        </v-tooltip>
                        <v-tooltip v-if="!workTime.is_available_availability" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :color="item.is_available_availability ? 'success': 'error'" icon v-bind="attrs" v-on="on" @click="showReleaseAvailability(item)"><v-icon small>{{ item.is_available_availability ? 'mdi-lock-open-variant-outline' : 'mdi-lock-open-outline' }}</v-icon></v-btn>
                            </template>
                            <span>
                                <span v-if="item.is_available_availability">
                                    Liberado até {{ item.release_availability_until | date }}
                                </span>
                                <span v-else>
                                    Liberar disponibilidade
                                </span>
                            </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" icon v-bind="attrs" v-on="on" :disabled="saving || !modelTemplate.id || item.emails.length == 0" @click="showSending(item)"><v-icon small>mdi-send</v-icon></v-btn>
                            </template>
                            <span>Enviar email para {{ item.name }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:footer.prepend>
                        <v-btn 
                            dense 
                            color="primary" 
                            @click.prevent.stop="showSending()" 
                            :loading="saving" 
                            :disabled="saving || !modelTemplate.id || selected.length == 0"
                        >
                                Enviar para selecionados
                                <v-icon right dark>mdi-send</v-icon>
                        </v-btn>
                    </template>

                </v-data-table>

                <div class="caption mx-2"><strong>Quantidade selecionada: </strong>{{ selected.length }}</div>

            </v-card-text>

            

        </v-card>
        </div>

        <sys-register-template-email v-bind:onModel.sync="modelTemplate" v-bind:show.sync="showEmailTemplate"/>
        <emc-loader-progress :show="loadingShowPreview" />
        <emc-modal-form v-bind:show.sync="showPreview">
            <template v-slot:form>
                <div v-html="previewMail"></div>
            </template>
        </emc-modal-form>

        <emc-alert-modal icon="mdi-send" title="Enviar email" :text="textSending" :disabled="saving" v-bind:show.sync="showSend">
            <template v-slot:actions>
                <v-btn color="primary" @click.prevent.stop="send()" :loading="saving" :disabled="saving">Enviar<v-icon right dark>mdi-send</v-icon></v-btn>
            </template>
        </emc-alert-modal>

        <emc-alert-modal title="Liberação de disponibilidade" text="Confirma a liberação da disponibilidade até o próximo dia?" :disabled="saving" v-bind:show.sync="showRelease">
            <template v-slot:actions>
                <v-btn color="success" @click.prevent.stop="releaseAvailability()" :loading="saving" :disabled="saving">Liberar<v-icon right dark>mdi-lock-open-variant-outline</v-icon></v-btn>
            </template>
        </emc-alert-modal>

        <emc-alert-snackbar :show="!! message.length"  :message="message" color="success"/>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import state from '../../../modules/auth/store/state'

export default {
    name: 'SysMailAvailability',
    data: () => ({
        dialog: false,
        saving: false,
        selected: [],
        filtered: [],
        teachers: [],
        search: '',
        itemsCampuses: [],
        showEmailTemplate: false,
        modelTemplate: [],
        showPreview: false,
        firstCharge: false,
        onlyPending: false,
        loadingShowPreview: false,
        showSend: false,
        campuses: [],
        itemsSending: [],
        textSending: '',
        showRelease: false,
        itemSelected: null,
        message: '',
        previewMail: '',
        loading: false,
        loadingSearch: false,
        loadingPending: false,
        workTime: null,
        headers: [
            { text: 'Nome', value: 'name'},
            { text: 'Email', value: 'email'},
            { text: 'Campus', value: 'campuses'},
            { text: 'Status', value: 'status'},
            { text: 'Ações', value: 'actions', sortable: false, align: 'center'}
        ],
    }),
    created (){
        this.workTime = state.acesso.periodo;
    },
    props: {
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    watch: { 
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            
            this.dialog = show_new;

            if(show_new){
                this.firstCharge = true;
                this.search = '';
                this.itemSelected = null;
                // this.getData();
            }

        },
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeachers', 'ActionReleaseAvailability']),
        ...mapActions('mail', ['ActionSendEmailAvailability', 'ActionPreviewMailAvailability']),
        
        getData(search = false){
            
            this.loading = true;
            this.loadingPending = !search;
            this.loadingSearch = search;
            this.selected = [];
            this.teachers = [];

            let payload = {
                column_order: 'name',
                direction: 'asc',
                with: 'campuses,availability.status,emails',
                find_columns: {
                    active: 1
                }
            };

            if(this.campuses.length < this.itemsCampuses.length){

                let ids = this.campuses.map(c => c['id']).join(',');
                
                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }

                payload.filter_relations.push({
                    name:   'campuses',
                    key:    'campus_id', 
                    value:  ids,
                    clause: 'whereIn'
                });

            }

            if(this.onlyPending){
                
                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push(
                    {
                        name: 'availability',
                        key: 'status_availability_id', 
                        value: 1,
                        includeNull: true
                    } 
                );
            }
            
            this.ActionFindTeachers(payload)
                .then((res) => {
                    
                    this.selected = res.data;
                    this.teachers = res.data;
                    
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingSearch = false;
                    this.loadingPending = false;
                    this.firstCharge = false;
                })
        },

        getFiltered(filtered){
            this.filtered = filtered
            
            if(this.search.length > 0) {
                this.selected = filtered
            }
            
        },
        showEmail(item) {
            this.loadingShowPreview = true
            this.ActionPreviewMailAvailability({ teacher_id: item.id, work_time_id: this.acesso.periodo.id})
                .then((res) => {
                    this.previewMail = res.data;
                })
                .finally(() => {
                    this.showPreview = true
                    this.loadingShowPreview = false
                })
        },

        showSending(item = null){
            
            if(item){
                this.itemsSending = [item];
                this.textSending = 'Confirma o envio do e-mail para ' + item.name + '?';
            }else{
                this.itemsSending = this.selected;
                if(this.selected.length == 1){
                    this.textSending = 'Confirma o envio do e-mail para ' + this.selected[0].name + '?';
                }else{
                    this.textSending = 'Confirma o envio do e-mail para os ' + this.selected.length + ' professores selecionados?';
                }
                
            }

            this.showSend = true;
            
        },

        send() {
            
            if(!this.itemsSending || this.itemsSending.length == 0){
                return;
            }

            this.saving = true;
            this.message = '';

            var items = this.itemsSending.map(o => ({ id: o['id'] }));
            var payload = {
                teachers: items
            };

            this.ActionSendEmailAvailability(payload)
                .then((res) => {
                    this.message = res.message
                })
                .finally(() => {
                    this.saving = false;
                    this.itemsSending = [];
                    this.showSend = false;
                })
        },

        showReleaseAvailability(item){
            this.itemSelected = item;
            this.showRelease = true;
        },

        releaseAvailability(){
                
            this.saving = true;
            
            var payload = {
                id: this.itemSelected.id
            };
            
            this.ActionReleaseAvailability(payload)
                .then((res) => {
                    this.itemSelected.release_availability_until = res.data.release_availability_until.substring(0, 10);
                    this.itemSelected.is_available_availability = res.data.is_available_availability;
                })
                .finally(() => {
                    this.saving = false;
                    this.showRelease = false;
                })
        }

    },
}
</script>