<template>
    <v-snackbar 
        v-model="hidden" 
        :color="color" 
        top
        :timeout="timeout"
    >
        
        <span v-if="message && message != ''">{{ message }}</span>
        <ul v-else>
            <li :key="i" v-for="(error, i) in errors">
                {{ error.toString().replace('[ "', '').replace('" ]', '') }}
            </li>
        </ul>
        
        <template v-slot:action="{ attrs }">
        <slot name='action' />
        <v-btn 
            class="float-right ma-1" 
            icon 
            @click="hidden = false" 
            v-bind="attrs"
            small
            >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </template>
      </v-snackbar>
</template>

<script>
export default {
    name: "EmcAlertSnackbar",
    data: () => ({
        hidden: false
    }),
    props: {
        show: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'success'
        },
        message: {
            type: String,
            default: null
        },
        timeout: {
            default: 5000
        },
        errors: {
            default: null
        },
    },

    watch: {
        hidden(){
             this.$emit('update:show', this.hidden);
        },
        show(){
            this.hidden = this.show;
        }
    },
}
</script>