import { service } from '../../../../helpers/http'

export const ActionIndexEventAreas = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'indexEventAreas', 
		payload: payload 
	})
}

export const ActionCreateEventArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'createEventArea', 
		payload: payload 
	})
}

export const ActionUpdateEventArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'updateEventArea', 
		payload: payload 
	})
}

export const ActionDestroyEventArea = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'destroyEventArea', 
		payload: payload 
	})
}


