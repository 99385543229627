<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="30%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker dense @onSelect="selectHour" :error="!!errors.start" :errors="errors.start" :time.sync="model.start" label="Início*" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker dense :error="!!errors.end" :errors="errors.end" :time.sync="model.end" label="Fim*" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno*"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker dense :error="!!errors.limit" :errors="errors.limit" :time.sync="model.elimitnd" label="Limite*" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-text-field dense :error-messages="errors.total_times" v-model="model.total_times" label="Total Aulas" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-text-field dense code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterBaseHour",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                
                if(!this.model.id){
                    this.model.active = true;
                }
                    
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Cadastro"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('hour', ['ActionUpdateBaseHour', 'ActionCreateBaseHour']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateBaseHour(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateBaseHour(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        selectHour(val){
            
            const [hour, minute] = val.split(':');
            var hourInt = parseInt(hour);
            
            if(hourInt >= 18){
                this.model.shift_id = 3;
            }else if(hourInt >= 12){
                this.model.shift_id = 2;
            }else{
                this.model.shift_id = 1;
            }
        }

    }
}
</script>