import { service } from '../../../../helpers/http'

export const ActionIndexEventPublics = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'indexEventPublics', 
		payload: payload 
	})
}

export const ActionCreateEventPublic = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'createEventPublic', 
		payload: payload 
	})
}

export const ActionUpdateEventPublic = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'updateEventPublic', 
		payload: payload 
	})
}

export const ActionDestroyEventPublic = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'calendar', 
		action: 'destroyEventPublic', 
		payload: payload 
	})
}


