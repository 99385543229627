<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'" persistent >
        <v-card 
                outlined
                height="75vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Filtros
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-term
                                :model.sync="terms"
                                label="Termos"
                                multiple
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-term-signature-status
                                :model.sync="status"
                                label="Status"
                                multiple
                                selectAll
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-term-option
                                :model.sync="options"
                                label="Opções"
                                multiple
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                    :disabled="disableButton"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "SysTermFilter",
    data: (vm) => ({
        dialog: false,
        loading: false,
        terms: [],
        status: [],
        options: [],
    }),
    mounted() {
        
    },
    computed: {

        disableButton (){
            
            return !this.terms.length && !this.status.length && !this.options.length;

        },

    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.updating = false;
            }
        },

    },
    methods: {

       filter(){

            var payload = {};
        
            if(this.terms && this.terms.length > 0){
                
                let ids = this.terms.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'term',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.status && this.status.length > 0){
                
                let ids = this.status.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'status',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            if(this.options && this.options.length > 0){
                
                let ids = this.options.map(o => o['id']).join(',');

                if(!payload.filter_relations){
                    payload.filter_relations = [];
                }
                
                payload.filter_relations.push({
                        name:   'option',
                        key:    'id', 
                        value:  ids,
                        clause: 'whereIn'
                    }
                );
            }

            this.$emit('filter', payload);

       },

       clearFilter(){

            this.terms      = [];
            this.status     = [];
            this.options    = [];
            
            this.$emit('filter', null);

       },

    }
}
</script>