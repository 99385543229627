<template>
    <v-dialog v-model="dialog" scrollable :max-height="maxHeight" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
         <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <slot name="tools"></slot>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>

                <v-progress-linear
                        class="ml-2 mr-2"
                        v-if="loading"
                        indeterminate
                        color="yellow darken-2"
                    />

                <v-card-text class="pt-2">
                    <v-list max-height="300" class="overflow-y-auto"
                        ref="messages"
                    >
                        <v-list-item 
                            v-for="(item, index) in items" :key="index" 
                            :class="(user && item.user.id == user.id) ? 'text-right' : ''"
                            :id="'list-item-' + index"
                        >
                            
                            <v-list-item-avatar v-if="user && item.user.id != user.id">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-avatar
                                            v-bind="attrs" v-on="on"
                                            :color="getColor(item)"
                                            size="28"
                                            class="white--text"
                                        >
                                            {{ getInitials(item) }}
                                        </v-avatar>
                                        </template>
                                        <span> 
                                            {{ item.user.name }}
                                            <br/>
                                            <span v-if="item.created_at == item.updated_at">
                                                {{ item.created_at | dateTime }}
                                            </span>
                                            <span v-else>
                                                Enviado: {{ item.created_at | dateTime }}
                                                <br/>
                                                Editado: {{ item.updated_at | dateTime }}
                                            </span>
                                             <span v-if="item.auto">
                                                <br/>
                                                :. Mensagem Automática .:
                                            </span>
                                        </span>
                                </v-tooltip>
                            </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title class="text-wrap">

                                <v-tooltip v-if="!showEditText && user && item.user.id == user.id" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            <strong v-if="index == items.length - 1"> 
                                                {{ item.message }}
                                                <br/>
                                                <small>{{ item.updated_at | dateTime }}</small>
                                            </strong>
                                            <span v-else>{{ item.message }}</span>
                                        </span>
                                    </template>

                                    <span v-if="item.created_at == item.updated_at">
                                        {{ item.created_at | dateTime }}
                                    </span>
                                    <span v-else>
                                        Enviado: {{ item.created_at | dateTime }}
                                        <br/>
                                        Editado: {{ item.updated_at | dateTime }}
                                    </span>
                                    <span v-if="item.auto">
                                        <br/>
                                        :. Mensagem Automática .:
                                    </span>

                                </v-tooltip>
                                <span v-else>
                                    <span v-if="showEditText && index == selectedIndex">
                                        <v-text-field
                                            v-model="editMessage"
                                            autofocus
                                            counter
                                            :loading="loadingUpdate"
                                            :maxlength="count"
                                            v-on:keyup.esc.prevent="showEditText = false"
                                            v-on:keyup.enter="updateMessage(item)"
                                            @blur="showEditText = false"
                                        >
                                            <template v-slot:append>
                                                <v-icon
                                                    v-if="editMessage && (item.message !== editMessage)"
                                                    color="success"
                                                    right
                                                    small
                                                    @click.stop.prevent="updateMessage(item)"
                                                >
                                                    mdi-content-save
                                                </v-icon>
                                                <v-icon
                                                    v-if="editMessage"
                                                    color="indigo"
                                                    right
                                                    small
                                                    @click.stop.prevent="editMessage = null"
                                                >
                                                    mdi-close
                                                </v-icon>
                                            </template>
                                        </v-text-field>

                                    </span>
                                    <span v-else>
                                        <strong v-if="index == items.length - 1"> 
                                            {{ item.message }}
                                            <small><br/>{{ item.updated_at | dateTime }}</small>
                                        </strong>
                                        <v-tooltip v-else bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.message }} </span>
                                            </template>
                                            <span> {{ item.updated_at | dateTime }} </span>
                                        </v-tooltip>
                                    </span>
                                </span>
                                   
                            </v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-action v-if="!item.auto">
                            <span v-if="index == items.length - 1">
                                <v-icon
                                    v-if="allowEdit && user && item.user.id == user.id"
                                    :disabled="index == selectedIndex && showEditText"
                                    color="indigo darken-4"
                                    right
                                    small
                                    @click.stop.prevent="editText(item, index)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-btn icon>
                                    <v-icon
                                        v-if="allowDelete && user && item.user.id == user.id"
                                        color="orange darken-4"
                                        right
                                        small
                                        @click.stop.prevent="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                                
                            </span>
                        </v-list-item-action>
                        </v-list-item>
                </v-list>
                   
                </v-card-text>

                <v-card-actions>
                    <v-text-field
                        v-model="modelMessage"
                        label="Anotação"
                        placeholder="Digite aqui sua anotação..."
                        filled
                        rounded
                        dense
                        counter
                        :maxlength="count"
                        autofocus
                        v-on:keyup.enter="sendMessage()"
                    >
                        <template v-slot:append>

                            <slot name='append'/>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"    
                                        :loading="loadingSend"
                                        icon
                                        :disabled="!modelMessage || modelMessage == ''"
                                    >
                                        <v-icon
                                            right
                                            color="orange"
                                            @click="sendMessage()"
                                        >
                                            mdi-send
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Enviar
                                </span>
                            </v-tooltip>

                        </template>
                    </v-text-field>

                    <slot name='actions'/>
                    
                </v-card-actions>
                
                

            </v-card>

            <emc-alert-modal 
              title="Excluir" text="Deseja excluir a anotação?" 
              :show.sync="showDelete"
              :disabled="loading"
            >
              <template v-slot:actions>
                  <v-btn :disabled="loading" :loading="loadingDelete" color="error" @click.prevent.stop="deleteMessage()">Excluir<v-icon right dark>mdi-delete</v-icon></v-btn>
              </template>
            </emc-alert-modal>

    </v-dialog>
</template>

<script>

import state from '../../../modules/auth/store/state'

export default {
    name: "EmcMessageBase",
    data: (vm) => ({
        dialog: false,
        modelMessage: null,
        user: state ? state.user : null,
        showDelete: false,
        itemDelete: null,
        lastIndex: -1,
        showEditText: false,
        selectedIndex: -1,
        users: [],
        height: 500,
        count: 255,
        editMessage: null,
        colors: ['error', 'success', 'indigo', 'primary', 'purple', 'pink', 'cyan', 'blue-grey', 'blue', 'teal', 'amber']
    }),
     props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "60%"
        },
        maxHeight:{
            type: String,
            default: "auto"
        },
        icon: {
            type: String,
            default: "mdi-chat-processing-outline"
        },
        title: {
            type: String,
            default: "Chat"
        },
        show: {
            type: Boolean,
            default: false
        },
        items: {

        },
        loading : {
            type: Boolean,
            default: false
        },
        loadingSend : {
            type: Boolean,
            default: false
        },
        loadingUpdate : {
            type: Boolean,
            default: false
        },
        loadingDelete: {
            type: Boolean,
            default: false
        },
        sent: {
            type: Boolean,
            default: false
        },
        allowDelete: {
            type: Boolean,
            default: true
        },
        allowEdit: {
            type: Boolean,
            default: true
        },
    },

    mounted (){
        
    },

    watch: {
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.users = [];
                this.showEditText = false;
                this.editMessage = null;
                this.modelMessage = null;
                this.loader();
            }

        },

        sent(val){
            if(val){
                this.modelMessage = null;
                this.showDelete = false;
                this.showEditText = false;
                this.editMessage = null;
            }
        },

        items(val){

            this.lastIndex = -1;
            
            if(val && val.length > 0){

                for (let i = 0; i < val.length; i++) {
                   
                   const item = val[i];
                   if(this.user && item.user.id != this.user.id){
                       this.lastIndex = i;
                       return;
                   }
                }
            }

        },
    },

    methods: {
        
        sendMessage(){
            if(this.modelMessage){
                this.$emit('onSend', this.modelMessage);
            }
        },

        deleteMessage(){
                this.$emit('onDelete', this.itemDelete);
        },

        setScroll(){
            
            let index = this.items.length - 1;
            let element = document.getElementById("list-item-" + index);
            
            if(element){
                element.scrollIntoView();
            }
            
        },

        getInitials(item){
            
            var parts = item.user.name.split(' ');
            
            if(parts.length > 1){
                return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
            }else{
                return parts[0][0].toUpperCase();
            }

        },

        getColor(item){
            
            var id = item.user.id;
            
            if(!this.users.includes(id)){
                this.users.push(id);
            }

            var index = this.users.indexOf(id);

            if(index < this.colors.length){
                return this.colors[index];
            }else{
                return this.colors[0];
            }
            
        },

        loader(){
        
            setTimeout(() => {  
                
                if(!this.dialog || (this.$route.name != 'views' && this.$route.name != 'schedule' && this.$route.name != 'offer_grids')){
                    return;
                }

                this.$emit('getData');
                this.loader();
                
                
            }, 10000);
        },

        deleteItem(item){

            this.itemDelete = item;
            this.showDelete = true;

        },

        editText(item, index){
            
            if(!this.showEditText){
                this.selectedIndex = index;
                this.editMessage = item.message;
                this.showEditText = true;
            }
        },

        updateMessage(item){
            
            if(!this.editMessage || this.editMessage == ''){
                return;
            }

            if(this.editMessage === item.message){
                this.showEditText = false;
                return;
            }

            item.message = this.editMessage;
            this.$emit('onUpdate', item);
        },
        
    },
   
}
</script>