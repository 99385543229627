import { service } from '../../../../helpers/http'


export const ActionIndexJoinCategories = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'join', 
		action: 'indexJoinCategories', 
		payload: payload 
	})
}

export const ActionCreateJoinCategory = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'join', 
		action: 'createJoinCategory', 
		payload: payload 
	})
}

export const ActionUpdateJoinCategory = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'join', 
		action: 'updateJoinCategory', 
		payload: payload 
	})
}

export const ActionDestroyJoinCategory = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'join', 
		action: 'destroyJoinCategory', 
		payload: payload 
	})
}