<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-check-all" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    
                    <v-row>
                        <v-col cols="12" sm="12">
                    
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model.teacher"
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                autofocus
                                :disabled="disableFields || teacher != null"
                                
                            />

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-work-load-confirm-status
                                :model.sync="model.work_load_confirmation_status_id"
                                :error="errors.length > 0"  
                                :errors="errors.work_load_confirmation_status_id"
                                label="Status*"
                                :disabled="disableFields"
                            /> 
                        </v-col>

                    </v-row>

                    <v-row>
                        
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note_teacher" 
                                :error-messages="errors.note_teacher" 
                                v-model="model.note_teacher" 
                                label="Observação do Professor"
                                :disabled="disableFields"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                 />
                        </v-col>
                    
                    </v-row>

                    <v-row>
                        
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note_user" 
                                :error-messages="errors.note_user" 
                                v-model="model.note_user" 
                                label="Observação do Usuário"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                 />
                        </v-col>
                    
                    </v-row>

            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherWorkLoadConfirmRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.work_load_confirmation_status_id = 1;
                }

                if(this.teacher){
                    this.model.teacher_id = this.teacher.id;
                    this.model.teacher = this.teacher;
                }

            }

            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-check-all"
        },
        title: {
            type: String,
            default: "Confirmação de Carga Horária"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        },
        disableFields: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionUpdateWorkLoadConfirmation', 'ActionCreateWorkLoadConfirmation']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {
                work_time_id:       this.acesso.periodo.id,
                institution_id:     this.acesso.instituicao.id
            });

            if(edit) {
                this.ActionUpdateWorkLoadConfirmation(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateWorkLoadConfirmation(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>