import { service } from '../../../helpers/http'
export * from '../unsanitary_type/store/actions'

export const ActionFindDisciplines = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'disciplines',
        payload: payload
    })
}

export const ActionFindDisciplinesToken = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'disciplinesToken',
        params: {token: payload.token},
        payload: payload
    })
}

export const ActionCreateDiscipline = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'disciplineCreate',
        payload: payload
    })
}

export const ActionUpdateDiscipline = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'disciplineUpdate',
        payload: payload
    })
}

export const ActionDeleteDiscipline = ({ dispatch }, payload) => {
    return service({
        module: 'discipline',
        action: 'disciplineDelete',
        payload: payload
    })
}