import { service } from '../../../../helpers/http'

export const ActionIndexDepartaments = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'indexDepartaments',
        payload: payload
    });
}

export const ActionCreateDepartament = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'createDepartament',
        payload: payload
    });
}

export const ActionUpdateDepartament = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'updateDepartament',
        payload: payload
    });
}

export const ActionDeleteDepartament = ({ dispatch }, payload) => {
    
    return service({
        module: 'institution',
        action: 'deleteDepartament',
        payload: payload
    });
}
