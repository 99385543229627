export default {
    find:                       { method: 'get', url: 'class_schedules' },
    classesCreate:              { method: 'post', url: 'class_schedules'},
    clearClasses:               { method: 'post', url: 'class_schedules/clear'},
    classesUpdate:              { method: 'put', url: 'class_schedules/{id}'},
    classesDelete:              { method: 'delete', url: 'class_schedules/{id}'},
    classesUpdateAmbience:      { method: 'put', url: 'class_schedules/{id}/ambience'},
    classesUpdateAmbiencesAll:  { method: 'put', url: 'class_schedules/ambiences/all'},
    classesUpdateAll:           { method: 'put', url: 'class_schedules/all'},
    indexClassesOptimized:      { method: 'get', url: 'class_schedules/{type}/{item_id}/{work_time_id}/optimized'},
    classesReplicate:           { method: 'post', url: 'class_schedules/replicate'},
}

