<template>
    <v-dialog 
        v-model="dialog" 
        persistent
        :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'"
    >
            <v-card>
                <v-toolbar height="40">
                    <v-toolbar-title>{{grid.discipline_name}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="dialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text class="pt-3">

                    <v-list>
                        <v-list-item-group>
                            <template v-for="(item, index) in grid.classes">
                            <v-list-item :key="item.hour.id">
                                <template v-slot:default>
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <SysSelectHour
                                                :model.sync="item.hour.id"
                                                :error="false"  
                                                :errors="[]"
                                                label="Horário*"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-spacer></v-spacer>
                                            <EmcCalendarDatePicker
                                                :model.sync="dateSelected"
                                                :label="dateSelected"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                                </template>
                            </v-list-item>

                            <v-divider
                                v-if="index < grid.classes.length - 1"
                                :key="index"
                            ></v-divider>
                            </template>
                            
                        </v-list-item-group>
                        </v-list>
                    <!--
                    <v-row justify="center">
                        <v-date-picker
                        v-model="dateSelected"
                        class="mt-4"
                        full-width
                        no-title
                        :min="grid.work_time.classes_start"
                        :max="grid.work_time.classes_end"
                        ></v-date-picker>
                    </v-row>
                    -->
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SysScheduleDate',
    data: () => ({
        dialog: false,
        dateSelected: '',
        error: false,
        errors: {},
    }),
    props: {
        show: {
            type: Boolean,
            required: true
        },
        grid: {
            type: Object,
            required: true
        },
        date: {
            type: String,
            required: true
        }
    },
    watch: {  
    
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },
        show(show) {
            this.dialog = show;
        },
        date(date) {
            this.dateSelected = date
        },
        dateSelected(dateSelected_new, dateSelected_old) {
            
            if(dateSelected_new !== dateSelected_old) {
                this.$emit('update:date', dateSelected_new)
            }

        }

    }
}
</script>