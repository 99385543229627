<template>
    <div>
        <v-simple-table
            fixed-header
            height="300"
        >
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-center">
                        <emc-info-help title="Disponibilidade" text="Informe os dias e horários indisponíveis para aula" />
                    </th>
                    <th class="text-center" v-for="(header, i) in headersFiltered" :key="i">
                        {{ header.name }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(hour, i) in hours" :key="i">
                    <td class="text-caption text-center">{{ hour.start }} <br/> {{ hour.end }}</td>
                    <td 
                        v-for="(day, i) in headersFiltered" 
                        :key="i" class="text-center"
                    >
                        <v-icon 
                            v-if="renderComponent"
                            :color="colors[hour[day.initials]]" 
                            @click="select(hour, day)"
                        >
                            {{ icons[hour[day.initials]] }}
                        </v-icon>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="showFoot">
                <tr>
                    <td :colspan="headersFiltered.length + 1">
                        
                    </td>
                </tr>
            </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<script>

import { mapActions } from 'vuex'
export default {
    name: "SysColorBase",
    data: () => ({
       hours: [],
       loading: false,
       renderComponent: true,
       colors: ['error', 'success'],
       icons: ['mdi-close-circle', 'mdi-checkbox-marked-circle'],
       daysList: { 1: 'Dom', 2: 'Seg', 3: 'Ter', 4: 'Qua', 5: 'Qui', 6: 'Sex', 7: 'Sab' },
       headers: [
        { id: 1, name: 'Domingo',   initials: 'Dom',    visible: false },
        { id: 2, name: 'Segunda',   initials: 'Seg',    visible: true },
        { id: 3, name: 'Terça',     initials: 'Ter',    visible: true },
        { id: 4, name: 'Quarta',    initials: 'Qua',    visible: true },
        { id: 5, name: 'Quinta',    initials: 'Qui',    visible: true },
        { id: 6, name: 'Sexta',     initials: 'Sex',    visible: true },
        { id: 7, name: 'Sábado',    initials: 'Sab',    visible: true },
       ]
    }),

    props: {
       model: {},
       showFoot: {
            type: Boolean,
            default: false
       }
    },

    created(){
      this.getData();
    },

    computed: {
       headersFiltered(){
        return this.headers.filter(h => h.visible);
       }
    },

    watch: {

        model(val){
            this.loadAvas();
        },

    },

    methods:{
        ...mapActions('hour', ['ActionGetHoursGroups']),

        getData(){
            
            var payload = {
                with: 'shift',
                per_page: 1000
            };
          
            this.ActionGetHoursGroups(payload)
                .then((res) => {
                    this.hours = res.data;
                    this.loadHours();
                    this.loadAvas();
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadAvas(){

            this.loadHours();
            
            if(!this.model || !this.model.availabilities){
                this.forceRerender();
                return;
            }
            
            this.model.availabilities.forEach(ava => {
                
                for (let i = 0; i < this.hours.length; i++) {
                    var hour = this.hours[i];
                    hour.items.forEach(item => {
                        if(ava.hour_id == item.id){
                            hour[this.daysList[ava.day_of_week_id]] = ava.availability_type_id;
                        }
                    });
                    
                }
            });

            this.forceRerender();

        },

        loadHours(){
            this.hours.forEach((h) => {
                h['Dom'] = 1;
                h['Seg'] = 1;
                h['Ter'] = 1;
                h['Qua'] = 1;
                h['Qui'] = 1;
                h['Sex'] = 1;
                h['Sab'] = 1;
            });
            this.forceRerender();
      },

      select(hour, day){
        
        var value = hour[day.initials] == 1 ? 0 : 1;

        hour.items.forEach(item => {
            
            var find = this.model.availabilities.find(ava => (ava.hour_id == item.id && ava.day_of_week_id == day.id));
            
            if(find){
                if(value){
                    this.model.availabilities.splice(this.model.availabilities.indexOf(find), 1);
                }else{
                    find.availability_type_id = value;
                }
            }else if(!value){
                this.model.availabilities.push({
                    hour_id:                item.id,
                    day_of_week_id:         day.id,
                    availability_type_id:   value
                });
            }
        });

        hour[day.initials] = value;
        this.model.updateAvailability = true;
        this.forceRerender();

      },

      forceRerender () {
      
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },
    }
    
}
</script>