<template>
    <v-dialog v-model="dialog" scrollable :max-height="maxHeight" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth">
        <v-card
            class="mt-4 mx-auto"
        >

            <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense :height="height" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                Extrato Financeiro
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small class="mr-1" @click.native="dialog = false" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
            <v-card-text>

            <v-tabs v-model="tab">
                <v-tab v-for="m in monthsFiltered" :key="m.id">{{ m.name }}</v-tab>
            </v-tabs>

            <v-tabs-items  v-model="tab">
                <v-tab-item v-for="m in monthsFiltered" :key="m.id">
                    <v-expansion-panels class="mt-2" v-model="panel" focusable >
                            <v-expansion-panel v-for="t in paymentTypes" :key="t.id">
                                <v-expansion-panel-header>{{ t.name }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <SysTeacherPaymentExportItemExtract :month="m.id" :payment-type-id="t.id" :workTime="acesso.periodo" :teacher="model" />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                </v-tab-item>
            </v-tabs-items>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>

import { mapState, mapActions } from 'vuex'

export default {
   name: 'FinancialExtract',
   data: () => ({
     dialog: false,
     tab: null,
     panel: null,
     months: [
        { id: 1,    name: 'Janeiro'     },
        { id: 2,    name: 'Fevereiro'   },
        { id: 3,    name: 'Março'       },
        { id: 4,    name: 'Abril'       },
        { id: 5,    name: 'Maio'        },
        { id: 6,    name: 'Junho'       },
        { id: 7,    name: 'Julho'       },
        { id: 8,    name: 'Agosto'      },
        { id: 9,    name: 'Setembro'    },
        { id: 10,   name: 'Outubro'     },
        { id: 11,   name: 'Novembro'    },
        { id: 12,   name: 'Dezembro'    },
     ],
     paymentTypes: [],
    }),

    props: {
        show: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: String,
            default: "90%"
        },
        maxHeight:{
            type: String,
            default: "auto"
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        height: {
            type: String,
            default: "45"
        },
        icon: {
            type: String,
            default: ""
        },
        model: {

        }
    },

    watch: {
      
        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            this.dialog = val;
        },


     
    },
    components: {
      
    },

    mounted(){
       
    },
    created() {
        this.loadTypes();
    },
    computed: {
      ...mapState('auth', ['acesso']),

      monthsFiltered(){

        let range = this.getRangeMonth();
        return this.months.filter(i => (i.id >= range.start && i.id <= range.end ));

      },
    },
    methods: {
        ...mapActions('paymentExport', ['ActionFindPaymentExportType']),

        loadTypes() {

        let payload = {
            find_columns: {
                active: 1
            }
        }

        this.ActionFindPaymentExportType(payload)
            .then((res) => {
                this.paymentTypes = res.data.filter(i => i.active);
            });
        },

  },

}
</script>
