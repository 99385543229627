<template>
    <v-dialog v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
            <v-card>
                <v-app-bar color="grey lighten-4" dense>
                <v-toolbar-title>
                    <v-icon left>{{ icon }}</v-icon>&nbsp;&nbsp; {{ title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="tools"></slot>
                <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" :disabled="saving" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                </v-tooltip>
            </v-app-bar>
                <v-card-text>
                    <v-container>
                        <slot name="form"></slot>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <slot name="actions"></slot>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalForm",
    data: () => ({
        dialog: false
    }),
    watch: {
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },
        show(show_new, show_old) {
            if(show_new !== show_old) {
                this.dialog = show_new
            }

        }
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-account-clock-outline"
        },
        title: {
            type: String,
            default: 'Visualização de E-mail'
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        }
    }
}
</script>