<template>
    <div>
        <sys-find-base 
            v-bind:show.sync="dialog"
            :collection="items" 
            :headers="headers" 
            :icon="icon"
            @onSearch="searchDisciplines($event)" 
            @onSelect="select($event)"
            :loading="loading"
            title="Pesquisar Disciplina"
            :singleSelect="singleSelect"
            :maxWidth="maxWidth"
            :showSelect="showSelect"
            :selecteds.sync="selected"
            :showAdd="showAdd"
            @onAdd="dialogAddDiscipline = true"
        >
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>

        </sys-find-base>

        <sys-register-discipline @onCompleted="selectDiscipline($event)" :model="{}" v-bind:show.sync="dialogAddDiscipline" />
        
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysFindDiscipline",
    data: (vm) => ({
        search: '',
        selected: [],
        dialog: false,
        loading: false,
        items: [],
        dialogAddDiscipline: false,
        headers: [
            { text: 'Código', value: 'code'},
            { text: 'Nome', value: 'name'},
            { text: 'Carga Horária', value: 'work_load'}
        ]
    }),
    created(){
        
    },

    computed: {
      
    },

    watch: {
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.searching = false;
                this.selected = [];
                this.search = "";
                this.items = [];
            }
        },
     
    
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "600"
        },
        maxWidth: {
            type: String,
            default: "70%"
        },
        icon: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: true
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        
        ...mapActions('discipline', ['ActionFindDisciplines']),
        
        select(val){

            this.dialog = false;
            this.$emit('onSelect', val);
            
        },

        searchDisciplines(search){
            
            this.loading = true;
            
            var payload = {
                column_order: 'name',
                direction: 'asc',
                search: search,
                find_columns: {
                    active: 1
                }
            };

            this.items = [];
            
            this.ActionFindDisciplines(payload)
                .then((res) => {
                    
                    let data = res.data;
                    
                    if(this.selected && this.selected.length > 0){
                        this.selected.forEach(d => { this.items.push(d); });
                    }

                    data.forEach(d => { 
                        var find = this.items.find(i => i.id == d.id);
                        if(!find){
                            this.items.push(d); 
                        }
                    });
                    
                })
                .finally(() => {
                    this.loading  = false;
                });
            
        },

        selectDiscipline(item){
            this.items.push(item);
            this.selected.push(item);
            this.dialogAddDiscipline = false;
        }
    }
}
</script>