<template>
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="350"
      scrollable
      dense
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="colorButton"
          v-bind="attrs"
          v-on="on"
          dark
          
        >
          <v-icon :dark='true'>mdi-format-color-fill</v-icon>
        </v-btn>
      </template>

      <v-card>
        <!-- <v-card-title class="headline grey lighten-2">
          Selecionar Cor
        </v-card-title> -->

        <v-card-text>
          <v-color-picker
            v-model="colorPicker"
            class="ma-2"
            show-swatches
            ></v-color-picker>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
                color="primary"
                text
                @click="select(true)"
            >
                OK
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="black"
                text
                @click="select(false)"
            >
                Sem Cor
            </v-btn>
            <v-btn
                color="error"
                text
                @click="dialog = false"
            >
                Cancelar
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>

export default {
    name: "SysColorBase",
    data: () => ({
        showColor: 'red',
        dialog: false,
        colorButton: '',
        colorPicker: '',
        colorDefault: '' // cyan | #4DD0E1FF
    }),

    props: {
        
        color: {
            type: String,
            default: ''
        },
    },

    created(){
      
      if(this.color){
        this.colorButton = this.color;
        this.colorPicker = this.color;
      }else{
        this.colorButton = this.colorDefault;
        this.colorPicker = this.colorDefault;
      }
      
    },

    watch: {
        
        color(val){
            
            if(val){
              this.colorButton = val;
              this.colorPicker = val;
            }else{
              this.colorButton = this.colorDefault;
              this.colorPicker = this.colorDefault;
            }
           
        },

        colorButton(val) {
            this.$emit('update:color', val);
        },


    },

    methods:{
        
        select(definir){
            
            this.colorButton = definir ? this.colorPicker : null;
            
            this.dialog = false;
            //this.$emit('onSave', this.edit);
        },

    }
    
}
</script>