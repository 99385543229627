var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.autoComplete && _vm.multiple)?_c('v-combobox',{class:_vm.classe,attrs:{"items":_vm.items,"label":_vm.label,"item-value":"id","item-text":_vm.itemText,"required":"","error":_vm.error,"error-messages":_vm.errors,"dense":_vm.dense,"loading":_vm.loading,"disabled":_vm.disabled || _vm.loading,"shaped":false,"multiple":_vm.multiple,"return-object":_vm.multiple || _vm.returnObject,"autofocus":_vm.autofocus,"hide-selected":_vm.hideSelected,"clearable":_vm.showClear,"hint":_vm.hint,"persistent-hint":_vm.persistentHint,"filled":_vm.filled,"outlined":_vm.outlined,"hide-details":_vm.hideDetails,"background-color":_vm.backgroundColor,"color":_vm.color},on:{"change":_vm.change,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.selected.length - 1)+" outros) ")]):_vm._e()]}}],null,false,4229398789),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):(_vm.autoComplete)?_c('v-autocomplete',{class:_vm.classe,attrs:{"items":_vm.items,"loading":_vm.loading,"search-input":_vm.search,"hide-no-data":"","hide-selected":"","item-value":"id","item-text":_vm.itemText,"label":_vm.label,"error":_vm.error,"error-messages":_vm.errors,"dense":_vm.dense,"disabled":_vm.disabled || _vm.loading,"autofocus":_vm.autofocus,"clearable":_vm.showClear,"hint":_vm.hint,"persistent-hint":_vm.persistentHint,"filled":_vm.filled,"multiple":_vm.multiple,"return-object":_vm.multiple || _vm.returnObject,"outlined":_vm.outlined,"hide-details":_vm.hideDetails,"background-color":_vm.backgroundColor,"color":_vm.color},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":_vm.getSearch,"change":_vm.change,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.textHelper)?{key:"prepend-inner",fn:function(){return [_c('emc-info-help',{attrs:{"title":"Informações","text":_vm.textHelper}})]},proxy:true}:null,(!_vm.loading && (_vm.showAdd && _vm.items.length == 0))?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disableAdd,"small":"","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showForm($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"btn":"","color":"green darken-1"}},[_vm._v("mdi-plus")])],1)]}}],null,false,462519055)},[_c('span',[_vm._v(" Novo ")])])]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item[_vm.itemText]))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.selected.length - 1)+" outros) ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [(_vm.showAdd && _vm.items.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disableAdd,"small":"","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showForm($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"btn":"","color":"green darken-1"}},[_vm._v("mdi-plus")])],1)]}}],null,false,462519055)},[_c('span',[_vm._v(" Novo ")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-select',{class:_vm.classe,attrs:{"items":_vm.items,"label":_vm.label,"item-value":"id","item-text":_vm.itemText,"required":"","error":_vm.error,"error-messages":_vm.errors,"dense":_vm.dense,"loading":_vm.loading,"disabled":_vm.disabled || _vm.loading,"shaped":false,"multiple":_vm.multiple,"return-object":_vm.multiple || _vm.returnObject,"autofocus":_vm.autofocus,"hide-selected":_vm.hideSelected,"clearable":_vm.showClear,"hint":_vm.hint,"persistent-hint":_vm.persistentHint,"filled":_vm.filled,"outlined":_vm.outlined,"hide-details":_vm.hideDetails,"background-color":_vm.backgroundColor,"color":_vm.color},on:{"change":_vm.change,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.appendIcon)?{key:"append",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.appendIcon))])]},proxy:true}:null,(_vm.textHelper)?{key:"prepend-inner",fn:function(){return [_c('emc-info-help',{attrs:{"title":"Informações","text":_vm.textHelper}})]},proxy:true}:null,{key:"prepend-item",fn:function(){return [(_vm.showAdd)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disableAdd,"small":"","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.showForm($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"btn":"","color":"green darken-1"}},[_vm._v("mdi-plus")])],1)]}}],null,false,462519055)},[_c('span',[_vm._v(" Novo ")])]):_vm._e(),(_vm.multiple && _vm.selectAll)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"label":"Todos"},on:{"change":_vm.changeCheckbox},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}},'v-checkbox',attrs,false),on))]}}],null,false,490028711)},[_c('span',[_vm._v(" Novo ")])]):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item[_vm.itemText]))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.selected.length - 1)+" outros) ")]):_vm._e()]}},(_vm.$slots.dataItem || !!_vm.$scopedSlots.dataItem)?{key:"item",fn:function(data){return [_vm._t("dataItem",null,{"item":data.item})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }