import { service } from '../../../../helpers/http'

export const ActionIndexChargeType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'indexChargeType',
        payload: payload
    })

}
export const ActionDestroyChargeType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'destroyChargeType',
        payload: payload
    })
}
export const ActionStoreChargeType = ({ dispatch }, payload ) => {
    return service({
        module: 'plan',
        action: 'storeChargeType',
        payload: payload
    })

}
export const ActionUpdateChargeType = ({ dispatch }, payload ) => {

    return service({
        module: 'plan',
        action: 'updateChargeType',
        payload: payload
    })
}