<template>
    <v-card
        class="mx-auto"
        max-width="300"
        tile
        >
        <v-list shaped>
            <v-subheader>
                <span>{{ title }}</span>
            </v-subheader>
            <v-list-item-group 
                v-model="selected"
                color="primary">
            <emc-util-scroll maxHeight="200px" >
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="$emit('on-select', item)"
                    @dblclick="$emit('on-open', item)"
                >
                    <v-list-item-content>
                        <v-list-item-title v-if="item">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                </template>
                                <span>{{ item.name }}</span>
                            </v-tooltip>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </emc-util-scroll>
            </v-list-item-group>
        </v-list>
        </v-card>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleItem",
    data: () => ({
       selected: null,
    }),
    computed: {
       
    },
    watch: {
        selected(selected_new, selected_old) {
            if(selected_new !== selected_old) {
                this.$emit('onClick', this.items[selected_new]);
                this.$emit('update:model', this.items[selected_new]);
            }
        },
        model(model_new, model_old) {
            if(model_new !== model_old) {
                this.selected = model_new;
            }
        },
    },
    props: {
        title: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        model: {

        }
    },
    methods: {

    }
}
</script>