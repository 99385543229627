import { service } from '../../../../helpers/http'

export const ActionIndexPaymentEvents = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'indexPaymentEvents', 
		payload: payload 
	})
}

export const ActionCreatePaymentEvent = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'createPaymentEvent', 
		payload: payload 
	})
}

export const ActionUpdatePaymentEvent = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'updatePaymentEvent', 
		payload: payload 
	})
}

export const ActionDestroyPaymentEvent = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'destroyPaymentEvent', 
		payload: payload 
	})
}

export const ActionIndexPaymentEventNatures = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'indexNatures', 
		payload: payload 
	})
}