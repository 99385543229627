<template>
  <sys-register-base 
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="70%"
        :actions="false"
    >
        <template v-slot:form>
            
            <v-progress-linear
                indeterminate
                color="blue"
                class="mb-0"
                v-if="loading"
            ></v-progress-linear>
            
            <v-container>
                <v-row>
                  <v-col cols="12" md="3" v-for="(item, i) in items.filter(i => i.indication.total > 0)" :key="i">
                      <emc-chart-class-schedule-of-work-time 
                            hide-tools 
                            type="campus" 
                            hide-sub-text
                            hide-footer
                            :indicator="item.indication"
                            :subtitle="item.name" 
                            :item-id="item.id"
                            class-sub-title="font-weight-light grey--text"
                        />
                  </v-col>
              </v-row>
              </v-container>
        </template>

    </sys-register-base> 
</template>

<script>


import { mapActions, mapState } from 'vuex'
export default {
    name: "EmcChartGeneralCampusTimeTable",
    data: (vm) => ({
        dialog: false,
        loading: false,
        items: [],
        itemsTemp: [],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.getData();
            }
            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-monitor-eye"
        },
        title: {
            type: String,
            default: "Horários por Campus"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        brandId: {
            type: Number,
            required: true
        }
    },
    methods:{
      ...mapActions('campus', ['ActionFindCampuses']),
      ...mapActions('controlPanel', ['ActionFindIndicatorTimeTable']),

        getData(){

          if(this.loading){
            return;
          }
          
          this.loading = true;
          this.items = [];
          this.itemsTemp = [];

          let payload = {
            find_columns: {
                brand_id: this.brandId,
            }
          };

          this.ActionFindCampuses(payload)
              .then((res) => {
                    
                    this.itemsTemp = res.data;
                    this.setIndicator();  

              })
              .catch((error) =>{
                  
              })
              .finally(() => {
                    
              });

        },


        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        setIndicator(){
      
                var payload = {
                    work_time_id: this.acesso.periodo.id,
                    type: 'allCampuses',
                    value:  this.brandId,
                };

                this.ActionFindIndicatorTimeTable(payload)
                    .then((res) => {
                        
                        let items = res.data;

                        this.itemsTemp.forEach(cam => {
                            cam.indication = items.find(i => i.campus_id == cam.id);

                        });

                        this.items = this.itemsTemp;
                    })
                    .finally(() => {
                        this.loading = false;
                        
                    });
    
        },
        
    }
}
</script>