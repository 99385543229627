<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon"
        v-bind:show.sync="dialog"
        maxWidth="90%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <div v-if="content" v-html="content"></div>
                    </v-col>
                </v-row>
                <v-row v-if="model.id">
                    <v-col cols="12" sm="12">
                        <a id="term"></a>
                        <v-checkbox
                            v-if="model.term.is_required"
                            v-model="model.agree"
                            :label="model.term.label"
                            color="primary"
                            hide-details
                            class="ma-2"
                            :disabled="view"
                        />
                        <span v-else>
                            <v-radio-group
                                v-if="model.id"
                                v-model="model.term_option_id"
                                row
                                class="ma-2"
                                :disabled="view"
                            >
                                <v-radio
                                    v-for="(opt, i) in model.institution.term_options"
                                    :key="i"
                                    :label="opt.label"
                                    :value="opt.id"
                                    :color="opt.color"
                                />
                            </v-radio-group>
                        </span>
                    </v-col>
                </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

            <emc-alert-modal text="Apó salvar a edição do termo, não será possível editar novamente. Deseja salvar?" v-bind:show.sync="showSave">
                <template v-slot:actions>
                    <v-btn color="primary" @click.prevent.stop="save()" :loading="updating">Salvar<v-icon right dark>mdi-content-save</v-icon></v-btn>
                </template>
            </emc-alert-modal>

        </template>

        <template v-slot:actions>
            <v-btn v-if="!view" color="primary" @click.prevent.stop="showSaveTerm()" >Salvar<v-icon right dark>mdi-content-save</v-icon></v-btn>
        </template>

    </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'
export default {
    name: "SysRegisterTermSignatureForm",
    data: (vm) => ({
        dialog: false,
        updating: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        model: {},
        content: null,
        showSave: false,
    }),
    
    created(){
       this.getData();
    },

    computed: {
     
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
                this.getData();
            }
        },
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Assinatura de Termo"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        signatureId: {
            type: Number,
            required: true,
        },
        view: {
            type: Boolean,
            default: false
        },
        token: {
            type: String,
        }
    },
    methods:{
        ...mapActions('term', ['ActionShowTermSignature', 'ActionUpdateTermSignature']),

        getData(){

            if(!this.signatureId){
                return;
            }
            
            this.loading = true;
            this.model = {};
            this.content = null;
            this.message = '';

            let payload = {
                id: this.signatureId,
                token: this.token,
            }

            this.ActionShowTermSignature(payload)
                .then((res) => {
                    this.model          = res.data.signature;
                    this.content        = res.data.content;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                     this.updating = false;
                });

        },

        showSaveTerm()
        {

            if(this.model.term){
                if((this.model.term.is_required && !this.model.agree) || (!this.model.term.is_required && !this.model.term_option_id)){
                    let el = document.createElement('a');
                    el.href="#term";
                    el.click();
                    this.showSnack('Informe se está de acordo ou não com o termo!', true);
                    return;
                }
            }

            this.showSave = true;

        },
        
        save()
        {
            
            this.error = false;
            this.message = '';
            this.updating = true;

            this.model.term_signature_status_id = 2;
            this.model.token = this.token;
            this.model.content = this.content;

            this.ActionUpdateTermSignature(this.model)
                .then((res) => {
                    this.message = res.message.success ? res.message.success : res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.updating = false;
                });
        },

        showSnack(message, error){
        
            this.message = '';

            this.$nextTick(() => {
                this.error = error;
                this.message = message;
            });

        },
    }
}
</script>