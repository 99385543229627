<template>
    <v-container>
        <v-row v-if="text && text != ''">
            <v-col>
                <v-alert border="top"
                        colored-border
                        type="info"
                        elevation="2"
                >
                    {{ text }}
                </v-alert>
            </v-col>
        </v-row>
         <v-row>
             <v-col>
                <v-autocomplete
                    multiple
                    v-model="disciplines"
                    :items="items"
                    return-object
                    item-text="name_complete"
                    sort-by="name"
                    :loading="loading"
                    chips
                    label="Selecione as disciplinas"
                    deletable-chips
                    clearable
                    auto-select-first
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click:close="remove(data.item)"
                        >
                            <span>
                                {{ data.item.name_complete }}
                                <small class="ma-0 pa-0" v-if="data.item.type"><br/>{{ data.item.type.name }}</small>
                            </span>
                            
                            <span v-if="data.item.syllabus">
                                <emc-info-help title="Ementa" :text="data.item.syllabus" />
                            </span>
                            
                        </v-chip>
                    </template>

                    <template v-slot:item="data">
                        <span>
                            {{ data.item.name_complete }}
                            <small class="ma-0 pa-0" v-if="data.item.type"><br/>{{ data.item.type.name }}</small>
                        </span>
                        
                        <span v-if="data.item.syllabus">
                            <emc-info-help title="Ementa" :text="data.item.syllabus" />
                        </span>
                    </template>
                    
                </v-autocomplete>
             </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-spacer></v-spacer>
                <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                    <v-btn :disabled="items.length == 0" :loading="loadingSave" v-on="on" color="primary" dark @click="save()">
                        <v-icon dark>save</v-icon>
                            Salvar
                    </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysTeacherDiscipline",
    data: (vm) => ({
        collection: [],
        showDelete: false,
        dialogAdd: false,
        selectedItem: null,
        disciplines: [],
        items: [],
        loading: false,
        loadingSave: false,
        headers: [
            { text: 'Disciplina', value: 'name'},
            { text: 'Ações', value: 'actions', sortable: false}
        ]
        
    }),
    created(){
       this.getDisciplines();
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        teacher(val){
            this.getData();
        },
            
    },
    props: {
        teacher: {

        },
        token:{
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        }
    },
    methods:{
        ...mapActions('teacher', ['ActionFindTeacher', 'ActionFindTeacherByToken', 'ActionUpdateDisciplines', 'ActionUpdateDisciplinesToken']),
        ...mapActions('discipline', ['ActionFindDisciplines', 'ActionFindDisciplinesToken']),
        
        getDisciplines(){

             this.loading = true;
            
            if(this.token){
                
                var payload = {
                    token: this.token,
                };
                
                this.ActionFindDisciplinesToken(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.getData();
                    })
                    .catch((error) =>{
                        
                    })
                    .finally(() => {
                        
                    });
            }else{
                
                var payload = {
                    find_columns: {
                        active: 1,
                        show_availability: 1
                    },
                    with: 'type'
                }
               
                this.ActionFindDisciplines(payload)
                    .then((res) => {
                        this.items = res.data;
                        this.getData();
                    })
                    .catch((error) =>{
                        
                    })
                    .finally(() => {
                       
                    });
            }

        },
        
        getData(){
            
            if(!this.teacher || !this.teacher.id || this.teacher.id == 0){
                return;
            }

            this.loading = true;
            var payload = {
                token: this.token,
                with: 'disciplines'
            };

            if(this.token){
                this.ActionFindTeacherByToken(payload)
                    .then((res) => {
                        this.disciplines = res.data.disciplines;
                    })
                    .catch((error) =>{
                        
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }else{
                
                var payload = {
                    id: this.teacher.id ,
                    with: 'disciplines'
                };
                
                this.ActionFindTeacher(payload)
                    .then((res) => {
                        this.disciplines = res.data.disciplines;
                    })
                    .catch((error) =>{
                        
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        
        save(){

            if(this.loading){
                return;
            }
            
            if(!this.teacher || !this.teacher.id || this.teacher.id == 0){
                return;
            }

            this.loadingSave = true;
            var method = this.token ? 'ActionUpdateDisciplinesToken' : 'ActionUpdateDisciplines';
            var payload = {
                    disciplines: this.disciplines.map(o => ({ discipline_id: o.id }))
                };

            if(this.token){
                payload.token = this.token;
            }else{
                payload.id = this.teacher.id;
            }

            this[method](payload)
                .then((res) => {
                    this.$emit('onComplete', { text:'Disciplinas atualizadas com sucesso!', error: false  });
                })
                .catch((error) =>{
                    this.$emit('onComplete', { text:'Erro ao atualizar as disciplinas!', error: false  });
                })
                .finally(() => {
                    this.loadingSave = false;
                });
        },

        remove (item) {
            const find = this.disciplines.find(i => i.id == item.id);
            const index = this.disciplines.indexOf(find);
            if (index >= 0) this.disciplines.splice(index, 1);
      },

    }
}
</script>