export default [
    {
        path: '/menus',
        name: 'menus',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Lista de Menus',
            icon: 'mdi-file-tree',
            breadcrumbs: [
                { text: 'Lista de menus', disabled: true }
            ]

        }
    }
]