import { service } from '../../../../helpers/http'

export const ActionIndexDynamicAttributionResults = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'indexDynamicAttributionResults',
        payload: payload
    })
    
}

export const ActionCreateDynamicAttributionResult = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'createDynamicAttributionResult',
        payload: payload
    })
    
}

export const ActionUpdateDynamicAttributionResult = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'updateDynamicAttributionResult',
        payload: payload
    })
}

export const ActionDeleteDynamicAttributionResult = ({ dispatch }, payload) => {
    
    return service({
        module: 'dynamicGeneration',
        action: 'deleteDynamicAttributionResult',
        payload: payload
    })
    
}

export const ActionShowDynamicAttributionResults = ({ dispatch }, payload) => {

    return service({
        module: 'dynamicGeneration',
        action: 'showDynamicAttributionResults',
        payload: payload
    })
    
}