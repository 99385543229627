<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="innerLoading || loading"
            :label="label"
            :errors="errors"
            :showClear="showClear"
            :itemText="itemText"
            :disabled="disabled || innerLoading || loading"
            @change="$emit('change', $event)"
            @input="$emit('input', $event)"
            return-object
            :outlined="outlined"
            :multiple="multiple"
            :hideDetails="hideDetails"
            :class="classe"
            :classe="classe"
        />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectOfferItemStatusFilter',
    data: () => ({
        items: [],
        innerLoading: false,
        selected: null
    }),
    computed: {
        ...mapState('auth', ['acesso'])
    },
    props: {
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        itemText: {
            type: String,
            default: 'name'
        },
        showClear: {
            type: Boolean,
            default: false
        },
        model: {
        
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        filterActive: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: [Boolean,String],
            default: false
        },
        classe: {

        },
        includeNull: {
            type: Boolean,
            default: false
        },
        nameNull: {
            type: String,
            default: 'Vazio'
        },
    },
    watch: {
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('grid', ['ActionIndexOfferItemOfferItemStatusFilter']),        
        
        async loadData(filter) {

            this.innerLoading = true;
            this.items = [];

            await this.ActionIndexOfferItemOfferItemStatusFilter(filter)
                    .then((res) => {

                        if(this.includeNull){
                            this.items.push({
                                id: null,
                                name: this.nameNull
                            });
                        }

                        res.data.forEach(element => {
                            this.items.push(element);
                        });

                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.innerLoading = false;
                    })
        },

        newItem(item){
            
            if(item){
                this.items.push(item);
                this.selected = item.id;
                this.showForm = false;
            }
        }
    },
    
}
</script>