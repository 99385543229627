<template>
    <v-container class="pa-0 ma-0" fluid>
        <v-card style="min-height: 600px">
            <v-toolbar v-if="showToolBar" dense height="45" color="grey lighten-3">
                <v-icon left>{{ icon }}</v-icon>
                <v-toolbar-title class="subtitle-1">
                    {{ title }}
                    <slot name="title"></slot>
                </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <slot name="tools"></slot>
            </v-toolbar>
            <v-card-text>
                <slot></slot> 
            </v-card-text>
        </v-card>    
    </v-container>
</template>

<script>
export default {
    name: 'MainContainer',
    components: {
     
    },
    props: {
        title: {},
        icon: {},
        text: {},
        showToolBar: {
            type: Boolean,
            default: true
        },
    }
}
</script>

<style lang="stylus" scoped>


</style>