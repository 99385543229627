export default {
    joins:              { method: 'get',    url: 'joins' },
    joinByGroupId:      { method: 'get',    url: 'joins/group/{group_id}' },
    joinByGroupIdToken: { method: 'get',    url: 'joins/group/{id}/token' },
    joinByAmbienceId:   { method: 'get',    url: 'joins/ambience/{ambience_id}' },
    status:             { method: 'get',    url: 'status_joins' },
    indexJoinTypes:     { method: 'get',    url: 'joins/types' },
    joinDelete:         { method: 'delete', url: 'joins/{id}' },
    store:              { method: 'post',   url: 'joins' },
    update:             { method: 'put',    url: 'joins/{id}' },
    joinDestroyTotvs:   { method: 'post',   url: 'integrations/join-destroy-totvs/{id}'},
}