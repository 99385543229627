<template>
    <div>
        <sys-find-base 
            v-bind:show.sync="dialog"
            :collection="items" 
            :headers="headers" 
            :icon="icon"
            @onSearch="searchGrids($event)" 
            @onSelect="select($event)"
            :loading="loading"
            :title="title"
            :singleSelect="singleSelect"
            :maxWidth="maxWidth"
            :validateExistJoin="validateExistJoin"
        >
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
                <slot :name="slot" v-bind="scope"/>
            </template>

            <template v-slot:item.actions="{item}">
                <v-icon  
                    v-if="item.join"
                    color="blue"
                    small
                    >
                    mdi-call-split
                </v-icon>
            </template>
        </sys-find-base>
        
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysFindGrid",
    data: (vm) => ({
        search: '',
        selected: [],
        dialog: false,
        loading: false,
        items: [],
        headers: [
                { text: 'Campus', align: 'left', value: 'group.campus.name' },
                { text: 'Turma', align: 'left', value: 'group.name' },
                { text: 'Turno', align: 'left', value: 'group.shift.name' },
                { text: 'Disciplina', align: 'left', value: 'discipline.name' },
                { text: 'Professor', align: 'left', value: 'teacher.name' },
                { text: 'Tipo', align: 'left', value: 'work_load_type.name' },
                { text: 'Carga Horária', align: 'left', value: 'work_load' },
                { text: '', align: 'left', value: 'actions' },
            ],
    }),
    created(){
        
    },

    computed: {
      
    },

    watch: {
        
        dialog(val) {
            this.$emit('update:show', val);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.searching = false;
                this.selected = [];
                this.search = "";
                this.items = [];
            }
        },
     
    
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "600"
        },
        maxWidth: {
            type: String,
            default: "70%"
        },
        icon: {
            type: String,
            default: "mdi-view-grid-plus-outline"
        },
        title: {
            type: String,
            default: "Selecionar Disciplinas"
        },
        show: {
            type: Boolean,
            default: false
        },
        singleSelect: {
            type: Boolean,
            default: true
        },
        validateExistJoin: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        
        ...mapActions('grid', ['ActionFindGrids']),
        
        select(val){

            this.dialog = false;
            this.$emit('onSelect', val);
            
        },

        searchGrids(search){
            
            this.loading = true;
            
            var payload = {
                search: search,
                with: 'group.campus,group.shift,discipline,workLoadType,teacher'
            };

            this.items = [];

            this.ActionFindGrids(payload)
                .then((res) => {
                    this.items = res.data;
                })
                .finally(() => {
                    this.loading  = false;
                });
            
        },
    }
}
</script>