<template>
    <v-card class="mt-6 mb-4 pl-3 pr-3 mx-auto" max-width="400">
        <v-skeleton-loader class="v-sheet--offset" max-height="330" type="image, article"></v-skeleton-loader>
    </v-card>
</template>

<script>
export default {
    name: "EmcChartLoading"
}
</script>