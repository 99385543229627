export default [
    {
        path: '/user-downloads',
        name: 'user-downloads',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Downloads',
            icon: 'mdi-file-download-outline',
            breadcrumbs: [
                { text: 'Lista de Downloads', disabled: true }
            ]
        }
    }
]