import { service } from '../../../../helpers/http'

export const ActionWorkLoadBalance = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'workLoadBalance',
        payload: payload
    })
    
}


