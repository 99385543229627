<template>
    
    <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" :class="color + ' mb-4 elevation-1'">
                        <v-icon left>{{ icon }}</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                <slot name="title">{{ title }}</slot>
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="reportId == 100">
                                <v-col cols="12" sm="4">
                                    <emc-calendar-date-picker2 
                                        :model.sync="databricks.date" 
                                        label="Data Foto*" 
                                        :show="dialog"
                                        class="mt-2"
                                    />
                                </v-col>
                                <!-- <v-col cols="12" sm="8">
                                    <s-switch 
                                        v-model="databricks.distinct"     
                                        text-helper='Remover Duplicidade de registros' 
                                        label="Sem Duplicidades"
                                        inset
                                    />
                                </v-col> -->
                            </v-row>
                            <v-row v-if="reportId == 100">
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        v-model="databricks.filter" 
                                        rows="3"
                                        clearable
                                        label="Filtro"
                                        outlined
                                        placeholder="Exemplo: cod_turma_composicao = 123 and cod_disciplina = 456"
                                        persistent-placeholder	

                                    />
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        v-model="databricks.columns" 
                                        rows="3"
                                        clearable
                                        label="Colunas (separadas por ',')"
                                        outlined
                                        placeholder="Exemplo: cod_turma_composicao, cod_disciplina, ..."
                                        persistent-placeholder	

                                    />
                                </v-col>
                                
                            </v-row>
                            <v-row v-if="reportId == 53 || reportId == 79">
                                <v-col cols="12" sm="12">
                                    <sys-select-brand
                                        :model.sync="brands"
                                        label="Marca"
                                        showClear
                                        multiple
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="reportId == 66 || reportId == 32">
                                    
                                <v-col v-if="showFilterPaymentExport" cols="12" sm="4">
                                    <sys-select-month dense :model.sync="month" />
                                </v-col>
                                <v-col v-if="showFilterPaymentExport" cols="12" sm="8">
                                    <sys-select-payment-type
                                        :model.sync="paymentTypes"
                                        label="Tipo de Pagamento*"
                                        multiple
                                        dense
                                    />
                                </v-col>    
 
                                <v-col cols="12" sm="12">
                                    <sys-select-company
                                        :model.sync="companies"
                                        label="Empresa (Obrigatório)"
                                        multiple
                                        dense
                                        select-all
                                    />
                                </v-col>
                                <v-col cols="12" sm="12">
                                    <sys-select-branch
                                        :model.sync="branches"
                                        label="Filial (Opcional)"
                                        multiple
                                        dense
                                        :disabled="companies.length == 0"
                                        :company-id="companies.map(i => i.id).join(',')"
                                    />
                                </v-col>
                            </v-row>
                    </v-container>
                        
                    </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="ligth green" @click.prevent.stop="downalodItem()" :loading="loading" :disabled="disabled" >Download<v-icon right dark>mdi-file-download-outline</v-icon></v-btn>
                </v-card-actions>
            </v-card>

    </v-dialog>

</template>

<script>

export default {
    name: "EmcExcelUserDownloadFilter",
    data: () => ({
        dialog: false,
        loading: false,
        brands: [],
        companies: [],
        branches: [],
        paymentTypes: [],
        month: null,
        databricks: {
            date: null,
            filter: null,
            columns: null,
            distinct: false,
        },
    }),
    created(){
        
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            this.loading = false;

            if(show_new){
                this.brands = [];
                this.companies = [];
                this.branches = [];
                this.paymentTypes = [];
                this.month = null;
            }
        },
        
    },

    computed: {

        disabled (){
            
            if(this.reportId == 66 && this.showFilterPaymentExport){
                return this.companies.length == 0 || this.paymentTypes.length == 0 || this.month == null;
            }else if(this.reportId == 53 || this.reportId == 79){
                return !this.brands.length && !this.companies.length && !this.branches.length;
            }else if(this.reportId == 100){
                return this.databricks.date == null;
            }else{
                return !this.companies.length && !this.branches.length;
            }
        }

    },
    
    props: {
        
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "40%"
        },
        icon: {
            type: String,
            default: "mdi-filter-outline"
        },
        title: {
            type: String,
            default: 'Filtros'
        },
        show: {
            type: Boolean,
            default: false
        },
        reportId: {
            required: true
        },
        showFilterPaymentExport:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        
        downalodItem(){

            
            this.loading = true;
            let params = {};
            let description = '';

            if(this.brands.length){
                params = {
                    brands:         this.brands.map(i => ({id: parseInt(i.id)})),
                    description:    this.brands.map(i => i.name).join(','),
                };
            }

            if(this.companies.length || this.branches.length){
                
                
                if(this.companies.length){
                    description = 'Empresa: ' + this.companies.map(i => i.name).join(',');
                }

                if(this.branches.length){
                    
                    if(description != ''){
                        description += ' | Filial: ';
                    }
                    
                    description = this.branches.map(i => i.name).join(',');
                }
                
                params = {
                    branches:         this.branches.length ? this.branches.map(i => ({id: parseInt(i.id)})) : null,
                    companies:        this.companies.length ? this.companies.map(i => ({id: parseInt(i.id)})) : null,
                    description:      description,
                };
            }

            if(this.paymentTypes.length){
                
                params.paymentTypes = this.paymentTypes.map(i => ({id: parseInt(i.id)}));
                params.month = this.month;
                params.description = 'Consolidado | Mês: ' + this.month + ' | ' + description;
                
                

                
            }

            if(this.databricks.date){
                params = {
                    date:           this.databricks.date,
                    description:    'Data Foto: ' + this.$options.filters.date(this.databricks.date),
                    filter:         this.databricks.filter,
                    columns:        this.databricks.columns,
                    distinct:       this.databricks.distinct,
                };
            }
            
            this.$emit('onCompleted', params);
            this.dialog = false;

        },
        
    }
}
</script>