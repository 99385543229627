<template>
    <div>
        <EmcChartLoading v-if="loading" />

        <emc-chart-filter :show.sync="showFilter" @filter="filter($event)" />

        <v-card v-show="!loading" class="pa-2">
            <canvas v-if="renderComponent" :id="name" :width="width" :height="height"></canvas>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="showFilter = true">
                                <v-icon btn>mdi-filter-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Selecionar Categorias
                        </span>
                    </v-tooltip>
                    <!-- <v-tooltip v-if="showMoreButton" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMore', itemId)">
                                <v-icon btn>mdi-monitor-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Ver Mais...
                        </span>
                    </v-tooltip> -->
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions } from 'vuex'

export default {
    name: 'EmcChartEfficiencyOfWorkload',
    data () {
        return {
            loading: true,
            width: '400',
            height: '240',
            name: 'EfficiencyOfWorkload',
            chartData: [],
            showFilter: false,
            filterPayload: null,
            renderComponent: true,
            title: '',
            chartOptions: {
                plugins: {
                    datalabels: {
                        color: 'white',
                        display: function(context) {
                            return context.dataset.data[context.dataIndex] > 15;
                        },
                        font: {
                            weight: 'bold'
                        },
                        formatter: Math.round
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: '',
                        padding: {
                            bottom: 20
                        }
                    }
                },
                aspectRatio: 5 / 3,
                layout: {
                    padding: {
                        top: 24,
                        right: 16,
                        bottom: 0,
                        left: 8
                    }
                },
                elements: {
                    line: {
                        fill: false
                    },
                    point: {
                        hoverRadius: 7,
                        radius: 5
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                }        
            }
        }
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        itemId: {
            type: Number,
        },
    },
    methods: {
        ...mapActions('chart', ['ActionChartEfficiencyOfWorkload']),
        async getData() {
            
            await this.ActionChartEfficiencyOfWorkload(this.filterPayload)
                .then((r) => {
                    this.chartData = r.data
                    this.chartOptions.plugins.title.text = r.data['title']
                })
            
            this.getChart();
        },
        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }
            
            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            var speedCanvas = document.getElementById(this.name)
            new Chart(speedCanvas, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false
        },

        filter(ev){
            this.filterPayload = ev;
            this.showFilter = false;
            this.forceRerender();
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
                this.getData();
            });
        },
    },

    created() {
        this.getData()
    }
}
</script>