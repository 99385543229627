<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :color="color" :small="small" icon @click.prevent.stop="clickAction()" v-bind="attrs" v-on="on"><v-icon :small="small">{{ icon }}</v-icon></v-btn>
            </template>
            <span> {{ toolTip }} </span>
        </v-tooltip>

        <emc-excel-user-import 
            :param="param"
            :layout="layout"
            :item-id="itemId"
            :showClearItems="showClearItems"
            @onCompleted="completed($event)" 
            :show.sync="showImport" 
        />

        <v-dialog v-model="dialog" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '40%'" persistent>
        <v-card>
                <v-card-title class="ma-0 pa-0">
                    <v-app-bar dense height="45" class="grey lighten-5 mb-4 elevation-1'">
                        <v-icon left>mdi-cloud-upload-outline</v-icon>
                            <v-toolbar-title class="subtitle-1">
                                Importação de Dados
                            </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.native="close()" v-bind="attrs" v-on="on">
                                        <v-icon color="red lighten-2">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                        </v-tooltip>
                    </v-app-bar>
                </v-card-title>
                    <v-card-text>
                        <p class="text-center text--primary">
                            Importação em processamento.
                            <br/>
                            Para acompanhar a importação, clique em <strong>Ir para Importações"</strong>    
                        </p>
                    </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary green" to="user-imports" target="_blank" >Ir para Importações<v-icon right dark>mdi-page-next-outline</v-icon></v-btn>
                    <v-btn color="ligth green" @click.prevent.stop="close()" >OK</v-btn>
                </v-card-actions>
            </v-card>

    </v-dialog>

    </span>
</template>
<script>
export default {
    name: "EmcExcelImportIcon",
    data () {
        return {
            message: 'Mensagem',
            dialog: false,
            showImport: false,
        }
    },
    props: {
        param: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: "success darken-1"
        },
        toolTip: {
            type: String,
            default: "Importar"
        },
        icon: {
            type: String,
            default: "mdi-cloud-upload"
        },
        hideDialog: {
            type: Boolean,
            default: false,
        },
        showClearItems: {
            type: Boolean,
            default: false
        },
        delay: {

        },
        layout: {
            type: String,
            default: null
        },
        itemId: {

        },
        small: {
            type: Boolean,
            default: false
        },
        attrs: {},
        on: {}
    },
    watch: {
        
        showImport(val){
           
            if(!val){
                this.$emit('update:delay', 200);
                this.$emit('update:menu', false);
            }
        }
    },
    
    methods: {

        completed(ev){
            this.showImport = false;
            
            if(this.hideDialog){
                this.close();
            }else{
                this.dialog = true;
            }
            
        },

        clickAction(){

            this.$emit('update:delay', 200000);
            this.showImport = true;

        },

        close(){
            this.$emit('onCompleted');
            this.dialog = false;
        },

    }
}
</script>
