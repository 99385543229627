export default [
    {
        path: '/dynamic-time',
        name: 'dynamict-time',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Horário',
            icon: 'mdi-puzzle-outline',
            breadcrumbs: [
                { text: 'Alocação dinâmica', disabled: true, href: '/' },
                { text: 'Horário', disabled: true, href: '/' },
            ],
        }
    },
]