import { default as download } from './download/routes'
import { default as userImport } from './import/routes'

export default [
    ...download,
    ...userImport,
    {
        path: '/users',
        name: 'UserIndex',
        component: () => import ('./pages/Index')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import ('./pages/Profile')
    },
    {
        path: '/my-tickets',
        name: 'my-tickets',
        component: () => import ('./pages/MyTickets')
    }
]