import { service } from '../../../helpers/http'

export const ActionFindHourGroups = ({ dispatch }, payload) => {

    return service({
        module: 'hourGroup',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateHourGroup = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroup',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateHourGroup = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroup',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteHourGroup = ({ dispatch }, payload) => {
    
    return service({
        module: 'hourGroup',
        action: 'delete',
        payload: payload
    })
    
}

