<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="80%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col>

                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="yellow darken-2"
                    />

                    <v-simple-table fixed-header height="400px">
                            <template v-slot:default>
                            <thead>
                                <tr v-if="items.length > 0">
                                    <th v-for="(item, i) in Object.keys(items[0])" :key="i">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <strong v-bind="attrs" v-on="on">{{ item }}</strong>
                                                </template>
                                                <span>{{  Object.values(items[0])[i]['campus2'] ?  Object.values(items[0])[i]['campus2'] : item }}</span>
                                            </v-tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="items.length > 0">
                                <tr
                                    v-for="(item, i) in items"
                                    :key="i"
                                >
                                    <td v-for="(item2, i) in Object.keys(item)" :key="i">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">{{ Object.values(item)[i]['value'] }}</span>
                                            </template>
                                            <span>
                                                <div class="text-center">
                                                    {{  Object.values(item)[i]['campus1'] }}
                                                    <span v-if="Object.values(item)[i]['campus2']"><br/>{{ Object.values(item)[i]['campus2'] }}</span>
                                                </div>
                                                
                                            </span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        
                    </v-col>
                </v-row>
                
            </v-container>
           
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDynamicTimeTeacherSharing",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        items: []
        
    }),
    created(){
      
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                this.getData();
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-share-variant"
        },
        title: {
            type: String,
            default: "Compartilhamento de Professores"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('dynamicGeneration', ['ActionDynamicTimeTeacherSharing']),

        getData()
        {
            this.error = false;
            this.message = '';
            this.loading = true;
            this.items = [];

            this.ActionDynamicTimeTeacherSharing()
                .then((res) => {
                    this.items = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                     this.loading = false;
                });
            
        },

    }
}
</script>