<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-book-open-page-variant" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:tools>
            <sys-find-grid 
                :show.sync="showSelectGrid"
                @onSelect="model.grid = $event[0]"
            />
        </template>
        
        <template v-slot:form>

            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field 
                                :error="!!errors.teacher_id" 
                                :error-messages="errors.teacher_id" 
                                label="Professor" 
                                :value="(model && model.teacher) ? model.teacher.name : ((model.grid && model.grid.teacher) ? model.grid.teacher.name : null)"
                            >
                                <template v-slot:append>
                                        <v-btn icon @click.stop.prevent="showSelectGrid = true"><v-icon color="indigo">mdi-magnify</v-icon></v-btn>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12" sm="8">
                            <sys-select-occurrence-type
                                :model.sync="model.occurrence_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.occurrence_type_id"
                                label="Tipo de Ocorrência"
                                showAdd
                                @change="changeType"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field 
                                v-model="model.value" 
                                :error="errors.length > 0" 
                                :error-messages="errors.value" 
                                label="Valor*"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_start" 
                                label="Data Início*"
                                :errorMessages="errors.date_start" 
                                :show="dialog"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_end" 
                                label="Data Fim*"
                                :errorMessages="errors.date_end" 
                                :show="dialog"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-time-picker 
                                :error="!!errors.hour_start" 
                                :errors="errors.hour_start" 
                                :time.sync="model.hour_start" 
                                label="Hora Início"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <emc-calendar-time-picker 
                                :error="!!errors.hour_end" 
                                :errors="errors.hour_end" 
                                :time.sync="model.hour_end" 
                                label="Hora Fim"
                            />
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" sm="4">
                            <v-switch @change="forceRerender()" v-if="renderComponent" :disabled="disableHasCertificate" inset v-model="model.has_medical_certificate" label="Atestado"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <emc-calendar-date-picker2 
                                v-if="renderComponent && model.has_medical_certificate"
                                :model.sync="model.date_start_certificate" 
                                label="Início*"
                                :errorMessages="errors.date_start_certificate" 
                                :show="dialog"
                                showClear
                                :disabled="!model.has_medical_certificate"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <emc-calendar-date-picker2 
                                v-if="renderComponent && model.has_medical_certificate"
                                :model.sync="model.date_end_certificate" 
                                label="Fim*"
                                :errorMessages="errors.date_end_certificate" 
                                :show="dialog"
                                showClear
                                :disabled="!model.has_medical_certificate"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error-messages="errors.note" 
                                v-model="model.note" 
                                label="Observação"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                 />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
       
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import { Loader } from '@googlemaps/js-api-loader';
export default {
    name: "SysOccurrenceRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        showSelectGrid: false,
        filter: [3,4,5,6],
        grid: null,
        selectedType: null,
        disableHasCertificate: false,
        renderComponent: true,
    }),
    created(){
        
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                
                if(!this.model.id){
                    this.model.grid = null;
                    this.model.active = 1;
                    this.model.allow_editing = 1;
                    this.model.has_medical_certificate = false;
                    this.disableHasCertificate = false;
                    this.selectedType = null;
                }else{
                    this.disableHasCertificate = this.model.type.requires_certificate ? true : false;
                    this.selectedType = this.model.type;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Ocorrências de Ponto"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('timeSheet', ['ActionUpdateOccurrence', 'ActionCreateOccurrence']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;
            
            var payload ={
                id:                             this.model.id ? this.model.id : 0,
                work_time_id:                   this.acesso.periodo.id,
                grid_id:                        this.model.grid ? this.model.grid.id : null,
                campus_id:                      this.model.grid ? this.model.grid.group.campus_id : null,
                course_id:                      this.model.grid ? this.model.grid.group.course_id : null,
                group_id:                       this.model.grid ? this.model.grid.group.id : null,
                teacher_id:                     this.model.grid ? this.model.grid.teacher_id : null,
                teacher_replaced_id:            null,
                discipline_id:                  this.model.grid ? this.model.grid.discipline_id : null,
                work_load_type_id:              this.model.grid ? this.model.grid.work_load_type_id : null,
                value_type_id:                  1,
                payment_periodicity_type_id:    1,
                occurrence_type_id:             this.model.occurrence_type_id,
                occurence_id:                   null,
                occurrence_status_id:            1,
                join_id:                        (this.model.grid && this.model.grid.join) ? this.model.grid.join.join_id : null,
                value:                          this.model.value,
                main:                           (this.model.grid && this.model.grid.join) ? this.model.grid.join.main : 1,
                has_medical_certificate:        this.model.has_medical_certificate,
                date_start:                     this.model.date_start,
                date_end:                       this.model.date_end,
                date_start_certificate:         this.model.date_start_certificate,
                date_end_certificate:           this.model.date_end_certificate,
                hour_start:                     this.model.hour_start,
                hour_end:                       this.model.hour_end,
                note:                           this.model.note,
                type:                           this.selectedType
            };

            if(edit) {
                this.ActionUpdateOccurrence(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateOccurrence(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })
            }

            
        },

        changeType(item){
            
            this.selectedType = item;
            this.disableHasCertificate = item.requires_certificate ? true: false;

            if(item.requires_certificate){
                this.model.has_medical_certificate = true;
            }

            this.forceRerender();
        },

        forceRerender () {
      
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },
 

    }
}
</script>