import { service } from '../../../helpers/http'

export const ActionFindFaqCategories = ({ dispatch }, payload) => {

    return service({
        module: 'faqCategory',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateFaqCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'faqCategory',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateFaqCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'faqCategory',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteFaqCategory = ({ dispatch }, payload) => {
    
    return service({
        module: 'faqCategory',
        action: 'delete',
        payload: payload
    })
    
}

