import { service } from '../../../helpers/http'

export const ActionFindCourseLevels = ({ dispatch }, payload) => {

    return service({
        module: 'courseLevel',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateCourseLevel = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseLevel',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateCourseLevel = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseLevel',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteCourseLevel = ({ dispatch }, payload) => {
    
    return service({
        module: 'courseLevel',
        action: 'delete',
        payload: payload
    })
    
}

