export default [
    {
        path: '/categoryAmbiences',
        name: 'categoryAmbiences',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Categoria de ambientes',
            icon: '',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' },
                { text: 'Categoria de ambientes', disabled: true, href: '/' }
            ],
        }
    },
]