export default {
    findTemplate:                   { method: 'get',    url: 'mail/template/{email_template_type_id}'},
    createEmailTemplate:            { method: 'post',   url: 'mail/template'},
    templateUpdate:                 { method: 'put',    url: 'mail/template/{id}'},
    sendEmailHours:                 { method: 'post',   url: 'mail/hour_teacher/send_hours_teacher'},
    sendEmailAvailability:          { method: 'post',   url: 'mail/availabilities/send_availability'},
    sendGeneralCommunication:       { method: 'post',   url: 'mail/send/general/communication'},
    previewMailAvailability:        { method: 'get',    url: 'mail/template/preview/{teacher_id}?teacher_id={teacher_id}&work_time_id={work_time_id}'},
    previewGeneralCommunication:    { method: 'get',    url: 'mail/template/preview/general/communication/{teacher_id}'},
    previewMailHours:               { method: 'get',    url: 'mail/template/preview/{teacher_id}/hours?id={teacher_id}&work_time_id={work_time_id}'},
}