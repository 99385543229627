export default [
    {
        path: '/big-occupation-areas',
        name: 'big-occupation-areas',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Grande Área',
            icon: 'mdi-ruler-square-compass',
            breadcrumbs: [
                { text: 'Lista de Grande Área', disabled: true }
            ]
        }
    }
]