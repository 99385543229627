<template>
    <div>
        <sys-register-base 
        :model="{}"
        title="Replicar Horário" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
        :showRequiredFields="false"
        :showButton="false"
    >
        
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <sys-select-course
                            :model.sync="model.courses"
                            :error="errors.length > 0"  
                            :errors="errors.courses"
                            label="Curso*"
                            showClear
                            dense
                            multiple
                            autoComplete
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <sys-select-group
                            :model.sync="model.groups"
                            :course-id="model.courses ? model.courses.map(c => c.id).join(',') : ''"
                            :error="errors.groups && errors.length > 0"  
                            :errors="errors.groups"
                            label="Turma"
                            :disabled="!model.courses || model.courses.length == 0"
                            outlined
                            multiple
                            dense
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <SysSelectWorkTime
                            :model.sync="workTime"
                            label="Período Letívo de Origem*"
                            filter-active
                            :error="errors.length > 0"  
                            :errors="errors.work_time_id_origin"
                            outlined
                            return-object
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col cols="12" md="6">
                        <s-switch
                            v-model="model.replicate_teacher" 
                            label="Replicar Professor"
                            inset
                        />
                    </v-col> -->
                    <v-col cols="12" md="6">
                        <s-switch
                            v-model="model.replicate_ambience" 
                            label="Replicar Ambiente"
                            inset
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-slot:actions>
            <emc-button-icon :icon="icon" text="Replicar Horário" color="light-green" @click.prevent.stop="showMessageReplicate = true" />
        </template>

        
            
    </sys-register-base>
        <emc-alert-modal text="Essa ação irá limpar os horários do período atual dos cursos selecionados. Deseja replicar o horário?" :show.sync="showMessageReplicate">
            <template v-slot:actions>
                <v-btn color="light-green" @click.prevent.stop="replicate()" :loading="loading">Replicar <v-icon right dark>{{ icon }}</v-icon></v-btn>
            </template>
        </emc-alert-modal>
    </div>

</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleReplicate",
    data: () => ({
        dialog: false,
        error: false,
        showMessageReplicate: false,
        loading: false,
        workTime: null,
        errors: {},
        model: {},
        icon: 'mdi-content-copy',           
    }),
    created(){
        
    },
    
    watch: {
        
        dialog(val_new, val_old) {
            
            if(val_new !== val_old) {
                this.$emit('update:show', val_new)
            }

        },

        show(val_new, val_old) {
            
            this.dialog = val_new;

            if(val_new){
                
                this.model = {};
                this.error = false;
                this.errors = {};
                
            }
        },

        
        
    },
    props: {
        
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
        ...mapActions('schedule', ['ActionReplicateClasses']),

        replicate(){

            this.loading = true;
            this.error = false;
            this.errors = {};
            let newModel = {};

            Object.assign(newModel, this.model);
            newModel.work_time_id_origin    = this.workTime ? this.workTime.id : null;
            newModel.courses                = this.model.courses ? this.model.courses.map(c => ({ id: c.id })) : null;
            newModel.groups                 = this.model.groups ? this.model.groups.map(c => ({ id: c.id })) : null;

            this.ActionReplicateClasses(newModel)
                .then((res) => {
                    this.dialog = false;
                })
                .catch((error) =>{
                    this.error = true;
                    this.errors = error.errors;
                })
                .finally(() => {
                    this.loading = false;
                    this.showMessageReplicate = false;
                });

        }

    }
}
</script>