<template>
    <div>
        <v-flex xs12 sm12>
            <input type="file" multiple style="display: none" ref="fileInput" accept=".xlsx" @change="onFiledPicked"/>
        </v-flex>

        <v-row>
            <v-container class="pa-4">
                <v-row class="fill-height" align="center">
                
                <v-col cols="12" xs="12" md="4">
                    <v-hover v-slot:default="{ hover }">
                        <v-card height="225px" @click.prevent.stop="onPickFile" outlined class="text-center" :elevation="hover ? 16 : 2">
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                        <v-icon class="mdi-48px" btn>mdi-plus</v-icon>
                                    
                            </v-row>
                        </v-card>
                    </v-hover>
                </v-col>

                <template v-for="(image, i) in images">
                    <v-col
                    :key="i"
                    cols="12"
                    md="4"
                    >
                    <v-hover v-slot:default="{ hover }">
                        <v-card
                        :elevation="hover ? 12 : 2"
                        :class="{ 'on-hover': hover }"
                        >
                        <v-img
                            :src="image.url"
                            height="225px"
                        >
                          <v-card-title class="title white--text">
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >

                                    
                                        <v-btn 
                                            small  
                                            fab 
                                            :elevation="!hover ? 0 : 2"
                                            color="transparent"
                                            :class="{ error: hover }" 
                                            @click="deleteImage(image)">
                                            <v-icon 
                                                :color="!hover ? 'transparent' : 'white'"
                                                >mdi-delete
                                            </v-icon>
                                        </v-btn>                            
                                    
                                </v-row>
                                </v-card-title>

                        </v-img>
                        </v-card>
                    </v-hover>
                    </v-col>
                </template>
                </v-row>
            </v-container>

        </v-row>
    </div>
</template>

<script>
  export default {
    name: "EmcFileUploadImages",
    props: {
        images: {
            type: Array,
            
        }
    },
    methods: {
        deleteImage(item) {
                const index = this.images.indexOf(item)
                this.images.splice(index, 1)
        },
        onPickFile(){
            this.$refs.fileInput.click()
        },
        onFiledPicked(event) {

            const files = event.target.files

            var i
            for (i=0; i<files.length; i++) {
                let filename = files[0].name

                if(filename.lastIndexOf('.') <= 0){
                    return alert('Selecione uma imagem')
                }

                const fileRender = new FileReader()

                fileRender.addEventListener('load', () => {
                    const image = fileRender.result

                    this.images.push({ url: image })
                    
                })
                fileRender.readAsDataURL(files[i])
            }

        },
    },
    watch: {
        images(event){
             this.$emit('update:images', event)
        }
    },
  }
</script>

<style>
    .show-btns {
        color: rgba(255, 255, 255, 1) !important;
    }
</style>