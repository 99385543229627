import { service } from '../../../helpers/http'

export const ActionFindCategories = ({ dispatch }, payload) => {

    return service({
        module: 'category',
        action: 'categories',
        payload: payload
    })
    
}

export const ActionCreateCategory = ({ dispatch }, payload) => {

    return service({
        module: 'category',
        action: 'createCategory',
        payload: payload
    })
    
}

export const ActionUpdateCategory = ({ dispatch }, payload) => {

    return service({
        module: 'category',
        action: 'updateCategory',
        payload: payload
    })
    
}

export const ActionDeleteCategory = ({ dispatch }, payload) => {

    return service({
        module: 'category',
        action: 'deleteCategory',
        payload: payload
    })
    
}