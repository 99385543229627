import { service } from '../../../helpers/http'

export const ActionFindBlocks = ({ dispatch }, payload) => {

    return service({
        module: 'block',
        action: 'blocks',
        payload: payload
    })
    
}

export const ActionFindBlocksFilter = ({ dispatch }, payload) => {

    return service({
        module: 'block',
        action: 'blocksFilter',
        payload: payload
    })
    
}

export const ActionCreateBlock = ({ dispatch }, payload) => {

    return service({
        module: 'block',
        action: 'createBlock',
        payload: payload
    })
    
}

export const ActionUpdateBlock = ({ dispatch }, payload) => {

    return service({
        module: 'block',
        action: 'updateBlock',
        payload: payload
    })
    
}

export const ActionDeleteBlock = ({ dispatch }, payload) => {

    return service({
        module: 'block',
        action: 'deleteBlock',
        payload: payload
    })
    
}
