<template>
    <v-progress-linear
        :value="100"
        :color="color"
        striped
        height="25"
    >
        <template v-slot:default>
            {{ text }}
        </template>
    </v-progress-linear>
</template>
<script>

export default {
    name: "SysAmbienceOccupationLegend",
    data() {
        return {
           
        };
    },
    props: {
        color: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
    },
};
</script>