import { default as time } from './time/routes'
import { default as allocation } from './allocation/routes'
import { default as offer } from './offer/routes'
import { default as attribution } from './attribution/routes'
import { default as attributionResult } from './attribution_result/routes'

export default [
    ...time,
    ...allocation,
    ...offer,
    ...attribution,
    ...attributionResult
]