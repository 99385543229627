<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="30%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-day-of-week
                                :model.sync="model.day_of_week_id"
                                :error="errors.length > 0"  
                                :errors="errors.day_of_week_id"
                                label="Dia*"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-shift
                                :model.sync="model.shift_id"
                                :error="errors.length > 0"  
                                :errors="errors.shift_id"
                                label="Turno*"
                                :maxShift="4"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker :error="!!errors.hour_start" :errors="errors.hour_start" :time.sync="model.hour_start" label="Início*" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker :error="!!errors.hour_end" :errors="errors.hour_end" :time.sync="model.hour_end" label="Fim*" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field 
                                v-model="model.total_classes" 
                                dense 
                                :error="!!errors.total_classes" 
                                :error-messages="errors.total_classes" 
                                label="Total de Aulas*"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date" 
                                label="Data" 
                                :errorMessages="errors.date"
                                :show="dialog"
                                showClear
                            />

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field :error="!!errors.description" :error-messages="errors.description" v-model="model.description" label="Descrição"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-switch inset v-model="model.active" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterHourGroupDate",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = true;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-calendar-multiselect"
        },
        title: {
            type: String,
            default: "Grupo de Horário por Data"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('hourGroupDate', ['ActionUpdateHourGroupDate', 'ActionCreateHourGroupDate']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id})

            if(edit) {
                this.ActionUpdateHourGroupDate(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateHourGroupDate(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>