<template>
    <v-main>
        <v-container fluid>
            <router-view></router-view> 
        </v-container>
    </v-main>
</template>

<script>
export default {
    name: "SysCoreContent"
}
</script>