<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-laptop-chromebook" 
        v-bind:show.sync="dialog"
        :maxWidth="maxWidth"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field autofocus :error="!!errors.name" :error-messages="errors.name" v-model="model.name" label="Nome*"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12" sm="8">
                            <sys-select-resource-type
                                :model.sync="model.resource_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.resource_type_id"
                                label="Tipo de Recurso"
                                showClear
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field code dense :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                v-model="model.description"
                                outlined
                                name="input-7-4"
                                label="Descrição"
                                :value="model.description"
                                :error="errors.length > 0"  
                                :errors="errors.description"
                                rows="2"
                                row-height="20"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                             />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch text-helper="Recurso de uso compartilhado. Na validação ao vincular ambientes não considera o número de alunos da turma. Exemplo: Elevador." inset v-model="model.shared" label="Recurso Compartilhado"/>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-switch text-helper="Recurso móvel que pode ser utilizado por diferentes ambientes." inset v-model="model.is_mobile" label="Recurso Móvel"/>
                        </v-col>
                        <v-col  cols="12" sm="2">
                            <s-text-field text-helper="Quantidade de recursos disponíveis para serem disponibilizados aos ambientes" v-if="model.is_mobile" :error-messages="errors.total" v-model="model.total" label="Total" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch inset text-helper="Recurso disponível para reserva e agendamento externo" v-model="model.allow_external_allocation" label="Agendamento Externo"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-switch inset v-model="model.active" label="Ativo"/>
                        </v-col>
                    </v-row>
                    
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterResource",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.shared = 1;
                    this.model.is_mobile = 0;
                    this.model.active = 1;
                }
                
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "50%"
        },
        icon: {
            type: String,
            default: "mdi-laptop-chromebook"
        },
        title: {
            type: String,
            default: "Recurso"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('resource', ['ActionUpdateResource', 'ActionCreateResource']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            if(!this.model.is_mobile){
                this.model.total = null;
            }

            if(edit) {
                this.ActionUpdateResource(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateResource(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>