<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus</th>
                        <th>Mês</th>
                        <th>Espelho</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>{{ item.campus.name }}</td>
                        <td>{{ item.month }}</td>
                        <td>
                            <emc-table-icon-download-pdf 
                                toolTip="Espelho de Ponto" 
                                :route="'pdf/export/' + token + '/' + item.id + '/time_sheet'"
                                :item="item"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherOccurrence',
    data: function() {
        return {
            items: [],
            loading: true,
            renderComponent: true,
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        token: {
            type:   String,
            default: null
        },
        workTime: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeacher', 'ActionFindTeacherByToken']),

        getData() {

            if(this.teacher.id === undefined) {
                return []
            }

            this.items = [];
            this.loading = true;
            var method = this.token ? 'ActionFindTeacherByToken' : 'ActionFindTeacher';

            var payload = {
                with: 'timeSheets.items,timeSheets.campus',
                filter_relation_withs: [
                    {
                        name: 'timeSheets',
                        key: 'work_time_id', 
                        value: this.workTime.id,
                    }
                ] 
            }

            if(this.token){
                payload.token = this.token;
            }else{
                payload.id = this.teacher.id;
            }

            this[method](payload)
                .then((r) => {
                    this.items = r.data.timeSheets;
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>