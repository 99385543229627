import { service } from '../../../../helpers/http'

export const ActionIndexTermSignatures = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'indexTermSignatures', 
		payload: payload 
	})
}

export const ActionCreateTermSignature = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'createTermSignature', 
		payload: payload 
	})
}

export const ActionUpdateTermSignature = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'updateTermSignature', 
		payload: payload 
	})
}

export const ActionDestroyTermSignature = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'destroyTermSignature', 
		payload: payload 
	})
}

export const ActionIndexTermSignatureStatus = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'indexTermSignatureStatus', 
		payload: payload 
	})
}

export const ActionShowTermSignature = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'showTermSignature',
		payload: payload,
		idAndPayload: true,
	})
}

export const ActionUpdateTermSignatureGenerate = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'term', 
		action: 'termSignatureGenerate', 
		payload: payload 
	})
}