<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        :maxWidth="maxWidth"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="10">
                            <v-autocomplete
                                v-model="selectedDiscipline"
                                :items="items"
                                :loading="isLoading"
                                :search-input.sync="search"
                                append-icon="mdi-magnify"
                                @click:append="searchDisciplines(search)"
                                v-on:keyup.enter="searchDisciplines(search)"
                                hide-no-data
                                hide-selected
                                item-text="name_complete"
                                label="Pesquisar disciplina..."
                                return-object
                                autofocus
                                persistent-hint
                            />
                        </v-col>
                         <v-col cols="12" sm="2">
                            <v-text-field :error="errors.length > 0" :error-messages="errors.code" v-model="model.code" label="Código" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-card outlined height="50%">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :items="equivalenceDisciplines"
                                    :single-select="false"
                                    item-key="discipline_id"
                                    show-select
                                    :item-selected="selected"
                                    class="elevation-0"
                                    hide-default-footer
                                    height="300"
                                    :items-per-page="-1"
                                    fixed-header
                                >
                                    <template v-slot:item.curriculum="{ item, index }">
                                        <sys-select-curriculum
                                            :model.sync="item.curriculum_id"
                                            :error="errors.length > 0"  
                                            :errors="errors['disciplines.' + index + '.curriculum_id']"
                                            label=""
                                            dense
                                            :discipline-id="item.discipline_id"
                                            auto-complete
                                        />
                                    </template>

                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>
            </v-container>
            
            <v-alert v-if="errors.length > 0" type="error">
                <ul>
                    <li :key="error" v-for="error in errors">
                    {{ error }}
                    </li>
                </ul>
            </v-alert>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterEquivalence",
    data: (vm) => ({
        selectedDiscipline: {},
        search: '',
        dialog: false,
        error: false,
        errors: {},
        message: '',
        isLoading: false,
        items: [],
        equivalenceDisciplines: [],
        loading: false,
        selected: [],
        headers: [
          { text: 'Código', align: 'left', value: 'discipline.code' },
          { text: 'Disciplina', align: 'left', value: 'discipline.name' },
          { text: 'Matriz', align: 'left', value: 'curriculum' },
          { text: 'Carga Horária', align: 'left', value: 'discipline.work_load' },
          
        ],
        
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {  
        
        selectedDiscipline(val){
            
            if(Object.keys(val).length > 0){
                
                let item = this.getItem(val);

                var disciplines2 = this.equivalenceDisciplines.filter((d) => {
                    return d.discipline_id == val.id
                })

                if(Object.keys(disciplines2).length == 0){
                    
                    var disciplines3 = this.equivalenceDisciplines;
                    
                    this.equivalenceDisciplines = [];
                    this.equivalenceDisciplines.push(item);
                    
                    disciplines3.forEach(d => {
                        this.equivalenceDisciplines.push(d);
                    });

                    this.selected.push(item);
                    
                }

                this.selectedDiscipline = {}
            }
            
        },
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },
        
        show(show_new, show_old) {
            
            this.dialog = show_new;

            if(show_new){
                
                this.errors = {};
                this.selected = [];
                this.search = null;
                this.items = [];
                this.equivalenceDisciplines = [];

                Object.assign(this.equivalenceDisciplines, this.model.equivalenceDisciplines);
                Object.assign(this.selected, this.equivalenceDisciplines);
                
            }
                
        },
    },
    props: {
        model: {
            default: null
        },
        maxWidth: {
            type: String,
            default: "60%"
        },
        icon: {
            type: String,
            default: "mdi-vector-polyline"
        },
        title: {
            type: String,
            default: "Equivalência"
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('equivalence', ['ActionCreateEquivalence', 'ActionUpdateEquivalence']),
        ...mapActions('discipline', ['ActionFindDisciplines']),
        
        getItem(item){
            return {
                discipline_id: item.id,
                curriculum_id: item.curriculum_id,
                discipline: {
                    id:         item.id,
                    code:       item.code,
                    name:       item.name,
                    work_load:  item.work_load
                },
            };
        },
        
        searchDisciplines(){
            
            var payload = {
                search: this.search,
                page: 1,
                per_page: 500,
            };

            this.isLoading  = true;
            this.items = [];
            
            this.ActionFindDisciplines(payload)
                .then((res) => {
                    this.items = res.data
                })
                .finally(() => {
                    this.isLoading  = false;
                });
        },
        save(edit)
        {  
            this.error = null;
            this.message = '';
            this.loading = true;
            
            var payload;

            if(this.model.id){
                payload = this.model;
            }else{  
                payload = {
                    institution_id: this.acesso.instituicao.id,
                    equivalence_status_id: 1,
                    code: null
                }
            }
            
            payload.disciplines = [];

            this.selected.forEach(d => {
                
                var item = {
                    discipline_id: d.discipline_id,
                    curriculum_id: d.curriculum_id,
                };

                payload.disciplines.push(item);
            });
            
            if(edit) {

                this.ActionUpdateEquivalence(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                    
                    if(error.errors.disciplines){
                        this.errors = error.errors.disciplines;
                    }
                    
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                
                this.ActionCreateEquivalence(payload)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })
            }
        },
    }
}
</script>