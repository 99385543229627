<template>
    <v-app id="inspire">
      <template v-if="$route.path.includes('login') || $route.path.includes('access-teacher') || $route.path.includes('access-time-sheet')">
        <Login v-if="$route.path.includes('login')" />
        <AccessTeacher v-if="$route.path.includes('access-teacher')" />
        <AccessTimeSheet v-if="$route.path.includes('access-time-sheet')" />
      </template>
      <SysCoreMaster v-else/>
    </v-app>
</template>
<script>

import Login from './modules/auth/pages/Login.vue'
import AccessTeacher from "./modules/auth/pages/AccessTeacher.vue";
import AccessTimeSheet from "./modules/time_sheet/auth/pages/Index.vue";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";
export default {
  name: "App",
  components: {
    Login,
    AccessTeacher,
    AccessTimeSheet
  },
  created() {
    loadMessages(ptMessages);
    locale(navigator.language);
  }
}
</script>