<template>
  <v-col :cols="cols" :md="md">
    
    <emc-form-auto-complete 
      v-model="model"
      :items="items"
      label="Categoria"
      :outlined="outlined"
      :disabled="disabled"
      :error-messages="errorMessages"
      :error="error"
      :loading="loading"
      :hide-no-data="hideNoData"
      @onClick="getData"
    >
      <template v-slot:item="{item}">
          <v-list-item-icon>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
          </v-list-item-content>
      </template>

    </emc-form-auto-complete>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SelectCategory',
  props: {
      cols: { type: [String, Number], default: 12 },
      md: { type: [String, Number], default: 6 },
      value: { type: [String, Number, Object] },
      errorMessages: { type: Array, required: false },
      error: { type: Boolean, required: false },
      disabled: { type: Boolean, required: false },
      outlined: { type: Boolean, required: false },
      item: { type: Object, required: false },
      hideNoData: { type: Boolean, required: false }
  },
  data () {
    return {
      loading: false,
      collection: []
    }
  },
  computed: {
    model: {
        get() {
            return this.value
        },
        set(val) {
            this.$emit('input', val)
        }
    },
    items: {
      get() {
          if(this.collection.length > 0) {
            return this.collection
          } else {
            return [this.item]
          }
      },
      set(val) {
         this.collection = val
      }
    }

  },
  methods: {
    ...mapActions('ticket', ['ActionTicketCategoriesIndex']),
    getData() {

      if(this.items.length > 2)
          return;
        
      this.loading = true
      this.ActionTicketCategoriesIndex()
              .then((r) => {
                  this.items = r.data
              })
              .finally(() => {
                  this.loading = false
              })
    }
  }
}
</script>