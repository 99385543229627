<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="30%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-integration-item
                                :model.sync="model.integration_item_id"
                                :error="errors.length > 0"
                                :errors="errors.integration_item_id"
                                label="Tipo de Integração"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-integration-schedule-type
                                :model.sync="model.schedule_type_id"
                                :error="errors.length > 0"
                                :errors="errors.schedule_type_id"
                                label="Tipo de Agendamento" 
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_start" 
                                label="Início*" 
                                :errorMessages="errors.date_start"
                                :show="dialog"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_end" 
                                label="Fim*" 
                                :errorMessages="errors.date_end"
                                :show="dialog"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <emc-calendar-time-picker 
                                :time.sync="model.time" 
                                :error="!!errors.time" 
                                :errors="errors.time" 
                                :label="labelTime" 
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-switch inset v-model="model.multi_lot" label="Múltiplos lotes" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-switch inset v-model="model.active" label="Ativo" />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysIntegrationScheduleRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),

      labelTime(){
        
        if(!this.model){
            return '';
        }
        
        switch (this.model.schedule_type_id) {
            case 2: return 'Intervalo*';
            case 3: return 'Horário*';
            default: return 'Tempo*';
        }
        
        return this.model.schedule_type_id
      },
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(!this.model.id){
                    this.model.active = true;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-database-plus"
        },
        title: {
            type: String,
            default: "Agendamento de Integração"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('integration', ['ActionUpdateIntegrationItemSchedule', 'ActionStoreIntegrationItemSchedule']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(edit) {
                this.ActionUpdateIntegrationItemSchedule(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionStoreIntegrationItemSchedule(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>