import { service } from '../../../helpers/http'

export const ActionFindBaseGroups = ({ dispatch }, payload) => {

    return service({
        module: 'baseGroup',
        action: 'baseGroups',
        payload: payload
    })
    
}

export const ActionCreateBaseGroup = ({ dispatch }, payload) => {

    return service({
        module: 'baseGroup',
        action: 'createBaseGroup',
        payload: payload
    })
    
}

export const ActionUpdateBaseGroup = ({ dispatch }, payload) => {

    return service({
        module: 'baseGroup',
        action: 'updateBaseGroup',
        payload: payload
    })
    
}

export const ActionDeleteBaseGroup = ({ dispatch }, payload) => {

    return service({
        module: 'baseGroup',
        action: 'deleteBaseGroup',
        payload: payload
    })
    
}

export const ActionIndexBaseGroupStatus = ({ dispatch }, payload) => {

    return service({
        module: 'baseGroup',
        action: 'baseGroupStatus',
        payload: payload
    })
    
}

