<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        title="Cadastrar template de email" 
        icon="mdi-card-account-mail-outline" 
        v-bind:show.sync="dialog"
        maxWidth="65%"
    >
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field dense :error="errors.length > 0" :error-messages="errors.subject" v-model="model.subject" label="Assunto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <!--<v-textarea dense :error="errors.length > 0" :error-messages="errors.body" v-model="model.body" label="Descrição"></v-textarea>-->
                        <span>Corpo</span>
                        <vue-editor v-model="model.body" :editorToolbar="customToolbar" />
                    </v-col>
                    <v-col cols="12" sm="12">
                        <!-- <v-textarea dense :error="errors.length > 0" :error-messages="errors.footer" v-model="model.footer" label="Rodapé"></v-textarea> -->
                        <span>Rodapé</span>
                        <vue-editor v-model="model.footer" :editorToolbar="customToolbar" />
                    </v-col>
                </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>

    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterTemplateEmail",
    data: () => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        model: {},
        customToolbar: [

                [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                [{ size: ["small", false, "large", "huge"] }],

                ["bold", "italic", "underline"],

                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],

                ["blockquote"],

                [{ list: "ordered" }, { list: "bullet" }],

                [{ indent: "-1" }, { indent: "+1" }],

                [{ color: [] }],

                ["link"],
                
        ]
        
    }),
    computed: {
      ...mapState('auth', ['acesso']),
    },
    created() {
        this.getData()
    },
    watch: {
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },
        show(show_new, show_old) {  
            this.dialog = show_new;
            if(show_new){
                this.error = false;
                this.errors = {};
            }
        },
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        templateId: {
            type: Number,
            default: 1
        }
    },
    methods:{
        ...mapActions('mail', ['ActionFindTemplate', 'ActionUpdateEmailTemplate', 'ActionCreateEmailTemplate']),
        save()
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            if(!this.model.id) {
                    
                Object.assign(this.model, { 
                    email_template_type_id: this.templateId,
                    institution_id: this.acesso.instituicao.id,
                    });

                this.ActionCreateEmailTemplate(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.model = res.data
                    this.$emit('update:onModel', res.data);
                    this.dialog = false;
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionUpdateEmailTemplate(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.model = res.data
                    this.$emit('update:onModel', res.data);
                    this.dialog = false;
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },
        getData() {
            
            this.loadingTemplate = true;
            var payload = {
                email_template_type_id: this.templateId
            };

            this.ActionFindTemplate(payload)
                .then((res) => {
                    if(res.data.id) {
                        this.model = res.data
                        this.$emit('update:onModel', res.data);
                    }
                })
                .finally(() => {
                    this.loadingTemplate = false
                })
        },

    }
}
</script>