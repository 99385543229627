import { service } from '../../../helpers/http'

export const ActionShowAddresses = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'showAddresses',
        payload: payload
    })
    
}

export const ActionIndexAddresses = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'indexAddresses',
        payload: payload
    })
    
}

export const ActionStoreAddress = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'storeAddress',
        payload: payload
    })
    
}

export const ActionUpdateAddress = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'updateAddress',
        payload: payload
    })
    
}

export const ActionDestroyAddress = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'destroyAddress',
        payload: payload
    })
    
}

export const ActionDistancesAddresses = ({ dispatch }, payload) => {

    return service({
        module: 'address',
        action: 'distancesAddress',
        payload: payload
    })
    
}
