<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="disabled" icon @click.prevent.stop="executeMethod()" v-bind="attrs" v-on="on">
                <v-icon :color="color" :small="$vuetify.breakpoint.name != 'xs'">{{ icon }}</v-icon>
            </v-btn>
            </template>
            <span> {{ toolTip }} </span>
        </v-tooltip>
    </span>
</template>
<script>
import  { myMixin } from '../../../mixins';
export default {
    mixins: [myMixin],
    name: "EmcTableIconDownloadBase",
    data () {
        return {
            
        }
    },
    props: {
        color: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        toolTip: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            default: 0
        },
        url: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    methods: {

        executeMethod(){

            if(this.type == 'excel'){
                this.download(this.id, 'id=' + this.item.id);
            }else{
                this.exportPdf(this.type, this.item.id, this.url);
            }
        }

    }
}
</script>
