<template>
    <v-col :cols="cols" :sm="sm" :md="md">
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              :label="label"
              append-icon="mdi-calendar"
              :readonly="readonly"
              :outlined="outlined"
              v-bind="attrs"
              v-on="on"
              :error="error"
              :error-messages="errorMessages"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
            :color="color"
          ></v-date-picker>
        </v-menu>
    </v-col>
</template>

<script>
export default {
    name: 'EmcFormDateTextField',
    data () {
      return {
        menu: null
      }
    },
    props: {
        sm: { type: String, default: "6" },
        md: { type: String, default: "4" },
        cols: { type: String, default: "12" },
        label: { type: String, default: "Data" },
        readonly: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        color: { type: String, default: "grey darken-2"},
        value: String,
        errorMessages: { type: Object, required: false },
        error: { type: Boolean, required: false }
    },
    computed: {
      dateFormatted: {
        get() {
          return this.formatDate(this.date)
        },
        set(val) {
          //
        }
      },
      date: {
        get() {
          return this.value
        },
        set(val) {
          this.dateFormatted = this.formatDate(this.date)
          this.$emit('input', val)
        }
      }
    },
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    }
}
</script>