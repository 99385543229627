<template>
    <div>
        {{ text }}
        <v-progress-linear
            indeterminate
            color="blue"
            class="mb-0"
        ></v-progress-linear>
    </div>  
</template>

<script>
export default {
    name: 'EmcBaseProgressBar',
    props: {
        text: {
            type: String,
            default: 'Carregando dados...',
            required: false
        }
    }
}
</script>