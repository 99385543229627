<template>
    <v-card
            class="mx-auto"
            width="256"
            tile
            >
            <v-list>
                <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                     {{ title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ subTitle }}</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list
                nav
                dense
            >
                <v-list-item-group
                v-model="selected"
                color="primary"
                >
                <draggable @remove="remove" @add="add" @start="start" gostClass="on-drag" :group="group" animation="400">
                
                    <v-list-item  v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </draggable>

                </v-list-item-group>
            </v-list>
        
            </v-card>
</template>

<script>
export default {
    name: "SysListDrag",
    data: () => ({
        selected: -1
    }),
    watch: {
        
        selected(val){
            this.$emit('update:model', val);
        },
        
        model(val){
            this.selected = val;
        },
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        model: {
            required: true
        },
        group: {
            type: String,
            default: ''
        },
        items: {

        }
    },

    methods: {
        add(val){
           
        },
        start(val){
            
        },
        remove(val){
           
        }
    }
}
</script>