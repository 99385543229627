<template>
    <div>
        <sys-select-base 
            :model.sync="selected"
            :items="items"
            :loading="loading"
            :label="label"
            :error="error"
            :errors="errors"
            :showClear="showClear"
            :showAdd="showAdd"
            :dense="dense"
        />
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'SysSelectStatusContact',
    data: () => ({
        items: [],
        loading: false,
        selected: null,
    }),
    created() {
        this.getItems();
    },
    props: {
        error: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
        },
        label: {
            type: String,
            required: true
        },
        showClear: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        model: {},
        showAll:{
            type: Boolean,
            default: false
        },
        maxShift: {
            type: Number,
            default: 3
        }
    },
    watch: {
        selected(val){
            this.$emit('update:model', val);
        },
        model(val){
            this.selected = val;
        },
    },
    methods: {
        ...mapActions('contact', ['ActionFindStatusContacts']),
        async getItems() {
            this.loading = true
            await this.ActionFindStatusContacts()
                    .then((res) => {
                        if(this.showAll){
                            this.items = res.data;
                        }else{
                            this.items = res.data.filter(s => s.id <= this.maxShift);
                        }
                        this.selected = this.model;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
        },
    },
    
}
</script>