<template>
    <emc-card-stats
            icon="mdi-account-clock-outline"
            color="blue accent-1"
            sheet-width="50"
            sheet-heigth="50"
            icon-class="px-3 py-1"
            subtitle="Disponibilidade"
            sub-icon="mdi-clock-outline"
            :sub-text="limited"
            tools
          >

          <template slot="tools">
              
              <emc-button-icon :loading="loading" icon="mdi-refresh-circle" text="Atualizar" color="success" @click.prevent.stop="refresh()" />

              <v-tooltip v-if="!hideTools" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="indigo darken-1" icon small @click.prevent.stop="download(1)" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                  </template>
                  <span>Resumido</span>
              </v-tooltip>

              <v-tooltip v-if="!hideTools" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green darken-1" icon small @click.prevent.stop="download(10)" v-bind="attrs" v-on="on"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                    </template>
                  <span>Detalhado</span>
              </v-tooltip>

          </template>
          <template slot="body">
            <v-progress-circular
                          :rotate="360"
                          :size="140"
                          :width="15"
                          :value="(availability && availability.percent) ? availability.percent : 0"
                          color="blue accent-1"
                        >
                        <template slot:default>
                          <span>
                            <span>{{ availability && availability.percent ? availability.percent : 0 }}%</span><br />
                            <span v-if="availability && availability.total">{{ availability.completed}} / {{ availability.total}}</span>
                          </span>
                        </template>
                      </v-progress-circular>
          </template>
          </emc-card-stats>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../mixins';

export default {
    name: 'EmcChartAvailabilitiesOfWorkTime',
    mixins: [myMixin],
    data () {
        return {
          loading: false,
          availability: {
            total: 0,
            percent: 0,
            completed: 0
          }
        }
    },
    props: {
        hideTools: {
            type: Boolean,
            default: false
        },
    },
    methods: {
      ...mapActions('controlPanel', ['ActionFindIndicatorAvailabilities', 'ActionIndicatorAvailabilityRefresh']),
      getData(){
      
        this.availability = {};
        
        var payload = {
          work_time_id: this.acesso.periodo.id
        };
        
        this.ActionFindIndicatorAvailabilities(payload)
          .then((res) => {
              this.availability = res.data;
          }).finally(() => {
            this.loading = false;
          });
      
      },

      refresh(){
      
        this.loading = true;
        
        this.ActionIndicatorAvailabilityRefresh()
            .then((res) => {
                
            })
            .finally(() => {
              setTimeout(() => {  
                this.getData();
              }, 6000);
            });
      },
    },
    created() {
      this.getData()
    },
    computed: {
      ...mapState('auth', ['acesso']),
      limited() {
        
        if(this.acesso.periodo && this.acesso.periodo.availability_end){
          const [year, month, day] = this.acesso.periodo.availability_end.split('-')
          return 'Liberado até ' + `${day}/${month}/${year}`
        }else{
          return null;
        }
        
      }
    }
}
</script>