import { service } from '../../../../helpers/http'

export const ActionIndexIntegrationHours = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'indexIntegrationHours',
        payload: payload
    })

}
export const ActionDestroyIntegrationHour = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'destroyIntegrationHour',
        payload: payload
    })
}
export const ActionStoreIntegrationHour = ({ dispatch }, payload ) => {
    return service({
        module: 'integration',
        action: 'storeIntegrationHour',
        payload: payload
    })

}
export const ActionUpdateIntegrationHour = ({ dispatch }, payload ) => {

    return service({
        module: 'integration',
        action: 'updateIntegrationHour',
        payload: payload
    })
}