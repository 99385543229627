<template>
    <span>
        <emc-table-icon-download-base 
            :type="type"
            :item="item"
            :id="id"
            :color="color" 
            :icon="icon" 
            :tool-tip="toolTip"
            :params="params"
        />
    </span>
</template>
<script>
export default {
    name: "EmcTableIconDownloadExcel",
    data () {
        return {
            
        }
    },
    props: {
        color: {
            type: String,
            default: 'success darken-1'
        },
        icon: {
            type: String,
            default: 'mdi-microsoft-excel'
        },
        toolTip: {
            type: String,
            default: 'Download Excel'
        },
        type: {
            type: String,
            default: 'excel'
        },
        item: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        params: {
            type: String,
            default: null
        }
    },
}
</script>
