import { service } from '../../../../helpers/http'

export const ActionIndexConsistencies = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'indexConsistencies',
        payload: payload
    })
    
}

export const ActionStoreConsistency = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'storeConsistency',
        payload: payload
    })
    
}

export const ActionUpdateConsistency = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'updateConsistency',
        payload: payload
    })
    
}

export const ActionDestroyConsistency = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'destroyConsistency',
        payload: payload
    })
}

export const ActionGenerateConsistency = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'generateConsistency',
        payload: payload
    })
}

export const ActionIndexConsistencyTypes = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'indexConsistencyTypes',
        payload: payload
    })
}


