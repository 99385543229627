<template>
    <div id="extract">
        <slot/>
    </div>
</template>
<script>

import { jsPDF } from "jspdf"
import html2canvas from 'html2canvas'
export default {
    name: "EmcUtilPdf",
    data () {
        return {
            
        }
    },
    props: {
        width: {
            type: Number,
            default: 208 // 1248 / 6
        },
        height: {
            type: Number,
            default: 158 // 1248 / 6
        },
        fileName: {
            type: String,
            default: 'extract'
        }
    },
    methods: {

        createPDF(){

            var pdf = new jsPDF("p", "mm", "a4");
            var element = document.getElementById('extract');
            html2canvas(element).then(canvas => {
                var image = canvas.toDataURL('image/png');
                
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                
                pdf.addImage(image, 'JPEG', 1, 1, width, height);
                pdf.save(this.fileName + '.pdf');
            });

        },

    }
}
</script>
