<template>
    <v-card class="elevation-0">
        <!-- <v-app-bar
            prominent
            height="40px"
        >
        
        <v-text-field
            v-model="search"
            prepend-inner-icon="search"
            x-small
            placeholder="Pesquisar" 
            rounded 
            single-line
            clearable
            autofocus
            
        ></v-text-field>

        </v-app-bar> -->

            <v-progress-linear
                class="ma-1"
                v-if="loading"
                indeterminate
                color="yellow darken-2"
            />

            <v-treeview
                v-model="model"
                ref="tree"
                :items="items"
                item-key="key"
                item-name="name" 
                return-object 
                item-children="children" 
                selectable
                activatable
                :open-all="false"
                :search="search"
                @input="selectItem($event[0])"
            />
        
    </v-card>
   
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysAmbienceTreeViewSelect",
    data: () => ({
      model: [],
      items: [],
      search: '',
      loading: false,
    }),
    
    computed: {

    },

    created() {
      this.getData();
    },
    
    watch: {
        
        search(val){
            
            this.search = val;

            if(val && val != ''){
                this.$refs.tree.updateAll(true);
            }else{
                this.$refs.tree.updateAll(false);
            }
            
        },
        active: {}
        
    },
    props: {
        
    },
    methods: {
        ...mapActions('ambience', ['ActionShowAmbiencesTree']),
        
        getData(){
    
            this.items = [];
            this.loading = true;

            this.ActionShowAmbiencesTree()
                .then((res) => {
                    this.items = res.data;
                }).finally(()=>{
                    this.loading = false;
                });
        },

        selectItem(){
            this.$emit('update:active', this.model);
        }

        
    }
}
</script>