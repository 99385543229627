
import { mapActions } from 'vuex'

export default {
  data: () => ({
    modeSendToken: false,
    snackbar: false,
    loading: false,
    loadingToken: false,
    dialog: false,
    tab: null,
    model: {},
    type: 'error',
    message: null,
    disabled: false,
    token: null,
    errors: [],
    model: {
      mode:         null,
      email:        null,
      mobile_phone: null,
      number_cpf:   null,
      birth_date:   null,
      token:        null
    },
    selectedItem: -1,
    items: [],
  }),
  created (){
      
  },
  watch: {
      
      
  },
  methods: {
    
    ...mapActions('auth', ['ActionAccessTeacher', 'ActionTokenTeacher', 'ActionDoLoginTeacher']),

    sendToken(){
        
        this.loading = true;
        this.snackbar = false;
        this.message = null;
        this.errors = [];
        this.model.mode = this.tab;
        delete this.model.key;
        
        if(this.tab == 'data' && this.selectedItem >= 0){
              this.model.key = {
                mode:   this.items[this.selectedItem].mode,
                key :   this.items[this.selectedItem].key,
              }        
        }

        this.ActionTokenTeacher(this.model).then((res) => {
            
            this.type = 'success';
            this.message = res.message;

            if(this.tab == 'data' && this.selectedItem == -1){
              this.loadData(res.data);
            }

        })
        .catch((error) =>{
          
          this.errors = error.errors;

          if(error.errors.length == 0){
            this.snackbar = true;
            this.message = error.message;
            this.type = 'error';
          }

        }).finally(() =>{
          this.loading = false;
          if(this.$refs.elToken){
            this.$refs.elToken.reset();
          }
        });
      
    },

    sendMail(){
        
      this.loading = true;
      this.snackbar = false;
      this.message = null;
      this.errors = [];
      this.model.mode = this.tab;
      
      this.ActionAccessTeacher(this.model).then((res) => {
          
          this.type = 'success';
          this.message = res.message;
          this.snackbar = true;

      })
      .catch((error) =>{
        
        this.errors = error.errors;

          if(error.errors.length == 0){
            this.snackbar = true;
            this.message = error.message;
            this.type = 'error';
          }
        

      }).finally(() =>{
        this.loading = false;
      });
    
  },

    loadData(data){

      this.items = [];
      this.selectedItem = -1;
      
      data.emails.forEach(email => {
        this.items.push({
          key: email.id,
          mode: 'email',
          icon: 'mdi-mail',
          text: email.email
        });
      });

      if(data.mobile_phone){
        this.items.push({
          key: 'mobile_phone',
          mode: 'phone',
          icon: 'mdi-cellphone-iphone',
          text: data.mobile_phone
        });
      }

      if(data.mobile_phone2){
        this.items.push({
          key: 'mobile_phone2',
          mode: 'phone',
          icon: 'mdi-cellphone-iphone',
          text: data.mobile_phone2
        });
      }

    },

    access(token){
      
      this.disabled = true;
      this.loadingToken = true;
      this.model.token = token;
      this.model.mode = this.tab;
      
      this.ActionDoLoginTeacher(this.model).then((res) => {
          this.type = 'success';
          this.message = res.message;
          this.$router.push({ path: '/availability-teacher', query: { token: res.data.token } });
      })
      .catch((error) =>{
        this.message = error.message;
        this.errors = error.errors;
        this.type = 'error';
      }).finally(() =>{
        this.snackbar = true;
        this.loadingToken = false;
        this.disabled = false;
      });
    },
  },
}