<template>
    <div>
        <emc-avatar-base 
            :src="model.url_avatar" 
            @onComplete="upload"
            :width="width"
            :height="height"
            :model="model"
        />
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "EmcAvatarUser",
    data() {
        return {
           
        };
    },
    props: {
        model: {
            required: true
        },
        width: {
            default: 100
        },
        height: {
            default: 100
        }
    },
    computed: {
      ...mapState('auth', ['acesso', 'user', 'menu'])
    },
    methods: {
        ...mapActions('user', ['ActionUserUploadAvatar']),
        ...mapActions('auth', ['ActionSetUser']),
        
        upload(form){

            form.append('id', this.model.id);
            
            this.ActionUserUploadAvatar(form)
                .then((res) => {
                   this.user.url_avatar = res.data.url_avatar;
                   this.ActionSetUser(this.user);
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    
                });

        },
    }
};
</script>