import { default as modules } from './module/services'
import { default as plan_types } from  './plan_type/services'
import { default as plan } from './plan/services'
import { default as charge_type } from './charge_type/services'

export default {
    ...modules,
    ...plan_types,
    ...plan,
    ...charge_type
}