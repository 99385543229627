import { service } from '../../../../helpers/http'

export const ActionIndexAllocations = ({ dispatch }, payload) => {

    return service({
        module: 'roomAllocation',
        action: 'indexAllocations',
        payload: payload
    })
    
}

export const ActionCreateAllocation = ({ dispatch }, payload) => {

    return service({
        module: 'roomAllocation',
        action: 'allocationCreate',
        payload: payload
    })
    
}

export const ActionUpdateAllocation = ({ dispatch }, payload) => {

    return service({
        module: 'roomAllocation',
        action: 'allocationUpdate',
        payload: payload
    })
    
}

export const ActionDestroyAllocation = ({ dispatch }, payload) => {

    return service({
        module: 'roomAllocation',
        action: 'allocationDelete',
        payload: payload
    })
    
}

export const ActionIndexAllocationsAll = ({ dispatch }, payload) => {

    return service({
        module: 'roomAllocation',
        action: 'indexAllocationsAll',
        payload: payload,
        idAndPayload: true
    })
    
}