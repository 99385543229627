import { service } from '../../../../helpers/http'

export const ActionDoLoginProcessTimeSheet = ({ dispatch }, payload) => {

    return service({
        module: 'timeSheet',
        action: 'auth',
        payload: payload
    })
    
}

export const ActionShowTimeSheet = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'showTimeSheet',
        payload: payload
    })

}

export const ActionLogout = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'logout',
        payload: payload
    })

}