<template>
    <div>
        <emc-avatar-base 
            :src="model.url_avatar" 
            @onComplete="upload"
            :width="width"
            :height="height"
            :model="model"
            :showMenu="showMenu"
        />
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: "EmcAvatarTeacher",
    data() {
        return {
           
        };
    },
    props: {
        model: {
            required: true
        },
        token: {
            required: true
        },
        width: {
            default: 100
        },
        height: {
            default: 100
        },
        showMenu: {
            type: Boolean,
            default: true
        }
    },

    watch: {
      
    },
    methods: {
        ...mapActions('teacher', ['ActionTeacherUploadAvatarToken', 'ActionTeacherUploadAvatarId']),
        
        upload(form){

            var method = this.token ? 'ActionTeacherUploadAvatarToken' : 'ActionTeacherUploadAvatarId';
            
            if(this.token){
                form.append('token', this.token);
            }else{
                form.append('id', this.model.id);
            }

            this[method](form)
                .then((res) => {
                    
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                   
                });

        },
    }
};
</script>