import { service } from '../../../../helpers/http'

export const ActionFindActivities = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'activities',
        payload: payload
    });
}

export const ActionCreateActivity = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'createActivity',
        payload: payload
    });
}

export const ActionUpdateActivity = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'updateActivity',
        payload: payload
    });
}

export const ActionDeleteActivity = ({ dispatch }, payload) => {
    
    return service({
        module: 'activity',
        action: 'deleteActivity',
        payload: payload
    });
}
