import { service } from '../../../../helpers/http'

export const ActionOfferItemsIndicatorEvolution = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indicatorEvolution',
        payload: payload
    })

}

export const ActionOfferItemsIndicatorHybridity = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indicatorHybridity',
        payload: payload
    })

}

export const ActionOfferItemsTableTotalUCDay = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'tableTotalUCDay',
        payload: payload
    })

}

export const ActionOfferItemsTableTotalUC = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'tableTotalUC',
        payload: payload
    })

}

export const ActionOfferItemsTableGroups = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'tableGroups',
        payload: payload
    })

}

export const ActionIndexOfferItemDays = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemDays',
        payload: payload
    })

}

export const ActionIndexOfferItemDisciplines = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemDisciplines',
        payload: payload
    })

}

export const ActionIndexOfferItemGroups = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemGroups',
        payload: payload
    })

}

export const ActionIndexOfferItemCampuses = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemCampuses',
        payload: payload
    })

}

export const ActionIndexOfferItemCourses = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemCourses',
        payload: payload
    })

}

export const ActionIndexOfferItemQualifiers = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemQualifiers',
        payload: payload
    })

}

export const ActionIndexOfferItemCategories = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemCategories',
        payload: payload
    })

}

export const ActionIndexOfferItemWorkTimes = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemWorkTimes',
        payload: payload
    })

}

export const ActionIndexOfferItemDaysFilter = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemOfferItemDaysFilter',
        payload: payload
    })

}

export const ActionIndexOfferItemBaseGroupStatusFilter = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemBaseGroupStatusFilter',
        payload: payload
    })

}

export const ActionIndexOfferItemOfferItemStatusFilter = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'indexOfferItemOfferItemStatusFilter',
        payload: payload
    })

}

export const ActionUpdateOfferItemAll = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'updateOfferItemAll',
        payload: payload
    })

}

export const ActionShowOfferItemInconsistencies = ({ dispatch }, payload ) => {

    return service({
        module: 'grid',
        action: 'showOfferItemInconsistencies',
        payload: payload
    })

}

