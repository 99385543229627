<template>
     
     <div>
        <EmcChartLoading v-if="loading" />

        <v-card v-show="!loading" class="pa-2">
            <canvas :id="name" :width="width" :height="height"></canvas>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-tooltip v-if="showMoreButton" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" color="primary" v-on="on" icon @click="$emit('showMore', item)">
                                <v-icon btn>mdi-monitor-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Ver Mais...
                        </span>
                    </v-tooltip>
            </v-card-actions>
        </v-card>
    </div>
    
</template>

<script>

import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { mapActions } from 'vuex'

export default {
    name: 'EmcChartTeacherBalance',
    data () {
        return {
            teachers: '0',
            loading: true,
            width: '400',
            height: '240',
            chartData: [],
            title: '',
            chartOptions: {
                responsive: true,
                    plugins: {
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart'
                    },
                    
                },
                aspectRatio: 5 / 3,
                // layout: {
                //     padding: {
                //         top: 24,
                //         right: 16,
                //         bottom: 0,
                //         left: 8
                //     }
                // },
                elements: {
                    line: {
                        fill: false
                    },
                    point: {
                        hoverRadius: 7,
                        radius: 5
                    }
                },
                scales: {
                        x: {
                            stacked: false
                        },
                        y: {
                            stacked: false
                    }        
                }
            }
        }
    },
    created(){
        
    },
    mounted() {
        this.getData();
    },
    props: {
        showMoreButton: {
            type: Boolean,
            default: false
        },
        item: {
            default: null
        },
        name: {
            type: String,
            default: 'TeacherBalance'
        },
        noLoad: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartTeacherBalance']),
        getData() {
            
            if(this.noLoad){
                this.chartData = this.item.data;
                this.chartOptions.plugins.title.text =  this.item.name;
                this.getChart();
                return;
            }
            
            this.ActionChartTeacherBalance()
            .then((r) => {
                this.chartData = r.data;
                this.chartOptions.plugins.title.text = r.data['title'];
            }).finally(() => {
                this.getChart();
            });
        },

        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }

            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            var speedCanvas = document.getElementById(this.name);
            new Chart(speedCanvas, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false
        }

    },
    

    
}
</script>