<template>
    <v-container>
         <v-row>
            <v-col cols="12" sm="12">
                <v-data-table
                    v-if="renderComponent"
                    :headers="headers"
                    :items="model.resources"
                    class="elevation-1"
                    :hide-default-footer="true"
                    :items-per-page="-1"
                    
                >
                    <template v-slot:top="{ on, attrs }">
                        <v-btn color="indigo darken-1" @click.prevent.stop="addItem()" icon v-bind="attrs" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                    </template>

                    <template dense v-slot:item.resource="{ item, index }">
                        <sys-select-resource
                            :model.sync="item.resource_id"
                            :error="errors.length > 0"  
                            :errors="errors['resources.' + index + '.resource_id']"
                            label=""
                            dense
                            auto-complete
                            item-text="name_complete"
                            :ambience-id="ambienceId"
                        />
                    </template>
                    
                    <template dense v-slot:item.amount="{ item, index }">
                        <v-text-field
                            v-model="item.amount"
                            :error="errors.length > 0"  
                            :error-messages="errors['resources.' + index + '.amount']"
                            dense
                        ></v-text-field>
                    </template>

                    <template dense v-slot:item.note="{ item, index }">
                        <v-text-field
                            v-model="item.note"
                            :error="errors.length > 0"  
                            :error-messages="errors['resources.' + index + '.note']"
                            dense
                        ></v-text-field>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            small
                            color="error"
                            @click.prevent.stop="deleteItem(item)"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                
                </v-data-table>
            </v-col>
         </v-row>
    </v-container>
</template>
<script>

import { mapActions } from 'vuex'

export default {
   name: 'AllocationResources',
   data: () => ({
        resources: [],
        renderComponent: true,
        headers: [
            {
                text: 'Recurso',
                align: 'start',
                sortable: false,
                value: 'resource',
                width: '50%'
            },
            { text: 'Qtde',  align: 'center', value: 'amount', sortable: false, width: '10%' },
            { text: 'Observação', value: 'note', sortable: false, width: '35%' },
            { text: '', value: 'actions', sortable: false,  width: '5%' },
        ],
    }),

    props: {
        model: {},
        errors: {},
        ambienceId: {},
    },

    watch: {
      
      

    },
    components: {
      
    },

    mounted(){
       
    },
    created() {
        
    },
    methods: {
        
        addItem(){
            
            var item = {
                allocation_id:  this.model.id ? this.model.id : 0,
                resource_id:    null,
                amount:         1,
                note:           null,
            };

            if(!this.model.resources){
                this.model.resources = [];
            }

            this.model.resources.push(item);

            this.forceRerender();
            
        },

        deleteItem(item){
            var index = this.model.resources.indexOf(item);
            this.model.resources.splice(index, 1);
            this.forceRerender();
        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },

  },

}
</script>
