export default [
    {
        path: '/dynamic-attribution-result',
        name: 'dynamic-attribution-result',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Resultado Atribuição',
            icon: 'mdi-puzzle-outline',
            breadcrumbs: [
                { text: 'Resultado Atribuição dinâmica', disabled: true, href: '/' },
                { text: 'Resultado Atribuição', disabled: true, href: '/' },
            ],
        }
    },
]