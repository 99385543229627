<template>
    <v-simple-table
        height="260px"
        fixed-header
        class="table-hour"
    >
        <template v-slot:top>
            
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <strong style="cursor: help" v-bind="attrs" v-on="on">{{ item ? item.name : (type == 'teacher' ? 'Horário do Professor' : 'Horário do Ambiente') }}</strong>
                </template>
                <span>{{ item ? item.name : (type == 'teacher' ? 'Selecione um Professor' : 'Selecione um Ambiente') }}</span>
            </v-tooltip>
            
            <v-progress-linear
                :indeterminate="loading"
                :color="loading ? 'cyan' : ''"
            />

        </template>
        <template v-slot:default>
            <thead>
                <tr style='height: 1px;'>
                    <th style='width: 1px;' ></th>
                    <th style='width: 1px;' class="text-center">Seg</th>
                    <th style='width: 1px;' class="text-center">Ter</th>
                    <th style='width: 1px;' class="text-center">Qua</th>
                    <th style='width: 1px;' class="text-center">Qui</th>
                    <th style='width: 1px;' class="text-center">Sex</th>
                    <th style='width: 1px;' class="text-center">Sáb</th>
                </tr>
            </thead>
            <tbody>
            <tr style='height: 1px' v-for="hour in hours" :key="hour.id" v-show="(shifts.includes(hour.shift_id) || showHours) && hasGroups(hour)">
                <td>
                    <small>
                        {{ hour.start }}
                        <br/>
                        {{ hour.end }}
                    </small>
                </td>
                <td
                     v-for="day in days"
                     :key="day"
                     :class="getClassAvailability(day, hour)"
                     @dblclick="selectGroup(day, hour)"
                     class="text-center"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                           <span style="cursor: help" :class="getClassText(day, hour)" v-on="on">
                                <v-chip v-if="getContent(day, hour)" color="transparent" class="ma-0 pa-0" label x-small outlined>
                                    <span class="ma-0 pa-0">
                                        <span class="black--text">{{ getContent(day, hour) }}</span>
                                        <v-icon v-if="getContentCount(day, hour) === 0" class="ml-1" color="primary" x-small>mdi-vector-polyline</v-icon>
                                        <v-icon v-else-if="getContentCount(day, hour) >= 1" class="ml-1" color="error" x-small>mdi-cancel</v-icon>
                                    </span>
                                </v-chip>
                            </span>
                        </template>
                        
                        <span v-for="(item, i) in getToolTip(day, hour)" :key="i">
                             {{ item }}
                             <br/>
                        </span>

                    </v-tooltip>
                </td>
            </tr>
            </tbody>
        </template>
        
    </v-simple-table>
</template>

<style scoped>
    .disponivel {
        background: repeating-linear-gradient(
            45deg,
            #adff2f,
            #adff2f 1px,
            #fff 2px,
            #fff 6px
        )
    }

    .table-hour tr:hover {
    background-color: transparent !important;
    }

    .table-hour td:hover {
        background-color: rgb(242, 248, 248);
    }
</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysScheduleHour",
    data: () => ({
      days: [ 2, 3, 4, 5, 6, 7 ]
    }),
    props: {
        
        title: {
            type: String,
            default: null
        },
        hours: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            default: null
        },
        group: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        shifts: {
            type: Array,
        },
        showHours: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            required: true
        }
    },
    methods: {
        
        hasGroups(hour){

            var hours = [];

            if(this.item){
                this.item.classes.forEach(c => {
                    if(!hours.includes(c.hour_id)){
                        hours.push(c.hour_id);
                    }
                });
            }
          
            if(this.group.hour_group_id){
                
                var find = hour.groups.find(g => g.id == this.group.hour_group_id);

                return find != null  || hours.includes(hour.id);

            }else{
                return false;
            }

        },

        getContent(day, hour){

            let result = this.getContentArray(day, hour);

            if(result){
                return result[0];
            }else{
                return '';
            }

        },

        getContentCount(day, hour){

            let result = this.getContentArray(day, hour);

            if(result){
                return result[1];
            }else{
                return null;
            }

        },
        
        getContentArray(day, hour){
            
            var classes = this.getClasses(day, hour);
                    
            if(classes && classes.length == 1){
                return [classes[0].campus, -1];
            }else if(classes && classes.length > 1){
                
                var join_id = 0;
                var count = 0;
                
                classes.forEach(classe => {
                   if(classe.join_id){
                       
                       if(join_id == 0){
                            join_id = classe.join_id;
                       }else{
                           if(join_id != classe.join_id){
                               count++;
                           }
                       }
                   }else{
                       count++;
                   }
                });

                let campus = classes[0].campus;
                return [campus, count];

            }else{
                return null;
            }
           
        },

        getToolTip(day, hour){
            
            var classes = this.getClasses(day, hour);
            var toolTips = [];
            
            if(classes){
               
               classes.forEach(classe => {
                    
                    var tip = classe.work_load_type  + ' - ';
                    tip += classe.group_name + ' | ' + classe.discipline_name;
                    
                    if(classe.periodicity_type_id != 1){
                        tip += ' (' + classe.periodicity_type + ')';
                    }

                    toolTips.push(tip);

                    

               });
                
                return toolTips;
                
            }else{
                return [];
            }
            
        },

        getClasses(day, hour){
            
            if(this.item && this.item.classes && this.item.classes.length > 0){
                    var classes = this.item.classes.filter(c => (c.day_of_week_id == day && c.hour_id == hour.id));
                    if(classes){
                        return classes;
                    }else{
                        return null;
                    }
            }else{
                return null;
            }
        },

        getClassText(day, hour){
            
            var classes = this.getClasses(day, hour);
            var template = { 'red--text': false, 'text-center' : true };
            
            
            if(classes){
                classes.forEach(c => {
                    if(this.group && c.group_id == this.group.id){
                        template = { 'red--text': true, 'text-center' : true };
                    }
                });
                
            }

            return template;
        },

        selectGroup(day, hour){
            
            var classes = this.getClasses(day, hour);

            if(classes){
                
                var classe = classes[0];

                if(classe.group_id != this.group.id){
                    
                    this.$emit('selectGroup', { 
                        id:             classe.group_id, 
                        name:           classe.group_name, 
                        shift_id:       classe.shift_id,
                        hour_group_id:  this.getHourGroupID(classe) })
                    };
            }
        },

        getHourGroupID(classe){

            if(classe.group_hour_group_id){
                return classe.group_hour_group_id;
            }else if(classe.category_hour_group_id){
                return classe.category_hour_group_id;
            }else{
                return classe.campus_hour_group_id;
            }

        },

        getClassAvailability(day, hour){

          var styleClass = {
              disponivel: false
          };

          if(this.item && this.item.availability){
            
            var disp = this.item.availability.find((d) => (this.isHourEqual({ id: d.hour_id, start: d.start, end: d.end}, hour) && d.day_of_week_id == day));

            if(disp){
                styleClass.disponivel = true;
            }
            else
              styleClass.disponivel = false;

            return styleClass;

          }else{
            return styleClass;
          }
        },

        getFirstName(name, length = 20){
            
            var index = name.indexOf(' ');
            var firstName = name;

            if(index != -1){
                firstName = name.split(' ')[0];
            }

            if(firstName.length > length)
                firstName = firstName.substring(0, length);

            return firstName;
        },
    }
}
</script>