<template>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <v-data-table
                  :headers="headers"
                  :items="model.dates"
                  hide-default-footer
                  :items-per-page="-1"
                  item-key="index"
                 
              >
                  <template v-slot:top="{ on, attrs }">
                      <v-btn 
                        :disabled="!model || !model.id"
                        color="indigo darken-1" 
                        @click.prevent.stop="addItem()" 
                        icon v-bind="attrs" 
                        v-on="on">
                        <v-icon>mdi-plus</v-icon></v-btn>
                  </template>

                  <template dense v-slot:item.name="{item, index}">
                      <v-text-field 
                        v-model="item.name" 
                        :error="errors.length > 0" 
                        :error-messages="errors['dates.' + index + '.name']" 
                        dense
                      />
                  </template>

                  <template dense v-slot:item.initials="{item, index}">
                      <v-text-field 
                        v-model="item.initials" 
                        dense 
                        :error="errors.length > 0"
                        :error-messages="errors['dates.' + index + '.initials']" 
                      />
                  </template>

                  <template dense v-slot:item.date="{ item, index }">
                      <emc-calendar-date-picker2 
                            :model.sync="item.date" 
                            label="" 
                            :error="errors.length > 0"
                            :error-messages="errors['dates.' + index + '.date']" 
                            :show="dialog"
                        />
                  </template>

                  <template dense v-slot:item.hour_start="{item, index}">
                      <emc-calendar-time-picker 
                        dense 
                        :error="!!errors.hour_start" 
                        :errors="errors['dates.' + index + '.hour_start']" 
                        :time.sync="item.hour_start" 
                        label=""
                        clearable
                      />
                  </template>

                  <template dense v-slot:item.hour_end="{item, index}">
                      <emc-calendar-time-picker 
                        dense 
                        :error="!!errors.hour_end" 
                        :errors="errors['dates.' + index + '.hour_end']" 
                        :time.sync="item.hour_end" 
                        label=""
                        clearable
                      />
                  </template>

                  
                  <template v-slot:item.actions="{ item, index }">
                      
                      <v-btn
                        @click.prevent.stop="deleteItem(item, index)"
                        :loading="loading && indexDelete == index"
                        :disabled="loading"
                        icon
                      >
                        <v-icon
                            small
                            color="error"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                      </v-btn>
                      
                      
                  </template>
                            
                </v-data-table>
        </v-col>
      </v-row>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysGridRegisterDates",
    data: () => ({
      fav: true,
      dates: [],
      dialog: false,
      loading: false,
      indexDelete: -1,
      headers: [
          { text: 'Descrição',  value: 'name',        sortable: false,  width: "15%" },
          { text: 'Sigla',      value: 'initials',    sortable: false,  width: "10%"},
          { text: 'Data',       value: 'date',        sortable: false,  width: "15%"},
          { text: 'Início',     value: 'hour_start',  sortable: false,  width: "10%"},
          { text: 'Fim',        value: 'hour_end',    sortable: false,  width: "10%"},
          { text: '',           value: 'actions',     sortable: false,  width: "2%"},
      ],
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {

        }
        
    },
    computed: {
      ...mapState('auth', ['acesso']),
    },
    watch: {
      
    },
    methods: {
      ...mapActions('grid', ['ActionDeleteGridDate']),
      
      deleteItem(item, index){
          
          if(!item.id){
            this.removeItem(item);
            return;
          }
          
          this.loading = true;
          this.indexDelete = index;
          
          this.ActionDeleteGridDate(item)
            .then((res) => {
                this.removeItem(item);
            })
            .catch((error) =>{
                
                
            })
            .finally(() => {
                  this.loading = false;
                  this.indexDelete = -1;
            });
            
      },

      removeItem(item){
          var index = this.model.dates.indexOf(item);
          this.model.dates.splice(index, 1);
      },

      addItem(){
          
          if(!this.model || !this.model.id){
            return;
          }
          
          var item = {
              index: this.model.dates.length,
              id: 0,
              work_time_id: this.acesso.periodo.id,
              name: null,
              initials: null,
              date: null,
              hour_start: null,
              hour_end: null,
          };

          this.model.dates.push(item);
          
      },
      
    }
}
</script>