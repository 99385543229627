<template>
    <v-dialog  v-model="dialog" scrollable max-height="auto" :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '55%'" persistent >
        <v-card 
                outlined
                height="85vh"
                prominent
                class="mx-auto elevation-0 overflow-y-auto"
                :loading='loading'
            >

            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="45" class='mb-4 elevation-1'>
                        <v-toolbar-title class="subtitle-1">
                            Filtros
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="clearFilter()" v-bind="attrs" v-on="on">
                                <v-icon color="primary lighten-2">mdi-filter-remove-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Limpar Filtro</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                <v-icon color="red lighten-2">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            
            <v-card-text  class="pt-2">
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="7">
                            <sys-select-campus
                                :model.sync="campus_id"
                                label="Campus*"
                                auto-complete
                            />
                        </v-col>
                        <v-col cols="12" sm="5">
                            <sys-select-block
                                :model.sync="blocks"
                                label="Bloco"
                                multiple
                                selectAll
                                :campus-id="campus_id"
                                :disabled="campus_id == null"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <sys-select-ambience-type
                            :model.sync="types"
                            label="Tipo de Ambiente"
                            multiple
                            selectAll
                            :campus-id="campus_id"
                            :disabled="campus_id == null"
                        />
                      </v-col>
                    <v-col cols="12" sm="6">
                            <sys-select-work-load-type
                                :model.sync="workLoads"
                                label="Tipo de CH"
                                multiple
                                selectAll
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col  cols="12" sm="6">
                            <v-range-slider
                                v-model="capacity"
                                :min="0"
                                :max="500"
                                hint="Capacidade do Ambiente"
                                persistent-hint
                                class="align-center"
                                thumb-label="always"
                                dense
                            />
                        </v-col>
                        <v-col  cols="12" sm="6">
                            <v-range-slider
                                v-model="students"
                                :min="0"
                                :max="1000"
                                hint="Alunos"
                                persistent-hint
                                class="align-center"
                                thumb-label="always"
                                dense
                            />
                        </v-col>
                  </v-row>
                  <v-row>
                        <v-col  cols="12" sm="6">
                            <v-range-slider
                                v-model="balance"
                                :min="-1000"
                                :max="1000"
                                hint="Saldo"
                                persistent-hint
                                class="align-center"
                                thumb-label="always"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-switch class="ml-3" inset v-model="onlyMain" label="Apenas Turmas Principais"/>
                        </v-col>
                    <!-- <v-col cols="12" sm="6">
                        <v-switch class="ml-3" inset v-model="onlyExceeded" label="Saldo Negativo"/>
                    </v-col> -->
                  </v-row>
                </v-container>
            </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    color='success' 
                    @click.prevent.stop="filter()"
                    :disabled="disableButton"
                >
                    Filtrar
                    <v-icon right dark>mdi-filter-menu-outline</v-icon>
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "SysAmbienceFilterVacancyControl",
    data: (vm) => ({
        dialog: false,
        loading: false,
        campus_id: null,
        onlyMain: false,
        onlyExceeded: false,
        capacity: [0, 500],
        students: [0, 1000],
        balance: [-1000, 1000],
        blocks: [],
        types: [],
        workLoads: [],
    }),
    mounted() {
        
    },
    computed: {

        disableButton (){
            
            return !this.campus_id;

        },

    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {

        dialog() {
            this.$emit('update:show', this.dialog);
        },

        show(val) {
            
            this.dialog = val;

            if(val){
                this.updating = false;
            }
        },

    },
    methods: {

       filter(){

            var payload = {
                campus_id: this.campus_id,
                blocks: this.blocks.map(i => i.id),
                types: this.types.map(i => i.id),
                workLoads: this.workLoads.map(i => i.id),
                onlyMain: this.onlyMain,
                capacity: this.capacity,
                students: this.students,
                balance: this.balance,
            };
        
            this.$emit('filter', payload);

       },

       clearFilter(){

            this.campus_id = null;
            this.blocks = [];
            this.types = [];
            this.workLoads = [];
            this.capacity = [0, 500];
            this.students = [0, 1000];
            this.balance = [-1000, 1000];
            
            this.$emit('filter', null);

       },

    }
}
</script>