import { service } from '../../../helpers/http'

export const ActionFindBudgets = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'find',
        payload: payload
    })
}

export const ActionCreateBudget = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'create',
        payload: payload
    })
}

export const ActionUpdateBudget = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteBudget = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'delete',
        payload: payload
    })
}

export const ActionFindValueTypes = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'valueTypes',
        payload: payload
    })
}

export const ActionFindCostCenters = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'findCostCenter',
        payload: payload
    })
}

export const ActionCreateCostCenter = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'createCostCenter',
        payload: payload
    })
}

export const ActionUpdateCostCenter = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'updateCostCenter',
        payload: payload
    })
}

export const ActionDeleteCostCenter = ({ dispatch }, payload) => {
    return service({
        module: 'budget',
        action: 'deleteCostCenter',
        payload: payload
    })
}