<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-pause-circle-outline" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                    
                    <v-row>
                        <v-col cols="12" sm="7">
                    
                            <sys-select-teacher
                                :model.sync="model.teacher_id"
                                :error="errors.teacher_id && errors.length > 0" 
                                :data.sync="model.teacher" 
                                :errors="errors.teacher_id"
                                label="Professor*"
                                autoComplete
                                :disabled="teacher != null"
                                autofocus
                                dense
                            />

                        </v-col>
                        <v-col cols="12" sm="5">
                            <sys-select-license
                                :model.sync="model.license_id"
                                :error="errors.length > 0"  
                                :errors="errors.license_id"
                                label="Tipo de Licença*"
                                dense
                            /> 
                        </v-col>
                    
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">

                            <emc-calendar-date-picker2 
                                :model.sync="model.date_start" 
                                label="Início*" 
                                :errorMessages="errors.date_start"
                                :show="dialog"
                            />

                        </v-col>
                        
                        <v-col cols="12" sm="6">
                            <emc-calendar-date-picker2 
                                :model.sync="model.date_end" 
                                label="Fim"
                                :errorMessages="errors.date_end" 
                                :show="dialog"
                                showClear
                            />
                        </v-col>
                        
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-switch @click.prevent.stop="clickRemunerated" inset v-model="model.remunerated" label="Licença Remunerada"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-text-field code :error-messages="errors.code" v-model="model.code" label="Código"/>
                        </v-col>
                    </v-row>
                    </v-row>
                    <v-row v-if="model.remunerated">
                        <v-col cols="12" sm="4">
                            <sys-select-value-type
                                :model.sync="model.value_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.value_type_id"
                                label="Tipo de Valor*"
                                :filter="[1,2]"
                                dense
                            /> 
                        </v-col>

                        <v-col cols="12" sm="4">
                            <sys-select-payment-periodicity-type
                                :model.sync="model.payment_periodicity_type_id"
                                :error="errors.length > 0"  
                                :errors="errors.payment_periodicity_type_id"
                                label="Periodicidade*"
                                :filter="[1,2]"
                                dense
                            /> 
                        </v-col>

                        <v-col v-if="model.remunerated" cols="12" sm="4">
                            <v-text-field 
                                dense :error="!!errors.value" 
                                :error-messages="errors.value" 
                                v-model="model.value"
                                label="Valor"
                                />
                                
                        </v-col>
                        
                    
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error="!!errors.note" 
                                :error-messages="errors.note" 
                                v-model="model.note" 
                                label="Observação"
                                rows="3"
                                row-height="20"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                 />
                        </v-col>
                    </v-row>

            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterTeacherLicense",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};

                if(this.teacher){
                    this.model.teacher_id = this.teacher.id;
                    this.model.teacher = this.teacher;
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-pause-circle-outline"
        },
        title: {
            type: String,
            default: "Cadastro de Licenças"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        teacher: {
            type: Object,
            default: null
        }
    },
    methods:{
        ...mapActions('teacherLicense', ['ActionUpdateTeacherLicense', 'ActionCreateTeacherLicense']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, {institution_id: this.acesso.instituicao.id});

            if(edit) {
                this.ActionUpdateTeacherLicense(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateTeacherLicense(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

        clickRemunerated(){
            
            if(this.model.remunerated){
                
                if(!this.model.value_type_id){
                    this.model.value_type_id = 1;
                }

                if(!this.model.payment_periodicity_type_id){
                    this.model.payment_periodicity_type_id = 1;
                }
            }
        }

    }
}
</script>