<template>
    <sys-register-base 
        :loading="loading" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-container style="height: 250px;">
                <v-row
                    v-if="model"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        <strong>Descrição: </strong>{{ model.description }}
                        <br/>
                        <span v-if="model.shift"><strong>Turno: </strong>{{ model.shift.name }}</span>
                        <br/>
                        <span  v-if="model.campuses"><strong>Campus: </strong>{{ model.campuses.map(o => o['abbreviation']).join(',') }}</span>
                    </v-col>
                </v-row>
                <v-row
                    v-if="model"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        <v-btn 
                            color="amber" 
                            @click.prevent.stop="generate()" 
                            :loading="loading" :disabled="loading"
                        >
                            <template v-slot:loader>
                                <span>Gerando Horário...</span>
                            </template>
                            Gerar Horário
                            <v-icon right dark>mdi-flash</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    v-if="loading"
                    align-content="center"
                    justify="center"
                >
                    <v-col cols="6">
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="20"
                            striped
                        />
                    </v-col>
                </v-row>
            </v-container>

            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>

        <!-- <template v-slot:actions>
            <v-btn color="amber" @click.prevent.stop="generate()" :loading="loading" :disabled="loading">Gerar Horário<v-icon right dark>mdi-flash</v-icon></v-btn>
        </template> -->
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysRegisterDynamicTimeGenerate",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            this.dialog = show_new;
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-puzzle-outline"
        },
        title: {
            type: String,
            default: "Gerar Horário"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('dynamicGeneration', ['ActionDynamicTimeGenerate']),

        generate()
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            this.ActionDynamicTimeGenerate({ id: this.model.id })
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.errors;
                })
                .finally(() => {
                     this.loading = false;
                });
            
        },

    }
}
</script>