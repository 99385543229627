<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        :icon="icon" 
        v-bind:show.sync="dialog"
        maxWidth="50%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-course
                                :model.sync="model.course_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_id"
                                label="Curso*"
                                dense
                                autoComplete
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <sys-select-campus
                                :model.sync="model.campus_id"
                                :error="errors.length > 0"  
                                :errors="errors.campus_id"
                                label="Campus*"
                                dense
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-company
                                :model.sync="model.company_id"
                                :error="errors.length > 0"  
                                :errors="errors.company_id"
                                label="Empresa"
                                showClear
                                dense
                                autoComplete
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <sys-select-branch
                                :model.sync="model.branch_id"
                                :error="errors.length > 0"  
                                :errors="errors.branch_id"
                                label="Filial"
                                showClear
                                dense
                                autoComplete
                                :company-id="model.company_id"
                                :disabled="model.company_id == null"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <s-text-field :error-messages="errors.code_inep" v-model="model.code_inep" label="Código Inep" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <s-text-field :error-messages="errors.business_unit" v-model="model.business_unit" label="Unidade Negócio" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <sys-select-course-level
                                :model.sync="model.course_level_id"
                                :error="errors.length > 0"  
                                :errors="errors.course_level_id"
                                label="Nível de Curso"
                                show-clear
                                :company-id="model.company_id"
                                dense
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <s-text-field code dense :error-messages="errors.code" v-model="model.code" label="Código" />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysCourseInfoRegister",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-book-information-variant"
        },
        title: {
            type: String,
            default: "Informações do Curso"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('courseInfo', ['ActionUpdateCourseInfo', 'ActionCreateCourseInfo']),

        save(edit)
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            Object.assign(this.model, { institution_id: this.acesso.instituicao.id });

            if(edit) {
                this.ActionUpdateCourseInfo(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted');
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                     this.loading = false;
                })
            } else {
                this.ActionCreateCourseInfo(this.model)
                .then((res) => {
                    this.message = res.message;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) =>{
                    this.error      = error;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                   this.loading = false;
                })

            }
        },

    }
}
</script>