<template>
    <div>
        <EmcChartLoading v-if="loading" />

        <v-card v-show="!loading" class="pa-2">
            <canvas :id="name" :width="width" :height="height"></canvas>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { mapActions } from 'vuex'

export default {
    name: 'EmcChartBudget',
    data () {
        return {
            loading: true,
            width: '400',
            height: '240',
            name: 'BudgetClass',
            chartData: [],
            title: '',
            chartOptions: {
                responsive: true,
                    plugins: {
                    legend: {
                        position: 'bottom',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart'
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions('chart', ['ActionChartBudgetClass']),
        
        async getData() {
            await this.ActionChartBudgetClass()
                .then((r) => {
                    this.chartData = r.data;
                    this.chartOptions.plugins.title.text = r.data['title']
                })

            this.getChart()
        },

        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }
            
            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            var speedCanvas = document.getElementById(this.name)
            new Chart(speedCanvas, {
                    type: 'bar',
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false
        }
    },
    created() {
        this.getData()
    }
}
</script>