<template>
    <div class="ma-0 pa-0">
        <v-switch 
            v-model="innerModel"
            v-bind:value="value"
            v-on:change="$emit('input', !!$event)"
            :disabled="disabled" 
            :color="color" 
            :inset="inset"
            :loading="loading"
            :dense="dense"
            :error="errorMessages && errorMessages.length > 0"
            :error-messages="errorMessages" 
        >
            <template v-slot:label>
                <emc-info-help v-if="textHelper" title="Informações" :text="textHelper" />
                {{ label }}
            </template>
        </v-switch>
    </div>
</template>
<script>
export default {
    name: "SSwitch",
    data () {
        return {
            innerModel: null,
        }
    },
    created(){
        this.innerModel = this.value;
    },
    watch: {
        
        value(new_value) {
            this.innerModel = new_value;
        }

    },
    props: {
       value: {  },
       dense: {
            type: Boolean,
            default: false
       },
       inset: {
            type: Boolean,
            default: false
       },
       label: {
            type: String,
            default: null,
       },
       textHelper: {
            type: String,
            default: null,
       },
       color: {
            type: String,
            default: undefined,
       },
       disabled: {
            type: Boolean,
            default: false
       },
       loading: {
            type: Boolean,
            default: false
       },
       error: {
            type: Boolean,
            default: false
       },
       errorMessages: {
            type: Array,
       },
    },
}
</script>
