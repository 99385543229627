<template>
    <v-dialog v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : maxWidth" :persistent="persistent">
        <v-card>
            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense>
                    <v-icon left>{{ icon }}</v-icon>
                        <v-toolbar-title class="subtitle-1">
                            <slot name="title">{{ title }}</slot>
                        </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <slot name="tools"></slot>
                    <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon @click.native="dialog = false" v-bind="attrs" v-on="on">
                                    <v-icon color="red lighten-2">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            <v-card-text class="pt-2">
                <slot name="text" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: { type: Boolean, default: false },
        maxWidth: { type: String, default: '50%'},
        persistent: { type: Boolean, default: true },
        icon: { type: String, default: "mdi-alert" },
        title: { type: String, required: false }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }
    }
}
</script>