<template>
    <div>
        <EmcChartLoading v-if="loading" />

        <v-card v-show="!loading" class="pa-2">
            <canvas :id="name" :width="width" :height="height"></canvas>
        </v-card>

    </div>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: 'EmcChartBase',
    data (vm) {
        return {
            loading: true,
            width: '400',
            height: '400',
            name: 'EmcChartBase' + vm.index,
            chartData: [],
            chartOptions: {
                plugins: {
                    datalabels: {
                        color: '#ffffff',
                        font: {
                            weight: 'bold'
                        }
                    },
                    legend: {
                        position: 'right',
                        display: vm.data.legend,
                    },
                    title: {
                        display: true,
                        text: '',
                        padding: {
                            bottom: 20
                        }
                    }
                },
                aspectRatio: 5 / 3,
                layout: {
                    padding: {
                        top: 24,
                        right: 16,
                        bottom: 0,
                        left: 8
                    }
                },
                elements: {
                    line: {
                        fill: false
                    },
                    point: {
                        hoverRadius: 7,
                        radius: 5
                    }
                }      
            }
        }
    },

    mounted() {
        this.setData();
    },

    props: {
        data: {
            
        },
        index: {

        },
    },


    methods: {
        
        setData() {
            
            this.chartData = this.data;
            this.chartOptions.plugins.title.text = this.data.title;

            this.getChart();
        },

        getChart() {

            if(!document.getElementById(this.name)) {
                return;
            }

            Chart.register(ChartDataLabels);

            // Change default options for ALL charts
            Chart.defaults.set('plugins.datalabels', {
                color: '#999999',
                anchor: 'start',
                align: 'end'
            });

            
            var speedCanvas = document.getElementById(this.name)
            new Chart(speedCanvas, {
                    type: this.data.type,
                    data: this.chartData,
                    options: this.chartOptions
                });

            this.loading = false;
           
        },
        
    },
    
}
</script>