<template>
    <div>
    <v-simple-table
        height="500px"
        fixed-header
    >
       
        <template v-slot:top>
            
            <strong v-if="ambience">{{ ambience.name }}</strong>

            <v-progress-linear
                indeterminate
                :color="(loadingInternal || loading) ? 'yellow darken-2' : ''"
            />
            
        </template>

        <template v-slot:default>

            <thead>
               
                <tr>
                    <th>
                        <small><strong>Horário</strong></small>
                    </th>
                    <th 
                        v-for="day in days_index"
                        :key="day"
                        class="text-center"
                    >
                        {{ days_name[day] }}
                                               
                    </th>
                </tr>
            </thead>
            <tbody v-if="renderComponent">
                <tr v-show="showHour(hour)" v-for="(hour, i) in hours" :key="i">
                
                    <td>
                        <small>
                            {{ hour.start }}
                            <br/>
                            {{ hour.end }}
                        </small>

                    </td>
                            
                        <td
                            v-for="day in days_index"
                            :key="day"
                            class="text-left"
                            @drop="drop"
                            @dragover.prevent
                            :class="getClassCell(hour.id, (day + 1))"
                            
                        >
                                <v-card
                                    v-if="renderComponent && getClassesAmbience(hour.id, (day + 1)) && getClassesAmbience(hour.id, (day + 1)).ambience_id"
                                    transition="fab-transition"
                                    color='blue lighten-3'
                                    dark
                                    class="ma-1 black--text"
                                    height="20px"
                                    width="30px"
                                    @dblclick="dblclick(getClassesAmbience(hour.id, (day + 1)))"
                                >
                                    
                                    <v-card-actions>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    left
                                                    v-on="on"
                                                    color="error"
                                                    style="position: absolute; top: 3px;" 
                                                    @click="$emit('remove', getClassesAmbience(hour.id, (day + 1)))"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>
                                                Liberar Ambiente
                                                <br/>
                                                {{ getClassesAmbience(hour.id, (day + 1)).group_name + ' - ' + getClassesAmbience(hour.id, (day + 1)).discipline_name }}
                                            </span>
                                        </v-tooltip>
                                    </v-card-actions>

                                </v-card>
                                
                                <v-card
                                    v-if="renderComponent && getClassesGroup(hour.id, (day + 1))"
                                    transition="fab-transition"
                                    color='amber lighten-3'
                                    dark
                                    class="ma-1 black--text"
                                    height="20px"
                                    width="30px"
                                    @dblclick="dblclick(getClassesGroup(hour.id, (day + 1)))"
                                >

                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-card-text v-on="on" v-bind="attrs">
                                            </v-card-text>
                                        </template>
                                        <span>{{ getTextDiscipline(hour.id, (day + 1)) }} </span>
                                    </v-tooltip>
                                   
                                    <v-card-actions>
                                        <v-tooltip v-if="getClassesGroup(hour.id, (day + 1)).ambience_id" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                    small
                                                    left
                                                    v-on="on"
                                                    color="error"
                                                    style="position: absolute; top: 3px;"
                                                    @click="$emit('remove', getClassesGroup(hour.id, (day + 1)))"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>
                                                Liberar Ambiente
                                                <br/>
                                                {{ getTextAmbience(hour.id, (day + 1)) }}
                                            </span>
                                        </v-tooltip>
                                    </v-card-actions>
                                </v-card>
                          
                        </td>
                </tr>
           </tbody>
           <tfoot v-if="false">
                    <tr>
                        <td colspan="2" class="ma-1" style="background-color: #FFE082;"><strong>Turma</strong></td>
                        <td colspan="2" class="ma-1" style="background-color: #90CAF9;"><strong>Ambiente</strong></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <!-- <td colspan="7" class="text-center">
                            <sys-ambience-legend-link/>
                        </td> -->
                        <!-- <td colspan="2" class="text-center">
                            <v-card
                                    transition="fab-transition"
                                    color='amber lighten-3'
                                    dark
                                    class="ma-1 black--text"
                            >
                            <v-card-text>Turma</v-card-text>
                            </v-card>
                        </td>
                        <td colspan="2" class="text-center">
                            <v-card
                                    transition="fab-transition"
                                    color='primary lighten-3'
                                    dark
                                    class="ma-1 black--text"
                            >
                            <v-card-text>Ambiente</v-card-text>
                            </v-card>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td> -->
                    </tr>
                </tfoot>
        </template>
        
    </v-simple-table>
    
    </div>
</template>

<style scoped>
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th, td {
        width: 30px !important;
    }
    .liberado {
        background: repeating-linear-gradient(
            45deg,
            #adff2f,
            #adff2f 1px,
            #fff 2px,
            #fff 6px
        )
    }

    .ocupado {
        background: repeating-linear-gradient(
            45deg,
            #f50505,
            #f50505 1px,
            #fff 2px,
            #fff 6px
        )
    }

    .mesmaDisciplina {
        background: repeating-linear-gradient(
            45deg,
            #4212f1,
            #4212f1 1px,
            #fff 2px,
            #fff 6px
        )
    }

</style>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysAmbienceHoursLink",
    data: () => ({
      days_name: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      days_index: [1,2,3,4,5,6],
      hours: [],
      shifts: [],
      hourClassesGroup: [],
      hourClassesAmbience: [],
      itemStart: {},
      loadingInternal: false,
      
    }),
    
    mounted(){
       this.getData();
    },
    created() {
      
    },
    computed: {
       
    },
    watch: {
        group(val){
            
            if(!val || !val.classes){
                return;
            }
            
            this.hourClassesGroup = [];

            val.classes.forEach(classe => {
                
                if(!this.hourClassesGroup.includes(classe.hour_id)){
                    this.hourClassesGroup.push(classe.hour_id);
                }

            });
        },

        ambience(val){
            
            if(!val || !val.classes){
                return;
            }
            
            this.hourClassesAmbience = [];

            val.classes.forEach(classe => {
                
                if(!this.hourClassesAmbience.includes(classe.hour_id)){
                    this.hourClassesAmbience.push(classe.hour_id);
                }

            });
        },
    },
    props: {
        
        title: {
            type: String,
            default: 'Titulo'
        },
        renderComponent: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        group: { },
        ambience: { },
       
    },
    methods: {
        ...mapActions('hour', ['ActionGetHours']),

        getData(){
        
            var payload = {
                with: 'shift'
            };

            this.loadingInternal = true;
            
            this.ActionGetHours(payload)
                .then((res) => {
                    this.hours = res.data;
                })
                .finally(() => {
                    this.loadingInternal = false;
                });
        },

        showHour(hour){

            return this.hourClassesGroup.includes(hour.id) || this.hourClassesAmbience.includes(hour.id);

        },


        getTextAmbience(hour, day){

            var classe = this.getClassesGroup(hour, day);

            if(classe){
                
                var text = '';
                
                if(classe.block_name){
                    text =  classe.block_name + ' - ' + classe.ambience_number;
                }else{
                    text =  classe.ambience_number;
                }

                if(classe.ambience_description){
                    text += ' - ' + classe.ambience_description;
                }

                return text;
            }

            return '';

        },

        getToolTip(hour, day){
            
            return '';
            
        },

        getClassesAmbience(hour, day){
            
            if(!this.ambience || !this.ambience.classes){
                return null;
            }
            
            var classes = this.ambience.classes.find(c => ( c.day_of_week_id == day && c.hour_id == hour ));

            if(classes){
                return classes;
            }else{
                return null;
            }
        
        },

        getTextDiscipline(hour, day){
            var classe = this.getClassesGroup(hour, day);
            if(classe){
                return classe.group_name + ' - ' + classe.discipline_name;
            }else{
                return null;
            }
        },

        getClassesGroup(hour, day){
            
            if(!this.group || !this.group.classes){
                return null;
            }
            
            var classes = this.group.classes.find(c => ( c.day_of_week_id == day && c.hour_id == hour ));

            if(classes){
                return classes;
            }else{
                return null;
            }
        
        },

        getClassCell(hour, day){
            
            var classCell = {
                liberado: false,
                ocupado: false,
                mesmaTurma: false
            };
            
            var classesGroup = this.getClassesGroup(hour, day);
            var classesAmbience = this.getClassesAmbience(hour, day);

            if(classesGroup && !classesAmbience){
                classCell.liberado = true;
            }else if(classesGroup && classesAmbience){
               
                if(classesGroup.grid_id == classesAmbience.grid_id){
                    classCell.mesmaDisciplina = true;
                }else{
                    classCell.ocupado = true;
                }
            }

            return classCell;
        },

        start(classe){

            this.$emit('start', [ classe.id ]);

        },

        drop(ev){
            this.$emit('drop');
        },

        dblclick(classe){
            
            this.$emit('dblclick', null);
           
        }
        
    }
}
</script>