<template>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="mr-2" min-width="0" icon v-bind="attrs" v-on="on">
            <v-badge :color="color" overlap :value="messages.filter((m)=>{ return !m.read }).length" :content="messages.filter((m)=> { return !m.read }).length">
              <v-icon>{{ icon }}</v-icon>
            </v-badge>
          </v-btn>
        </template>


        <v-list nav dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in messages" :key="i">
              <v-list-item-icon>
                <v-icon>{{ item.read ? iconRead : icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" v-bind:class="{ 'font-weight-bold': !item.read }"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-item>
              <v-list-item-content>
                <v-btn color="primary" right small text>
                  Ver todos
                </v-btn>
              </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>

export default {
    name: "BadgeNotification",
    data () {
        return {
            messages: this.collection,
            selectedItem: ''
        }
    },
    props: {
        color: {
            type: String,
            default: "green"
        },
        icon: {
            type: String,
            default: "mdi-email"
        },
        iconRead: {
            type: String,
            default: "mdi-email-open-outline"
        },
        collection: {
            type: Array,
            default: () => ([])
        }
    }
}
</script>