import { service } from '../../../../helpers/http'

export const ActionIndexOccurrenceSheets = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'indexOccurrenceSheets',
        payload: payload
    })

}
export const ActionDestroyOccurrenceSheet = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'destroyOccurrenceSheet',
        payload: payload
    })
}
export const ActionStoreOccurrenceSheet = ({ dispatch }, payload ) => {
    return service({
        module: 'timeSheet',
        action: 'storeOccurrenceSheet',
        payload: payload
    })

}
export const ActionUpdateOccurrenceSheet = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'updateOccurrenceSheet',
        payload: payload
    })
}

export const ActionIndexOccurrenceSheetStatus = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'indexOccurrenceSheetStatus',
        payload: payload
    })

}

export const ActionIndexOccurrenceSheetTypes = ({ dispatch }, payload ) => {

    return service({
        module: 'timeSheet',
        action: 'indexOccurrenceSheetTypes',
        payload: payload
    })

}

export const ActionStoreOccurrenceSheetAll = ({ dispatch }, payload ) => {
    return service({
        module: 'timeSheet',
        action: 'storeOccurrenceSheetAll',
        payload: payload
    })

}