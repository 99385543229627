export default [
    {
        path: '/integration-shifts',
        name: 'integration-shifts',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Integração',
            icon: 'mdi-timeline-clock-outline',
            breadcrumbs: [
                { text: 'Turnos', disabled: true }
            ]
        }
    }
]