import { service } from '../../../../helpers/http'

export const ActionFindIndicatorAvailabilities = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'controlPanelIndicatorAvailabilities',
        payload: payload
    })

}

export const ActionFindIndicatorIndications = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'controlPanelIndicatorIndications',
        payload: payload,
        idAndPayload: true,
    })

}

export const ActionFindIndicatorTimeTable = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'controlPanelIndicatorTimeTable',
        payload: payload
    })

}

export const ActionFindIndicatorAmbiences = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'controlPanelIndicatorAmbiences',
        payload: payload
    })

}

export const ActionExcelImport = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'excelImport',
        payload: payload
    })
    
}

export const ActionExportEmail = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'exportEmail',
        payload: payload
    })
    
}

export const ActionExportDownloadOptimized = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'exportDownloadOptimized',
        payload: payload
    })
    
}

export const ActionExportDownload = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'download',
        payload: payload
    })
    
}

export const ActionExportPdf = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'exportPdf',
        payload: payload,
        idAndPayload: true,
        unformatResponse: true,
    })
    
}

export const ActionUpload = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'upload',
        payload: payload,
    })
    
}

export const ActionUploadToken = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'uploadToken',
        payload: payload,
    })
    
}

export const ActionIndicatorAttributionsRefresh = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'indicatorAttributionsRefresh',
        payload: payload,
    })
    
}

export const ActionIndicatorTimeTableRefresh = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'indicatorTimeTableRefresh',
        payload: payload,
    })
    
}

export const ActionIndicatorAmbiencesRefresh = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'indicatorAmbiencesRefresh',
        payload: payload,
    })
    
}

export const ActionJoinStudentsRefresh = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'joinStudentsRefresh',
        payload: payload,
    })
    
}

export const ActionIndicatorAvailabilityRefresh = ({ dispatch }, payload) => {
    
    return service({
        module: 'controlPanel',
        action: 'indicatorAvailabilityRefresh',
        payload: payload,
    })
    
}