<template>
    <v-col cols="12">
        <EmcCardStats 
            :color="data.color"
            :icon="data.icon"
            icon-large
            :title="data.title"
            :value="data.value"
            :sub-text="data.description"
        >
            <template v-slot:actions>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :loading="loading" color="primary" icon v-bind="attrs" v-on="on" :disabled="!data.value" @click="$emit('filterChart', data.title)"><v-icon small>mdi-filter-outline</v-icon></v-btn>
                    </template>
                    <span>Filtrar</span>
                </v-tooltip>
            </template>
        </EmcCardStats>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'SingleCard',
    data () {
        return {
            
        }
    },
    props: {
        data: {
            required: true,
        },
        icon: {
            type: String,
            default: 'mdi-bank'
        },
        color: {
            type: String,
            default: 'green lighten-1'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    
}
</script>