export default [
    {
        path: '/plan-types',
        name: 'plan-types',
        component: () => import ('./pages/PlanTypeIndex'),
        meta: {
            title: 'Tipos de planos',
            icon: 'mdi-format-list-bulleted-type',
            breadcrumbs: [
                { text: 'Lista dos tipos de planos', disabled: true }
            ]
        }
    }
]