export default [
    {
        path: '/event-areas',
        name: 'event-areas',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Área de Eventos',
            icon: 'mdi-chart-timeline',
            breadcrumbs: [
                { text: 'Área de Eventos', disabled: true }
            ]
        }
    }
]