<template>
    <sys-register-base 
        :loading="loading" 
        :disabled="disabled"
        @onSave="save" 
        :model.sync="model" 
        title="Selecionar a data aula" 
        icon="mdi-clock-outline" 
        v-bind:show.sync="dialog"
        maxWidth="30%"
        :showRequiredFields="false"
        :showButton="false"
    >
        <template v-slot:form>
            <v-row align="center">
                <v-date-picker
                    v-if="model"
                    v-model="model.start_date"
                    year-icon="mdi-calendar-blank"
                    :allowed-dates="allowedDates"
                    :events="arrayEvents"
                    event-color="error"
                    full-width
                    :min="acesso.periodo.classes_start"
                    :max="acesso.periodo.classes_end"
                ></v-date-picker>
            </v-row>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
        <template v-slot:actions>
            <v-btn color="primary" @click.prevent.stop="save()" :loading="loadingDestroy" :disabled="disabled || model.start_date == null" >Salvar<v-icon dark>mdi-content-save</v-icon></v-btn>
        </template>
    </sys-register-base>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: "SysRegisterTimeTableDate",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        disabled: false,
        holidays: [],
        loadingDestroy: false,
        arrayEvents: [],
        
    }),
    created(){
        
    },
    computed: {
        ...mapState('auth', ['acesso']),
      },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.error = false;
                this.errors = {};

                if(this.holidays.length == 0){
                    this.getHolidays();
                }

            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-format-list-text"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('calendar', ['ActionIndexCalendarHolidays']),

        getHolidays(){
            
            this.holidays = [];
            this.arrayEvents = [];
            
            this.ActionIndexCalendarHolidays()
                    .then((res) => {
                        
                        this.holidays = res.data;

                        this.holidays.forEach(h => {
                            this.arrayEvents.push(h.date);
                        })

                    })
                    .catch((error) => {
                       
                    })
                    .finally(() => {
                        
                    })
        },

        allowedDates(val){
            
            if(this.model){
                let date = new Date(val);
                return (date.getDay() == this.model.day_of_week_id - 2) && !this.arrayEvents.includes(val);

            }else{
                return true;
            }
        },
        
        save()
        {
            this.model.end_date = this.model.start_date;
            this.$emit('save', this.model);
        },

    }
}
</script>