<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus</th>
                        <th>Curso</th>
                        <th>Turno</th>
                        <th>Turma</th>
                        <th>Disciplina</th>
                        <th>Carga Horária</th>
                        <th>CH Paga</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="grid in grids" :key="grid.id">
                        <td>{{ grid.group.campus.abbreviation }}</td>
                        <td>{{ grid.group.course.name }}</td>
                        <td>{{ grid.group.shift.name }}</td>
                        <td>
                            {{ grid.group.name}}
                            <span v-if="renderComponent && grid.join">
                                 <emc-info-help icon="mdi-call-split" :title="'Junção: ' + grid.join.join_id">
                                        <template v-slot:text>
                                            <ul type="none">
                                                <li :key="grid.id" v-for="grid in grid.join.grids">
                                                    {{ grid.group.name + ' | ' + grid.discipline.name }}
                                                </li>
                                            </ul>
                                        </template>
                                </emc-info-help>
                            </span>
                        </td>
                        <td>
                            {{ grid.discipline_name }}
                            <small v-if="grid.subGroup"><br/>{{ grid.subGroup.name }}</small>
                        </td>
                        <td>{{ grid.work_load }} ({{ grid.work_load_type.initials}})</td>
                        <td class="justify-center">{{ grid.work_load_pay ? grid.work_load_pay : grid.work_load }}</td>
                        
                    </tr>
                </tbody>
                <tfoot v-if="grids.length > 0">
                    <tr>
                        <td colspan="6" class="text-right">Total:</td>
                        <td class="justify-center"><strong>{{ parseFloat(workLoadPay).toFixed(2) }}</strong></td>
                    </tr>
                    <tr v-if="!hideJoins && gridListJoins.length > 0">
                        <td colspan="7">
                            <v-icon mr-2 x-small color="indigo">mdi-call-split</v-icon>
                            <strong class="text-center ma-0">Disciplinas com Junção:</strong>
                            <ul type="none">
                                <li :key="i" v-for="(grid, i) in gridListJoins">
                                    {{ grid }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherIndication',
    data: function() {
        return {
            grids: [],
            workLoadPay: 0,
            loading: true,
            renderComponent: true,
            gridListJoins: [],
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        token: {
            type:   String,
            default: null
        },
        hideJoins: {
            type:   Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeacher']),
        ...mapActions('availability', ['ActionGetTeacherByTokenAttribution']),
        ...mapActions('join', ['ActionFindJoinByGroupId', 'ActionFindJoinByGroupIdToken']),

        getData() {

            this.grids = [];
            this.workLoadPay = 0;
            this.gridListJoins = [];
            
            if(this.teacher.id === undefined) {
                return []
            }

            this.loading = true;
            var method = this.token ? 'ActionGetTeacherByTokenAttribution' : 'ActionFindTeacher';

            var params = {
                with: 'grids.join,grids.subGroup,grids.discipline,grids.group.course,grids.group.campus,grids.group.shift,grids.workLoadType',
                showAllCampuses: 'true'
            }

            if(this.token){
                params.token = this.token;
            }else{
                params.id = this.teacher.id;
            }

            this[method](params)
                .then((r) => {
                    
                    var grids = r.data.grids;
                    
                    if(grids && grids.length > 0){
                        this.grids = grids.filter((grid) => {
                       
                            if(grid.join) {
                                return (grid.join.main === true)
                            } else {
                                return true
                            }

                        });
                    }else{
                        this.grids = [];
                    }
                    
                    this.loadJoins(this.grids);
                    this.sumWorkLoads(this.grids);

                })
                .finally(()=> {
                    this.loading = false
                })
        },

        sumWorkLoads(grids){

            this.workLoadPay = 0;
            var sum = 0;
            
            grids.forEach(grid => {
                var result = grid.work_load_pay ? grid.work_load_pay : grid.work_load;
                sum += parseFloat(result);
            });

            this.workLoadPay = sum.toFixed(2);

        },

        loadJoins(grids){

            grids.forEach(grid => {
                this.loadJoin(grid);
            });

        },

        loadJoin(grid){

            if(!grid.join){
                return;
            }

            var method = this.token ? 'ActionFindJoinByGroupIdToken' : 'ActionFindJoinByGroupId';
            var payload;
            
            if(this.token){
                payload = {
                    id: grid.group_id,
                    token: this.token,
                }
            }else{
                payload = {
                    group_id: grid.group_id,
                    showAllCampuses: true,
                    with: "grids.join,grids.discipline,grids.group"
                }
            }
           
            this[method](payload).then((res) => {
                   
                var join = res.data.find(j => j.id == grid.join.join_id);
                
                if(join){
                    grid.join.grids = join.grids;
                }

                var text = grid.discipline.name + ' (' + grid.work_load_type.initials + '): ';
                    
                    var textGroups = '';
                    
                    join.grids.forEach(g => {
                        
                        if(textGroups.length > 0){
                            textGroups += ', ';
                        }
                        
                        textGroups += g.group.name;
                    });

                    var textGrid = text + textGroups;

                    if(!this.gridListJoins.includes(textGrid)){
                        this.gridListJoins.push(textGrid);
                    }

                this.forceRerender();
                
            });
        },
        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>