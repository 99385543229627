<template>
    <v-card class="elevation-0">
        <v-app-bar
            prominent
            height="40px"
            v-if="false"
        >
        
        <v-text-field
            v-model="searchInner"
            prepend-inner-icon="search"
            x-small
            placeholder="Pesquisar" 
            rounded 
            single-line
            clearable
            autofocus
            
        ></v-text-field>

        </v-app-bar>

            <v-progress-linear
                class="ma-1"
                v-if="loading"
                indeterminate
                color="yellow darken-2"
            />

            <v-treeview
                ref="tree"
                :active.sync="active"
                :items="items"
                :load-children="fetchItems"
                :open.sync="open"
                activatable
                item-children="children"
                :search="searchInner"
                color="warning"
                :open-on-click="true"
                transition
                item-key="key"
                return-object
                @update:active="selectItem($event[0])"
            >
                 
                 <template slot="label" slot-scope="{ item }">
                    <v-tooltip v-if="item.level == 4" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-on="on" v-bind="attrs">
                                {{ item.name }}
                            </span>
                        </template>
                        <span> 
                            {{ item.name }}
                            <br/>
                            Capacidade: {{ item.capacity  }} 
                        </span>
                    </v-tooltip>
                    <span v-else>{{ item.name }}</span>
                </template>

            </v-treeview>
        
        
    </v-card>
   
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "SysAmbienceTreeView",
    data: () => ({
      campuses: [],
      active: [],
      open: [],
      searchInner: '',
      loading: false,
    }),
    computed: {
       
       items () {
            return this.campuses;
      },
      

    },

    created() {
      this.getCampuses();
    },
    
    watch: {
        
        search(val){
            
            this.searchInner = val;

            if(this.autoUpdateAll){
                if(val && val != ''){
                    this.$refs.tree.updateAll(true);
                }else{
                    this.$refs.tree.updateAll(false);
                }
            }
        },
        
    },
    props: {
        loadData: {
            type: Boolean,
            default: false
        },
        showActions: {
          type: Boolean,
          default: false
        },
        search: {
            type: String,
            default: null
        },
        autoUpdateAll: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('campus', ['ActionFindCampusesFilterAmbience']),
        ...mapActions('block', ['ActionFindBlocksFilter']),
        ...mapActions('floor', ['ActionFindFloorsFilter']),
        ...mapActions('ambienceType', ['ActionFindAmbienceTypesFilter']),
        ...mapActions('ambience', ['ActionFindAmbiences']),
        
        async fetchItems (item) {
        
            switch (item.level) {
                case 0:
                    await this.getFloorsByCampus(item);
                    return await this.getBlocks(item);
                case 1:
                    return await this.getFloorsByBlock(item);
                case 2:
                    return await this.getTypes(item);
                case 3:
                    return await this.getAmbiences(item);
            }
        },
        
        getCampuses(){
    
            this.campuses = [];
            this.loading = true;

            this.ActionFindCampusesFilterAmbience()
                .then((res) => {
                
                    res.data.forEach(campus => {
                        this.campuses.push({
                            id: campus.id,
                            key: campus.id,
                            level: 0,
                            name: campus.name,
                            children: []
                        })
                    });
                }).finally(()=>{
                    this.loading = false;
                });
        },

        async getBlocks(campus) {
            
            var payload = {
                campus_id: campus.id,
            };

            return this.ActionFindBlocksFilter(payload)
                .then((res) => {
                    res.data.forEach(block => {
                        campus.children.push({
                            id:         block.id,
                            key:        campus.key + '|' + block.id,
                            campus_id:  campus.id,
                            level:      1,
                            name:       block.name,
                            children:   []
                        });
                    });
            })
        },

        async getFloorsByCampus(campus) {
            
            var payload = {
                campus_id: campus.id,
            };

            return this.ActionFindFloorsFilter(payload)
                .then((res) => {
                    res.data.forEach(floor => {
                        campus.children.push({
                            id:         floor.id,
                            key:        campus.key + '|0|' + floor.id,
                            campus_id:  campus.id,
                            block_id:   null,
                            level:      2,
                            name:       floor.name,
                            children:   []
                        });
                    });
            })
        },

        async getFloorsByBlock(block) {
            
            var payload = {
                campus_id: block.campus_id,
                block_id: block.id,
            };

            return this.ActionFindFloorsFilter(payload)
                .then((res) => {
                    res.data.forEach(floor => {
                        block.children.push({
                            id:         floor.id,
                            key:        block.key + '|' + floor.id,
                            campus_id:  block.campus_id,
                            block_id:   block.id,
                            level:      2,
                            name:       floor.name,
                            children:   []
                        });
                    });
            })
        },

        async getTypes(floor) {
            
            var payload = {
                campus_id:  floor.campus_id,
                block_id:   floor.block_id,
                floor_id:   floor.id,
            };

            return this.ActionFindAmbienceTypesFilter(payload)
                .then((res) => {
                    res.data.forEach(type => {
                        floor.children.push({
                            id:         type.id,
                            key:        floor.key + '|' + type.id,
                            campus_id:  floor.campus_id,
                            block_id:   floor.block_id,
                            floor_id:   floor.id,
                            level:      3,
                            name:       type.name,
                            children:   []
                        });
                    });
            })
        },

        async getAmbiences(type) {
            
            var payload = {
                find_columns: {
                    campus_id:          type.campus_id,
                    block_id:           type.block_id,
                    floor_id:           type.floor_id,
                    ambience_type_id:   type.id,
                    active:             1
                },
                filter_relations: [
                    {
                        name:   'type',
                        key:    'is_virtual', 
                        value:  0,
                    }
                ]
            };

            return this.ActionFindAmbiences(payload)
                .then((res) => {
                    res.data.forEach(ambience => {
                        type.children.push(
                            {
                                id:                 ambience.id,
                                key:                type.key + '|' + ambience.id,
                                campus_id:          type.campus_id,
                                block_id:           type.block_id,
                                floor_id:           type.floor_id,
                                type_id:            type.id,
                                block:              ambience.block,
                                floor:              ambience.floor,
                                campus:             ambience.campus,
                                type:               ambience.type,
                                slug_full:          ambience.slug_full,
                                level:              4,
                                name:               ambience.name + (ambience.description ? ' - ' + ambience.description : ''),
                                description:        ambience.description,
                                capacity:           ambience.capacity,
                                reduced_capacity:   ambience.reduced_capacity,
                                extended_capacity:  ambience.extended_capacity,
                                classes:            ambience.classes,
                                allocations:        ambience.allocations
                            }
                        );
                    });
            })
        },

        selectItem(item){
            
            if(!item || !item.id){
                return;
            }

            if(!this.loadData){
                this.$emit('select', item);
                return;
            }

            this.loading = true;
            
            var payload = {
                with: 'block,floor,type,campus,allocations.grid.discipline,allocations.grid.group',
                find_columns: {
                    id:  item.id,
                }
            };

            return this.ActionFindAmbiences(payload).then((res) => {
                var data = res.data;
                if(res.data.length > 0){
                    this.$emit('select', res.data[0]);
                }else{
                     this.$emit('select', item);
                }
                
            })
            .finally(()=>{
                this.loading = false;
            });
           
        }
        
    }
}
</script>