export default [
    {
        path: '/offer_grids',
        name: 'offer_grids',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Catálogo de Oferta',
            icon: 'mdi-view-grid-plus-outline',
            breadcrumbs: [
                { text: 'Catálogo de Oferta', disabled: true }
            ]
        }
    }
]