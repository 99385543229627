<template>
    <v-card class="mx-auto" >

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    {{ title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="subTitle">{{ subTitle }}</span>
                    <slot name="subtitle" />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-text>
                <v-row align="center">
                  <v-col
                  >
                      <v-progress-circular
                          :rotate="360"
                          :size="120"
                          :width="15"
                          :value="data.percent"
                          :color="color"
                        >
                          {{ data.percent + '%' }}
                      </v-progress-circular>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-list-item-subtitle>Concluídos: <strong>{{ data.completed }}</strong> / <strong>{{ data.total }}</strong> </v-list-item-subtitle>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <slot name="actions" />
              </v-card-actions>

          </v-card>
</template>

<script>
export default {
    name: "SysPainelTimeTable",
    data: () => ({
        
    }),
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            default: null
        },
        data: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            default: 'indigo darken-1'
        }
    },
}
</script>