<template>
    <v-dialog
          v-model="show"
          persistent
          width="300"
            >
              <v-card
                :color="color"
                dark
              >
                <v-card-text>
                  {{ text }}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
        </v-dialog>
</template>

<script>
export default {
    name: 'EmcLoaderProgress',
    data: () => ({
        dialog: true,
    }),
    props: {
        text: {
            type: String,
            default: 'Carregando dados...'
        },
        color: {
            type: String,
            default: 'primary'
        },
        show: {
            type: Boolean,
        },
    },
    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            if(show_new !== show_old) {
                this.dialog = show_new
            }
        }
    },
    
}
</script>