import * as types from './mutation-types'

export default {
  [types.SET_USER] (state, payload) {
    state.user = payload
  },
  [types.SET_TOKEN] (state, payload) {
    state.token = payload
  },
  [types.SET_ACESSO] (state, payload) {
    state.acesso = payload
  },
  [types.SET_HEADER] (state, payload) {
    state.header = payload
  },
  [types.SET_MENU] (state, payload) {
    state.menu = payload
  },
  [types.SET_HTTP_ERROR] (state, payload) {
    state.errorsHttp = payload
  }
}