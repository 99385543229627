<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-if="readonly"
                v-model="dateFormatted"
                :label="label"
                readonly
                v-bind="openOnClick ? attrs : null"
                v-on="openOnClick ? on : null"
                :dense="dense"
                :error-messages="errorMessages"
                @blur="date = parseDate(dateFormatted)"
                :disabled="disabled"
                :class="fieldClass"
                :outlined="outlined"
            >
                <template v-if="textHelper" v-slot:prepend-inner>
                    <emc-info-help title="Informações" :text="textHelper" />
                </template>
                
                <template v-slot:append>
                    
                    <v-tooltip v-if="showClear && dateFormatted" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="disabled" v-bind="attrs" v-on="on" small icon @click.prevent.stop="clear()">
                                <v-icon small btn>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Limpar
                        </span>
                    </v-tooltip>

                    <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabled"
                    >
                        event
                    </v-icon>

                </template>

            </v-text-field>
            <v-text-field
                v-else
                v-model="dateFormatted"
                :label="label"
                v-mask="'00/00/0000'"
                v-bind="openOnClick ? attrs : null"
                v-on="openOnClick ? on : null"
                :dense="dense"
                :error-messages="errorMessages"
                @blur="date = parseDate(dateFormatted)"
                :disabled="disabled"
                :class="fieldClass"
                :outlined="outlined"
            >
                <template v-slot:append>
                    
                    <v-tooltip v-if="showClear && dateFormatted" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="disabled" v-bind="attrs" v-on="on" small icon @click.prevent.stop="clear()">
                                <v-icon small btn>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            Limpar
                        </span>
                    </v-tooltip>

                    <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabled"
                    >
                        event
                    </v-icon>

                </template>

            </v-text-field>
        </template>
        <v-date-picker :min="min" :max="max" :dense="dense" v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false; date = null">Fechar</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date); $emit('change', date)">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
export default {
    name: "EmcCalendarDatePicker",
    data: (vm) => ({
        modal: false,
        date: vm.model,
        dateFormatted: vm.formatDate(vm.model),
    }),
    props: {
        label: {
            type: String,
            default: '',
            required: false
        },
        icon: {
            type: String,
            default: 'event'
        },
        model: {
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        fieldClass: {
            type: String,
            default: '',
            required: false
        },
        small: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        showClear: {
            type: Boolean,
            default: false
        },
        readonly: {
            type:    Boolean,
            default: true
        },
        openOnClick: {
            type:    Boolean,
            default: true
        },
        textHelper: {
            type: String,
            default: undefined
        },
        outlined: {
            type:    Boolean,
            default: false
        }
    },

    computed: {
        computedDateFormatted () {
             return this.formatDate(this.date);
        },
    },

    watch: {
        
        show(val){
            
            if(val && !this.model){
                this.dateFormatted = null;
            }else{
                this.dateFormatted = this.formatDate(this.model);
            }
        },
        
        modal(val) {
            
            if(!val){
                this.date = null;
                this.dateFormatted = null;
            }
        },
        
        date (val) {
            
            this.dateFormatted = this.formatDate(this.date);
            this.$emit('update:model', this.date);
            
        },
    },

    methods: {
        
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        
        parseDate (date) {
            if (!date) return null;

            if(date.length != 10){
                return null;
            }

            const [day, month, year] = date.split('/');
            var date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            var isDate = new Date(date);

            if(isDate != 'Invalid Date'){
                return date;
            }else{
                return null;
            }
           
        },

        clear(){
            this.date = null;
            this.dateFormatted = null;
            this.$emit('update:model', null);
        },
        
    }
}
</script>