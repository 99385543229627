<template>
    
        <sys-register-base 
                :loading="loading" 
                :model.sync="model" 
                :title="title" 
                :icon="icon"
                v-bind:show.sync="dialog"
                maxWidth="80%"
                :actions="false"
            >

            <!-- <template v-slot:tools>
                <v-btn v-if="!loading" icon small ><v-icon small color="red lighten-1" @click="$refs.extract.createPDF()">mdi-file-pdf-box</v-icon></v-btn>
            </template> -->

            
            <template v-slot:form>

                <v-progress-linear
                    indeterminate
                    color="blue"
                    class="mb-0"
                    v-if="loading"
                ></v-progress-linear>
                
                <emc-chart-general-campus-ambience :brand-id="brandId" :show.sync="showMore" /> 
                <emc-util-pdf ref='extract'>
                    <v-container>
                        <v-row>
                        <v-col cols="12" md="3" v-for="(item, i) in items.filter(i => (i.indication && i.indication.total > 0))" :key="i">
                            <emc-chart-ambience-of-work-time
                                @showMoreAmbiences="showChartCampuses" 
                                hide-sub-text 
                                show-more-button
                                hide-tools
                                :indicator="item.indication"
                                type="institution" 
                                :subtitle="item.name" 
                                :item-id="item.id"
                            />
                        </v-col>
                    </v-row>
                    </v-container>
                </emc-util-pdf>
            </template>
       

        </sys-register-base> 
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "EmcChartGeneralInstitutionAmbience",
    data: (vm) => ({
        dialog: false,
        loading: false,
        showMore: false,
        brandId: 0,
        items: [],
        itemsTemp: [],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                this.getData();
            }
            
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-monitor-eye"
        },
        title: {
            type: String,
            default: "Ambientes por Instituição"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    methods:{
      ...mapActions('campus', ['ActionFindCampuses']),
      ...mapActions('controlPanel', ['ActionFindIndicatorAmbiences']),

        getData(){

          if(this.loading){
            return;
          }
          
          this.loading = true;
          this.items = [];
          this.itemsTemp = [];

          let payload = {
            with: 'brand',
            withContract: true,
            find_columns: {
                id: this.acesso.campus.map(c => c.id).join(','),
            }
          };

          this.ActionFindCampuses(payload)
              .then((res) => {
                   
                let ids = [];

                res.data.forEach(cam => {

                    let brand = { id: cam.brand.id, name: cam.brand.name };

                    if(!ids.includes(brand.id)){
                        this.itemsTemp.push(brand);
                        ids.push(brand.id);
                    }
                });

                this.setIndicator();  

              })
              .catch((error) =>{
                  
              })
              .finally(() => {
                    
              });

        },

        setIndicator(){
      
                var payload = {
                    work_time_id:   this.acesso.periodo.id,
                    type:           'allBrands',
                };

                this.ActionFindIndicatorAmbiences(payload)
                    .then((res) => {
                        
                        let items = res.data;

                        this.itemsTemp.forEach(ies => {
                            ies.indication = items.find(i => i.brand_id == ies.id);

                        });

                        this.items = this.itemsTemp;
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        showChartCampuses(id){

            this.brandId = id;
            this.showMore = true;

        },
        
    }
}
</script>