import { service } from '../../../../helpers/http'

export const ActionIndexOccurrences = ({ dispatch }, payload) => {

    return service({
        module: 'timeSheet',
        action: 'indexOccurrences',
        payload: payload
    })
    
}

export const ActionCreateOccurrence = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'createOccurrence',
        payload: payload
    })
    
}

export const ActionUpdateOccurrence = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'updateOccurrence',
        payload: payload
    })
}

export const ActionDeleteOccurrence = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'deleteOccurrence',
        payload: payload
    })
    
}

export const ActionValidateOccurrence = ({ dispatch }, payload) => {
    
    return service({
        module: 'timeSheet',
        action: 'validateOccurrence',
        payload: payload
    })
}