import { service } from '../../../helpers/http'

export const ActionFindBanks = ({ dispatch }, payload) => {

    return service({
        module: 'bank',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateBank = ({ dispatch }, payload) => {
    
    return service({
        module: 'bank',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateBank = ({ dispatch }, payload) => {
    
    return service({
        module: 'bank',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteBank = ({ dispatch }, payload) => {
    return service({
        module: 'bank',
        action: 'delete',
        payload: payload
    })
}

