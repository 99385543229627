export default {
    
    find:               { method: 'get',    url: 'budgets' },
    create:             { method: 'post',   url: 'budgets'},
    update:             { method: 'put',    url: 'budgets/{id}'},
    delete:             { method: 'delete', url: 'budgets/{id}'},
    valueTypes:         { method: 'get',    url: 'value_types'},
    findCostCenter:     { method: 'get',    url: 'cost_centers' },
    createCostCenter:   { method: 'post',   url: 'cost_centers'},
    updateCostCenter:   { method: 'put',    url: 'cost_centers/{id}'},
    deleteCostCenter:   { method: 'delete', url: 'cost_centers/{id}'},
}