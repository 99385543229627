<template>
    <div>
        <sys-register-base 
            :model="{}"
            title="Professores" 
            icon="mdi-mailbox" 
            v-bind:show.sync="dialog"
            maxWidth="65%"
            :showRequiredFields="false"
            :showButton="false"
    >
        <template v-slot:tools>
            
            <emc-button-icon :disabled="data == null" icon="mdi-google-maps" text="Ver no mapa" color="purple" :href="source"/>
        </template>
        <template v-slot:form>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-progress-linear
                            v-if="loading"
                            indeterminate
                            color="yellow darken-2"
                        />
                        <v-simple-table fixed-header height="300" dense>
                            <template v-slot:default>
                                <caption>
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <s-switch
                                                    v-model="hasDiscipline"
                                                    @input="loadingDiscipline = true;loadData()"
                                                    text-helper='Exibir apenas professores que informaram possuir aderência pela disciplina' 
                                                    label="Aderência"
                                                    :loading="loadingDiscipline"
                                                    inset
                                                    class="ml-5"
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <s-switch
                                                    v-model="hasAvailability"
                                                    @input="loadingAvailability = true; loadData()"
                                                    text-helper='Exibir apenas professores com disponibilidade para lecionar a disciplina' 
                                                    label="Disponível"
                                                    :loading="loadingAvailability"
                                                    inset
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="distance"
                                                    :items="distances"
                                                    label="Distância"
                                                    return-object
                                                    @change="loadData()"
                                                    class="mr-3"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                        <v-col>
                                            <strong>Unidade: </strong>{{ data ? data.campus.name : null }}
                                            <br/>
                                            <small>{{ data ? data.address : null }}</small>
                                        </v-col>
                                    </v-row>
                                </caption>
                               
                                <thead>
                                    
                                    <tr>
                                        <th>Professor</th>
                                        <th>Distância</th>
                                        <th>Duração</th>
                                        <th style='text-align:center;'>Aderência</th>
                                        <th style='text-align:center;'>Disponível</th>
                                        <!-- <th>Endereço</th> -->
                                    </tr>
                                </thead>
                                <tbody v-if="data">
                                    <tr v-for="(item, index) in data.items" :key="index">
                                        <td>{{ item.teacher.name  }}</td>
                                        <td>{{ item.distance.text }}</td>
                                        <td>{{ item.duration.text }}</td>
                                        <td align="center" valign="center">
                                            <v-icon v-if="hasAttribution(item.teacher)" small color="success">mdi-check-circle-outline</v-icon>
                                            <v-icon v-else small color="error">mdi-cancel</v-icon>
                                        </td>
                                        <td align="center" valign="center">
                                            <v-icon v-if="item.teacher.hasAvailability && !item.teacher.hasTimeTable" small color="success">mdi-check-circle-outline</v-icon>
                                            <v-icon v-else-if="item.teacher.hasAvailability && item.teacher.hasTimeTable" small color="amber">mdi-alert-outline</v-icon>
                                            <v-icon v-else small color="error">mdi-cancel</v-icon>
                                        </td>
                                        <!-- <td><small>{{ item.address  }}</small></td> -->
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <!-- <template v-slot:actions>
            <emc-button-icon :icon="icon" text="Replicar Atribuição" color="light-green" @click.prevent.stop="showMessageReplicate = true" />
        </template> -->

    </sys-register-base>    
    </div>

</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysTeacherDistance",
    data: () => ({
        dialog: false,
        error: false,
        loading: false,
        workTime: null,
        data: null,
        hasDiscipline: true,
        hasAvailability: true,
        loadingDiscipline: false,
        loadingAvailability: false,
        distances: [
            { value: 10000, text: '10 km' },
            { value: 15000, text: '15 km' },
            { value: 20000, text: '20 km' },
            { value: 25000, text: '25 km' },
            { value: 30000, text: '30 km' },
            { value: 45000, text: '45 km' },
            { value: 50000, text: '50 km' },
            { value: 80000, text: '80 km' },
            { value: 100000, text: '100 km' },
        ],
        distance: { value: 10000, text: '10 km' }

    }),
    created(){
      
    },

    computed: {
        ...mapState('auth', ['token']),

        source: function() {
            
            if(this.grid && this.grid.group){
                var root = process.env.VUE_APP_ROOT;
                return root + 'teacher-maps/' + this.grid.group.campus_id + '?token=' + this.token;
            }else{
                return null;
            }
            
        },

  },
    
    watch: {
        
        dialog(val_new, val_old) {
            
            if(val_new !== val_old) {
                this.$emit('update:show', val_new)
            }

        },

        show(val_new, val_old) {
            
            this.dialog = val_new;

            if(val_new){
                this.loadData();
            }
        },
        
    },
    props: {
        
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        show: {
            type: Boolean,
            default: false
        },
        grid: {
            required: true,
        }
    },
    methods:{
        ...mapActions('address', ['ActionDistancesAddresses']),

        loadData(){

            this.loading = true;

            let payload = {
                campus_id: this.grid.group.campus_id,
                hasAvailability: this.hasAvailability,
                distance: this.distance
            };

            if(this.hasDiscipline){
                payload.grid_id = this.grid.id;
            }

            this.ActionDistancesAddresses(payload)
                .then((res) => {
                    this.data = res.data;
                })
                .catch((error) =>{
                   
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingDiscipline = false;
                    this.loadingAvailability = false;
                });

        },

        hasAttribution(teacher){

            var count = teacher.disciplines.find(d => d.id == this.grid.discipline_id);
            return count ? true : false;

        }

    }
}
</script>