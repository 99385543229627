<template>
        <div>
            <v-avatar
                v-if="renderComponent"
                class="mx-auto"
                color="grey lighten-4"
                :width="width"
                :height="height"
                @click.prevent="onShowDialog()"
                :style="(!showMenu && source) ? 'cursor: pointer;' : ''"
                :tile="tile"
            >
                <v-img
                    v-if="renderComponent && source"
                    width="100%"
                    height="100%"
                    :src="source"
                >
                </v-img>
                <v-icon v-else size="90px" class="ma-1">{{ iconDefault }}</v-icon>
            </v-avatar>
            <v-menu 
                v-if="showMenu"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="position: relative; bottom: -30px; left: -30px" 
                        v-bind="attrs"
                        v-on="on"
                        color="orange"
                        class="white--text ml-1"
                        fab
                        x-small
                        bottom
                    >
                        <v-icon small>mdi-camera</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="source" @click.prevent="dialog = true" link>
                        <v-list-item-icon>
                            <v-icon color="indigo">mdi-eye</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Ver Imagem</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="showCamera" @click.prevent="show = true" link>
                        <v-list-item-icon>
                            <v-icon color="amber">mdi-camera-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Câmera</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.prevent="onLoad" link>
                        <v-list-item-icon>
                            <v-icon color="success">mdi-folder-image</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Carregar</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click.prevent="onRemove()" v-if="source" link>
                        <v-list-item-icon>
                            <v-icon color="error">mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                    
                </v-list>
            </v-menu>
           

            <form v-show="false" ref="form">
                <v-file-input
                    v-if="forceRerender"
                    v-model="fileAvatar"
                    id="inputAvatar"
                    name="avatar"
                    @change="onFiledPicked"
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick an avatar"
                    prepend-icon="mdi-camera"
                    label="Avatar"
                />
            </form>

            <emc-avatar-capture :show.sync="show" @onSelect="onSelect" />

            <emc-avatar-show :model="model" :show.sync="dialog" :source="source" />

            <emc-alert-modal text="Confirma a exclusão da imagem?" v-bind:show.sync="showDelete">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="deleteImage()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                </template>
            </emc-alert-modal>
            
     </div>
</template>
<script>
export default {
    name: "EmcAvatarBase",
    data () {
      return {
        source: null,
        file: null,
        show: false,
        dialog: false,
        deleting: false,
        showDelete: false,
        renderComponent: true,
        fileAvatar: null,
        rules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
      }
    },
    created() {
        this.source = this.src;
    },
    watch: {
      
      src(val){
        this.source = val;
      },
      
    },
    props: {
        src: {
            required: true
        },
        width: {
            default: 100
        },
        height: {
            default: 100
        },
        model: {
            
        },
        showMenu: {
            type: Boolean,
            default: true
        },
        tile: {
            type: Boolean,
            default: false
        },
        showCamera: {
            type: Boolean,
            default: true
        },
        iconDefault: {
            type: String,
            default: 'mdi-account'
        }
    },
    methods: {
        
        onLoad(){
            var element = document.getElementById('inputAvatar');
            element.click();
        },

        onSelect(ev){
            
            this.source = ev;
            var instance = this;
            
            this.urltoFile(ev, null)
                .then(function(file){
                    let formData = new FormData();
                    formData.append('avatar', file);
                    instance.onComplete(formData);
                    instance.forceRerender();
                });

            this.forceRerender();
            
        },

        urltoFile(url, filename, mimeType){
            mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
            return (fetch(url)
                .then(function(res){return res.arrayBuffer();})
                .then(function(buf){return new File([buf], filename, {type:mimeType});})
            );
        },

        onFiledPicked(file) {

            if(!file){
                return;
            }

            let filename = file.name;

            if(filename.lastIndexOf('.') <= 0){
                return alert('Selecione uma imagem');
            }

            const fileRender = new FileReader();
            var instance = this;

            fileRender.addEventListener('load', () => {
                const image = fileRender.result;
                instance.source = image;
                instance.forceRerender();
            });

            fileRender.readAsDataURL(file);
            this.file = file;
            let formData = new FormData(this.$refs.form);
            this.onComplete(formData);
            this.forceRerender();
        },

        onComplete(formData){
            
            this.$emit('onComplete', formData);
            this.forceRerender();
        },

        onRemove(){
            this.showDelete = true;
        },

        deleteImage(){
            this.deleting = true;
            this.source = null;
            this.fileAvatar = null;
            let formData = new FormData();
            this.$emit('onComplete', formData);
            this.showDelete = false;
            this.deleting = false;
            this.forceRerender();
        },

    forceRerender () {
            
        this.renderComponent = false;

        this.$nextTick(() => {
            this.renderComponent = true;
        });

      },

      onShowDialog(){
          if(!this.showMenu && this.source){
              this.dialog = true;
          }
      }

      
    }
  }
</script>