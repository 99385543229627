import { service } from '../../../../helpers/http'

export const ActionIndexWorkLoadHistories = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'indexWorkLoadHistories',
        payload: payload
    })
    
}

export const ActionStoreWorkLoadHistory = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'storeWorkLoadHistory',
        payload: payload
    })
    
}

export const ActionUpdateWorkLoadHistory = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'updateWorkLoadHistory',
        payload: payload
    })
    
}

export const ActionDestroyWorkLoadHistory = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'destroyWorkLoadHistory',
        payload: payload
    })
}

export const ActionGenerateWorkLoadHistory = ({ dispatch }, payload) => {

    return service({
        module: 'controlPanel',
        action: 'generateWorkLoadHistory',
        payload: payload
    })
}


