<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormatted"
                :label="label"
                append-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
                :dense="dense"
                :error="errors.length > 0"
                :error-messages="errors"
                @blur="dates = parseDate(dateFormatted)"
                @click:clear="clear()"
                :clearable="clearable"
            >
                <template v-if="textHelper" v-slot:prepend-inner>
                    <emc-info-help title="Informações" :text="textHelper" />
                </template>

            </v-text-field>
        </template>
        <v-date-picker :min="min" :max="max" range v-model="dates" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cancel()">Fechar</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dates)">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>

export default {
    name: "EmcCalendarDatePickerRange",
    data: (vm) => ({
        modal: false,
        dates: [vm.model1, vm.model2],
        dateFormatted: vm.formatDate([vm.model1, vm.model2]),
        errors: [],
        isModel: false,
    }),
    props: {
        label: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'event'
        },
        model1: {
            required: true
        },
        model2: {
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
        errorMessages1: {
            type: Array,
            default: null
        },
        errorMessages2: {
            type: Array,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: undefined
        },
        max: {
            type: String,
            default: undefined
        },
        textHelper: {
            type: String,
            default: undefined
        }
    },

    created(){
       
    },

    mounted(){
       
    },

    computed: {
        computedDateFormatted () {
             return this.formatDate(this.dates);
        },
    },

    watch: {
        
        show(val){
            
            if(val && this.model1 && this.model2){
                this.dateFormatted = this.formatDate([this.model1, this.model2]);
                this.isModel = true;
            }else{
                this.dateFormatted = null;
                this.dates = null;
                this.errors = [];
                this.isModel = false;
            }
            
        },

        errorMessages1(val){
            
            this.errors = [];
            
            if(val){
                
                val.forEach(element => {
                    this.errors.push(element);
                });
            }
        },

        errorMessages2(val){
            
            this.errors = [];
            
            if(val){
                
                val.forEach(element => {
                    this.errors.push(element);
                });
            }
            
        },
        
        dates (dates) {
            
            if(dates && dates.length > 0){
                
                if(dates.length == 1){
                    this.$emit('update:model1', dates[0]);
                }else{
                    
                    var date1 = new Date(dates[0]);
                    var date2 = new Date(dates[1]);

                    if(date1 < date2){
                        this.$emit('update:model1', dates[0]);
                        this.$emit('update:model2', dates[1]);
                        this.dateFormatted = this.formatDate(dates);
                    }else{
                        this.$emit('update:model1', dates[1]);
                        this.$emit('update:model2', dates[0]);
                        this.dateFormatted = this.formatDate([dates[1], dates[0]]);
                    }
                }
            }

        },
    },

    methods: {
        
        clear(){
            
            this.dateFormatted = null;
            this.dates = [null, null];

        },
        
        formatDate (dates) {
            
            if(dates && dates.length > 0 && dates[0]){
                
                const [year1, month1, day1] = dates[0].split('-');
                
                if(dates.length > 1 &&  dates[1]){
                    const [year2, month2, day2] = dates[1].split('-');
                    return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`;
                }else{
                    return `${day1}/${month1}/${year1}`;
                }
            }
            
        },
        
        parseDate (dates) {
            
            if(dates && dates.includes(' - ')){
                
                var array = dates.split(' - ');
                const [day1, month1, year1] = array[0].split('/');
                const [day2, month2, year2] = array[1].split('/');

                return [`${year1}-${month1.padStart(2, '0')}-${day1.padStart(2, '0')}`, `${year2}-${month2.padStart(2, '0')}-${day2.padStart(2, '0')}`];
                
            }else{
                return null;
            }
        },

        cancel(){
            
            this.modal = false;

            if(!this.isModel){
                this.dateFormatted = null;
            }

            this.dates = null;
            
        }
    }
}
</script>