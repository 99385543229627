<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus/Departamento</th>
                        <th>Atividade</th>
                        <th>Periodicidade</th>
                        <th>Tipo</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Compõe CH</th>
                        <th class="text-center">Período</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            <span v-if="item.activityItem.campus">{{ item.activityItem.campus.name }}</span>
                            <span v-else-if="item.activityItem.departament">{{ item.activityItem.departament.name }}</span>
                        </td>
                        <td>{{ item.activityItem.activity.name }}</td>
                        <td>{{ item.activityItem.periodicityType.name }}</td>
                        <td>{{ item.activityItem.valueType.name }}</td>
                        <td class="text-center">{{ item.value }}</td>
                        <td class="text-center">
                            <v-icon small v-if="item.activityItem.activity.is_work_load" color="success">mdi-check-bold</v-icon>
                            <v-icon small v-else color="error">mdi-close-thick</v-icon>
                        </td>
                        <td class="text-center">{{ item.start|date }} - {{ item.end|date }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SysTeacherOtherActivities',
    data: function() {
        return {
            items: [],
            loading: true,
            renderComponent: true,
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true,
        },
        token: {
            type:   String,
            default: null
        }
    },
    methods: {
        ...mapActions('teacher', ['ActionFindTeacher']),
        ...mapActions('availability', ['ActionGetTeacherByTokenActivities']),

        getData() {

            this.items = [];
            this.loading = true;
            var method = this.token ? 'ActionGetTeacherByTokenActivities' : 'ActionFindTeacher';

            if(!this.teacher || !this.teacher.id) {
                return;
            }

            var params = {
                with: 'activities.activityItem.campus,activities.activityItem.departament,activities.activityItem.activity,activities.activityItem.valueType,activities.activityItem.periodicityType',
                showAllCampuses: 'true',
                showAllTeachers: true
            }

            if(this.token){
                params.token = this.token;
            }else{
                params.id = this.teacher.id;
            }

            this[method](params)
                .then((r) => {
                    
                    let items = [];

                    r.data.activities.forEach(item => {
                        if(item.activityItem.activity_item_status_id != 4){
                            items.push(item);
                        }
                    });
                    
                    this.items = items;
                })
                .finally(()=> {
                    this.loading = false
                })
        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        },
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>