import services  from '@/http'
import * as types from './mutation-types'
import * as storage from '../storage'
import state from '../store/state'
import { service } from '../../../helpers/http'

export const ActionDoLogin = ( { commit, dispatch }, payload) => {
    
    return new Promise( async (resolve, reject) => {

        try {

            let data;

            if(payload.token){
                data = await services.auth.token(payload);
            }else{
                data = await services.auth.login(payload);
            }

            const user = data.body.data.user;
            user.expire_in = data.body.data.expire_in;

            dispatch('ActionSetUser', user);
            dispatch('ActionSetToken', data.body.data.token);
            
            var access = {
                institution_id:         0,
                work_time_id:           0,
                educational_group_id:   0,
                campuses_id:            [],
                brands_id:              [],
                institutions_id:        [],
                user_id:                data.body.data.user.id
            };
        
            storage.setParamsHeader(JSON.stringify(access));
            commit(types.SET_HEADER, access);
            
            resolve(data);

        } catch(error) {

            if(error.status === 401) {

                const params = {
                    success:    error.body.success,
                    code:       error.body.code,
                    errors:     [],
                    message:    error.body.message
                };

                reject(params);
            } else {

                const params = {
                    success:    false,
                    code:       error.body.code,
                    errors:     error.body.errors,
                    message:    error.body.code == 422 ? 'Erro ao validar os campos!' : 'Erro inesperado!'
                };

                reject(params);
            }

        }
    });
}

export const ActionSignOut = ({ dispatch }) => {
    
    return services.auth.logout().then(res => {
        
        dispatch('ActionSetAcesso', {});
        dispatch('ActionSetMenu', {});
        dispatch('ActionSetUser', {});
        dispatch('ActionSetParams', {});
        dispatch('ActionSetToken', '');
        storage.setHeaderToken('');
        storage.deleteLocalUser();
        storage.deleteLocalToken();
        storage.deleteLocalAcesso();
        storage.deleteLocalMenu();
    
        if(window._Vue.$router.app._route.name != 'login')
            window._Vue.$router.push({ name: 'login' });
            
    });
    
}

export const ActionLoadAcesso = ({ dispatch }) => {
    return new Promise(async (resolve, reject) => {
        
        var payload = {
            user_id:    state.user.id,
            showAllCampuses: true,
            per_page: 100
        };

        try{
            await services.auth.loadAcesso(payload).then(res => {
                
                if(res.body.success){
                    resolve(res.body.data);
                }
            });
        }catch (err){
            dispatch('ActionSignOut');
            reject(err);
        }
    })
}



export const ActionCheckToken = ({ dispatch, state }) => {
    
    if(state.token)
    {
        return Promise.resolve(state.token)
    }

    const token = storage.getLocalToken()

    if(!token)
    {
        return Promise.reject(new Error('Token inválido'))
    }

    dispatch('ActionSetToken', token)

    const user = storage.getLocalUser()

    if(!user)
    {
        return Promise.reject(new Error('Usuário não encontrado'))
    }

    dispatch('ActionSetUser', JSON.parse(user));

    const acesso = storage.getLocalAcesso();
    
    if(acesso)
    {
        dispatch('ActionSetAcesso', JSON.parse(acesso))
    }
    
    const menu = storage.getLocalMenu()

    if(menu) {
        dispatch('ActionSetMenu', JSON.parse(menu))
    }

    //return dispatch('ActionLoadSession');
    return Promise.resolve(state.token)
    
}

export const ActionLoadSession = ({ dispatch }) => {
    return new Promise(async (resolve, reject) => {
        
        try{
            await services.auth.loadSession().then(res => {

                if(res.body.success){

                    const acesso = storage.getLocalAcesso();

                    if(acesso){
                        dispatch('ActionSetAcesso', JSON.parse(acesso));
                    }

                    //dispatch('ActionSetUser', object.user);
                    dispatch('ActionSetUser', res.body.data.user);
                    dispatch('ActionSetToken', res.body.data.token);
                    resolve();
                }
            });
        }catch (err){
            
            dispatch('ActionSignOut');
            reject(err);
        }

    })
}

export const ActionSetErrorsHttp = ({ commit }, payload) => {
    
    var messages = []

    if(state.errorsHttp.length > 0 && Object.keys(payload).length > 0) {
        messages = state.errorsHttp;
        messages.push(payload)
    } else if(Object.keys(payload).length > 0){
        messages.push(payload)
    }

    commit(types.SET_HTTP_ERROR, messages)
}

export const ActionSetAcesso = ({ dispatch, commit }, payload) => {
    
    storage.setLocalAcesso(JSON.stringify(payload));
    commit(types.SET_ACESSO, payload);
    dispatch('ActionSetParams', payload);
    
}

export const ActionSetParams = ({ commit }, payload) => {

    var access = {
        institution_id:         payload.instituicao             ? payload.instituicao.id : 0,
        work_time_id:           payload.periodo                 ? payload.periodo.id : 0,
        educational_group_id:   payload.grupo                   ? payload.grupo.id : 0,
        campuses_id:            payload.campus                  ? payload.campus.map(o => ({ id: parseInt(o['id']) })) : [],
        brands_id:              payload.brands                  ? payload.brands.map(o => ({ id: parseInt(o['id']) })) : [],
        institutions_id:        payload.institutions            ? payload.institutions.map(o => ({ id: o['id'] })) : [],
        user_id:                (state.user && state.user.id)   ? state.user.id : 0
    };
    
    storage.setParamsHeader(JSON.stringify(access));
    commit(types.SET_HEADER, access);

}

export const ActionSetUser = ({ commit }, payload) => {
    storage.setLocalUser(JSON.stringify(payload))
    commit(types.SET_USER, payload);
}

export const ActionSetToken = ({ commit }, payload) => {
    
    storage.setLocalToken(payload);
    storage.setHeaderToken(payload);
    commit(types.SET_TOKEN, payload);
}

export const ActionSetMenu = ({ commit }, payload) => {

    storage.setLocalMenu(JSON.stringify(payload))
    commit(types.SET_MENU, payload)

}

export const ActionGetMenus = ({ dispatch }, payload) => {

    return new Promise( async (resolve, reject) => {

        try {

            const data = await services.auth.menus(payload);

            const menu = data.body.data;

            dispatch('ActionSetMenu', menu)
            resolve(menu)

        } catch(error) {
            
            reject(error)
        }
    })
}

export const ActionSendMailResetPassword = ({dispatch}, payload) => {

    return new Promise( async (resolve, reject) => {

        try {

            const data = await services.auth.sendMailResetPassword(payload)

            const res = {
                success:    data.body.success,
                code:       data.body.code,
                message:    data.body.message,
                data:       data.body.data,
            };
            
            resolve(res);

        } catch(error) {

            if(error.status === 422) {

                const params = {
                    success: error.body.success,
                    code:   error.body.code,
                    errors: error.body.errors,
                    message: error.body.message
                };

                reject(params);
            } else {
                const params = {
                    code: error.status,
                    error: error.statusText
                }
            
                reject(params);
            }

        }

    })

}

export const ActionSendConfirmResetPassword = ({ dispatch }, payload) => {

    return new Promise( async (resolve, reject) => {

        try {

            const data = await services.auth.sendConfirmResetPassword(payload)

            if(data.body.code === 422) {
                
                const params = {
                    success: data.body.success,
                    code:   data.body.code,
                    errors: data.body.errors,
                    message: data.body.message
                };

                reject(params);

            } else {

                const res = {
                    success:    data.body.success,
                    code:       data.body.code,
                    message:    data.body.message,
                    data:       data.body.data,
                };
                
                resolve(res);
            }

        } catch(error) {

            if(error.status === 422) {

                const params = {
                    success: error.body.success,
                    code:   error.body.code,
                    errors: error.body.errors,
                    message: error.body.message
                };

                reject(params);
            } else {
                const params = {
                    code: error.status,
                    message: error.statusText
                }
            
                reject(params);
            }

        }

    })
}

export const ActionAccessTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'accessTeacher',
        payload: payload
    })
    
}

export const ActionTokenTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'tokenTeacher',
        payload: payload
    })
    
}

export const ActionDoLoginTeacher = ({ dispatch }, payload) => {

    return service({
        module: 'auth',
        action: 'accessTeacherAuth',
        payload: payload
    })
    
}

export const ActionSetSession = (({ dispatch }, payload) => {
    
    payload.campuses = payload.campuses.map(o => ({id: o['id']}));
    
    return service({
        module: 'auth',
        action: 'setSelected',
        payload: payload
    })
})

export const ActionAuthLoginFace = (({ dispatch }, payload) => {
    
    return service({
        module: 'auth',
        action: 'authLoginFace',
        payload: payload
    })
})