<template>
    <v-dialog v-model="dialog" scrollable :width="width" persistent>
            <v-card>
                <v-card-title dark :class="color">
                <v-icon :color="colorIcon" left>
                    {{ icon }}
                </v-icon>&nbsp;&nbsp;<span :class="classTitle">{{title}}</span></v-card-title>
                <v-card-text>
                    <v-container>
                        {{ text }}
                        <slot name="text"></slot>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <slot name="actions-prepend"></slot>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="disabled" @click.native="setCancel">{{ textCancel }}</v-btn>
                    <slot name="actions"></slot>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EmcAlertModal",
    data: () => ({
        dialog: false
    }),
    watch: {
        
        dialog(dialog_new, dialog_old) {
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }
        },

        show(show_new, show_old) {
            if(show_new !== show_old) {
                this.dialog = show_new
            }

        }
    },
    props: {
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Atenção"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        textCancel: {
            type: String,
            default: "Cancelar"
        },
        colorIcon: {
            type: String,
            default: 'grey'
        },
        classTitle: {
            type: String,
            default: null
        }
    },
    methods: {

        setCancel(){
            this.dialog = false;
            this.$emit('clickCancel');
        },

    }
}
</script>