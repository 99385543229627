import { service } from '../../../helpers/http'
export * from '../branch/store/actions'
export * from '../event/store/actions'
export * from '../company/store/actions'
export * from '../type_setting/store/actions'
export * from '../extract/store/actions'

export const ActionFindPaymentExports = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'find',
        payload: payload
    })
}

export const ActionCreatePaymentExport = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'create',
        payload: payload
    })
}

export const ActionUpdatePaymentExport = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'update',
        payload: payload
    })
}

export const ActionDeletePaymentExport = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'delete',
        payload: payload
    })
}

export const ActionFindValueTypes = ({ dispatch }, payload) => {

    return service({
        module: 'budget',
        action: 'valueTypes',
        payload: payload
    })
    
}

export const ActionFindPaymentExportStatus = ({ dispatch }, payload) => {

    return service({
        module: 'paymentExport',
        action: 'status',
        payload: payload
    })
    
}

export const ActionFindPaymentExportType = ({ dispatch }, payload) => {

    return service({
        module: 'paymentExport',
        action: 'type',
        payload: payload
    })
    
}

export const ActionGeneratePaymentExport = ({ dispatch }, payload) => {

    return service({
        module: 'paymentExport',
        action: 'generate',
        payload: payload
    })
    
}

export const ActionFindPaymentPeriodicityTypes = ({ dispatch }, payload) => {

    return service({
        module: 'paymentExport',
        action: 'periodicityTypes',
        payload: payload
    })
    
}

export const ActionFindPaymentExportItems = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'findItems',
        payload: payload
    })
}

export const ActionCreatePaymentExportItem = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'createItem',
        payload: payload
    })
}

export const ActionUpdatePaymentExportItem = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'updateItem',
        payload: payload
    })
}

export const ActionDeletePaymentExportItem = ({ dispatch }, payload) => {
    return service({
        module: 'paymentExport',
        action: 'deleteItem',
        payload: payload
    })
}

export const ActionIndexPaymentExportProratedTypes = ({ dispatch }, payload) => {

    return service({
        module: 'paymentExport',
        action: 'indexProratedTypes',
        payload: payload
    })
    
}