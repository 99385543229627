<template>
    <v-dialog v-model="dialog" scrollable :max-width="($vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm') ? '90%' : '80%'" persistent>
        <v-card height="600px" max-height="600px">
            <v-card-title class="ma-0 pa-0">
                <v-app-bar dense height="35" class="primary lighten-2 mb-4 elevation-1">
                    <v-toolbar-title >
                        <span class="subtitle-1"></span>
                    </v-toolbar-title>
                    <v-tooltip v-if="ambience && ambience.images && ambience.images.length > 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="openImage" >
                                <v-icon small color="white">mdi-folder-image</v-icon>
                            </v-btn>
                        </template>
                        <span>Carregar Foto</span>
                    </v-tooltip>
                    <v-tooltip v-if="ambience && ambience.images && ambience.images.length > 0" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="showCamera = true" >
                                <v-icon small color="white">mdi-camera</v-icon>
                            </v-btn>
                        </template>
                        <span>Tirar Foto</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                        <strong v-if="ambience" class="text-subtitle-1 white--text">{{ ambience.name_complete }}</strong>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon x-small @click.native="dialog = false" >
                                    <v-icon small color="white">mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                    </v-tooltip>
                </v-app-bar>
            </v-card-title>
            <v-card-text>
                <v-row
                    v-if="!overlay && (ambience && ambience.images && ambience.images.length == 0)"
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        
                        Nenhuma imagem disponível!
                        <br/>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon x-large @click.native="openImage" >
                                        <v-icon x-larg color="cyan">mdi-folder-image</v-icon>
                                    </v-btn>
                                </template>
                                <span>Carregar Foto</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon x-large @click.native="showCamera = true" >
                                        <v-icon x-large color="cyan">mdi-camera</v-icon>
                                    </v-btn>
                                </template>
                                <span>Tirar Foto</span>
                            </v-tooltip>
                      
                    </v-col>
                </v-row>
                <v-row
                    v-if="overlay"
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                    <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                    >
                     Carregando imagens...
                    </v-col>
                    <v-col cols="6">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col
                        v-for="(item, i) in ambience.images"
                        :key="i"
                        class="d-flex child-flex"
                        cols="4"
                    >
                        <v-card>
                            <v-img
                                :src="item.url"
                                aspect-ratio="1"
                                class="grey lighten-2"
                            >
                                <template v-slot:default>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn :loading="loadingMain && i == index" :style="item.main ? 'cursor: default;' : 'cursor: pointer;'" v-bind="attrs" v-on="on" @click="updateMain(item, i)" icon>
                                                <v-icon loading :color="item.main ? 'yellow' : 'black'" class="ma-1">{{ item.main ? 'mdi-star-half-full' : 'mdi-star-outline' }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ item.main ? 'Imagem principal' : 'Tornar imagem principal' }}</span>
                                    </v-tooltip>
                                </template>
                                
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-card-title>
                            
                            <span v-if="edit && i == index">
                                <v-text-field v-if="edit && i == index" dense :error="errors.length > 0" :error-messages="errors.title" v-model="item.title" label="Título"></v-text-field>
                            </span>
                            <span v-else>
                                {{ item.title }}
                            </span>
                            
                            <v-spacer></v-spacer>

                            <v-tooltip v-if="false" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn x-small v-bind="attrs" v-on="on" icon @click.native="editItem(i)" >
                                        <v-icon small color="primary">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :loading="loading && i == index" x-small v-bind="attrs" v-on="on" icon @click.native="openDelete(item, i)" >
                                        <v-icon small color="error">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                            
                        </v-card-title>
                        <v-card-subtitle>

                            <span v-if="edit && i == index">
                                <v-text-field v-if="edit && i == index" dense :error="errors.length > 0" :error-messages="errors.description" v-model="item.description" label="Descrição"></v-text-field>
                            </span>
                            <span v-else>
                                {{ item.description }}
                            </span>
                            
                        </v-card-subtitle>
                    </v-card>
                </v-col>

            </v-row>
            </v-card-text>
        </v-card>
        
        <form v-show="false" ref="form">
            <v-file-input
                id="inputAvatar"
                v-model="files"
                @change="onFiledPicked"
                :rules="rules"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pick an avatar"
                prepend-icon="mdi-camera"
                label="Avatar"
                multiple
            />
        </form>

        <emc-avatar-capture :show.sync="showCamera" @completeCapture="upload" />

        <emc-alert-modal text="Confirma a exclusão da imagem?" v-bind:show.sync="showDelete">
            <template v-slot:actions>
                <v-btn color="error" @click.prevent.stop="deleteItem()" >Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
            </template>
        </emc-alert-modal>

    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysAmbienceImages",
    data: (vm) => ({
        dialog: false,
        files: null,
        model: {},
        edit: false,
        loading: false,
        loadingMain: false,
        itemDelete: null,
        overlay: false,
        showDelete: false,
        index: -1,
        error: false,
        errors: [],
        showCamera: false,
        rules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        show(val){
            
            this.dialog = val;

            if(val){
                this.overlay = false;
                this.edit = false;
                this.index = -1;
            }
        },

        dialog(val){
            this.$emit('update:show', val);
        },
        
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        ambience: {
            required: true
        },
    },
    methods:{
        ...mapActions('ambience', ['ActionUploadAmbienceImage', 'ActionUpdateAmbienceImage', 'ActionDeleteAmbienceImage', 'ActionUpdateAmbienceImageMain']),

        onFiledPicked(files){
            
            if(files){
                
                let formData = new FormData(this.$refs.form);

                for (let i = 0; i < files.length; i++) {
                    formData.append('images[' + i + ']', files[i]);
                }

                this.upload(formData);
            }
        },
        
        upload(form)
        {
            var file = form.get('avatar');

            if(file){
                form.append('images[0]', file);
            }
            
            form.append('ambience_id', this.ambience.id);
            form.append('title', '');
            form.append('description', '');
            this.overlay = true;
            
            this.ActionUploadAmbienceImage(form)
                .then((res) => {
                    this.ambience.images = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.overlay = false;
                    this.files = null;
                })
        },

        updateMain(item, i){
            
            if(item.main){
                return;
            }
            
            this.loadingMain = true;
            item.main = !item.main;
            this.index = i;

            this.ActionUpdateAmbienceImageMain(item)
                .then((res) => {
                    this.ambience.images.forEach(element => {
                        element.main = (element.id == item.id);
                    });
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.loadingMain = false;
                    this.index = -1;
                })

        },

        updateItem(item)
        {
            this.ActionUpdateAmbienceImage(item)
                .then((res) => {
                    
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.index = -1;
                })
        },

        openDelete(item, i){
            
            this.itemDelete = item;
            this.index = i;
            this.showDelete = true;
        },

        deleteItem()
        {
            this.showDelete = false;
            this.loading = true;
            
            this.ActionDeleteAmbienceImage({ id: this.itemDelete.id })
                .then((res) => {
                    this.ambience.images = res.data;
                })
                .catch((error) =>{
                    
                })
                .finally(() => {
                    this.loading = false;
                    this.index = -1;
                    this.itemDelete = null;
                })
        },

        openImage(){
            var element = document.getElementById('inputAvatar');
            element.click();
        },

        editItem(i){
            this.index = i;
            this.edit = !this.edit;
        }

        

    }
}
</script>