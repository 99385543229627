import { default as place } from  './place/services'

export default {
    ...place,
    ambiences:              { method: 'get',     url: 'ambiences' },
    ambiencesSearch:        { method: 'get',     url: 'ambiences/search' },
    ambiencesSearchByItem:  { method: 'get',     url: 'ambiences/search/item' },
    ambiencesFilter:        { method: 'get',     url: 'ambiences/filter' },
    ambienceCreate:         { method: 'post',    url: 'ambiences' },
    ambienceUpdate:         { method: 'put',     url: 'ambiences/{id}' },
    ambienceDelete:         { method: 'delete',  url: 'ambiences/{id}' },
    uploadImage:            { method: 'post',    url: 'ambience_images/upload' },
    updateImage:            { method: 'put',     url: 'ambience_images/{id}'},
    deleteImage:            { method: 'delete',  url: 'ambience_images/{id}' },
    updateImageMain:        { method: 'put',     url: 'ambience_images/{id}/main'},
    classesOptimized:       { method: 'get',     url: 'ambiences/{id}/classes_optimized' },
    showAmbiencesTree:      { method: 'get',     url: 'ambiences/tree' },  
}