export default [
    {
        path: '/integration-process',
        name: 'integration-process',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Processos de integração',
            icon: 'mdi-transfer',
            breadcrumbs: [
                { text: 'Processos de integração', disabled: true }
            ]
        }
    }
]