import services  from '@/http'
import { returnResolve, returnReject } from '../helpers/response'

export function service(params) {

    return new Promise( async (resolve, reject) => {

        try {

            let payload = params.payload ? params.payload : {};
            let routParams = (!params.params && payload.id) ? { id: payload.id } : params.params;
            let data;

            if(routParams != undefined && payload.with != undefined) {
                routParams.with = payload.with
            }

            if(params.idAndPayload){
                data = await services[params.module][params.action].call(routParams, payload);
            }else{
                
                if(routParams){
                    data = await services[params.module][params.action](routParams, payload);
                }else{
                    data = await services[params.module][params.action].call({}, payload);
                }
            }

            if(params.unformatResponse){
                resolve(data);
            }else{
                resolve(returnResolve(data));
            }

        } catch(error) {

            if(params.unformatResponse){
                reject(error);
            }else{
                reject(returnReject(error));
            }

        }
    })

}

