import { default as unsanitary_types } from './unsanitary_type/routes'

export default [
    ...unsanitary_types,
    {
        path: '/disciplines',
        name: 'disciplines',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Disciplinas',
            icon: '',
            breadcrumbs: [
                { text: 'Turmas', disabled: true, href: '/' },
                { text: 'Disciplinas', disabled: true, href: '/' },
            ],
        }
    },
]