<template>
    <span>
        <v-tooltip v-if="toolTip" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :to="to" :target="target" :disabled="disabled" :loading="loading" v-bind="attrs" v-on="on" icon>
                    <v-icon :small="small || (!cancelAutoSmall && $vuetify.breakpoint.name != 'xs')" :color="color" @click.prevent.stop="$emit('click')">{{ icon }}</v-icon>
                </v-btn>
            </template>
            <span>{{ toolTip }}</span>
        </v-tooltip>
        <v-btn :to="to" :target="target" v-else :disabled="disabled" :loading="loading" icon>
            <v-icon  :small="small || (!cancelAutoSmall && $vuetify.breakpoint.name != 'xs')" :color="color" @click.prevent.stop="$emit('click')">{{ icon }}</v-icon>
        </v-btn>
    </span>
    
</template>
<script>
export default {
    name: "EmcTableIcon",
    data () {
        return {
            
        }
    },
    props: {
        color: {
            type: String,
            default: "blue accent-4"
        },
        icon: {
            type: String,
            required: true
        },
        toolTip: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        cancelAutoSmall: {
            type: Boolean,
            default: false
        },
        to: {
            default: null
        },
        target: {
            default: null
        },
        
    },
}
</script>
