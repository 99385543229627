import { default as branch } from  './branch/services'
import { default as event } from  './event/services'
import { default as company } from  './company/services'
import { default as type_setting } from  './type_setting/services'
import { default as extract } from  './extract/services'

export default {
    ...branch,
    ...event,
    ...company,
    ...type_setting,
    ...extract,
    find:               { method: 'get',    url: 'payment_exports' },
    create:             { method: 'post',   url: 'payment_exports'},
    update:             { method: 'put',    url: 'payment_exports/{id}'},
    delete:             { method: 'delete', url: 'payment_exports/{id}'},
    status:             { method: 'get',    url: 'payment_exports/status'},
    type:               { method: 'get',    url: 'payment_exports/types'},
    indexProratedTypes: { method: 'get',    url: 'payment_exports/prorated/types'},
    generate:           { method: 'post',   url: 'payment_exports/{id}/generate'},
    periodicityTypes:   { method: 'get',    url: 'payment_periodicity_types' },
    findItems:          { method: 'get',    url: 'payment_export_items' },
    createItem:         { method: 'post',   url: 'payment_export_items'},
    updateItem:         { method: 'put',    url: 'payment_export_items/{id}'},
    deleteItem:         { method: 'delete', url: 'payment_export_items/{id}'},
}