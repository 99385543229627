import { service } from '../../../../helpers/http'

export const ActionIndexUserDownloads = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'indexUserDownloads', 
		payload: payload 
	})
}

export const ActionDestroyUserDownload = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'destroyUserDownload', 
		payload: payload 
	})
}

export const ActionIndexReports = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'user', 
		action: 'indexReports', 
		payload: payload 
	})
}