<template>
    <div>
        <EmcBaseProgressBar v-if="loading" />

        <v-simple-table dense v-else>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Campus</th>
                        <th>Empresa</th>
                        <th>Descrição</th>
                        <th>Período</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in items.filter(i => i.registration !== null)" :key="i">
                        <td>{{ item.campus.abbreviation }}</td>
                        <td>
                            <span v-if="item.company">{{ item.company.name }}</span>
                            <small v-if="item.registration"><br/>{{ item.registration }}</small>
                        </td>
                        <td>{{ item.description ? item.description : item.note }}</td>
                        <td>{{ item.start | date }} - {{ item.end | date  }}</td>
                        <td>
                            <span v-if="item.payment_export_item_status_id == 1">{{ parseFloat(item.value) }}</span>
                            <span v-else>{{ parseFloat(item.value_prorated_grids) }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4" style='text-align:right;'><strong>Total: </strong></td>
                        <td style='text-align:center;'><strong>{{ total }}</strong></td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'PaymentExportItemExtract',
    data: function() {
        return {
            items: [],
            total: 0,
            loading: true,
            renderComponent: true,
        }
    },
    props: {
        teacher: {
            type: Object,
            required: true
        },
        token: {
            type:   String,
            default: null
        },
        paymentTypeId: {
            required: true
        },
        month: {
            required: true
        },
        workTime: {
            type:   Object,
            required: true
        },
    },
    methods: {
        ...mapActions('paymentExport', ['ActionFindPaymentExportItems']),

        getData() {

            this.items = [];
            this.total = 0;
            
            if(this.teacher.id === undefined) {
                return []
            }

            this.loading = true;

            var params = {
                find_columns: {
                   teacher_id:   this.teacher.id,
                   main:         1,
                   payment_export_item_status_id: '1,2,3',
                },
                filter_relations: [
                    {
                        name:   'paymentExport',
                        key:    'payment_type_id', 
                        value:  this.paymentTypeId,
                    },
                    {
                        name:   'paymentExport',
                        key:    'month', 
                        value:  this.month,
                    },
                    {
                        name:   'paymentExport',
                        key:    'payment_export_status_id', 
                        value:  4,
                    },
                    {
                        name:   'paymentExport',
                        key:    'work_time_id', 
                        value:  this.workTime.id,
                    },
                ],
                with: 'paymentExport.type,campus,company',
            }

            this.ActionFindPaymentExportItems(params)
                .then((r) => {
                    
                    this.items = r.data;
                    // this.sortAtts(items);
                    this.sumWorkLoads(this.items);

                })
                .finally(()=> {
                    this.loading = false
                })
        },

        sortAtts(items){

            items.forEach(att => {
                att.key = att.grid.group.campus.name + '|' + att.grid.group.course.name + '|' + att.grid.group.shift.id + '|' + att.grid.group.name + '|' + att.grid.discipline.name + '|' + att.grid.group_number.toString().padStart(3, '0') + '|' + att.date_start;
            });

            items.sort(function(a, b){
                return a.key.toString().localeCompare(b.key);
            });

        },

        sumWorkLoads(items){

            this.total = 0;
            var sum = 0;
            
            items.forEach(item => {
                if(item.registration){
                    if(item.payment_export_item_status_id == 1){
                        sum += parseFloat(item.value);
                    }else{
                        sum += parseFloat(item.value_prorated_grids);
                    }
                }
            });

            this.total = sum.toFixed(2);

        },

        forceRerender () {
            this.renderComponent = false;

            this.$nextTick(() => {
            this.renderComponent = true;
        });
      },
    },
    created() {
        this.getData()
    },
    watch: {
        teacher: function(){
                this.getData()
        },

        month: function(){
                this.getData()
        },

        paymentTypeId: function(){
                this.getData()
        }
    }
}
</script>

<style scoped>

      table td {
          white-space: nowrap;
      }
    
</style>