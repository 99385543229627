<template>
    <v-card :class="classCard" :color="colorCard" :elevation="elevation" :min-height="minHeight">
    <v-card-title class="pb-0 card--title">
        <v-sheet
          :class="classSheet"
          :color="color"
          elevation="6"
          :width="sheetWidth"
          :height="sheetHeigth"
          dark
          rounded
    >
        <div :class="iconClass">
          <v-icon :large="iconLarge" :small="iconSmall">{{ icon }}</v-icon> 
        </div>
    </v-sheet>
    <v-spacer></v-spacer>
    <v-card-subtitle class="align-self-start text-right pa-0">
      <slot name="body"></slot>
      <div v-if="title" class="subheading font-weight-light grey--text">{{ title }}</div>
      <div v-if="value || value === 0" class="text-h4 font-weight-light">{{ value }}</div>
    </v-card-subtitle>

    </v-card-title>

    <v-card-text class="pt-0">
      <!-- <div v-if="subtitle" :class="classSubTitle">{{ subtitle }}</div>
      <v-divider v-if="tools" class="my-1"></v-divider>
      <v-toolbar v-if="tools" class="elevation-0" dense>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <slot name="tools" />
      </v-toolbar> -->

      <!-- <span v-if="showActions">
        <v-divider  class="my-1"></v-divider>
        <v-row>
          <v-col cols="12" md="10">
            <span class="text-caption font-weight-light">
              {{ textActions }}
            </span>
          </v-col>
          <v-col  cols="12" md="2">
            <slot name="actions" />
          </v-col>
        </v-row>
      </span> -->

      <!-- <span v-if="subText">
          
        <v-divider  class="my-1"></v-divider>

          <v-icon v-if="subIcon" class="mr-2" small>{{ subIcon }}</v-icon>

          <span class="text-caption font-weight-light">
            {{ subText }}
            
            </span>
      </span> -->
      
      <div v-if="subtitle" :class="classSubTitle">{{ subtitle }}</div>

      <v-divider v-if="showCaption" class="mt-2"></v-divider>
      <v-card-actions v-if="showCaption" class="pa-0 pt-3">
        <v-icon v-if="subIcon" class="mr-2" small>{{ subIcon }}</v-icon>
        <span class="text-caption font-weight-light">{{ caption }}</span>
        <v-spacer></v-spacer>
        <slot name="actions" />
        <slot name="tools" />
      </v-card-actions>
      
    </v-card-text>
  </v-card>

</template>

<script>
export default {
    name: "EmcCardStats",
    data () {
      return {
      }
    },
    props: {
        minHeight: {
          type: [String, Number],
          default: 0
        },
        color: {
            type: String,
            default: undefined
        },
        colorCard: {
            type: String,
            required: false
        },
        elevation: {
          type: [Number, String],
          required: false
        },
        icon: {
            type: String,
            default: undefined
        },
        value: {
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        subtitle: {
            type: String,
            required: false
        },
        subIcon: {
            type: String,
            required: false
        },
        subText: {
            type: String,
            required: false
        },
        sheetWidth: {
          type: String,
          default: '100'
        },
        sheetHeigth: {
          type: String,
          default: '100'
        },
        iconLarge: {
          type: Boolean,
          default: false
        },
        iconSmall: {
          type: Boolean,
          default: false
        },
        classCard: {
          type: String,
          default: '"mt-6 mx-auto'
        },
        classSheet: {
          type: String,
          default: 'mt-0 v-sheet-mini--offset'
        },
        iconClass: {
          type: String,
          default: 'pa-8'
        },
        tools: {
          type: Boolean,
          default: false
        },
        showActions: {
          type: Boolean,
          default: false,
        },
        textActions: {
          type: String,
          default: null
        },
        classSubTitle: {
          type: String,
          default: 'text-h5 font-weight-light grey--text'
        }
    },
    computed: {
      caption: {
        get() {
          return this.subText || this.textActions
        },
        set(val) {
          //
        }
      },
      showCaption: {
        get() {
          return this.tools || this.subText || this.textActions
        },
        set(val) {
          //
        }
      }
    }
}
</script>

<style>
  .card--title .v-sheet-mini--offset {
    top: -30px;
    position: relative;
  }
</style>