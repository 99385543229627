<template>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
            <v-data-table
                  v-if="renderComponent"
                  :headers="headers"
                  :items="model.teachers"
                  :items-per-page="-1"
                  item-key="index"
                  hide-default-footer
                  fixed-header
                  height="300"
                 
              >
                  <template v-slot:top="{ on, attrs }">
                        <v-btn 
                          color="indigo darken-1" 
                          @click.prevent.stop="addItem()" 
                          icon v-bind="attrs" 
                          v-on="on">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                  </template>

                  <template v-slot:item.teacher="{item, index}">
                      <sys-select-teacher
                          :model.sync="item.teacher_id"
                          :data.sync="item.teacher"
                          :error="errors.length > 0" 
                          :errors="errors['teachers.' + index + '.teacher_id']"
                          label=""
                          autoComplete
                          autofocus
                          showClear
                          dense
                      />
                  </template>

                  <template v-slot:item.start="{item, index}">
                    <emc-calendar-date-picker2
                        :errorMessages="errors['teachers.' + index + '.start']" 
                        :show="dialog" 
                        :model.sync="item.start" 
                        :min="model.date_start"
                        :max="model.date_end"
                        :open-on-click="false"
                        :readonly="false"
                      />
                  </template>

                  <template v-slot:item.end="{item, index}">
                    <emc-calendar-date-picker2
                        :errorMessages="errors['teachers.' + index + '.end']" 
                        :show="dialog" 
                        :model.sync="item.end" 
                        :min="model.date_start"
                        :max="model.date_end"
                        :open-on-click="false"
                        :readonly="false"
                      />
                  </template>


                  <template v-slot:item.value="{item, index}">
                    <v-text-field 
                        v-model="item.value" 
                        :error="errors.length > 0" 
                        :error-messages="errors['teachers.' + index + '.value']" 
                        dense
                      />
                  </template>

                  <template v-slot:item.contract="{item, index}">
                      <sys-select-teacher-contract
                          :model.sync="item.teacher_contract_id"
                          :data.sync="item.teacher_contract_id"
                          :error="errors.length > 0" 
                          :errors="errors['teachers.' + index + '.teacher_contract_id']"
                          label=""
                          autoComplete
                          autofocus
                          showClear
                          dense
                          :teacher-id="item.teacher_id"
                          :campus-id="model.campus_id"
                        />
                  </template>

                  <template v-slot:item.note="{item, index}">
                     <v-textarea
                        v-model="item.note" 
                        :error="errors.length > 0" 
                        :error-messages="errors['teachers.' + index + '.note']" 
                        dense
                        rows="1"
                        clearable
                      />
                  </template>

                  <template v-slot:item.actions="{ item, index }">
                      
                      <v-btn
                        @click.prevent.stop="deleteItem(item, index)"
                        :loading="loading && indexDelete == index"
                        :disabled="loading"
                        icon
                      >
                        <v-icon
                            small
                            color="error"
                            dense
                        >
                            mdi-delete
                        </v-icon>
                      </v-btn>
                      
                      
                  </template>

            </v-data-table>
        </v-col>
      </v-row>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: "SysActivityItemRegisterTeachers",
    data: () => ({
      fav: true,
      dialog: false,
      loading: false,
      indexDelete: -1,
      renderComponent: true,
      headers: [
          { text: 'Professor',  value: 'teacher',   sortable: false,  width: "29%" },
          { text: 'Início',     value: 'start',     sortable: false,  width: "13%" },
          { text: 'Fim',        value: 'end',       sortable: false,  width: "13%" },
          { text: 'Valor',      value: 'value',     sortable: false,  width: "7%"  },
          // { text: 'Matrícula',  value: 'contract',  sortable: false,  width: "12%" },
          { text: 'Observação', value: 'note',      sortable: false,  width: "25%" },
          { text: '',           value: 'actions',   sortable: false,  width: "1%"  },
      ],
    }),
    props: {
        model: {
            type: Object,
        },
        errors: {

        },
        workTime: {
          required: true
        }
        
    },
     watch: {
       
     },
     
     computed: {
      ...mapState('auth', ['acesso']),
    },
    methods: {
      ...mapActions('activityItem', ['ActionDeleteActivityItemTeacher']),
      
      deleteItem(item, index){
          
          if(!item.id){
            this.removeItem(item);
            return;
          }
          
          this.loading = true;
          this.indexDelete = index;
          
          this.ActionDeleteActivityItemTeacher({id: item.id})
            .then((res) => {
                this.removeItem(item);
            })
            .catch((error) =>{
                
                
            })
            .finally(() => {
                  this.loading = false;
                  this.indexDelete = -1;
                  this.forceRerender();
            });
            
      },

      removeItem(item){
          var index = this.model.teachers.indexOf(item);
          this.model.teachers.splice(index, 1);
          this.forceRerender();
      },

      addItem(){
          
          if(!this.model){
            return;
          }

          var length = this.model.teachers.length;

          var item = {
              index:                length,
              id:                   0,
              teacher_id:           null,
              value:                this.model.value,
              start:                this.model.date_start,
              end:                  this.model.date_end,
              teacher_contract_id:  null,
              note:                 null,
          };

          this.model.teachers.push(item);
          this.forceRerender();
          
      },

      forceRerender () {
      
        this.renderComponent = false;

        this.$nextTick(() => {
            this.renderComponent = true;
      });

  },
    }
}
</script>