export default [
    {
        path: '/clocking',
        name: 'clocking',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Marcação de Ponto',
            icon: 'mdi-layers-triple',
            breadcrumbs: [
                { text: 'Marcação de Ponto', disabled: true }
            ]
        }
    }
]