import { service } from '../../../helpers/http'

export const ActionFindResources = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'resource', 
		action: 'resources', 
		payload: payload 
	})
}

export const ActionCreateResource = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'resource', 
		action: 'createResource', 
		payload: payload 
	})
}

export const ActionUpdateResource = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'resource', 
		action: 'updateResource', 
		payload: payload 
	})
}

export const ActionDeleteResource = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'resource', 
		action: 'deleteResource', 
		payload: payload 
	})
}