import { default as place } from './place/routes'

export default [
    ...place,
    {
        path: '/ambiences',
        name: 'ambiences',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Ambientes',
            icon: 'mdi-flask-outline',
            breadcrumbs: [
                { text: 'Ambientes', disabled: true, href: '/' }
            ],
        }
    },
]