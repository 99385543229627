export default {
    ticketCategoriesIndex: { method: 'get', url: 'emcadmin/ticket-categories' },
    ticketCategoriesStore: { method: 'post', url: 'emcadmin/ticket-categories' },
    ticketCategoriesUpdate: { method: 'put', url: 'emcadmin/ticket-categories/{id}' },
    ticketCategoriesDestroy: { method: 'delete', url: 'emcadmin/ticket-categories/{id}' },

    ticketStatusesIndex: { method: 'get', url: 'emcadmin/ticket-statuses' },
    ticketStatusesStore: { method: 'post', url: 'emcadmin/ticket-statuses' },
    ticketStatusesUpdate: { method: 'put', url: 'emcadmin/ticket-statuses/{id}' },
    ticketStatusesDestroy: { method: 'delete', url: 'emcadmin/ticket-statuses/{id}' },

    ticketTypesIndex: { method: 'get', url: 'emcadmin/ticket-types' },
    ticketTypesStore: { method: 'post', url: 'emcadmin/ticket-types' },
    ticketTypesUpdate: { method: 'put', url: 'emcadmin/ticket-types/{id}' },
    ticketTypesDestroy: { method: 'delete', url: 'emcadmin/ticket-types/{id}' },

    ticketsIndex: { method: 'get', url: 'emcadmin/tickets' },
    ticketsStore: { method: 'post', url: 'emcadmin/tickets' },
    ticketsUpdate: { method: 'put', url: 'emcadmin/tickets/{id}' },
    ticketsDestroy: { method: 'delete', url: 'emcadmin/tickets/{id}' },

    ticketsCommentStore: { method: 'post', url: 'emcadmin/tickets/{id}/comments' },

    ticketsGraphTotalIndex: { method: 'get', url: 'emcadmin/tickets/graph/total' },
}