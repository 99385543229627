export default [
    {
        path: '/activities',
        name: 'activities',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Tipos de atividades',
            icon: '',
            breadcrumbs: [
                { text: 'Atividades', disabled: true, href: '/' },
                { text: 'Tipos de atividades', disabled: true, href: '/' }
            ],
        }
    },
]