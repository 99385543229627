export default [
    {
        path: '/establishments',
        name: 'establishments',
        component: () => import ('./pages/Index'),
        meta: {
            title: 'Estabelecimentos',
            icon: 'mdi-office-building',
            breadcrumbs: [
                { text: 'Instituição', disabled: true, href: '/' },
                { text: 'Estabelecimentos', disabled: true, href: '/' }
            ],
        }
    },
]