<template>
    <sys-register-base 
        :loading="loading" 
        @onSave="save" 
        :model.sync="model" 
        :title="title" 
        icon="mdi-text-box-check" 
        v-bind:show.sync="dialog"
        maxWidth="40%"
    >
        <template v-slot:form>
            <v-container>
                    <v-row>
                        <v-col  cols="12" sm="12">
                            <sys-select-occurrence-status
                                v-if="renderComponent"
                                :model.sync="model.occurrence_status_id"
                                :error="errors.length > 0"  
                                :errors="errors.occurrence_status_id"
                                label="Status"
                                :filter="filter"
                                :disabled="disableValidation"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-textarea
                                :error-messages="errors.justification" 
                                v-model="model.justification" 
                                label="Justificativa"
                                :counter="255"
                                :rules="[v => (!v || v.length <= 255) || 'Máximo de 255 caracteres atingido']"
                                outlined
                                 />
                        </v-col>
                    </v-row>
            </v-container>
            <!-- Alert para mensagens -->
            <emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: "SysOccurrenceValidation",
    data: (vm) => ({
        dialog: false,
        error: false,
        errors: {},
        message: '',
        loading: false,
        filter: [2],
        renderComponent: true,
        disableValidation: false,
    }),
    created(){
       
    },

    computed: {
      ...mapState('auth', ['acesso']),
    },

    watch: {
        
        dialog(dialog_new, dialog_old) {
            
            if(dialog_new !== dialog_old) {
                this.$emit('update:show', dialog_new)
            }

        },

        show(show_new, show_old) {
            
            this.dialog = show_new;
            
            if(show_new){
                
                this.error = false;
                this.errors = {};
                this.filter = this.model.type.status_default.map(i => i.id);
                this.disableValidation = !this.model.type.allow_editing;
                this.forceRerender();
            }
        },
        
    },
    props: {
        model: {
            default: null
        },
        color: {
            type: String,
            default: 'grey lighten-5'
        },
        width: {
            type: String,
            default: "500"
        },
        maxWidth: {
            type: String,
            default: "80%"
        },
        icon: {
            type: String,
            default: "mdi-alert"
        },
        title: {
            type: String,
            default: "Validação de Ocorrências de Ponto"
        },
        text: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        ...mapActions('timeSheet', ['ActionValidateOccurrence']),

        save()
        {
            this.error = false;
            this.message = '';
            this.loading = true;

            var payload ={
                id:                     this.model.id,
                occurrence_status_id:   this.model.occurrence_status_id,
                justification:          this.model.justification
            };

            this.ActionValidateOccurrence(payload)
            .then((res) => {
                this.message = res.message;
                this.$emit('onCompleted');
            })
            .catch((error) =>{
                this.error      = true;
                this.errors     = error.errors;
                this.message    = error.message;
            })
            .finally(() => {
                    this.loading = false;
            });
            
        },

        forceRerender () {
      
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },
    }
}
</script>