export default [
    {
        path: '/modules',
        name: 'module',
        component: () => import ('./pages/ModuleIndex'),
        meta: {
            title: 'Módulos',
            icon: 'mdi-layers-triple',
            breadcrumbs: [
                { text: 'Lista de módulos', disabled: true }
            ]
        }
    }
]