import { service } from '../../../../helpers/http'

export const ActionIndexCompanies = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'indexCompanies', 
		payload: payload 
	})
}

export const ActionCreateCompany = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'createCompany', 
		payload: payload 
	})
}

export const ActionUpdateCompany = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'updateCompany', 
		payload: payload 
	})
}

export const ActionDestroyCompany = ({ dispatch }, payload ) => { 
	return service({ 
		module: 'paymentExport', 
		action: 'destroyCompany', 
		payload: payload 
	})
}