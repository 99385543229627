import { service } from '../../../helpers/http'

export const ActionFindFaqs = ({ dispatch }, payload) => {

    return service({
        module: 'faq',
        action: 'find',
        payload: payload
    })
    
}

export const ActionCreateFaq = ({ dispatch }, payload) => {
    
    return service({
        module: 'faq',
        action: 'create',
        payload: payload
    })
    
}

export const ActionUpdateFaq = ({ dispatch }, payload) => {
    
    return service({
        module: 'faq',
        action: 'update',
        payload: payload
    })
}

export const ActionDeleteFaq = ({ dispatch }, payload) => {
    
    return service({
        module: 'faq',
        action: 'delete',
        payload: payload
    })
    
}

